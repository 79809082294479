import React, { useEffect, useRef, useState, createRef } from "react";
import "./MailPopup.css";
import { useDispatch } from "react-redux";
import {
  feedbackData,
  getEmailTemplate,
} from "../../../reducers/feedbackSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToastMessage from "../../Toast/ToastComponent";
import PageLoader from "../../../components/UI/loader/PageLoader";

import { EMAIL_TYPE, Rejection_Letter } from "../../../constants/textConstant";
import {
  applicantData,
  clearEmailState,
  getShortlistedApplicantList,
  editOfferLetterAmountEmail,
  clearState,
  rejectApplicants,
  postStudentAvailability,
} from "../../../reducers/applicantSlice";
import "suneditor/dist/css/suneditor.min.css";

const RejectWaitlistPopup = (props) => {
  const [isMailSkipped, setChecked] = useState(false);

  const navigate = useNavigate();

  const [emailAddress, setEmailAddress] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  let ckeditor = useRef(null);
  const ref = createRef();
  const [loggedInUser, setLoggedInUser] = useState("");

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    setLoggedInUser(userName);
  }, []);

  const [showEditTemplate, setEditTemplate] = useState(false);
  const [showErroMessage, setEnableErrorMessage] = useState(false);
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [emailToken, setEmailToken] = useState(null);
  const [editorState, setEditorState] = useState("");
  const dispatch = useDispatch();
  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    successMessage,
    feedbackList,
    feedbackResponse,
    studentList,
    feedbackDetail,
    emailTemplateData,
  } = useSelector(feedbackData);
  const appData = useSelector(applicantData);
  const emailContent = emailTemplateData;

  console.log("template data", emailTemplateData);

  const bindEmailSubject = () => {
    setEmailSubject(emailContent && emailContent.templateSubject);
  };

  useEffect(() => {
    bindEmailSubject();
  }, [emailContent && emailContent.templateSubject]);

  // useEffect(() => {
  //   dispatch(
  //     getEmailTemplate({
  //       templateName: "",
  //     })
  //   );
  // }, []);

  useEffect(() => {
    if (props?.mailType === "reject") {
      console.log("inside rejected case");

      dispatch(
        getEmailTemplate({
          templateName:
            props?.programMasterId === "53e08f7a-a03b-42f3-b181-064d1afc0511"
              ? Rejection_Letter.rejectionFPA
              : props?.programMasterId ===
                "53e08f7a-a03b-42f3-b181-064d1afc0510"
              ? Rejection_Letter.rejectionUsrExp
              : props?.programMasterId ===
                "53e08f7a-a03b-42f3-b181-064d1afc05e6"
              ? Rejection_Letter.rejectionProductMgmt
              : props?.programMasterId ===
                "e524ffd2-1bd5-4130-a5a4-9509fb314658"
              ? Rejection_Letter.rejectionHRMgmt
              : Rejection_Letter.rejectionHRMgmt,
        })
      );
    }

    if (props?.mailType === "waitList") {
      console.log("inside waitlist case");

      dispatch(
        getEmailTemplate({
          templateName: "Masters'Union_Applicants_Waitlist_Email",
        })
      );
    }
  }, [props?.mailType]);

  const sendRejectMail = () => {
    let obj = {
      obj: [props?.applicantId],
      programId: props?.programBatchId,
      programMasterId: props?.programMasterId,
      templateBody: emailContent.templateBody,
      templateSubject: emailContent.templateSubject,
    };
    console.log("pringint object", obj);
    dispatch(
      rejectApplicants({
        obj: [props?.applicantId],
        programId: props?.programBatchId,
        programMasterId: props?.programMasterId,
        templateBody: emailContent.templateBody,
        templateSubject: emailContent.templateSubject,
      })
    );
    props?.setPageNo("1");
    // }
  };

  const sendWaitlistMail = () => {
    let obj = {
      isApplicantApproved: "Waitlisted",
      applicantId: props.applicantId,
      programId: props?.programBatchId,
      programMasterId: props?.programMasterId,
      specificTemplateBody: emailContent.templateBody,
      specificTemplateSubject: emailContent.templateSubject,
    };
    console.log("printing obejc", obj);

    dispatch(postStudentAvailability(obj));
    props.setPageNo("1");
  };

  return (
    <React.Fragment>
      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Email Sent Successfully"}
      />

      {isFetching && <PageLoader />}
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className={`popup-container mail-popped active`}>
          <div className="popup-header">
            <div className="popup-heading">
              Email Preview
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>

            <div className="popup-header-body">
              <div className="mail-head-shell">
                <div className="mail-head-shell-inside">
                  <span className="text-1">Subject</span>
                  <p className="text-1-bold mt-10">{emailSubject}</p>
                </div>

                <div className="mail-head-shell-inside">
                  <span className="text-1">Send To</span>
                  <p className="text-1-bold mt-10">1</p>
                </div>

                <div className="mail-head-shell-inside width-100 mt-50">
                  <span className="text-1">Email Body</span>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-body only-img scrollbar ckeditorPopupOutr">
            <div
              dangerouslySetInnerHTML={{
                __html: emailContent && emailContent.templateBody,
              }}
            />
          </div>

          <div className="popup-footer mail-popup-footer">
            <label
              htmlFor="skip-offer"
              className="checkbox-label skip-mail-offerletter"
            >
              <input
                id="skip-offer"
                className="checkmark-btn"
                type="checkbox"
                defaultChecked={isMailSkipped}
                onChange={() => setChecked(!isMailSkipped)}
              />
              <span class="checkmarkspan"></span>
              Skip Mail
            </label>

            {props?.mailType === "reject" ? (
              <button
                onClick={sendRejectMail}
                className="danger-btn width-auto"
              >
                Reject
              </button>
            ) : (
              <button
                onClick={sendWaitlistMail}
                className="danger-btn width-auto"
              >
                Waitlist
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RejectWaitlistPopup;
