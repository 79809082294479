import React, { useEffect, useState } from "react";
import ProgramDropdown from "../../Dropdowns/ProgramDropdown/ProgramDropdown";
import "./EditFeePopupStyle.css";
import PageLoader from "../../../components/UI/loader/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addProgramAndFeeDetails, editFeeDetails, feeData, getEditFeeDetails, getFeeTypes } from "../../../reducers/feeSlice";
import moment from 'moment'

import toast from "react-hot-toast";
import ErrorToast from "../../Toast/ErrorToast";
const EditFeePopup = (props) => {

  const defaultQuestionType = {
    type: "Choose Question type",
    image: null,
  };

  useEffect(() => {
    dispatch(getFeeTypes());
  }, []);

  useEffect(() => {
    if (props.feeItem !== null) {
      setProgramFeeData(props.feeItem);
    } else {
      setFeeId('');
      setFeeAmt('');
      setTaxes([{ id: 0, taxType: '', percent: '' }]);
      setInstallments([{ id: 0, installmentNumber: '', amount: '', dueDate: '' }]);
      setIsInstallment(false);
    }

  }, [props.feeItem])


  const [searchParams] = useSearchParams();
  const programMasterId = searchParams.get("programMasterId") ?? '';
  const programBatchId = searchParams.get("programBatchId") ?? '';

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [feeId, setFeeId] = useState('');
  const [feeAmt, setFeeAmt] = useState('');
  // const [feeDue, setFeeDue] = useState('');

  const [taxes, setTaxes] = useState([{ id: 0, taxType: '', percent: '' }]);
  const [installments, setInstallments] = useState([{ id: 0, installmentNumber: '', amount: '', dueDate: '' }]);

  const [isInstallment, setIsInstallment] = useState(false);

  const { feeTypes } = useSelector(feeData);

  const setProgramFeeData = (feesObj) => {
    setFeeId(feesObj?.FeeMaster.id);
    setFeeAmt(feesObj?.amount);
    setTaxes(feesObj?.FeeTaxes);
    setInstallments(feesObj?.FeeInstallments);
    setIsInstallment(feesObj?.isPayableInInstallments);
  }

  const onAddInstallment = () => {
    setIsInstallment((prev) => !prev);
    setInstallments([{ id: 0, installmentNumber: '', amount: '', dueDate: '' }]);
  }

  // tax functions
  const onAddTax = e => {
    let isEmpty = taxes.some((tax) => tax.id === '' || tax.percent === '');
    if (isEmpty) {
      return;
    }
    setTaxes((prev) => [...prev, { id: prev[prev.length - 1].id + 1, taxType: '', percent: '' }]);
  }

  const onDeleteTax = id => {
    setTaxes(prev => prev.filter((el) => el.id !== id));
  }

  const setTaxName = (e, id) => {
    let taxx = [...taxes];
    let idx = taxx.findIndex(obj => obj.id == id);
    let obj = { id: taxx[idx].id, taxType: e.target.value, percent: taxx[idx].percent };
    taxx.splice(idx, 1, obj);
    setTaxes(taxx);
  }
  const setTaxPer = (e, id) => {
    let taxx = [...taxes];
    let idx = taxx.findIndex(obj => obj.id == id);
    let obj = { id: taxx[idx].id, taxType: taxx[idx].taxType, percent: e.target.value };
    taxx.splice(idx, 1, obj);
    setTaxes(taxx);
  }


  // installement functions
  const onAddIns = e => {
    let isEmpty = installments.some((ins) => ins.amount === '' || ins.installmentNumber === '' || ins.dueDate === '');
    if (isEmpty) {
      return;
    }
    setInstallments((prev) => [...prev, { id: prev[prev.length - 1].id + 1, installmentNumber: '', amount: '', dueDate: '' }]);
  }

  const onDeleteIns = id => {
    setInstallments(prev => prev.filter((el) => el.id !== id));
  }

  const setInstallmentNo = (e, id) => {
    let ins = [...installments];
    let idx = ins.findIndex(obj => obj.id == id);
    let obj = { id: ins[idx].id, installmentNumber: e.target.value, amount: ins[idx].amount, dueDate: ins[idx].dueDate };
    ins.splice(idx, 1, obj);
    setInstallments(ins);
  }
  const setInstallmentAmt = (e, id) => {
    let ins = [...installments];
    let idx = ins.findIndex(obj => obj.id == id);
    let obj = { id: ins[idx].id, installmentNumber: ins[idx].installmentNumber, amount: e.target.value, dueDate: ins[idx].dueDate };
    ins.splice(idx, 1, obj);
    setInstallments(ins);
  }
  const setDueDate = (e, id) => {
    let ins = [...installments];
    let idx = ins.findIndex(obj => obj.id == id);
    let obj = { id: ins[idx].id, installmentNumber: ins[idx].installmentNumber, amount: ins[idx].amount, dueDate: e.target.value };
    ins.splice(idx, 1, obj);
    setInstallments(ins);
  }

  const onFormSubmit = e => {
    e.preventDefault();
    let isValid = true;
    if (feeId === '' || feeAmt === '') {
      isValid = false;
      // toast.error("Fill all the required fields !");
      setIsShowError(true);
      setErrMessage("Fill all the required fields!");
      return
    }

    let isEmpty = taxes.some((tax) => tax.id === '' || tax.percent === '');
    if (isEmpty) {
      isValid = false;
      // toast.error("Fill all the required fields !");
      setIsShowError(true);
      setErrMessage("Fill all the required fields!");
      return
    }
    const program = {
      isCreate: false,
      programId: programBatchId,
      programMasterId: programMasterId
    }
    const fee = {
      feeMasterId: feeId,
      amount: feeAmt,
      isPayableInInstallments: isInstallment,
      numberOfInstallments: installments.length,
      feeTax: taxes,
      installment: installments
    }
    if (isValid) {
      if (props.feeItem !== null) {
        dispatch(editFeeDetails({ fee: { ...fee, feeId: props.feeItem.id }, program: program, }))
        setFeeId('');
        setFeeAmt('');
        setTaxes([{ id: 0, taxType: '', percent: '' }]);
      } else {
        dispatch(addProgramAndFeeDetails({ fee: fee, program: program }));
        setFeeId('');
        setFeeAmt('');
        setTaxes([{ id: 0, taxType: '', percent: '' }]);
      }
    }
  }

  const handleClose = () => {
    props.handleToggle()

    if (props.feeItem === null) {
      setFeeId('');
      setFeeAmt('');
      setTaxes([{ id: 0, taxType: '', percent: '' }]);
    }

  }

  return (
    <>
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container editFeePopup">
          <div className="popup-header">
            <div className="popup-heading">
              Fee Details
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={handleClose}
              />
            </div>
          </div>
          <div className="popup-body scrollbar">
            <form className=" popup-form ">
              <div className="form-grp mt-20 mb-20">
                <label htmlFor="">
                  Fee Name <span className="imp-mark">*</span>
                </label>
                {/* <ProgramDropdown /> */}
                <select id="type" className="popup-dropdown mt-5" onChange={e => setFeeId(e.target.value)} value={feeId}>
                  <option value="">Select Fee Name</option>
                  {feeTypes.length > 0 && feeTypes.map((fee) => (
                    <option key={fee.id} value={fee.id}>{fee.feeName}</option>
                  ))}
                </select>
                <span className="error-msg">Enter the field</span>
              </div>

              <div className="form-grp mb-20">
                <label htmlFor="name">
                  Fee Amount <span className="imp-mark">*</span>
                </label>
                <input
                  type="number"
                  name="feeAmt"
                  id="feeAmt"
                  value={feeAmt}
                  className="form-input"
                  placeholder="Enter Fee Amount"
                  onChange={e => setFeeAmt(e.target.value)}
                />
                <span className="error-msg">Enter the field</span>
              </div>

              {/* <div className="form-grp mb-20">
                <label htmlFor="name">
                  Due Date <span className="imp-mark">*</span>
                </label>
                <input type="date" className="form-input" />
                <span className="error-msg">Enter the field</span>
              </div> */}

              <div className="border-bottom"></div>

              {taxes.map(({ id, taxType, percent }, index) => (
                <div className="multidivs" key={id}>
                  <div className="multidivs-content">
                    <label htmlFor="title" className="create-label">
                      Tax name<span className="imp-mark">*</span>
                    </label>
                    <input
                      type="text"
                      name="taxName"
                      id={"taxName-" + id}
                      value={taxType}
                      onChange={e => setTaxName(e, id)}
                      className="create-input mt-5"
                      placeholder="Enter Tax name"
                    />
                  </div>

                  <div className="multidivs-content">
                    <label htmlFor="title" className="create-label">
                      Tax %<span className="imp-mark">*</span>
                    </label>
                    <input
                      type="number"
                      name="taxPer"
                      id={"taxPer-" + id}
                      value={percent}
                      onChange={e => setTaxPer(e, id)}
                      className="create-input mt-5"
                      placeholder="Enter Tax"
                    />
                  </div>

                  {index === 0 ? (<div className="deleteicon" onClick={onAddTax}>
                    <img src="../../assets/icons/add_circle.svg" alt="" />
                  </div>) :
                    (<div className="deleteicon" onClick={e => onDeleteTax(id)}>
                      <img src="../../assets/icons/delete_circle.svg" alt="" />
                    </div>)}
                </div>
              ))}

              {/* <div className="form-grp">
                <label className="checkmark-label pt-0" htmlFor="DailyRadio">
                  <input
                    type="checkbox"
                    name="DailyRadio"
                    id="DailyRadio"
                    className="checkmark-btn"
                    checked={isInstallment}
                    onChange={onAddInstallment}
                  />
                  <span className="checkmarkspan"></span>Add installments
                </label>
              </div> */}

              {isInstallment && (<>
                <div className="border-bottom"></div>

                {installments.length > 0 && installments.map(({ id, installmentNumber, amount, dueDate }, index) => (
                  <div className="multidivs with_3_sections" key={id}>
                    <div className="multidivs-content">
                      <label htmlFor="title" className="create-label">
                        Installment Number<span className="imp-mark">*</span>
                      </label>
                      <input
                        type="number"
                        name=""
                        onChange={e => setInstallmentNo(e, id)}
                        value={installmentNumber}
                        id=""
                        className="create-input mt-5"
                        placeholder="Enter number of Installment"
                      />
                    </div>

                    <div className="multidivs-content">
                      <label htmlFor="title" className="create-label">
                        Installment amount<span className="imp-mark">*</span>
                      </label>
                      <input
                        type="number"
                        name=""
                        id=""
                        onChange={e => setInstallmentAmt(e, id)}
                        value={amount}
                        className="create-input mt-5"
                        placeholder="Enter amount"
                      />
                    </div>

                    <div className="multidivs-content">
                      <label htmlFor="title" className="create-label">
                        Installment due date<span className="imp-mark">*</span>
                      </label>
                      <input
                        type="date"
                        name=""
                        id=""
                        value={moment(dueDate).format('YYYY-MM-DD')}
                        onChange={e => setDueDate(e, id)}
                        className="create-input mt-5"
                        placeholder="Enter due date"
                      />
                    </div>

                    {index === 0 ? (<div className="deleteicon" onClick={onAddIns}>
                      <img src="../../assets/icons/add_circle.svg" alt="" />
                    </div>) :
                      (<div className="deleteicon" onClick={e => onDeleteIns(id)}>
                        <img src="../../assets/icons/delete_circle.svg" alt="" />
                      </div>)}
                  </div>
                ))}

              </>)}

            </form>

            <div className="form-footer  multibuttons">
              <button className="primary-btn" onClick={onFormSubmit}>Save</button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default EditFeePopup;
