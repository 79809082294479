import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearState, loginUser, userSelector } from "../../reducers/userSlice";
import PageLoader from "../../components/UI/loader/PageLoader";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { COMMON_TEXT } from "../../constants/textConstant";
import ErrorToast from "../../components/Toast/ErrorToast";

function ResetPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    let isAuth = localStorage.getItem("token");
    if (isAuth & (isAuth !== "undefined")) {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isError) {
      // toast.error(errorMessage);
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      navigate("/", { replace: true });
    }
  }, [isError, isSuccess]);

  return (
    <div className="loginContainer">
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="loginForm form-1">
        <div className="loginTop form-1-top">
          <h1 className="heading-1">{COMMON_TEXT.resetAdmin}</h1>
          <p className="text-1">{COMMON_TEXT.resetAdminText}</p>
        </div>
        <div className="mt-40 loginCenter">
          <form id="login">
            <div className="form-outer">
              <div className="form-input">
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="password"
                      placeholder="Enter your Password"
                      id="name"
                      name="name"
                      className="text-3 input-box mt-5"
                    />
                    <label htmlFor="name" className="text-2 form-label">
                      {COMMON_TEXT.enterPassword}
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-30 form-input">
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Enter your Password"
                      id="pass"
                      name="pass"
                      className="text-3 input-box mt-5"
                    />
                    <label htmlFor="pass" className="text-2 form-label">
                      {COMMON_TEXT.enterCnfPassword}
                    </label>
                  </div>
                  { }{" "}
                </div>
              </div>
              <div className=" mt-100 form-input">
                <div className="">
                  <button
                    className="primary-btn btn-lg"
                  // onClick={onsubmit}
                  >
                    {COMMON_TEXT.resetText}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPage;
