import React, { useState, useEffect } from "react";
import FeeDropdown from "../../Dropdowns/FeeDropdown/FeeDropdown";
import {
  feedbackData,
  getEmailTemplate,
} from "../../../reducers/feedbackSlice";
import { useSelector, useDispatch } from "react-redux";
import MailPopup from "../../../components/Popups/MailPopup/MailPopup";
import "../FeeDuePopup/FeeDuePopupStyle.css";
import {
  COMMON_TEXT,
  EMAIL_TEMPLATE_NAME,
  EMAIL_TYPE,
  Acceptance_Letter,
} from "../../../constants/textConstant";
import {
  clearFeeDue,
  feeData,
  getCourseFeeStudentList,
  getStudentFeeList,
  postEditFeeDueAmount,
  postCreateFeeDueAmount,
} from "../../../reducers/feeSlice";
import ToastMessage from "../../Toast/ToastComponent";
import {
  applicantData,
  getWelcomeLetterStudentList,
} from "../../../reducers/applicantSlice";
import ErrorToast from "../../Toast/ErrorToast";
const EditFeeDuePopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("Select Fee Type");
  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    feedbackList,
    feedbackResponse,
    studentList,
    feedbackDetail,
    emailTemplateData,
  } = useSelector(feedbackData);
  const [feeMasterId, setFeeMasterId] = useState("");

  const { admissionAmount } = useSelector(applicantData);
  const [programId, setProgramId] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState("");

  const [feeAmount, setFeeAmount] = useState("");
  const [feeDate, setFeeDate] = useState("");
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState(false);
  const [validateDate, setDateValidation] = useState(false);

  console.log("fdfdfdfdfdf", props);

  const dispatch = useDispatch();

  const handleClose = () => {
    props.closePopup();
    setFeeMasterId("");
  };

  console.log("printing props ", props);

  const onChangeFeeType = (event) => {
    setFeeMasterId(event.target.value);
  };

  useEffect(() => {
    setFeeAmount(props?.feeAmount);
    setFeeDate("");
  }, [props?.feeAmount]);

  const updateFeeDue = () => {
    console.log("this is update fee due");

    if (feeAmount === "") {
      setErrorMessage(true);
    } else {
      const obj = {
        amount: feeAmount,
        programId: props?.programBatchId,
        feeDueId: props?.feeDueId,
        studentId: props?.studentId,
        dueOn: props?.dueDate,
        feeMasterId: feeMasterId,
      };
      console.log(obj, "send email");
      dispatch(postEditFeeDueAmount(obj));
    }
  };

  const createFeeDue = () => {
    console.log("this is create fee due");

    if (feeAmount === "" || feeDate === "") {
      setErrorMessage(true);
    } else {
      const obj = {
        amount: feeAmount,
        programId: props?.programBatchId,
        studentId: props?.studentId,
        dueOn: feeDate,
        feeDueSentBy: loggedInUser,
        feeMasterId: feeMasterId,
      };
      console.log(obj, "send email");

      dispatch(postCreateFeeDueAmount(obj));
    }
  };

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    setLoggedInUser(userName);
  }, []);

  useEffect(() => {
    if (
      props?.feeId !== "" &&
      props?.feeId !== null &&
      props?.feeId !== undefined
    ) {
      setFeeMasterId(props?.feeId);
    }
  }, [props?.feeId]);

  return (
    <>
      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Created Successfully"}
      />

      <ErrorToast
        show={showErrorMessage}
        setShow={() => setErrorMessage(false)}
        message={"Please fill all fields"}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container due-popup feeDuePopup">
          <div className="popup-heading">
            Fee Due{" "}
            <img
              src="../assets/icons/close.svg"
              alt=""
              className="close-btn"
              onClick={handleClose}
            />
          </div>

          <div className="popup-body">
            <form action="">
              <div className="form-grp">
                <label htmlFor="amount">
                  Fee Type <span className="imp-mark">*</span>
                </label>
                <select
                  id="type"
                  className="popup-dropdown mt-5"
                  onChange={onChangeFeeType}
                  value={feeMasterId}
                >
                  <option value="">Select Fee Type</option>
                  {props?.feeType.map((option, index) => (
                    <option key={option.id} value={option?.FeeMaster.id}>
                      {option?.FeeMaster.feeName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-grp mt-10">
                <label htmlFor="">
                  Fee Amount <span className="imp-mark">*</span>
                </label>

                <input
                  type="number"
                  name="feeAmount"
                  id="amount"
                  className="create-input pr-85"
                  placeholder="Enter Amount"
                  value={feeAmount}
                  onChange={(e) => setFeeAmount(e.target.value)}
                />

                <label className="gstCustom" htmlFor="amount">
                  ( Inclusive 18% GST )<span className="imp-mark">*</span>
                </label>
                <span
                  className={validateDate ? "error-msg active" : "error-msg"}
                >
                  Please enter fee amount
                </span>
              </div>

              {props?.dueType === "createFeeDue" && (
                <div className="form-grp">
                  <label htmlFor="">
                    Due Date <span className="imp-mark">*</span>
                  </label>
                  <input
                    type="date"
                    name="feeDate"
                    id="date"
                    className="create-input"
                    placeholder="Enter Amount"
                    value={feeDate}
                    onChange={(e) => setFeeDate(e.target.value)}
                  />
                  <span
                    className={validateDate ? "error-msg active" : "error-msg"}
                  >
                    Please select due date
                  </span>
                </div>
              )}
            </form>
          </div>
          <div className="popup-footer">
            {props?.dueType === "createFeeDue" && (
              <button className="primary-btn" onClick={createFeeDue}>
                Create Fee Due
              </button>
            )}
            {props?.dueType === "editFeeDue" && (
              <button className="primary-btn" onClick={updateFeeDue}>
                Update Fee Due
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFeeDuePopup;
