import React, { useEffect, useState } from "react";
import "../interviewScheduler/InterviewSchedulerStyle.css";
import "rc-time-picker-date-fns/assets/index.css";
import { Calendar } from "react-calendar";
import { useSelector, useDispatch } from "react-redux";
import {
  meetingData,
  getMentorCallInterviewSlots,
  deleteAdminInterviewSlot,
  clearState,
} from "../../../reducers/meetingSlice";
import PageLoader from "../../../components/UI/loader/PageLoader";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../../components/Layout/menu/SideMenu";

const ViewMentorAvailableSlots = () => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const { adminInterviewSlots, isFetching, isSuccess } =
    useSelector(meetingData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getMentorCallInterviewSlots(moment(selectedDate).format("YYYY-MM-DD 00:00:00"))
    );
  }, [selectedDate]);

  const handleSlotClick = (id) => {
    if (selectedSlot === id) setSelectedSlot("");
    else setSelectedSlot(id);
  };

  const handleSlotDelete = (id) => {
    const obj = {
      interviewSlotId: id,
    };
    dispatch(deleteAdminInterviewSlot(obj));
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        getMentorCallInterviewSlots(
          moment(selectedDate).format("YYYY-MM-DD 00:00:00")
        )
      );
      dispatch(clearState());
    }
  }, [isSuccess]);

  return (
    <>
      {isFetching && <PageLoader />}
      <SideMenu />
      <div className="createInterviewSlotContainerOuter">
        <div className="pageHeader">
          <div className="backBtn">
            {/* <img src="../assets/icons/back-btn.svg" alt="" /> */}
            {/* Back to Page */}
          </div>
          <div className="pageTogglers">
            <div style={{ cursor: "default" }} className="pageToggler active">
              Mentor Call Slots
            </div>
          </div>
          <div onClick={() => navigate("createMentorCallSlot")}>
            <button className="primary-btn-a">
              {" "}
              <img src="/assets/icons/pencil-line.svg" alt="" /> Set Availbility
            </button>
            {/* <PrimaryButtonImg classname={'primaryBtn'} children={'Edit Availiblity'} img={'../assets/icons/pencil-line.svg'} /> */}
          </div>
        </div>

        <div className="filterBar">
          {/* <div className="filterLeft">
                        <div className="searchBox">
                            <img src="/assets/icons/search-sm.svg" alt="" />
                            <input type="text" className="searchInput" placeholder='Search ...' />
                        </div>

                    </div> */}
          {/* <div className='filterBarRight'>
                        <button className="fiterBtn"
                        //  onClick={() => setOpenFilters(!openFilters)}
                        > <img src="/assets/icons/filter-lines.svg" alt="" /> Filters</button>
                    </div> */}
        </div>
        <div className="interviewListContainer containerNew">
          {/* <div className="emptyTableBox" style={{ display: 'none' }}>
                        <img src="/assets/img/emptyEventList.png" alt="" />
                        <h3 className="heading">Add Availiblity for Interview slot</h3>
                        <p className="text">You have to add your date and time preference to create interview slots for students to schedule interview .</p>
                    </div> */}

          <div className="selectInterviewContainerInner">
            <div className="selectInterviewContainer">
              <Calendar
                minDate={new Date()}
                onChange={setSelectedDate}
                value={selectedDate}
              />
            </div>
            <div className="selectInterviewContainer">
              <p className="month">
                {moment(selectedDate).format("MMMM YYYY")}
              </p>
              <div className="slotsOuter">
                {adminInterviewSlots?.length > 0
                  ? adminInterviewSlots?.map((item, index) => (
                      <div
                        key={index}
                        className={`slots`}
                        onClick={() => handleSlotClick(item.id)}
                      >
                        <div
                          style={{ cursor: "default" }}
                          className={`slot ${item?.isBooked ? "booked" : ""}`}
                        >
                          {item.time}
                        </div>
                        <button
                          className="primary-btn-a"
                          onClick={() => handleSlotDelete(item.id)}
                        >
                          Delete
                        </button>
                      </div>
                    ))
                  : "No available slots today"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewMentorAvailableSlots;
