import React, { useEffect, useRef, useState, createRef } from "react";
import "./MailPopup.css";
import { useDispatch } from "react-redux";
import {
  extendExpiryDate,
  feedbackData,
  publishFeedback,
  sendFeedbackFillReminder,
  sendFeedbackTestEmail,
} from "../../../reducers/feedbackSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToastMessage from "../../Toast/ToastComponent";

import { EMAIL_TYPE } from "../../../constants/textConstant";
import {
  sendAcceptanceOfferLatterEmail,
  applicantData,
  clearEmailState,
  getShortlistedApplicantList,
  editOfferLetterAmountEmail,
  clearState,
  sendWelcomeLetterMail,
  postSendOrientationMailLetter,
} from "../../../reducers/applicantSlice";
import SunEditor from "suneditor-react";
import mergeTag from "../../Editor/EditorPlugin";
import "suneditor/dist/css/suneditor.min.css";
import ContentEditable from "react-contenteditable";
import insertTextAtCursor from "insert-text-at-cursor";
import { sendFeeDueEmail } from "../../../reducers/feeSlice";

const MailPopup = (props) => {
  const [isMailSkipped, setChecked] = useState(false);
  const navigate = useNavigate();
  const emailContent = props.emailTemplateData;
  const [emailAddress, setEmailAddress] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  let ckeditor = useRef(null);
  const ref = createRef();
  const [loggedInUser, setLoggedInUser] = useState("");

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    setLoggedInUser(userName);
  }, []);

  const [showEditTemplate, setEditTemplate] = useState(false);
  const [showErroMessage, setEnableErrorMessage] = useState(false);
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [emailToken, setEmailToken] = useState(null);
  const [editorState, setEditorState] = useState("");
  const dispatch = useDispatch();
  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    successMessage,
    feedbackList,
    feedbackResponse,
    studentList,
    feedbackDetail,
    emailTemplateData,
  } = useSelector(feedbackData);
  const appData = useSelector(applicantData);

  const onClickTestEmail = () => {
    dispatch(
      sendFeedbackTestEmail({
        feedId: props.feedbackId,
        specificTemplateSubject: emailContent.templateSubject,
        specificTemplateBody: emailContent.templateBody,
        emailAddress: emailAddress,
      })
    );
  };

  console.log("checking email type", props.emailType);

  const onClickSendEmail = () => {
    if (props.emailType === EMAIL_TYPE.feeDueMail) {
      const obj = {
        specificTemplateBody: emailContent.templateBody,
        specificTemplateSubject: emailContent.templateSubject,
        approvedStudents: props.student,
        dueOn: props.feeDate,
        feeDue: props.feeAmount,
        program_name: props.programName,
        programId: props.programBatchId,
        programMasterId: props.programMasterId,
        feeDueSentBy: loggedInUser,
        skipMail: isMailSkipped,
      };
      dispatch(sendFeeDueEmail(obj));
    } else if (props.emailType === EMAIL_TYPE.welcomeLetter) {
      const obj = {
        specificTemplateBody: emailContent.templateBody,
        specificTemplateSubject: emailContent.templateSubject,
        approvedStudents: props.student,
        dueOn: props.feeDate,
        feeDue: props.feeAmount,
        program_name: props.programName,
        programId: props.programBatchId,
        programMasterId: props.programMasterId,
        welcomeLetterSentBy: loggedInUser,
        skipMail: isMailSkipped,
        isTotalSelected: props.isTotalSelected,
      };
      dispatch(sendWelcomeLetterMail(obj));
    } else if (props.emailType === EMAIL_TYPE.orientationMail) {
      const obj = {
        specificTemplateBody: emailContent.templateBody,
        specificTemplateSubject: emailContent.templateSubject,
        approvedStudents: props.student,
        dueOn: props.feeDate,
        feeDue: props.feeAmount,
        program_name: props.programName,
        programId: props.programBatchId,
        programMasterId: props.programMasterId,
        welcomeLetterSentBy: loggedInUser,
        skipMail: isMailSkipped,
        isTotalSelected: props.isTotalSelected,
        documentDate: props.documentDate,
      };
      dispatch(postSendOrientationMailLetter(obj));
    }

    // else {
    //   const obj = {
    //     specificTemplateBody: editorState,
    //     specificTemplateSubject: emailSubject,
    //     approvedStudents: props.student,
    //     dueOn: props.feeDate,
    //     feeDue: props.feeAmount,
    //     program_name: props.programName,
    //     programId: props.programBatchId,
    //     programMasterId: props.programMasterId,
    //   };
    //   console.log(obj, "obj");
    //   console.log(editorState, "editorState");
    // }
    else {
      if (props.emailType === EMAIL_TYPE.reminderFeedbackEmail) {
        dispatch(
          sendFeedbackFillReminder({
            feedId: props.feedbackId,
            specificTemplateSubject: emailContent.templateSubject,
            specificTemplateBody: emailContent.templateBody,
          })
        );
        props.handleClose();
        setEnableErrorMessage(false);
        setEnableSuccessMessage(true);
      }
      if (props.expiryDate !== undefined && props.expiryDate !== null) {
        dispatch(
          extendExpiryDate({
            feedId: props.feedbackId,
            expiryDate: props.expiryDate,
          })
        );
        props.handleClose();
        setEnableErrorMessage(false);
        setEnableSuccessMessage(true);
        return;
      }
      if (props.emailType === EMAIL_TYPE.publishEmail) {
        props.handleClose();
        setEnableErrorMessage(false);
        setEnableSuccessMessage(true);

        const obj = {
          specificTemplateBody: emailContent.templateBody,
          specificTemplateSubject: emailContent.templateSubject,
          feedId: props.feedbackId,
        };

        dispatch(publishFeedback(obj));

        // if (isSuccess) {
        //   setTimeout(() => {
        //     navigate(`/feedbackList`);
        //   }, 3000);
        // }
      } else {
        setEnableErrorMessage(true);
      }
    }
  };

  const handleEmailToken = (event) => {
    setEmailToken(event.target.value);
    insertTextAtCursor(ref.current, event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleChangeSubject = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEditorChange = (content) => {
    setEditorState(content);
  };

  const bindEmailSubject = () => {
    setEmailSubject(emailContent && emailContent.templateSubject);
  };

  useEffect(() => {
    bindEmailSubject();
  }, [emailContent && emailContent.templateSubject]);

  const sendEmailAcceptance = () => {
    if (props?.isNewOffer) {
      const obj = {
        specificTemplateBody: emailContent.templateBody,
        specificTemplateSubject: emailContent.templateSubject,
        approvedStudents: props.student,
        dueOn: props.feeDate,
        feeDue: props.feeAmount,
        program_name: props.programName,
        programId: props.programBatchId,
        programMasterId: props.programMasterId,
        offerLetterSentBy: loggedInUser,
        scholarshipPercentage: "",
        skipMail: isMailSkipped,
      };

      console.log("fdfdfdfdfdfdf", obj);
      var params = {
        searchTitle: props.searchTitle,
        emailStatus: props.emailStatus,
        approvedStatus: props.approvedStatus,
      };
      dispatch(
        sendAcceptanceOfferLatterEmail({
          obj,
          query: params,
          isTotalSelected: props.isTotalSelected,
        })
      );

      // dispatch(
      //   sendOfferLetterApplicantEmailToVendor({
      //     obj,
      //     query: params,
      //     isTotalSelected: props.isTotalSelected,
      //   })
      // );

      console.log("inside new offer letter");
    } else {
      console.log("inside edit offer letter");

      const obj = {
        specificTemplateBody: emailContent.templateBody,
        specificTemplateSubject: emailContent.templateSubject,
        amount: props.feeAmount,
        program_name: props.programName,
        programId: props.programBatchId,
        programMasterId: props.programMasterId,
        feeDueId: props?.feeDueId,
        applicantId: props?.applicantId,
        offerLetterSentBy: loggedInUser,
        scholarshipPercentage: "",
        skipMail: isMailSkipped,
      };
      console.log("fdfdfdfdfdfdf", obj);
      dispatch(editOfferLetterAmountEmail(obj));
    }
  };

  useEffect(() => {
    if (appData.sendAcceptanceEmail) {
      props.handleClose();
      dispatch(clearEmailState());
      props?.setPageNo("1");

      //     // let obj = {
      //     //   programBatchId: props.programBatchId,
      //     // };
      //     // var params = {
      //     //   searchTitle: props.searchTitle,
      //     //   emailStatus: props.emailStatus,
      //     //   approvedStatus: props.approvedStatus,
      //     //   pageNo: props.pageNo,
      //     //   pageSize: props.pageSize,
      //     // };

      //     // dispatch(getShortlistedApplicantList({ obj, params }));
    }
  }, [appData.sendAcceptanceEmail]);

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Email Sent Successfully"}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div
          className={`popup-container mail-popped withedit ${
            showEditTemplate ? "active" : ""
          } `}
        >
          <div className="popup-header">
            <div className="popup-heading">
              Edit Email
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>

            <div className="popup-header-body ">
              <div className="mail-head-shell">
                <div className="mail-head-shell-inside">
                  <label htmlFor="questions" className="text-5 mb-5 dis-block">
                    Email Subject
                  </label>

                  <ContentEditable
                    style={{
                      border: "1px solid #cccccc",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                    innerRef={ref}
                    html={emailSubject}
                    disabled={false}
                    onChange={handleChangeSubject}
                  />
                  {/* <input type="text" value={emailSubject} onChange={handleChangeSubject} /> */}
                  {/* <select id="type" className="popup-dropdown">
                    <option value="hide">Feedback Type</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select> */}
                </div>

                <div className="mail-head-shell-inside">
                  <label htmlFor="questions" className="text-5 mb-5 dis-block">
                    Add Token To Subject
                  </label>
                  <select
                    id="type"
                    onChange={handleEmailToken}
                    className="popup-dropdown"
                  >
                    <option value="hide">Select token</option>
                    <option value="{candidate_name}">Candidate Name</option>
                    <option value="{feedback_name}">Feedback Name</option>
                    <option value="{feedback_type}">Feedback Type</option>
                    <option value="{feedback_expiryDate}">
                      Feedback Expiry Date
                    </option>
                  </select>
                </div>

                {/* <div className="mail-head-shell-inside mt-30">
                  <label htmlFor="questions" className="text-5 mb-5 dis-block">
                    Add Token To Email Body
                  </label>
                  <select
                    id="type"
                    onChange={handleEmailToken}
                    className="popup-dropdown"
                  >
                    <option value="hide">Select token</option>
                    <option value="{candidate_name}">Candidate Name</option>
                    <option value="{feedback_name}">Feedback Name</option>
                    <option value="{feedback_type}">Feedback Type</option>
                    <option value="{feedback_expiryDate}">
                      Feedback Expiry Date
                    </option>
                  </select>
                </div> */}
              </div>
            </div>
          </div>

          <div className="popup-body scrollbar">
            <div className="mail-head-shell-inside width-100 mt-10">
              <span className="text-5 mb-5 dis-block">Email Body</span>
              <SunEditor
                // setContents="<table  cellpadding='0' cellspacing='0' data-end-index='1624' data-start-index='1413' style='max-width:540px;margin:0 auto;border:1px solid #333;font-family:Arial,Helvetica,sans-serif;color:#333333;margin-top:50px;margin-bottom:50px' width='100%'><tbody data-end-index='1631' data-start-index='1624'><tr data-end-index='1635' data-start-index='1631'><td data-end-index='1718' data-start-index='1635' style='padding:40px 20px;padding-top:30px;background:#fffdf5;padding-bottom:0'><img alt='Header' className='CToWUd' data-end-index='2026' data-start-index='1718' src='https://ci4.googleusercontent.com/proxy/zT8K4Uy3Q38G63-bdYEzSdVN011QfA15nNIWgzwutoJPGjoJMrvPfgROoYhqGruzR7_Vhf1S1Wh4H5IU1xuHIX_I0vBZBZoA2sR6K-D-kssC--1rzqTwWBiwaJw=s0-d-e1-ft#https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/mu-logo.jpg' style='width:150px' /></td></tr><tr data-end-index='2040' data-start-index='2036'><td data-end-index='2103' data-start-index='2040' style='padding:40px 20px;padding-top:0;background:#fffdf5'>    <p data-end-index='2167' data-start-index='2103' style='margin-bottom:0;display:inline-block;margin-top:40px'>Dear {candidate_name},</p><br /> Please share your feedback on the session:<br /><a href='{link}' style='color: #f12a2a!important; text-decoration:none;'>Click Here</a><table  cellpadding='3' cellspacing='0' style='width:600px'><tbody><tr><td style='vertical-align:middle; width:70%'><strong>Feedback Name:</strong></td><td>{feedback_name}</td></tr><tr><td><strong>Feedback Type:</strong></td><td>{feedback_type}</td></tr><tr><td><strong>Expiry Date:</strong></td><td>{feedback_expiryDate}</td></tr></tbody></table><br /> We really value your views.Thank you for taking time out to do this.<br /><br />Best Regards<br />Academic Services<p>&nbsp;</p></td></tr></tbody></table>"
                setContents={emailContent && emailContent.templateBody}
                showToolbar={true}
                onChange={handleEditorChange}
                setDefaultStyle="height: auto"
                setOptions={{
                  plugins: [mergeTag],
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "list",
                      "align",
                      "fontSize",
                      "formatBlock",
                      "table",
                      "fullScreen",
                      "preview",
                      "codeView",
                    ],
                    [
                      {
                        name: "merge_tag",
                        dataCommand: "merge_tag",
                        buttonClass: "",
                        title: "Add Token",
                        dataDisplay: "submenu",
                        innerHTML: "<span>Token</span>",
                      },
                    ],
                  ],
                }}
              />
              {/* <CKEditor
                editor={ClassicEditor}
                ref={ckeditor}
                data={emailContent && emailContent.templateBody}
                // onInit={(editor) =>
                //   console.log("Editor is ready to use!", editor)
                // }
                onReady={(editor) => {
                  console.log("Editor is ready to use!", editor);
                  editor.model.change((writer) => {
                    const position =
                      editor.model.document.selection.getFirstPosition();
                    writer.insertText("reerrerereerererer", position, "after");
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log({ event, editor, data });
                  editor.model.change((writer) => {
                    const position =
                      editor.model.document.selection.getFirstPosition();
                    writer.insertText(emailToken, position, "after");
                  });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);

                  // editor.model.change((writer) => {
                  //   const position =
                  //     editor.model.document.selection.getFirstPosition();
                  //   writer.insertText(emailToken, position, "after");
                  // });
                }}
                // onFocus={(event, editor) => {
                //   console.log("Focus.", editor);
                // }}
              /> */}
              {/* <img src="../../assets/img/text-editor.png" alt="" /> */}
            </div>
            {/* <div className="test-mail-shell">
              <span className="text-5 mt-30 dis-block">Send Test Email</span>
              <div className="test-mail mt-5">
                <input
                  onChange={handleChangeEmail}
                  type="email"
                  placeholder="Enter Test Email Id"
                />
                <button
                  onClick={onClickTestEmail}
                  className="secondary-btn width-auto"
                >
                  Test Mail
                </button>
              </div>
            </div> */}
          </div>

          <div className="popup-footer mail-popup-footer">
            <button
              className="danger-btn width-auto"
              onClick={() => setEditTemplate(false)}
            >
              Preview
            </button>
            {/* <button
              className="danger-btn width-auto"
              onClick={onClickSendEmail}
            >
              Send Mail
            </button> */}
            {props.emailType === "acceptanceLetter" ? (
              <button
                onClick={sendEmailAcceptance}
                className="danger-btn width-auto"
              >
                Send
              </button>
            ) : (
              <button
                onClick={onClickSendEmail}
                className="danger-btn width-auto"
              >
                Send Mail
              </button>
            )}
          </div>
        </div>

        <div
          className={`popup-container mail-popped ${
            !showEditTemplate ? "active" : ""
          }`}
        >
          <div className="popup-header">
            <div className="popup-heading">
              Email Preview
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>

            <div className="popup-header-body">
              <div className="mail-head-shell">
                <div className="mail-head-shell-inside">
                  <span className="text-1">Subject</span>
                  <p className="text-1-bold mt-10">
                    {emailSubject}
                    {/* {emailContent && emailContent.templateSubject} */}
                  </p>
                </div>

                <div className="mail-head-shell-inside">
                  <span className="text-1">Send To</span>
                  <p className="text-1-bold mt-10">
                    {props?.isNewOffer === false ? (
                      "1 Student"
                    ) : (
                      <>
                        {props.isTotalSelected
                          ? `${props.shortListCount} Students`
                          : props.studentCount > 1
                          ? `${props.studentCount} Students`
                          : `${props.studentCount} Student`}
                      </>
                    )}
                  </p>
                </div>

                <div className="mail-head-shell-inside width-100 mt-50">
                  <span className="text-1">Email Body</span>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-body only-img scrollbar ckeditorPopupOutr">
            <div
              dangerouslySetInnerHTML={{
                // __html: editorState,
                __html: emailContent && emailContent.templateBody,
              }}
            />

            {/* <img src="../../assets/img/demomail.png" alt="" /> */}
          </div>

          {showErroMessage && <span>Please select students first</span>}

          <div className="popup-footer mail-popup-footer">
            <label
              htmlFor="skip-offer"
              className="checkbox-label skip-mail-offerletter"
            >
              <input
                id="skip-offer"
                className="checkmark-btn"
                type="checkbox"
                defaultChecked={isMailSkipped}
                onChange={() => setChecked(!isMailSkipped)}
              />
              <span class="checkmarkspan"></span>
              Skip Mail
            </label>
            {/* <button
              onClick={() => setEditTemplate(!showEditTemplate)}
              className="secondary-btn mr-15"
            >
              Edit
            </button> */}
            {props.emailType === "acceptanceLetter" ? (
              <button
                onClick={sendEmailAcceptance}
                className="danger-btn width-auto"
              >
                Send
              </button>
            ) : (
              <button
                onClick={onClickSendEmail}
                className="danger-btn width-auto"
              >
                Send Mail
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MailPopup;
