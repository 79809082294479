import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import * as moment from "moment";
import "./CreateFeedbackStyle.css";
import PageLoader from "../../../components/UI/loader/PageLoader";

import {
  clearState,
  createFeedback,
  feedbackData,
  getFeedbackDetailById,
  getAllStudent,
  editFeedback,
  clearDataResponse,
  getAllProgramsDetails,
  getAllBatchByProgramId,
} from "../../../reducers/feedbackSlice";
import EditQuestion from "../../../components/EditQuestion/EditQuestion/EditQuestion";
import EveryQuestionMenu from "../../../components/EditQuestion/EveryQuestionMenu/EveryQuestionMenu";
import Paragraph from "../../../components/AnswersComponents/Paragraph/Paragraph";
import RatingBox from "../../../components/AnswersComponents/Rating/RatingBox";
import UploadBox from "../../../components/AnswersComponents/UploadBox/UploadBox";
import SingleSelect from "../../../components/AnswersComponents/SingleSelect/SingleSelect";
import MultiSelect from "../../../components/AnswersComponents/MultiSelect/MultiSelect";
import $ from "jquery";

import { setQuestionType } from "../../../reducers/pageLayoutSlice";
import { useNavigate, useParams } from "react-router-dom";
import { COMMON_TEXT } from "../../../constants/textConstant";
import DropdownLayout from "../../../components/Dropdowns/DropdownLayout/DropdownLayout";
import GoBackPopup from "../../../components/Popups/GoBackPopup/GoBackPopup";

export default function CreateFeedback(props) {
  const params = useParams();
  console.log("inside props", params);
  const mFeedbackId = params?.id;
  const navigate = useNavigate();
  const [selectedLayout, setSelectedLayout] = useState({
    type: "Card Style",
  });
  const [questionDetail, setQuestionDetail] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [batch, setBatch] = useState([]);
  const [studentCount, setStudentCount] = useState();

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    feedbackList,
    feedbackResponse,
    studentList,
    batchDetail,
    programDetails,
    feedbackDetail,
    totalStudentsCount,
    createdSuccess,
  } = useSelector(feedbackData);

  useEffect(() => {
    dispatch(getAllProgramsDetails());
  }, []);
  const [feedbackId, setFeedbackId] = useState("");
  const firstRef = useRef("first");
  const [pageLayout, setPageLayout] = useState(COMMON_TEXT.multipage);

  const array = [];
  const [questionArray, setNewQuestion] = useState(array);

  const refs = questionArray.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});
  console.log("checking change question data", questionArray);
  const moveTop = (id) => {
    console.log(id);
    document.querySelectorAll("div.inside-main").classList.add("active");
  };

  const dispatch = useDispatch();
  const [allChecked, setAllChecked] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [floatMenu, setFloatingMenu] = useState(false);
  const [isEditQuestion, setIsEditQuestion] = useState(false);

  /**Setting Form Variable */
  const [feedbackTitle, setFeedbackTitle] = useState("");
  const [feedbackDescription, setFeedbackDesc] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [feedbackDate, setFeedbackDate] = useState("");
  const [feedbackExpiryTime, setFeedbackExpiryTime] = useState("");
  const [active, setActive] = useState(0);
  const [feedbackDetails, setFeedbackDetails] = useState({});

  /**Setting Form Validation */
  const [validateTitle, setTitleValidation] = useState(false);
  const [validateDescription, setDescValidation] = useState(false);
  const [validateFeedbackType, setFeedbackTypeValidation] = useState(false);
  const [validateAnonymous, setAnonymousValidation] = useState(false);
  const [validateDate, setDateValidation] = useState(false);
  const [validateTime, setTimeValidation] = useState(false);

  // Students Filters

  // Students Filters

  const [programName, setProgramName] = useState("");
  const [batchName, setBatchName] = useState("");
  const [pageSize, setPageSize] = useState("10");
  const [searchTitle, setSearchTitle] = useState("");
  const [pageNo, setPageNo] = useState("1");

  const [isTotalSelected, setTotalSelected] = useState(false);

  useEffect(() => {
    var params = {
      searchTitle: searchTitle,
      programName: programName,
      batchName: batchName,
      pageSize: pageSize,
      pageNo: pageNo,
    };

    dispatch(getAllStudent(params));
  }, [programName, batchName, pageSize, pageNo]);
  useEffect(() => {
    setPrograms(programDetails);
  }, [programDetails]);

  useEffect(() => {
    setBatch(batchDetail);
  }, [batchDetail]);

  useEffect(() => {
    setStudentCount(totalStudentsCount);
  }, [totalStudentsCount]);

  useEffect(() => {
    checkAllChecked();
  }, [studentList]);

  useEffect(() => {
    setPageNo("1");
  }, [pageSize]);

  console.log("******* checking is Success result ********", createdSuccess);

  const changeContainer = (show, hide1, hide2) => {
    $(`#btn-${show}`).addClass("active");
    $(`#btn-${hide1}`).removeClass("active");
    $(`#btn-${hide2}`).removeClass("active");

    $(`#container-${show}`).addClass("show");
    $(`#container-${hide1}`).removeClass("show");
    $(`#container-${hide2}`).removeClass("show");
  };

  const handleAddQuestionClick = () => {
    setFloatingMenu(!floatMenu);
  };
  const showEditQuestion = (type, showEditScreen) => {
    setIsEditQuestion(showEditScreen);
    dispatch(setQuestionType(type));
  };

  const onFeedbackTitleChange = (event) => {
    setFeedbackTitle(event.target.value);
  };

  const onFeedbackDescChange = (event) => {
    setFeedbackDesc(event.target.value);
  };

  const onChangeFeedbackType = (event) => {
    setFeedbackType(event.target.value);
  };

  const isAnonymousChange = (event) => {
    const isAnonymousFeedback = event.target.value === "true" ? true : false;

    setIsAnonymous(isAnonymousFeedback);
  };

  const onDateChange = (event) => {
    setFeedbackDate(event.target.value);
  };

  const onChangeTime = (event) => {
    setFeedbackExpiryTime(event.target.value);
  };

  const onProgramChange = (event) => {
    let programId = event.target.value;
    setProgramName(programId);
    if (programId === "") {
      setBatchName("");
    }
    dispatch(getAllBatchByProgramId(programId));
  };

  const onBatchChange = (e) => {
    let batchId = e.target.value;
    setBatchName(batchId);
  };

  const onPageSizeChange = (e) => {
    let size = e.target.value;
    setPageSize(size);
  };

  const setNextPage = () => {
    if (pageNo == Math.ceil(totalStudentsCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
  };

  const setPreviousPage = () => {
    if (pageNo == "1") {
      return;
    }
    setPageNo((prev) => --prev);
  };

  const onTitleSearch = (title) => {
    setSearchTitle(title);
    var params = {
      searchTitle: title,
      programName: programName,
      batchName: batchName,
      pageSize: pageSize,
      pageNo: pageNo,
    };

    dispatch(getAllStudent(params));
  };

  const onSearchClick = () => {
    onTitleSearch(searchTitle);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onTitleSearch(e.target.value);
    }
  };

  const onSearchChange = (e) => {
    setSearchTitle(e.target.value);
  };

  const handleAllCheck = (e) => {
    let currStdIds = studentList.map((data) => data.id);
    const currSet = new Set(currStdIds);
    if (allChecked) {
      setTotalSelected(false);
      setAllChecked(false);
      let newArr = isChecked.filter((val) => !currSet.has(val));
      return setIsChecked(newArr);
    }
    setAllChecked(true);
    let newIsChecked = [...isChecked];
    currStdIds.map((val) => {
      if (!newIsChecked.includes(val)) {
        newIsChecked.push(val);
      }
    });
    return setIsChecked(newIsChecked);
  };

  const handleSingleCheck = (e) => {
    const id = e.target.id;
    console.log(id);
    if (isChecked.includes(id)) {
      setIsChecked(isChecked.filter((checked_id) => checked_id !== id));
      setTotalSelected(false);
      return setAllChecked(false);
    }
    isChecked.push(id);
    setIsChecked([...isChecked]);
    checkAllChecked();
  };

  useEffect(() => {
    if (createdSuccess) {
      navigate(`/feedbackList`);
      dispatch(clearDataResponse());
    } else if (isSuccess) {
      // navigate(`/feedbackList`);
      dispatch(clearDataResponse());
    }
  }, [createdSuccess, isSuccess]);

  const checkAllChecked = () => {
    let stdIds = studentList.map((item) => item.id);
    let isAll = stdIds.every((val) => isChecked.includes(val));
    if (!isAll) {
      setTotalSelected(false);
    }
    setAllChecked(isAll);
  };

  const selectTotalSelected = () => {
    setTotalSelected((prev) => !prev);
  };

  const clearSelected = () => {
    setTotalSelected((prev) => !prev);
    setIsChecked([]);
    setAllChecked(false);
  };

  const handleOnSaveAsDraft = (e) => {
    e.preventDefault();
    var params = {
      searchTitle: searchTitle,
      programName: programName,
      batchName: batchName,
    };

    let feedbackObj = {
      isTotalSelected: isTotalSelected,
      query: params,
      title: feedbackTitle,
      description: feedbackDescription,
      feedbackType: feedbackType,
      isAnonymous: isAnonymous,
      expiryDate: feedbackDate,
      expiryTime: feedbackExpiryTime,
      organizationId: null,
      adminId: null,
      userId: null,
      questions: questionArray,
      previewType: pageLayout,
      layoutType: "card-style",
      studentsId: isChecked,
      feedId: feedbackId,
      status: "draft",
      isExpired: false,
    };
    console.log(feedbackObj);
    if (feedbackTitle && feedbackType && feedbackDate && feedbackExpiryTime) {
      if (mFeedbackId === "018-42c1a8c4-c2eaf774e0-a125f3845") {
        dispatch(createFeedback(feedbackObj));
        console.log("inside create new feedback");
      } else {
        console.log("inside edit feedback");
        dispatch(editFeedback(feedbackObj));
      }
    } else {
      if (feedbackTitle === "") {
        setTitleValidation(true);
      } else {
        setTitleValidation(false);
      }

      if (feedbackType === "") {
        setFeedbackTypeValidation(true);
      } else {
        setFeedbackTypeValidation(false);
      }

      if (feedbackDate === "") {
        setDateValidation(true);
      } else {
        setDateValidation(false);
      }

      if (feedbackExpiryTime === "") {
        setTimeValidation(true);
      } else {
        setTimeValidation(false);
      }
    }
  };

  const handleOnPressNext = (e) => {
    e.preventDefault();
    let feedbackObj = {
      title: feedbackTitle,
      description: feedbackDescription,
      feedbackType: feedbackType,
      isAnonymous: isAnonymous,
      expiryDate: feedbackDate,
      expiryTime: feedbackExpiryTime,
      organizationId: null,
      adminId: null,
      userId: null,
    };
    console.log("inside handleOnPressNext", feedbackObj);

    if (feedbackTitle && feedbackType && feedbackDate && feedbackExpiryTime) {
      changeContainer(2, 1, 3);
      // console.log(feedbackObj);
      setFeedbackDetails(feedbackObj);
      // dispatch(createFeedback(feedbackObj));

      console.log("inside if conditon");
    } else {
      console.log("inside else conditon");
      if (feedbackTitle == "" || feedbackTitle == null) {
        setTitleValidation(true);
      } else {
        setTitleValidation(false);
      }

      if (feedbackType == "" || feedbackType == null) {
        setFeedbackTypeValidation(true);
      } else {
        setFeedbackTypeValidation(false);
      }

      if (
        feedbackDate == "" ||
        feedbackDate == null ||
        feedbackDate == "Invalid date"
      ) {
        setDateValidation(true);
      } else {
        setDateValidation(false);
      }

      if (feedbackExpiryTime == "" || feedbackExpiryTime == null) {
        setTimeValidation(true);
      } else {
        setTimeValidation(false);
      }
    }
  };

  const scrollTo = (id) => {
    if (id == "first") {
      firstRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      return;
    }
    refs[id].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const scrollToNext = (id) => {
    if (questionArray.length + 1 !== id) {
      refs[id].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const onClickPreviewListItem = (id, questionType) => {
    if (questionType === "rating") {
      showEditQuestion(
        {
          type: "rating",
          image:
            "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/rating.svg",
        },
        true
      );
    } else if (questionType === "paragraph") {
      showEditQuestion(
        {
          type: "paragraph",
          image:
            "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/paragraph.svg",
        },
        true
      );
    } else if (questionType === "multiSelect") {
      showEditQuestion(
        {
          type: "multiSelect",
          image:
            "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/multiSelect.svg",
        },
        true
      );
    } else if (questionType === "singleSelect") {
      showEditQuestion(
        {
          type: "singleSelect",
          image:
            "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/singleSelect.svg",
        },
        true
      );
    } else {
      showEditQuestion(
        {
          type: "uploadMedia",
          image:
            "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/uploadMedia.svg",
        },
        true
      );
    }

    var newArray = questionArray.filter(function (el) {
      return el.id === id;
    });
    console.log("ojfoejf efe fe", newArray);
    setQuestionDetail(newArray);
    console.log("questionDetail", questionDetail);
  };

  useEffect(() => {
    setFeedbackId(mFeedbackId);
  }, [mFeedbackId]);

  console.log("fdfdfdfdfdfdf", params?.id);

  useEffect(() => {
    console.log("printint feedback id:", mFeedbackId);
    if (mFeedbackId === "018-42c1a8c4-c2eaf774e0-a125f3845") {
      console.log("/////////////////////");
    } else {
      console.log("inside else main condition");
      console.log("**********************");
      dispatch(getFeedbackDetailById(mFeedbackId));
    }
  }, [mFeedbackId]);

  console.log(isSuccess, "checkign responses", createdSuccess);

  useEffect(() => {
    if (feedbackDetail !== null) {
      console.log("inside new else as well data");
      setFeedbackTitle(feedbackDetail && feedbackDetail?.title);
      setFeedbackDesc(feedbackDetail && feedbackDetail?.description);
      setFeedbackType(feedbackDetail && feedbackDetail?.feedbackType);
      setIsAnonymous(feedbackDetail && feedbackDetail?.isAnonymous);

      var expiryDate = feedbackDetail && feedbackDetail?.expiryDate;
      const newDate = moment(expiryDate).format("YYYY-MM-DD");
      setFeedbackDate(newDate);
      setFeedbackExpiryTime(feedbackDetail && feedbackDetail?.expiryTime);

      var array = [];

      feedbackDetail &&
        feedbackDetail?.FeedbackQuestions.forEach((element, index) => {
          var item = {
            id: index + 1,
            questionType: element.questionType,
            questionName: element.questionName,
            isMandatory: element.isMandatory,
            option: element.FeedbackQuestionOptions,
          };

          array.push(item);
        });
      setNewQuestion(array);

      let stdIds = [];
      feedbackDetail &&
        feedbackDetail?.FeedbackStudents.forEach((element, index) => {
          stdIds.push(element.studentId);
        });

      setIsChecked(stdIds);
      checkAllChecked();
    }
  }, [feedbackDetail]);

  console.log("just checking feedback detail", feedbackDetail);

  // console.log("fgdgggddgdg", questionArray);

  const [isGoBackOpen, setGoBackPopupOpen] = useState(false);

  const toggleGoBackPopup = () => {
    setGoBackPopupOpen(!isGoBackOpen);
    dispatch(clearState());
  };

  const changePageLayout = (previewType) => {
    setPageLayout(previewType);
  };

  // const disablePastDate = () => {
  //   const today = new Date();
  //   const dd = String(today.getDate() + 1).padStart(2, "0");
  //   const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  //   const yyyy = today.getFullYear();
  //   return yyyy + "-" + mm + "-" + dd;
  // };

  return (
    <React.Fragment>
      {isFetching && <PageLoader />}
      <SideMenu />

      <GoBackPopup
        handleCloseGoBack={toggleGoBackPopup}
        show={isGoBackOpen}
        navigateTo="/feedbackList"
      />

      <div className="main-body">
        <div className="page-header create-header">
          <button onClick={toggleGoBackPopup} className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            {COMMON_TEXT.goBack}
          </button>
          <div>
            <button
              className="primary-clean active"
              id="btn-1"
              onClick={() => changeContainer(1, 2, 3)}
            >
              Create a Feedback
            </button>
            <button
              className="primary-clean"
              id="btn-2"
              onClick={() => changeContainer(2, 1, 3)}
              // onClick={handleOnPressNext}
            >
              Add Questions
            </button>
            <button
              className="primary-clean"
              id="btn-3"
              onClick={() => changeContainer(3, 2, 1)}
            >
              Add student
            </button>
          </div>
          <button className="green-btn-outline" onClick={handleOnSaveAsDraft}>
            <div
              className={`${!isFetching ? "active-btn" : ""}`}
              style={{ display: "none" }}
            >
              <p>Save as Draft</p>
            </div>

            <div
              className={`${isFetching ? "active-btn" : ""}`}
              style={{ display: "none" }}
            >
              <img
                src="../assets/icons/saving.svg"
                alt=""
                className="rotate-img"
              />
              <p>Saving...</p>
            </div>
            {/* <div
              className={`${
                isFetching == false && isSuccess == true ? "active-btn" : ""
              }`}
              style={{ display: "none" }}
            >
              <img src="../assets/icons/saved.svg" alt="" />
              <p>Saved...</p>
            </div> */}
          </button>
        </div>
        {/* CREATE CONTAINER STARTS */}
        <div
          className=" scrollbar show"
          id="container-1"
          style={{ display: "none" }}
        >
          <div className="create-container">
            <form action="">
              <div className="form-group mt-20">
                <label htmlFor="title" className="create-label">
                  Feedback Title <span className="imp-mark">*</span>
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  className="create-input mt-5"
                  placeholder="Write a title"
                  value={feedbackTitle}
                  onChange={onFeedbackTitleChange}
                />
                <span
                  className={validateTitle ? "error-msg active" : "error-msg"}
                >
                  Enter the field
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="title" className="create-label">
                  Description
                </label>
                <textarea
                  type="textarea"
                  name=""
                  id=""
                  className="create-input mt-5"
                  placeholder="Write a Description"
                  rows={5}
                  cols={5}
                  maxLength="500"
                  value={feedbackDescription}
                  onChange={onFeedbackDescChange}
                />
              </div>
              <div className="form-group mt-15">
                <label htmlFor="title" className="create-label">
                  Feedback Type <span className="imp-mark">*</span>
                </label>
                <div
                  onChange={onChangeFeedbackType}
                  className="radio-group mt-10"
                >
                  <label
                    className={
                      active == 0 ? "create-label active" : "create-label"
                    }
                    onClick={() => setActive(0)}
                    htmlFor="className"
                  >
                    <input
                      className="radio-btn"
                      type="radio"
                      value="Class"
                      name="semester"
                      checked={feedbackType === "Class"}
                      id="className"
                    />
                    <span className="radiomark" />
                    Class
                  </label>
                  <label
                    className={
                      active == 1 ? "create-label active" : "create-label"
                    }
                    onClick={() => setActive(1)}
                    htmlFor="General"
                  >
                    <input
                      className="radio-btn"
                      type="radio"
                      value="General"
                      name="semester"
                      checked={feedbackType === "General"}
                      id="General"
                    />
                    <span className="radiomark" />
                    General
                  </label>
                  <label
                    className={
                      active == 2 ? "create-label active" : "create-label"
                    }
                    onClick={() => setActive(2)}
                    htmlFor="Event"
                  >
                    <input
                      className="radio-btn"
                      type="radio"
                      value="Event"
                      name="semester"
                      checked={feedbackType === "Event"}
                      id="Event"
                    />
                    <span className="radiomark" />
                    Event
                  </label>
                  <label
                    htmlFor="Session"
                    className={
                      active == 3 ? "create-label active" : "create-label"
                    }
                    onClick={() => setActive(3)}
                  >
                    <input
                      className="radio-btn"
                      type="radio"
                      value="Session"
                      name="semester"
                      checked={feedbackType === "Session"}
                      id="Session"
                    />
                    <span className="radiomark" />
                    Session
                  </label>
                  <label
                    htmlFor="Others"
                    className={
                      active == 4 ? "create-label active" : "create-label"
                    }
                    onClick={() => setActive(4)}
                  >
                    <input
                      className="radio-btn"
                      type="radio"
                      value="Others"
                      name="semester"
                      checked={feedbackType === "Others"}
                      id="Others"
                    />
                    <span className="radiomark" />
                    Others
                  </label>
                </div>
                <span
                  className={
                    validateFeedbackType ? "error-msg active" : "error-msg"
                  }
                >
                  select any option
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="title" className="create-label">
                  Do You Want To Allow Anonymous Feedback?{" "}
                  <span className="imp-mark">*</span>
                </label>
                <div onChange={isAnonymousChange} className="radio-group mt-10">
                  <label className="create-label">
                    <input
                      className="radio-btn"
                      type="radio"
                      value="true"
                      checked={isAnonymous === true}
                    />
                    <span className="radiomark" />
                    Yes
                  </label>
                  <label className="create-label">
                    <input
                      className="radio-btn"
                      type="radio"
                      value="false"
                      checked={isAnonymous === false}
                    />
                    <span className="radiomark" />
                    No
                  </label>
                </div>
                <span
                  className={
                    validateAnonymous ? "error-msg active" : "error-msg"
                  }
                >
                  select any option
                </span>
              </div>
              <div className="form-group">
                <div className="date-time">
                  <div className="date-sec">
                    <label htmlFor="title" className="create-label">
                      Set expiry date of this Feedback{" "}
                      <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="date"
                      name=""
                      min={new Date().toISOString().split("T")[0]}
                      id=""
                      className="create-input mt-5"
                      value={feedbackDate}
                      onChange={onDateChange}
                    />

                    <span
                      className={
                        validateDate ? "error-msg active" : "error-msg"
                      }
                    >
                      Enter the date
                    </span>
                  </div>
                  <div className="time-sec">
                    <label htmlFor="title" className="create-label">
                      Expiry Time<span className="imp-mark">*</span>
                    </label>
                    <input
                      type="time"
                      name=""
                      id=""
                      className="create-input mt-5"
                      value={feedbackExpiryTime}
                      onChange={onChangeTime}
                    />
                    <span
                      className={
                        validateTime ? "error-msg active" : "error-msg"
                      }
                    >
                      Enter the time
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-footer mt-30">
                <button onClick={handleOnPressNext} className="primary-btn">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* CREATE CONTAINER ENDS */}

        {/* QUESTIONS CONTAINER STARTS */}
        <div id="container-2" style={{ display: "none" }}>
          <div className="question-container">
            <div className="inpage-side-menu scrollbar">
              <div
                className="shell-menu"
                onClick={() => {
                  if (floatMenu == true) setFloatingMenu(false);
                }}
              >
                <div className="menu-top">
                  <p className="text-2">Page Layout</p>
                  <div className="page-butons">
                    <div
                      onClick={() => changePageLayout(COMMON_TEXT.singlePage)}
                      className="tooltip"
                    >
                      <img
                        src={
                          pageLayout === COMMON_TEXT.singlePage
                            ? "../assets/icons/one_page_blue.svg"
                            : "../assets/icons/one_page.svg"
                        }
                        className="tooltip"
                      />
                      <span className="tooltiptext">One Page</span>
                    </div>
                    <div
                      onClick={() => changePageLayout(COMMON_TEXT.multipage)}
                      className="tooltip"
                    >
                      <img
                        src={
                          pageLayout === COMMON_TEXT.multipage
                            ? "../assets/icons/multiple_page_blue.svg"
                            : "../assets/icons/multiple_page.svg"
                        }
                        className="tooltip"
                      />
                      <span className="tooltiptext">Multi Page</span>
                    </div>
                  </div>
                </div>

                <div className="sec-2">
                  <div className="sec-2-inner">
                    <label htmlFor="layout" className="text-6">
                      {" "}
                      Select Layout
                    </label>
                    <DropdownLayout
                      selectedLayout={selectedLayout}
                      setSelectedLayout={setSelectedLayout}
                      name="layout"
                    />
                  </div>
                </div>

                <div className="step-home" onClick={() => scrollTo("first")}>
                  <p className="text-3">Home page</p>
                </div>
                <EveryQuestionMenu
                  scrollTo={scrollTo}
                  questionArray={questionArray}
                  showEditQuestion={showEditQuestion}
                  setQuestionDetail={setQuestionDetail}
                  setNewQuestion={setNewQuestion}
                />
                <div className="add-question" onClick={handleAddQuestionClick}>
                  <div className="red-question with-icon">
                    <img src="../assets/icons/plus-red.svg" alt="" /> Add
                    Question
                  </div>

                  <div
                    className={
                      floatMenu ? "floating-menu show" : "floating-menu"
                    }
                    id="floting-menu"
                  >
                    <ul>
                      <li
                        key={0}
                        onClick={() =>
                          showEditQuestion(
                            {
                              type: "rating",
                              image:
                                "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/rating.svg",
                            },
                            true
                          )
                        }
                      >
                        <img
                          src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/rating.svg"
                          alt=""
                        />{" "}
                        Rating
                      </li>
                      <li
                        key={1}
                        onClick={() =>
                          showEditQuestion(
                            {
                              type: "paragraph",
                              image:
                                "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/paragraph.svg",
                            },
                            true
                          )
                        }
                      >
                        <img
                          src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/paragraph.svg"
                          alt=""
                        />{" "}
                        Paragraph
                      </li>
                      <li
                        key={2}
                        onClick={() =>
                          showEditQuestion(
                            {
                              type: "multiSelect",
                              image:
                                "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/multiSelect.svg",
                            },
                            true
                          )
                        }
                      >
                        <img
                          src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/multiSelect.svg"
                          alt=""
                        />{" "}
                        Multi Select
                      </li>
                      <li
                        key={3}
                        onClick={() =>
                          showEditQuestion(
                            {
                              type: "singleSelect",
                              image:
                                "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/singleSelect.svg",
                            },
                            true
                          )
                        }
                      >
                        <img
                          src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/singleSelect.svg"
                          alt=""
                        />{" "}
                        Single Select
                      </li>
                      <li
                        key={4}
                        onClick={() =>
                          showEditQuestion(
                            {
                              type: "uploadMedia",
                              image:
                                "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/uploadMedia.svg",
                            },
                            true
                          )
                        }
                      >
                        <img
                          src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/uploadMedia.svg"
                          alt=""
                        />{" "}
                        Upload media
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <EditQuestion
                showEditQuestion={showEditQuestion}
                isEditQuestion={isEditQuestion}
                setNewQuestion={setNewQuestion}
                questionArray={questionArray}
                questionDetail={questionDetail}
                setQuestionDetail={setQuestionDetail}
              />
            </div>
            <div className="main-box">
              <div className="inside-outer scrollbar">
                <div className="inside-main" ref={firstRef}>
                  <div className="inside-top">
                    <img src="../assets/logo/main-logo.png" alt="" />
                  </div>

                  <div className="inside-shell">
                    <div
                      className={
                        selectedLayout.type === "Card Style"
                          ? "inside-mid card-style"
                          : "inside-mid"
                      }
                    >
                      <div className="inside-content">
                        <h2 className="heading-2">{feedbackTitle}</h2>
                        <p className="text-4">{feedbackDescription}</p>

                        <button
                          onClick={() => scrollToNext(1)}
                          className="danger-btn  max-w170 mt-30"
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {questionArray.map((item, index) => {
                  const {
                    id,
                    questionType,
                    questionName,
                    answers,
                    title,
                    desc,
                    isMandatory,
                  } = item;

                  return (
                    <div className="inside-main" key={index} ref={refs[id]}>
                      <div className="inside-shell">
                        <div
                          className={
                            selectedLayout.type === "Card Style"
                              ? "inside-mid card-style"
                              : "inside-mid"
                          }
                        >
                          <div
                            className="inside-content"
                            onClick={() =>
                              onClickPreviewListItem(id, questionType)
                            }
                          >
                            <h2 className="heading-2">
                              {questionName || title}
                            </h2>
                            {desc && <p className="text-4">{desc}</p>}
                            {questionType === "paragraph" && <Paragraph />}
                            {questionType === "rating" && <RatingBox />}
                            {questionType === "singleSelect" && (
                              <SingleSelect item={item} />
                            )}
                            {questionType === "multiSelect" && (
                              <MultiSelect item={item} />
                            )}
                            {questionType === "uploadMedia" && <UploadBox />}
                            {/* {index === 0 && (
                              <button
                                className="danger-btn  max-w170"
                                // onClick={() => moveTop(id)}
                              >
                                Get Started
                              </button>
                            )} */}
                            {/* {index !== 1 && ( */}
                            <>
                              <button
                                className="danger-btn  max-w170 mt-30"
                                onClick={() => scrollToNext(id + 1)}
                              >
                                Continue
                              </button>
                              {isMandatory !== false && (
                                <button
                                  onClick={() => scrollToNext(id + 1)}
                                  className="skip-btn"
                                >
                                  skip
                                </button>
                              )}
                            </>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="inside-main">
                  <div className="inside-top">
                    <img src="../assets/logo/main-logo.png" alt="" />
                  </div>

                  <div className="inside-shell">
                    <div className="inside-mid card-style">
                      <div className="inside-content">
                        <h2 className="heading-1">
                          How would you rate the technology enabled <br />
                          online className format ?
                        </h2>

                        <Paragraph />

                        <button className="danger-btn mt-20 max-w170">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="inside-main">
                  <div className="inside-top">
                    <img src="../assets/logo/main-logo.png" alt="" />
                  </div>

                  <div className="inside-shell">
                    <div className="inside-mid card-style">
                      <div className="inside-content">
                        <h2 className="heading-1">
                          How would you rate the technology enabled <br />
                          online className format ?
                        </h2>
                        <RatingBox />
                        <button className="danger-btn mt-20 max-w170">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="inside-main">
                  <div className="inside-top">
                    <img src="../assets/logo/main-logo.png" alt="" />
                  </div>

                  <div className="inside-shell">
                    <div className="inside-mid card-style">
                      <div className="inside-content">
                        <h2 className="heading-1">
                          How would you rate the technology enabled <br />
                          online className format ?
                        </h2>

                        <UploadBox />

                        <button className="danger-btn mt-40 max-w170">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="inside-main">
                  <div className="inside-top">
                    <img src="../assets/logo/main-logo.png" alt="" />
                  </div>

                  <div className="inside-shell">
                    <div className="inside-mid card-style">
                      <div className="inside-content">
                        <h2 className="heading-1">
                          How would you rate the technology enabled <br />
                          online className format ?
                        </h2>

                        <SingleSelect />

                        <button className="danger-btn mt-40 max-w170">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="inside-main">
                  <div className="inside-top">
                    <img src="../assets/logo/main-logo.png" alt="" />
                  </div>

                  <div className="inside-shell">
                    <div className="inside-mid card-style">
                      <div className="inside-content">
                        <h2 className="heading-1">
                          How would you rate the technology enabled <br />
                          online className format ?
                        </h2>

                        <MultiSelect />

                        <button className="danger-btn mt-40 max-w170">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* 
              <div className="inside-footer">
                <div className="nu-buttons">
                  <p>
                    <a href="javascript:void(0)">
                      <img
                        src="../assets/icons/prev.svg"
                        alt=""
                        className="mr-15"
                      />{" "}
                      5
                    </a>{" "}
                    /{" "}
                    <a href="javascript:void(0)">
                      6{" "}
                      <img
                        src="../assets/icons/forw.svg"
                        alt=""
                        className="ml-15"
                      />{" "}
                    </a>
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* QUESTIONS CONTAINER ENDS */}

        {/* ADD-STUDENT CONTAINER STARTS */}
        <div
          className="add-student"
          id="container-3"
          style={{ display: "none" }}
          onClick={() => changeContainer(3, 2, 1)}
        >
          <div className="filter-bar">
            <form action="" className="search-bar">
              <img
                src="../assets/icons/search.svg"
                alt=""
                onClick={onSearchClick}
              />
              <input
                type="text"
                name=""
                id=""
                placeholder="Search Students By Firstname Or Lastname"
                className="clean-input"
                onChange={onSearchChange}
                onKeyDown={onSearchKeyPress}
              />
            </form>
            <div className="filters">
              <select id="type" onChange={onProgramChange}>
                <option value="">Program Name</option>
                {programs.length > 0 &&
                  programs.map((program, index) => (
                    <option key={index} value={program.id}>
                      {program.programName}{" "}
                    </option>
                  ))}
              </select>
              {batch.length > 0 && (
                <select id="type" onChange={onBatchChange}>
                  <option value="">Batch</option>
                  {batch.map((bth, idx) => (
                    <option key={idx} value={bth.id}>
                      {bth.batch}
                    </option>
                  ))}
                </select>
              )}
              <select id="type" onChange={onPageSizeChange}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          {allChecked && (
            <div className="filter-bar">
              {!isTotalSelected && (
                <div className="selection-bar">
                  <span>
                    All {isChecked?.length} Students in this page are selected
                  </span>
                  <button className="clear-btn" onClick={selectTotalSelected}>
                    Select All {studentCount} Students
                  </button>
                </div>
              )}
              {isTotalSelected && (
                <div className="selection-bar">
                  <span>All {studentCount} Students are selected</span>
                  <button className="clear-btn" onClick={clearSelected}>
                    Clear Selected Students
                  </button>
                </div>
              )}
            </div>
          )}
          <div className="feedback-container scrollbar border">
            <table className="feedback-table" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th className="check-row">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        name="allCheck"
                        id="allCheck"
                        className="checkmark-btn"
                        checked={allChecked}
                        onChange={handleAllCheck}
                      />
                      <span className="checkmarkspan"></span>
                    </label>

                    {/* <img
                      src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                      className="ml-5"
                    /> */}
                  </th>
                  <th style={{ paddingLeft: "0 !important" }}>Student</th>
                  <th>Programme</th>
                  <th>Batch</th>
                </tr>
              </thead>
              <tbody>
                {studentList?.length > 0 &&
                  studentList.map((item, index) => (
                    <tr key={index} className="p-20">
                      <td>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            className="checkmark-btn"
                            checked={isChecked.includes(item.id)}
                            value={item.id}
                            onChange={handleSingleCheck}
                          />
                          <span className="checkmarkspan" />
                        </label>
                      </td>
                      <td>
                        {item.firstName} {item.lastName}
                      </td>
                      <td>{item.ProgramDetail?.Program?.programName}</td>
                      <td>{item.ProgramDetail?.batch}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="footer pg-arrows-outer pd-add">
            <p className="pg-arraows-p">
              {pageNo} of{" "}
              {totalStudentsCount <= pageSize
                ? "1"
                : Math.ceil(totalStudentsCount / pageSize)}
            </p>
            <div className="pg-arrows">
              <a onClick={setPreviousPage}>
                {" "}
                <img
                  src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                  className="pg-left"
                />
              </a>
              <a onClick={setNextPage}>
                {" "}
                <img
                  src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                  className="pg-right"
                />
              </a>
            </div>
          </div>
          {/* <div className="footer">
            <div className="btn-group">
              <button className="secondary-btn">Back</button>
              <button className="danger-btn" onClick={handleOnSaveAsDraft}>
                Save as Draft
              </button>
            </div>
          </div> */}
        </div>
        {/* ADD-STUDENT CONTAINER ENDS */}
      </div>
    </React.Fragment>
  );
}
