import React, { useState, useEffect } from "react";
import FeeDropdown from "../../Dropdowns/FeeDropdown/FeeDropdown";
import {
  feedbackData,
  getEmailTemplate,
} from "../../../reducers/feedbackSlice";
import { useSelector, useDispatch } from "react-redux";
import MailPopup from "../../../components/Popups/MailPopup/MailPopup";
import "./FeeDuePopupStyle.css";
import {
  COMMON_TEXT,
  EMAIL_TEMPLATE_NAME,
  EMAIL_TYPE,
  Acceptance_Letter,
  EMAIL_ORIENTATION_MAIL,
} from "../../../constants/textConstant";
import {
  clearFeeDue,
  feeData,
  getCourseFeeStudentList,
  getStudentFeeList,
} from "../../../reducers/feeSlice";
import ToastMessage from "../../Toast/ToastComponent";
import {
  applicantData,
  clearState,
  getWelcomeLetterStudentList,
} from "../../../reducers/applicantSlice";
const FeeDuePopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("Select Fee Type");
  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    feedbackList,
    feedbackResponse,
    studentList,
    feedbackDetail,
    emailTemplateData,
  } = useSelector(feedbackData);

  const { sendFeeDueSuccess } = useSelector(feeData);

  const { admissionAmount, welcomeLetterSuccess } = useSelector(applicantData);
  const [programId, setProgramId] = useState(null);

  const [gstPercentage, setGstPercentage] = useState("18");
  const [totalFee, setTotalFee] = useState("");
  const [feeAmount, setFeeAmount] = useState("");
  const [feeDate, setFeeDate] = useState("");
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [validateDate, setDateValidation] = useState(false);
  const [documentDate, setDocumentDate] = useState("");
  const [validateDocDate, setDocumentValidation] = useState(false);

  const dispatch = useDispatch();

  const toggleMailPopup = () => {
    setIsMailOpen(!isMailOpen);
  };

  const onClickSendMail = () => {
    if (props?.isNewOffer) {
      if (feeDate === "") {
        setDateValidation(true);
        if (documentDate === "" && props?.dueType === "orientationMail") {
          setDocumentValidation(true);
        }
      } else {
        setDateValidation(false);
        setDocumentValidation(false);
        toggleMailPopup();
        props.closePopup();
      }
    } else {
      toggleMailPopup();
      props.closePopup();
    }

    // sendMail();
  };

  // handleAmountChange = (event) => {
  //   console.log("event change amount", event.target.value);
  // };

  const handleClose = () => {
    // setFeeAmount("");
    setFeeDate("");
    props.closePopup();
  };

  console.log("printing props ", props);

  useEffect(() => {
    // if (props?.dueType === "") {
    // }

    console.log("ghfghighijghghghgh", props?.dueType);

    switch (props?.dueType) {
      case "offerLetter":
        if (programId != null && programId != undefined) {
          console.log("dfdfdfdfdfdfdf", programId);

          console.log("inside if condition of Offer Letter");
          dispatch(
            getEmailTemplate({
              templateName:
                programId === "53e08f7a-a03b-42f3-b181-064d1afc05e3"
                  ? Acceptance_Letter.acceptanceLetterKPMG
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc05e5"
                  ? Acceptance_Letter.acceptanceLetterKPMG
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc05e7"
                  ? Acceptance_Letter.acceptanceLetterLeaderShip
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc05e8"
                  ? Acceptance_Letter.acceptanceLetterArtComm
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc05e6"
                  ? Acceptance_Letter.acceptanceLetterProductMgmt
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc05e4"
                  ? Acceptance_Letter.acceptanceLetterBlockChain
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0510"
                  ? Acceptance_Letter.acceptanceLetterUserExperience
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0511"
                  ? Acceptance_Letter.acceptanceLetterFinancialPlanning
                  : programId === "e524ffd2-1bd5-4130-a5a4-9509fb314658"
                  ? Acceptance_Letter.acceptanceLetterHumanResource
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0513"
                  ? Acceptance_Letter.acceptanceLetterCreatorpreneur
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0512"
                  ? Acceptance_Letter.acceptanceLetterPGPRise
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0514"
                  ? Acceptance_Letter.acceptanceLetterAudit
                  : Acceptance_Letter.acceptanceLetterKPMG,
            })
          );
        }

        console.log("inside switch case offerLetter");
        break;

      case "welcomeLetter":
        if (programId != null && programId != undefined) {
          console.log("inside if condition of Welcome Letter");
          dispatch(
            getEmailTemplate({
              templateName:
                programId === "53e08f7a-a03b-42f3-b181-064d1afc05e6"
                  ? EMAIL_TEMPLATE_NAME.emailTemplateWelcomeLetterProduct
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc05e4"
                  ? EMAIL_TEMPLATE_NAME.emailTemplateWelcomeLetterBlockChain
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc05e5" ||
                    programId === "53e08f7a-a03b-42f3-b181-064d1afc05f9"
                  ? EMAIL_TEMPLATE_NAME.emailTemplateWelcomeLetterKPMG
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0511"
                  ? EMAIL_TEMPLATE_NAME.emailTemplateWelcomeLetterFPA
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0510"
                  ? EMAIL_TEMPLATE_NAME.emailTemplateWelcomeLetterUX
                  : programId === "e524ffd2-1bd5-4130-a5a4-9509fb314658"
                  ? EMAIL_TEMPLATE_NAME.emailTemplateWelcomeLetterHRM
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0513"
                  ? EMAIL_TEMPLATE_NAME.emailTemplateWelcomeLetterCreator
                  : EMAIL_TEMPLATE_NAME.emailTemplateWelcomeLetter,
            })
          );
        }
        console.log("inside switch case welcome Letter");
        break;

      case "orientationMail":
        if (programId != null && programId != undefined) {
          console.log("inside if condition of Welcome Letter");
          dispatch(
            getEmailTemplate({
              templateName:
                programId === "e524ffd2-1bd5-4130-a5a4-9509fb314658"
                  ? EMAIL_ORIENTATION_MAIL.emailTemplateOrientationLetterHRM
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0510"
                  ? EMAIL_ORIENTATION_MAIL.emailTemplateOrientationLetterUX
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc05e5"
                  ? EMAIL_ORIENTATION_MAIL.emailTemplateOrientationLetterAF
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc05e6"
                  ? EMAIL_ORIENTATION_MAIL.emailTemplateOrientationLetterPM
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0512"
                  ? EMAIL_ORIENTATION_MAIL.emailTemplateOrientationLetterPGPRise
                  : programId === "53e08f7a-a03b-42f3-b181-064d1afc0513"
                  ? EMAIL_ORIENTATION_MAIL.emailTemplateOrientationLetterCreatorpreneur
                  : EMAIL_TEMPLATE_NAME.emailTemplateWelcomeLetter,
            })
          );
        }
        console.log("inside switch case welcome Letter");
        break;

      case "feeDue":
        dispatch(
          getEmailTemplate({
            templateName: EMAIL_TEMPLATE_NAME.emailTemplateForFeeDue,
          })
        );

        console.log("inside if condition of Fee Due");

        console.log("inside switch case fee Due");

        break;

      default:
        break;
    }
  }, [props?.dueType, programId]);

  // const sendEmailFeeDue = () => {
  //   const obj = {
  //     approvedStudents: props.student,
  //     dueOn: feeDate,
  //     feeDue: feeAmount,
  //     program_name: admissionAmount?.ProgramDetail?.Program?.programName,
  //     programId: props.programBatchId,
  //     programMasterId: props.programMasterId,
  //   };
  //   console.log(obj, "send email");
  //   dispatch(sendFeeDueEmail(obj));
  // };

  useEffect(() => {
    if (sendFeeDueSuccess) {
      // let obj = {
      //   programBatchId: props.programBatchId,
      // };
      // var params = {
      //   searchTitle: "",
      //   dueStatus: "false",
      //   requestType: "",
      //   pageNo: "1",
      //   pageSize: "10",
      // };
      // dispatch(getCourseFeeStudentList({ obj, params }));

      // handleClose();
      setTimeout(() => {
        setEnableSuccessMessage(true);
      }, 2000);
      props?.clearSelected();
      dispatch(clearFeeDue());
      dispatch(clearState());
      setIsMailOpen(false);

      console.log("inside send fee due");
    } else if (welcomeLetterSuccess) {
      props?.setPageNo("1");
      props?.clearSelected();
      dispatch(clearFeeDue());
      dispatch(clearState());
      setIsMailOpen(false);
      setTimeout(() => {
        setEnableSuccessMessage(true);
      }, 2000);
    }
  }, [sendFeeDueSuccess, welcomeLetterSuccess]);

  useEffect(() => {
    setTotalFee(admissionAmount?.amount);
    setProgramId(admissionAmount?.ProgramDetail?.Program?.id);
  }, [admissionAmount]);

  useEffect(() => {
    setTotalFee(props?.feeAmount);
  }, [props?.feeAmount]);

  useEffect(() => {
    var gst = gstPercentage;
    var amount = totalFee * 1;
    var tot_price = (amount * gst) / 100 + amount;
    setFeeAmount(tot_price);
  }, [totalFee]);

  return (
    <>
      <MailPopup
        emailType={
          props?.dueType === "offerLetter"
            ? EMAIL_TYPE.acceptanceLetter
            : props?.dueType === "welcomeLetter"
            ? EMAIL_TYPE.welcomeLetter
            : props?.dueType === "orientationMail"
            ? EMAIL_TYPE.orientationMail
            : EMAIL_TYPE.feeDueMail
        }
        emailTemplateData={emailTemplateData}
        programName={admissionAmount?.ProgramDetail?.Program?.programName}
        handleClose={toggleMailPopup}
        show={isMailOpen}
        studentCount={props.student && props.student.length}
        student={props.student && props.student}
        feeDate={feeDate}
        feeAmount={feeAmount}
        programBatchId={props.programBatchId}
        programMasterId={props.programMasterId}
        searchTitle={props.searchTitle}
        emailStatus={props.emailStatus}
        approvedStatus={props.approvedStatus}
        pageNo={props.pageNo}
        pageSize={props.pageSize}
        isTotalSelected={props.isTotalSelected}
        isNewOffer={props?.isNewOffer}
        feeDueId={props?.feeDueId}
        shortListCount={props.shortListCount}
        applicantId={props?.applicantId}
        setPageNo={props?.setPageNo}
        documentDate={documentDate}
      />

      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Email Sent Successfully"}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container due-popup">
          <div className="popup-heading">
            Send Fee Due{" "}
            <img
              src="../assets/icons/close.svg"
              alt=""
              className="close-btn"
              onClick={handleClose}
            />
          </div>

          <div className="popup-body">
            <form action="">
              <div className="form-grp">
                {props?.isNewOffer ? (
                  <>
                    <label htmlFor="">
                      Due Date <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="date"
                      name="feeDate"
                      id="date"
                      className="create-input"
                      placeholder="Enter Amount"
                      value={feeDate}
                      onChange={(e) => setFeeDate(e.target.value)}
                    />
                    <span
                      className={
                        validateDate ? "error-msg active" : "error-msg"
                      }
                    >
                      Please select due date !
                    </span>
                    {props?.dueType === "orientationMail" && (
                      <>
                        <label htmlFor="">
                          Document Submission Date
                          <span className="imp-mark">*</span>
                        </label>
                        <input
                          type="date"
                          name="feeDate"
                          id="documentDate"
                          className="create-input"
                          // placeholder="Enter Amount"
                          value={documentDate}
                          onChange={(e) => setDocumentDate(e.target.value)}
                        />
                        <span
                          className={
                            validateDocDate ? "error-msg active" : "error-msg"
                          }
                        >
                          Please select document submission date !
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <label htmlFor="amount">
                      Fee Amount <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="number"
                      name="feeAmount"
                      id="amount"
                      className="create-input pr-85"
                      placeholder="Enter Amount"
                      value={feeAmount}
                      onChange={(e) => setFeeAmount(e.target.value)}
                    />

                    <label class="gstCustom" for="amount">
                      + 18% GST<span class="imp-mark">*</span>
                    </label>
                    <span
                      className={
                        validateDate ? "error-msg active" : "error-msg"
                      }
                    >
                      Please enter fee amount
                    </span>
                  </>
                )}
              </div>
              {/* 
              <div className="form-grp mt-20">
                <label htmlFor="amount">
                  Fee Amount <span className="imp-mark">*</span>
                </label>
                <div className="form-2-grp">
                  <input
                    type="number"
                    name="feeAmount"
                    id="amount"
                    className="create-input"
                    placeholder="Enter Amount"
                    value={totalFee}
                    onChange={handleAmountChange}
                  />

                  <span className="text-danger"> + GST 18% </span>
                </div>
                {/* <label htmlFor="amount">
                  Fee Amount <span className="imp-mark">*</span>
                </label>
                <input
                  type="number"
                  name="feeAmount"
                  id="amount"
                  className="create-input"
                  placeholder="Enter Amount"
                  value={feeAmount}
                  onChange={(e) => setFeeAmount(e.target.value)}
                /> */}

              {/* <input
                  type="number"
                  name="feeAmount"
                  id="GST"
                  className="create-input"
                  placeholder="Enter Amount"
                  value={gstPercentage}
                  onChange={handleGstAmountPercentage}
                /> */}
              {/* </div> */}
            </form>
            {/* <span className="amount">Total Payable Amount: ₹{feeAmount} </span> */}
          </div>
          <div className="popup-footer">
            {/* {props.sendFeeDue ? (
              <button className="primary-btn" onClick={sendEmailFeeDue}>
                Send Fee Due
              </button>
            ) : ( */}
            <button className="primary-btn" onClick={onClickSendMail}>
              Send Mail
            </button>
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeDuePopup;
