import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SunEditor from "suneditor-react";
import "./chat.css";
const AgendaPopup = (props) => {
  const handleEditorChange = (content) => {
    props.setMeetingChat(content);
  };
  return (
    <>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container feedback-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Agenda
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>
          </div>
          <div className="popup-body chatOuterr">
            <div className="chatOuter scrollbar">
              {props?.getAllAgenda &&
                props?.getAllAgenda?.MeetingAgendas?.map((res, i) => (
                  <React.Fragment key={i}>
                    {res.Student && (
                      <div className="chatLeftOutr">
                        <div className="chatLeft">
                          <div className="chatHeading">
                            {res?.Student?.firstName} {res?.Student?.lastName}
                          </div>
                          <div className="chatMsgOutr">
                            {/* <p className="chatMsg">{res.reply}</p> */}
                            <div
                              className="chatMsg"
                              dangerouslySetInnerHTML={{
                                __html: res.reply,
                              }}
                            />
                            {/* <label className="chatTime">10:00am</label> */}
                          </div>
                        </div>
                      </div>
                    )}
                    {res.User && (
                      <div className="chatRightOutr">
                        <div className="chatRight">
                          <div className="chatHeading">
                            {res?.User?.firstName} {res?.User?.lastName}
                          </div>
                          <div className="chatMsgOutr">
                            {/* <p className="chatMsg">{res.reply}</p> */}
                            <div
                              className="chatMsg"
                              dangerouslySetInnerHTML={{
                                __html: res.reply,
                              }}
                            />
                            {/* <label className="chatTime">10:00am</label> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}

              {/* <div className="chatLeftOutr">
                <div className="chatLeft">
                  <img src="/assets/img/blankuser.png" alt="" className="" />
                  <div className="chatLeftInner">
                    <div className="chatMsgOutr">
                      <p className="chatMsg">Hello Sattiii</p>
                      <label className="chatTime">10:00am</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chatRightOutr">
              <div className="chatRight">
              <img src="/assets/img/blankuser.png" alt="" className="" />
                <div className="chatRightInner">
                  <div className="chatMsgOutr">
                    <p className="chatMsg">
                      More details pertaining to the programme design and
                      activities will be shared closer to the start date.
                    </p>
                    <label className="chatTime">10:00am</label>
                  </div>
                </div>
              </div>
              </div> */}
            </div>
            <div className="chatReply">
              {/* <form>
                <textarea
                  cols="5"
                  rows="5"
                  placeholder="type your answer"
                  value={props.meetingChat}
                  onChange={(e) => props.setMeetingChat(e.target.value)}
                ></textarea>
              </form> */}
              <SunEditor
                setContents={props.meetingChat}
                showToolbar={false}
                onChange={handleEditorChange}
                setDefaultStyle="height: 100px"
              />
            </div>
          </div>
          <div className="popup-footer">
            <div className="btn-group">
              <button className="green-btn" onClick={props.createAgenda}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgendaPopup;
