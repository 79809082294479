import React from 'react'

const AdditionalInformationPopup = (props) => {
    return (
        <>
            <div className={`popup-outer ${props.show ? 'show-popup':''}`}>
                <div className="popup-container sendFeePopup">
                    <div className="popup-header">
                        <div className="popup-heading">
                            Ask for more details
                            <img
                                src="../assets/icons/close.svg"
                                alt=""
                                className="close-btn"
                                onClick={props.handleAdditionalInfoClose}

                            />
                        </div>
                    </div>
                    <div className="popup-body">
                        <textarea className='create-input mt-5' name="" id="" cols="5" rows="5" placeholder='type your answer'></textarea>
                    </div>
                    <div className="btn-group ">
                        <button className="primary-btn" onClick={props.handleAdditionalInfoClose}>
                            Send Mail
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdditionalInformationPopup