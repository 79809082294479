const mergeTag = {
  name: "merge_tag",
  display: "submenu",
  add: function (core, targetElement) {
    let listDiv = this.setSubmenu.call(core);
    var self = this;
    listDiv.querySelectorAll(".se-btn-list").forEach(function (btn) {
      btn.addEventListener("click", self.onClick.bind(core));
    });
    core.initMenuTarget(this.name, targetElement, listDiv);
  },
  setSubmenu: function () {
    const listDiv = this.util.createElement("DIV");
    listDiv.className = "se-submenu se-list-layer";
    listDiv.innerHTML = `<div className="se-list-inner se-list-font-size"><ul className="se-list-basic">
      <li><button type="button" className="se-btn-list" value="{candidate_name}">Candidate Name</button></li>
      <li><button type="button" className="se-btn-list" value="{feedback_name}">Feedback Name</button></li>
      <li><button type="button" className="se-btn-list" value="{feedback_type}">Feedback Type</button></li>
      <li><button type="button" className="se-btn-list" value="{feedback_expiryDate}">Feedback Expiry Date</button></li>
      </ul></div>`;

    return listDiv;
  },
  onClick: function (e) {
    const value = e.target.value;
    const node = this.util.createElement("span");
    this.util.addClass(node, "se-custom-tag");
    node.textContent = value;

    this.insertNode(node);
    const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
    node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);

    this.submenuOff();
  },
};

export default mergeTag;
