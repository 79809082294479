import React, { useState } from "react";
import "./DropdownLayoutStyle.css";

function DropdownLayout({ selectedLayout, setSelectedLayout }) {
  const [isActive, setIsActive] = useState(false);
  // const options = ['React', 'React2', 'Angular']

  const options = [
    {
      type: "1 Column",
    },
    {
      type: "Card Style",
    },
  ];
  return (
    <div className="dropdown">
      <div
        className="dropdown-btn text-6"
        onClick={(e) => setIsActive(!isActive)}
      >
        {selectedLayout.type}
      </div>
      {isActive && (
        <div className="dropdown-content text-6">
          {options.map((option,i) => (
            <div key={i}
              onClick={(e) => {
                setSelectedLayout(option);
                setIsActive(false);
              }}
              className="dropdown-item"
            >
              {option.type}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownLayout;
