import React from "react";
import "./BiddingListStyle.css";
import $ from "jquery";
import SideMenu from "../../../components/Layout/menu/SideMenu";

const BiddingList = () => {
  $("td").hover(
    function () {
      var t = parseInt($(this).index()) + 1;
      $("td:nth-child(" + t + ")").addClass("highlighted");
    },
    function () {
      var t = parseInt($(this).index()) + 1;
      $("td:nth-child(" + t + ")").removeClass("highlighted");
    }
  );
  return (
    <>
      <SideMenu />
      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">
            <span className="text-grey">Applied Finance | </span>All Bidding
            Request
          </p>
          <button className="primary-btn feed-btn">
            <img src="../assets/icons/plus.svg" alt="" /> Add Program
          </button>
        </div>
        <div className="student-container">
          <div className="student-info-outer">
            <div className="student-inner-left">
              <div className="student-details-outer">
                <div className="student-details">
                  <p className="student-name text-18">
                    Applied Finance
                    <img src="../assets/icons/sectionSymbol.svg" />{" "}
                    <span className="text-danger">All Courses</span>
                  </p>
                </div>
                <div className="student-details">
                  <p className="student-mail">2022 &bull; 10 months</p>
                </div>
              </div>
            </div>
            <div className="student-inner-right bg-grey">
              <div className="fee-box">
                <p>All Request</p>
                <p className="text-dark">40</p>
              </div>
              <div className="fee-box">
                <p>Courses</p>
                <p className="text-danger">4</p>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-bar">
          <form action="" className="search-bar">
            <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Search"
              className="clean-input"
            />
          </form>
          <div className="filters">
            <select id="type">
              <option value="10">1-60</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div className="feedback-container scrollbar course-table-outer">
          <table
            className="feedback-table bidding-list"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Course Count</th>
                <th>Course 1</th>
                <th>Course 2</th>
                <th>Course 3</th>
                <th>Course 4</th>
                <th>Course 5</th>
                <th>Course 6</th>
                <th>Course 7</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>John Doe</td>
                <td>2</td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
              </tr>
              <tr>
                <td>John Doe</td>
                <td>2</td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
              </tr>
              <tr>
                <td>John Doe</td>
                <td>2</td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
              </tr>
              <tr>
                <td>John Doe</td>
                <td>2</td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
              </tr>
              <tr>
                <td>John Doe</td>
                <td>2</td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
              </tr>
              <tr>
                <td>John Doe</td>
                <td>2</td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
                <td>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span> 6
                  </label>
                </td>
              </tr>
              <tr className="save-row">
                <td></td>
                <td></td>
                <td>
                  <button className="green-btn">Save</button>
                </td>
                <td>
                  <button className="green-btn">Save</button>
                </td>
                <td>
                  <button className="green-btn">Save</button>
                </td>
                <td>
                  <button className="green-btn">Save</button>
                </td>
                <td>
                  <button className="green-btn">Save</button>
                </td>
                <td>
                  <button className="green-btn">Save</button>
                </td>
                <td>
                  <button className="green-btn">Save</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default BiddingList;
