import React from "react";
import "./ClubPreviewStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import { COMMON_TEXT } from "../../../constants/textConstant";

const ClubPreview = () => {
  return (
    <React.Fragment>
      <SideMenu />
      <div className="main-body">
        <div className="page-header club-preview-header">
          <button className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            {COMMON_TEXT.goBack}
          </button>
          <div className="btn-group">
            <button className="dark-btn-outline">
              <img src="../assets/icons/edit-black.svg" alt="" /> Edit
            </button>
            <button className="danger-btn">
              <img src="../assets/icons/live.svg" alt="" /> Unpublish
            </button>
          </div>
        </div>
        <div className="preview-container scrollbar">
          <div className="main-container">
            <div className="club-header">
              <div className="header-top">
                <img
                  src="https://img.freepik.com/free-vector/smooth-mesh-blurred-background-multi-color-gradient-pattern-smooth-
                  modern-watercolor-style-backdrop_124894-147.jpg?size=626&ext=jpg&ga=GA1.2.1292351815.1642896000"
                  alt=""
                />
                <img
                  src="https://images.unsplash.com/photo-1640622842223-e1e39f4bf627?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                  alt=""
                  className="club-image"
                />
              </div>
              <div className="header-bottom">
                <div className="club-detail">
                  <h3 className="heading-3 mb-5">Harvard business Club</h3>
                  <p className="club-desc">
                    At Harvard Business Review, we believe in management. If the
                    world’s organizations and institutions were run more
                    effectively, if our leaders made better decisions, if people
                    worked more productively,
                  </p>
                </div>
                <div className="btn-group">
                  <div className="club-btn">
                    <img src="../assets/icons/chat.svg" alt="" />
                    Forum
                  </div>
                  <div className="club-btn">
                    <img src="../assets/icons/preview.svg" alt="" />
                    Guiding Documents
                  </div>
                </div>
              </div>
            </div>
            <div className="club-body mt-10">
              <div className="body-left scrollbar">
                <div className="events-block">
                  <div className="events-header">
                    <button className="clear-btn active">
                      Upcoming Events
                    </button>
                    <button className="clear-btn">Past Events</button>
                    <button className="club-btn">
                      <img src="../assets/icons/plus-black.svg" alt="" />
                      Add Event
                    </button>
                  </div>
                  <div className="events-body">
                    <ul className="event-list">
                      <li className="event-list-item">
                        <p className="text-2">
                          Learn Website Wireframing with Shravan Tickoo
                        </p>
                        <p className="text-2">21st Jan 2022</p>
                      </li>
                      <li className="event-list-item">
                        <p className="text-2">
                          Learn Website Wireframing with Shravan Tickoo
                        </p>
                        <p className="text-2">21st Jan 2022</p>
                      </li>
                      <li className="event-list-item">
                        <p className="text-2">
                          Learn Website Wireframing with Shravan Tickoo
                        </p>
                        <p className="text-2">21st Jan 2022</p>
                      </li>
                      <li className="event-list-item">
                        <p className="text-2">
                          Learn Website Wireframing with Shravan Tickoo
                        </p>
                        <p className="text-2">21st Jan 2022</p>
                      </li>
                      <li className="event-list-item">
                        <p className="text-2">
                          Learn Website Wireframing with Shravan Tickoo
                        </p>
                        <p className="text-2">21st Jan 2022</p>
                      </li>
                      <li className="event-list-item">
                        <p className="text-2">
                          Learn Website Wireframing with Shravan Tickoo
                        </p>
                        <p className="text-2">21st Jan 2022</p>
                      </li>
                      <li className="event-list-item">
                        <button className="clear-btn">
                          See all Upcoming Events
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="body-right">
                <div className="club-holder">
                  <img
                    src="https://images.unsplash.com/photo-1640057692320-c88208a4c832?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    alt=""
                  />
                  <div className="holder-details">
                    <h4 className="text-1">
                      Rahul Kapoor <p className="tag-active">President</p>{" "}
                    </h4>
                    <p className="text-2">
                      Programme in Finance and Accounting | 2018-2020 |
                    </p>
                  </div>
                </div>

                <div className="club-members">
                  <ul className="event-list club-members">
                    <li className="event-list-item list-heading">
                      <p className="text-2">Club memeber</p>
                    </li>

                    <li className="event-list-item">
                      <div className="event-list-img"><img src="../../assets/img/ali.png" alt="" /></div>
                      <div className="event-list-details">
                        <h2 className="heading-small">Ali Hassan</h2>
                        <p className="text-2">Ali@masterunion.com</p>
                      </div>
                    </li>

                    <li className="event-list-item">
                      <div className="event-list-img"><img src="../../assets/img/muhamad.png" alt="" /></div>
                      <div className="event-list-details">
                        <h2 className="heading-small">Muhammad Faizan</h2>
                        <p className="text-2">Muhammad@masterunion.com</p>
                      </div>
                    </li>

                    <li className="event-list-item">
                      <div className="event-list-img"><img src="../../assets/img/sharika.png" alt="" /></div>
                      <div className="event-list-details">
                        <h2 className="heading-small">Sharika Raina</h2>
                        <p className="text-2">Sharika@masterunion.com</p>
                      </div>
                    </li>

                    <li className="event-list-item">
                      <div className="event-list-img"><img src="../../assets/img/rahul.png" alt="" /></div>
                      <div className="event-list-details">
                        <h2 className="heading-small">Rahul Kapoor</h2>
                        <p className="text-2">Rahul@masterunion.com</p>
                      </div>
                    </li>

                    <li className="event-list-item list-btn">
                      <button className="clear-btn">
                        See all Club memeber
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="club-report">
                  <ul className="event-list">
                    <li className="event-list-item list-heading">
                      <p className="text-2">Club Report</p>
                    </li>

                    <li className="event-list-item">
                      <p className="heading-small">Learn Website</p>
                      <p className="text-8">5 July 2020</p>
                      <img src="../../assets/icons/downloadreport.svg" alt="" />
                      <img
                        src="../../assets/icons/downloadreportfill.svg"
                        alt=""
                      />
                    </li>

                    <li className="event-list-item">
                      <p className="heading-small">Exploring Shar..</p>
                      <p className="text-8">5 July 2020</p>
                      <img src="../../assets/icons/downloadreport.svg" alt="" />
                      <img
                        src="../../assets/icons/downloadreportfill.svg"
                        alt=""
                      />
                    </li>

                    <li className="event-list-item">
                      <p className="heading-small">Alumni, Episode</p>
                      <p className="text-8">5 July 2020</p>
                      <img src="../../assets/icons/downloadreport.svg" alt="" />
                      <img
                        src="../../assets/icons/downloadreportfill.svg"
                        alt=""
                      />
                    </li>

                    <li className="event-list-item">
                      <p className="heading-small">CXO Lesson</p>
                      <p className="text-8">5 July 2020</p>
                      <img src="../../assets/icons/downloadreport.svg" alt="" />
                      <img
                        src="../../assets/icons/downloadreportfill.svg"
                        alt=""
                      />
                    </li>

                    <li className="event-list-item">
                      <p className="heading-small">Learn Website</p>
                      <p className="text-8">5 July 2020</p>
                      <img src="../../assets/icons/downloadreport.svg" alt="" />
                      <img
                        src="../../assets/icons/downloadreportfill.svg"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClubPreview;
