import React, { useState } from "react";
import "./MultiSelect.css";

const MultiSelect = (props) => {
  const [checked, setChecked] = useState([]);
  const [Active, setActive] = useState(false);

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);

    console.log("ggr r gr gr ", checked);
  };

  return (
    <div className="mt-30 checkmark-wrapper col-3">
      {props.item.option.map((item, index) => (
        <label
          className={Active ? "checkmark-label active" : "checkmark-label"}
          key={index}
        >
          <input
            value={item.value}
            type="checkbox"
            className="checkmark-btn"
            onChange={handleCheck}
          />
          <span className="checkmarkspan"></span>
          {item.value}
        </label>
      ))}
    </div>
  );
};

export default MultiSelect;
