import React, { useEffect } from "react";

const ToastMessage = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.setShow();
    }, 3000);
  }, [props.show]);
  return (
    <div className={`success-toast ${props.show ? "active" : ""}`}>
      <img src="../assets/icons/check-white.svg" alt="" />
      <p>{props.message}</p>
    </div>
  );
};

export default ToastMessage;
