import { configureStore } from "@reduxjs/toolkit";
import { applicantSlice } from "../applicantSlice";
import { feedbackSlice } from "../feedbackSlice";
import { feeSlice } from "../feeSlice";
import { headerSlice } from "../headerSlice";
import { pageLayoutSlice } from "../pageLayoutSlice";
import { userSlice } from "../userSlice";
import { meetingSlice } from "../meetingSlice";
import { genericMailSendSlice } from "../genericMailSendSlice";
import { dashboardSlice } from "../dashboardSlice";
import { transactionHistorySlice } from "../transactionHistorySlice";
import { verifyEmailSlice } from "../verifyEmailSlice";
import { expenseSlice } from "../expenseSlice";
import { shippingSlice } from "../shippingSlice";

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    header: headerSlice.reducer,
    pageLayout: pageLayoutSlice.reducer,
    feedbackList: feedbackSlice.reducer,
    feeDetail: feeSlice.reducer,
    applicantDetail: applicantSlice.reducer,
    meetingDetails: meetingSlice.reducer,
    genericMail: genericMailSendSlice.reducer,
    dashboardDetail: dashboardSlice.reducer,
    tranactionHistory: transactionHistorySlice.reducer,
    verifyEmailDetail: verifyEmailSlice.reducer,
    expense: expenseSlice.reducer,
    shippingDetail: shippingSlice.reducer,
  },
});
