import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearState, loginUser, userSelector } from "../../reducers/userSlice";
import PageLoader from "../../components/UI/loader/PageLoader";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { COMMON_TEXT } from "../../constants/textConstant";
import ErrorToast from "../../components/Toast/ErrorToast";

function ForgetPage() {
  const navigate = useNavigate();

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const [check, setCheck] = useState(true);
  const [username, setUsernameInput] = useState("");
  const [validateEmail, setEmailValidation] = useState(false);
  const [validatePassword, setPasswordValidation] = useState(false);
  const [password, setPasswordInput] = useState("");
  const dispatch = useDispatch();

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector);

  /**VERIFY EMAIL ON TYPE */
  const onChangeEmail = (event) => {
    var mEmailAddress = event.target.value;
    setUsernameInput(event.target.value);
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (mEmailAddress === "") {
      setEmailValidation(true);
    } else if (!filter.test(mEmailAddress)) {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
    }
  };

  /**VERIFY PASSWORD ON TYPE */
  const onChangePassword = (event) => {
    var mPassword = event.target.value;
    setPasswordInput(event.target.value);
    if (mPassword === "") {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  };

  const onsubmit = (e) => {
    e.preventDefault();
    if (username && password) {
      var data = {
        email: username,
        password: password,
      };
      dispatch(loginUser(data));
    } else {
      // toast.error("Both fields must not be empty !");
      setIsShowError(true);
      setErrMessage("Both fields must not be empty!");
      // localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    let isAuth = localStorage.getItem("token");
    if (isAuth & (isAuth !== "undefined")) {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isError) {
      // toast.error(errorMessage);
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      navigate("/", { replace: true });
    }
  }, [isError, isSuccess]);
  return (
    <div className="loginContainer">
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      {isFetching && <PageLoader />}
      <div className="loginForm form-1">
        <div className="loginTop form-1-top">
          <h1 className="heading-1">{COMMON_TEXT.forgetAdmin}</h1>
          <p className="text-1">{COMMON_TEXT.forgetAdminText}</p>
        </div>
        <div className="mt-40 loginCenter">
          <form id="login">
            <div className="form-outer">
              <div className="form-input">
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Enter your Email"
                      id="name"
                      name="name"
                      className="text-3 input-box mt-5"
                    />
                    <label htmlFor="name" className="text-2 form-label">
                      {COMMON_TEXT.enterEmail}
                    </label>
                  </div>
                  {validateEmail && (
                    <span className="msg-error" id="nameValidation">
                      {COMMON_TEXT.emailAlert}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-130 form-input">
                <div className="">
                  <button className="danger-btn btn-lg" onClick={onsubmit}>
                    {COMMON_TEXT.forgetText}
                  </button>
                </div>
              </div>
              <div className="form-input">
                <a href="/login" className="mt-10 blue-alert">
                  {COMMON_TEXT.backToLogin}
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgetPage;
