import React from "react";
import "./AddCourseStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import { COMMON_TEXT } from "../../../constants/textConstant";

function AddCourse() {
  return (
    <>
      <SideMenu />
      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">Add Course</p>
          <button className="primary-btn feed-btn">
            <img src="../assets/icons/plus.svg" alt="" /> Save
          </button>
        </div>
        <div className="create-container addCourse">
          <form action="">
            <div className="form-2-col">
              <div className="form-group mt-20">
                <label htmlFor="name" className="create-label">
                  Course Name <span className="imp-mark">*</span>
                </label>
                <input
                  type="text"
                  className="create-input mt-5"
                  placeholder="Enter Couse Name"
                />
                <span className="error-msg">Enter the field</span>
              </div>
              <div className="form-group mt-20">
                <label htmlFor="name" className="create-label">
                  Course Code <span className="imp-mark">*</span>
                </label>
                <input
                  type="text"
                  className="create-input mt-5"
                  placeholder="Enter Couse Name"
                />
                <span className="error-msg">Enter the field</span>
              </div>
            </div>

            <div className="form-2-col">
              <div className="form-group mt-5 cluster">
                <label htmlFor="name" className="create-label">
                  Cluster <span className="imp-mark">*</span>
                </label>
                <div className="create-input mt-5">Select Cluster</div>
                <ul className="cluster-dropdown scrollbar">
                  {/* add active className */}
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                </ul>
                <span className="error-msg">Enter the field</span>
              </div>

              <div className="form-group mt-5">
                <label htmlFor="name" className="create-label">
                  Type <span className="imp-mark">*</span>
                </label>
                <input
                  type="text"
                  className="create-input mt-5"
                  placeholder="Enter Couse Type"
                />
                <span className="error-msg">Enter the field</span>
              </div>
            </div>
            <div className="form-2-col">
              <div className="form-group mt-5">
                <label htmlFor="name" className="create-label">
                  Faculty Name <span className="imp-mark">*</span>
                </label>
                <input
                  type="text"
                  className="create-input mt-5"
                  placeholder="Enter Faculty Name"
                />
                <span className="error-msg">Enter the field</span>
              </div>
              <div className="form-group mt-5">
                <label htmlFor="name" className="create-label">
                  Course Code <span className="imp-mark">*</span>
                </label>
                <input
                  type="text"
                  className="create-input mt-5"
                  placeholder="Enter Couse Code"
                />
                <span className="error-msg">Enter the field</span>
              </div>
            </div>
            <div className="form-2-col">
              <div className="form-group mt-5 cluster">
                <p type="text" className="create-input mt-5">
                  Term
                </p>
                <ul className="cluster-dropdown scrollbar ">
                  <li className="cluster-item">Number of Terms</li>
                  <li className="cluster-item">1</li>
                  <li className="cluster-item">2</li>
                  <li className="cluster-item">3</li>
                  <li className="cluster-item">4</li>
                  <li className="cluster-item">5</li>
                  <li className="cluster-item">6</li>
                </ul>
              </div>
              <div className="form-group mt-5 cluster">
                <p type="text" className="create-input mt-5">
                  Mode
                </p>
                <ul className="cluster-dropdown scrollbar ">
                  <li className="cluster-item">Select Mode</li>
                  <li className="cluster-item">Offline</li>
                  <li className="cluster-item">Online</li>
                  <li className="cluster-item">Hybrid</li>
                </ul>
              </div>
              <div className="form-group mt-5">
                <input
                  type="number"
                  className="create-input mt-5"
                  placeholder="Duration"
                />
              </div>
              <div className="form-group mt-5">
                <input
                  type="number"
                  className="create-input mt-5"
                  placeholder="Credits"
                />
              </div>
            </div>
            <div className="form-upload mt-20">
              <label htmlFor="name" className="create-label">
                Course Code <span className="imp-mark">*</span>
              </label>
              <div className="form">
                <div className="upload-btn-wrapper">
                  <div className="upload-inner">
                    <input
                      className="uploadbox"
                      id="uploadbox"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <button className="upload-btn">Choose a File</button>
                    <p className="file-name">file name</p>
                  </div>
                  <img src="../../assets/icons/delete.svg" alt="" />
                </div>
                <p className="text-2">OR</p>
                <button className="primary-outline-btn">Select Student</button>
              </div>
              <span className="error-msg active">
                *Disclaimer: You should choose one option to add students
              </span>
            </div>
          </form>
          <div className="form-footer-new mt-20">
            <button className="component-back">
              <img src="../assets/icons/back-btn.svg" />
              Back to page
            </button>
            <button className="primary-btn">Next</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCourse;
