import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EMAIL_TYPE } from "../../../constants/textConstant";
import { feedbackData } from "../../../reducers/feedbackSlice";
import MailPopup from "../MailPopup/MailPopup";
import "./ExtendDatePopup.css";
const ExtendDatePopup = (props) => {
  const { emailTemplateData } = useSelector(feedbackData);

  const [isMailOpen, setIsMailOpen] = useState(false);
  const [expiryDate, setExpiryDate] = useState(props.expiryDate);
  const toggleMailPopup = () => {
    setIsMailOpen(!isMailOpen);
  };
  const multipleEvent = () => {
    if (expiryDate !== null) {
      toggleMailPopup();
      props.handleClose();
    }
  };

  useState(() => {
    console.log("--in useState---");
    let date = moment(expiryDate).format("YYYY-MM-DD");
    setExpiryDate(date);
  }, [expiryDate]);

  return (
    <React.Fragment>
      <MailPopup
        emailType={EMAIL_TYPE.extendDataEmail}
        emailTemplateData={emailTemplateData}
        show={isMailOpen}
        feedbackId={props.feedbackId}
        expiryDate={expiryDate}
        handleClose={toggleMailPopup}
      />
      <div className={`popup-outer  ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container extend-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Extend Date
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={() => props.handleClose()}
              />
            </div>
          </div>
          <div className="popup-body">
            <input
              type="date"
              name="expiryDate"
              id="expiryDate"
              value={expiryDate}
              className="create-input mt-5"
              onChange={(e) => setExpiryDate(e.target.value)}
            />
          </div>
          <div className="popup-footer pt-10">
            <div className="btn-group">
              <button
                className="dark-btn-outline"
                onClick={() => props.handleClose()}
              >
                cancel
              </button>
              <button className="green-btn" onClick={multipleEvent}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExtendDatePopup;
