import React, { useEffect, useState,createRef } from "react";
// import "./createFee.css";
import SideMenu from "../../components/Layout/menu/SideMenu";
import PageLoader from "../../components/UI/loader/PageLoader";
import { genericMailData,getAllTemplateData,createTemplate,clearState,updateTemplate } from "../../reducers/genericMailSendSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorToast from "../../components/Toast/ErrorToast";
import ToastMessage from "../../components/Toast/ToastComponent";
import AddStudentListPopup from "../../components/Popups/AddStudentListPopup/AddStudentListPopup";
import SunEditor from "suneditor-react";
import mergeTag from "../../components/Editor/EditorPlugin";
import "suneditor/dist/css/suneditor.min.css";
import TemplateDataDropdown from "../../components/Dropdowns/TemplateDataDropdown/TemplateDataDropdown";
import ContentEditable from "react-contenteditable";
import insertTextAtCursor from "insert-text-at-cursor";
import CreateTemplatePopup from "../../components/Popups/CreateTemplatePopup/CreateTemplatePopup";
import CreateTemplateListPopup from "../../components/Popups/CreateTemplatePopup/CreateTemplateListPop";
export default function GenericMailTemplateList() {
    const {
      isFetching,templateDataList,isSuccess,successMessgae
    } = useSelector(genericMailData);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getAllTemplateData())
    },[])
    const ref = createRef();

  //toast states
  const [showAddCsvPopup, setAddCsvPopup] = useState(false);
  const [isShowError,setIsShowError] = useState(false);
  const [errMessage,setErrMessage] = useState('');
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [dropdownDataChange, setDropdownDataChange] = useState("");
  const [getAllTemplates,setGetAllTemplates]=useState([])
  const [emailSubject, setEmailSubject] = useState("");
  const [emailToken, setEmailToken] = useState(null);
  const [isCreateTemplateOpen,setIsCreateTemplateOpen]=useState(false)
  const [isViewTemplateOpen,setIsViewTemplateOpen]=useState(false)
  const [menuId, setMenuId] = useState(null);
  const [singleTemplateData,setSingleTemplateData]=useState("")
  //Create Template
  const [templateName, setTemplateName] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateBody, setTemplateBody] = useState("");
  const [addedOn, setAddedOn] = useState("");
  const [viewTemplateList,setViewTemplateList]=useState(false)
  const [editTemplateList,setEditTemplateList]=useState(false)



  const [isGoBackOpen, setIsGoBackOpen] = useState(false);

  
  /**onClikc */
  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };
  
  const onClickUpload = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    }
    console.log(event.target)
  };

  const onClickDeleteUpload = () => {
    setSelectedFile("");
    setIsFilePicked(false);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  
  const toggleClosePopup = () => {
    setAddCsvPopup(!showAddCsvPopup);
    onClickDeleteUpload();
  };
  
  const onClickAddApplicant = () => {
    setAddCsvPopup(true)
  };
  
  const dropDownOnChhange = (e) => {
    let data = e.target.value;
    setDropdownDataChange(data);
  };


  const toggleGoBackPopup = () => {
    setIsGoBackOpen(!isGoBackOpen);
  };
  const handleChangeSubject = (e) => {
    setEmailSubject(e.target.value);
  };
  
  const handleEmailToken = (event) => {
    setEmailToken(event.target.value);
    insertTextAtCursor(ref.current, event.target.value);
  };

  const toggleCreateTemplatePopup=()=>{
    setIsCreateTemplateOpen(false)
    setAddedOn("")
    setTemplateBody("")
    setTemplateName("")
    setTemplateSubject("")
    // view
    setSingleTemplateData("")
    setViewTemplateList(false)
  }
  const toggleViewTemplatePopup=()=>{
    setIsViewTemplateOpen(false)
    setAddedOn("")
    setTemplateBody("")
    setTemplateName("")
    setTemplateSubject("")
    // view
    setSingleTemplateData("")
    setViewTemplateList(false)
    //edit
    setEditTemplateList(false)
  }
  const openCreateTemplatePopup=()=>{
    setIsCreateTemplateOpen(true)
  }

  //view Template
  const viewTemplate=(data)=>{
    console.log(data,"data")
    setSingleTemplateData(data)
    setViewTemplateList(true)
    setIsViewTemplateOpen(true)
}
const editTemplate=(data)=>{
    setSingleTemplateData(data)
    setViewTemplateList(false)
    setIsViewTemplateOpen(true)
    setEditTemplateList(true)
}

const createTemplateSubmit=()=>{
  let obj={
    templateName:templateName,
    templateSubject:templateSubject,
    templateBody:templateBody,
    addedOn:addedOn
  }
  dispatch(createTemplate(obj))
}
const editTemplateSubmit=()=>{
  let obj={
    templateName:templateName,
    templateSubject:templateSubject,
    templateBody:templateBody,
    templateId:singleTemplateData?.id
  }
  dispatch(updateTemplate(obj))
}
  //Set Data
  useEffect(()=>{
      setGetAllTemplates(templateDataList)
      setDropdownDataChange(templateDataList?.length >0 && templateDataList[0]?.id)
  },[templateDataList])

  useEffect(()=>{
      if(editTemplateList){
    setTemplateBody(singleTemplateData && singleTemplateData.templateBody)
    setTemplateName(singleTemplateData && singleTemplateData.templateName)
    setTemplateSubject(singleTemplateData && singleTemplateData.templateSubject)
      }
  },[editTemplateList])

  useEffect(()=>{
    if(isSuccess){
      setIsShowSuccess(true)
      dispatch(clearState())
      dispatch(getAllTemplateData())
      toggleCreateTemplatePopup()
      toggleViewTemplatePopup()
    }
  },[isSuccess,dropdownDataChange])
  console.log(successMessgae,"successMessgae")

  return (
    <React.Fragment>
      <SideMenu />
      {isFetching && <PageLoader />}
      <ToastMessage
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessgae}
      />
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <CreateTemplatePopup
        handleClose={toggleCreateTemplatePopup}
        show={isCreateTemplateOpen}
        templateName={templateName}
        templateBody={templateBody}
        templateSubject={templateSubject}
        setTemplateName={setTemplateName}
        setTemplateBody={setTemplateBody}
        setTemplateSubject={setTemplateSubject}
        addedOn={addedOn}
        setAddedOn={setAddedOn}
        createTemplateSubmit={createTemplateSubmit}
       />

       <CreateTemplateListPopup
         handleClose={toggleViewTemplatePopup}
         show={isViewTemplateOpen}
         templateName={templateName}
         templateBody={templateBody}
         templateSubject={templateSubject}
         setTemplateName={setTemplateName}
         setTemplateBody={setTemplateBody}
         setTemplateSubject={setTemplateSubject}
         addedOn={addedOn}
         setAddedOn={setAddedOn}
         viewTemplateList={viewTemplateList}
         singleTemplateData={singleTemplateData}
         updateTemplateSubmit={editTemplateSubmit}
        />
      <div className="main-body">
        <div className="page-header create-header">
          <button className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            Back to page
          </button>
          <button className="primary-btn feed-btn" onClick={openCreateTemplatePopup}>
            <img src="../assets/icons/plus.svg" alt="" /> Create Template
          </button>
        </div>
        
        <div className="feedback-container scrollbar">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr className="p-20">
                <th>Template Name</th>
                <th>Template Subject</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {getAllTemplates?.length >0 &&
                getAllTemplates?.map((res, index) => (
                  <tr key={index}>
                  <td>{res.templateName}</td>
                    <td>{res.templateSubject}</td>
                            <td>
                                <button
                                  className="action-btn"
                                  onClick={() => openActionMenu(index)}
                                  onBlur={closeActionMenu}
                                >
                                  <img src="../assets/icons/options.svg" />
                                  <ul
                                    className={
                                      menuId === index
                                        ? "action-dropdown active"
                                        : "action-dropdown"
                                    }
                                  >
                                      <li
                                        className="dropdown-item"
                                        onClick={() => viewTemplate(res)}
                                      >
                                        <img
                                          src="../assets/icons/preview.svg"
                                          alt=""
                                        />
                                        <p className="dropdown-text">
                                          View Template
                                        </p>
                                      </li>
                                      <li
                                        className="dropdown-item"
                                        onClick={() => editTemplate(res)}
                                      >
                                        <img
                                          src="../assets/icons/preview.svg"
                                          alt=""
                                        />
                                        <p className="dropdown-text">
                                          Edit Template
                                        </p>
                                      </li>
                                  </ul>
                                </button>
                            </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}
