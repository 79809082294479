import React, { useEffect, useState, useRef } from "react";
import "../studentFeeList/studentFeeListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import FeeDropdown from "../../../components/Dropdowns/FeeDropdown/FeeDropdown";
import FeeDuePopup from "../../../components/Popups/FeeDuePopup/FeeDuePopup";
import PageLoader from "../../../components/UI/loader/PageLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./studentCourseFeeListStyle.css";
import { environment } from "../../../environments/environment";

import {
  clearState,
  feeData,
  getCourseFeeStudentList,
  getParticularProgramFeeTypes,
  getStudentFeeList,
} from "../../../reducers/feeSlice";
import moment from "moment";
import VerifyPopup from "../../../components/Popups/VerifyPopup/VerifyPopup";
import LoanPopup from "../../../components/Popups/LoanPopup/LoanPopup";
import MuLoanPopUp from "../../../components/Popups/MuLoan/MuLoan";
import LoanApprovePopup from "../../../components/Popups/LoanApprovePopup/LoanApprovePopup";
import UpdateFeePopup from "../../../components/Popups/UpdateFeePopup/UpdateFeePopup";
import StudentStatusPopup from "../../../components/Popups/StudentStatusPopup/StudentStatusPopup";
import { postDownloadStudentCSV } from "../../../reducers/applicantSlice";
const CourseFeeStudentList = () => {
  /**Student Status Popup State */
  const [studentStatusPopup, setStudentStatusPopup] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [isFilterMenuActive, setisFilterMenuActive] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [descending, setDescending] = useState("");
  const [date, setDate] = useState("");

  /**END */
  const {
    isFetching,
    isComplete,
    isSuccess,
    isError,
    programFeeTypeList,
    courseStudentListData,
    updateStatusSuccess,
    studentCount,
  } = useSelector(feeData);
  const [menuId, setMenuId] = useState(null);

  const [showMoreLoader, setShowMoreLoader] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [allChecked, setAllChecked] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isTotalSelected, setTotalSelected] = useState(false);
  const [feeAmount, setFeeAmount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [applyFilter, setApplyFilter] = useState(false);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [isLoanPopUpOpen, setLoanPopup] = useState(false);
  const [isLoanApprovePopUpOpen, setLoanApprovePopUp] = useState(false);
  const [isUpdateFeePopUpOpen, setUpdateFeePopUp] = useState(false);
  const [isMuLoanPopUpOpen, setMuLoanPopup] = useState(false);
  const [isVerifyPopupOpen, setVerifyPopup] = useState(false);

  const [feeDueData, setFeeDueData] = useState(null);
  const [feeDueId, setFeeDueId] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [utrNumber, setUtrNumber] = useState(null);
  const filter1 = useRef(null);
  const filter2 = useRef(null);
  const filter3 = useRef(null);
  const filter4 = useRef(null);

  const handleAllCheck = (e) => {
    let currStdIds = studentData?.map((data) => data.id);
    const currSet = new Set(currStdIds);
    if (allChecked) {
      setTotalSelected(false);
      setAllChecked(false);
      let newArr = isChecked.filter((val) => !currSet.has(val));
      return setIsChecked(newArr);
    }
    setAllChecked(true);
    let newIsChecked = [...isChecked];
    currStdIds.map((val) => {
      if (!newIsChecked.includes(val)) {
        newIsChecked.push(val);
      }
    });
    return setIsChecked(newIsChecked);
  };

  const handleSingleCheck = (e) => {
    const id = e.target.value;
    if (isChecked.includes(id)) {
      setIsChecked(isChecked.filter((checked_id) => checked_id !== id));
      setTotalSelected(false);
      return setAllChecked(false);
    }
    isChecked.push(id);
    setIsChecked([...isChecked]);
    checkAllChecked();
  };
  const checkAllChecked = () => {
    let stdIds = studentData?.map((item) => item.id);
    let isAll = stdIds.every((val) => isChecked.includes(val));
    if (!isAll) {
      setTotalSelected(false);
    }
    setAllChecked(isAll);
  };
  const clearSelected = () => {
    setTotalSelected((prev) => !prev);
    setIsChecked([]);
    setAllChecked(false);
  };

  const clickApply = () => {
    setisFilterMenuActive(false);

    setPageNo("1");
    if (
      loanStatus !== "" ||
      balancePayableStatus !== "" ||
      studentStatus !== "" ||
      welcomeStatus !== ""
    ) {
      setFilterApplied(true);
      setApplyFilter(true);
    }
  };
  const clickClear = () => {
    setFilterApplied(false);
    setPageNo("1");

    setDescending("");
    setLoanStatus("");
    setBalancePayableStatus("");
    setStudentStatus("");
    setDate("");
    setWelcomeStatus("");
    setisFilterMenuActive(false);

    if (
      loanStatus !== "" ||
      balancePayableStatus !== "" ||
      studentStatus !== "" ||
      welcomeStatus !== "" ||
      descending !== ""
    )
      setApplyFilter(true);
    filter1.current.selectedIndex = 0;
    filter2.current.selectedIndex = 0;
    filter3.current.selectedIndex = 0;
    filter4.current.selectedIndex = 0;
  };

  //filters states
  const [balance, setBalance] = useState("");
  const [dueStatus, setDueStatus] = useState("false");
  const [welcomeStatus, setWelcomeStatus] = useState("");

  const [searchTitle, setSearchTitle] = useState("");
  const [requestType, setRequestType] = useState("");
  const [balancePayableStatus, setBalancePayableStatus] = useState("");
  const [loanStatus, setLoanStatus] = useState("");
  const [studentStatus, setStudentStatus] = useState("");

  const [pageNo, setPageNo] = useState("1");
  const [pageSize, setPageSize] = useState("10");

  const handleSearch = (event) => {
    var search = event.target.value;
    setSearchTitle(search);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchByTitle(e.target.value);
    }
  };

  const searchByTitle = (val) => {
    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: val,
      dueStatus: dueStatus,
      requestType: requestType,
      pageNo: pageNo,
      pageSize: pageSize,
      balanceStatus: balancePayableStatus,
      studentStatus: studentStatus,
      welcomeStatus: welcomeStatus,
      bankLoan: loanStatus,
      descending: descending,
      date: date,
      feeDueStartDate:
        startDate && moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
      feeDueEndDate: endDate && moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
    };
    if (startDate != null && endDate == null) {
      return;
    }
    if (startDate == null && endDate == null) {
      params = {
        searchTitle: val,
        dueStatus: dueStatus,
        requestType: requestType,
        pageNo: pageNo,
        pageSize: pageSize,
        balanceStatus: balancePayableStatus,
        studentStatus: studentStatus,
        welcomeStatus: welcomeStatus,
        descending: descending,
        date: date,
        feeDueStartDate: "",
        feeDueEndDate: "",
        bankLoan: loanStatus,
      };
    }
    dispatch(getCourseFeeStudentList({ obj, params }));
    dispatch(clearState());
    setIsChecked([]);
    setAllChecked(false);
    setPageNo("1");
  };

  const [studentData, setStudentData] = useState([]);
  const [searchParams] = useSearchParams();
  const programBatchId = searchParams.get("programBatchId");
  const programMasterId = searchParams.get("programMasterId");
  const feeMasterId = searchParams.get("feeMasterId");
  const feeId = searchParams.get("feeId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFeePopupActive, setIsFeePopupActive] = useState(false);
  const handleFeePopup = () => {
    setIsFeePopupActive(!isFeePopupActive);
  };

  const [updateType, setUpdateType] = useState("");
  const [loanDetails, setLoanDetails] = useState(null);

  useEffect(() => {
    if (applyFilter || loaded) {
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        dueStatus: dueStatus,
        requestType: requestType,
        pageNo: pageNo,
        pageSize: pageSize,
        balanceStatus: balancePayableStatus,
        studentStatus: studentStatus,
        date: date,
        welcomeStatus: welcomeStatus,
        bankLoan: loanStatus,
        descending: descending,
        feeDueStartDate: moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
        feeDueEndDate: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
      };
      if (startDate != null && endDate == null) {
        return;
      }
      if (startDate == null && endDate == null) {
        params = {
          searchTitle: searchTitle,
          dueStatus: dueStatus,
          requestType: requestType,
          pageNo: pageNo,
          pageSize: pageSize,
          balanceStatus: balancePayableStatus,
          studentStatus: studentStatus,
          welcomeStatus: welcomeStatus,
          descending: descending,
          date: date,
          feeDueStartDate: "",
          feeDueEndDate: "",
          bankLoan: loanStatus,
        };
      }
      dispatch(getCourseFeeStudentList({ obj, params }));
      setApplyFilter(false);
      dispatch(clearState());
    } else {
      console.log("done");
    }
  }, [
    dueStatus,
    requestType,
    pageNo,
    pageSize,
    programBatchId,
    applyFilter,
    endDate,
  ]);

  useEffect(() => {
    if (isSuccess || updateStatusSuccess) {
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        dueStatus: dueStatus,
        requestType: requestType,
        pageNo: pageNo,
        pageSize: pageSize,
        balanceStatus: balancePayableStatus,
        studentStatus: studentStatus,
        descending: descending,
        date: date,
        welcomeStatus: welcomeStatus,
        feeDueStartDate: moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
        feeDueEndDate: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
        bankLoan: loanStatus,
      };
      if (startDate != null && endDate == null) {
        return;
      }
      if (startDate == null && endDate == null) {
        params = {
          searchTitle: searchTitle,
          dueStatus: dueStatus,
          requestType: requestType,
          pageNo: pageNo,
          pageSize: pageSize,
          balanceStatus: balancePayableStatus,
          studentStatus: studentStatus,
          descending: descending,
          welcomeStatus: welcomeStatus,
          date: date,
          feeDueStartDate: "",
          feeDueEndDate: "",
          bankLoan: loanStatus,
        };
      }
      dispatch(getCourseFeeStudentList({ obj, params }));
      dispatch(clearState());
      setStudentStatusPopup(false);
      setStatusType("");
    }
  }, [isSuccess, updateStatusSuccess]);

  useEffect(() => {
    setPageNo("1");
    setIsChecked([]);
    setAllChecked(false);
    setLoaded(false);
  }, [pageSize, requestType, dueStatus, applyFilter]);

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    // console.log("in courseStudent changee");
    // setStudentData(courseStudentListData?.Data);
    // // setFeeDueData((dueData) => {
    // //   console.log("dueData", dueData);
    // //   if (dueData === null) {
    // //     return null;
    // //   }
    // //   let student = courseStudentListData?.Data.find(
    // //     (x) => x.id === dueData?.studentId
    // //   );
    // //   return student?.FeeDues?.find((x) => x.id === dueData.id);
    // // });

    if (courseStudentListData) {
      setFeeAmount(courseStudentListData?.fee?.amount);
      if (loaded === true) {
        setStudentData([...studentData, ...courseStudentListData?.Data]);
        setLoaded("done");
      } else if (loaded === false) {
        setStudentData(courseStudentListData?.Data);
      } else {
        console.log(null);
      }
      setLoaded(false);
    }
  }, [courseStudentListData]);

  const viewStudent = (id) => {
    var isStudent = true;
    window.open(
      `/particularStudentfee?studentId=${id}&programBatchId=${programBatchId}&isStudent=${isStudent}`
    );
  };

  const toggleVerifyPopup = () => {
    setVerifyPopup((p) => !p);
  };

  const toggleLoanPopup = () => {
    setLoanPopup((p) => !p);
  };

  const toggleLoanApprovePopup = () => {
    setLoanApprovePopUp((p) => !p);
  };

  const toggleMuLoanPopup = () => {
    setMuLoanPopup((p) => !p);
  };
  const setVerifyData = (res) => {
    setFeeDueId(res.id);
    setUtrNumber(res.UTR_number);
    // setStudentId(res.id);
  };

  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);
  const [isMailAccess, setMailAccess] = useState(false);

  useEffect(() => {
    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    const isMailAccess = localStorage.getItem("mailSending") === "true";
    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
    if (isMailAccess) {
      setMailAccess(isMailAccess);
    }

    setStudentData([]);
    setApplyFilter(true);
  }, []);

  const toggleStudentStatusPopup = () => {
    setStudentStatusPopup(true);
  };

  const handleLoadClick = () => {
    if (pageNo == Math.ceil(studentCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
    setShowMoreLoader(true);
    setLoaded(true);
  };

  console.log("hgfghfhfhfhfhfhfhf", feeDueData);

  const toggleUpdateFeePopup = () => {
    setUpdateFeePopUp((p) => !p);
    setUpdateType("");
    setLoanDetails(null);
  };

  const onClickStudentCSV = () => {
    var url =
      environment.baseURL +
      "/api/org/programId/" +
      programBatchId +
      "/downloadStudentFeeListCSV";

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onClickLoanRequests = () => {
    navigate(
      `/loanRequest?programMasterId=${programMasterId}&programBatchId=${programBatchId}`
    );
  };

  return (
    <>
      <SideMenu />

      <UpdateFeePopup
        show={isUpdateFeePopUpOpen}
        handleClose={toggleUpdateFeePopup}
        studentId={studentId}
        feeDueId={feeDueId}
        programBatchId={programBatchId}
        updateType={updateType}
        loanDetails={loanDetails}
      />

      <VerifyPopup
        show={isVerifyPopupOpen}
        data={{ utrNumber, feeDueId, studentId }}
        handleClose={toggleVerifyPopup}
        programId={programBatchId}
      />
      <LoanPopup
        show={isLoanPopUpOpen}
        feeDueData={feeDueData}
        studentId={studentId}
        handleClose={toggleLoanPopup}
      />
      <LoanApprovePopup
        show={isLoanApprovePopUpOpen}
        feeDueData={feeDueData}
        studentId={studentId}
        handleClose={toggleLoanApprovePopup}
        programName={
          courseStudentListData?.programDetails?.Program?.programName
        }
        programId={programBatchId}
      />
      <MuLoanPopUp show={isMuLoanPopUpOpen} handleClose={toggleMuLoanPopup} />

      <StudentStatusPopup
        closePopup={() => {
          setStudentStatusPopup(false);
          // setFeeAmount("");
          setStatusType("");
          // setDueDate("");
        }}
        show={studentStatusPopup}
        statusType={statusType}
        studentId={studentId}
        studentDetails={{}}
      />

      {isFetching && pageNo === "1" ? <PageLoader /> : ""}
      <FeeDuePopup
        show={isFeePopupActive}
        closePopup={handleFeePopup}
        setIsFeePopupActive={setIsFeePopupActive}
        student={isChecked}
        programBatchId={programBatchId}
        programMasterId={programMasterId}
        sendFeeDue={true}
        feeId={feeId}
        feeMasterId={feeMasterId}
        clearSelected={clearSelected}
        feeAmount={feeAmount}
        dueType={"feeDue"}
        isNewOffer={true}
      />
      <div className="main-body popupFixed">
        <div className="popupFixedContainer">
          <div className="page-header">
            <button
              className="component-back"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src="../assets/icons/back-btn.svg" />
              Back to Page
            </button>
            <div className="studentDetails">
              <div className="student-inner-left">
                <div className="student-details-outer">
                  <div className="student-details">
                    <p className="student-name text-18">
                      {
                        courseStudentListData?.programDetails?.Program
                          ?.programName
                      }{" "}
                      <img src="../assets/icons/sectionSymbol.svg" />{" "}
                      <span className="text-danger">Course Fee</span>
                    </p>
                  </div>
                  <div className="student-details">
                    <p className="student-mail">
                      {courseStudentListData?.programDetails?.name} &#xb7;
                      {
                        courseStudentListData?.programDetails?.durationInMonths
                      }{" "}
                      months
                    </p>
                  </div>
                </div>
              </div>
              <div className="student-inner-right bg-grey">
                <div className="fee-box total">
                  <p>Total Dues</p>
                  <p className="text-dark">₹ {courseStudentListData?.total}</p>
                </div>
                <div className="fee-box">
                  <p>Received</p>
                  <p className="text-success">
                    ₹ {courseStudentListData?.submitted}
                  </p>
                </div>
                <div className="fee-box">
                  <p>Pending</p>
                  <p className="text-danger">
                    ₹ {courseStudentListData?.pending}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="filter-bar">
            <form action="" className="search-bar">
              <img src="../assets/icons/search.svg" alt="" />
              <input
                type="text"
                name=""
                id=""
                onChange={handleSearch}
                onKeyDown={onSearchKeyPress}
                placeholder="Search"
                className="clean-input"
              />
            </form>

            <div className="total-data-desc">
              Showing {studentData?.length} out of {studentCount}
            </div>

            <div className="filters">
              {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} selectsRange/> */}

              {/* <select id="type" onChange={(e) => setBalance(e.target.value)}>
              <option value="">Balance Payable</option>
              <option value="0">None</option>
              <option value="1">Not None</option>
            </select>
            <select
              id="type"
              onChange={(e) => setDueStatus(e.target.value)}
            >
              <option value="false">Due Status</option>
              <option value="false">Pending</option>
              <option value="true">Sent</option>
            </select>
            <select id="type" onChange={(e) => setRequestType(e.target.value)}>
              <option value="">Request Type</option>
              <option value="muloan">Mu Loan</option>
              <option value="bankloan">Bank Loan</option>
              <option value="banktransfer">Bank Transfer</option>
              <option value="online">Online Payment</option>
            </select> */}

              {/* <DatePicker
              className="datePicker"
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              placeholderText="Fee Due Date"
            /> */}

              {/* <select id="type" onChange={(e) => setLoanStatus(e.target.value)}>
                <option disabled value="">
                  Loan Status
                </option>
                <option value="">All</option>
                <option value="paid">Approved</option>
                <option value="pending">Pending</option>
              </select>
              <select
                id="type"
                onChange={(e) => setBalancePayableStatus(e.target.value)}
              >
                <option value="">Payment Status</option>
                <option value="paid">Paid</option>
                <option value="pending">Pending</option>
              </select>

              <select
                id="type"
                onChange={(e) => setStudentStatus(e.target.value)}
              >
                <option value="">Student Status</option>
                <option value="Active">Active</option>
                <option value="DropOut">DropOut</option>
                <option value="Defer">Defer</option>
              </select>

              <select
                id="type"
                onChange={(e) => setWelcomeStatus(e.target.value)}
              >
                <option value="">Welcome Status</option>
                <option value="pending">Pending</option>
                <option value="sent">Sent</option>
              </select>

              */}

              <button
                className="primary-btn feed-btn"
                onClick={onClickLoanRequests}
              >
                Loan Requests
              </button>

              <button
                className="primary-btn feed-btn"
                onClick={onClickStudentCSV}
              >
                Student CSV
              </button>
              <button
                className={filterApplied ? "filterBtn-active" : "filterBtn"}
                onClick={() => setisFilterMenuActive(!isFilterMenuActive)}
              >
                {" "}
                {filterApplied ? (
                  <img src="../assets/icons/filterWhite.svg" alt="" />
                ) : (
                  <img src="../assets/icons/filter.svg" alt="" />
                )}{" "}
                Filters
              </button>

              <select
                id="type"
                onChange={(e) => {
                  setPageSize(e.target.value);
                  setApplyFilter(true);
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="student-table-outer scrollbar ">
            <table
              className="feedback-table fixedTableDesign maxWidthTable"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th className="check-row">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="checkbox-btn"
                        disabled={viewOnlyAccess || isMailAccess === false}
                        checked={allChecked}
                        onChange={handleAllCheck}
                      />
                      <span className="checkmark" />
                    </label>
                  </th>
                  <th>
                    <div className="filterTableHeader">
                      Student Name
                      <div className="filterImg">
                        <img
                          src="../assets/icons/filterArrowUp.svg"
                          alt=""
                          className={
                            descending === "true" ? "pointer active" : "pointer"
                          }
                          onClick={() => {
                            setDescending("false");
                            setApplyFilter(true);
                            setPageNo("1");
                          }}
                        />
                        <img
                          src="../assets/icons/filterArrowDown.svg"
                          alt=""
                          className={
                            descending === "false"
                              ? "pointer active"
                              : "pointer"
                          }
                          onClick={() => {
                            setDescending("true");
                            setApplyFilter(true);
                            setPageNo("1");
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>Email</th>
                  <th>Mobile</th>
                  {/* <th>Fee Due Date</th> */}
                  <th>Total payable </th>
                  <th>Submited Amount </th>
                  <th>Balance Payable</th>
                  <th>Payment Method</th>
                  {/* <th>Due Status</th> */}
                  {/* <th>Welcome Mail Status</th> */}
                  {!viewOnlyAccess && isMailAccess && (
                    <th className="text-center">Request</th>
                  )}
                  {/* <th>Fee Dues</th> */}
                  <th>Due Sent By</th>
                  {/* <th>Transaction</th> */}
                  <th>
                    <div className="filterTableHeader">
                      Registered On
                      <div className="filterImg">
                        <img
                          src="../assets/icons/filterArrowUp.svg"
                          alt=""
                          className={date === "" ? "pointer active" : "pointer"}
                          onClick={() => {
                            setDate("true");
                            setApplyFilter(true);
                            setPageNo("1");
                            setDescending("");
                          }}
                        />
                        <img
                          src="../assets/icons/filterArrowDown.svg"
                          alt=""
                          className={
                            date === "true" ? "pointer active" : "pointer"
                          }
                          onClick={() => {
                            setDate("");
                            setApplyFilter(true);
                            setPageNo("1");
                            setDescending("");
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  {!viewOnlyAccess && isMailAccess && (
                    <th className="text-center">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {isChecked?.length > 0 && (
                  <tr className="selection">
                    <td className=" pl-0" colSpan="12">
                      <table
                        className="selection-table"
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td>
                              {!isTotalSelected && (
                                <>
                                  <p>
                                    {allChecked && "All"}{" "}
                                    <b>{isChecked?.length} Students</b> on this
                                    page are selected.
                                  </p>

                                  {allChecked && (
                                    <button
                                      className="clear-btn"
                                      onClick={() =>
                                        setTotalSelected((prev) => !prev)
                                      }
                                    >
                                      Select All <b>{studentCount} Students</b>
                                    </button>
                                  )}
                                </>
                              )}
                              {isTotalSelected && (
                                <>
                                  <p>
                                    All <b>{studentCount} Students</b> are
                                    selected.
                                  </p>
                                  <button
                                    className="clear-btn"
                                    onClick={clearSelected}
                                  >
                                    Clear Selected Students
                                  </button>
                                </>
                              )}
                              {"   "}
                              <button
                                className="primary-btn-a"
                                onClick={handleFeePopup}
                              >
                                Send Fee Due
                              </button>

                              {/* <img src="../../assets/icons/delete.svg" alt="" /> */}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}

                {studentData?.map((res, index) => {
                  const {
                    id,
                    firstName,
                    lastName,
                    officialEmail,
                    registrationNumber,
                    mobileNumber,
                    pendingAmount,
                    submitAmount,
                    FeeDues,
                    StudentFeeRecord,
                    studentStatus,
                    createdAt,
                    paymentMethod,
                  } = res;
                  return (
                    <React.Fragment key={id}>
                      <tr>
                        <td className="club-check-box">
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              disabled={
                                viewOnlyAccess || isMailAccess === false
                              }
                              className="checkmark-btn"
                              checked={isChecked.includes(res.id)}
                              value={res.id}
                              onChange={handleSingleCheck}
                            />
                            <span className="checkmarkspan" />
                          </label>
                        </td>
                        {/* <td> {`${firstName} ${lastName}`}</td> */}
                        <td>
                          <div className="programDetail">
                            <div
                              onClick={() =>
                                viewStudent(res.id, res?.FeeDues[0]?.feeId)
                              }
                              className="programDetailText"
                            >
                              <p className="primary-text pointer">
                                {(firstName + " " + lastName)
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.substring(1)
                                  )
                                  .join(" ")}
                              </p>
                            </div>
                            {studentStatus === "Active" ? (
                              <div className="activeCourse">Active</div>
                            ) : (
                              ""
                            )}
                            {studentStatus === "DropOut" ? (
                              <div className="expiredCourse">Dropout</div>
                            ) : (
                              ""
                            )}
                            {studentStatus === "Defer" ? (
                              <div className="DeferCourse">Defer</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        <td>{officialEmail.toLowerCase()}</td>
                        <td>{mobileNumber}</td>
                        <td>₹ {StudentFeeRecord?.totalAmount ?? "0"}</td>
                        <td>
                          <p className="text-success">
                            ₹ {StudentFeeRecord?.submittedAmount ?? "0"}
                          </p>
                        </td>
                        <td>
                          <p className="text-danger">
                            ₹ {StudentFeeRecord?.pendingAmount ?? "0"}
                          </p>
                        </td>

                        <td>
                          {paymentMethod === "Pending"
                            ? "Pending"
                            : paymentMethod === "oneTimePayment"
                            ? "One Time Payment"
                            : paymentMethod}
                        </td>

                        {!viewOnlyAccess && isMailAccess && (
                          <td className="text-center">
                            {FeeDues[0]?.isPaid ? (
                              <p>N/A</p>
                            ) : (
                              <>
                                {FeeDues[0]?.isBankTransfer ||
                                FeeDues[0]?.isMuLoan ||
                                FeeDues[0]?.isBankLoan ? (
                                  <div>
                                    {FeeDues[0]?.isBankTransfer && (
                                      <span
                                        onClick={() => {
                                          toggleVerifyPopup();
                                          setStudentId(id);
                                          setVerifyData(FeeDues[0]);
                                        }}
                                        className="dark-btn"
                                      >
                                        Verify Fee
                                      </span>
                                    )}

                                    {FeeDues[0]?.isBankLoan && (
                                      <span
                                        onClick={() => {
                                          toggleLoanPopup();
                                          setStudentId(id);
                                          setFeeDueData(FeeDues[0]);
                                        }}
                                        className="dark-btn"
                                      >
                                        Bank Loan
                                      </span>
                                    )}
                                  </div>
                                ) : (
                                  <p>N/A</p>
                                )}
                              </>
                            )}
                          </td>
                        )}
                        <td>{res?.feeDueSentBy ?? "--"}</td>
                        <td>{moment(createdAt).format("DD MMMM YYYY")}</td>
                        <td className="text-center">
                          <button
                            className="action-btn"
                            onClick={() => openActionMenu(index)}
                            onBlur={closeActionMenu}
                          >
                            <img src="../assets/icons/options.svg" />
                            <ul
                              className={`action-dropdown ${
                                menuId === index ? "active" : ""
                              } approval-drpdown`}
                            >
                              <li
                                className="dropdown-item "
                                onClick={() => {
                                  toggleStudentStatusPopup();
                                  setStudentId(id);
                                  setStatusType("studentStatus");
                                  // setFeeDueData(FeeDues[0]);
                                }}
                              >
                                <img src="../assets/icons/copy.svg" alt="" />
                                <p className="dropdown-text">Student Status</p>
                              </li>

                              <li
                                className="dropdown-item "
                                onClick={() => {
                                  toggleStudentStatusPopup();
                                  setStudentId(id);
                                  setStatusType("paymentMode");
                                  // setFeeDueData(FeeDues[0]);
                                }}
                              >
                                <img src="../assets/icons/copy.svg" alt="" />
                                <p className="dropdown-text">Payment Method</p>
                              </li>

                              {/* onClick={() => {
                                    toggleUpdateFeePopup();
                                    // setStudentId(id);
                                    setUpdateType("updateFee");
                                    setFeeDueId(due?.id);
                                  }} */}
                              {FeeDues[0]?.isBankLoan &&
                                FeeDues[0]?.FeeTransactions?.length === 0 && (
                                  <li
                                    className="dropdown-item "
                                    onClick={() => {
                                      toggleLoanApprovePopup();
                                      setStudentId(id);
                                      setFeeDueData(FeeDues[0]);
                                    }}
                                  >
                                    <img
                                      src="../assets/icons/copy.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">
                                      Loan Approval
                                    </p>
                                  </li>
                                )}

                              {FeeDues[0]?.isBankLoan &&
                                FeeDues[0]?.FeeTransactions?.length > 0 && (
                                  <li
                                    className="dropdown-item "
                                    onClick={() => {
                                      toggleUpdateFeePopup();
                                      setUpdateType("viewLoanDetail");
                                      setFeeDueId(FeeDues[0]?.id);
                                      setLoanDetails(FeeDues[0]);
                                    }}

                                    // onClick={() => {
                                    //   toggleLoanApprovePopup();
                                    //   setStudentId(id);
                                    //   setFeeDueData(FeeDues[0]);
                                    // }}
                                  >
                                    <img
                                      src="../assets/icons/copy.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">
                                      Loan Details
                                    </p>
                                  </li>
                                )}
                            </ul>
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>

          {studentData?.length !== studentCount && (
            <div className="loadMoreRow">
              <button
                className={`${
                  isFetching && showMoreLoader
                    ? "primary-btn-a active"
                    : "primary-btn-a"
                }`}
                onClick={handleLoadClick}
              >
                <img src="../assets/icons/loader icon-01.png" /> Show More
              </button>
            </div>
          )}

          {/* <div className=" pg-arrows-outer ">
            <p className="pg-arraows-p">
              {pageNo} of{" "}
              {courseStudentListData?.Count <= pageSize
                ? "1"
                : Math.ceil(courseStudentListData?.Count / pageSize)}
            </p>
            <div className="pg-arrows">
              <a onClick={setPreviousPage}>
                <img
                  src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                  className="pg-left"
                />
              </a>
              <a onClick={setNextPage}>
                <img
                  src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                  className="pg-right"
                />
              </a>
            </div>
          </div> */}
        </div>
      </div>
      {/* FILTER - MENU - STARTS */}
      <div className={`filterMenuOuter ${isFilterMenuActive ? "active" : ""}`}>
        <div className="filterHeader">
          <div className="heading">Filters</div>
          <img
            src="../assets/icons/close.svg"
            alt=""
            className="pointer"
            onClick={() => setisFilterMenuActive(false)}
          />
        </div>
        <div className="filterBody">
          {/* <div className="text-1-bold">Admission Fee</div>
          <div className="selectOuter mt-5">
         
          <select id="type" onChange={(e) => setAdmissionFee(e.target.value)} ref={filter1}>
              <option value="">All</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
          <div className="mt-15 text-1-bold">Interview Status</div>
         
          <div className="selectOuter mt-5">
         
          <select
              id="type"
              onChange={(e) => setInterviewStatus(e.target.value)} ref={filter2}
            >
              <option value="">All</option>
              <option value="Completed">Completed</option>
              <option value="Pending">Pending</option>
              <option value="Scheduled">Scheduled</option>
            </select>
          </div> */}
          {/* <div className="selectOuter"></div> */}
          <div className="text-1-bold">Loan Status</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setLoanStatus(e.target.value)}
              ref={filter4}
            >
              <option value="">All</option>
              <option value="paid">Approved</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div className="text-1-bold mt-15">Payment Status</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setBalancePayableStatus(e.target.value)}
              ref={filter1}
            >
              <option value="">All</option>
              <option value="paid">Paid</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div className="text-1-bold mt-15">Student Status</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setStudentStatus(e.target.value)}
              ref={filter2}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="DropOut">DropOut</option>
              <option value="Defer">Defer</option>
            </select>
          </div>

          <div className="text-1-bold mt-15">Welcome Status</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setWelcomeStatus(e.target.value)}
              ref={filter3}
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="sent">Sent</option>
            </select>
          </div>
        </div>

        <div className="filterFooter">
          <div className="btnGrp">
            <button className="dark-btn-outline-a" onClick={clickClear}>
              Clear
            </button>
            <button className="primary-btn-a" onClick={clickApply}>
              Apply
            </button>
          </div>
        </div>
      </div>
      {/* FILTER- MENU- ENDS */}
    </>
  );
};

export default CourseFeeStudentList;
