import React, { useState } from 'react'
import './TagInput.css';

const TagInput = ({placeholder, selected, setSelected}) => {
    
    const [temp, setTemp] = useState({id: 0, name: ''});

    const handleEnter = (e) => {
        if(e.key === 'Enter'){
            if(temp.name !== ''){
                setTemp({...temp,id: temp.id + 1});
                setSelected(prev => [...prev,temp]);
                setTemp({id: temp.id+1,name:''});
            }
        }
    }

    const handleClick = (e) => {
        setSelected(selected.filter(item => +e.target.id !== item.id));
    }

  return (
    <div className='tag-input'>
        <div className='items-container'>{selected.map(item => (
            <div key={item.id} className='items'><span>{item.name}</span> <img id={item.id} onClick={handleClick} src="/assets/icons/crossW.svg" alt="cut" /></div>
        ))}</div>
        <input type="text" placeholder={placeholder} onKeyPress={handleEnter} value={temp.name} onChange={ e => setTemp({...temp,name: e.target.value})} />
    </div>
  )
}

export default TagInput