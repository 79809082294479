import React, { useState } from 'react';
import "./RatingBox.css";

const RatingBox = () =>{
    const[rating,setRating] = useState(0)
    return (
    <div className='rating-wrapper mt-30'>
        <div className="rating">
        <input type="radio" name="rating" id="rating-5" onClick={()=>setRating(5)}/>
        <label htmlFor="rating-5"></label>
        <input type="radio" name="rating" id="rating-4" onClick={()=>setRating(4)}/>
        <label htmlFor="rating-4"></label>
        <input type="radio" name="rating" id="rating-3" onClick={()=>setRating(3)}/>
        <label htmlFor="rating-3"></label>
        <input type="radio" name="rating" id="rating-2" onClick={()=>setRating(2)}/>
        <label htmlFor="rating-2"></label>
        <input type="radio" name="rating" id="rating-1" onClick={()=>setRating(1)}/>
        <label htmlFor="rating-1"></label>
        </div>
        <div className="rating-count">
            {rating} out of 5
        </div>
    </div>
    )
}

export default RatingBox;