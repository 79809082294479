import React from "react";
const MeetingCancelPopup = (props) => {
  return (
    <React.Fragment>
      <div
        className={`popup-outer meeting-cancel-popup ${
          props.show ? "show-popup" : ""
        }`}
      >
        <div className="popup-container delete-poup">
          <div className="popup-header">
            <div className="popup-heading">
              {props.singleMeetTicketOpen ? "SOS" : "Cancel Meeting"}
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.cancelMeetingHandleClose}
              />
            </div>

            <div className="popup-header-body">
              <div className="header-left">
                <p className="text-7">
                  {props.singleMeetTicketOpen
                    ? "Please raise the concern to the tech team."
                    : " Are you sure you want to Cancel this meeting session? "}
                </p>
              </div>
              <textarea
                className="meeting-cancel-textarea mt-10"
                rows={4}
                placeholder="Description"
                value={props.cancelReason}
                onChange={(e) => props.setCancelReason(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="popup-footer mt-20">
            <div className="btn-group">
              <button
                onClick={props.cancelMeetingHandleClose}
                className="dark-btn-outline"
              >
                No
              </button>
              <button onClick={props.cancelMeetSubmit} className="danger-btn">
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MeetingCancelPopup;
