import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { postAddTimeLineNote } from "../../../reducers/feeSlice";
import ErrorToast from "../../Toast/ErrorToast";
import "./AddANotePopupStyle.css";

const AddANotePopup = (props) => {
  const [noteMessage, setNoteMessage] = useState("");
  const [followerName, setFollowerName] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [followUpNote, setFollowUpNote] = useState("");

  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const dispatch = useDispatch();
  let adminId;

  let setTokenValues = () => {
    adminId = localStorage.getItem("adminId");
  };

  useEffect(() => {
    setTokenValues();
  }, []);

  const onClickAddNote = () => {
    if (noteMessage === "") {
      setIsShowError(true);
      setErrMessage("Please write comment");

      return;
    }

    let obj = {
      note: noteMessage,
      isFollowUp: false,
      date: new Date(),
      programId: props.programBatchId,
      applicantId: props.studentId,
      followerName: "",
    };
    dispatch(postAddTimeLineNote(obj));
    clearPopupData();
  };

  const onClickAddFollowUpNote = () => {
    if (followerName === "" || dateTime === "" || followUpNote === "") {
      setIsShowError(true);
      setErrMessage("Please fill all fields");

      return;
    }

    let obj = {
      note: followUpNote,
      isFollowUp: true,
      date: dateTime,
      programId: props.programBatchId,
      applicantId: props.studentId,
      followerName: followerName,
    };

    dispatch(postAddTimeLineNote(obj));
    clearPopupData();
  };

  const clearPopupData = () => {
    setNoteMessage("");
    setFollowerName("");
    setDateTime("");
    setFollowUpNote("");
  };

  const handleClose = () => {
    props.handleClose();
    clearPopupData();
  };

  return (
    <>
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />

      {props.noteType === "normalNote" && (
        <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
          <div className="popup-container addANoteContainer">
            <div className="popup-header">
              <div className="popup-heading">
                <p className="heading">Create a Note</p>
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              </div>
            </div>
            <div className="popup-body">
              <div className="form-grp">
                <label htmlFor="comment" className="create-label">
                  Write Note
                </label>
                <textarea
                  type="textarea"
                  name="comment"
                  id="comment"
                  className="form-input"
                  placeholder="Write a Comment"
                  rows="5"
                  cols="5"
                  spellcheck="false"
                  onChange={(e) => setNoteMessage(e.target.value)}
                />
              </div>
            </div>
            <div className="popup-footer mb-20">
              <button onClick={onClickAddNote} className="primary-btn w-100">
                Add
              </button>
            </div>
          </div>
        </div>
      )}
      {props.noteType === "followUpNote" && (
        <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
          <div className="popup-container addANoteContainer">
            <div className="popup-header">
              <div className="popup-heading">
                <p className="heading">Add Follow Up</p>
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              </div>
            </div>
            <div className="popup-body">
              <div className="form-grp">
                <label htmlFor="name" className="create-label">
                  Follower Name
                </label>

                <input
                  type="text"
                  id="text"
                  name="text"
                  placeholder="Enter name"
                  className="form-input"
                  onChange={(e) => setFollowerName(e.target.value)}
                />
                {/* <select name="name" id="name">
                  <option value="" disabled selected>
                    Select Member
                  </option>
                  <option value="">1</option>
                  <option value="">2</option>
                </select> */}
              </div>
              <div className="form-grp mt-20">
                <label htmlFor="datetime" className="create-label">
                  Date & Time
                </label>
                <input
                  type="datetime-local"
                  id="datetime"
                  name="datetime"
                  className="form-input"
                  onChange={(e) => setDateTime(e.target.value)}
                />
              </div>
              <div className="form-grp mt-20">
                <label htmlFor="comment" className="create-label">
                  Write Note
                </label>
                <textarea
                  type="textarea"
                  name="comment"
                  id="comment"
                  className="form-input"
                  placeholder="Write a Comment"
                  rows="5"
                  cols="5"
                  spellcheck="false"
                  onChange={(e) => setFollowUpNote(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="popup-footer mb-20">
              <button
                onClick={onClickAddFollowUpNote}
                className="primary-btn w-100"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddANotePopup;
