import React, { useEffect } from "react";
import "./ReportPopupStyle.css";
import { COMMON_TEXT } from "../../../constants/textConstant";

const Reportpopup = (props) => {
  var data = null;
  if (props.data !== null) {
    data = props.data;
  }
  const handleClick = (feedId, stuId) => {
    window.open(`/feedbackAnswer?feedId=${feedId}&studId=${stuId}`);
  };

  const downloadFeedbackReportCSV = (table_id) => {
    setTimeout(() => {
      let rows = document.querySelectorAll("table#" + table_id + " tr");
      let csv = [];
      for (let i = 0; i < rows.length; i++) {
        let row = [],
          cols = rows[i].querySelectorAll("td, th");
        for (let j = 0; j < cols.length; j++) {
          let data = cols[j].textContent
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ");
          data = data.replace(/"/g, '""');
          row.push('"' + data + '"');
        }
        csv.push(row.join(","));
      }
      let csv_string = csv.join("\n");
      // let filename = this.surveyTitle +'_'+ new Date().toLocaleDateString() + '.csv';
      let filename = "first Title" + ".csv";
      let link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 2000);
  };

  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container feedback-report">
          <div className="popup-header">
            <div className="popup-heading">
              {COMMON_TEXT.feedbackReportText}
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>
            <div className="popup-header-body">
              <div className="header-left">
                <p>
                  {data && data.title}{" "}
                  {data && data.isAnonymous
                    ? "( Anonymous)"
                    : "( Non Anonymous)"}{" "}
                </p>
                <p className="text-2">{COMMON_TEXT.studentReportList}</p>
              </div>
              <div className="header-right">
                <button
                  onClick={() => downloadFeedbackReportCSV("survey_table")}
                  className="primary-btn"
                >
                  <img src="../assets/icons/download.svg" alt="" />{" "}
                  {COMMON_TEXT.exportCsv}
                </button>
              </div>
            </div>
          </div>
          <div className="popup-body scrollbar">
            <table
              id="survey_table"
              className="pop-up-table hide"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th>Student</th>
                  <th>Student email</th>
                  {props.csvStudent[0]?.FeedbackQuestions.map((item) => (
                    <th>
                      <span>{item.questionName}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.csvStudent.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.Student && item.Student.firstName}{" "}
                      {item.Student && item.Student.lastName}
                    </td>
                    <td>{item.Student && item.Student.officialEmail}</td>

                    {item.FeedbackQuestions.map((element, index) => (
                      <td key={index}>
                        {/* {index} */}

                        {/* {element.questionType === "multiSelect" && (
                          // <span>this is multi select</span>
                          <>
                            {element.FeedbackAnswers.map((answer) => (
                              <>
                                {item.studentId === answer.studentId && (
                                  <td>{answer.answer}</td>
                                )}
                              </>
                            ))}
                          </>
                        )} */}
                        {element.questionType === "singleSelect" && (
                          <>
                            {element.FeedbackAnswers.map((answer, index) => (
                              <>
                                {item.studentId === answer.studentId && (
                                  <span key={index}>{answer.answer}</span>
                                )}
                              </>
                            ))}
                          </>
                        )}

                        {element.questionType === "multiSelect" && (
                          <>
                            {element.FeedbackAnswers.map((answer, index) => (
                              <>
                                {item.studentId === answer.studentId && (
                                  <span key={index}>{answer.answer},</span>
                                )}
                              </>
                            ))}
                          </>
                        )}

                        {element.questionType === "uploadMedia" && (
                          <>
                            {element.FeedbackAnswers.map((answer, index) => (
                              <>
                                {item.studentId === answer.studentId && (
                                  <span key={index}>{answer.answer}</span>
                                )}
                              </>
                            ))}
                          </>
                        )}

                        {element.questionType === "paragraph" && (
                          <>
                            {element.FeedbackAnswers.map((answer, index) => (
                              <>
                                {item.studentId === answer.studentId && (
                                  <span key={index}>{answer.answer}</span>
                                )}
                              </>
                            ))}
                          </>
                        )}

                        {element.questionType === "rating" && (
                          <>
                            {element.FeedbackAnswers.map((answer, index) => (
                              <>
                                {item.studentId === answer.studentId && (
                                  <span key={index}>{answer.answer}</span>
                                )}
                              </>
                            ))}
                          </>
                        )}
                      </td>
                    ))}

                    {/* {props.csvStudent?.FeedbackQuestions.map((item) => (
                      <td>hello hello</td>
                    ))} */}
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="pop-up-table" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th>Student</th>
                  <th>Program</th>
                  <th>Batch</th>
                  <th>Feedback Type</th>
                </tr>
              </thead>
              <tbody>
                {props.studentList.map((item, index) => (
                  <tr>
                    <td>
                      {item.Student && item.Student.firstName}{" "}
                      {item.Student && item.Student.lastName}
                    </td>
                    <td>
                      {item.Student &&
                        item.Student.ProgramDetail &&
                        item.Student.ProgramDetail.Program &&
                        item.Student.ProgramDetail.Program.programName}
                    </td>
                    <td>
                      {item.Student &&
                        item.Student.ProgramDetail &&
                        item.Student.ProgramDetail.batch}
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          handleClick(
                            item && item.feedbackId,
                            item.Student && item.Student.id
                          )
                        }
                        className="danger-btn-outline"
                      >
                        {COMMON_TEXT.viewResponseText}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Reportpopup;
