import React, { useState, useEffect } from "react";
import FeeDropdown from "../../Dropdowns/FeeDropdown/FeeDropdown";
import {
  feedbackData,
  getEmailTemplate,
} from "../../../reducers/feedbackSlice";
import { useSelector, useDispatch } from "react-redux";
import MailPopup from "../../../components/Popups/MailPopup/MailPopup";
import "../FeeDuePopup/FeeDuePopupStyle.css";
import {
  COMMON_TEXT,
  EMAIL_TEMPLATE_NAME,
  EMAIL_TYPE,
  Acceptance_Letter,
} from "../../../constants/textConstant";
import {
  clearFeeDue,
  feeData,
  getCourseFeeStudentList,
  getStudentFeeList,
  postEditFeeDueAmount,
  postCreateFeeDueAmount,
} from "../../../reducers/feeSlice";
import ToastMessage from "../../Toast/ToastComponent";
import {
  applicantData,
  getApplicantScholarship,
  getWelcomeLetterStudentList,
  postEditScholarship,
  postUpdateApplicantScholarship,
} from "../../../reducers/applicantSlice";
import ErrorToast from "../../Toast/ErrorToast";
const EditScholarship = (props) => {
  const { isFetching, isSuccess, scholarshipDetails } =
    useSelector(applicantData);

  const { editFeeDueSuccess, createFeeDueSuccess } = useSelector(feeData);
  const [reason, setReason] = useState("");
  const { admissionAmount } = useSelector(applicantData);
  const [programId, setProgramId] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [feeAmount, setFeeAmount] = useState("");
  const [feeDate, setFeeDate] = useState("");
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState(false);
  const [validateDate, setDateValidation] = useState(false);
  const [scholarship, setScholarship] = useState("");

  const [oldScholarship, setOldScholarship] = useState("");
  const [newScholarship, setNewScholarship] = useState("");

  const dispatch = useDispatch();

  const handleClose = () => {
    setScholarship("");
    setReason("");
    props.closePopup();
  };

  const emptyScholarshipPopup = () => {
    setScholarship("");
    setReason("");

    console.log("fdfdfdf");
  };

  // useEffect(() => {
  //   setFeeAmount(props?.feeAmount);
  //   setFeeDate("");
  // }, [props?.feeAmount]);

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    setLoggedInUser(userName);
  }, []);

  const onClickUpdate = () => {
    if (reason === "" || scholarship === "") {
      setErrorMessage(true);
    } else {
      const obj = {
        amount: scholarship,
        programId: props?.programBatchId,
        studentId: props?.studentId,
        applicantId: props?.studentId,
        reason: reason,
      };
      console.log(obj, "send email");
      dispatch(postEditScholarship(obj));
      props.setPageNo("1");
    }
  };

  const onClickAcceptButton = () => {
    const obj = {
      amount: scholarshipDetails?.editStudentScholarships[0]?.amount,
      programId: props?.programBatchId,
      status: "Approved",
      isStudent: scholarshipDetails?.isStudent,
      studentId: props?.studentId,
      oldAmount: scholarshipDetails?.scholarshipAmount,
    };
    console.log(obj, "send email");
    dispatch(postUpdateApplicantScholarship(obj));
    props.setPageNo("1");
  };

  const onClickRejectButton = () => {
    const obj = {
      amount: scholarshipDetails?.editStudentScholarships[0]?.amount,
      programId: props?.programBatchId,
      status: "Rejected",
      isStudent: scholarshipDetails?.isStudent,
      studentId: props?.studentId,
      oldAmount: scholarshipDetails?.scholarshipAmount,
    };
    console.log(obj, "send email");
    dispatch(postUpdateApplicantScholarship(obj));
    props.setPageNo("1");
  };

  useEffect(() => {
    if (props?.scholarshipType === "view") {
      dispatch(getApplicantScholarship(props?.studentId));
    }

    if (props?.scholarshipType === "edit") {
      console.log("inside function", props?.scholarshipType);
      emptyScholarshipPopup();
    }
  }, [props?.scholarshipType]);

  console.log("printing props", props);

  useEffect(() => {
    if (scholarshipDetails) {
      setReason(scholarshipDetails?.editStudentScholarships[0]?.reason);
      setScholarship(scholarshipDetails?.editStudentScholarships[0]?.amount);
    }
  }, [scholarshipDetails]);

  return (
    <>
      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Created Successfully"}
      />

      <ErrorToast
        show={showErrorMessage}
        setShow={() => setErrorMessage(false)}
        message={"Please fill all fields"}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container due-popup editScholarship">
          <div className="popup-heading">
            Edit Scholarship{" "}
            <img
              src="../assets/icons/close.svg"
              alt=""
              className="close-btn"
              onClick={handleClose}
            />
          </div>
          {props?.scholarshipType === "edit" && (
            <>
              <div className="popup-body">
                <form action="">
                  <div className="form-grp">
                    <label htmlFor="amount">
                      Scholarship Percentage <span className="imp-mark">*</span>
                    </label>
                    <select
                      id="type"
                      className="popup-dropdown mt-5"
                      onChange={(e) => setScholarship(e.target.value)}
                      value={scholarship}
                    >
                      <option value="">Choose Scholarship</option>
                      <option value="0">No Scholarship</option>
                      {[...Array(10)].map((p, i) => (
                        <option key={i} value={(i + 1) * 5}>
                          {(i + 1) * 5}%
                        </option>
                      ))}
                      <option value="75">75% (Special Case)</option>
                      <option value="100">100% Tuition Fee</option>
                    </select>

                    <span
                      className={
                        validateDate ? "error-msg active" : "error-msg"
                      }
                    >
                      Please select scholarship
                    </span>
                  </div>

                  <div className="form-grp">
                    <label htmlFor="">
                      Reason <span className="imp-mark">*</span>
                    </label>

                    <textarea
                      className="create-input"
                      cols="5"
                      rows="5"
                      placeholder="type your reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    ></textarea>

                    <span
                      className={
                        validateDate ? "error-msg active" : "error-msg"
                      }
                    >
                      Please enter valid reason
                    </span>
                  </div>
                </form>
              </div>
              <div className="popup-footer">
                {props?.scholarshipType === "edit" && (
                  <button className="primary-btn" onClick={onClickUpdate}>
                    Update
                  </button>
                )}
              </div>
            </>
          )}

          {props?.scholarshipType === "view" && (
            <>
              <div className="popup-body">
                <form action="">
                  <div className="form-2-col">
                    <div className="form-grp">
                      <label htmlFor="amount">Old Scholarship</label>
                      <input
                        type="text"
                        className="create-input"
                        value={scholarshipDetails?.scholarshipAmount + "%"}
                        disabled={true}
                      />
                    </div>

                    <div className="form-grp">
                      <label htmlFor="amount">New Scholarship</label>

                      <input
                        type="text"
                        className="create-input"
                        value={
                          scholarshipDetails?.editStudentScholarships[0]
                            ?.amount + "%"
                        }
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="form-grp">
                    <label htmlFor="">Reason</label>

                    <textarea
                      className="create-input"
                      cols="5"
                      rows="5"
                      placeholder="type your reason"
                      value={reason}
                      disabled
                      onChange={(e) => setReason(e.target.value)}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="popup-footer">
                <div className="btn-group">
                  <button
                    onClick={onClickRejectButton}
                    className="dark-btn-outline"
                  >
                    Reject
                  </button>

                  <button className="green-btn" onClick={onClickAcceptButton}>
                    Accept
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EditScholarship;
