import React from "react";
import { useDispatch } from "react-redux";
import { setQuestionType } from "../../../reducers/pageLayoutSlice";
import "./EveryQuestionMenu.css";

function EveryQuestionMenu({
  questionArray,
  scrollTo,
  setQuestionDetail,
  setNewQuestion,
}) {
  const onItemClick = (id, questionType) => {
    scrollTo(id);
  };

  const onItemDeleteClick = (id, index) => {
    console.log("value", id);

    var updatedArray = questionArray.filter((item) => item.id !== id);

    var filteredArray = [];

    updatedArray.forEach((element, index) => {
      var item = {
        id: index + 1,
        questionType: element.questionType,
        questionName: element.questionName,
        isMandatory: element.isMandatory,
        option: element.option,
      };
      filteredArray.push(item);
    });

    setNewQuestion(filteredArray);
    console.log("print value after update", filteredArray);
  };

  return (
    <div className="asked-questions">
      <ul>
        {questionArray.map((item, index) => (
          <li key={index} className="text-3">
            <img
              src={`https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/${item.questionType}.svg`}
              alt=""
            />
            <p
              className="question"
              onClick={() => onItemClick(item.id, item.questionType)}
            >
              Q{index + 1}. {item.questionName}
            </p>
            <img
              onClick={() => onItemDeleteClick(item.id, index)}
              className="remove_question"
              src="../../assets/icons/delete.svg"
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EveryQuestionMenu;
