import React from "react";
import "./GradeListStyle.css";
import SideMenu from "../../../../components/Layout/menu/SideMenu";

const GradeList = () => {
  return (
    <>
      <SideMenu />
      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">
            <span className="text-grey">Course 1 |</span> Grades
          </p>
          <button className="primary-btn feed-btn">Publish</button>
        </div>
        <div className="filter-bar">
          <div className="left-filters">
            <select id="type">
              <option value="">Program</option>
              <option value="Class">Class</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
            <select id="type">
              <option value="">Batch</option>
              <option value="Class">Class</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
            <select id="type">
              <option value="">Course</option>
              <option value="Class">Class</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div className="filters">
            <select id="type">
              <option value="">Term</option>
              <option value="Class">Class</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
            <select id="type">
              <option value="">All Status</option>
              <option value="expire">Expired</option>
              <option value="draft">Draft</option>
              <option value="active">Active</option>
            </select>
            <select id="type">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div className="feedback-container scrollbar">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Course name</th>
                <th>Student Name & Email</th>
                <th>Assignment 1</th>
                <th>Assignment 2</th>
                <th>Grades</th>
                <th>Total Marks</th>
                <th>GPA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="custom-width">
                  How Does the Economy Work (Part 1)
                </td>
                <td className="custom-width">
                  <p className="faculty-details">
                    <b>Rimpa Roy</b> Rimpa@gmail.com
                  </p>
                </td>
                <td>80</td>
                <td>80</td>
                <td>
                  <input className="create-input" value={"A"} />
                </td>
                <td>
                  <input className="create-input" value={60} />
                </td>
                <td>
                  <input className="create-input" value={6} />
                </td>
              </tr>
              <tr>
                <td className="custom-width">
                  How Does the Economy Work (Part 1)
                </td>
                <td className="custom-width">
                  <p className="faculty-details">
                    <b>Rimpa Roy</b> Rimpa@gmail.com
                  </p>
                </td>
                <td>80</td>
                <td>80</td>
                <td>
                  <input className="create-input" placeholder="Enter Grades" />
                </td>
                <td>
                  <input className="create-input" placeholder="Enter Marks" />
                </td>
                <td>
                  <input className="create-input" placeholder="Enter Grades" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GradeList;
