import React, { useEffect, useState } from "react";
import SideMenu from "../../components/Layout/menu/SideMenu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDailyTransactionUpdates,
  feeData,
  getOrganizationPrograms,
  getSingleBatchByProgramId,
  postUpdateReceiptNumber,
  clearState,
} from "../../reducers/feeSlice";
import PageLoader from "../../components/UI/loader/PageLoader";
import moment from "moment";
import { environment } from "../../environments/environment";
import StudentStatusPopup from "../../components/Popups/StudentStatusPopup/StudentStatusPopup";

const TransactionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isFetching,
    transactionData,
    programList,
    singleProgramBatch,
    updateStatusSuccess,
  } = useSelector(feeData);
  const [isFilterMenuActive, setisFilterMenuActive] = useState(false);
  const [getAllTransaction, setGetAllTransaction] = useState([{}]);
  //   console.log(getAllTransaction, "data");
  const [program, setProgram] = useState("");
  const [batch, setBatch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [getBatch, setGetBatch] = useState([]);
  const [receiptNumber, setReceiptNumber] = useState("");

  const [studentStatusPopup, setStudentStatusPopup] = useState(false);
  const [statusType, setStatusType] = useState("");

  const [studentDetails, setStudentDetails] = useState({});
  const [studentId, setStudentId] = useState("");

  useEffect(() => {
    if (singleProgramBatch) {
      setGetBatch(singleProgramBatch);
    } else {
      setGetBatch([]);
    }
  }, [singleProgramBatch]);

  useEffect(() => {
    let obj = {
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
      programMasterId: "allPrograms",
      programId: "allCohorts",
    };
    dispatch(getDailyTransactionUpdates(obj));
    dispatch(getOrganizationPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlurFunction = () => {
    console.log("value");
  };

  useEffect(() => {
    setGetAllTransaction(transactionData);
  }, [transactionData]);

  const applyFilter = () => {
    let obj = {
      fromDate: fromDate ? fromDate : moment(new Date()).format("YYYY-MM-DD"),
      toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
      programMasterId: program ? program : "allPrograms",
      programId: batch ? batch : "allCohorts",
    };
    dispatch(getDailyTransactionUpdates(obj));
    setisFilterMenuActive(false);
  };

  useEffect(() => {
    if (updateStatusSuccess) {
      let obj = {
        fromDate: fromDate ? fromDate : moment(new Date()).format("YYYY-MM-DD"),
        toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
        programMasterId: program ? program : "allPrograms",
        programId: batch ? batch : "allCohorts",
      };
      dispatch(getDailyTransactionUpdates(obj));
      dispatch(clearState());
      clearPopupData();
    }
  }, [updateStatusSuccess]);

  const clearFilter = () => {
    setFromDate("");
    setToDate("");
    setBatch("");
    setProgram("");
    setGetBatch([]);
    let obj = {
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
      programMasterId: "allPrograms",
      programId: "allCohorts",
    };
    dispatch(getDailyTransactionUpdates(obj));
    setisFilterMenuActive(false);
  };

  const programHandleChange = (e) => {
    let data = e.target.value;
    setProgram(data);
    dispatch(getSingleBatchByProgramId(data));
  };
  const batchHandleChange = (e) => {
    let data = e.target.value;
    setBatch(data);
  };

  const handlereceiptNumber = (e, id) => {
    const { value } = e.target;

    // console.log("fjdhufhudhfhdf", value);
    setGetAllTransaction((room) =>
      room?.map((list, index) =>
        list.id === id ? { ...list, receiptNumber: value } : list
      )
    );
  };

  const updateReceiptNumber = (e) => {
    console.log("printing event", e.target.id);
    console.log("printing value", e.target.value);

    const obj = {
      transactionId: e.target.id,
      receiptNumber: e.target.value,
    };
    console.log(obj);
    dispatch(postUpdateReceiptNumber(obj));
    setReceiptNumber("");

    // let object = getAllTransaction.filter((o) => {
    //   if (o.id === id) {
    //     return o;
    //   }
    // });

    // console.log("printing object data", object);
  };

  const handleInvoiceGeneratedOn = (e, id) => {
    const { value } = e.target;

    // console.log("fjdhufhudhfhdf", value);
    setGetAllTransaction((room) =>
      room?.map((list, index) =>
        list.id === id ? { ...list, invoiceGeneratedOn: value } : list
      )
    );
  };

  const updateInvoiceGeneratedOn = (e) => {
    console.log("printing event", e.target.id);
    console.log("printing value", e.target.value);

    const obj = {
      transactionId: e.target.id,
      invoiceGeneratedOn: e.target.value,
    };
    console.log(obj);
    dispatch(postUpdateReceiptNumber(obj));
    setReceiptNumber("");

    // let object = getAllTransaction.filter((o) => {
    //   if (o.id === id) {
    //     return o;
    //   }
    // });

    // console.log("printing object data", object);
  };

  const toggleStudentStatusPopup = () => {
    setStudentStatusPopup(true);
  };

  const clearPopupData = () => {
    setStudentStatusPopup(false);
    setStatusType("");
    setStudentId("");
    setStudentDetails({});
  };

  return (
    <>
      <SideMenu />
      {isFetching && <PageLoader />}
      <StudentStatusPopup
        closePopup={clearPopupData}
        show={studentStatusPopup}
        statusType={statusType}
        studentId={studentId}
        feeDueId={null}
        studentDetails={studentDetails}
      />
      <div className="main-body">
        {/* FILTER - MENU - STARTS */}
        <div
          className={`filterMenuOuter ${isFilterMenuActive ? "active" : ""}`}
        >
          <div className="filterHeader">
            <div className="heading">Filters</div>
            <img
              src="../assets/icons/close.svg"
              alt=""
              className="pointer"
              onClick={() => setisFilterMenuActive(false)}
            />
          </div>
          <div className="filterBody">
            <div className="selectOuter">
              <select value={program} onChange={programHandleChange}>
                {/* <option selected disabled>Select Program</option> */}
                <option>Select Program</option>
                <option value={"allPrograms"}>All</option>
                {programList?.length > 0 &&
                  programList?.map((res, i) => (
                    <option value={res.id} key={i}>
                      {res.programName}
                    </option>
                  ))}
              </select>
            </div>
            {program === "allPrograms" ? (
              ""
            ) : (
              <div className="selectOuter mt-10">
                <select value={batch} onChange={batchHandleChange}>
                  <option>Select Cohorts</option>
                  <option value={"allCohorts"}>All</option>
                  {getBatch?.length > 0 &&
                    getBatch?.map((res, i) => (
                      <option value={res.id} key={i}>
                        {res.name}
                      </option>
                    ))}
                </select>
              </div>
            )}
            <p className="text-3 mt-10">From Date</p>
            <input
              type="date"
              className="new-filter-input mt-5"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <p className="text-3 mt-10">To Date</p>
            <input
              type="date"
              className="new-filter-input mt-5"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="filterFooter">
            <div className="btnGrp">
              <button className="dark-btn-outline-a" onClick={clearFilter}>
                Clear
              </button>
              <button className="primary-btn-a" onClick={applyFilter}>
                Apply
              </button>
            </div>
          </div>
        </div>
        {/* FILTER- MENU- ENDS */}
        <div className="page-header">
          <p className="component-heading">All Transaction</p>{" "}
          <p
            onClick={() => {
              var url =
                environment.baseURL +
                `/api/org/downloadInvoicesCSV?fromDate=${fromDate}&toDate=${toDate}&programMasterId=${program}&programId=${batch}`;

              window.open(url, "_blank", "noopener,noreferrer");
            }}
            className="component-heading"
          >
            Download Csv
          </p>
        </div>
        <div className="filter-bar">
          <div id="type" className="faculty-div">
            <button onClick={() => navigate(-1)} className="component-back">
              <img src="../assets/icons/back-btn.svg" alt="" />
              Back
            </button>
          </div>
          <div className="filters">
            <button
              className="filterBtn"
              onClick={() => setisFilterMenuActive(true)}
            >
              {" "}
              <img src="../assets/icons/filter.svg" alt="" /> Filters
            </button>
          </div>
        </div>
        <div className="feedback-container">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Program Name</th>
                <th>Student Name</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Receipt Number</th>
                <th>Invoice Date</th>
                <th>Supply Place</th>
              </tr>
            </thead>
            <tbody>
              {getAllTransaction?.length > 0 &&
                getAllTransaction.map((res) => (
                  <tr>
                    <td>
                      {res.FeeDue?.Student?.ProgramDetail?.Program?.programName}
                    </td>
                    <td>
                      {res.FeeDue?.Student?.firstName}{" "}
                      {res.FeeDue?.Student?.lastName}
                    </td>
                    <td>{res.FeeDue?.Student?.officialEmail}</td>
                    <td>{res.amount}</td>
                    <td>
                      <input
                        type="text"
                        className="create-input"
                        placeholder="Enter Receipt Number"
                        value={res.receiptNumber}
                        id={res.id}
                        onBlur={(e) => updateReceiptNumber(e)}
                        onChange={(e) => handlereceiptNumber(e, res.id)}
                      />
                    </td>

                    <td>
                      <input
                        type="date"
                        className="create-input"
                        placeholder="Enter Receipt Number"
                        value={moment(res?.invoiceGeneratedOn).format(
                          "YYYY-MM-DD"
                        )}
                        id={res.id}
                        onBlur={(e) => updateInvoiceGeneratedOn(e)}
                        onChange={(e) => handleInvoiceGeneratedOn(e, res.id)}
                      />
                    </td>
                    {/* handleChangeSupplyPlace */}
                    <td>
                      {res.FeeDue?.Student?.supplyPlace}{" "}
                      <span
                        onClick={() => {
                          toggleStudentStatusPopup();
                          setStatusType("updateAddress");
                          setStudentId(res?.studentId || res?.applicantId);
                          setStudentDetails({
                            address: res.FeeDue?.Student?.address,
                            supplyPlace: res.FeeDue?.Student?.supplyPlace,
                            studentGSTNo: res.FeeDue?.Student?.studentGSTNo,
                          });
                        }}
                      >
                        Edit
                      </span>
                    </td>

                    {/* <td>{res.id}</td> */}
                    {/* <td>
                      {res.FeeDue?.isOneTimePayment && "OTP"}
                      {res.FeeDue?.isPartialLoan && "Partial Loan"}
                      {res.FeeDue?.isPartialOtp && "Partial OTP"}
                      {res.FeeDue?.isFullLoan && "Full Loan"}
                      {res.FeeDue?.isPartialLoan && "Partial Loan"}
                      {res.FeeDue?.isFlex && "Bank Loan"}
                      {res.FeeDue?.isAdmissionsFee && "Admissions Fee"}
                    </td> */}
                    {/* <td>{res.transactionNumber}</td> */}
                    {/* <td>{res.amount}</td> */}
                    {/* <td>
                                            <input
                                                type="number"
                                                name=""
                                                id=""
                                                className="create-input"
                                                placeholder="Enter Amount"
                                                value={res.amount}
                                                onBlur={onBlurFunction}
                                            />
                                        </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TransactionList;
