import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import PageLoader from "../../../components/UI/loader/PageLoader";
import FeeDuePopup from "../../../components/Popups/FeeDuePopup/FeeDuePopup";

// import moment from "moment";

import {
  getShortlistedApplicantList,
  applicantData,
  clearState,
  clearCsvState,
  // getApplicantFeedbackAns,
  getSendOrientationMailStudentList,
} from "../../../reducers/applicantSlice";
// import InterViewDeletePopup from "../../../components/Popups/InterViewDeletePopup/InterViewDeletePopup";
import ToastMessage from "../../../components/Toast/ToastComponent";
import "./ShortListStyle.css";
import { feeData } from "../../../reducers/feeSlice";
const OrientationMailLetter = () => {
  const navigate = useNavigate();
  const param = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const programBatchId = param.proId;
  const programMasterId = searchParams.get("programMasterId");
  const [isNewOffer, setIsNewOffer] = useState(true);
  const [showMoreLoader, setShowMoreLoader] = useState(false);

  const [pageNo, setPageNo] = useState("1");
  const [pageSize, setPageSize] = useState("10");

  //action Popup states
  const [searchTitle, setSearchTitle] = useState("");
  const [emailStatus, setEmailStatus] = useState("false");
  const [approvedStatus, setApprovedStatus] = useState("Anpproved");
  const [welcomeStatus, setWelcomeStatus] = useState("true");

  const [loaded, setLoaded] = useState(false);
  const [shortListDataArr, setShortListDataArr] = useState([]);

  const handleSearch = (event) => {
    var search = event.target.value;
    setSearchTitle(search);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchByTitle(e.target.value);
    }
  };

  const searchByTitle = (val) => {
    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: val,
      emailStatus: emailStatus,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    dispatch(getSendOrientationMailStudentList({ obj, params }));
    dispatch(clearState());
    setIsChecked([]);
    setAllChecked(false);
    setPageNo("1");
  };

  useEffect(() => {
    setPageNo("1");
    setIsChecked([]);
    setAllChecked(false);
  }, [pageSize, approvedStatus, emailStatus]);

  const [menuId, setMenuId] = useState(null);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isInterviewPopup, setIsInterviewPopup] = useState(false);
  const [applicantId, setApplicantId] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [interviewerId, setInterviewerId] = useState("");
  const [scholarshipAmount, setScholarshipAmount] = useState(0);
  const [feeDueId, setFeeDueId] = useState("");

  const closeInterviewPopup = () => {
    setIsInterviewPopup(false);
    setInterviewerId("");
    setApplicantId("");
    setPopupTitle("");
    setScholarshipAmount(0);
    // setInterviewData(null);
  };

  //check logic
  const [allChecked, setAllChecked] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isTotalSelected, setTotalSelected] = useState(false);
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [shortListId, setShortListId] = useState([]);

  const handleAllCheck = (e) => {
    let currStdIds = shortListDataArr.map((data) => data.id);
    const currSet = new Set(currStdIds);
    if (allChecked) {
      setTotalSelected(false);
      setAllChecked(false);
      let newArr = isChecked.filter((val) => !currSet.has(val));
      return setIsChecked(newArr);
    }
    setAllChecked(true);
    let newIsChecked = [...isChecked];
    currStdIds.map((val) => {
      if (!newIsChecked.includes(val)) {
        newIsChecked.push(val);
      }
    });
    return setIsChecked(newIsChecked);
  };

  const handleSingleCheck = (e) => {
    const id = e.target.value;
    if (isChecked.includes(id)) {
      setIsChecked(isChecked.filter((checked_id) => checked_id !== id));
      setTotalSelected(false);
      return setAllChecked(false);
    }
    isChecked.push(id);
    setIsChecked([...isChecked]);
    checkAllChecked();
  };
  const checkAllChecked = () => {
    let stdIds = shortListDataArr.map((item) => item.id);
    let isAll = stdIds.every((val) => isChecked.includes(val));
    if (!isAll) {
      setTotalSelected(false);
    }
    setAllChecked(isAll);
  };
  const clearSelected = () => {
    setTotalSelected((prev) => !prev);
    setIsChecked([]);
    setAllChecked(false);
  };

  const {
    shortListData,
    shortListCount,
    isFetching,
    feedbackAns,
    isSuccess,
    isError,
    deleteInterViewList,
    admissionAmount,
    offerLetterAmount,
    tutionFeeAmount,
  } = useSelector(applicantData);

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    if (welcomeStatus == "false") {
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        approvedStatus: approvedStatus,
        pageNo: pageNo,
        pageSize: pageSize,
      };

      dispatch(getShortlistedApplicantList({ obj, params }));
      dispatch(clearState());
      // setIsChecked([]);
      // setAllChecked(false);
    } else {
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        pageNo: pageNo,
        pageSize: pageSize,
      };

      dispatch(getSendOrientationMailStudentList({ obj, params }));
      dispatch(clearState());
      // setIsChecked([]);
      // setAllChecked(false);
    }
  }, [emailStatus, approvedStatus, pageNo, pageSize, welcomeStatus]);

  useEffect(() => {
    console.log("in shortList", isSuccess);
    if (isSuccess) {
      closeInterviewPopup();
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        pageNo: pageNo,
        pageSize: pageSize,
      };

      dispatch(getSendOrientationMailStudentList({ obj, params }));
      dispatch(clearState());
      setIsChecked([]);
      setAllChecked(false);
    }
  }, [isSuccess]);

  const sendOfferLetters = (obj) => {
    // console.log(isChecked);
  };

  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);
  const [isMailAccess, setMailAccess] = useState(false);
  const [isOfferAccess, setOfferAccess] = useState(false);
  const [studentFeedbackAccess, setFeedbackAccess] = useState(false);

  useEffect(() => {
    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    const isOfferAccess =
      localStorage.getItem("offerLetterApproval") === "true";
    const isMailAccess = localStorage.getItem("mailSending") === "true";
    const interviewFeedback =
      localStorage.getItem("interviewFeedback") === "true";
    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
    if (isOfferAccess) {
      setOfferAccess(isOfferAccess);
    }
    if (isMailAccess) {
      setMailAccess(isMailAccess);
    }
    if (interviewFeedback) {
      setFeedbackAccess(interviewFeedback);
    }

    setShortListDataArr([]);
  }, []);

  const handleLoadClick = () => {
    if (pageNo == Math.ceil(shortListCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
    setLoaded(true);
    setShowMoreLoader(true);
  };

  useEffect(() => {
    if (shortListData) {
      console.log("checking loaded status", loaded);
      if (loaded === true) {
        setShortListDataArr([...shortListDataArr, ...shortListData]);
        setLoaded("done");
      } else if (loaded === false) {
        setShortListDataArr(shortListData);
      } else {
        console.log(null);
      }
      setLoaded(false);
    }
  }, [shortListData]);

  console.log("checking is Fetching", isFetching);

  const fullName = (item) => {
    if (!item || (!item.firstName && item.firstName !== "")) {
      return "";
    }

    const formattedFirstName =
      item.firstName.charAt(0).toUpperCase() + item.firstName.substring(1);
    const formattedLastName = item.lastName
      ? item.lastName.charAt(0).toUpperCase() + item.lastName.substring(1)
      : "";

    return (
      formattedFirstName + (formattedLastName ? " " + formattedLastName : "")
    );
  };
  return (
    <>
      <SideMenu />
      {isFetching && pageNo === "1" ? <PageLoader /> : ""}
      <FeeDuePopup
        show={isPopupActive}
        closePopup={() => setIsPopupActive(false)}
        sendOfferLetters={sendOfferLetters}
        student={isChecked}
        programBatchId={programBatchId}
        programMasterId={programMasterId}
        searchTitle={searchTitle}
        emailStatus={emailStatus}
        approvedStatus={approvedStatus}
        pageNo={pageNo}
        pageSize={pageSize}
        isTotalSelected={isTotalSelected}
        shortListCount={shortListCount}
        dueType={"orientationMail"}
        isNewOffer={isNewOffer}
        feeDueId={feeDueId}
        clearSelected={clearSelected}
        applicantId={applicantId}
        setPageNo={setPageNo}
      />

      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Orientation letter sent successfully"}
      />

      <div className="main-body">
        <div className="page-header">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="component-back"
          >
            <img src="../assets/icons/back-btn.svg" />
            back to page
          </button>
          <div className="studentDetails">
            <div className="student-inner-left">
              <div className="student-details-outer">
                <div className="student-details">
                  <p className="student-name text-18">
                    {admissionAmount?.ProgramDetail?.Program?.programName}{" "}
                    <img src="../assets/icons/sectionSymbol.svg" />{" "}
                    <span className="text-danger">
                      Send Orientation Letters
                    </span>
                  </p>
                </div>
                <div className="student-details">
                  <p className="student-mail">
                    {admissionAmount?.ProgramDetail?.batch} &#xb7;{" "}
                    {admissionAmount?.ProgramDetail?.durationInMonths} months
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="student-inner-right bg-grey">
              <div className="fee-box total">
                <p>Total Dues</p>
                <p className="text-dark">
                  ₹ {offerLetterAmount?.totalAmount?.toFixed(2) ?? 0}
                </p>
              </div>
              <div className="fee-box">
                <p>Received</p>
                <p className="text-success">
                  ₹ {offerLetterAmount?.submittedAmount?.toFixed(2) ?? 0}
                </p>
              </div>
              <div className="fee-box">
                <p>Pending</p>
                <p className="text-danger">
                  ₹ {offerLetterAmount?.pendingAmount?.toFixed(2) ?? 0}
                </p>
              </div>
            </div> */}
          </div>
          {/* <p className="text-1">PGP in Technology & Business Management</p> */}
          <p className="text-1"></p>
        </div>
        <div className="filter-bar">
          <form action="" className="search-bar">
            <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              onChange={handleSearch}
              onKeyDown={onSearchKeyPress}
              placeholder="Search"
              className="clean-input"
            />
          </form>

          <div className="total-data-desc">
            Showing {shortListDataArr?.length} out of {shortListCount}
          </div>

          <div className="filters">
            {/* <button className={`dark-toggle-btn ${approvedStatus == 'false' ? 'active' : ''}`} onClick={() => setApprovedStatus('false')}>Pending</button>
            <button className={`success-toggle-btn ${approvedStatus == 'true' ? 'active' : ''}`} onClick={() => setApprovedStatus('true')}>Approved</button> */}
            {/* {approvedStatus == 'Approved' && (<select id="type" onChange={e => setEmailStatus(e.target.value)}>
              <option value="">Mail Status</option>
              <option value="true">Completed</option>
              <option value="false">Pending</option>
            </select>)} */}
            <select id="type" onChange={(e) => setPageSize(e.target.value)}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div className="feedback-container scrollbar shortlist-new">
          <table
            className="feedback-table table"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                {emailStatus === "false" && (
                  <th className="check-row">
                    {welcomeStatus === "false" ? (
                      <label className="checkbox-label headerCheckbox">
                        <input
                          type="checkbox"
                          name="allCheck"
                          id="allCheck"
                          disabled={viewOnlyAccess || isOfferAccess === false}
                          className="checkmark-btn"
                          checked={allChecked}
                          onChange={handleAllCheck}
                        />
                        <span className="checkmarkspan"></span>
                      </label>
                    ) : (
                      <label className="checkbox-label headerCheckbox">
                        <input
                          type="checkbox"
                          name="allCheck"
                          id="allCheck"
                          disabled={viewOnlyAccess || isMailAccess === false}
                          className="checkmark-btn"
                          checked={allChecked}
                          onChange={handleAllCheck}
                        />
                        <span className="checkmarkspan"></span>
                      </label>
                    )}
                    {/* <></> */}
                  </th>
                )}
                <th>candidate name</th>
                <th>Phone Number</th>
                <th>Email Id</th>
              </tr>
            </thead>
            <tbody>
              {isChecked?.length > 0 && emailStatus === "false" && (
                <tr className="selection">
                  <td className=" pl-0" colSpan="12">
                    <table
                      className="selection-table"
                      cellPadding={0}
                      cellSpacing={0}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {!isTotalSelected && (
                              <>
                                <p>
                                  {allChecked && "All"}{" "}
                                  <b>{isChecked?.length} Candidates</b> on this
                                  page are selected.
                                </p>
                                {allChecked && (
                                  <button
                                    className="clear-btn"
                                    onClick={() =>
                                      setTotalSelected((prev) => !prev)
                                    }
                                  >
                                    Select All{" "}
                                    <b>{shortListCount} Candidates</b>
                                  </button>
                                )}
                              </>
                            )}
                            {isTotalSelected && (
                              <>
                                <p>
                                  All <b>{shortListCount} Candidates</b> are
                                  selected.
                                </p>
                                <button
                                  className="clear-btn"
                                  onClick={clearSelected}
                                >
                                  Clear Selected Candidates
                                </button>
                              </>
                            )}

                            <button
                              className="primary-btn-a"
                              onClick={() => {
                                setIsPopupActive(true);
                                setIsNewOffer(true);
                              }}
                            >
                              Send Orientation Letters
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
              {shortListDataArr &&
                shortListDataArr.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkmark-btn"
                          checked={isChecked.includes(item.id)}
                          disabled={viewOnlyAccess || isMailAccess === false}
                          value={item.id}
                          onChange={handleSingleCheck}
                        />
                        <span className="checkmarkspan"></span>
                      </label>
                    </td>

                    <td>
                      {fullName(item)}
                      {/* {(item.firstName + " " + item.lastName)
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() + word.substring(1)
                        )
                        .join(" ")} */}
                    </td>
                    <td>{item.mobileNumber}</td>
                    <td>{item.officialEmail}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {shortListDataArr?.length !== shortListCount && (
            <div className="loadMoreRow">
              <button
                className={`${
                  isFetching && showMoreLoader
                    ? "primary-btn-a active"
                    : "primary-btn-a"
                }`}
                onClick={handleLoadClick}
              >
                <img src="../assets/icons/loader icon-01.png" /> Show More
              </button>
            </div>
          )}
        </div>

        {/* <div className=" pg-arrows-outer ">
          <p className="pg-arraows-p">
            {pageNo} of{" "}
            {shortListCount <= pageSize
              ? "1"
              : Math.ceil(shortListCount / pageSize)}
          </p>
          <div className="pg-arrows">
            <a onClick={setPreviousPage}>
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                className="pg-left"
              />
            </a>
            <a onClick={setNextPage}>
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                className="pg-right"
              />
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default OrientationMailLetter;
