import React, { useEffect, useState } from "react";
import "./FeeRecieptStyle.css";
import convertor from "number-to-words";
import PdfDownloader from "../PdfDownloader/PdfDownloader";
import moment from "moment";

const FeeReciept = ({ showReceipt, setShowReceipt, receiptData }) => {
  const [isDownload, setDownload] = useState(false);

  return (
    <>
      <PdfDownloader
        downloadFileName={receiptData.name + "_" + receiptData.feeName}
        rootElementId="receipt"
        isDownload={isDownload}
        setDownload={(val) => setDownload(val)}
      />
      <div className={`popup-outer ${showReceipt ? "show-popup" : ""}`}>
        <div className="popup-container receipt-popup-container">
          <div className="popup-header">
            <div className="popup-heading receipt-popup-header">
              <div className="popup-right">
                Download Receipt
                <button
                  className="primary-btn-a"
                  onClick={() => setDownload(true)}
                >
                  Download Now
                </button>
              </div>
              <img
                onClick={() => {
                  setShowReceipt(false);
                }}
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
              />
            </div>
          </div>
          <div className="popup-body  fee-recipt-body scrollbar">
            <div className={`receipt-outer`}>
              <div className="receipt-container-outer scrollbar">
                <div className="receipt-container" id="receipt">
                  <div className="receipt-header">
                    <img
                      src="/assets/logo/old-mu-on-white.svg"
                      alt=""
                      className="logo"
                    />
                    <p className="receipt-text">Office of Finance</p>
                  </div>
                  <div className="receipt-header">
                    <p className="receipt-text">
                      Receipt No.: <b>{receiptData.receiptNumber}</b>
                    </p>
                    <p className="receipt-text">
                      Receipt Issue Date:{" "}
                      <b>{moment(receiptData.paidOn).format("Do MMM YYYY")}</b>
                    </p>
                  </div>
                  <div className="receipt-header">
                    <div>
                      <p className="receipt-text initCap">
                        Dear {receiptData.name},
                      </p>
                      {/* <p className="receipt-text"><b>Address: </b> A-3/33, Pashim Vihar</p> */}
                      <p className="receipt-text">
                        <b>Place of Supply: </b> Gurugram
                      </p>
                      <p className="receipt-text">
                        Whether the TAX is payable on reverse charge basis -{" "}
                        <b>No</b>
                      </p>
                    </div>
                  </div>
                  <table
                    className="receipt-table"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th colSpan="4">Description</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td colSpan="4">
                          <b>{receiptData.feeName}</b>
                        </td>
                        <td>
                          ₹{" "}
                          {(parseFloat(receiptData.feeAmount) / 1.18).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                      {/* {receiptData.scholarshipAmount && (<tr>
                                                <td></td>
                                                <td colSpan="4">Scholarship</td>
                                                <td>{receiptData.scholarshipAmount}</td>
                                            </tr>)} */}
                      {receiptData.feeTaxes &&
                        receiptData.feeTaxes.length > 0 &&
                        receiptData.feeTaxes.map((tax) => (
                          <tr key={tax.id}>
                            <td></td>
                            <td colSpan="2"></td>
                            <td colSpan="2">
                              {tax.taxType} @ {tax.percent}%
                            </td>
                            <td>
                              ₹{" "}
                              {(
                                parseFloat(receiptData.feeAmount) -
                                parseFloat(receiptData.feeAmount) / 1.18
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td colSpan="5">
                          <b>Total Amount After Tax</b>
                        </td>
                        <td>
                          <b>₹ {receiptData?.feeAmount}</b>
                        </td>
                      </tr>
                      <tr>
                        {receiptData.feeAmount && (
                          <td colSpan="7">
                            Rupees in Words:
                            <b className="initCap">
                              {convertor.toWords(receiptData.feeAmount)} /-
                            </b>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  <div className="stamp">
                    <p className="receipt-text">For Masters’ Union</p>
                    <p className="receipt-text">Authorised Signatory</p>
                    {/* <img
                                            src=""
                                            alt="Mu stamp"
                                        /> */}
                  </div>
                  <hr />
                  <div className="receipt-footer">
                    <div className="location">
                      <img src="assets/icons/MapPin.svg" alt="map-icon" />
                      <div className="content">
                        <p>
                          <b>Masters' Union</b>
                        </p>
                        <p>
                          A unit of Shanti Informatics Ground Floor, Tower C,
                          DLF Cyber Park, Sector 20, Udhyog Vihar Phase III,
                          Gurgaon, Haryana, PIN-122008
                        </p>
                      </div>
                    </div>
                    <div className="location">
                      <img src="assets/icons/Phone.svg" alt="phone-icon" />
                      <div className="content">
                        <p>
                          <b>1800 120 5288</b>
                        </p>
                        <p>GSTIN:06ADSFS8704J1ZG</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeReciept;
