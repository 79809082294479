import React, { useEffect, useState } from "react";
import "./AcceptPaymentPopupStyle.css";
import {
  addUTRTransaction,
  feeData,
  clearTransaction,
  getSingleStudentFeeList,
} from "../../../reducers/feeSlice";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../Toast/ToastComponent";
const AcceptPaymentPopup = (props) => {
  const dispatch = useDispatch();
  const { isFetching, isComplete, isError, addUTRTransactionSuccess } =
    useSelector(feeData);
  const [paidAmount, setPaidAmount] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [showSuccessMessage1, setEnableSuccessMessage1] = useState(false);
  const handleSubmit = () => {
    let obj = {
      amount: paidAmount,
      transactionNumber: props.utrNumber,
      paidOn: paymentDate,
      studentId: props.studentId,
      feeInstallmentId: "",
      feeId: props.feeId,
      studentFeeDueId: props.feeDueId,
      status: "Approved",
      programId: props?.programId,
    };
    dispatch(addUTRTransaction(obj));
    console.log(obj);
    props.handleClose();
  };

  useEffect(() => {
    if (addUTRTransactionSuccess) {
      setTimeout(() => {
        setEnableSuccessMessage1(true);
      }, 1000);
      dispatch(clearTransaction());
    }
  }, [addUTRTransactionSuccess]);
  return (
    <>
      <ToastMessage
        show={showSuccessMessage1}
        setShow={() => setEnableSuccessMessage1(false)}
        message={"Success"}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container payment-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Pay details
              <img
                onClick={props.handleClose}
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
              />
            </div>
          </div>
          <div className="popup-body">
            <div className="form-grp mt-20">
              <label htmlFor="">Paid Amount</label>
              <input
                type="number"
                name=""
                id=""
                className="create-input"
                placeholder="Enter Amount"
                value={paidAmount}
                onChange={(e) => setPaidAmount(e.target.value)}
              />
            </div>
            <p className="text">
              Receipt Number will Autometically Genarated, if you want to add it
              Manually click on Manually Add Receipt no.
            </p>
            <div className="form-grp mt-20">
              <label htmlFor="">Payment Date</label>
              <input
                type="datetime-local"
                name=""
                id=""
                className="create-input"
                placeholder="Enter Amount"
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
              />
            </div>
          </div>
          <div className="popup-footer mt-20">
            <div className="btn-group ">
              <button className="primary-btn" onClick={handleSubmit}>
                Accept payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptPaymentPopup;
