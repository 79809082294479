import React, { useEffect, useState, createRef } from "react";
import "./GenericMailStyle.css";
import SideMenu from "../../components/Layout/menu/SideMenu";
import PageLoader from "../../components/UI/loader/PageLoader";
import SearchSelect from "react-select";
import {
  genericMailData,
  getAllTemplateData,
  createTemplate,
  clearState,
  emailSampleFileDownload,
  getTokenList,
  sendGenericMail,
} from "../../reducers/genericMailSendSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorToast from "../../components/Toast/ErrorToast";
import ToastMessage from "../../components/Toast/ToastComponent";
import AddStudentListPopup from "../../components/Popups/AddStudentListPopup/AddStudentListPopup";
import SunEditor from "suneditor-react";
// import mergeTag from "../../components/Editor/EditorPlugin";
import "suneditor/dist/css/suneditor.min.css";
import TemplateDataDropdown from "../../components/Dropdowns/TemplateDataDropdown/TemplateDataDropdown";
import ContentEditable from "react-contenteditable";
import insertTextAtCursor from "insert-text-at-cursor";
import CreateTemplatePopup from "../../components/Popups/CreateTemplatePopup/CreateTemplatePopup";
export default function GenericMailSend() {
  const {
    isFetching,
    templateDataList,
    isSuccess,
    sampleFileDownload,
    getAllTokenList,
    successMessgae,
    mailSendSuccess,
  } = useSelector(genericMailData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTemplateData());
  }, []);
  useEffect(() => {
    dispatch(emailSampleFileDownload());
  }, []);
  const ref = createRef();

  //toast states
  const [showAddCsvPopup, setAddCsvPopup] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [dropdownDataChange, setDropdownDataChange] = useState("");
  const [getAllTemplates, setGetAllTemplates] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailToken, setEmailToken] = useState(null);
  const [isCreateTemplateOpen, setIsCreateTemplateOpen] = useState(false);
  const [createTemplateList, setCreateTemplateList] = useState(false);
  const [getToken, setGetToken] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [fromEmail, setFromEmail] = useState("");

  //Create Template
  const [templateName, setTemplateName] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateBody, setTemplateBody] = useState("");
  const [addedOn, setAddedOn] = useState("");

  //
  console.log(getAllTokenList, "getAllTokenList");

  const onClickUpload = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    }
    console.log(event.target);
  };

  const onClickDeleteUpload = () => {
    setSelectedFile("");
    setIsFilePicked(false);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const toggleClosePopup = () => {
    setAddCsvPopup(false);
    // onClickDeleteUpload();
  };

  const onClickAddApplicant = () => {
    setAddCsvPopup(true);
  };

  const handleChangeSubject = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEmailToken = (event) => {
    setEmailToken(event.target.value);
    insertTextAtCursor(ref.current, event.target.value);
  };

  const toggleCreateTemplatePopup = () => {
    setIsCreateTemplateOpen(false);
    setAddedOn("");
    setTemplateBody("");
    setTemplateName("");
    setTemplateSubject("");
    setCreateTemplateList(false);
  };
  const openCreateTemplatePopup = () => {
    setIsCreateTemplateOpen(true);
    setCreateTemplateList(true);
  };

  const createTemplateSubmit = () => {
    let obj = {
      templateName: templateName,
      templateSubject: templateSubject,
      templateBody: templateBody,
      addedOn: addedOn,
    };
    dispatch(createTemplate(obj));
  };

  let templateEditorData =
    getAllTemplates?.length > 0 &&
    getAllTemplates?.filter((res) => res?.id === dropdownDataChange);
  //Set Data
  useEffect(() => {
    setGetAllTemplates(templateDataList);
  }, [templateDataList]);

  const onClickCsvSubmit = () => {
    if (selectedFile === "" || selectedFile.type !== "text/csv") {
      setIsShowError(true);
      setErrMessage("Choose a file in csv format!");
      return;
    }
    dispatch(getTokenList(selectedFile));
    setGetToken("");
  };

  console.log(fromEmail, "selectedFileselectedFile");

  useEffect(() => {
    setGetToken(getAllTokenList);
  }, [getAllTokenList]);
  let mergeTag = {
    name: "merge_tag",
    display: "submenu",
    add: function (core, targetElement) {
      let listDiv = this.setSubmenu.call(core);
      var self = this;
      listDiv.querySelectorAll(".se-btn-list").forEach(function (btn) {
        btn.addEventListener("click", self.onClick.bind(core));
      });
      core.initMenuTarget(this.name, targetElement, listDiv);
    },
    setSubmenu: function () {
      const listDiv = this.util.createElement("DIV");
      listDiv.className = "se-submenu se-list-layer";
      listDiv.innerHTML = `<div className="se-list-inner se-list-font-size">
         <ul className="se-list-basic">
           ${
             getToken?.length > 0
               ? getToken?.map(
                   (res, i) =>
                     `<li key=${i}>
                   <button type="button" className="se-btn-list" value={${res}}>
                     ${res}
                   </button>
                 </li>`
                 )
               : ""
           }
         </ul>
       </div>`;

      return listDiv;
    },

    onClick: function (e) {
      const value = e.target.value;
      const node = this.util.createElement("span");
      this.util.addClass(node, "se-custom-tag");
      node.textContent = value;

      this.insertNode(node);
      const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
      node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);

      this.submenuOff();
    },
  };

  const formValidation = () => {
    let status = true;
    if (selectedFile === "") {
      setIsShowError(true);
      setErrMessage("Add Csv");
      status = false;
    } else if (editorState === "") {
      setIsShowError(true);
      setErrMessage("Select Template");
      status = false;
    } else if (emailSubject === "") {
      setIsShowError(true);
      setErrMessage("Select Email Subject");
      status = false;
    } else if (fromEmail === "") {
      setIsShowError(true);
      setErrMessage("Select From Email");
      status = false;
    }
    return status;
  };

  const submitGenericMail = () => {
    if (!formValidation()) return;
    let obj = {
      file: selectedFile,
      specificTemplateBody: editorState,
      specificTemplateSubject: emailSubject,
      fromEmail: fromEmail,
    };
    dispatch(sendGenericMail(obj));
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsShowSuccess(true);
      }, 2000);
      dispatch(clearState());
      dispatch(getAllTemplateData());
      toggleCreateTemplatePopup();
      toggleClosePopup();
    }
  }, [isSuccess, dropdownDataChange]);

  useEffect(() => {
    if (mailSendSuccess) {
      setTimeout(() => {
        setIsShowSuccess(true);
      }, 2000);
      dispatch(clearState());
      dispatch(getAllTemplateData());
      onClickDeleteUpload();
      setEditorState("");
      setEmailSubject("");
      setFromEmail("");
      setGetToken([]);
      setDropdownDataChange("");
    }
  }, [mailSendSuccess]);

  const handleEditorChange = (content) => {
    setEditorState(content);
  };
  const dropDownOnChhange = (e) => {
    let data = e.id;
    setDropdownDataChange(data);

    let subData =
      getAllTemplates?.length > 0 &&
      getAllTemplates?.filter((res) => res?.id === data);
    setEmailSubject(subData?.length > 0 ? subData[0]?.templateSubject : "");
  };
  console.log(dropdownDataChange, "emailSubject");

  console.log(editorState, "editorState");

  return (
    <React.Fragment>
      {/* <SideMenu /> */}
      {isFetching && <PageLoader />}
      <ToastMessage
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessgae}
      />
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <CreateTemplatePopup
        handleClose={toggleCreateTemplatePopup}
        show={isCreateTemplateOpen}
        templateName={templateName}
        templateBody={templateBody}
        templateSubject={templateSubject}
        setTemplateName={setTemplateName}
        setTemplateBody={setTemplateBody}
        setTemplateSubject={setTemplateSubject}
        addedOn={addedOn}
        setAddedOn={setAddedOn}
        createTemplateSubmit={createTemplateSubmit}
      />

      <AddStudentListPopup
        handleClose={toggleClosePopup}
        show={showAddCsvPopup}
        onClickCsv={onClickCsvSubmit}
        csvFileDownload={sampleFileDownload}
        isFilePicked={isFilePicked}
        hiddenFileInput={hiddenFileInput}
        selectedFile={selectedFile}
        onClickUpload={onClickUpload}
        onClickDeleteUpload={onClickDeleteUpload}
        handleClick={handleClick}
        createTemplateList={createTemplateList}
        title={"Token"}
      />

      {/* <div className="main-body">
        <div className="page-header create-header">
          <div className="selected-fee">
            <TemplateDataDropdown
              options={getAllTemplates}
              dropDownOnChhange={dropDownOnChhange}
            />
          </div>
          <button
            className="primary-btn feed-btn"
            onClick={openCreateTemplatePopup}
          >
            <img src="../assets/icons/plus.svg" alt="" /> Create Template
          </button>
          <button
            className="primary-btn feed-btn"
            onClick={onClickAddApplicant}
          >
            <img src="../assets/icons/plus.svg" alt="" /> Add Csv
          </button>
        </div>

        <div
        //   className={`popup-container mail-popped withedit`}
        >
          <div className="popup-header">
            <div className="popup-header-body ">
              <div className="mail-head-shell">
                <div className="mail-head-shell-inside">
                  <label htmlFor="questions" className="text-5 mb-5 dis-block">
                    Email Subject
                  </label>

                  <ContentEditable
                    style={{
                      border: "1px solid #cccccc",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                    innerRef={ref}
                    html={
                      emailSubject
                    }
                    disabled={false}
                    onChange={handleChangeSubject}
                  />
                </div>

                <div className="mail-head-shell-inside">
                  <label htmlFor="questions" className="text-5 mb-5 dis-block">
                    Add Token To Subject
                  </label>
                  <select
                    id="type"
                    onChange={handleEmailToken}
                    className="popup-dropdown"
                  >
                    <option value="hide">Select token</option>
                    <option value="{candidate_name}">Candidate Name</option>
                    <option value="{feedback_name}">Feedback Name</option>
                    <option value="{feedback_type}">Feedback Type</option>
                    <option value="{feedback_expiryDate}">
                      Feedback Expiry Date
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-body scrollbar">
            <div className="mail-head-shell-inside width-100 mt-10">
              <span className="text-5 mb-5 dis-block">Email Body</span>
              {getToken?.length > 0 && (
                <SunEditor
                  setContents={
                    templateEditorData?.length > 0 &&
                    templateEditorData[0]?.templateBody
                  }
                  showToolbar={true}
                  onChange={handleEditorChange}
                  setDefaultStyle="height: auto"
                  setOptions={{
                    plugins: [mergeTag],
                    buttonList: [
                      [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "list",
                        "align",
                        "fontSize",
                        "formatBlock",
                        "table",
                        "fullScreen",
                        "preview",
                        "codeView",
                      ],
                      [
                        {
                          name: "merge_tag",
                          dataCommand: "merge_tag",
                          buttonClass: "",
                          title: "Add Token",
                          dataDisplay: "submenu",
                          innerHTML: "<span>Token</span>",
                        },
                      ],
                    ],
                  }}
                />
              )}
            </div>
            <div className="test-mail-shell">
              <span className="text-5 mt-30 dis-block">Send Test Email</span>
              <div className="test-mail mt-5">
                <input
                  //   onChange={handleChangeEmail}
                  type="email"
                  placeholder="Enter Test Email Id"
                />
                <button
                  //   onClick={onClickTestEmail}
                  className="secondary-btn width-auto"
                >
                  Test Mail
                </button>
              </div>
            </div>
          </div>

          <div className="popup-footer mail-popup-footer">
            <button
              className="danger-btn width-auto"
              //   onClick={onClickSendEmail}
            >
              Send Mail
            </button>
          </div>
        </div>
      </div> */}
      <div className="main-body full-container">
        <div className="page-header">
          <p className="component-heading">Mailer</p>
          <button
            className="primary-btn feed-btn"
            onClick={openCreateTemplatePopup}
          >
            <img src="../assets/icons/plus.svg" alt="" /> Crete Template
          </button>
        </div>
        <div className="mail-box-outer scrollbar">
          <div className="mail-details">
            <div className="form-2-grp">
              <div className="form-group mt-20">
                <label htmlFor="" className="create-label">
                  Select Template
                </label>
                <div className="selected-fee">
                  {/* <TemplateDataDropdown
                    options={getAllTemplates}
                    dropDownOnChhange={dropDownOnChhange}
                  /> */}
                  <SearchSelect
                    onChange={dropDownOnChhange}
                    options={getAllTemplates}
                    getOptionLabel={(option) => option.templateName}
                    getOptionValue={(option) => option.id}
                    styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                </div>
              </div>
              <div className="btn-grp">
                <label htmlFor="" className="create-label">
                  Upload CSV
                </label>
                <button
                  className="primary-btn-a feed-btn mt-5"
                  onClick={onClickAddApplicant}
                >
                  <img src="../assets/icons/plus.svg" alt="" /> Add Csv
                </button>
              </div>
            </div>
            <div className="form-2-grp">
              <div className="form-group mt-20">
                <label for="name" className="create-label">
                  Email Subject
                </label>
                <input
                  type="text"
                  className="create-input mt-5"
                  placeholder="Enter Email Subject"
                  value={emailSubject}
                  onChange={handleChangeSubject}
                />
              </div>
              <div className="form-group mt-20">
                <label for="name" className="create-label">
                  From
                </label>

                <select
                  id="type"
                  value={fromEmail}
                  onChange={(e) => setFromEmail(e.target.value)}
                  className="popup-dropdown"
                >
                  <option value="">Select From Email</option>
                  <option value="tech@mastersunion.org">
                    tech@mastersunion.org
                  </option>
                  <option value="mastercampadmissions@mastersunion.org">
                    mastercampadmissions@mastersunion.org
                  </option>
                  <option value="admissions@mastersunion.org">
                    admissions@mastersunion.org
                  </option>
                </select>
                {/* <input
                  type="text"
                  className="create-input mt-5"
                  placeholder="Enter Couse Name"
                /> */}
              </div>
            </div>
          </div>
          <div className="edit-mail-container scrollbar mt-20">
            {getToken?.length > 0 && (
              <SunEditor
                setContents={
                  templateEditorData?.length > 0 &&
                  templateEditorData[0]?.templateBody
                }
                showToolbar={true}
                onChange={handleEditorChange}
                setDefaultStyle="height: auto"
                setOptions={{
                  plugins: [mergeTag],
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "list",
                      "align",
                      "fontSize",
                      "formatBlock",
                      "table",
                      "fullScreen",
                      "preview",
                      "codeView",
                    ],
                    [
                      {
                        name: "merge_tag",
                        dataCommand: "merge_tag",
                        buttonClass: "",
                        title: "Add Token",
                        dataDisplay: "submenu",
                        innerHTML: "<span>Token</span>",
                      },
                    ],
                  ],
                }}
              />
            )}
          </div>
          <div className="edit-email-footer mt-20">
            <div className="test-mail">
              {/* <input type="email" name="" id="" className="create-input" />
              <button className="primary-btn-a">Send Test Mail</button> */}
            </div>
            <div className="btn-grp mt-10">
              <button className="success-btn" onClick={submitGenericMail}>
                Send Mail
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
