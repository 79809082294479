import React, { useState } from 'react';
import SideMenu from '../../../components/Layout/menu/SideMenu';
import ApprovePopup from '../../../components/Popups/ApprovePopup/ApprovePopup';
import RejectPopup from '../../../components/Popups/RejectPopup/RejectPopup';
import $ from "jquery";
import "./ViewClubStyle.css";

export default function ViewClub() {
    const [isRejectOpen, setIsRejectOpen] = useState(false);
    const toggleRejectPopup = () => {
        setIsRejectOpen(!isRejectOpen);
    };
    const [isApproveOpen, setIsApproveOpen] = useState(false);
    const toggleApprovePopup = () => {
        setIsApproveOpen(!isApproveOpen);
      };
      const changeContainer = (show, hide1, hide2) => {
        $(`#btn-${show}`).addClass("active");
        $(`#btn-${hide1}`).removeClass("active");
        $(`#btn-${hide2}`).removeClass("active");
    
        $(`#viewclubcontainer-${show}`).addClass("show");
        $(`#viewclubcontainer-${hide1}`).removeClass("show");
        $(`#viewclubcontainer-${hide2}`).removeClass("show");
      };
    return (
        <React.Fragment>
            <SideMenu />
            <RejectPopup handleClose={toggleRejectPopup} show={isRejectOpen} />
            <ApprovePopup handleClose={toggleApprovePopup} show={isApproveOpen}/>
            <div className='main-body'>
                <div className="page-header ">
                    <button className="back-btn">
                        <img src="../assets/icons/back-btn.svg" />Back to page
                    </button>
                    <div className='center-links'>
                        <button className="primary-clean active" id="btn-1"onClick={() => changeContainer(1, 2, 3)}>
                            Club details
                        </button>
                        <button className="primary-clean" id="btn-2" onClick={() => changeContainer(2, 1, 3)}>
                            Club memeber
                        </button>
                        <button className="primary-clean" id="btn-3" onClick={() => changeContainer(3, 2, 1)}>
                            Comments
                        </button>
                    </div>
                    <div className='side-links'>
                        <button className="danger-btn-outline icon-btn" onClick={toggleRejectPopup}>
                            <img src="../assets/icons/PlusCircle.svg" />Reject
                        </button>
                        <button className="green-btn-outline icon-btn"onClick={toggleApprovePopup}>
                            <img src="../assets/icons/CheckCircle.svg" />Approve
                        </button>

                    </div>
                </div>
                {/* View Club Details */}
                <div className="scrollbar show" id="viewclubcontainer-1" style={{ display: "none" }}>

                    <div className="viewclub-container view-club-detail scrollbar ">
                        <div className="common-container">

                            <div className="viewclub-wrapper">
                                <form>
                                    <div className="form-group mt-30">
                                        <label htmlFor="title" className="create-label">Club Name</label>
                                        <input type="text" name="" id="" className="create-input mt-5" placeholder="Create a Club" value="" />
                                    </div>

                                    <div className="form-group mt-30">
                                        <label htmlFor="title" className="create-label">Mission of your Club</label>
                                        <input type="text" name="" id="" className="create-input mt-5" placeholder="Description of Club" value="" />
                                    </div>

                                    <div className="form-group mt-30">
                                        <label htmlFor="title" className="create-label">Vision of your Club</label>
                                        <input type="text" name="" id="" className="create-input mt-5" placeholder="Vision of your Club" value="" />
                                    </div>

                                    <div className="form-group mt-30">
                                        <label htmlFor="title" className="create-label">Why do you want to form the club?</label>
                                        <textarea type="textarea" name="" id="" className="create-input mt-5 srollbar" placeholder="Want to form the club" rows="4" cols="5" ></textarea>
                                    </div>

                                    {/* <div className="form-group mt-30">
                                    <label htmlFor="title" className="create-label">Club Type</label>
                                    <select id="type" className="popup-dropdown mt-5">
                                        <option value="hide">Select Program</option>
                                        <option value="1">Music Club</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                    </select>
                                </div> */}

                                    <div className="form-footer mt-30">
                                        <button className="primary-btn">Next</button>
                                    </div>

                                </form>

                            </div>

                        </div>
                    </div>

                </div>
                {/* View Club Members */}
                <div className=" scrollbar" id="viewclubcontainer-2" style={{ display: "none" }}>

                    <div className="viewclub-container view-club-member">
                        <div className="common-container">
                            <div className="viewclub-wrapper">
                                <div className="filter-bar">
                                    <form action="" className="search-bar">
                                        <img src="../assets/icons/search.svg" alt="" />
                                        <input type="text" name="" id="" placeholder="Search" className="clean-input" />
                                    </form>
                                    <div className="filters">
                                        <select id="type">
                                            <option value="hide">Feedback Type</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                        <select id="type">
                                            <option value="hide">Status</option>
                                            <option value="1">Expired</option>
                                            <option value="2">Draft</option>
                                            <option value="3">Active</option>
                                        </select>
                                        <select id="type">
                                            <option value="hide">1-50</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="table-container scrollbar border">
                                    <table className="feedback-table" cellPadding={0} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th className="check-row">
                                                    <label className="checkbox-label">
                                                        <input
                                                            type="checkbox"
                                                            name=""
                                                            id=""
                                                            className="checkbox-btn"
                                                        />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <img
                                                        src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                                                        className="ml-5"
                                                    />
                                                </th>
                                                <th style={{ paddingLeft: "0 !important" }}>Student</th>
                                                <th>Programme</th>
                                                <th>Batch</th>
                                                {/* <th>President</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="p-20">
                                                <td>
                                                    <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn" />
                                                        <span className="checkmarkspan" />
                                                    </label>
                                                </td>
                                                <td>Anand Krishnan</td>
                                                <td>Programming in Finance and Accounting</td>
                                                <td>2018-2022</td>
                                                {/* <td><button className="green-btn-outline">President</button></td> */}
                                            </tr>

                                            <tr className="p-20">
                                                <td>
                                                    <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn" />
                                                        <span className="checkmarkspan" />
                                                    </label>
                                                </td>
                                                <td>Anand Krishnan</td>
                                                <td>Programming in Finance and Accounting</td>
                                                <td>2018-2022</td>
                                                {/* <td><button className="green-btn-outline">President</button></td> */}
                                            </tr>

                                            <tr className="p-20">
                                                <td>
                                                    <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn" />
                                                        <span className="checkmarkspan" />
                                                    </label>
                                                </td>
                                                <td>Anand Krishnan</td>
                                                <td>Programming in Finance and Accounting</td>
                                                <td>2018-2022</td>
                                                {/* <td><button className="green-btn-outline">President</button></td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form-footer multibuttons">
                                    <button className="secondary-btn">Back</button>
                                    <button className="primary-btn">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* View Comments */}
                <div className="scrollbar" id="viewclubcontainer-3"style={{ display: "none"}} >
                    <div className="viewclub-container view-club-comments">
                        <div className="common-container">

                            <div className="viewclub-wrapper">

                                <div className='chat-container-outer'>
                                    <div className='chat-comment-container scrollbar'>
                                        <div className='chat-comment-left'>
                                            <div className='chat-comment-left-top'>
                                                <img src="../assets/img/admin.jpg" />
                                                <div className='chat-comment-left-right'>
                                                    <p>The Development Monitoring and Evaluation Office (DMEO) was constituted in September 2015 by merging the erstwhile Program Evaluation Office (PEO) and the was constituted in September 2015 (DMEO) was constituted in September 2015 by </p>
                                                    <span>10:26 am</span>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='chat-comment-right'>
                                            <div className='chat-comment-right-top'>
                                                <img src="../assets/img/user-side.jpg" />
                                                <div className='chat-comment-right-left'>
                                                    <p>The Development Monitoring and Evaluation Office (DMEO) was constituted in September 2015 by merging the erstwhile Program Evaluation Office (PEO) and the was constituted in September 2015 (DMEO) was constituted in September 2015 by </p>
                                                    <span>10:26 am</span>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className='chat-msgsend-container'>
                                        <input className='chat-input text-7r' placeholder='Send Message'></input>
                                        <button className='send-outer'> <img src="../assets/icons/PaperPlaneTilt.svg" /></button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
