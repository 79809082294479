import React, { useEffect, useState } from "react";
import "./feeListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import DeletePopup from "../../../components/Popups/DeletePopup/DeletePopup";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  feeData,
  getOrganizationProgramsWithBatch,
} from "../../../reducers/feeSlice";
import PageLoader from "../../../components/UI/loader/PageLoader";

export default function FeeList() {
  const { isFetching, isSuccess, isError, programNameList } =
    useSelector(feeData);
  const [menuId, setMenuId] = useState(null);
  const [programList, setProgramList] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };
  const onAddFeeClick = () => {
    navigate(`/createFee`);
  };

  const onClickFeeDetail = (programId, batchId) => {
    navigate(
      `/feePreview?programMasterId=${programId}&programBatchId=${batchId}`
    );
  };

  const onEditProgram = (programId, batchId, feeId) => {
    navigate(`/createFee?edit=true&programMasterId=${programId}&programBatchId=${batchId}&feeId=${feeId}`);
  }

  /**Getting all programs list */
  useEffect(() => {
    dispatch(getOrganizationProgramsWithBatch());
  }, []);

  /**Setting all programs list data */
  useEffect(() => {
    setProgramList(programNameList);
  }, [programNameList]);

  console.log("dfmfdfdfdf", programList);

  /**onClikc */
  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
      console.log("inside equal condition");
    } else {
      setMenuId(id);
      console.log("inside other condition");
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  }

  return (
    <React.Fragment>
      <SideMenu />
      {isFetching && <PageLoader />}

      <DeletePopup handleClose={toggleDeletePopup} show={isDeleteOpen} />
      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">Organization Programs Details</p>
          <button className="primary-btn feed-btn" onClick={onAddFeeClick}>
            <img src="../assets/icons/plus.svg" alt="" /> Add program
          </button>
        </div>

        <div className="feedback-container scrollbar feelist-container">
          <table
            className="feedback-table table"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr className="p-20">
                <th className="text-left">Program name</th>
                <th className="text-left">Batch</th>
                <th className="text-center">Duration In Months</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {programList.map((item) => {
                const { id, programName } = item;

                return item.ProgramDetails.map((element, index) => (
                  <tr key={index} className="p-20">
                    <td className="text-left">{programName}</td>
                    <td className="text-left">{element.name}</td>

                    <td className="text-center">
                      {element.durationInMonths} Months
                    </td>
                    <td
                      onClick={() => openActionMenu(index)}
                      onBlur={closeActionMenu}
                      className="text-center"
                    >
                      <button className="action-btn">
                        <img src="../assets/icons/options.svg" />
                      </button>
                      <ul
                        className={
                          menuId === index
                            ? "action-dropdown active"
                            : "action-dropdown"
                        }
                      >
                        <li
                          onClick={() =>
                            onClickFeeDetail(
                              element.programMasterId,
                              element.id
                            )
                          }
                          className="dropdown-item"
                        >
                          <img src="../assets/icons/preview.svg" alt="" />
                          <p className="dropdown-text">Fee Details</p>
                        </li>
                        <li className="dropdown-item">
                          <img src="../assets/icons/list.svg" alt="" />
                          <p className="dropdown-text">Student List</p>
                        </li>
                        <li
                          className="dropdown-item"
                        // onClick={toggleDeletePopup}
                        >
                          <img src="../assets/icons/delete.svg" alt="" />
                          <p className="dropdown-text text-danger">Delete</p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ));
              })}

              {/* <tr className="p-20">
                <td className="text-left">Art of communication</td>
                <td className="text-left">2021</td>

                <td className="text-center">2 Months</td>
                <td className="text-center">
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                  </button>
                </td>
              </tr>

              <tr className="p-20">
                <td className="text-left">Program name</td>

                <td className="text-left">2022</td>

                <td className="text-center">12 Months</td>

                <td className="text-center">
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                  </button>

                  <ul
                    className={
                      menuId === index
                        ? "action-dropdown active"
                        : "action-dropdown"
                    }
                  >
                    <li className="dropdown-item">
                      <img src="../assets/icons/edit.svg" alt="" />
                      <p className="dropdown-text">Edit</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/preview.svg" alt="" />
                      <p className="dropdown-text">Fee Details</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/list.svg" alt="" />
                      <p className="dropdown-text">Student List</p>
                    </li>
                    <li className="dropdown-item" onClick={toggleDeletePopup}>
                      <img src="../assets/icons/delete.svg" alt="" />
                      <p className="dropdown-text text-danger">Delete</p>
                    </li>
                  </ul>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}
