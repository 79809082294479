import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SideMenu from "../../components/Layout/menu/SideMenu";
import CreateInterviewSlots from "./interviewScheduler/CreateInterviewSlots";
import InterviewScheduler from "./interviewScheduler/InterviewScheduler";
import AvailableSlotsDetails from "./interviewSettings/AvailableSlotsDetails";
import InterviewSettingPage from "./interviewSettings/InterviewSettingPage";
import MentorCallSettings from "./mentorCallSettings/MentorCallSettings";
import CreateMentorCallSlots from "./mentorScheduler/CreateMentorCallSlots";
import ViewMentorAvailableSlots from "./mentorScheduler/ViewMentorAvailableSlots";
import SettingsSideMenu from "./SettingsSideMenu";
import "./SettingsStyle.css";

const Settings = () => {
  const [renderComponent, setRenderComponent] = useState(2);

  const onClickComponent = (id) => {
    setRenderComponent(id);
  };

  return (
    <>
      <SideMenu />

      <div className="interviewSettingsOuter">
        <SettingsSideMenu />

        <div className="interviewSettingsContainer">
          <Routes>
            <Route
              index
              path="interviewSetting"
              element={<InterviewSettingPage />}
            />
            <Route
              element={<AvailableSlotsDetails />}
              path="interviewSetting/availableSlots"
            />
            <Route path="interviewSlots" element={<InterviewScheduler />} />
            <Route
              path="interviewSlots/createInterviewSlot"
              element={<CreateInterviewSlots />}
            />

            <Route path="mentorCallSettings" element={<MentorCallSettings />} />
            <Route
              path="mentorCallSlots/createMentorCallSlot"
              exact
              element={<CreateMentorCallSlots />}
            />
            <Route
              path="mentorCallSlots"
              exact
              element={<ViewMentorAvailableSlots />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Settings;
