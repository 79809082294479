import React, { useState } from "react";
import "./approvalListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getApplicantDetailsForLogisticsPartner,
  putSaveLogisticsPartnerResponse,
  shippingData,
} from "../../../reducers/shippingSlice";
import { useEffect } from "react";
import { environment } from "../../../environments/environment";
import PageLoader from "../../../components/UI/loader/PageLoader";
import SaveLogisticDataPopup from "../../../components/Popups/StudentAvailibilityPopup/SaveLogisticDataPopup";
import moment from "moment";

const LogisticList = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const [show, setShow] = useState(false);
  const [partnerName, setPartnerName] = useState("");

  const {
    isFetching,
    vendorOrderDetails,
    applicantDetailsLogistics,
    offerBoxStatus,
    isSuccess,
    logisticResponse,
  } = useSelector(shippingData);
  const [applicantDetails, setApplicantDetails] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  useEffect(() => {
    dispatch(getApplicantDetailsForLogisticsPartner(orderId));
  }, [orderId]);

  // const handleOnBlur = (e, id) => {
  //   const { value } = e.target;
  //   if (value) console.log("dispatch", value, "id", id);
  // };

  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     e.target.blur();
  //   }
  // };

  useEffect(() => {
    if (applicantDetailsLogistics) {
      setApplicantDetails(applicantDetailsLogistics);
    }
  }, [applicantDetailsLogistics]);

  const handleTrackingIdChange = (e, id) => {
    const { value } = e.target;

    // console.log("fjdhufhudhfhdf", value);
    setApplicantDetails((room) =>
      room?.map((list, index) =>
        list.id === id ? { ...list, trackingId: value } : list
      )
    );
  };

  const handleSaveClick = () => {
    console.log("dispatch");

    let obj = {
      orderId: orderId,
      applicants: selectedCandidates,
      deliveryPartner: partnerName,
    };

    console.log("dispatch", obj);

    dispatch(putSaveLogisticsPartnerResponse(obj));
  };

  const handleCandidateChange = (id, obj) => {
    if (selectedCandidates.some((ind) => ind.id === obj.id)) {
      console.log("inside if condition");
      setSelectedCandidates((prev) => prev.filter((item) => item.id !== id));
    } else {
      setSelectedCandidates((prev) => [...prev, obj]);
      console.log("inside else condition");
    }
    // if (selectedCandidates.includes(obj.id))
    //   setSelectedCandidates((prev) => prev.filter((item) => item !== id));
    // else setSelectedCandidates((prev) => [...prev, obj]);
  };

  // const handleAllChecked = () => {
  //   if (selectedCandidates.length === data?.length) setSelectedCandidates([]);
  //   else setSelectedCandidates(data?.map((item) => item.id));
  // };

  const toggleLogisticDataPopup = () => {
    setShow(!show);
    // setOfferViewType("");
    // setDLResReason("");
    // setDLResponse("");
  };

  useEffect(() => {
    if (isSuccess) {
      setShow(false);
    }
  }, [isSuccess]);

  console.log("printing isSuccess", isSuccess);

  // console.log("nubububububuu", selectedCandidates);

  console.log("fihreihfiehfihef", logisticResponse);

  return (
    <>
      <SideMenu />
      {isFetching && <PageLoader />}

      <SaveLogisticDataPopup
        show={show}
        closePopup={toggleLogisticDataPopup}
        setPartnerName={setPartnerName}
        handleSave={handleSaveClick}
        partnerName={partnerName}
      />

      <div className="main-body">
        <div className="page-header">
          <div className="studentDetails">
            <div className="student-inner-left button-set-student">
              <div className="student-details-outer">
                <div className="student-details">
                  <p className="student-name text-18">
                    {logisticResponse?.program?.Program?.programName}
                    {/* {programDetails?.Program?.programName}{" "} */}
                    {/* {logisticResponse?.} */}
                    {" / "}
                    <span className="text-danger">
                      {moment(logisticResponse?.createdAt).format("LL")}
                    </span>
                  </p>
                </div>
                <div className="student-details">
                  <p className="student-mail">
                    {logisticResponse?.program?.batch}
                    {/* {programDetails?.batch} &#xb7;{" "}
                    {programDetails?.durationInMonths} months */}
                  </p>
                </div>
              </div>

              <div>
                <button
                  className="primary-btn"
                  style={{ "margin-right": "10px" }}
                  onClick={() => {
                    var url =
                      environment.baseURL +
                      `/api/org/orderId/${orderId}/downloadOfferLetterBoxLogisticsApplicantCSV`;

                    window.open(url, "_blank", "noopener,noreferrer");
                  }}
                >
                  Download Csv
                </button>
                {/* onClick={handleSaveClick} */}
                <button
                  onClick={() => {
                    toggleLogisticDataPopup();
                  }}
                  className="primary-btn"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
        </div>

        <div className="feedback-container scrollbar">
          <table
            className="feedback-table table"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th></th>
                <th>S.No.</th>
                <th>Candidate name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>House No./Flat No.</th>
                <th>Apartment/Road/Area</th>
                <th>Landmark</th>
                <th>City</th>
                <th>PinCode</th>
                <th>State</th>
                <th>Offer Box Status</th>
                <th>Tracking Id</th>
              </tr>
            </thead>
            <tbody>
              {applicantDetails?.length > 0 &&
                applicantDetails?.map(
                  (item, index) => {
                    const { Applicant, id, trackingId } = item;
                    // const { applicantdetail } = Applicant;
                    return (
                      <tr key={id}>
                        <td>
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              className="checkmark-btn"
                              checked={selectedCandidates.some(
                                (ind) => ind.id === item.id
                              )}
                              onChange={() =>
                                handleCandidateChange(item.id, item)
                              }
                            />
                            <span className="checkmarkspan"></span>
                          </label>
                        </td>
                        <td>{index + 1}.</td>
                        <td>
                          {Applicant?.firstName + " " + Applicant?.lastName}
                        </td>
                        <td>{Applicant?.mobileNumber ?? "--"}</td>
                        <td>{Applicant?.officialEmail ?? "--"}</td>
                        <td>{Applicant?.applicantdetail?.HouseNo ?? "--"}</td>
                        <td>
                          {Applicant?.applicantdetail?.ApartmentRoadArea ??
                            "--"}
                        </td>
                        <td>{Applicant?.applicantdetail?.Landmark ?? "--"}</td>
                        <td>
                          {Applicant?.applicantdetail?.City_Current ?? "--"}
                        </td>
                        <td>{Applicant?.applicantdetail?.PinCode ?? "--"}</td>
                        <td>
                          {Applicant?.applicantdetail?.State_Current ?? "--"}
                        </td>
                        <td>{offerBoxStatus}</td>
                        <td>
                          <input
                            type="text"
                            className="create-input"
                            placeholder="Enter tracking id"
                            value={trackingId}
                            id={id}
                            onChange={(e) => handleTrackingIdChange(e, id)}
                          />
                        </td>
                      </tr>
                    );
                  }

                  // (
                  //   <tr key={index}>
                  //     <td>{item.name ? item.name : "---"}</td>
                  //     <td>{item.number ? item.number : "---"}</td>
                  //     <td>{item.email ? item.email : "---"}</td>
                  //     <td>
                  //       <input
                  //         className="create-input"
                  //         onBlur={(e) => handleOnBlur(e, item.id)}
                  //         onKeyPress={handleKeyPress}
                  //       />
                  //     </td>
                  //   </tr>
                  // )
                )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default LogisticList;
