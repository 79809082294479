import React from 'react';
import "./ApprovePopup.css"

const ApprovePopup = (props) => {
    return (
        <React.Fragment>
            <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
                <div className="popup-container approve-popup">
                    <div className="popup-header">
                        <div className="popup-heading">
                            Approved Club Request
                            <img
                                src="../assets/icons/close.svg"
                                alt=""
                                className="close-btn"
                                onClick={props.handleClose}
                            />
                        </div>
                        <div className="popup-header-body">
                            <div className="">
                                <p className="text-7">
                                You have created a new club.{" "}
                                </p>
                                <p className="text-7r"> To publish this club, please fill the details.</p>
                            </div>

                        </div>
                        {/* <div className='leave-comment'>
                            <p className='text-7r '>Leave your comment*</p>
                            <div className="form-group-comment">
                                <label htmlFor="comment" className="create-label"></label>
                                <textarea type="textarea" name="comment" id="" className="text-7r" placeholder="Write a Comment" rows="5" cols="5" spellcheck="false"></textarea></div>
                        </div> */}
                        <div className="popup-footer pt-10">
                            <div className="btn-group">
                                <button className="dark-btn-outline">cancel</button>
                                <button className="popup-primary-btn ">continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ApprovePopup