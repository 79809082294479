import React from "react";
import { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { searchApplicant, feeData } from "../../reducers/feeSlice";
// import moment from "moment/moment";
// import { useSearchParams } from "react-router-dom";
import PageLoader from "../../components/UI/loader/PageLoader";

const FindApplicant = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, ApplicantList } = useSelector(feeData);
  const [search, setSearch] = useState("");

  useEffect(() => {
    let objs = {
      name: search,
    };
    dispatch(searchApplicant(objs));
    console.log(ApplicantList.Data, "data");
    // eslint-disable-next-line
  }, []);

  //SearchBar
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (search !== "") {
        let objs = {
          name: search,
        };
        dispatch(searchApplicant(objs));
      }
    }
  };

  const ClearInput = () => {
    setSearch("");
    // setClearSearch(!clearSearch)
    let objs = {
      name: search,
    };
    dispatch(searchApplicant(objs));
  };

  return (
    <>
      {isFetching && <PageLoader />}
      <div className="dueFeeListOuter">
        <div className="filter-bar">
          <div className="searchBox">
            {/* <img src="../assets/icons/search-sm.svg" alt="" /> */}
            <input
              type="text"
              className="searchInput"
              placeholder="Search ..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={handleKeyPress}
            />

            {search.length === 0 ? (
              <img src="../assets/icons/search-sm.svg" alt="" />
            ) : (
              <img
                src="../assets/icons/close.svg"
                onClick={ClearInput}
                alt=""
                className="pointer"
              />
            )}
          </div>
        </div>

        <div className="feeDueTableContainer">
          <div className="feeTableOuter">
            <table
              className="feedback-table  feeTable"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Program </th>
                  <th>Batch </th>
                </tr>
              </thead>

              <tbody>
                {ApplicantList.Data &&ApplicantList.Data?.map((item) => (
                <tr key={item.id}>
                  <td>
                    {item.firstName}{" "}
                    {item.lastName}
                  </td>
                  <td>{item.officialEmail}</td>
                  <td> {item.mobileNumber}</td>
                  <td>
                    {item.ProgramDetail?.Program?.programName}
                  </td>
                  <td>{item.ProgramDetail?.batch}</td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindApplicant;
