import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let adminId;
let userId

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};
export const getAllTemplateData = createAsyncThunk(
  "mailSend/getAllTemplates",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org" +
          // orgId +
          // "/admin/" +
          // adminId +
          "/getAllTemplates",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// /:organizationId/admin/:adminId/createEmailTemplate
export const createTemplate = createAsyncThunk(
  "mailSend/createTemplate",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const payload = {
        templateName:obj.templateName,
        templateSubject:obj.templateSubject,
        templateBody:obj.templateBody,
        // addedOn:obj.addedOn,
        // organizationId:orgId,
        // adminId:adminId
      }

      const response = await fetch(
        environment.baseURL +
        "/api/org" +
        // orgId +
        // "/admin/" +
        // adminId +
        "/createEmailTemplate",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /:organizationId/admin/:adminId/template/:templateId/updateEmailTemplate
export const updateTemplate = createAsyncThunk(
  "mailSend/updateTemplate",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const payload = {
        templateName:obj.templateName,
        templateSubject:obj.templateSubject,
        templateBody:obj.templateBody,
        // organizationId:orgId,
        // adminId:adminId
      }

      const response = await fetch(
        environment.baseURL +
        "/api/org" +
        // orgId +
        // "/admin/" +
        // adminId +
        "/template/" +
        obj.templateId +
        "/updateEmailTemplate",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const emailSampleFileDownload = createAsyncThunk(
  "mailSend/emailSampleFileDownload",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/org" +
        // orgId +
        // "/admin/" +
        // adminId +
        `/downloadSampleEmailCSV`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.text();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getTokenList = createAsyncThunk(
  "mailSend/getTokenList",
  async (obj,thunkAPI) => {
    try {
      setTokenValues();
      const formData = new FormData();
      formData.append("file", obj);
      const response = await fetch(
        environment.baseURL +
        "/api/org" +
        // orgId +
        // "/admin/" +
        // adminId +
        `/returnTokenNames`,

        {
          method: "POST",
          headers: {
            Authorization: token,
          },
          body: formData,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sendGenericMail = createAsyncThunk(
  "mailSend/sendGenericMail",
  async (obj,thunkAPI) => {
    try {
      setTokenValues();
      const formData = new FormData();
      formData.append("file", obj.file);
      formData.append("specificTemplateBody", obj.specificTemplateBody);
      formData.append("specificTemplateSubject", obj.specificTemplateSubject);
      formData.append("fromEmail", obj.fromEmail);
      const response = await fetch(
        environment.baseURL +
        "/api/org" +
        // orgId +
        // "/admin/" +
        // adminId +
        `/sendGenricTemplate`,

        {
          method: "POST",
          headers: {
            Authorization: token,
          },
          body: formData,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const genericMailSendSlice = createSlice({
  name: "genericMail",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    templateDataList:[],
    successMessgae:"",
    sampleFileDownload: null,
    getAllTokenList:[],
    mailSendSuccess:false
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.mailSendSuccess=false
      return state;
    },
  },
  extraReducers: {
    [getAllTemplateData.fulfilled]: (state, { payload }) => {
      state.getMentorStudentsList = payload;
      state.isFetching = false;
      state.templateDataList=payload
      return state;
    },
    [getAllTemplateData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllTemplateData.pending]: (state) => {
      state.isFetching = true;
    },
    [createTemplate.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess=true
      state.successMessgae=payload?.Message
      return state;
    },
    [createTemplate.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [createTemplate.pending]: (state) => {
      state.isFetching = true;
    },
    [updateTemplate.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess=true
      state.successMessgae=payload?.Message
      return state;
    },
    [updateTemplate.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateTemplate.pending]: (state) => {
      state.isFetching = true;
    },
    [emailSampleFileDownload.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.sampleFileDownload = payload;
      state.isFetching = false;
      return state;
    },
    [emailSampleFileDownload.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [emailSampleFileDownload.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getTokenList.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.getAllTokenList = payload.Data;
      state.isSuccess=true
      state.isFetching = false;
      state.successMessgae=payload?.Message
      return state;
    },
    [getTokenList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getTokenList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [sendGenericMail.fulfilled]: (state, { payload }) => {
      state.isSuccess=true
      state.mailSendSuccess=true
      state.isFetching = false;
      state.successMessgae=payload?.Message
      return state;
    },
    [sendGenericMail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [sendGenericMail.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
  },
});

export const { clearState} = genericMailSendSlice.actions;

export const genericMailData = (state) => state.genericMail;
