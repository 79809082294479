import React from "react";
import "./ButtonStyle.css";

const Button = ({ className, name, onClick, isDisable, src }) => {
  return (
    <button
      onClick={onClick}
      className={`button ${className}`}
      disabled={isDisable}
    >
      <img src={src} />
      {name}
    </button>
  );
};

export default Button;
