import React from "react";
import "./AddStudentPopupStyle.css";

const StudentRevenuePopup = ({ onClose, show, data, counsellorName }) => {
  const formatAmount = (amount) => {
    var number = amount == null ? 0 : parseInt(amount);

    var formattedAmount = new Intl.NumberFormat("en-IN", {
      currency: "INR",
    }).format(number);

    return formattedAmount;
  };
  return (
    <>
      <div className={`popup-outer ${show ? "show-popup" : ""}`}>
        <div className="popup-container addStudentPopup">
          <div className="popup-header">
            <div className="popup-heading">
              {counsellorName} Revenue
              <img
                onClick={onClose}
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
              />
            </div>
          </div>
          <div className="popup-body">
            <div className="feedback-container scrollbar border">
              <table className="feedback-table" cellPadding={0} cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Student</th>
                    <th>Email Id</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => {
                      const { submittedAmount, id, name, officialEmail } = item;
                      return (
                        <tr key={id} className="p-20">
                          <td>{index + 1}</td>
                          <td>{name}</td>
                          <td>{officialEmail}</td>
                          <td>₹ {formatAmount(submittedAmount)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="form-footer-new">
            <button className="component-back">
              <img src="../assets/icons/back-btn.svg" />
              Back to page
            </button>
            <button className="primary-btn">Submit</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default StudentRevenuePopup;
