import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import './FinanceRequestStyle.css'
import SideMenu from '../../../components/Layout/menu/SideMenu';
import $ from "jquery";
import FinanceInvoicePopoup from '../../../components/Popups/FinanceInvoicePopoup/FinanceInvoicePopoup';
import AskDetailsPopup from '../../../components/Popups/AskDetailsPopup/AskDetailsPopup';
import AdditionalInformationPopup from '../../../components/Popups/AdditionalInformationPopup/AdditionalInformationPopup';
import FinancePayment from '../../../components/Popups/FinancePayment/FinancePayment';
import BankTransferPopup from '../../../components/Popups/BankTransferPopup/BankTransferPopup';
import CashDetailsPopup from '../../../components/Popups/CashDetailsPopup/CashDetailsPopup';
import PageLoader from "../../../components/UI/loader/PageLoader";
import { getAllRequests, expenseSliceFunction, clearState, getAllProcessed, rejectExpense, approveExpense } from '../../../reducers/expenseSlice';

import moment from 'moment';
import {
  feeData, getOrganizationProgramsWithBatch,
} from "../../../reducers/feeSlice";
import { useNavigate } from 'react-router-dom';


const Purpose = ["Admissions and Marketing", "Acad Ops/Student Experience",
  "Career Services", "Academics", "Admin", "Master Relations", "Travel"]

const FinanceRequest = () => {
  const dispatch = useDispatch()
  const [authority, setAuthority] = useState("")

  const { isFetching, isSuccess, isError, allRequests, allVendors, allProcessed } = useSelector(expenseSliceFunction);
  const [expense, setExpense] = useState("")
  const [purpose, setPurpose] = useState("")
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [searchTitle, setSearchTitle] = useState("")
  const [processed, setProcessed] = useState(false)

  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)

  const getAuthority = () => {
    let finance = localStorage.getItem("isFinanceMember")
    let admin = localStorage.getItem("canApproveExpense")

    if (admin === "true") {
      setAuthority("admin")
      setTimeout(() => {
        setStatus('Pending');
      }, 1500);
    }
    else if (finance === "true") {
      setAuthority("finance")
    }
    else {
      setAuthority("member")
    }
  }
  console.log(allProcessed)
  // dispatch(getAllVendors())

  const handleClick = (id, name = "") => {
    console.log("name", name);
    if (name == "reject") { dispatch(rejectExpense(id)) }
    else if (name == "accept") {
      dispatch(approveExpense(id))
    }
  }

  const handleContChange = () => {

    changeContainer(2, 1, 3);
    setProcessed(true)

    setExpense("")
    setPurpose("")
    setStatus("Completed")
    ref1.current.selectedIndex = 0
    ref2.current.selectedIndex = 0
    ref3.current.selectedIndex = 0

  }
  const handleContChange2 = () => {
    changeContainer(1, 2, 3);
    setProcessed(false)
    setExpense("")
    setPurpose("")
    if (authority === "admin") {
      setStatus("Pending")
    }
    ref1.current.selectedIndex = 0
    ref2.current.selectedIndex = 0
  }
  const handleContChange3 = () => {
    changeContainer(3, 1, 2);
    setProcessed(false)
    setExpense("")
    setPurpose("")
    setStatus("Completed")

    ref1.current.selectedIndex = 0
    ref2.current.selectedIndex = 0
  }

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      console.log(searchTitle)
      setSearch(searchTitle);
    }
  };

  useEffect(() => {
    var params = {
      expenseFor: expense,
      Purpose: purpose,
      status: status,
      searchTitle: searchTitle
    };
    console.log(params);
    if (!processed) { dispatch(getAllRequests(params)) }
    else { dispatch(getAllProcessed(params)) }
    dispatch(clearState());

  }, [expense, purpose, status, search, processed]);

  // console.log(expense, purpose, status,searchTitle);

  useEffect(() => {
    // var params = {
    //   expenseFor: expense,
    //   Purpose: purpose,
    //   status: status,
    //   searchTitle: searchTitle
    // };
    //   dispatch(getAllRequests(params))

    dispatch(getOrganizationProgramsWithBatch());
    getAuthority()

  }, [])
  useEffect(() => {
    if (isSuccess) {
      var params = {
        expenseFor: expense,
        Purpose: purpose,
        status: status,
        searchTitle: searchTitle
      };
      dispatch(getAllRequests(params))
      dispatch(clearState())
      setShowFinanceInvoicePopup(false)
    }
  }, [isSuccess])

  const { programNameList } = useSelector(feeData);
  const navigate = useNavigate()



  const changeContainer = (num1, num2, num3) => {
    $(`#heading-${num1}`).addClass('primary-text').removeClass('text-grey')
    $(`#heading-${num2},#heading-${num3}`).addClass('text-grey').removeClass('primary-text')
    if (num1 == 3) {
      num1 = 1;
      num2 = 2;
    }
    $(`#container-${num1}`).addClass('show')
    $(`#container-${num2}`).removeClass('show')
  }

  const [showAdditionalInfoPopup, setShowAdditionalInfoPopup] = useState(false)
  const [showFinanceInvoicePopup, setShowFinanceInvoicePopup] = useState(false)
  const [showAskDetailsPopup, setShowAskDetailsPopup] = useState(false)
  const [showFinancePaymentPopup, setShowFinancePayment] = useState(false)
  const [showCashDetailsPopup, setShowCashDetailsPopup] = useState(false)
  const [showBankTransferPopup, setShowBankTransferPopup] = useState(false)
  const [invoiceData, setInvoiceData] = useState([])



  const handleAdditionalInfoClose = () => {
    setShowAdditionalInfoPopup(!showAdditionalInfoPopup)
  }
  const handleAskDetailsClose = () => {
    setShowAskDetailsPopup(!showAskDetailsPopup)
  }
  const handleFinanceInvoiceClose = () => {
    setShowFinanceInvoicePopup(!showFinanceInvoicePopup)
  }
  const handleBankTransferPopup = () => {
    setShowBankTransferPopup(!showBankTransferPopup)
  }
  const handleCashDetailsPopup = () => {
    setShowCashDetailsPopup(!showCashDetailsPopup)
  }
  const handleFinancePaymentPopup = () => {
    setShowFinancePayment(!showFinancePaymentPopup)
  }

  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <>
      {isFetching && <PageLoader />}
      <SideMenu />
      <BankTransferPopup show={showBankTransferPopup} handleBankTransferPopup={handleBankTransferPopup} />
      <CashDetailsPopup show={showCashDetailsPopup} handleCashDetailsPopup={handleCashDetailsPopup} />
      <FinancePayment show={showFinancePaymentPopup} handleFinancePaymentPopup={handleFinancePaymentPopup} handleBankTransferPopup={handleBankTransferPopup} handleCashDetailsPopup={handleCashDetailsPopup} />
      <AskDetailsPopup show={showAskDetailsPopup} handleAskDetailsClose={handleAskDetailsClose} handleAdditionalInfoClose={handleAdditionalInfoClose} handleFinancePaymentPopup={handleFinancePaymentPopup} />
      <AdditionalInformationPopup show={showAdditionalInfoPopup} handleAdditionalInfoClose={handleAdditionalInfoClose} />
      <FinanceInvoicePopoup show={showFinanceInvoicePopup} handleClose={handleFinanceInvoiceClose} handleAskDetailsClose={handleAskDetailsClose} data={invoiceData} authority={authority} />
      <div className="main-body">


        {authority !== "member" ?
          <div className="page-header ">
            <button className="component-back" type='button' onClick={() => navigate(-1)}>
              <img src="../assets/icons/back-btn.svg" />
              Back to page
            </button>
            <div className="toggleBtns">
              <p className="primary-text pointer" onClick={handleContChange2} id='heading-1' >Requests Received</p>
              {authority === "admin" && <p className="text-grey pointer" onClick={handleContChange3} id='heading-3' >Requests Authorised</p>}
              <p className="text-grey pointer" onClick={handleContChange} id='heading-2' >All Processed Expenses </p>
            </div>
            <button className="primary-btn feed-btn" onClick={() => navigate("/raiseRequest", { exact: true })}>
              <img src="../assets/icons/plus.svg" alt="" /> Raise Requests
            </button>
            {/* :<>All Raised Requests <button className='primary-btn'>Raise Request</button></> } */}
          </div>
          :
          <div className="page-header">
            <p className="component-heading" style={{ color: "#004E9A" }}>All Raised Requests</p>
            <button className="primary-btn feed-btn" onClick={() => navigate("/raiseRequest", { exact: true })}>
              <img src="../assets/icons/plus.svg" alt="" /> Raise Requests
            </button>
          </div>
        }
        <div className="filter-bar">
          <form action="" className="search-bar">
            <img
              //   onClick={onClickSearch}
              src="../assets/icons/search.svg"
              alt=""
            />
            <input
              type="text"
              name=""
              id=""
              //   onChange={handleSearch}
              //   onKeyDown={onSearchKeyPress}
              placeholder="Search"
              className="clean-input" onKeyDown={onSearchKeyPress} onChange={(e) => setSearchTitle(e.target.value)}
            />
          </form>
          <div className="filters">
            <select id="type" defaultValue={"Expense For"} onChange={(e) => setExpense(e.target.value)} ref={ref1}>
              <option value=''>Expense For</option>
              {
                programNameList?.length > 0 && programNameList?.map((res, i) => (<option value={res.id} key={i}>{res.programName}</option>)
                )
              }
            </select>

            <select id="type" onChange={(e) => setPurpose(e.target.value)} ref={ref2}>
              <option value=''>Raised For</option>
              {Purpose && Purpose?.map((item, index) => (
                <option value={item} key={index}>{item == "Acad Ops/Student Experience" ? "Student Experience": item}</option>
              ))}

            </select>

            {!processed && authority === "admin" && status != 'pending' && <select id="type" defaultValue={"Status"} onChange={(e) => setStatus(e.target.value)} ref={ref3}>
              {/* <option value=''>Status</option> */}
              {/* <option value="Pending">Pending</option> */}
              <option value="Completed">Approved</option>
              <option value="Rejected">Rejected</option>

            </select>}
          </div>
        </div>

        <div className='addSessionContainer show scrollbar financeRequestContainer' id='container-1' style={{ display: 'none' }}>
          <div className="expenceTableOuter">
            <table className="expenceTable maxWidthTable" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th>Ticket ID</th>
                  <th>Raised By</th>
                  <th>Vendor Name</th>
                  {/* <th>Member Name</th> */}
                  <th>Expense For</th>
                  <th>Cohort</th>
                  <th>Invoice Type</th>
                  <th>Raised For</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Request</th>
                </tr>
              </thead>
              <tbody>
                {allRequests && allRequests.length > 0 && allRequests?.map((val, index) => (
                  <tr key={index} >
                    <td>{'INVOICE-' + val.ticketId}</td>
                    <td>{capitalize(val.Admin?.firstName ?? '-') + ' ' + capitalize(val.Admin?.lastName ?? '-')}</td>
                    <td>{val.VendorDetail?.vendorName ?? '-'}</td>
                    {/* <td>{val.MemberDetail?.memberName ?? '-'}</td> */}
                    <td>{val?.Program?.programName ?? '-'}</td>
                    <td>{val?.ProgramDetail?.name ?? '-'}</td>
                    <td>
                      {val.invoiceType === "Final" && (<p className="success-tag">{val.invoiceType} Payment</p>)}
                      {val.invoiceType === "Partial" && (<p className="draft-tag">{val.invoiceType} Payment</p>)}
                      {val.invoiceType === "Advance" && (<p className="primary-tag">{val.invoiceType} Payment</p>)}
                    </td>
                    <td>{val.purpose == "Acad Ops/Student Experience" ? "Student Experience": val.purpose}</td>
                    <td>₹ {val.amount}</td>
                    <td>{val.status === "Completed" && (<p className="tag-active">Approved</p>)}
                      {val.status === "Pending" && (authority === "admin" ?
                        (<><button className="success-btn-new" onClick={() => handleClick(val?.id, "accept")}>
                          <img src="../assets/icons/tickmark.svg" alt="" /> Approve</button>
                          <button className="reject-btn reject-btn-new mt-5" onClick={() => handleClick(val?.id, "reject")}>
                            <img src="../../assets/icons/redCross.svg" alt="" />{" "}
                            <img src="../../assets/icons/whiteCross.svg" alt="" />{" "}
                            Reject</button>
                        </>) : (<p className="tag-draft">{val.status}</p>))}
                      {val.status === "Rejected" && (<p className="tag-expired">{val.status}</p>)}
                    </td>
                    <td><button className="primary-btn" onClick={() => { setInvoiceData(val); setShowFinanceInvoicePopup(true) }}>View</button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="addSessionContainer scrollbar financeRequestContainer" id='container-2' style={{ display: 'none' }}>
          <div className="expenceTableOuter">
            <table className="expenceTable" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th>Ticket ID</th>
                  <th>Raised By</th>
                  <th>Vendor Name</th>
                  {/* <th>Member Name</th> */}
                  <th>Expense For</th>
                  <th>Invoice Type</th>
                  <th>Raised For </th>
                  <th>Expense Date</th>
                  <th>Amount</th>
                  <th>Request</th>
                </tr>
              </thead>
              <tbody>
                {console.log(allProcessed)}
                {allProcessed && allProcessed.length > 0 && allProcessed?.map((val, index) => (
                  <tr key={index} >
                    <td>{'INVOICE-' + val.ticketId}</td>
                    <td>{capitalize(val.Admin?.firstName ?? '-') + ' ' + capitalize(val.Admin?.lastName ?? '-')}</td>
                    <td>{val.VendorDetail?.vendorName ?? '-'}</td>
                    {/* <td>{val.MemberDetail?.memberName ?? '-'}</td> */}
                    <td>{val?.Program?.programName}</td>
                    <td>
                      {val.invoiceType === "Final" && (<p className="success-tag">{val.invoiceType} Payment</p>)}
                      {val.invoiceType === "Partial" && (<p className="draft-tag">{val.invoiceType} Payment</p>)}
                      {val.invoiceType === "Advance" && (<p className="primary-tag">{val.invoiceType} Payment</p>)}
                    </td>
                    <td>{val.purpose == "Acad Ops/Student Experience" ? "Student Experience": val.purpose}</td>
                    <td>
                      {moment(val.expenseDate).format("YYYY-MM-DD")}
                    </td>
                    <td>₹ {val.amount}</td>

                    <td><button className="primary-btn" onClick={() => { setInvoiceData(val); setShowFinanceInvoicePopup(true) }}>View</button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinanceRequest