import React, { useState } from "react";
import "./MeetingDetailDropdown.css";

const MeetingDetailDropdown = ({
  options,
  dropDownOnChhange,
}) => {
  return (
    <>
      <select id="type" className="popup-dropdown mt-5" 
      onChange={dropDownOnChhange}
      >
            {options?.length> 0 && options.map((op) => (
                <option key={op.id} value={op.id}>{op.firstName} {op.lastName}</option>
            ))}
        </select>
    </>
  );
};

export default MeetingDetailDropdown;
