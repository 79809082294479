import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  meetingData,
  getMeetingTitleData,
  getMentorStudentsData,
  getMeetingDetailListData,
  getAllUserData,
} from "../../../reducers/meetingSlice";
import "./MeetingListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import PageLoader from "../../../components/UI/loader/PageLoader";
import MeetFeedbackPopup from "../../../components/Popups/MeetFeedbackPopup/MeetFeedbackPopup";
import moment from "moment";
import MeetingDetailDropdown from "../../../components/Dropdowns/MeetingDetailDropdown/MeetingDetailDropdown";
const AdminMeetingDetails = () => {
  const {
    isFetching,
    getMeetingTitleList,
    getMentorStudentsList,
    getMeetingDetailsList,
    allUserData,
  } = useSelector(meetingData);
  const dispatch = useDispatch();
  const [meetingListData, setMeetingListData] = useState([]);
  const [meetingDetails, setMeetingDetail] = useState([]);
  const [mentorListData, setMentorListData] = useState([]);
  const [mentor, setMentor] = useState("");
  const [menuId, setMenuId] = useState(null);
  const [singleFeedBackDetail, setSingleFeedBackDetail] = useState(null);
  const [studentFeedBackDetail, setStudentFeedBackDetail] = useState(null);
  const [isMeetFeedbackPopupOpen, setIsMeetFeedbackPopupOpen] = useState(false);
  const [dropdownDataChange, setDropdownDataChange] = useState("");
  const [allUser, setAllUser] = useState([]);
  const [isDropDown,setIsDropDown] = useState(false);

  const toggleMeetFeedbackPopup = () => {
    setIsMeetFeedbackPopupOpen(false);
  };
  // Get All User
  useEffect(() => {
    dispatch(getAllUserData());
  }, []);

  useEffect(() => {
    if (allUser?.length > 0) {
      let obj = {
        userId: allUser[0]?.id,
      };
      dispatch(getMeetingTitleData(obj));
      dispatch(getMentorStudentsData(obj));
    }
  }, [allUser]);

  // set Data
  useEffect(() => {
    setMeetingListData(getMeetingTitleList);
    setMentorListData(getMentorStudentsList);
    setMeetingDetail(getMeetingDetailsList);
    setAllUser(allUserData);
  }, [
    getMeetingTitleList,
    getMentorStudentsList,
    getMeetingDetailsList,
    allUserData,
  ]);

  useEffect(() => {
    if(mentorListData?.length === 0 || allUser?.length === 0){
      return;
    }
    if(!isDropDown){
      setMentor(mentorListData[0]?.studentId);
      console.log("in if api",{studentId:mentorListData[0]?.studentId , userId: allUser[0]?.id});
      dispatch(getMeetingDetailListData({studentId:mentorListData[0]?.studentId , userId: allUser[0]?.id}));
    }else{
      setMentor(mentorListData[0]?.studentId);
      console.log("in else api",{studentId:mentorListData[0]?.studentId , userId: dropdownDataChange});
      dispatch(getMeetingDetailListData({studentId:mentorListData[0]?.studentId , userId: dropdownDataChange}));
    }
  },[mentorListData,allUser,dropdownDataChange])

  const feedBackDetailOpen = (data) => {
    setIsMeetFeedbackPopupOpen(true);
    setSingleFeedBackDetail(data);
    setStudentFeedBackDetail("");
  };
  const studentFeedBackDetailOpen = (data) => {
    setIsMeetFeedbackPopupOpen(true);
    setStudentFeedBackDetail(data);
    setSingleFeedBackDetail("");
  };

  const handleMentorChange = (e) => {
    let data = e.target.value;
    setMentor(data);
    let obj 
    if (dropdownDataChange) {
      obj = {
        studentId: data,
        userId: dropdownDataChange,
      }
    }else{
      obj = {
        studentId: data,
        userId: allUser[0]?.id,
      }
    }
    dispatch(getMeetingDetailListData(obj));
  };

  /**onClikc */
  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const dropDownOnChhange = (e) => {
    let data = e.target.value;
    setIsDropDown(true);
    setDropdownDataChange(data);
    let obj 
    console.log("mentor",mentor);
    console.log("mentorListData",mentorListData);
    if(mentor){
      console.log("inside if");
      obj = {
        studentId: mentor,
        userId: data,
      };
    }else{
      obj = {
        studentId: mentorListData[0]?.studentId,
        userId: data,
      };
    }
    dispatch(getMeetingTitleData(obj));
    dispatch(getMentorStudentsData(obj));
  };


  return (
    <>
      {isFetching && <PageLoader />}
      <div className="main-body">
        <SideMenu />
        <MeetFeedbackPopup
          show={isMeetFeedbackPopupOpen}
          handleClose={toggleMeetFeedbackPopup}
          singleFeedBackDetail={singleFeedBackDetail}
          studentFeedBackDetail={studentFeedBackDetail}
        />
        <div className="page-header">
          <p className="component-heading">Meeting Details</p>
          <div className="selected-fee">
            <MeetingDetailDropdown
              options={allUser}
              dropDownOnChhange={dropDownOnChhange}
            />
          </div>
        </div>

        <div className="filter-bar">
          <div className="mentor-details">
            {mentorListData &&
              mentorListData?.map((res, index) => (
                <label className="create-label" key={index}>
                  <input
                    className="radio-btn"
                    type="radio"
                    value={res?.Student && res?.Student?.id}
                    checked={mentor === res?.Student?.id}
                    onChange={handleMentorChange}
                    key={index}
                  />
                  <span className="radiomark"></span>
                  {res.Student && res.Student.firstName}{" "}
                  {res.Student && res.Student.lastName}
                </label>
              ))}
          </div>
        </div>
        <div className="feedback-container scrollbar">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr className="p-20">
                <th>Meeting Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Mentor Meeting Status</th>
                <th>Student Meeting Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {meetingListData &&
                meetingListData?.map((res, index) => (
                  <tr key={index}>
                    <td>{res.title}</td>
                    {meetingDetails &&
                      meetingDetails
                        .filter((row) => row.meetingMasterId === res.id)
                        .map((data, i) => (
                          <React.Fragment key={i}>
                            <td>
                              <div className="edit-date">
                                {moment(data.Date).format("DD-MMMM-YYYY")}
                              </div>
                            </td>
                            <td>{data.time}</td>
                            <td>{data.meetingStatusByMentor}</td>
                            <td>{data.meetingStatusByStudent}</td>
                            <td>
                              {data?.MeetFeedbacks?.length > 0 && (
                                <button
                                  className="action-btn"
                                  onClick={() => openActionMenu(index)}
                                  onBlur={closeActionMenu}
                                >
                                  <img src="../assets/icons/options.svg" />
                                  <ul
                                    className={
                                      menuId === index
                                        ? "action-dropdown active"
                                        : "action-dropdown"
                                    }
                                  >
                                    {data?.MeetFeedbacks?.filter(
                                      (dd) => dd.feedbackFor === "Student"
                                    )?.length > 0 ? (
                                      <li
                                        className="dropdown-item"
                                        onClick={() => feedBackDetailOpen(data)}
                                      >
                                        <img
                                          src="../assets/icons/preview.svg"
                                          alt=""
                                        />
                                        <p className="dropdown-text">
                                          Given By Mentor
                                        </p>
                                      </li>
                                    ) : (
                                      ""
                                    )}

                                    {data?.MeetFeedbacks?.filter(
                                      (dd) => dd.feedbackFor === "Mentor"
                                    )?.length > 0 ? (
                                      <li
                                        className="dropdown-item"
                                        onClick={() =>
                                          studentFeedBackDetailOpen(data)
                                        }
                                      >
                                        <img
                                          src="../assets/icons/preview.svg"
                                          alt=""
                                        />
                                        <p className="dropdown-text">
                                          Received By Student
                                        </p>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                  </ul>
                                </button>
                              )}
                            </td>
                          </React.Fragment>
                        ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminMeetingDetails;
