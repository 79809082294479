import React, { useState, useEffect } from "react";
import "./approvalListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getVendorOrderDetails,
  putSaveVendorOrderDetails,
  shippingData,
} from "../../../reducers/shippingSlice";
import PageLoader from "../../../components/UI/loader/PageLoader";
import { environment } from "../../../environments/environment";

const VendorList = () => {
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const { isFetching, vendorOrderDetails, offerBoxStatus } =
    useSelector(shippingData);

  //   useEffect =
  //     (() => {
  //       if (orderId) {
  //         dispatch(getVendorOrderDetails(orderId));
  //       }
  //     },
  //     [orderId]);

  useEffect(() => {
    dispatch(getVendorOrderDetails(orderId));
  }, [orderId]);

  const data = [
    {
      id: 101,
      name: "Manpreet Singh",
      number: 7814001299,
      email: "something@test.com",
    },
    {
      id: 201,
      name: "Arpit Singh Joshi",
      number: 9447650452,
      email: "not@test.com",
    },
  ];

  //   const handleCandidateChange = (id) => {
  //     if (selectedCandidates.includes(id))
  //       setSelectedCandidates((prev) => prev.filter((item) => item !== id));
  //     else setSelectedCandidates((prev) => [...prev, id]);
  //   };

  //   const handleAllChecked = () => {
  //     if (selectedCandidates.length === data?.length) setSelectedCandidates([]);
  //     else setSelectedCandidates(data?.map((item) => item.id));
  //   };

  const handleSaveClick = () => {
    console.log("dispatch");
    dispatch(putSaveVendorOrderDetails(orderId));
  };

  return (
    <>
      <SideMenu />
      {isFetching && <PageLoader />}
      <div className="main-body">
        <div className="page-header">
          <button className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            Back to page
          </button>
          {/* <p className="text-1">PGP in Technology & Business Management</p> */}
        </div>
        <div className="filter-bar">
          <form action="" className="search-bar">
            {/* <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Search"
              className="clean-input"
            /> */}
          </form>

          <div>
            <button
              className="primary-btn"
              style={{ "margin-right": "10px" }}
              onClick={() => {
                var url =
                  environment.baseURL +
                  `/api/org/orderId/${orderId}/downloadOfferLetterBoxApplicantCSV`;

                window.open(url, "_blank", "noopener,noreferrer");
              }}
            >
              Download Csv
            </button>

            <button className="primary-btn" onClick={handleSaveClick}>
              Save
            </button>
          </div>
        </div>
        <div className="feedback-container scrollbar">
          <table
            className="feedback-table table"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                {/* <th className="check-row">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                      checked={selectedCandidates.length === data?.length}
                      onChange={handleAllChecked}
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                </th> */}
                <th>S.No.</th>
                <th>Candidate name</th>
                <th>Applicant Id</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>House No./Flat No.</th>
                <th>Apartment/Road/Area</th>
                <th>Landmark</th>
                <th>City</th>
                <th>PinCode</th>
                <th>State</th>
                <th>Offer Box Status</th>
              </tr>
            </thead>
            <tbody>
              {vendorOrderDetails?.length > 0 &&
                vendorOrderDetails?.map((item, index) => {
                  const { Applicant, id } = item;

                  return (
                    <tr key={id}>
                      {/* <td>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkmark-btn"
                          checked={selectedCandidates.includes(item.id)}
                          onChange={() => handleCandidateChange(item.id)}
                        />
                        <span className="checkmarkspan"></span>
                      </label>
                    </td> */}
                      <td>{index + 1}</td>
                      <td>
                        {Applicant?.firstName + " " + Applicant?.lastName}
                      </td>
                      <td>{Applicant?.applicationId}</td>
                      <td>{Applicant?.mobileNumber ?? "--"}</td>
                      <td>{Applicant?.officialEmail ?? "--"}</td>
                      <td>{Applicant?.applicantdetail?.HouseNo ?? "--"}</td>
                      <td>
                        {Applicant?.applicantdetail?.ApartmentRoadArea ?? "--"}
                      </td>
                      <td>{Applicant?.applicantdetail?.Landmark ?? "--"}</td>
                      <td>
                        {Applicant?.applicantdetail?.City_Current ?? "--"}
                      </td>
                      <td>{Applicant?.applicantdetail?.PinCode ?? "--"}</td>
                      <td>
                        {Applicant?.applicantdetail?.State_Current ?? "--"}
                      </td>
                      <td>{offerBoxStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default VendorList;
