import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  layoutSelector,
  setQuestionType,
} from "../../../reducers/pageLayoutSlice";
import "./DropdownStyle.css";

function Dropdown({ selected, setSelected }) {
  const [isActive, setIsActive] = useState(false);
  const { questionType } = useSelector(layoutSelector);
  useEffect(() => {
    setSelected(questionType);
  });

  const dispatch = useDispatch();
  // const options = ['React', 'React2', 'Angular']

  const options = [
    {
      type: "rating",
      image:
        "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/rating.svg",
    },
    {
      type: "paragraph",
      image:
        "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/paragraph.svg",
    },
    {
      type: "multiSelect",
      image:
        "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/multiSelect.svg",
    },
    {
      type: "singleSelect",
      image:
        "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/singleSelect.svg",
    },
    {
      type: "uploadMedia",
      image:
        "https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/uploadMedia.svg",
    },
  ];
  return (
    <div className="dropdown">
      <div
        className="dropdown-btn text-6"
        onClick={(e) => setIsActive(!isActive)}
      >
        {questionType.image != null && (
          <img src={questionType.image} alt="logo" />
        )}
        {questionType.type}

        <span className="fas fa-caret-down"></span>
      </div>
      {isActive && (
        <div className="dropdown-content text-6">
          {options.map(
            (option, index) => (
              <div
                key={index}
                onClick={(e) => {
                  setSelected(option);
                  dispatch(setQuestionType(option));
                  setIsActive(false);
                }}
                className="dropdown-item"
              >
                {option.image != null && <img src={option.image} alt="logo" />}
                {option.type}
              </div>
            )

            //   (
            //     <div
            //       onClick={(e) => {
            //         setSelected(option);
            //         setIsActive(false);
            //       }}
            //       className="dropdown-item"
            //     >
            //       {option}
            //     </div>
            //   )
          )}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
