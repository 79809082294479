import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import PageLoader from "../../../components/UI/loader/PageLoader";
import FeeDuePopup from "../../../components/Popups/FeeDuePopup/FeeDuePopup";
import CreateInterviewPopup from "../../../components/Popups/createInterviewPopup/createInterviewPopup";
import moment from "moment";

import {
  getShortlistedApplicantList,
  applicantData,
  clearState,
  clearCsvState,
  deleteInterView,
  getApplicantFeedbackAns,
  approveApplicants,
  rejectApplicants,
  generateNewPartialFeeDue,
  getWelcomeLetterStudentList,
  postSendMentorCallMail,
} from "../../../reducers/applicantSlice";
import InterViewDeletePopup from "../../../components/Popups/InterViewDeletePopup/InterViewDeletePopup";
import ToastMessage from "../../../components/Toast/ToastComponent";
import "./ShortListStyle.css";
import ActionPopup from "../../../components/Popups/ActionPopup/ActionPopup";
import MuLoanPopUp from "../../../components/Popups/MuLoan/MuLoan";
import StudentAvailibilityPopup from "../../../components/Popups/StudentAvailibilityPopup/StudentAvailibilityPopup";
import { environment } from "../../../environments/environment";
import MentorCallFeedbackPopup from "../../../components/Popups/MentorCallFeedback/MentorCallFeedbackPopup";
const OfferLetterList = () => {
  const navigate = useNavigate();
  const [dueType, setDueType] = useState("offerLetter");
  const param = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const programBatchId = param.proId;
  const programMasterId = searchParams.get("programMasterId");
  const [isNewOffer, setIsNewOffer] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [shortListDataArr, setShortListDataArr] = useState([]);

  const [pageNo, setPageNo] = useState("1");
  const [pageSize, setPageSize] = useState("10");
  const [showMoreLoader, setShowMoreLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [viewType, setOfferViewType] = useState("");
  const [newApplicantId, setNewApplicantId] = useState("");

  const [loggedInUser, setLoggedInUser] = useState("");

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    setLoggedInUser(userName);
  }, []);

  //action Popup states
  const [actionMessage, setActionMessage] = useState("");
  const [actionHeading, setActionHeading] = useState("");
  const [showAction, setShowAction] = useState(false);
  const [actionType, setActionType] = useState("");
  const [actionName, setActionName] = useState("");

  const [searchTitle, setSearchTitle] = useState("");
  const [emailStatus, setEmailStatus] = useState("true");
  const [approvedStatus, setApprovedStatus] = useState("Approved");
  const [welcomeStatus, setWelcomeStatus] = useState("false");

  const handleSearch = (event) => {
    var search = event.target.value;
    setSearchTitle(search);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchByTitle(e.target.value);
    }
  };

  const searchByTitle = (val) => {
    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: val,
      emailStatus: emailStatus,
      approvedStatus: approvedStatus,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    dispatch(getShortlistedApplicantList({ obj, params }));
    dispatch(clearState());
    setIsChecked([]);
    setAllChecked(false);
    setPageNo("1");
  };

  useEffect(() => {
    setPageNo("1");
    setIsChecked([]);
    setAllChecked(false);
  }, [pageSize, approvedStatus, emailStatus]);

  const [menuId, setMenuId] = useState(null);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isInterviewPopup, setIsInterviewPopup] = useState(false);
  const [applicantId, setApplicantId] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [interviewerId, setInterviewerId] = useState("");
  const [scholarshipAmount, setScholarshipAmount] = useState(0);
  const [feeDueId, setFeeDueId] = useState("");

  const [offerStatusObj, setOfferStatusObj] = useState({});

  const closeInterviewPopup = () => {
    setIsInterviewPopup(false);
    setInterviewerId("");
    setApplicantId("");
    setPopupTitle("");
    setScholarshipAmount(0);
    // setInterviewData(null);
  };

  //check logic
  const [allChecked, setAllChecked] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isTotalSelected, setTotalSelected] = useState(false);
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isMentorFeedbackOpen, setIsMentorFeedbackOpen] = useState(false);
  const [shortListId, setShortListId] = useState([]);

  const clearSelected = () => {
    setTotalSelected((prev) => !prev);
    setIsChecked([]);
    setAllChecked(false);
  };

  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const {
    shortListData,
    shortListCount,
    isFetching,
    feedbackAns,
    isSuccess,
    isError,
    deleteInterViewList,
    admissionAmount,
    offerLetterAmount,
    tutionFeeAmount,
  } = useSelector(applicantData);

  const deleteShortlistMessage = () => {
    if (deleteInterViewList) {
      setTimeout(() => setEnableSuccessMessage(true), 2000);
      dispatch(clearCsvState());
      setIsChecked([]);
      setAllChecked(false);
    }
  };

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: searchTitle,
      emailStatus: emailStatus,
      approvedStatus: approvedStatus,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    dispatch(getShortlistedApplicantList({ obj, params }));
    dispatch(clearState());
    // setIsChecked([]);
    // setAllChecked(false);
  }, [emailStatus, approvedStatus, pageNo, pageSize, welcomeStatus]);

  useEffect(() => {
    console.log("in shortList", isSuccess);
    if (isSuccess) {
      closeInterviewPopup();
      setShowAction(false);
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        approvedStatus: approvedStatus,
        pageNo: pageNo,
        pageSize: pageSize,
      };

      dispatch(getShortlistedApplicantList({ obj, params }));
      dispatch(clearState());
      setIsChecked([]);
      setAllChecked(false);
      closeStudentAvailabilityPopup();
    }
  }, [isSuccess]);

  useEffect(() => {
    deleteShortlistMessage();
  }, [deleteInterViewList]);

  const sendOfferLetters = (obj) => {
    // console.log(isChecked);
  };

  const onApproveClick = (ids) => {
    if (ids.length > 0) {
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        approvedStatus: approvedStatus,
      };
      dispatch(
        approveApplicants({
          obj: ids,
          programId: programBatchId,
          isTotalSelected,
          approvedBy: loggedInUser,
          query: params,
        })
      );
      setIsChecked([]);
      setAllChecked(false);
    }
  };
  const onRejectClick = (ids) => {
    if (ids.length > 0) {
      dispatch(rejectApplicants({ obj: ids, programId: programBatchId }));
      setIsChecked([]);
      setAllChecked(false);
    }
  };

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onDeleteShortListClick = (interViewId) => {
    var params = {
      searchTitle: searchTitle,
      emailStatus: emailStatus,
      approvedStatus: approvedStatus,
    };
    dispatch(
      deleteInterView({
        programId: programBatchId,
        Id: interViewId,
        query: params,
        isTotalSelected,
      })
    );
    setIsDeleteOpen(false);
    setIsChecked([]);
    setAllChecked(false);
  };

  const onSubmit = () => {
    if (actionName === "Approve") {
      if (actionHeading === "Approve Candidates") {
        onApproveClick(isChecked);
      } else {
        if (applicantId !== "") {
          onApproveClick([applicantId]);
        }
      }
    } else if (actionName === "Reject") {
      if (applicantId !== "") {
        onRejectClick([applicantId]);
      }
    } else if (actionName === "Delete") {
      if (applicantId === "" || feeDueId === "") {
        return;
      }
      dispatch(
        generateNewPartialFeeDue({
          applicantId: applicantId,
          feeDueId: feeDueId,
        })
      );
    }
  };

  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);
  const [isMailAccess, setMailAccess] = useState(false);
  const [isOfferAccess, setOfferAccess] = useState(false);
  const [studentFeedbackAccess, setFeedbackAccess] = useState(false);

  useEffect(() => {
    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    const isOfferAccess =
      localStorage.getItem("offerLetterApproval") === "true";
    const isMailAccess = localStorage.getItem("mailSending") === "true";
    const interviewFeedback =
      localStorage.getItem("interviewFeedback") === "true";
    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
    if (isOfferAccess) {
      setOfferAccess(isOfferAccess);
    }
    if (isMailAccess) {
      setMailAccess(isMailAccess);
    }
    if (interviewFeedback) {
      setFeedbackAccess(interviewFeedback);
    }

    setShortListDataArr([]);
  }, []);

  const handleLoadClick = () => {
    if (pageNo == Math.ceil(shortListCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
    setLoaded(true);
    setShowMoreLoader(true);
  };

  useEffect(() => {
    if (shortListData) {
      console.log("checking loaded status", loaded);
      if (loaded === true) {
        setShortListDataArr([...shortListDataArr, ...shortListData]);
        setLoaded("done");
      } else if (loaded === false) {
        setShortListDataArr(shortListData);
      } else {
        console.log(null);
      }
      setLoaded(false);
    }

    // setApplicantListArray(applicantListDetail);
  }, [shortListData]);

  const checkDueOnDate = (oldDate) => {
    var isDueOn = false;
    var todayDate = new Date();
    var dateOne = new Date(oldDate);
    if (todayDate > dateOne) {
      return (isDueOn = false);
    } else {
      return (isDueOn = true);
    }
  };

  const closeStudentAvailabilityPopup = () => {
    setShow(false);
    setOfferViewType("");
    setOfferStatusObj({});
  };

  const onClickStudentCSV = () => {
    var url =
      environment.baseURL +
      "/api/org/programId/" +
      programBatchId +
      "/downloadSentOfferLetterApplicantList";

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onClickSendMentorMail = (applicantId) => {
    dispatch(postSendMentorCallMail({ applicantId }));
  };

  const viewStudentTimeline = (id, isStudent) => {
    window.open(
      `/particularStudentfee?studentId=${id}&programBatchId=${programBatchId}&isStudent=${isStudent}`
    );
  };

  const toggleMentorFeedbackPopup = () => {
    setIsMentorFeedbackOpen(!isMentorFeedbackOpen);
  };

  console.log("pri", isMentorFeedbackOpen);

  return (
    <>
      <SideMenu />
      {isFetching && pageNo === "1" ? <PageLoader /> : ""}
      <ActionPopup
        show={showAction}
        heading={actionHeading}
        handleClose={() => {
          setShowAction(false);
          setApplicantId("");
          setFeeDueId("");
        }}
        message={actionMessage}
        action={actionName}
        actionType={actionType}
        onSubmit={onSubmit}
      />
      {/* <CreateInterviewPopup
        type="feedback"
        togglePopup={closeInterviewPopup}
        show={isInterviewPopup}
        feedbackAns={feedbackAns}
        // applicantName={applicantName}
        interviewerId={interviewerId}
        applicantId={applicantId}
        // interviewData={interviewData}
        programBatchId={programBatchId}
        popupTitle={popupTitle}
        scholarshipAmount={scholarshipAmount}
      /> */}
      <FeeDuePopup
        show={isPopupActive}
        closePopup={() => setIsPopupActive(false)}
        sendOfferLetters={sendOfferLetters}
        student={isChecked}
        programBatchId={programBatchId}
        programMasterId={programMasterId}
        searchTitle={searchTitle}
        emailStatus={emailStatus}
        approvedStatus={approvedStatus}
        pageNo={pageNo}
        pageSize={pageSize}
        isTotalSelected={isTotalSelected}
        shortListCount={shortListCount}
        dueType={dueType}
        isNewOffer={isNewOffer}
        feeDueId={feeDueId}
        clearSelected={clearSelected}
        applicantId={applicantId}
        setPageNo={setPageNo}
      />

      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Shorlisted deleted successfully"}
      />

      <InterViewDeletePopup
        handleDelete={onDeleteShortListClick}
        handleClose={toggleDeletePopup}
        shortListId={shortListId}
        show={isDeleteOpen}
        name={"Shortlisted"}
      />

      <StudentAvailibilityPopup
        show={show}
        closePopup={closeStudentAvailabilityPopup}
        popupTitle={"Student Availability"}
        applicantId={applicantId}
        setLoaded={setLoaded}
        setPageNo={setPageNo}
        viewType={viewType}
        dlResponseReason={""}
        dlResponse={""}
        newApplicantId={newApplicantId}
        setNewApplicantId={setNewApplicantId}
        offerStatusObj={offerStatusObj}
      />
      {/* 
      <MentorCallFeedbackPopup
        show={show}
        handleClose={toggleMentorFeedbackPopup}
      /> */}

      <div className="main-body">
        <div className="page-header">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="component-back"
          >
            <img src="../assets/icons/back-btn.svg" />
            Back to Page
          </button>
          <div className="studentDetails">
            <div className="student-inner-left">
              <div className="student-details-outer">
                <div className="student-details">
                  <p className="student-name text-18">
                    {admissionAmount?.ProgramDetail?.Program?.programName}{" "}
                    <img src="../assets/icons/sectionSymbol.svg" />{" "}
                    <span className="text-danger">Offer Letters</span>
                  </p>
                </div>
                <div className="student-details">
                  <p className="student-mail">
                    {admissionAmount?.ProgramDetail?.batch} &#xb7;{" "}
                    {admissionAmount?.ProgramDetail?.durationInMonths} months
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="student-inner-right bg-grey">
              <div className="fee-box total">
                <p>Total Dues</p>
                <p className="text-dark">
                  ₹ {offerLetterAmount?.totalAmount?.toFixed(2) ?? 0}
                </p>
              </div>
              <div className="fee-box">
                <p>Received</p>
                <p className="text-success">
                  ₹ {offerLetterAmount?.submittedAmount?.toFixed(2) ?? 0}
                </p>
              </div>
              <div className="fee-box">
                <p>Pending</p>
                <p className="text-danger">
                  ₹ {offerLetterAmount?.pendingAmount?.toFixed(2) ?? 0}
                </p>
              </div>
            </div> */}

            <div className="filters">
              <button
                className="primary-btn feed-btn"
                onClick={onClickStudentCSV}
              >
                Offer Letter CSV
              </button>
            </div>
          </div>

          {/* <p className="text-1">PGP in Technology & Business Management</p> */}
        </div>

        <div className="filter-bar">
          <form action="" className="search-bar">
            <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              onChange={handleSearch}
              onKeyDown={onSearchKeyPress}
              placeholder="Search"
              className="clean-input"
            />
          </form>

          <div className="total-data-desc">
            Showing {shortListDataArr?.length} out of {shortListCount}
          </div>

          <div className="filters">
            <select id="type" onChange={(e) => setPageSize(e.target.value)}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div className="feedback-container scrollbar shortlist offerLetterContainer">
          <table
            className="feedback-table table maxWidthTable offerLetterTable"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th>candidate name</th>
                <th>Phone Number</th>
                <th>Email Id</th>
                <th>Scholarship </th>
                <th>Fee</th>
                <th>Fee Submission date</th>
                <th>Fee Due date</th>
                <th>Mentor Mail Status</th>
                <th>Mentor Call Status</th>
                <th>Mentor Name</th>
                {/* <th>Mentor Feedback</th> */}
                <th>Offer Acceptance Status</th>
                <th>Letter Box Status</th>
                <th>Letter Sent By</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {shortListDataArr &&
                shortListDataArr.map((item, index) => (
                  <tr key={item.id}>
                    <td className="programDetail">
                      <div
                        onClick={() =>
                          viewStudentTimeline(item.id, item.isStudent)
                        }
                        className="programDetailText"
                      >
                        <p className="primary-text pointer">
                          {(item.firstName + " " + item.lastName)
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.substring(1)
                            )
                            .join(" ")}
                        </p>
                      </div>
                    </td>
                    <td>{item.mobileNumber}</td>
                    <td>{item.officialEmail}</td>
                    <td>
                      ₹{" "}
                      {parseFloat(
                        item?.scholarshipAmount * tutionFeeAmount?.amount
                      ) / 100}{" "}
                      ({item?.scholarshipAmount} %){" "}
                    </td>

                    <>
                      {item?.FeeDues[0]?.isPaid ? (
                        <td>
                          <p className="text-success">
                            ₹ {item?.FeeDues[0]?.FeeTransactions[0]?.amount}
                          </p>
                        </td>
                      ) : (
                        <td>
                          <p className="text-danger">Pending</p>
                        </td>
                      )}
                      {item?.FeeDues[0]?.isPaid ? (
                        <td>
                          {moment(
                            item?.FeeDues[0]?.FeeTransactions[0]?.paidOn
                          ).format("DD MMMM YYYY")}
                        </td>
                      ) : (
                        <td>---</td>
                      )}

                      <td>
                        {item?.FeeDues[0]?.isPaid ? (
                          <p className="text-success">
                            {moment(item?.FeeDues[0]?.dueOn).format(
                              "DD MMMM YYYY"
                            )}
                          </p>
                        ) : (
                          <>
                            {checkDueOnDate(item?.FeeDues[0]?.dueOn) ===
                            false ? (
                              <p className="text-danger">
                                {moment(item?.FeeDues[0]?.dueOn).format(
                                  "DD MMMM YYYY"
                                )}
                              </p>
                            ) : (
                              <p className="text-success">
                                {moment(item?.FeeDues[0]?.dueOn).format(
                                  "DD MMMM YYYY"
                                )}
                              </p>
                            )}
                          </>
                        )}
                      </td>

                      <td>
                        {item?.isMentorshipCallMailSent ? (
                          <p className="tag-active">Yes</p>
                        ) : (
                          <p className="tag-draft">No</p>
                        )}
                      </td>

                      <td>
                        {item?.mentorshipCallStatus === "Pending" && (
                          <p className="tag-draft">Pending</p>
                        )}
                        {item?.mentorshipCallStatus === "Scheduled" && (
                          <p className="tag-active">
                            Scheduled
                            {/* {moment(ApplicantInterviews[0]?.date).format("lll")} */}
                          </p>
                        )}
                        {item?.mentorshipCallStatus === "Completed" && (
                          <p className="tag-active">Completed</p>
                        )}
                      </td>

                      <td>{item?.mentorName}</td>

                      {/* <td>--</td> */}

                      <td className="toastRow">
                        <div className="hoverElement">
                          {item?.offerLetterStatus === "Pending" ? (
                            <p>Pending</p>
                          ) : item?.offerLetterStatus === "willPay" ? (
                            <p>Will Pay</p>
                          ) : (
                            <p>{item?.notInterestedReason ?? "--"}</p>
                          )}
                          {/* {item?.offerLetterStatus !== "notInterested" &&
                            checkDueOnDate(item?.FeeDues[0]?.dueOn) ===
                              true && ( */}
                          <img
                            onClick={() => {
                              setShow(true);
                              setApplicantId(item?.id);
                              setOfferViewType("offerStatus");
                              setOfferStatusObj({
                                offerLetterStatus: item?.offerLetterStatus,
                                notInterestedReason:
                                  item?.notInterestedReason ?? "",
                              });
                            }}
                            src="../assets/icons/edit.svg"
                            className="pointer"
                          />
                          {/* )} */}
                        </div>
                        {item?.offerLetterStatus === "notInterested" && (
                          <div className="tooltipOuter">
                            <p className="tooltipText">
                              {item?.notInterestedReason}
                            </p>
                          </div>
                        )}
                      </td>
                      <td>{item?.offerLetterBox}</td>
                      <td>{item?.offerLetterSentBy ?? "--"}</td>
                    </>

                    {!viewOnlyAccess && (
                      <td className="text-center">
                        <button
                          className="action-btn"
                          onClick={() => openActionMenu(index)}
                          onBlur={closeActionMenu}
                        >
                          <img src="../assets/icons/options.svg" />
                          <ul
                            className={`action-dropdown ${
                              menuId === index ? "active" : ""
                            } approval-drpdown`}
                          >
                            <>
                              <li
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    item?.FeeDues[0]?.paymentLink
                                  )
                                }
                                className="dropdown-item "
                              >
                                <img src="../assets/icons/copy.svg" alt="" />
                                <p className="dropdown-text">
                                  Copy Payment Link
                                </p>
                              </li>

                              <li
                                onClick={() => {
                                  onClickSendMentorMail(item?.id);
                                }}
                                className="dropdown-item "
                              >
                                <img src="../assets/icons/copy.svg" alt="" />
                                <p className="dropdown-text">
                                  Send Mentor Mail
                                </p>
                              </li>

                              {/* <li
                                onClick={() => {
                                  onClickSendMentorMail(item?.id);
                                }}
                                className="dropdown-item "
                              >
                                <img src="../assets/icons/copy.svg" alt="" />
                                <p className="dropdown-text">
                                  Send Mentor Mail
                                </p>
                              </li>

                              <li
                                onClick={() => {
                                  toggleMentorFeedbackPopup();
                                }}
                                className="dropdown-item "
                              >
                                <img src="../assets/icons/copy.svg" alt="" />
                                <p className="dropdown-text">
                                  Applicant Feedback
                                </p>
                              </li> */}

                              {isOfferAccess && (
                                <>
                                  {!item?.FeeDues[0]?.isPaid && (
                                    <li
                                      onClick={() => {
                                        setIsPopupActive(true);
                                        setIsNewOffer(false);
                                        setFeeDueId(item?.FeeDues[0]?.id);
                                        setApplicantId(item?.id);
                                      }}
                                      className="dropdown-item "
                                    >
                                      <img
                                        src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/sendWelcomeLetter.svg"
                                        alt=""
                                      />
                                      <p className="dropdown-text">
                                        Resend Offer Letter
                                      </p>
                                    </li>
                                  )}
                                </>
                              )}

                              <li
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    item?.FeeDues[0]?.pdfLink
                                  )
                                }
                                className="dropdown-item "
                              >
                                <img src="../assets/icons/copy.svg" alt="" />
                                <p className="dropdown-text">Copy PDF Link</p>
                              </li>

                              {/* <li
                                className="dropdown-item "
                                // onClick={() => dispatch(generateNewPartialFeeDue({ applicantId: item?.id, feeDueId: item?.FeeDues[0]?.id }))}
                                onClick={() => {
                                  setApplicantId(item?.id);
                                  setFeeDueId(item?.FeeDues[0]?.id);
                                  setShowAction(true);
                                  setActionHeading("Delete Offer Letter");
                                  setActionMessage(
                                    "Are you sure you want to delete applicant offer letter?"
                                  );
                                  setActionName("Delete");
                                  setActionType("warning");
                                }}
                              >
                                <img src="../assets/icons/delete.svg" alt="" />
                                <p className="dropdown-text">Delete</p>
                              </li> */}
                            </>
                            {/* ) : (
                              <>
                                <li
                                  className="dropdown-item "
                                  onClick={() => onDeleteClick([item.id])}
                                >
                                  <img
                                    src="../assets/icons/delete.svg"
                                    alt=""
                                  />
                                  <p className="dropdown-text">Delete</p>
                                </li>
                              </>
                            )} */}
                          </ul>
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>

          {shortListDataArr?.length !== shortListCount && (
            <div className="loadMoreRow">
              <button
                className={`${
                  isFetching && showMoreLoader
                    ? "primary-btn-a active"
                    : "primary-btn-a"
                }`}
                onClick={handleLoadClick}
              >
                <img src="../assets/icons/loader icon-01.png" /> Show More
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OfferLetterList;
