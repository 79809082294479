import React from "react";
import "./NewMeetingStyle.css";
import SideMenu from "../../components/Layout/menu/SideMenu";
import AgendaPopup from "../../components/Popups/AgendaPopup/AgendaPopup";
import ExtendDatePopup from '../../components/Popups/ExtendDatePopup/ExtendDatePopup'

const NewMeeting = () => {
  return (
    <>
      <SideMenu />
      <AgendaPopup/>
      <ExtendDatePopup/>
      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">Dashboard</p>{" "}
          <button className="primary-btn feed-btn">
            {" "}
            <img src="../assets/icons/plus.svg" alt="" /> Add Meeting
          </button>
        </div>
        <div className="filter-bar">
          <select id="type" className="faculty-select">
            <option value="hide">Select Student</option>
            <option value="1">Select Student 1</option>
            <option value="2">Select Student 2</option>
            <option value="3">Select Student 3</option>
          </select>
          <div className="filters">
            <select id="type">
              <option value="hide">1-50</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
        </div>
        <div className="feedback-container">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Meeting Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Availability</th>
                <th>Meeting Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Meeting Session 1</td>
                <td>18 June, 2022</td>
                <td>09:00 to 10:00 AM</td>
                <td>
                  <p className="text-success">Approved</p>
                </td>
                <td>
                  <p className="tag-expired">Cancelled</p>
                </td>
                <td className="d-flex">
                  <div className="edit-btn active">
                    <img src="../assets/icons/edit.svg" />
                    <img src="../assets/icons/editDisable.svg" />
                  </div>
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Meeting Session 1</td>
                <td>18 June, 2022</td>
                <td>09:00 to 10:00 AM</td>
                <td>
                  <p className="text-success">Approved</p>
                </td>
                <td>
                  <p className="tag-active">Completed</p>
                </td>
                <td className="d-flex">
                  <div className="edit-btn">
                    <img src="../assets/icons/edit.svg" />
                    <img src="../assets/icons/editDisable.svg" />
                  </div>
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Meeting Session 1</td>
                <td>18 June, 2022</td>
                <td>09:00 to 10:00 AM</td>
                <td>
                  <button className="approve-btn">
                    <img src="../assets/icons/tickmark.svg" alt="" /> Approve
                  </button>
                </td>
                <td>
                  <p className="tag-draft">Pending</p>
                </td>
                <td className="d-flex">
                  <div className="edit-btn active">
                    <img src="../assets/icons/edit.svg" />
                    <img src="../assets/icons/editDisable.svg" />
                  </div>
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                    <ul className="action-dropdown active">
                      <li className="dropdown-item">
                        <img src="../assets/icons/message.svg" alt="" />
                        <p className="dropdown-text">Feedback Detail</p>
                      </li>
                      <li className="dropdown-item">
                        <img src="../assets/icons/plus-black.svg" alt="" />
                        <p className="dropdown-text">Add Agenda</p>
                      </li>
                      <li className="dropdown-item">
                        <img src="../assets/icons/delete.svg" alt="" />
                        <p className="dropdown-text text-danger">
                          Cancel Meeting
                        </p>
                      </li>
                    </ul>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default NewMeeting;
