import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let adminId;


let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};

// Add Vendor Details
export const addVendorDetails = createAsyncThunk(
  "expense/addVendorDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      
      const response = await fetch(environment.baseURL +"/api/org/addVendorDetails", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      });
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addMemberDetails = createAsyncThunk(
  "expense/addMemberDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      
      const response = await fetch(environment.baseURL +"/api/org/addMemberDetails", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      });
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getDashboardData = createAsyncThunk(
  "expense/getDashboardData",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      var programId = params?.programId ?? "";
      var cohort = params?.cohort ?? "";

      

      const response = await fetch(environment.baseURL +`/api/org/admin/${adminId}/getDashboardData?programId=${programId}&cohort=${cohort}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      });
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// Get All Vendors
export const getAllVendors = createAsyncThunk(
  "expense/getAllVendors",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(environment.baseURL +"/api/org/getAllVendors", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      });
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getAllMembers = createAsyncThunk(
  "expense/getAllMembers",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(environment.baseURL +"/api/org/getAllMembers", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      });
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const postRequestData = createAsyncThunk(
  "expense/postRequestData",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
     

      const response = await fetch(
        environment.baseURL +
          "/api/org/"+orgId +"/admin/"+adminId +'/raiseExpenseRequest',

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllRequests = createAsyncThunk(
  "expense/getAllRequests",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      var status = params?.status ?? "";
      var expenseFor = params?.expenseFor ?? "";
      var Purpose = params?.Purpose ?? "";
      var search = params?.searchTitle ?? "";
      const response = await fetch(environment.baseURL +"/api/org/admin/" +adminId+ `/getAllExpenses?status=${status}&Purpose=${Purpose}&expenseFor=${expenseFor}&searchTitle=${search}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      });
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const rejectExpense = createAsyncThunk(
  "expense/rejectExpense",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/"+`/rejectExpense/${id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const approveExpense = createAsyncThunk(
  "expense/approveExpense",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
     
      const response = await fetch(
        environment.baseURL +
          "/api/org"+`/approveExpense/${id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const processExpense = createAsyncThunk(
  "expense/processExpense",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
     

      const response = await fetch(
        environment.baseURL +
          "/api/org/"+"admin/"+adminId +`/processPayment/${obj.expenseId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllProcessed = createAsyncThunk(
  "expense/getAllProcessed",
  async (params, thunkAPI) => {
    try {
      setTokenValues();
      var expenseFor = params?.expenseFor ?? "";
      var Purpose = params?.Purpose ?? "";
      var search = params?.searchTitle ?? "";
      const response = await fetch(environment.baseURL +"/api/org/admin/" +adminId+`/getProcessedExpenses?&Purpose=${Purpose}&expenseFor=${expenseFor}&searchTitle=${search}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      });
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);



export const expenseSlice = createSlice({
  name: "expenseManagement",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    allVendors:[],
    allMembers:[],
    allRequests:[],
    allProcessed:[],
    dashboardData:[],
    requestRaised:false
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      // state.isFetching = false;
      state.requestRaised =false
      return state;
    },
  },
  extraReducers: {
    [addVendorDetails.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      return state;
    },
    [addVendorDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addVendorDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [addMemberDetails.fulfilled]: (state, { payload }) => {
      console.log(payload,"member")
      state.isSuccess = true;
      state.isFetching = false;
      return state;
    },
    [addMemberDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addMemberDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllVendors.fulfilled]: (state, { payload }) => {
      state.allVendors = payload;
      state.isFetching = false;
      return state;
    },

    [getAllVendors.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllVendors.pending]: (state) => {
      state.isFetching = true;
    },
    [postRequestData.fulfilled]: (state, { payload }) => {
     
     
      state.isFetching = false;
      state.isSuccess =true
      state.requestRaised =true
      return state;
    },
    [postRequestData.rejected]: (state, { payload }) => {
          state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postRequestData.pending]: (state) => {
     
      state.isFetching = true;
    },

     [getAllRequests.fulfilled]: (state, { payload }) => {
     
      state.allRequests = payload; 
      state.isFetching = false;
      return state;
    },
    [getAllRequests.rejected]: (state, { payload }) => {
          state.isFetching = false;
         state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllRequests.pending]: (state) => {
      state.isFetching = true;
    },
    [rejectExpense.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess =true
      return state;
    },
    [rejectExpense.rejected]: (state, { payload }) => {
          state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [rejectExpense.pending]: (state) => {
     
      state.isFetching = true;
    },  
    [approveExpense.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess =true
      return state;
    },
    [approveExpense.rejected]: (state, { payload }) => {
          state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [approveExpense.pending]: (state) => {
      state.isFetching = true;
    },  
    [processExpense.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess =true
      return state;
    },
    [processExpense.rejected]: (state, { payload }) => {
          state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [processExpense.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllProcessed.fulfilled]: (state, { payload }) => {
      console.log(payload)
      state.allProcessed = payload;
      state.isFetching = false;
      return state;
    },

    [getAllProcessed.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllProcessed.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllMembers.fulfilled]: (state, { payload }) => {
      state.allMembers = payload;
      state.isFetching = false;
      return state;
    },

    [getAllMembers.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllMembers.pending]: (state) => {
      state.isFetching = true;
    },
    [getDashboardData.fulfilled]: (state, { payload }) => {
      console.log("dataaaa",payload);
      state.dashboardData = payload;
      state.isFetching = false;
      return state;
    },

    [getDashboardData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getDashboardData.pending]: (state) => {
      state.isFetching = true;
    },
  },
});



export const { clearState } = expenseSlice.actions;

export const expenseSliceFunction = (state) => state.expense;
