import React from 'react'
import './MentorFeedbackStyle.css'
const MentorFeedback = () => {
    return (
        <div className="mentorFeedbackContainerOuter">
            <div className="mentorFeedbackCard">
                <div className="mentorFeedbackCardDetails" >
                    <div className="heading">Mentor Feedback</div>
                    <div className="form-group">
                        <p>Do you think the candidate will enroll in the programme?</p>
                        <div className="radio-group mt-10">
                            <label className="create-label">
                                <input
                                    className="radio-btn"
                                    type="radio"
                                    name="scholarship"
                                />
                                <span className="radiomark"></span>Yes
                            </label>
                            <label className="create-label">
                                <input
                                    className="radio-btn"
                                    type="radio"
                                    name="scholarship"
                                />
                                <span className="radiomark"></span>No
                            </label>
                            <label className="create-label">
                                <input
                                    className="radio-btn"
                                    type="radio"
                                    name="scholarship"
                                />
                                <span className="radiomark"></span>Maybe
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <p>Any other comments/suggestions</p>
                        <textarea rows={10} cols={5}/>
                    </div>
                    <button className="dark-btn-a w-100 text-center mt-50">Submit</button>
                </div>
                <div className="mentorFeedbackCardDetails" style={{display:'none'}}>
                    <div className="heading">Student Feedback</div>
                    <div className="form-group">
                        <p>How helpful was the mentor connect call?</p>
                        <div className="radio-group mt-10 studentRadio">
                            <label className="create-label">
                                <input
                                    className="radio-btn"
                                    type="radio"
                                    name="scholarship"
                                />
                                <span className="radiomark"></span>Extremely helpful
                            </label>
                            <label className="create-label">
                                <input
                                    className="radio-btn"
                                    type="radio"
                                    name="scholarship"
                                />
                                <span className="radiomark"></span>Somewhat helpful
                            </label>
                            <label className="create-label">
                                <input
                                    className="radio-btn"
                                    type="radio"
                                    name="scholarship"
                                />
                                <span className="radiomark"></span>Now helpful at all
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <p>Are there any other questions that we could help address?</p>
                        <textarea rows={10} cols={5}/>
                    </div>
                    <button className="dark-btn-a w-100 text-center">Submit</button>
                </div>
                <img src="../assets/img/mentorFeedbackImg.png" alt="" />
            </div>
        </div>
    )
}

export default MentorFeedback