import React, { useState } from "react";
import "./AddProgramStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import { COMMON_TEXT } from "../../../constants/textConstant";
import AddClusterPopup from "../../../components/Popups/AddClusterPopup/AddClusterPopup";

function AddProgram() {
  const [showAddPopup, setShowAddPopup] = useState(false);
  const handleAdddPopup = () => {
    setShowAddPopup(!showAddPopup);
  };
  return (
    <>
      <AddClusterPopup show={showAddPopup} handleClose={handleAdddPopup} />
      <SideMenu />
      <div className="main-body">
        <div className="page-header single-heading-header">
          <button className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            {COMMON_TEXT.goBack}
          </button>
          <div>
            <p className="primary-text">Program Details</p>
          </div>
        </div>
        <div className="scrollbar show" id="container-1">
          <div className="create-container program">
            <form action="">
              <div className="form-group mt-20">
                <label htmlFor="name" className="create-label">
                  Program Name <span className="imp-mark">*</span>
                </label>
                <input type="text" className="create-input mt-5" />
                <span className="error-msg">Enter the field</span>
              </div>
              <div className="form-group mt-5">
                <label htmlFor="name" className="create-label">
                  Batch Name <span className="imp-mark">*</span>
                </label>
                <input type="text" className="create-input mt-5" />
                <span className="error-msg">Enter the field</span>
              </div>
              <div className="form-group mt-5 cluster">
                <label htmlFor="name" className="create-label">
                  Cluster <span className="imp-mark">*</span>
                </label>
                <div className="create-input mt-5">
                  {" "}
                  <div className="selected-cluster">
                    Marketing{" "}
                    <img src="../assets/icons/cluster-cross.svg" alt="" />{" "}
                  </div>{" "}
                  <div className="selected-cluster">
                    Marketing{" "}
                    <img src="../assets/icons/cluster-cross.svg" alt="" />{" "}
                  </div>{" "}
                </div>
                <ul className="cluster-dropdown scrollbar active">
                  <li className="cluster-item" onClick={handleAdddPopup}>
                    Create New Cluster
                  </li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                </ul>
                <span className="error-msg">Enter the field</span>
              </div>
              <div className="form-group mt-5 cluster">
                <label htmlFor="name" className="create-label">
                  Type <span className="imp-mark">*</span>
                </label>
                <p type="text" className="create-input mt-5">
                  Select Type
                </p>
                <ul className="cluster-dropdown scrollbar ">
                  <li className="cluster-item">Create New Cluster</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                  <li className="cluster-item">Marketing</li>
                </ul>
                <span className="error-msg">Enter the field</span>
              </div>
              <div className="form-2-col">
                <div className="form-group mt-5 cluster">
                  <label htmlFor="name" className="create-label">
                    Type <span className="imp-mark">*</span>
                  </label>
                  <p type="text" className="create-input mt-5">
                    Select Type
                  </p>
                  <ul className="cluster-dropdown scrollbar ">
                    <li className="cluster-item">Create New Cluster</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                  </ul>
                  <span className="error-msg">Enter the field</span>
                </div>
                <div className="form-group mt-5 cluster">
                  <label htmlFor="name" className="create-label">
                    Type <span className="imp-mark">*</span>
                  </label>
                  <p type="text" className="create-input mt-5">
                    Select Type
                  </p>
                  <ul className="cluster-dropdown scrollbar ">
                    <li className="cluster-item">Create New Cluster</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                    <li className="cluster-item">Marketing</li>
                  </ul>
                  <span className="error-msg">Enter the field</span>
                </div>
              </div>
            </form>
            <div className="footer">
              <button className="primary-btn">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProgram;
