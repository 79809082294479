import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateBankResponse } from "../../../reducers/feeSlice";

import "../LoanPopup/LoanPopupStyle.css";
import "./LoanApprovePopupStyle.css";
import ErrorToast from "../../Toast/ErrorToast";

const LoanApprovePopup = ({
  show,
  handleClose,
  feeDueData,
  studentId,
  programName,
  programId,
}) => {
  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const dispatch = useDispatch();
  const [bankName, setBankName] = useState("");
  const [loanAmt, setLoanAmt] = useState("");
  const [subventionAmt, setSubventionAmt] = useState("");
  const [transactionNo, setTransactionNo] = useState("");

  const onSendLoan = (status) => {
    console.log(bankName);
    console.log(loanAmt);
    if (
      bankName === "" ||
      loanAmt === "" ||
      transactionNo === "" ||
      subventionAmt === ""
    ) {
      // toast.error("Fill all the required fields!");
      setIsShowError(true);
      setErrMessage("Fill all the required fields!");
      return;
    }
    dispatch(
      updateBankResponse({
        bankName,
        loanAmt,
        subventionAmt,
        feeDueId: feeDueData.id,
        studentId,
        status,
        transactionNumber: transactionNo,
        programName,
        programId,
      })
    );
  };

  return (
    <>
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className={`loan_popup popup-outer ${show ? "show-popup" : ""}`}>
        <div className="popup-container loan-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Approve Loan
              <img
                onClick={() => {
                  handleClose();
                  setBankName("State Bank of India");
                  setLoanAmt("");
                  setSubventionAmt("");
                  setTransactionNo("");
                }}
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
              />
            </div>
          </div>
          <div className="popup-body loan-popup-body ">
            <div className="loan-step-content scrollbar">
              {/* {(feeDueData?.LoanApproves[0]?.isDocumentStatus === 'Approved' && feeDueData?.LoanApproves[0]?.isRequestResponseStatus === 'Approved') && (
                <div className="border-box">
                  <div className="box-header">
                    <div className="box-left">
                      <p className="heading">Request for Loan</p>
                      <p className="text">
                        Send request for Loan to Loan partners
                      </p>
                    </div>
                    <div className="box-right">
                      {!feeDueData?.LoanApproves[0]?.isLoanApprovalResponse ? (<button className="green-btn"
                        onClick={() => dispatch(applyForLoan(feeDueData.id))}>
                        Apply for Loan
                      </button>)
                        : (<div className="approved-tag">Request Sent</div>)}
                    </div>
                  </div>
                </div>)} */}
              {
                <div className="border-box">
                  <div className="box-header">
                    <div className="box-left">
                      <p className="heading">Bank response</p>
                      <p className="text">Show Bank response to students</p>
                    </div>
                    <div className="box-right">
                      {feeDueData?.LoanApproves[0]?.isLoanResponseStatus ===
                        "Approved" && (
                        <div className="approved-tag">Approved</div>
                      )}
                      {feeDueData?.LoanApproves[0]?.isLoanResponseStatus ===
                        "Rejected" && (
                        <div className="pending-tag">Rejected</div>
                      )}
                      {/* <select name="status" id="status">
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                    </select> */}
                    </div>
                  </div>
                  <div className="loan-options">
                    <div className="form-grp mt-10">
                      <label htmlFor="partner">Bank partners</label>
                      <select
                        disabled={
                          feeDueData?.LoanApproves[0]?.isLoanResponseStatus ===
                          "Approved"
                        }
                        name="partner"
                        id="partner"
                        value={
                          feeDueData?.LoanApproves[0]?.bankPartner ?? bankName
                        }
                        onChange={(e) => setBankName(e.target.value)}
                      >
                        <option value="Eduvanz">Eduvanz</option>
                        <option value="Jodo">Jodo</option>
                        <option value="PropellD">PropellD</option>
                        <option value="Liquiloans">Liquiloans</option>
                        <option value="GrayQuest">GrayQuest</option>
                        <option value="IDFC First">IDFC First</option>
                        <option value="HDFC Credila">HDFC Credila</option>
                      </select>
                    </div>
                    <div className="form-grp mt-10">
                      <label htmlFor="amount">Enter Loan amount</label>
                      <input
                        type="number"
                        name="amount"
                        id="amount"
                        placeholder="Enter amount"
                        className="create-input"
                        value={
                          feeDueData?.LoanApproves[0]
                            ?.loanAmountApprovedByBank ?? loanAmt
                        }
                        onChange={(e) => setLoanAmt(e.target.value)}
                        disabled={
                          feeDueData?.LoanApproves[0]?.isLoanResponseStatus ===
                          "Approved"
                        }
                      />
                    </div>
                    <div className="form-grp mt-10">
                      <label htmlFor="amount">Enter Subvention amount</label>
                      <input
                        type="number"
                        name="Subvention"
                        id="Subvention"
                        placeholder="Enter Subvention Amount"
                        className="create-input"
                        // value={
                        //   feeDueData?.LoanApproves[0]
                        //     ?.loanAmountApprovedByBank ?? loanAmt
                        // }
                        onChange={(e) => setSubventionAmt(e.target.value)}
                        // disabled={
                        //   feeDueData?.LoanApproves[0]?.isLoanResponseStatus ===
                        //   "Approved"
                        // }
                      />
                    </div>
                    <div className="form-grp mt-10">
                      <label htmlFor="amount">Enter Transaction Number</label>
                      <input
                        type="number"
                        name="transaction"
                        id="transaction"
                        placeholder="Enter Trasaction Number"
                        className="create-input"
                        //   value={feeDueData?.LoanApproves[0]?.loanAmountApprovedByBank ?? loanAmt}
                        onChange={(e) => setTransactionNo(e.target.value)}
                        //   disabled={feeDueData?.LoanApproves[0]?.isLoanResponseStatus === "Approved" }
                      />
                    </div>
                  </div>
                  <div className="btn-grp">
                    {/* {feeDueData?.LoanApproves[0]?.isLoanResponseStatus === "Pending" && (<button className="green-btn-a" onClick={onSendLoan}>Accept</button>)}
                  {feeDueData?.LoanApproves[0]?.isLoanResponseStatus === "Pending" && (<button className="green-btn-a" onClick={onSendLoan}>Reject</button>)} */}

                    {feeDueData?.LoanApproves[0]?.isLoanResponseStatus ===
                      "Pending" && (
                      <button
                        className="accept-btn"
                        onClick={() => onSendLoan("Approved")}
                      >
                        <img src="../../assets/icons/whiteTick.svg" alt="" />
                        Approve
                      </button>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
          {/* <div className="popup-footer">
            <button className="green-btn">Save</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LoanApprovePopup;
