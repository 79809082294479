import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../../components/Layout/menu/SideMenu";
import {
  dashboardData,
  getDashboardDetailData,
} from "../../reducers/dashboardSlice";
import "./DashboardStyle.css";
import PageLoader from "../../components/UI/loader/PageLoader";

const Dashboard = () => {
  const dispatch = useDispatch();
  // const [feeAmount, setFeeAmount] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { isFetching } = useSelector(dashboardData);

  useEffect(() => {
    dispatch(getDashboardDetailData());
  }, []);

  // useEffect(() => {
  //   setFeeAmount(dashboardDetails?.feeAmount?.[0]);
  // }, [dashboardDetails]);

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);
  // console.log("checking state inside dashboard", state);

  return (
    <>
      <SideMenu />
      {isLoading && <PageLoader />}
      {/* <div className="main-body">
        <div className="page-header">
          <p className="component-heading">Dashboard</p>
        </div>
        <div className="dashboard-container">
          <div className="dashboard-items">
            <div className="dashboard-item">
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/dashboard-icon-1.svg"
                alt=""
              />
              <div className="dashboard-content">
                <p className="number">{dashboardDetails?.studentCount}</p>
                <p className="type">Total No. of Students</p>
              </div>
            </div>
            <div className="dashboard-item">
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/dashboard-icon-2.svg"
                alt=""
              />
              <div className="dashboard-content">
                <p className="number">{dashboardDetails?.userCount}</p>
                <p className="type">Total No. of Users</p>
              </div>
            </div>
            <div className="dashboard-item">
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/dashboard-icon-3.svg"
                alt=""
              />
              <div className="dashboard-content">
                <p className="number">987</p>
                <p className="type">Total No. of Students</p>
              </div>

              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/dashboard-icon-5.svg"
                alt=""
              />
              <div className="dashboard-content">
                <p className="number">
                  {feeAmount?.totalAmount?.toFixed(2) ?? 0}
                </p>
                <p className="type">Total Fee</p>
              </div>
            </div>
            <div className="dashboard-item">
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/dashboard-icon-6.svg"
                alt=""
              />
              <div className="dashboard-content">
                <p className="number">
                  {feeAmount?.submittedAmount?.toFixed(2) ?? 0}
                </p>
                <p className="type">Total Submitted Fee</p>
              </div>
            </div>
          </div>
          <p className="heading">Total Amount</p>
          <div className="dashboard-items mt-20">
            <div className="dashboard-item fee">
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/dashboard-icon-5.svg"
                alt=""
              />
              <div className="dashboard-content">
                <p className="number">
                  {feeAmount?.totalAmount?.toFixed(2) ?? 0}
                </p>
                <p className="type">Total Fee</p>
              </div>
            </div>
            <div className="dashboard-item fee">
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/dashboard-icon-6.svg"
                alt=""
              />
              <div className="dashboard-content">
                <p className="number">
                
                  {feeAmount?.submittedAmount?.toFixed(2) ?? 0}
                </p>
                <p className="type">Total Submitted Fee</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="main-body scrollbar">
        <div className="page-header">
          <p className="component-heading">Dashboard</p>
        </div>
        <div className="dashboard_wrapper scrollbar">
          <div className="dashboard_wrapper_top">
            <div className="dashboard_section">
              <img src="../../../assets/img/dashboard_user.png" alt="" />
              <div className="dashboard_text_right">
                <h4 className="heading-3">50</h4>
                <p className="text-3 mt10">Total No. of Users</p>
              </div>
            </div>

            <div className="dashboard_section">
              <img src="../../../assets/img/dashboard_files.png" alt="" />
              <div className="dashboard_text_right">
                <h4 className="heading-3">14</h4>
                <p className="text-3 mt10">Total No. of Programs</p>
              </div>
            </div>

            <div className="dashboard_section">
              <img src="../../../assets/img/dashboard_scholarship.png" alt="" />
              <div className="dashboard_text_right">
                <h4 className="heading-3">12</h4>
                <p className="text-3 mt10">No. of Scholarships</p>
              </div>
            </div>
          </div>

          <div className="dashboard_wrapper_bottom">
            <div className="dashboard_details">
              <div className="dashboard_details_top">
                <div className="dashboard_details_top_left">
                  <h2 className="heading-2">06</h2>
                  <h4 className="text-4">Leave Request</h4>
                </div>
                <div className="dashboard_details_top_right">
                  <img
                    src="../../../assets/img/dashboard_calender.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="dashboard_details_bottom">
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Approved</h5>
                  <h6 className="text-10 mt10">04</h6>
                </div>
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Pending</h5>
                  <h6 className="text-10 mt10">02</h6>
                </div>
              </div>
              <span className="bottom_line green-bg"></span>
            </div>

            <div className="dashboard_details">
              <div className="dashboard_details_top">
                <div className="dashboard_details_top_left">
                  <h2 className="heading-2">60</h2>
                  <h4 className="text-4">Total Masters</h4>
                </div>
                <div className="dashboard_details_top_right">
                  <img src="../../../assets/img/dashboard_master.png" alt="" />
                </div>
              </div>
              <div className="dashboard_details_bottom">
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">In House</h5>
                  <h6 className="text-10 mt10">40</h6>
                </div>
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Practising</h5>
                  <h6 className="text-10 mt10">20</h6>
                </div>
              </div>
              <span className="bottom_line yellow-bg"></span>
            </div>

            <div className="dashboard_details">
              <div className="dashboard_details_top">
                <div className="dashboard_details_top_left">
                  <h2 className="heading-2">10</h2>
                  <h4 className="text-4">Investory</h4>
                </div>
                <div className="dashboard_details_top_right">
                  <img
                    src="../../../assets/img/dashboard_investory.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="dashboard_details_bottom">
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Alocated</h5>
                  <h6 className="text-10 mt10">06</h6>
                </div>
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Available</h5>
                  <h6 className="text-10 mt10">04</h6>
                </div>
              </div>
              <span className="bottom_line blue-bg"></span>
            </div>

            <div className="dashboard_details">
              <div className="dashboard_details_top">
                <div className="dashboard_details_top_left">
                  <h2 className="heading-2">06</h2>
                  <h4 className="text-4">Club Request</h4>
                </div>
                <div className="dashboard_details_top_right">
                  <img src="../../../assets/img/dashboard_reqest.png" alt="" />
                </div>
              </div>
              <div className="dashboard_details_bottom">
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Approved</h5>
                  <h6 className="text-10 mt10">04</h6>
                </div>
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Pending</h5>
                  <h6 className="text-10 mt10">02</h6>
                </div>
              </div>
              <span className="bottom_line red-bg"></span>
            </div>

            <div className="dashboard_details">
              <div className="dashboard_details_top">
                <div className="dashboard_details_top_left">
                  <h2 className="heading-2">10</h2>
                  <h4 className="text-4">Tickets Raised</h4>
                </div>
                <div className="dashboard_details_top_right">
                  <img src="../../../assets/img/dashboard_ticket.png" alt="" />
                </div>
              </div>
              <div className="dashboard_details_bottom">
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Resolved</h5>
                  <h6 className="text-10 mt10">06</h6>
                </div>
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Pending</h5>
                  <h6 className="text-10 mt10">04</h6>
                </div>
              </div>
              <span className="bottom_line blue-bg"></span>
            </div>

            <div className="dashboard_details">
              <div className="dashboard_details_top">
                <div className="dashboard_details_top_left">
                  <h2 className="heading-2">06</h2>
                  <h4 className="text-4">Leave Request</h4>
                </div>
                <div className="dashboard_details_top_right">
                  <img
                    src="../../../assets/img/dashboard_attendance.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="dashboard_details_bottom">
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Approved</h5>
                  <h6 className="text-10 mt10">04</h6>
                </div>
                <div className="dashboard_details_bottom_data">
                  <h5 className="text-9">Pending</h5>
                  <h6 className="text-10 mt10">02</h6>
                </div>
              </div>
              <span className="bottom_line black-bg"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
