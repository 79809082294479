import React from 'react'

const SomethingWentWrong = () => {
  return <>
   <div className="flexCol">
   <img src="../assets/icons/somethingWentWrong.svg" alt="" />
    <h2 className="heading mt-40 mb-10">Something Went Wrong</h2>
    <p className="text">Try refreshing the page, or try again later.</p>
    <button className="primary-btn mt-20">Refresh Page</button>
   </div>
  </>
}

export default SomethingWentWrong