import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import "./FeedbackListStyle.css";
import $ from "jquery";
import Reportpopup from "../../../components/Popups/ReportPopup/Reportpopup";
import UserListPopup from "../../../components/Popups/UserListPopup/UserListPopup";
import DeletePopup from "../../../components/Popups/DeletePopup/DeletePopup";
import MailPopup from "../../../components/Popups/MailPopup/MailPopup";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteFeedback,
  feedbackData,
  getAllFeedbacks,
  getAttemptedStudentCSV,
  getEmailTemplate,
  getFeedbackAttemptedStudents,
  setInitialState,
} from "../../../reducers/feedbackSlice";
import PageLoader from "../../../components/UI/loader/PageLoader";
import moment from "moment";
import BlankScreen from "../../../components/BlankComponent/BlankScreen";
import ExtendDatePopup from "../../../components/Popups/ExtendDatePopup/ExtendDatePopup";
import {
  EMAIL_TEMPLATE_NAME,
  EMAIL_TYPE,
} from "../../../constants/textConstant";
export default function FeedbackList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    feedbackList,
    feedbackCount,
    attemptedStudentList,
    csvAttemptedStudent,
    emailTemplateData,
  } = useSelector(feedbackData);

  const [isLoading, setIsLoading] = useState(false);
  const [menuId, setMenuId] = useState(null);
  const [isAttemptedOpen, setIsAttemptedOpen] = useState(false);
  const [isCollaboratorOpen, setIsCollaboratorOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [feedbackId, setFeedbackId] = useState(null);
  const [isExtendDateOpen, setIsExtendDateOpen] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [searchFeedback, setSearchFeedback] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [statusType, setStatusType] = useState("");
  const [particularFeedbackData, setDataFeedback] = useState({});

  //pagination
  const [pageNo, setPageNo] = useState("1");
  const [pageSize, setPageSize] = useState("10");

  const setPreviousPage = () => {
    if (pageNo == "1") {
      return;
    }
    setPageNo((prev) => --prev);
  };
  const setNextPage = () => {
    if (pageNo == Math.ceil(feedbackCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
  };
  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    var params = {
      searchTitle: searchFeedback,
      searchParams: feedbackType,
      status: statusType,
      pageNo: pageNo,
      pageSize: pageSize,
    };
    dispatch(getAllFeedbacks(params));
  }, [feedbackType, statusType, pageNo, pageSize]);

  useEffect(() => {
    try {
      dispatch(
        getEmailTemplate({
          templateName: EMAIL_TEMPLATE_NAME.emailTemplateForSendReminder,
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const generateToast = () => {
    $(".success-toast").css({ visibility: "visible", opacity: "1" });
    setTimeout(() => {
      $(".success-toast").css({ visibility: "hidden", opacity: "0" });
    }, 2000);
  };

  const toggelAttemptedPopup = () => {
    setIsAttemptedOpen(!isAttemptedOpen);
  };
  const onExtendClick = (id, date) => {
    setFeedbackId(id);
    setExpiryDate(date);
    toggleExtendDatePopup();
  };
  const onClickAttemptedStudentCount = (id, data) => {
    try {
      if (data !== null) {
        setDataFeedback(data);
      }
      dispatch(getFeedbackAttemptedStudents(id));
      dispatch(getAttemptedStudentCSV(id));

      if (isSuccess) {
        toggelAttemptedPopup();
      }
    } catch (error) {
      console.log("error...", error);
    }
  };
  const toggleCollaboratorPopup = () => {
    setIsCollaboratorOpen(!isCollaboratorOpen);
  };
  const onDeleteClick = (feedbackId) => {
    toggleDeletePopup();
    setFeedbackId(feedbackId);
  };
  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };
  const toggleExtendDatePopup = () => {
    setIsExtendDateOpen(!isExtendDateOpen);
  };

  const toggleMailPopup = () => {
    setIsMailOpen(!isMailOpen);
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     dispatch(setInitialState());
  //   }
  // }, [isSuccess]);

  const onAddFeedbackClick = () => {
    navigate(`/createFeedback/018-42c1a8c4-c2eaf774e0-a125f3845`);
  };
  const onDeleteFeedbackClick = (feedbackId) => {
    dispatch(deleteFeedback({ feedId: feedbackId }));
    setIsDeleteOpen(false);
  };
  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
      console.log("inside equal condition");
    } else {
      setMenuId(id);
      console.log("inside other condition");
    }
  };

  const convertPublishOnDate = (date) => {
    var publishDate = moment(date).format("ll");
    return publishDate;
  };

  const closeActionMenu = () => {
    setMenuId(null);
  };

  const onClickEditFeedback = (id) => {
    navigate(`/createFeedback/${id}`);
  };

  const searchByTitle = (val) => {
    try {
      var params = {
        searchTitle: val,
        searchParams: feedbackType,
        status: statusType,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      dispatch(getAllFeedbacks(params));
    } catch (error) {
      console.log("error", error);
    }
  };

  const onClickSearch = () => {
    searchByTitle(searchFeedback);
  };

  const handleSearch = (event) => {
    var search = event.target.value;
    setSearchFeedback(search);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchByTitle(e.target.value);
    }
  };

  const handleFeedbackType = (event) => {
    var type = event.target.value;
    setFeedbackType(type);
  };

  const handleStatusChange = (event) => {
    var statusType = event.target.value;
    setStatusType(statusType);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePreviewClick = (feedbackId) => {
    navigate(`/feedbackPreview?id=${feedbackId}`);
  };

  const onClickSendReminder = (id) => {
    setFeedbackId(id);
    toggleMailPopup();
  };

  return (
    <React.Fragment>
      {isLoading && <PageLoader />}
      <SideMenu />
      <ExtendDatePopup
        handleClose={toggleExtendDatePopup}
        feedbackId={feedbackId}
        show={isExtendDateOpen}
        expiryDate={expiryDate}
      />
      <Reportpopup
        studentList={attemptedStudentList}
        csvStudent={csvAttemptedStudent}
        handleClose={toggelAttemptedPopup}
        show={isAttemptedOpen}
        data={particularFeedbackData}
      />
      <UserListPopup
        handleClose={toggleCollaboratorPopup}
        show={isCollaboratorOpen}
      />
      <DeletePopup
        handleDelete={onDeleteFeedbackClick}
        handleClose={toggleDeletePopup}
        feedbackId={feedbackId}
        show={isDeleteOpen}
      />
      <MailPopup
        emailType={EMAIL_TYPE.reminderFeedbackEmail}
        emailTemplateData={emailTemplateData}
        feedbackId={feedbackId}
        handleClose={toggleMailPopup}
        show={isMailOpen}
      />

      <div
        //  onClick={closeActionMenuView}
        className="main-body"
      >
        <div className="page-header">
          <p className="component-heading">List of Feedbacks</p>
          <button onClick={onAddFeedbackClick} className="primary-btn feed-btn">
            <img src="../assets/icons/plus.svg" alt="" /> Add Feedback
          </button>
        </div>

        {/* <BlankScreen /> */}

        <div className="filter-bar">
          <form action="" className="search-bar">
            <img
              onClick={onClickSearch}
              src="../assets/icons/search.svg"
              alt=""
            />
            <input
              type="text"
              name=""
              id=""
              onChange={handleSearch}
              onKeyDown={onSearchKeyPress}
              placeholder="Search"
              className="clean-input"
            />
          </form>
          <div className="filters">
            <select id="type" onChange={handleFeedbackType}>
              <option value="">All Feedback</option>
              <option value="Class">Class</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
            <select id="type" onChange={handleStatusChange}>
              <option value="">All Status</option>
              <option value="expire">Expired</option>
              <option value="draft">Draft</option>
              <option value="active">Active</option>
            </select>
            <select id="type" onChange={handlePageSizeChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>

        <div className="feedback-container scrollbar">
          <table
            // onClick={closeActionMenu}
            className="feedback-table table"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr className="p-20">
                <th className="text-left">Feedback Title</th>
                <th className="text-left">Published On </th>
                <th>Feedback type</th>
                <th>Attempted</th>
                <th>Created by</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {feedbackList.length > 0 &&
                feedbackList.map((item, index) => (
                  <tr key={index} className="p-20">
                    <td className="text-left">
                      {item.title}{" "}
                      {item.isAnonymous && (
                        <div className="tooltip">
                          <img
                            src="../assets/icons/info-red.svg"
                            className="tooltip"
                          />
                          <span className="tooltiptext">Anonymous</span>
                        </div>
                      )}
                    </td>
                    <td className="text-left">
                      {item.publishedOn === null
                        ? "-- -- ----"
                        : convertPublishOnDate(item.publishedOn)}
                    </td>
                    <td>{item.feedbackType}</td>
                    <td>
                      {item.totalCount === 0 ? (
                        "0 Student"
                      ) : (
                        <p
                          className="primary-link"
                          onClick={() =>
                            onClickAttemptedStudentCount(item.id, item)
                          }
                        >
                          {item.totalCount === 1
                            ? item.totalCount + " Student"
                            : item.totalCount + " Students"}
                        </p>
                      )}
                    </td>
                    <td>Admin</td>
                    <td>
                      {item.status === "draft" && (
                        <p className="tag-draft">Draft</p>
                      )}
                      {item.status === "expire" && (
                        <p className="tag-expired">Expired</p>
                      )}

                      {item.status === "active" && (
                        <p className="tag-active">Active</p>
                      )}
                    </td>
                    <td className="text-center">
                      <button
                        onClick={() => openActionMenu(index)}
                        onBlur={closeActionMenu}
                        className="action-btn"
                      >
                        <img src="../assets/icons/options.svg" />
                        <ul
                          className={
                            menuId === index
                              ? "action-dropdown active"
                              : "action-dropdown"
                          }
                          key={index}
                          // id={`action-dropdown-` + index}
                        >
                          {item.isPublished === false && (
                            <li
                              onClick={() => onClickEditFeedback(item.id)}
                              className="dropdown-item"
                            >
                              <img src="../assets/icons/edit.svg" alt="" />
                              <p className="dropdown-text">Edit</p>
                            </li>
                          )}

                          <li
                            className="dropdown-item"
                            onClick={() => handlePreviewClick(item.id)}
                          >
                            <img src="../assets/icons/preview.svg" alt="" />
                            <p className="dropdown-text">Preview</p>
                          </li>

                          {item.isPublished && (
                            <>
                              <li
                                onClick={() =>
                                  onExtendClick(item.id, item.expiryDate)
                                }
                                className="dropdown-item"
                              >
                                <img
                                  src="../assets/icons/calenderX.svg"
                                  alt=""
                                />
                                <p className="dropdown-text">Extend Date</p>
                              </li>
                              <li
                                onClick={() => onClickSendReminder(item.id)}
                                className="dropdown-item"
                              >
                                <img src="../assets/icons/reload.svg" alt="" />
                                <p className="dropdown-text">Send Reminder</p>
                              </li>
                            </>
                          )}

                          <li className="dropdown-item" onClick={generateToast}>
                            <img src="../assets/icons/copy.svg" alt="" />
                            <p className="dropdown-text">Copy Link</p>
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={toggleCollaboratorPopup}
                          >
                            <img src="../assets/icons/users.svg" alt="" />
                            <p className="dropdown-text">Add collaborator</p>
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => onDeleteClick(item.id)}
                          >
                            <img src="../assets/icons/delete.svg" alt="" />
                            <p className="dropdown-text text-danger">Delete</p>
                          </li>
                        </ul>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className=" pg-arrows-outer ">
          <p className="pg-arraows-p">
            {pageNo} of{" "}
            {feedbackCount <= pageSize
              ? "1"
              : Math.ceil(feedbackCount / pageSize)}
          </p>
          <div className="pg-arrows">
            <a onClick={setPreviousPage}>
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                className="pg-left"
              />
            </a>
            <a onClick={setNextPage}>
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                className="pg-right"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="success-toast">
        <img src="../assets/icons/check-white.svg" alt="" />
        <p>Link Copied to Clipboard</p>
      </div>
    </React.Fragment>
  );
}
