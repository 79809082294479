import React from "react";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import "./CreateClubStyle.css";
import $ from "jquery";

import { COMMON_TEXT } from "../../../constants/textConstant";

const CreateClub = () =>{

    const changeContainer = (show, hide1, hide2) => {
        $(`#btn-${show}`).addClass("active");
        $(`#btn-${hide1}`).removeClass("active");
        $(`#btn-${hide2}`).removeClass("active");
    
        $(`#clubcontainer-${show}`).addClass("show");
        $(`#clubcontainer-${hide1}`).removeClass("show");
        $(`#clubcontainer-${hide2}`).removeClass("show");
    };

    const hiddenFileInput = React.useRef(null);
    const hiddenFileInput2 = React.useRef(null);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleClick2 = (event) => {
        hiddenFileInput2.current.click();
    };
    
    return(
        <React.Fragment>
            <SideMenu/>

            <div className="main-body">

                <div className="page-header create-header">
                    <button className="primary-clean active" id="btn-1" onClick={() => changeContainer(1, 2, 3)}>
                        Club details
                    </button>
                    <button className="primary-clean" id="btn-2" onClick={() => changeContainer(2, 1, 3)} >
                        Upload Documents
                    </button>
                    <button className="primary-clean" id="btn-3" onClick={() => changeContainer(3, 2, 1)} >
                        Club memeber
                    </button>

                    <button className="save-button">
                        <img src="../assets/icons/save.svg" alt=""/>
                            Save
                    </button>

                </div>

                <div className="scrollbar show" id="clubcontainer-1" style={{ display: "none" }}>

                    <div className="create-club-container">

                        <div className="club_detail_wrapper">
                            <div className="photo_section">
                                <input type="file" id="profile_input"/>
                                <input type="file" id="bg_profile_input"/>
                                <div className="profile_photo">
                                    <img src="../../assets/icons/demo_profile.svg" alt="" />

                                    <label htmlFor="profile_input">
                                        <img className="add_image" src="../../assets/icons/profile_camera.svg" alt="" />
                                    </label>
                                </div>

                                <div className="cover_photo text-2">
                                    <label htmlFor="bg_profile_input">
                                        <img src="../../assets/icons/profile_camera.svg" alt="" />
                                    Upload Cover Photo</label>
                                </div>
                            </div>

                            <form>

                                <div className="form-group mt-35">
                                    <label htmlFor="title" className="create-label">Club Name</label>
                                    <input type="text" name="" id="" className="create-input mt-5" placeholder="Create a Club" value="" />        
                                </div>

                                <div className="form-group mt-30">
                                    <label htmlFor="title" className="create-label">Mission of your Club</label>
                                    <input type="text" name="" id="" className="create-input mt-5" placeholder="Description of Club" value="" />        
                                </div>

                                <div className="form-group mt-30">
                                    <label htmlFor="title" className="create-label">Vision of your Club</label>
                                    <input type="text" name="" id="" className="create-input mt-5" placeholder="Vision of your Club" value="" />        
                                </div>

                                <div className="form-group mt-30">
                                    <label htmlFor="title" className="create-label">Why do you want to form the club?</label>
                                    <textarea type="textarea" name="" id="" className="create-input mt-5" placeholder="Want to form the club" rows="4" cols="5" ></textarea>
                                </div>

                                <div className="form-group mt-30">
                                    <label htmlFor="title" className="create-label">Club Type</label>
                                    <select id="type" className="popup-dropdown mt-5">
                                        <option value="hide">Select Program</option>
                                        <option value="1">Music Club</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                    </select>
                                </div>

                                <div className="form-footer mt-30">
                                    <button className="primary-btn">Next</button>
                                </div>

                            </form>

                        </div>

                    </div>

                </div>

                <div className=" scrollbar" id="clubcontainer-2" style={{ display: "none" }}>
                    
                    <div className="create-club-container">

                        <div className="club_detail_wrapper">

                            <form>

                                <div className="form-group mt-20">
                                    <p className="create-label">Club Guiding Document <span className="imp-mark">*</span></p> 
                                    <input type="file" name="" id="custom_upload_button" className="" value="" ref={hiddenFileInput}/>
                                    <div className="custom_upload_box mt-5 active">
                                        <button type="button" onClick={handleClick}>Choose File</button>KPMG-File.pdf
                                        <img src="../../assets/icons/delete_circle.svg" className="deleted_icon" alt="" />
                                    </div>
                                </div>

                                <div className="form-group mt-30">
                                    <p htmlFor="title" className="create-label">Club Report<span className="imp-mark">*</span></p>

                                    <div className="club_report_box mt-5">
                                    <input type="file" name="" id="custom_upload_button" className="" value="" ref={hiddenFileInput2}/>
                                    <div className="custom_upload_box">
                                        <button type="button" onClick={handleClick2}>Choose File</button>No File chosen
                                        <img src="../../assets/icons/delete_circle.svg" className="deleted_icon club_report" alt="" />
                                    </div>

                                        <div className="club_report_box_list mt-10">
                                            <table className="club_report_table pop-up-table">

                                                <thead>
                                                    <tr>
                                                        <th>Club Report</th>
                                                        <th>Size</th>
                                                        <th>Date</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td className="table-highlight">Programme in Finance.pdf</td>
                                                        <td>140 KB</td>
                                                        <td>5 july 2020</td>
                                                        <td><button className="danger-btn-outline">Remove</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-highlight">Exploring Shar..</td>
                                                        <td>150 KB</td>
                                                        <td>5 july 2020</td>
                                                        <td><button className="danger-btn-outline">Remove</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-highlight">Alumni, Episode</td>
                                                        <td>1.00 MB</td>
                                                        <td>5 july 2020</td>
                                                        <td><button className="danger-btn-outline">Remove</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-highlight">CXO Lesson</td>
                                                        <td>215 KB</td>
                                                        <td>5 july 2020</td>
                                                        <td><button className="danger-btn-outline">Remove</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-highlight">Learn Website</td>
                                                        <td>140 KB</td>
                                                        <td>5 july 2020</td>
                                                        <td><button className="danger-btn-outline">Remove</button></td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>    
                                    </div>

                                    
                                </div>

                                <div className="form-footer mt-30 multibuttons">
                                    <button className="secondary-btn">Back</button>
                                    <button className="primary-btn">Next</button>
                                </div>

                            </form>

                        </div>

                    </div>

                </div>

                <div className="scrollbar" id="clubcontainer-3" style={{ display: "none" }}>

                            <div className="create-club-container club_member_wrapper">
                                <div className="filter-bar">
                                    <form action="" className="search-bar">
                                        <img src="../assets/icons/search.svg" alt="" />
                                        <input type="text" name="" id="" placeholder="Search" className="clean-input" />
                                    </form>
                                    <div className="filters">
                                        <select id="type">
                                            <option value="hide">Feedback Type</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                        <select id="type">
                                            <option value="hide">Status</option>
                                            <option value="1">Expired</option>
                                            <option value="2">Draft</option>
                                            <option value="3">Active</option>
                                        </select>
                                        <select id="type">
                                            <option value="hide">1-50</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="club_member_shell">
                                    <table className="feedback-table" cellPadding={0} cellSpacing={0}>
                                            <thead>
                                                <tr>
                                                <th className="check-row">
                                                    <label className="checkbox-label">
                                                    <input
                                                        type="checkbox"
                                                        name=""
                                                        id=""
                                                        className="checkbox-btn"
                                                    />
                                                    <span className="checkmark" />
                                                    </label>
                                                    <img
                                                    src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                                                    className="ml-5"
                                                    />
                                                </th>
                                                <th style={{ paddingLeft: "0 !important" }}>Student</th>
                                                <th>Programme</th>
                                                <th>Batch</th>
                                                <th>President</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="club-check-box">
                                                        <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn"/>
                                                        <span className="checkmarkspan" />
                                                        </label>
                                                    </td>
                                                    <td>Anand Krishnan</td>
                                                    <td>Programming in Finance and Accounting</td>
                                                    <td>2018-2022</td>
                                                    <td><button className="green-btn-outline">President</button></td>
                                                </tr>

                                                <tr>
                                                    <td className="club-check-box">
                                                        <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn"/>
                                                        <span className="checkmarkspan" />
                                                        </label>
                                                    </td>
                                                    <td>Anand Krishnan</td>
                                                    <td>Programming in Finance and Accounting</td>
                                                    <td>2018-2022</td>
                                                    <td><button className="green-btn-outline">President</button></td>
                                                </tr>

                                                <tr>
                                                    <td className="club-check-box">
                                                        <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn"/>
                                                        <span className="checkmarkspan" />
                                                        </label>
                                                    </td>
                                                    <td>Anand Krishnan</td>
                                                    <td>Programming in Finance and Accounting</td>
                                                    <td>2018-2022</td>
                                                    <td><button className="green-btn-outline">President</button></td>
                                                </tr>

                                                <tr>
                                                    <td className="club-check-box">
                                                        <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn"/>
                                                        <span className="checkmarkspan" />
                                                        </label>
                                                    </td>
                                                    <td>Anand Krishnan</td>
                                                    <td>Programming in Finance and Accounting</td>
                                                    <td>2018-2022</td>
                                                    <td><button className="green-btn-outline">President</button></td>
                                                </tr>

                                                <tr>
                                                    <td className="club-check-box">
                                                        <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn"/>
                                                        <span className="checkmarkspan" />
                                                        </label>
                                                    </td>
                                                    <td>Anand Krishnan</td>
                                                    <td>Programming in Finance and Accounting</td>
                                                    <td>2018-2022</td>
                                                    <td><button className="green-btn-outline">President</button></td>
                                                </tr>

                                                <tr>
                                                    <td className="club-check-box">
                                                        <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn"/>
                                                        <span className="checkmarkspan" />
                                                        </label>
                                                    </td>
                                                    <td>Anand Krishnan</td>
                                                    <td>Programming in Finance and Accounting</td>
                                                    <td>2018-2022</td>
                                                    <td><button className="green-btn-outline">President</button></td>
                                                </tr>

                                                <tr>
                                                    <td className="club-check-box">
                                                        <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn"/>
                                                        <span className="checkmarkspan" />
                                                        </label>
                                                    </td>
                                                    <td>Anand Krishnan</td>
                                                    <td>Programming in Finance and Accounting</td>
                                                    <td>2018-2022</td>
                                                    <td><button className="green-btn-outline">President</button></td>
                                                </tr>

                                                <tr>
                                                    <td className="club-check-box">
                                                        <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn"/>
                                                        <span className="checkmarkspan" />
                                                        </label>
                                                    </td>
                                                    <td>Anand Krishnan</td>
                                                    <td>Programming in Finance and Accounting</td>
                                                    <td>2018-2022</td>
                                                    <td><button className="green-btn-outline">President</button></td>
                                                </tr>

                                                <tr>
                                                    <td className="club-check-box">
                                                        <label className="checkbox-label">
                                                        <input type="checkbox" name="" id="" className="checkmark-btn"/>
                                                        <span className="checkmarkspan" />
                                                        </label>
                                                    </td>
                                                    <td>Anand Krishnan</td>
                                                    <td>Programming in Finance and Accounting</td>
                                                    <td>2018-2022</td>
                                                    <td><button className="green-btn-outline">President</button></td>
                                                </tr>
                                            </tbody>
                                    </table>
                                    
                                    <div className="form-footer multibuttons mt-30">
                                        <button className="secondary-btn">Back</button>
                                        <button className="primary-btn">Next</button>
                                    </div>
                                </div>

                            </div>
                </div>

        </div>

        </React.Fragment>
    );
};
 export default CreateClub;