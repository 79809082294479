import React, { useEffect, useState } from "react";
import "./FeeSummaryStyle.css";
import SideMenu from "../../components/Layout/menu/SideMenu";
// import ReactApexChart from "react-apexcharts";
// import FeeSummaryDropdown from "../../components/Dropdowns/FeeSummaryDropdown/FeeSummaryDropdown";
import PageLoader from "../../components/UI/loader/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCounsellorData,
  clearState,
  feeData,
  getAllBatchByProgramId,
  getCounsellorReport,
  getProgramInterviewersSummary,
  getProgramSummaryDetail,
  getProgramSummaryDetailsPart1,
  getProgramSummaryDetailsPart2,
  getProgramSummaryDetailsPart3,
  getStudentRevenueCouncillorWise,
  summaryGetAllProgramList,
} from "../../reducers/feeSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import MentorFeedbackPopup from "../../components/Popups/mentorFeedbackPopup/MentorFeedbackPopup";
import VendorPopup from "../../components/Popups/vendorPopup/VendorPopup";
import UpdateBoxAutomation from "../../components/Popups/updateBoxAutomation/UpdateBoxAutomation";
import CohortStats from "../CohortStats/CohortStats";
import StudentRevenuePopup from "../../components/Popups/StudentRevenuePopup/StudentRevenuePopup";

const FeeSummary = () => {
  // const { programSummaryData } = useSelector(applicantData);

  // const [dropdownActive, setDropdownActive] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    summaryProgramList,
    programSummaryDataPart1,
    programSummaryDataPart2,
    programSummaryDataPart3,
    isFetching,
    batchDetails,
    counsellorReportData,
    programSummaryData,
    programInterviewersSummary,
    cohortStatsData,
    studentCounsellorWise,
  } = useSelector(feeData);
  const [programsList, setProgramList] = useState([]);
  const [searchParams] = useSearchParams();
  const [programId, setProgramId] = useState("");
  const [batchId, setBatchId] = useState("");
  const [renderComponent, setRenderComponent] = useState(1);
  const [tabActive, setTabActive] = useState(1);

  const programBatchId = searchParams.get("programBatchId");
  const programMasterId = searchParams.get("programMasterId");
  const activeTab = searchParams.get("activeTab");
  // console.log(activeTab);

  const [showStudentRevenuePopup, setShowStudentRevenuePopup] = useState(false);
  const [counsellorName, setCounsellorName] = useState(false);

  useEffect(() => {
    dispatch(summaryGetAllProgramList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programBatchId) {
      dispatch(getAllBatchByProgramId(programMasterId));
      setBatchId(programBatchId);
      setProgramId(programMasterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programBatchId]);

  useEffect(() => {
    if (summaryProgramList.length > 0) {
      setProgramList(summaryProgramList);
    }
  }, [summaryProgramList]);

  useEffect(() => {
    dispatch(clearState());
    if (batchId !== "" && batchId !== null && batchId !== undefined) {
      let obj = {
        batchId: batchId,
        programId: programId,
      };

      dispatch(getProgramSummaryDetailsPart1(obj));
      dispatch(getProgramSummaryDetailsPart2(obj));
      dispatch(getProgramSummaryDetailsPart3(obj));
    }

    if (programId !== "" && programId !== null && programId !== undefined)
      dispatch(getAllBatchByProgramId(programId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchId, programId]);

  const onChangeProgram = (e) => {
    setProgramId(e.target.value);
    setBatchId("allCohorts");
  };

  const formatAmount = (amount) => {
    var number = amount == null ? 0 : parseInt(amount);

    var formattedAmount = new Intl.NumberFormat("en-IN", {
      currency: "INR",
    }).format(number);

    return formattedAmount;
  };

  const onClickApplicantCount = () => {
    if (programId !== "allPrograms" && batchId !== "allCohorts") {
      navigate(`/applicantList/${batchId}`);
    }
  };
  const onClickOfferLetter = () => {
    if (programId !== "allPrograms" && batchId !== "allCohorts") {
      navigate(`/offerLetterList/${batchId}?programMasterId=${programId}`);
    }
  };
  const onClickStudent = () => {
    if (programId !== "allPrograms" && batchId !== "allCohorts") {
      navigate(
        `/couseFeeStudentList?programMasterId=${programId}&programBatchId=${batchId}`
      );
    }
  };

  const onClickCounsellorTab = () => {
    setRenderComponent(2);
    setTabActive(2);
    let obj = {
      batchId: programBatchId,
    };
    dispatch(getCounsellorReport(obj));
  };

  const onClickProgramSummary = () => {
    setRenderComponent(3);
    setTabActive(3);
    dispatch(getProgramSummaryDetail(programBatchId));
  };

  const onClickInterviewReport = () => {
    setRenderComponent(4);
    setTabActive(4);
    dispatch(getProgramInterviewersSummary(programBatchId));
  };

  const onClickMentorCall = () => {
    setRenderComponent(5);
    setTabActive(5);
  };
  const onClickBoxAutomation = () => {
    setRenderComponent(6);
    setTabActive(6);
  };

  const onClickFeeSummary = () => {
    setRenderComponent(1);
    setTabActive(1);
  };

  useEffect(() => {
    if (activeTab === "3") {
      onClickProgramSummary();
    }
  }, [activeTab]);

  const onClickCohortStats = () => {
    setRenderComponent(7);
    setTabActive(7);
  };

  const toggleRevenuePopup = () => {
    setShowStudentRevenuePopup((prev) => !prev);
    if (showStudentRevenuePopup) {
      dispatch(clearCounsellorData());
    }
  };

  const onClickRevenueAmount = (name) => {
    toggleRevenuePopup();
    fetchCounsellorWiseRevenue(name);
  };

  const fetchCounsellorWiseRevenue = (councillorName) => {
    dispatch(
      getStudentRevenueCouncillorWise({ programBatchId, councillorName })
    );
  };

  console.log("print print", showStudentRevenuePopup);

  return (
    <>
      {/* {isFetching && <PageLoader />} */}
      <div className="main-body">
        <SideMenu />
        {showStudentRevenuePopup && (
          <StudentRevenuePopup
            show={showStudentRevenuePopup}
            onClose={toggleRevenuePopup}
            counsellorName={counsellorName}
            data={studentCounsellorWise}
          />
        )}

        <div className="page-header">
          <button onClick={() => navigate(-1)} className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            Back to Page
          </button>

          <div className="switchTabsBtns">
            <button
              onClick={() => onClickFeeSummary()}
              className={`${tabActive === 1 && "active"}`}
            >
              Fee Summary
            </button>
            <button
              onClick={() => onClickCounsellorTab()}
              className={`${tabActive === 2 && "active"}`}
            >
              Counsellor Report
            </button>
            <button
              onClick={() => onClickProgramSummary()}
              className={`${tabActive === 3 && "active"}`}
            >
              Program Summary
            </button>

            <button
              onClick={() => onClickInterviewReport()}
              className={`${tabActive === 4 && "active"}`}
            >
              Interviewer Report
            </button>

            {/* <button
              onClick={() => onClickMentorCall()}
              className={`${tabActive === 5 && "active"}`}
            >
              Mentor Call Summary
            </button> */}

            {/* <button
              onClick={() => onClickMentorCall()}
              className={`${tabActive === 5 && "active"}`}
            >
              Mentor Call Summary
            </button>
            <button
              onClick={() => onClickBoxAutomation()}
              className={`${tabActive === 6 && "active"}`}
            >
              Boxes Automation
            </button> */}

            <button
              onClick={() => onClickCohortStats()}
              className={`${tabActive === 7 && "active"}`}
            >
              Cohort Stats
            </button>
          </div>

          <div className="blank"></div>
        </div>

        <div className="switchTabsWrapper">
          <div className={`switchTabs ${renderComponent === 1 && "active"}`}>
            <div className="page-header">
              <div className="dashboardFilters">
                <select
                  disabled
                  id="type"
                  className="customDropdownOuter"
                  // onChange={(e) => setProgramId(e.target.value)}
                >
                  <option value="true">Masters Camps</option>
                  <option value="false">Masters Union</option>
                </select>

                <select
                  id="type"
                  className="customDropdownOuter"
                  onChange={onChangeProgram}
                >
                  <option value="allPrograms">All Programs</option>
                  {programsList?.map((option, index) => {
                    return (
                      <>
                        <option
                          selected={programId === option?.id}
                          key={index}
                          value={option?.id}
                        >
                          {option?.programName}
                        </option>
                      </>
                    );
                  })}
                </select>

                {/* <FeeSummaryDropdown options={programsList} /> */}

                {programId !== "allPrograms" && (
                  <select
                    id="type"
                    className="customDropdownOuter"
                    onChange={(e) => setBatchId(e.target.value)}
                  >
                    <option value="allCohorts">All Cohorts</option>
                    {batchDetails?.map((option, index) => {
                      return (
                        <>
                          <option
                            selected={batchId === option?.id}
                            key={index}
                            value={option?.id}
                          >
                            {option?.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                )}
              </div>
            </div>

            <div className="newDashboardContainer scrollbar">
              <div className="headerBoxes">
                <div onClick={onClickApplicantCount} className="box">
                  {programId !== "allPrograms" && batchId !== "allCohorts" && (
                    <img src="../assets/icons/upRightBlackArrow.svg" alt="" />
                  )}
                  <p className="value">{programSummaryDataPart1?.applicants}</p>
                  <p className="valueItem">Applicant Count</p>
                </div>
                <div onClick={onClickOfferLetter} className="box">
                  {programId !== "allPrograms" && batchId !== "allCohorts" && (
                    <img src="../assets/icons/upRightBlackArrow.svg" alt="" />
                  )}
                  <p className="value">
                    {programSummaryDataPart1?.offerLetter}
                  </p>
                  <p className="valueItem">Offer Letter</p>
                </div>
                <div onClick={onClickStudent} className="box">
                  {programId !== "allPrograms" && batchId !== "allCohorts" && (
                    <img src="../assets/icons/upRightBlackArrow.svg" alt="" />
                  )}
                  <p className="value">
                    {programSummaryDataPart1?.studentCount}
                  </p>
                  <p className="valueItem">Student Count</p>
                </div>
                <div onClick={onClickStudent} className="box">
                  {programId !== "allPrograms" && batchId !== "allCohorts" && (
                    <img src="../assets/icons/upRightBlueArrow.svg" alt="" />
                  )}
                  <p className="value primary-text">
                    ₹{" "}
                    {formatAmount(
                      programSummaryDataPart1?.feeRecord?.totalAmount
                    )}
                  </p>
                  <p className="valueItem">Expected Fee Amount</p>
                </div>
                <div onClick={onClickStudent} className="box">
                  {programId !== "allPrograms" && batchId !== "allCohorts" && (
                    <img src="../assets/icons/upRightGreenArrow.svg" alt="" />
                  )}
                  <p className="value green-text">
                    ₹{" "}
                    {formatAmount(
                      programSummaryDataPart1?.feeRecord?.submittedAmount
                    )}
                  </p>
                  <p className="valueItem">Total Fee Received</p>
                </div>
                <div onClick={onClickStudent} className="box">
                  {programId !== "allPrograms" && batchId !== "allCohorts" && (
                    <img src="../assets/icons/upRightRedArrow.svg" alt="" />
                  )}
                  <p className="value danger-text">
                    ₹{" "}
                    {formatAmount(
                      programSummaryDataPart1?.feeRecord?.pendingAmount
                    )}
                  </p>
                  <p className="valueItem">Balance Fee Amount</p>
                </div>
              </div>
              <div className="studentStatusOuter">
                <div className="studentStatusBox">
                  <div className="header">
                    <p className="heading">Student Status</p>
                  </div>
                  <div className="body">
                    <div className="attendaceBox">
                      <div className="attendanceHeader">
                        <div className="greenBox"></div>
                        <p>Active</p>
                      </div>
                      <div className="attendanceBody">
                        <p className="value text-success">
                          {programSummaryDataPart1?.activeStudent}
                        </p>
                        {/* <a href="#">View More</a> */}
                      </div>
                    </div>
                    <div className="attendaceBox">
                      <div className="attendanceHeader">
                        <div className="dangerBox"></div>
                        <p>Drop-out</p>
                      </div>
                      <div className="attendanceBody">
                        <p className="value text-danger">
                          {programSummaryDataPart1?.dropOutStudent}
                        </p>

                        {/* <a href="#">View More</a> */}
                      </div>
                      <div className="detailsBox lowerBody">
                        <div className="details">
                          <p className="content">Total Amount</p>
                          <p className="value">
                            ₹{" "}
                            {formatAmount(
                              programSummaryDataPart1?.dropoutAmount
                                ?.totalAmount
                            )}
                          </p>
                        </div>

                        <div className="details">
                          <p className="content">Submitted Amount</p>
                          <p className="value">
                            ₹{" "}
                            {formatAmount(
                              programSummaryDataPart1?.dropoutAmount
                                ?.submittedAmount
                            )}
                          </p>
                        </div>

                        <div className="details">
                          <p className="content">Pending Amount</p>
                          <p className="value">
                            ₹{" "}
                            {formatAmount(
                              programSummaryDataPart1?.dropoutAmount
                                ?.pendingAmount
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="attendaceBox">
                      <div className="attendanceHeader">
                        <div className="blueBox"></div>
                        <p>Defer</p>
                      </div>
                      <div className="attendanceBody">
                        <p className="value text-primary">
                          {programSummaryDataPart1?.deferStudent}
                        </p>
                        {/* <a href="#">View More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="studentStatusBox">
                  <div className="header">
                    <p className="heading">Payment Mode</p>
                  </div>
                  <div className="body dashboardPayment">
                    <div className="paymentBoxOuter">
                      <div className="paymentBox">
                        <div className="upperBody">
                          <img
                            src="../assets/icons/paymentDashboardImg1.svg"
                            alt=""
                          />
                          <div className="paymentDetails">
                            <p className="value">
                              ₹{" "}
                              {formatAmount(
                                programSummaryDataPart1?.oneTimePayment
                                  ?.isOneTimePaymentAmount
                              )}
                            </p>
                            <p className="text">
                              One time payment:{" "}
                              <span>
                                {
                                  programSummaryDataPart1?.oneTimePayment
                                    ?.isOneTimePaymentCount
                                }
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="paymentBox"
                        onClick={() => {
                          // if (
                          //   programId !== "allPrograms" &&
                          //   batchId !== "allCohorts"
                          // ) {
                          navigate(
                            `/flexList?programMasterId=${programId}&programBatchId=${batchId}`
                          );
                          // } else {
                          //   navigate(
                          //     `/flexList?programMasterId=${programMasterId}&programBatchId=${programBatchId}`
                          //   );
                          // }
                        }}
                      >
                        <div className="upperBody">
                          <img
                            src="../assets/icons/paymentDashboardImg3.svg"
                            alt=""
                          />
                          <div className="paymentDetails">
                            <p className="value">
                              ₹{" "}
                              {formatAmount(
                                programSummaryDataPart3?.flex?.isFlexAmount
                              )}
                            </p>
                            <p className="text">
                              Flex:{" "}
                              <span>
                                {programSummaryDataPart3?.flex?.isFlexCount}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="lowerBody">
                          <div className="detailsBox">
                            <div className="details">
                              <p className="content">Collected</p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart2?.flexCollected
                                    ?.flexCollectedAmount
                                )}
                              </p>
                            </div>
                            <div className="details">
                              <p className="content">To be collected</p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart2?.flexToBeRecieved
                                    ?.flexToBeRecievedAmount
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="paymentBoxOuter">
                      <div className="paymentBox">
                        <div className="upperBody">
                          <img
                            src="../assets/icons/paymentDashboardImg2.svg"
                            alt=""
                          />
                          <div className="paymentDetails">
                            <p className="value">
                              ₹{" "}
                              {formatAmount(
                                programSummaryDataPart3?.partialOtp
                                  ?.partialOtpAmount
                              )}
                            </p>
                            <p className="text">
                              Partial OTP:{" "}
                              <span>
                                {
                                  programSummaryDataPart3?.partialOtp
                                    ?.partialOtpCount
                                }
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="paymentBox">
                        <div className="upperBody">
                          <img
                            src="../assets/icons/paymentDashboardImg4.svg"
                            alt=""
                          />
                          <div className="paymentDetails">
                            <p className="value">
                              ₹{" "}
                              {formatAmount(
                                programSummaryDataPart2?.partialLoan?.FeeDue
                                  ?.partialLoanAmount
                              )}
                            </p>
                            <p className="text">
                              Partial Loan:{" "}
                              <span>
                                {
                                  programSummaryDataPart2?.partialLoan?.FeeDue
                                    ?.partialLoanCount
                                }
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="lowerBody">
                          <div className="detailsBox">
                            <div className="details">
                              <p className="content">Subvention deducted</p>
                              <p className="value text-danger">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart2?.partialLoan
                                    ?.subventionAmount
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="detailsBox">
                            <div className="details">
                              <p className="content">Eduvanz</p>
                              <p className="conteint">
                                {
                                  programSummaryDataPart2
                                    ?.partialLoanBankDetails?.eduvanzPartial
                                    ?.eduvanzPartialLoanCount
                                }
                              </p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart2
                                    ?.partialLoanBankDetails?.eduvanzPartial
                                    ?.eduvanzPartialLoanAmount
                                )}
                              </p>
                            </div>
                            <div className="details">
                              <p className="content">Jodo</p>
                              <p className="conteint">
                                {
                                  programSummaryDataPart2
                                    ?.partialLoanBankDetails?.jodoPartial
                                    ?.JodoPartialLoanCount
                                }
                              </p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart2
                                    ?.partialLoanBankDetails?.jodoPartial
                                    ?.JodoPartialLoanAmount
                                )}
                              </p>
                            </div>
                            <div className="details">
                              <p className="content">PropellD</p>
                              <p className="conteint">
                                {
                                  programSummaryDataPart2
                                    ?.partialLoanBankDetails?.propellDPartial
                                    ?.PropellDPartialLoanCount
                                }
                              </p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart2
                                    ?.partialLoanBankDetails?.propellDPartial
                                    ?.PropellDPartialLoanAmount
                                )}
                              </p>
                            </div>
                            <div className="details">
                              <p className="content">Liquiloans</p>
                              <p className="conteint">
                                {
                                  programSummaryDataPart2
                                    ?.partialLoanBankDetails?.liquiloansPartial
                                    ?.liquiLoanPartialLoanCount
                                }
                              </p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart2
                                    ?.partialLoanBankDetails?.liquiloansPartial
                                    ?.liquiLoanPartialLoanAmount
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="paymentBoxOuter">
                      <div className="paymentBox">
                        <div className="upperBody">
                          <img
                            src="../assets/icons/paymentDashboardImg5.svg"
                            alt=""
                          />
                          <div className="paymentDetails">
                            <p className="value">
                              ₹{" "}
                              {formatAmount(
                                programSummaryDataPart1?.admissionFee
                                  ?.admissionFeeAmount
                              )}
                            </p>
                            <p className="text">
                              Admissions Fee:{" "}
                              <span>
                                {programSummaryDataPart1?.admissionFee
                                  ?.admissionFeepaid ?? 0}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="paymentBox">
                        <div className="upperBody">
                          <img
                            src="../assets/icons/paymentDashboardImg6.svg"
                            alt=""
                          />
                          <div className="paymentDetails">
                            <p className="value">
                              ₹{" "}
                              {formatAmount(
                                programSummaryDataPart3?.FullLoan?.FeeDue
                                  ?.fullLoanAmount
                              )}
                            </p>
                            <p className="text">
                              Full Loan:{" "}
                              <span>
                                {programSummaryDataPart3?.FullLoan?.FeeDue
                                  ?.fullLoanCount ?? 0}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="lowerBody">
                          <div className="detailsBox">
                            <div className="details">
                              <p className="content">Subvention deducted</p>
                              <p className="value text-danger">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart3?.FullLoan
                                    ?.subventionAmount
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="detailsBox">
                            <div className="details">
                              <p className="content">Eduvanz</p>
                              <p className="conteint">
                                {programSummaryDataPart3?.fullLoanBankDetails
                                  ?.eduvanzFull?.eduvanzFullLoanCount ?? 0}
                              </p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart3?.fullLoanBankDetails
                                    ?.eduvanzFull?.eduvanzFullLoanAmount
                                )}
                              </p>
                            </div>
                            <div className="details">
                              <p className="content">Jodo</p>
                              <p className="conteint">
                                {programSummaryDataPart3?.fullLoanBankDetails
                                  ?.jodoFull?.JodoFullLoanCount ?? 0}
                              </p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart3?.fullLoanBankDetails
                                    ?.jodoFull?.JodoFullLoanAmount
                                )}
                              </p>
                            </div>
                            <div className="details">
                              <p className="content">PropellD</p>
                              <p className="conteint">
                                {programSummaryDataPart3?.fullLoanBankDetails
                                  ?.propellDFull?.PropellDFullLoanCount ?? 0}
                              </p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart3?.fullLoanBankDetails
                                    ?.propellDFull?.PropellDFullLoanAmount
                                )}
                              </p>
                            </div>
                            <div className="details">
                              <p className="content">Liquiloans</p>
                              <p className="conteint">
                                {programSummaryDataPart3?.fullLoanBankDetails
                                  ?.liquiloansFull?.liquiLoanFullLoanCount ?? 0}
                              </p>
                              <p className="value">
                                ₹{" "}
                                {formatAmount(
                                  programSummaryDataPart3?.fullLoanBankDetails
                                    ?.liquiloansFull?.liquiLoanFullLoanAmount
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="newDashboardChart">
                <div className="header">
                  <p>Payment Amount</p>
                  <div
                    className={`customDropdownOuter ${
                      dropdownActive == 4 ? "active" : ""
                    }`}
                    onClick={() => setDropdownActive(4)}
                  >
                    <p className="selectedOption">Total Revenue</p>
                    <ul className="dropdownItems">
                      <li
                        className="dropdownItem"
                        onClick={() => setDropdownActive(null)}
                      >
                        Total Revenue 1
                      </li>
                      <li
                        className="dropdownItem"
                        onClick={() => setDropdownActive(null)}
                      >
                        Total Revenue 2
                      </li>
                      <li
                        className="dropdownItem"
                        onClick={() => setDropdownActive(null)}
                      >
                        Total Revenue 3
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="chart" style={{ width: "99%" }}>
                  <ReactApexChart
                    options={option.options}
                    series={option.series}
                    type="area"
                    height={280}
                  />
                </div>
              </div> */}
            </div>
          </div>

          <div className={`switchTabs ${renderComponent === 2 && "active"}`}>
            <div className=" mt-20 feedback-container scrollbar">
              <table
                className="feedback-table table maxWidthTable"
                cellPadding={0}
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th>Counsellor Name</th>
                    <th>Total Applicants</th>
                    <th>Interview Pending</th>
                    <th>Interview Scheduled</th>
                    <th>Interview Completed</th>
                    <th>Not Eligible</th>
                    <th>Not Interested</th>
                    <th>No Show</th>
                    <th>Total Rejected</th>
                    <th>Total Waitlisted</th>
                    <th>Offers Made</th>
                    <th>Will Pay</th>
                    <th>NotInterested After Offer</th>
                    <th>Enrolled</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {counsellorReportData.length > 0 &&
                    counsellorReportData.map((item, index) => {
                      const {
                        councillorName,
                        Applicants,
                        interviewPending,
                        Scheduled_Interview,
                        Completed_Interview,
                        OfferLetterSent,
                        Rejected,
                        Waitlisted,
                        NotEligible,
                        NotInterested,
                        NotAppeared,
                        enrolled,
                        willPay,
                        notInterested,
                        amount,
                      } = item;
                      return (
                        <tr key={index}>
                          <td>
                            {councillorName !== "" &&
                            councillorName !== null ? (
                              councillorName
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.substring(1)
                                )
                                .join(" ")
                            ) : (
                              <p className="text-danger">N/A</p>
                            )}
                          </td>
                          <td>{Applicants ?? 0}</td>
                          <td>{interviewPending ?? 0}</td>
                          <td>{Scheduled_Interview ?? 0}</td>
                          <td>{Completed_Interview ?? 0}</td>
                          <td>{NotEligible ?? 0}</td>
                          <td>{NotInterested ?? 0}</td>
                          <td>{NotAppeared ?? 0}</td>
                          <td>{Rejected ?? 0}</td>
                          <td>{Waitlisted ?? 0}</td>
                          <td>{OfferLetterSent ?? 0}</td>
                          <td>{willPay ?? 0}</td>
                          <td>{notInterested ?? 0}</td>
                          <td>{enrolled ?? 0}</td>
                          <td>
                            {amount && amount > 0 ? (
                              <div
                                className="highlight"
                                onClick={() => {
                                  onClickRevenueAmount(councillorName);
                                  setCounsellorName(councillorName);
                                }}
                              >
                                <strong>₹ {formatAmount(amount)}</strong>
                              </div>
                            ) : (
                              "₹ 0"
                            )}
                          </td>
                        </tr>
                      );
                    })}

                  {/* <tr>
                    <td>Utkarsh Rathore</td>
                    <td>9234567890</td>
                    <td>utkarsh@gmail.com</td>
                    <td>10/04/2021 & 10:00 AM</td>
                    <td>Vikas Sharma</td>
                    <td>
                      <p className="tag-draft">Pending</p>
                    </td>
                    <td>
                      <p className="">---</p>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>

          <div className={`switchTabs ${renderComponent === 3 && "active"}`}>
            {/* <div className="programSummaryContainer scrollbar">
              <table className="popupTable" cellPadding={0} cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Total Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Applicants</td>
                    <td>
                      <p className="primary-text">
                        {programSummaryData?.totalApplicant} Interview
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Interview Scheduled</td>
                    <td>
                      <p className="primary-text">
                        {programSummaryData?.totalScheduledToday} Interview
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Interviews Pending</td>
                    <td>
                      <p className="primary-text">
                        {programSummaryData?.interviewToBeScheduled} Interview
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Interview Completed</td>
                    <td>
                      <p className="primary-text">
                        {programSummaryData?.interviewsDone} Interview
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>Total Offers Made</td>
                    <td>
                      <p className="primary-text">
                        {programSummaryData?.paymentExpected} Applicants
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>Total Rejected</td>
                    <td>
                      <p className="primary-text">
                        {programSummaryData?.totalRejected} Applicants
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Total Waitlisted</td>
                    <td>
                      <p className="primary-text">
                        {programSummaryData?.waitlistedCandidates} Applicants
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Not Eligible</td>
                    <td>
                      <p className="danger-text">
                        {programSummaryData?.notEligible} Applicants
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Not Interested</td>
                    <td>
                      <p className="danger-text">
                        {programSummaryData?.NotInterestedBeforeInterview}{" "}
                        Applicants
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>No Show</td>
                    <td>
                      <p className="danger-text">
                        {programSummaryData?.noShowCount} Applicants
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>Enrolled</td>
                    <td>
                      <p className="success-text">
                        {programSummaryData?.totalPaid} Students
                      </p>
                    </td>
                  </tr>
                  <tr>
                  <td>Payment Expected</td>
                  <td>
                    <p className="primary-text">
                      {programSummaryData?.paymentExpected} Applicants
                    </p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div> */}
            <div className="newProgramSummary">
              <div className="programSummaryHeader">
                <p>
                  Total Applicant for this program:{" "}
                  <span>{programSummaryData?.totalApplicant} Applicant</span>
                </p>
              </div>
              <div className="programSummaryContainerOuter">
                <div className="programSummaryBox">
                  <div className="programSummaryBoxHeader">
                    <img src="../assets/icons/programSummaryImg1.svg" alt="" />
                    <div className="programSummaryBoxHeaderRight">
                      <p className="success-text">
                        {programSummaryData?.totalApplicant}
                      </p>
                      <p>Total Interviews</p>
                    </div>
                  </div>
                  <div className="programSummaryList">
                    <div className="programSummaryListItem">
                      <p>Interview Pending</p>
                      <p>{programSummaryData?.interviewToBeScheduled}</p>
                    </div>

                    <div className="programSummaryListItem">
                      <p>Interview Scheduled</p>
                      <p>{programSummaryData?.totalScheduledToday}</p>
                    </div>
                    <div className="programSummaryListItem">
                      <p>Interview Completed</p>
                      <p>{programSummaryData?.interviewsDone}</p>
                    </div>
                    <div className="programSummaryListItem">
                      <p>Not Interested</p>
                      <p>{programSummaryData?.NotInterestedBeforeInterview}</p>
                    </div>

                    <div className="programSummaryListItem">
                      <p>No Show</p>
                      <p>{programSummaryData?.noShowCount}</p>
                    </div>

                    <div className="programSummaryListItem">
                      <p>Eligible</p>
                      <p>{programSummaryData?.notEligible}</p>
                    </div>
                  </div>
                </div>
                <div className="programSummaryBox">
                  <div className="programSummaryBoxHeader">
                    <img src="../assets/icons/programSummaryImg1.svg" alt="" />
                    <div className="programSummaryBoxHeaderRight">
                      <p className="success-text">
                        {programSummaryData?.totalApplicantApproval}
                      </p>
                      <p>Applicant Approval Status</p>
                    </div>
                  </div>
                  <div className="programSummaryList">
                    <div className="programSummaryListItem">
                      <p>Approved</p>
                      <p>{programSummaryData?.approvedApplicant}</p>
                    </div>

                    <div className="programSummaryListItem">
                      <p>Rejected</p>
                      <p>{programSummaryData?.totalRejected}</p>
                    </div>

                    <div className="programSummaryListItem">
                      <p>Waitlisted</p>
                      <p>{programSummaryData?.waitlistedCandidates}</p>
                    </div>
                  </div>
                </div>
                <div className="programSummaryBox">
                  <div className="programSummaryBoxHeader">
                    <img src="../assets/icons/programSummaryImg1.svg" alt="" />
                    <div className="programSummaryBoxHeaderRight">
                      <p className="success-text">
                        {programSummaryData?.paymentExpected}
                      </p>
                      <p>Offer Letter Status</p>
                    </div>
                  </div>
                  <div className="programSummaryList">
                    <div className="programSummaryListItem">
                      <p>Will Pay</p>
                      <p>{programSummaryData?.willPayAmount}</p>
                    </div>
                    <div className="programSummaryListItem">
                      <p>Not Interested</p>
                      <p>{programSummaryData?.notInterestedToJoin}</p>
                    </div>
                    <div className="programSummaryListItem">
                      <p>Enrolled</p>
                      <p>{programSummaryData?.totalPaid}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`switchTabs ${renderComponent === 4 && "active"}`}>
            <div className="feeHeaderBoxes">
              <div className="feeHeaderBox">
                <p className="successText">
                  {programInterviewersSummary?.total}
                </p>
                <p className="heading">Total Interviews</p>
              </div>
              {/* <div className="feeHeaderBox">
                <p className="successText">50</p>
                <p className="heading">Avg mark</p>
              </div> */}
              <div className="feeHeaderBox">
                <p className="successText">
                  {programInterviewersSummary?.studentCount}
                </p>
                <p className="heading">Enrolled students</p>
              </div>
            </div>
            <div className=" mt-20 feedback-container scrollbar">
              <table
                className="feedback-table table maxWidthTable"
                cellPadding={0}
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th>Interviewer Name</th>
                    <th>Total Interviews</th>
                    <th>Pass</th>
                    <th>Fail</th>
                    <th>Not Eligible</th>
                    <th>Not Interested</th>
                    <th>No Show</th>
                    <th>Offers Made</th>
                    <th>Enrolled</th>
                  </tr>
                </thead>
                <tbody>
                  {programInterviewersSummary?.Data.length > 0 &&
                    programInterviewersSummary?.Data.map((item, index) => {
                      const {
                        totalCount,
                        enrolledStudent,
                        failCount,
                        interviewerName,
                        noresponse,
                        notAppeared,
                        notEligible,
                        offerLetterSent,
                        passCount,
                      } = item;
                      return (
                        <tr key={index}>
                          <td>
                            {interviewerName !== "" ? (
                              interviewerName
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.substring(1)
                                )
                                .join(" ")
                            ) : (
                              <p className="text-danger">N/A</p>
                            )}
                          </td>
                          <td>{totalCount ?? 0}</td>
                          <td>{passCount ?? 0}</td>
                          <td>{failCount ?? 0}</td>
                          <td>{noresponse ?? 0}</td>
                          <td>{notEligible ?? 0}</td>
                          <td>{notAppeared ?? 0}</td>
                          <td>{offerLetterSent ?? 0}</td>
                          <td>{enrolledStudent ?? 0}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <MentorFeedbackPopup />
          <div className={`switchTabs ${renderComponent === 5 && "active"}`}>
            <div className="mentorContainerOuter">
              <div className="mentorTableContainer">
                <table className="mentorTable" cellSpacing={0} cellPadding={0}>
                  <thead>
                    <tr>
                      <th>Mentor Name</th>
                      <th>Total Calls</th>
                      <th>Interested</th>
                      <th>Not Interested</th>
                      <th>No Show</th>
                      <th>Enrolled</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Gurji Kochar</td>
                      <td>50 Interviews</td>
                      <td>10</td>
                      <td>5</td>
                      <td>30</td>
                      <td>50</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mentorTableContainer">
                <table className="mentorTable" cellSpacing={0} cellPadding={0}>
                  <thead>
                    <tr>
                      <th>Applicant Name</th>
                      <th>Email ID</th>
                      <th>Phone Number</th>
                      <th>Offer Sent</th>
                      <th>Enrolled</th>
                      <th>Mentor Connect mail</th>
                      <th>Call Status</th>
                      <th>Feedback Filled</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Rimpa Roy</td>
                      <td>RimpaRoy@gnmali.com</td>
                      <td>896523654</td>
                      <td>Yes</td>
                      <td>yes</td>
                      <td>yes</td>
                      <td>
                        <div className="tag-draft">Not Applicable</div>
                        <div className="tag-expired">Not Applicable</div>
                        <div className="tag-active">Not Applicable</div>
                      </td>
                      <td>
                        <div className="tag-draft">No</div>
                        <button className="primaryBtnOutlineSmall">View</button>
                      </td>
                      <td className="text-center">
                        <button className="action-btn">
                          <img src="../assets/icons/options.svg" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <VendorPopup />
          <UpdateBoxAutomation />
          <div className={`switchTabs ${renderComponent === 6 && "active"}`}>
            <div className="page-header flex-end">
              <button className="primary-btn-a noRotate">
                {" "}
                <img src="/assets/icons/plus.svg" alt="" /> Add Vendor
              </button>
              <button className="primary-outline-btn-a">Download CSV</button>
              <button className="primary-btn">
                <img src="/assets/icons/plus.svg" alt="" /> Add Member
              </button>
            </div>
            <div className="boxAutomationContainer">
              <div className="boxesTableOuter" style={{ display: "none" }}>
                <table className="boxesTable" cellSpacing={0} cellPadding={0}>
                  <thead>
                    <tr>
                      <th>Boxes Status</th>
                      <th>Offer Boxes</th>
                      <th>Enrollment Boxes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total Boxes Ordered</td>
                      <td>57</td>
                      <td>32</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="vendorTableOuter">
                <table className="boxesTable" cellPadding={0} cellSpacing={0}>
                  <thead>
                    <tr>
                      <th>Vendor Name</th>
                      <th>Offer Boxes Ordered</th>
                      <th>Enrollment Boxes Ordered</th>
                      <th>Logistics Partner</th>
                      <th>Offer Boxes Delivered</th>
                      <th>Enrollment Boxes Delivered</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Gurji Kochar</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Gurji Kochar</td>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className={`switchTabs ${renderComponent === 7 && "active"}`}>
            <CohortStats
              renderComponent={renderComponent}
              programId={programBatchId}
              cohortStatsData={cohortStatsData}
            />
            {/* <div className="page-header flex-end">
              <button className="primary-btn-a noRotate">
                {" "}
                <img src="/assets/icons/plus.svg" alt="" /> Add Vendor
              </button>
              <button className="primary-outline-btn-a">Download CSV</button>
              <button className="primary-btn">
                <img src="/assets/icons/plus.svg" alt="" /> Add Member
              </button>
            </div>
            <div className="boxAutomationContainer">
              <div className="boxesTableOuter" style={{ display: "none" }}>
                <table className="boxesTable" cellSpacing={0} cellPadding={0}>
                  <thead>
                    <tr>
                      <th>Boxes Status</th>
                      <th>Offer Boxes</th>
                      <th>Enrollment Boxes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total Boxes Ordered</td>
                      <td>57</td>
                      <td>32</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeSummary;
// const option = {
//   series: [
//     {
//       name: "STOCK ABC",
//       data: [10, 20, 40, 37, 58, 28, 46, 99, 100, 127, 56],
//     },
//   ],
//   options: {
//     chart: {
//       type: "area",
//       height: 280,
//       zoom: {
//         enabled: false,
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       curve: "straight",
//     },

//     title: {
//       text: "Fundamental Analysis of Stocks",
//       align: "left",
//     },
//     subtitle: {
//       text: "Price Movements",
//       align: "left",
//     },
//     labels: [
//       "1 Dec",
//       "12 Dec",
//       "13 Dec",
//       "14 Dec",
//       "15 Dec",
//       "16 Dec",
//       "17 Dec",
//       "18 Dec",
//       "19 Dec",
//       "20 Dec",
//       "21 Dec",
//     ],
//     xaxis: {
//       type: "datetime",
//     },
//     yaxis: {
//       opposite: true,
//     },
//     legend: {
//       horizontalAlign: "left",
//     },
//     fill: {
//       type: "gradient",
//       colors: ["#eff4f2", "#eff4f2", "#eff4f2"],
//     },
//   },
// };
