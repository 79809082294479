import React, { useState } from "react";
import "./CalculatorStyle.css";
import SideMenu from "../../components/Layout/menu/SideMenu";
const Calculator = () => {
  let bankData = [
    {
      name: "Eduvanz",
      value: "1",
    },
    {
      name: "Jodo",
      value: "2",
    },
    {
      name: "PropellD",
      value: "3",
    },
    {
      name: "Liquiloans",
      value: "4",
    },
  ];
  let monthsData = [
    {
      name: "3 Months",
      value: 3,
    },
    {
      name: "6 Months",
      value: 6,
    },
    {
      name: "9 Months",
      value: 9,
      id:9
    },
    {
      name: "12 Months",
      value: 12,
    },
    {
      name: "18 Months",
      value: 18,
    },
    {
      name: "24 Months",
      value: 24,
    }
  ];
  const [bank, setBank] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [rateOfInterest, setRateOfInterest] = useState("");
  const [months, setmonths] = useState("");
  const [selectedDropDown, setSelectedDropDown] = useState("Select Months");
  const [installments, setInstallments] = useState("");
  const [toggeler,setToggler] = useState(false)
  const handleToggle = (res) =>{
    setToggler(!toggeler)
  }
  
  const dropDownOnChange=(res)=>{
    setToggler(!toggeler)
    setSelectedDropDown(res.name)
    setmonths(res.value)
    if(res.value === 3 || res.value === 6 || res.value === 9 || res.value === 12){
    setRateOfInterest(0)
    }else if(res.value === 18){
    setRateOfInterest(9.75)
    }else if(res.value === 24){
      setRateOfInterest(7.5)
    }
  }
  const calculateData = () => {
    let res=(parseFloat(loanAmount)+(parseFloat(loanAmount*rateOfInterest)/100))/months;
    setInstallments(parseFloat(res.toFixed(2)));
  };

  return (
    <>
      <div className="main-body">
        <div className="calculator-outer">
          <div className="calculator-header">
            <img src="../assets/icons/calculator.svg" alt="" />
            <div className="header-content">
              <p className="text-1">EMI Calculator</p>
              <p className="text-2 text-grey">
                Calculate the Cost of Your Loan
              </p>{" "}
            </div>
          </div>
          <div className="calc-form-outer">
            {/* <div className="form-left">
              <p className="text-2 text-grey">Select A bank</p>
              <div className="radio-group mt-20">
                // 
                {bankData?.map((val) => (
                  <label className="create-label" key={val.value}>
                    <input
                      className="radio-btn"
                      type="radio"
                      name="que1"
                      value={val.value}
                      checked={bank === val.value}
                      onChange={(e) => setBank(e.target.value)}
                    />
                    <span className="radiomark"></span>
                    {val.name}
                  </label>
                ))}
                \\
                <label className="create-label">
                  <input
                    className="radio-btn"
                    type="radio"
                    name="shouldAccept"
                    defaultChecked
                  />
                  <span className="radiomark"></span>Eduvanz
                </label>
                <label className="create-label">
                  <input
                    className="radio-btn"
                    type="radio"
                    name="shouldAccept"
                  />
                  <span className="radiomark"></span>Jodo
                </label>
                <label className="create-label">
                  <input
                    className="radio-btn"
                    type="radio"
                    name="shouldAccept"
                  />
                  <span className="radiomark"></span>PropellD
                </label>
                <label className="create-label">
                  <input
                    className="radio-btn"
                    type="radio"
                    name="shouldAccept"
                  />
                  <span className="radiomark"></span>Liquiloans
                </label>
              </div>
            </div> */}
            <div className="form-right">
              <div className="form-group">
                <label htmlFor="amount">
                  Loan Amount <span>(In Rupees)</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Amount"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(e.target.value)}
                />
              </div>
              <div className="form-2-col mt-20">
                {/* <div className="form-group">
                  <label htmlFor="months">
                    Tenure <span>(Months)</span>
                  </label>
                  <select
                    id="type"
                    className="popup-dropdown mt-5"
                    onChange={dropDownOnChange}
                  >
                    <option>Select Months</option>
                    {monthsData?.map((res)=>(
                        <option key={res.id} value={res.value}>{res.name}</option>
                    ))}
                        
                  </select>
                </div> */}
                <div className="form-group mt-5 cluster">
                <label htmlFor="name" className="create-label">
                Tenure <span>(Months)</span>
                </label>
                <p className="create-input mt-5" onClick={handleToggle}>{selectedDropDown} </p>
                <ul className={`cluster-dropdown scrollbar ${toggeler? 'active':''}`} >
                {monthsData?.map((res,i)=>(
                  <li key={i} className="cluster-item" onClick={()=>dropDownOnChange(res)}>{res.name}</li>
                    ))}
                </ul>
                
              </div>
                <div className="form-group">
                  <label htmlFor="interest">
                    Rate of Interest <span>(Per Annum)</span>
                  </label>
                  <input
                    type="number"
                    placeholder="Rate of Interest"
                    value={rateOfInterest}
                    onChange={(e) => setRateOfInterest(e.target.value)}
                    disabled
                  />
                </div>
              </div>
              <div className="btn-grp">
                <button onClick={calculateData}>calculate emi</button>
              </div>
              <div className="border"></div>
              <div className="form-group mt-20">
                <label htmlFor="installment">
                  Installments <span>(Per Month)</span>
                </label>
                <input
                  type="number"
                  placeholder="Installment Amount"
                  value={installments}
                  onChange={(e) => setInstallments(e.target.value)}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calculator;
