import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { feeData, getOrganizationPrograms } from "../../../reducers/feeSlice";

const ProgramDropdown = (props) => {
  const dispatch = useDispatch();

  const { programList } = useSelector(feeData);

  useEffect(() => {
    dispatch(getOrganizationPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <select
      id="type"
      className="popup-dropdown mt-5"
      value={props.value}
      onChange={props.onChange}
    >
      <option value="">Select Program</option>
      {programList.length > 0 &&
        programList.map((op) => (
          <option key={op.id} value={op.id}>
            {op.programName}
          </option>
        ))}
    </select>
  );
};

export default ProgramDropdown;
