import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../FeeDuePopup/FeeDuePopupStyle.css";
import ToastMessage from "../../Toast/ToastComponent";
import ErrorToast from "../../Toast/ErrorToast";
import SearchSelect from "react-select";

import {
  postStudentAvailability,
  applicantData,
  postStudentAvailibility,
  getProgramsDetails,
  updateApplicantProgram,
  postAddApplicantFeeManually,
  putUpdateApplicantOfferStatus,
} from "../../../reducers/applicantSlice";

const SaveLogisticDataPopup = ({
  closePopup,
  show,
  setPartnerName,
  partnerName,
  handleSave,
}) => {
  const [counsellorName, setCounsellorName] = useState("");
  const { getAllProgramsDetails } = useSelector(applicantData);

  const dispatch = useDispatch();
  const [reason, setReason] = useState("");

  const [dlResponseReason, setDLResponseReason] = useState("");
  const [newProgramId, setNewProgramId] = useState("");

  const handleClose = () => {
    setAvailibility("NotInterested");
    closePopup();
    clearPopupData();
    setNewProgramId("");
  };

  const [avalibility, setAvailibility] = useState("NotInterested");
  const [letterBoxStatus, setLetterBoxStatus] = useState("Pending");
  const [dlResponse, setDLResponse] = useState("NotSent");

  const [offerAcceptanceStatus, setOfferAcceptanceStatus] = useState("willPay");

  const clearPopupData = () => {
    setAvailibility("NotInterested");
    setLetterBoxStatus("Pending");
    setReason("");
    setCounsellorName("");
    setDLResponse("NotSent");
    setDLResponseReason("");
    setPartnerName("");
    setOfferAcceptanceStatus("willPay");
    // setTransactionNumber("");
    // setAmount("");
    // setPaidOn("");
  };

  return (
    <>
      <div className={`popup-outer ${show ? "show-popup" : ""}`}>
        <div className="popup-container due-popup">
          <>
            <div className="popup-heading">
              Offer Box
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={handleClose}
              />
            </div>

            <div className="popup-body">
              <>
                <label>
                  Courier partner <span className="imp-mark">*</span>
                </label>
                <select
                  id="type"
                  className="popup-dropdown mt-5"
                  onChange={(e) => setPartnerName(e.target.value)}
                  value={partnerName}
                >
                  <option value="">Select Courier partner</option>
                  <option value="Shree Tirupati Courier">Tirupati courier</option>
                  <option value="Delhivery">Delhivery courier</option>
                  <option value="DTDC">Dtdc</option>
                  <option value="ATS Amazon Shipping">Amazon shipping</option>
                  <option value="Ekart Courier">Ekart</option>
                  <option value="Ekart Logistics">Ekart</option>
                </select>
              </>
            </div>
            <div className="popup-footer">
              <button className="primary-btn" onClick={handleSave}>
                Save
              </button>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default SaveLogisticDataPopup;
//

{
  /* <div
                  onChange={handleDLResponseChange}
                  className="radio-group mt-10"
                >
                  <label className="create-label" htmlFor="NotSent">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={dlResponse === "NotSent"}
                      value="NotSent"
                      id="NotSent"
                      name="NotSent"
                    />
                    <span className="radiomark"></span>Not Sent
                  </label>

                  <label className="create-label" htmlFor="Pending">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={dlResponse === "Pending"}
                      value="Pending"
                      id="Pending"
                      name="Pending"
                    />
                    <span className="radiomark"></span>Pending
                  </label>

                  <label className="create-label" htmlFor="Accepted">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={dlResponse === "Accepted"}
                      value="Accepted"
                      id="Accepted"
                      name="Accepted"
                    />
                    <span className="radiomark"></span>Accepted
                  </label>

                  <label className="create-label" htmlFor="Rejected">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={dlResponse === "Rejected"}
                      value="Rejected"
                      id="Rejected"
                      name="Rejected"
                    />
                    <span className="radiomark"></span>Rejected
                  </label>

                  <label className="create-label" htmlFor="InProgress">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="InProgress"
                      id="InProgress"
                      value="InProgress"
                      checked={dlResponse === "InProgress"}
                    />
                    <span className="radiomark"></span>In Progress
                  </label>
                </div> */
}
