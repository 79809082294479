import React, { useState, useEffect } from "react";
import AcceptPaymentPopup from "../../../components/Popups/AcceptPaymentPopup/AcceptPaymentPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyUTRReciept,
  addUTRTransaction,
  feeData,
  clearTransaction,
} from "../../../reducers/feeSlice";
import "./VerifyPopupStyle.css";
import ToastMessage from "../../Toast/ToastComponent";

const VerifyPopup = (props) => {
  const dispatch = useDispatch();
  const { isFetching, isComplete, isError, addUTRTransactionSuccess } =
    useSelector(feeData);
  const [isPaymentPopupOpen, setPaymentPopup] = useState(false);
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const togglePaymentPopup = () => {
    setPaymentPopup(false);
  };

  const [paymentStatus, setPaymentStatus] = useState(false);

  const submitVer = () => {
    // dispatch(verifyUTRReciept({feeDueId: props.data.feeDueId,studentId:props.data.studentId,status }));
    let obj = {
      amount: "",
      transactionNumber: props.data.utrNumber,
      paidOn: "",
      studentId: props.data.studentId,
      feeInstallmentId: "",
      studentFeeDueId: props.data.feeDueId,
      status: "Rejected",
      programId: props?.programId,
    };
    dispatch(addUTRTransaction(obj));
    props.handleClose();
    setPaymentStatus(false);
  };
  const openPaymentPopup = () => {
    setPaymentPopup(true);
    props.handleClose();
    setPaymentStatus(true);
  };

  useEffect(() => {
    if (addUTRTransactionSuccess) {
      setTimeout(() => {
        setEnableSuccessMessage(true);
      }, 1000);
      dispatch(clearTransaction());
    }
  }, [addUTRTransactionSuccess]);
  console.log(addUTRTransactionSuccess, "addUTRTransactionSuccess");

  return (
    <>
      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Success"}
      />
      <AcceptPaymentPopup
        show={isPaymentPopupOpen}
        handleClose={togglePaymentPopup}
        utrNumber={props.data.utrNumber}
        studentId={props.data.studentId}
        feeDueId={props.data.feeDueId}
        feeId={props.data.feeId}
        programId={props?.programId}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container verify-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Verify Fee Status
              <img
                onClick={props.handleClose}
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
              />
            </div>

            <div className="popup-header-body">
              <div className="header-left">
                <p className="text-7">UTR Number:</p>{" "}
                <p className="text-7r">{props.data.utrNumber}</p>
              </div>
            </div>
          </div>

          <div className="popup-footer mt-20">
            <div className="btn-group">
              <button className="danger-btn-outline" onClick={submitVer}>
                Reject
              </button>
              {/* <button className="green-btn" onClick={() => submitVer("Approved")}>Accept</button> */}

              <button className="green-btn" onClick={openPaymentPopup}>
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyPopup;
