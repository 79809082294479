import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import PageLoader from "../../../components/UI/loader/PageLoader";
import FeeDuePopup from "../../../components/Popups/FeeDuePopup/FeeDuePopup";
import CreateInterviewPopup from "../../../components/Popups/createInterviewPopup/createInterviewPopup";
import moment from "moment";

import {
  getShortlistedApplicantList,
  applicantData,
  clearState,
  clearCsvState,
  deleteInterView,
  getApplicantFeedbackAns,
  approveApplicants,
  rejectApplicants,
  generateNewPartialFeeDue,
  getWelcomeLetterStudentList,
} from "../../../reducers/applicantSlice";
import InterViewDeletePopup from "../../../components/Popups/InterViewDeletePopup/InterViewDeletePopup";
import ToastMessage from "../../../components/Toast/ToastComponent";
import "./ShortListStyle.css";
import ActionPopup from "../../../components/Popups/ActionPopup/ActionPopup";
import MuLoanPopUp from "../../../components/Popups/MuLoan/MuLoan";
const ShortList = () => {
  const navigate = useNavigate();
  const [dueType, setDueType] = useState("");
  const param = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const programBatchId = param.proId;
  const programMasterId = searchParams.get("programMasterId");
  const [isNewOffer, setIsNewOffer] = useState(true);

  const [pageNo, setPageNo] = useState("1");
  const [pageSize, setPageSize] = useState("10");

  const setPreviousPage = () => {
    if (pageNo == "1") {
      return;
    }
    setPageNo((prev) => --prev);
  };
  const setNextPage = () => {
    if (pageNo == Math.ceil(shortListCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
  };

  const [loggedInUser, setLoggedInUser] = useState("");

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    setLoggedInUser(userName);
  }, []);

  //action Popup states
  const [actionMessage, setActionMessage] = useState("");
  const [actionHeading, setActionHeading] = useState("");
  const [showAction, setShowAction] = useState(false);
  const [actionType, setActionType] = useState("");
  const [actionName, setActionName] = useState("");

  const [searchTitle, setSearchTitle] = useState("");
  const [emailStatus, setEmailStatus] = useState("false");
  const [approvedStatus, setApprovedStatus] = useState("Pending");
  const [welcomeStatus, setWelcomeStatus] = useState("false");

  const handleSearch = (event) => {
    var search = event.target.value;
    setSearchTitle(search);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchByTitle(e.target.value);
    }
  };

  const searchByTitle = (val) => {
    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: val,
      emailStatus: emailStatus,
      approvedStatus: approvedStatus,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    dispatch(getShortlistedApplicantList({ obj, params }));
    dispatch(clearState());
    setIsChecked([]);
    setAllChecked(false);
    setPageNo("1");
  };

  useEffect(() => {
    setPageNo("1");
    setIsChecked([]);
    setAllChecked(false);
  }, [pageSize, approvedStatus, emailStatus]);

  const [menuId, setMenuId] = useState(null);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isInterviewPopup, setIsInterviewPopup] = useState(false);
  const [applicantId, setApplicantId] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [interviewerId, setInterviewerId] = useState("");
  const [scholarshipAmount, setScholarshipAmount] = useState(0);
  const [feeDueId, setFeeDueId] = useState("");

  const closeInterviewPopup = () => {
    setIsInterviewPopup(false);
    setInterviewerId("");
    setApplicantId("");
    setPopupTitle("");
    setScholarshipAmount(0);
    // setInterviewData(null);
  };

  //check logic
  const [allChecked, setAllChecked] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isTotalSelected, setTotalSelected] = useState(false);
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [shortListId, setShortListId] = useState([]);

  const handleAllCheck = (e) => {
    let currStdIds = shortListData.map((data) => data.id);
    const currSet = new Set(currStdIds);
    if (allChecked) {
      setTotalSelected(false);
      setAllChecked(false);
      let newArr = isChecked.filter((val) => !currSet.has(val));
      return setIsChecked(newArr);
    }
    setAllChecked(true);
    let newIsChecked = [...isChecked];
    currStdIds.map((val) => {
      if (!newIsChecked.includes(val)) {
        newIsChecked.push(val);
      }
    });
    return setIsChecked(newIsChecked);
  };

  const handleSingleCheck = (e) => {
    const id = e.target.value;
    if (isChecked.includes(id)) {
      setIsChecked(isChecked.filter((checked_id) => checked_id !== id));
      setTotalSelected(false);
      return setAllChecked(false);
    }
    isChecked.push(id);
    setIsChecked([...isChecked]);
    checkAllChecked();
  };
  const checkAllChecked = () => {
    let stdIds = shortListData.map((item) => item.id);
    let isAll = stdIds.every((val) => isChecked.includes(val));
    if (!isAll) {
      setTotalSelected(false);
    }
    setAllChecked(isAll);
  };
  const clearSelected = () => {
    setTotalSelected((prev) => !prev);
    setIsChecked([]);
    setAllChecked(false);
  };

  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const {
    shortListData,
    shortListCount,
    isFetching,
    feedbackAns,
    isSuccess,
    isError,
    deleteInterViewList,
    admissionAmount,
    offerLetterAmount,
    tutionFeeAmount,
  } = useSelector(applicantData);

  const deleteShortlistMessage = () => {
    if (deleteInterViewList) {
      setTimeout(() => setEnableSuccessMessage(true), 2000);
      dispatch(clearCsvState());
      setIsChecked([]);
      setAllChecked(false);
    }
  };

  useEffect(() => {
    if (welcomeStatus == "false") {
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        approvedStatus: approvedStatus,
        pageNo: pageNo,
        pageSize: pageSize,
      };

      dispatch(getShortlistedApplicantList({ obj, params }));
      dispatch(clearState());
      // setIsChecked([]);
      // setAllChecked(false);
    } else {
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        pageNo: pageNo,
        pageSize: pageSize,
      };

      dispatch(getWelcomeLetterStudentList({ obj, params }));
      dispatch(clearState());
      // setIsChecked([]);
      // setAllChecked(false);
    }
  }, [emailStatus, approvedStatus, pageNo, pageSize, welcomeStatus]);

  useEffect(() => {
    console.log("in shortList", isSuccess);
    if (isSuccess) {
      closeInterviewPopup();
      setShowAction(false);
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        approvedStatus: approvedStatus,
        pageNo: pageNo,
        pageSize: pageSize,
      };

      dispatch(getShortlistedApplicantList({ obj, params }));
      dispatch(clearState());
      setIsChecked([]);
      setAllChecked(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    deleteShortlistMessage();
  }, [deleteInterViewList]);

  const sendOfferLetters = (obj) => {
    // console.log(isChecked);
  };

  const onApproveClick = (ids) => {
    if (ids.length > 0) {
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        approvedStatus: approvedStatus,
      };
      dispatch(
        approveApplicants({
          obj: ids,
          programId: programBatchId,
          isTotalSelected,
          approvedBy: loggedInUser,
          query: params,
        })
      );
      setIsChecked([]);
      setAllChecked(false);
    }
  };
  const onRejectClick = (ids) => {
    if (ids.length > 0) {
      dispatch(rejectApplicants({ obj: ids, programId: programBatchId }));
      setIsChecked([]);
      setAllChecked(false);
    }
  };
  const onDeleteClick = (id) => {
    toggleDeletePopup();
    setShortListId(id);
  };
  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onDeleteShortListClick = (interViewId) => {
    var params = {
      searchTitle: searchTitle,
      emailStatus: emailStatus,
      approvedStatus: approvedStatus,
    };
    dispatch(
      deleteInterView({
        programId: programBatchId,
        Id: interViewId,
        query: params,
        isTotalSelected,
      })
    );
    setIsDeleteOpen(false);
    setIsChecked([]);
    setAllChecked(false);
  };

  const onSubmit = () => {
    if (actionName === "Approve") {
      if (actionHeading === "Approve Candidates") {
        onApproveClick(isChecked);
      } else {
        if (applicantId !== "") {
          onApproveClick([applicantId]);
        }
      }
    } else if (actionName === "Reject") {
      if (applicantId !== "") {
        onRejectClick([applicantId]);
      }
    } else if (actionName === "Delete") {
      if (applicantId === "" || feeDueId === "") {
        return;
      }
      dispatch(
        generateNewPartialFeeDue({
          applicantId: applicantId,
          feeDueId: feeDueId,
        })
      );
    }
  };

  const mTotalPayableFee = (tutionFee, admissionFee, scholarship) => {
    let scholarshipAmount = parseFloat(scholarship * tutionFee) / 100;
    let gettingTotalTutionFee =
      parseFloat(tutionFee) - parseFloat(scholarshipAmount);
    let gettingTotalAmount =
      parseFloat(gettingTotalTutionFee) + parseFloat(admissionFee);
    let totalPayableFee =
      (parseFloat(gettingTotalAmount) * 18) / 100 +
      parseFloat(gettingTotalAmount);

    console.log("total payable amount", totalPayableFee);

    return parseFloat(totalPayableFee).toFixed(2);

    // return
  };

  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);
  const [isMailAccess, setMailAccess] = useState(false);
  const [isOfferAccess, setOfferAccess] = useState(false);
  const [studentFeedbackAccess, setFeedbackAccess] = useState(false);

  useEffect(() => {
    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    const isOfferAccess =
      localStorage.getItem("offerLetterApproval") === "true";
    const isMailAccess = localStorage.getItem("mailSending") === "true";
    const interviewFeedback =
      localStorage.getItem("interviewFeedback") === "true";
    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
    if (isOfferAccess) {
      setOfferAccess(isOfferAccess);
    }
    if (isMailAccess) {
      setMailAccess(isMailAccess);
    }
    if (interviewFeedback) {
      setFeedbackAccess(interviewFeedback);
    }
  }, []);

  return (
    <>
      {/* <VerifyPopup />
      <AcceptPaymentPopup />
      <LoanPopup /> */}
      <MuLoanPopUp />
      <SideMenu />
      {isFetching && <PageLoader />}
      <ActionPopup
        show={showAction}
        heading={actionHeading}
        handleClose={() => {
          setShowAction(false);
          setApplicantId("");
          setFeeDueId("");
        }}
        message={actionMessage}
        action={actionName}
        actionType={actionType}
        onSubmit={onSubmit}
      />
      <CreateInterviewPopup
        type="feedback"
        togglePopup={closeInterviewPopup}
        show={isInterviewPopup}
        feedbackAns={feedbackAns}
        // applicantName={applicantName}
        interviewerId={interviewerId}
        applicantId={applicantId}
        // interviewData={interviewData}
        programBatchId={programBatchId}
        popupTitle={popupTitle}
        scholarshipAmount={scholarshipAmount}
      />
      <FeeDuePopup
        show={isPopupActive}
        closePopup={() => setIsPopupActive(false)}
        sendOfferLetters={sendOfferLetters}
        student={isChecked}
        programBatchId={programBatchId}
        programMasterId={programMasterId}
        searchTitle={searchTitle}
        emailStatus={emailStatus}
        approvedStatus={approvedStatus}
        pageNo={pageNo}
        pageSize={pageSize}
        isTotalSelected={isTotalSelected}
        shortListCount={shortListCount}
        dueType={dueType}
        isNewOffer={isNewOffer}
        feeDueId={feeDueId}
        clearSelected={clearSelected}
        applicantId={applicantId}
      />

      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Shorlisted deleted successfully"}
      />

      <InterViewDeletePopup
        handleDelete={onDeleteShortListClick}
        handleClose={toggleDeletePopup}
        shortListId={shortListId}
        show={isDeleteOpen}
        name={"Shortlisted"}
      />
      <div className="main-body">
        <div className="page-header">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="component-back"
          >
            <img src="../assets/icons/back-btn.svg" />
            back to page
          </button>
          <div>
            <button
              className={`primary-clean ${
                emailStatus === "false" && approvedStatus === "Pending"
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setApprovedStatus("Pending");
                setEmailStatus("false");
                setWelcomeStatus("false");
              }}
              id="feebtn-1"
            >
              Request
            </button>
            <button
              className={`primary-clean ${
                emailStatus === "false" && approvedStatus === "Approved"
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setApprovedStatus("Approved");
                setEmailStatus("false");
                setWelcomeStatus("false");
                setDueType("offerLetter");
              }}
              id="feebtn-2"
            >
              Approved
            </button>
            <button
              className={`primary-clean ${
                emailStatus === "true" && approvedStatus === "Approved"
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setApprovedStatus("Approved");
                setEmailStatus("true");
                setWelcomeStatus("false");
                setDueType("offerLetter");
              }}
              id="feebtn-3"
            >
              Offer Letter
            </button>

            <button
              className={`primary-clean ${
                welcomeStatus == "true" ? "active" : ""
              }`}
              onClick={() => {
                // setApprovedStatus("Approved");
                setWelcomeStatus("true");
                setEmailStatus("false");
                setApprovedStatus("Anpproved");
                setDueType("welcomeLetter");
              }}
              id="feebtn-3"
            >
              Welcome Letter
            </button>
          </div>
          {/* <p className="text-1">PGP in Technology & Business Management</p> */}
          <p className="text-1"></p>
        </div>
        <div className="student-container">
          <div className="student-info-outer">
            <div className="student-inner-left">
              <div className="student-details-outer">
                <div className="student-details">
                  <p className="student-name text-18">
                    {admissionAmount?.ProgramDetail?.Program?.programName}{" "}
                    <img src="../assets/icons/sectionSymbol.svg" />{" "}
                    <span className="text-danger">Shortlisted Applicants</span>
                  </p>
                </div>
                <div className="student-details">
                  <p className="student-mail">
                    {admissionAmount?.ProgramDetail?.batch} &#xb7;{" "}
                    {admissionAmount?.ProgramDetail?.durationInMonths} months
                  </p>
                </div>
              </div>
            </div>
            <div className="student-inner-right bg-grey">
              <div className="fee-box total">
                <p>Total Dues</p>
                <p className="text-dark">
                  ₹ {offerLetterAmount?.totalAmount?.toFixed(2) ?? 0}
                </p>
              </div>
              <div className="fee-box">
                <p>Received</p>
                <p className="text-success">
                  ₹ {offerLetterAmount?.submittedAmount?.toFixed(2) ?? 0}
                </p>
              </div>
              <div className="fee-box">
                <p>Pending</p>
                <p className="text-danger">
                  ₹ {offerLetterAmount?.pendingAmount?.toFixed(2) ?? 0}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-bar">
          <form action="" className="search-bar">
            <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              onChange={handleSearch}
              onKeyDown={onSearchKeyPress}
              placeholder="Search"
              className="clean-input"
            />
          </form>
          <div className="filters">
            {/* <button className={`dark-toggle-btn ${approvedStatus == 'false' ? 'active' : ''}`} onClick={() => setApprovedStatus('false')}>Pending</button>
            <button className={`success-toggle-btn ${approvedStatus == 'true' ? 'active' : ''}`} onClick={() => setApprovedStatus('true')}>Approved</button> */}
            {/* {approvedStatus == 'Approved' && (<select id="type" onChange={e => setEmailStatus(e.target.value)}>
              <option value="">Mail Status</option>
              <option value="true">Completed</option>
              <option value="false">Pending</option>
            </select>)} */}
            <select id="type" onChange={(e) => setPageSize(e.target.value)}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div className="feedback-container scrollbar shortlist">
          <table
            className="feedback-table table"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                {emailStatus === "false" && (
                  <th className="check-row">
                    {welcomeStatus === "false" ? (
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          name="allCheck"
                          id="allCheck"
                          disabled={viewOnlyAccess || isOfferAccess === false}
                          className="checkmark-btn"
                          checked={allChecked}
                          onChange={handleAllCheck}
                        />
                        <span className="checkmarkspan"></span>
                      </label>
                    ) : (
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          name="allCheck"
                          id="allCheck"
                          disabled={viewOnlyAccess || isMailAccess === false}
                          className="checkmark-btn"
                          checked={allChecked}
                          onChange={handleAllCheck}
                        />
                        <span className="checkmarkspan"></span>
                      </label>
                    )}
                    {/* <></> */}
                  </th>
                )}
                <th>candidate name</th>
                <th>Phone Number</th>
                <th>Email Id</th>
                {welcomeStatus === "false" && <th>Scholarship </th>}
                {emailStatus === "false" && welcomeStatus === "false" && (
                  <>
                    <th>Interviewer Name</th>
                    {/* <th>Scholarship </th> */}
                    <th>Total Amount Payable</th>
                  </>
                )}
                {approvedStatus == "Approved" && emailStatus === "false" && (
                  <th>Approved By</th>
                )}

                {emailStatus === "true" && welcomeStatus === "false" && (
                  <>
                    <th>Fee</th>
                    <th>Fee Submission date</th>
                    <th>Fee Due date</th>
                    <th>Letter Sent By</th>
                  </>
                )}

                {approvedStatus === "Pending" && <th>Status</th>}
                {!viewOnlyAccess && welcomeStatus === "false" && (
                  <th className="text-center">Action</th>
                )}

                {/* {welcomeStatus === "true" && <th>Letter Status</th>} */}
              </tr>
            </thead>
            <tbody>
              {isChecked?.length > 0 && emailStatus === "false" && (
                <tr className="selection">
                  <td className=" pl-0" colSpan="12">
                    <table
                      className="selection-table"
                      cellPadding={0}
                      cellSpacing={0}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {!isTotalSelected && (
                              <>
                                <p>
                                  {allChecked && "All"}{" "}
                                  <b>{isChecked?.length} Candidates</b> on this
                                  page are selected.
                                </p>
                                {allChecked && (
                                  <button
                                    className="clear-btn"
                                    onClick={() =>
                                      setTotalSelected((prev) => !prev)
                                    }
                                  >
                                    Select All{" "}
                                    <b>{shortListCount} Candidates</b>
                                  </button>
                                )}
                              </>
                            )}
                            {isTotalSelected && (
                              <>
                                <p>
                                  All <b>{shortListCount} Candidates</b> are
                                  selected.
                                </p>
                                <button
                                  className="clear-btn"
                                  onClick={clearSelected}
                                >
                                  Clear Selected Candidates
                                </button>
                              </>
                            )}
                            {"   "}
                            {approvedStatus === "Approved" && (
                              <button
                                className="primary-btn-a"
                                onClick={() => {
                                  setIsPopupActive(true);
                                  setIsNewOffer(true);
                                }}
                              >
                                Send Offer Letters
                              </button>
                            )}

                            {welcomeStatus === "true" && (
                              <button
                                className="primary-btn-a"
                                onClick={() => {
                                  setIsPopupActive(true);
                                  setIsNewOffer(true);
                                }}
                              >
                                Send Welcome Letters
                              </button>
                            )}

                            {approvedStatus === "Pending" && (
                              <button
                                className="success-btn-a"
                                onClick={() => {
                                  setShowAction(true);
                                  setActionHeading("Approve Candidates");
                                  setActionMessage(
                                    "Approve checked candidates"
                                  );
                                  setActionName("Approve");
                                  setActionType("success");
                                }}
                              >
                                Approve
                              </button>
                            )}
                            <button
                              className="danger-btn-a"
                              onClick={() => onDeleteClick(isChecked)}
                            >
                              Delete
                            </button>
                            {/* <img src="../../assets/icons/delete.svg" alt="" /> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
              {shortListData &&
                shortListData.map((item, index) => (
                  <tr key={item.id}>
                    {emailStatus === "false" && (
                      <td>
                        {welcomeStatus === "false" ? (
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              className="checkmark-btn"
                              checked={isChecked.includes(item.id)}
                              disabled={
                                viewOnlyAccess || isOfferAccess === false
                              }
                              value={item.id}
                              onChange={handleSingleCheck}
                            />
                            <span className="checkmarkspan"></span>
                          </label>
                        ) : (
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              className="checkmark-btn"
                              checked={isChecked.includes(item.id)}
                              disabled={
                                viewOnlyAccess || isMailAccess === false
                              }
                              value={item.id}
                              onChange={handleSingleCheck}
                            />
                            <span className="checkmarkspan"></span>
                          </label>
                        )}
                      </td>
                    )}
                    <td>
                      {item.firstName} {item.lastName}
                    </td>
                    <td>{item.mobileNumber}</td>
                    <td>{item.officialEmail}</td>
                    {welcomeStatus === "false" && (
                      <td>
                        ₹{" "}
                        {parseFloat(
                          item?.scholarshipAmount * tutionFeeAmount?.amount
                        ) / 100}{" "}
                        ({item?.scholarshipAmount} %){" "}
                      </td>
                    )}
                    {emailStatus === "false" && welcomeStatus === "false" && (
                      <>
                        {item?.ApplicantInterviews?.length > 0 && (
                          <td>
                            {item?.ApplicantInterviews[0]?.interviewerName ??
                              "--"}
                          </td>
                        )}
                        {/* <td>
                          ₹{" "}
                          {parseFloat(
                            item?.scholarshipAmount * tutionFeeAmount?.amount
                          ) / 100}{" "}
                          ({item?.scholarshipAmount} %){" "}
                        </td> */}

                        <td>
                          ₹{" "}
                          {mTotalPayableFee(
                            tutionFeeAmount?.amount,
                            admissionAmount?.amount,
                            item?.scholarshipAmount
                          )}
                        </td>
                      </>
                    )}

                    {approvedStatus == "Approved" &&
                      emailStatus === "false" && (
                        <td>{item?.applicantApprovedBy ?? "-"}</td>
                      )}

                    {emailStatus === "true" && (
                      <>
                        {item?.FeeDues[0]?.isPaid ? (
                          <td>
                            <p className="text-success">
                              ₹ {item?.FeeDues[0]?.FeeTransactions[0]?.amount}
                            </p>
                          </td>
                        ) : (
                          <td>
                            <p className="text-danger">Pending</p>
                          </td>
                        )}
                        {item?.FeeDues[0]?.isPaid ? (
                          <td>
                            {moment(
                              item?.FeeDues[0]?.FeeTransactions[0]?.paidOn
                            ).format("DD MMMM YYYY")}
                          </td>
                        ) : (
                          <td>---</td>
                        )}
                        <td>
                          <p className="text-success">
                            {moment(item?.FeeDues[0]?.dueOn).format(
                              "DD MMMM YYYY"
                            )}
                          </p>
                        </td>

                        <td>{item?.offerLetterSentBy ?? "--"}</td>
                      </>
                    )}

                    {item.isApplicantApproved !== "Approved" &&
                      welcomeStatus === "false" &&
                      (item.isApplicantApproved === "Pending" ? (
                        <td className="btn-grp">
                          <button
                            className="danger-btn"
                            onClick={() => {
                              setApplicantId(item.id);
                              setShowAction(true);
                              setActionHeading("Reject Candidate");
                              setActionMessage("Reject this candidate");
                              setActionName("Reject");
                              setActionType("warning");
                            }}
                          >
                            {" "}
                            <img src="../../assets/icons/redCross.svg" /> Reject
                          </button>
                          <button
                            className="green-btn"
                            onClick={() => {
                              setApplicantId(item.id);
                              setShowAction(true);
                              setActionHeading("Approve Candidate");
                              setActionMessage("Approve this candidate");
                              setActionName("Approve");
                              setActionType("success");
                            }}
                          >
                            <img src="../../assets/icons/tickmark.svg" />
                            Approve
                          </button>
                        </td>
                      ) : (
                        <td>
                          <p className="tag-expired">Rejected</p>
                        </td>
                      ))}

                    {/* {welcomeStatus === "true" && (
                      <td>
                        <p className="text-danger">Pending</p>
                      </td>
                    )} */}
                    {/* {approvedStatus == 'Approved' && item.isMailed && (<td>
                  <p className="tag-active">Sent</p>
                </td>)}
                {approvedStatus == 'Approved' && !item.isMailed && (<td>
                  <p className="tag-draft">Pending</p>
                </td>)} */}
                    {/* {emailStatus === "true" && (
                      <td>
                        <p className="text-success">₹{item.registrationFee}</p>
                      </td>
                    )} */}

                    {!viewOnlyAccess && (
                      <>
                        {welcomeStatus === "false" && (
                          <td className="text-center">
                            <button
                              className="action-btn"
                              onClick={() => openActionMenu(index)}
                              onBlur={closeActionMenu}
                            >
                              <img src="../assets/icons/options.svg" />
                              <ul
                                className={`action-dropdown ${
                                  menuId === index ? "active" : ""
                                } approval-drpdown`}
                              >
                                {studentFeedbackAccess && (
                                  <>
                                    {!item?.FeeDues[0]?.isPaid && (
                                      <li
                                        className="dropdown-item"
                                        onClick={() => {
                                          setInterviewerId(
                                            item.ApplicantInterviews[0].id
                                          );
                                          setApplicantId(item.id);
                                          setIsInterviewPopup(true);
                                          setPopupTitle("Candidate Feedback");
                                          setScholarshipAmount(
                                            item.scholarshipAmount
                                          );
                                          dispatch(
                                            getApplicantFeedbackAns({
                                              applicantId: item.id,
                                            })
                                          );
                                        }}
                                      >
                                        <img
                                          src="../assets/icons/edit.svg"
                                          alt=""
                                        />
                                        <p className="dropdown-text">
                                          Candidate Feedback
                                        </p>
                                      </li>
                                    )}
                                  </>
                                )}

                                {emailStatus === "true" ? (
                                  <>
                                    <li
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          item?.FeeDues[0]?.paymentLink
                                        )
                                      }
                                      className="dropdown-item "
                                    >
                                      <img
                                        src="../assets/icons/copy.svg"
                                        alt=""
                                      />
                                      <p className="dropdown-text">
                                        Copy Payment Link
                                      </p>
                                    </li>
                                    {!viewOnlyAccess && isOfferAccess === false && (
                                      <>
                                        {!item?.FeeDues[0]?.isPaid && (
                                          <li
                                            onClick={() => {
                                              setIsPopupActive(true);
                                              setIsNewOffer(false);
                                              setFeeDueId(item?.FeeDues[0]?.id);
                                              setApplicantId(item?.id);
                                            }}
                                            className="dropdown-item "
                                          >
                                            <img
                                              src="../assets/icons/copy.svg"
                                              alt=""
                                            />
                                            <p className="dropdown-text">
                                              Resend Offer Letter
                                            </p>
                                          </li>
                                        )}
                                      </>
                                    )}

                                    <li
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          item?.FeeDues[0]?.pdfLink
                                        )
                                      }
                                      className="dropdown-item "
                                    >
                                      <img
                                        src="../assets/icons/copy.svg"
                                        alt=""
                                      />
                                      <p className="dropdown-text">
                                        Copy PDF Link
                                      </p>
                                    </li>

                                    <li
                                      className="dropdown-item "
                                      // onClick={() => dispatch(generateNewPartialFeeDue({ applicantId: item?.id, feeDueId: item?.FeeDues[0]?.id }))}
                                      onClick={() => {
                                        setApplicantId(item?.id);
                                        setFeeDueId(item?.FeeDues[0]?.id);
                                        setShowAction(true);
                                        setActionHeading("Delete Offer Letter");
                                        setActionMessage(
                                          "Are you sure you want to delete applicant offer letter?"
                                        );
                                        setActionName("Delete");
                                        setActionType("warning");
                                      }}
                                    >
                                      <img
                                        src="../assets/icons/delete.svg"
                                        alt=""
                                      />
                                      <p className="dropdown-text">Delete</p>
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li
                                      className="dropdown-item "
                                      onClick={() => onDeleteClick([item.id])}
                                    >
                                      <img
                                        src="../assets/icons/delete.svg"
                                        alt=""
                                      />
                                      <p className="dropdown-text">Delete</p>
                                    </li>
                                  </>
                                )}
                              </ul>
                            </button>
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
              {/* <tr>
                <td>
                  <label className="checkbox-label">
                    <input type="checkbox" className="checkmark-btn" />
                    <span className="checkmarkspan"></span>
                  </label>
                </td>
                <td>Utkarsh Rathore</td>
                <td>7905004305</td>
                <td>utkarsh.rathore@gmail.com</td>
                <td>Vikas Sharma</td>
                <td>50%</td>
                <td>
                  <p className="tag-draft">Pending</p>
                </td>
                <td>
                  <p className="text-danger">Pending</p>
                </td>
                <td className="text-center">
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                    <ul className="action-dropdown active  approval-drpdown">
                      <li className="dropdown-item">
                        <img src="../assets/icons/list.svg" alt="" />
                        <p className="dropdown-text">Candidate Details</p>
                      </li>
                      <li className="dropdown-item">
                        <img src="../assets/icons/edit.svg" alt="" />
                        <p className="dropdown-text">Candidate Feedback</p>
                      </li>
                      <li className="dropdown-item ">
                        <img src="../assets/icons/delete.svg" alt="" />
                        <p className="dropdown-text">Delete</p>
                      </li>
                    </ul>
                  </button>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className=" pg-arrows-outer ">
          <p className="pg-arraows-p">
            {pageNo} of{" "}
            {shortListCount <= pageSize
              ? "1"
              : Math.ceil(shortListCount / pageSize)}
          </p>
          <div className="pg-arrows">
            <a onClick={setPreviousPage}>
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                className="pg-left"
              />
            </a>
            <a onClick={setNextPage}>
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                className="pg-right"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShortList;
