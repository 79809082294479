import React from 'react'
import './MentorFeedbackPopupStyle.css'
const MentorFeedbackPopup = (props) => {
    return (
        <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
            <div className="popup-container mentorFeedbackContainer">
                <div className="popup-header">
                    <div className="popup-heading">
                    <p className='heading'>Feedback</p>
                    <img src="../assets/icons/close.svg" alt="" className="close-btn"/>
                    </div>
                </div>
                <div className="popup-body">
                    <div className="feedbackCard">
                        <p className="text-primary heading">Mentor Feedback</p>
                        <div className="feedbackBox">
                            <p>Que:{" "}Do you think the candidate will enroll in the programme?</p>
                            <p>Ans:<span>Maybe</span> </p>
                        </div>
                        <div className="feedbackBox">
                            <p>Que:{" "}Any other comments/suggestions</p>
                            <p>Ans:<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</span> </p>
                        </div>
                    </div>
                    <div className="feedbackCard">
                        <p className="text-primary heading">Student Feedback</p>
                        <div className="feedbackBox">
                            <p>Que:{" "}Do you think the candidate will enroll in the programme?</p>
                            <p>Ans:<span>Maybe</span> </p>
                        </div>
                        <div className="feedbackBox">
                            <p>Que:{" "}Any other comments/suggestions</p>
                            <p>Ans:<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</span> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MentorFeedbackPopup