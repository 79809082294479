import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";
let token;

// /:organizationId/orderId/:orderId/fetchOrderDetails
export const getVendorOrderDetails = createAsyncThunk(
  "shipping/organizatonProgramsWithBatch",
  async (orderId, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL +
          "/api/org/orderId/" +
          orderId +
          "/fetchOrderDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const putSaveVendorOrderDetails = createAsyncThunk(
  "shipping/putSaveVendorOrderDetails",
  async (orderId, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL +
          "/api/org/orderId/" +
          orderId +
          "/saveVendorResponse",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getApplicantDetailsForLogisticsPartner = createAsyncThunk(
  "shipping/getApplicantDetailsForLogisticsPartner",
  async (orderId, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL +
          "/api/org/orderId/" +
          orderId +
          "/ApplicantsDetailsForLogisticsPartner",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const putSaveLogisticsPartnerResponse = createAsyncThunk(
  "shipping/putSaveLogisticsPartnerResponse",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL +
          "/api/org/orderId/" +
          obj?.orderId +
          "/saveLogisticsPartnerResponse",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const shippingSlice = createSlice({
  name: "shippingDetail",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    vendorOrderDetails: [],
    applicantDetailsLogistics: [],
    offerBoxStatus: null,
    logisticResponse: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.offerBoxStatus = null;
      state.logisticResponse = null;
      return state;
    },
  },
  extraReducers: {
    [getVendorOrderDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.vendorOrderDetails = payload?.applicantsofferboxtrackings;
      state.offerBoxStatus = payload?.status;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getVendorOrderDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getVendorOrderDetails.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [putSaveVendorOrderDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [putSaveVendorOrderDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [putSaveVendorOrderDetails.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getApplicantDetailsForLogisticsPartner.fulfilled]: (
      state,
      { payload }
    ) => {
      console.log("printing payload", payload);
      state.applicantDetailsLogistics =
        payload?.Data.applicantsofferboxtrackings;
      state.offerBoxStatus = payload?.Data.status;
      state.logisticResponse = payload;
      state.isFetching = false;
      // state.isSuccess = true;
      return state;
    },
    [getApplicantDetailsForLogisticsPartner.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getApplicantDetailsForLogisticsPartner.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [putSaveLogisticsPartnerResponse.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [putSaveLogisticsPartnerResponse.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [putSaveLogisticsPartnerResponse.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
  },
});

export const { clearState } = shippingSlice.actions;

export const shippingData = (state) => state.shippingDetail;
