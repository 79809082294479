import React from "react";
import "./MuLoanStyle.css";
const MuLoanPopUp = (props) => {
  return (
    <>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container loan-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Masters’ Union Loan Details
              <img
                onClick={props.handleClose}
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
              />
            </div>
          </div>
          <div className="popup-body loan-popup-body muloan-body">
            <div className="loan-steps-outer">
              <div className="loan-step active">
                <p className="number">1</p>
                <p className="text">Request response </p>
              </div>
              <div className="loan-step">
                <p className="number">2</p>
                <p className="text">Create EMI</p>
              </div>
            </div>
            <div className="loan-step-content scrollbar">
              <div className="border-box">
                <div className="box-header">
                  <div className="box-left">
                    <p className="heading">
                      Request Response{" "}
                      <p className="text-success">₹ 28,050.00</p>{" "}
                    </p>
                    <p className="text">Student send Request for Loan</p>
                  </div>
                  <div className="box-right">
                    <button className="reject-btn">
                      <img src="../../assets/icons/redCross.svg" alt="" />{" "}
                      Reject
                    </button>
                    {/* <button className="accept-btn">
                      <img src="../../assets/icons/whiteTick.svg" alt="" />
                      Approve
                    </button>
                    <div className="approved-tag">Approved</div> */}
                  </div>
                </div>
                <div className="box-body">
                  Most personal loans have fixed interest rates, which means
                  that your payments will stay the same every month. Personal
                  loans are also typically unsecured, meaning there’s no
                  collateral behind the loan. If you don’t qualify for an
                  unsecured personal loan, you may have to use collateral to be
                  approved, like a savings account.
                </div>
              </div>
              <div className="border-box">
                <div className="box-header">
                  <div className="box-left">
                    <p className="heading">Masters’ Union EMI</p>
                    <p className="text">Create EMI For Students</p>
                  </div>
                  <div className="box-right">
                    <div className="light-tag">₹ 215247</div>
                    <div className="approved-tag">Completed</div>
                  </div>
                </div>
                {/* <div className="loan-radio-grp">
                  <label className="create-label active" for="3">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="loan"
                      id="3"
                      value="3"
                    />
                    <span className="radiomark"></span>₹ 71,749.00/mo For 3 months
                  </label>
                  <label className="create-label active" for="6">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="loan"
                      id="6"
                      value="6"
                    />
                    <span className="radiomark"></span>₹ 35,874.50/mo For 6 months
                  </label>
                  <label className="create-label active" for="9">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="loan"
                      id="9"
                      value="9"
                    />
                    <span className="radiomark"></span>₹ 23,916.33/mo For 9 months
                  </label>
                  <label className="create-label active" for="12">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="loan"
                      id="12"
                      value="12"
                    />
                    <span className="radiomark"></span>₹ 17,937.25/mo For 12 months
                  </label>
                  <label className="create-label active" for="18">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="loan"
                      id="18"
                      value="18"
                    />
                    <span className="radiomark"></span>₹ 11,958.25/mo For 18 months{" "}
                    <span className="light-text">(₹ 1167.71) 6.51%</span>
                  </label>
                  <label className="create-label active" for="24">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="loan"
                      id="24"
                      value="24"
                    />
                    <span className="radiomark"></span>₹ 8,968.63/mo For 24 months{" "}
                    <span className="light-text">(₹ 1164.13) 6.50%</span>
                  </label>
                </div>
                <div className="popup-footer">
                  <p className="amount">₹ 71,749,00/mo</p>
                  <button className="green-btn-a">Send</button>
                </div> */}
                <p className="text-success">₹ 71,749,00/mo for 6 month</p>
              </div>
            </div>
          </div>
          {/* <div className="popup-footer">
            <button className="green-btn">Save</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MuLoanPopUp;
