import React from "react";
import "./MeetingDatePopup.css";
import moment from "moment";
const MeetingDatePopup = (props) => {
  const handleClose=()=>{
    props.handleClose()
    props.setExpiryDate("")
  }
  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container extend-popup">
          <div className="popup-header">
            <div className="popup-heading">
              {props.nextMeetingPopupName ? "Schedule your session" : "Session Date & Time"}
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={handleClose}
              />
            </div>
          </div>
          <div className="popup-body">
            <input
              type="datetime-local"
              name="expiryDate"
              id="expiryDate"
              value={props.expiryDate}
              className="create-input mt-5"
              onChange={(e) => props.setExpiryDate(e.target.value)}
              min={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
            />
          </div>
          <div className="popup-footer pt-10">
            <div className="btn-group">
              <button
                className="dark-btn-outline"
                onClick={handleClose}
              >
                cancel
              </button>
              <button className="green-btn" onClick={props.handleDateSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MeetingDatePopup;
