import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

export const loginUser = createAsyncThunk(
  "loginUser",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = {
        email: email,
        password: password,
      };

      console.log("nvnfjkvnfknvjfnfv fv f", payload);
      const response = await fetch(
        // "https://test-epicapi.herokuapp.com/api/org/admin/auth",
        `${environment.baseURL}/api/org/admin/auth`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        localStorage.setItem("token", data.Token);
        localStorage.setItem("orgId", data.Data.Organization.id);
        localStorage.setItem("adminId", data.Data.id);
        localStorage.setItem("isUser", data.isUser);
        localStorage.setItem(
          "canApproveExpense",
          data?.roles?.canApproveExpense
        );
        localStorage.setItem("isFinanceMember", data?.roles?.isFinanceMember);
        localStorage.setItem("isMentor", data?.roles?.isExternalMentor);

        localStorage.setItem(
          "interviewFeedback",
          data?.roles?.interviewFeedback
        );
        localStorage.setItem(
          "isBlockChainProgram",
          data?.roles?.isBlockChainProgram
        );
        localStorage.setItem("isKpmgProgram", data?.roles?.isKpmgProgram);
        localStorage.setItem("isProductProgram", data?.roles?.isProductProgram);
        localStorage.setItem("mailSending", data?.roles?.mailSending);
        localStorage.setItem(
          "offerLetterApproval",
          data?.roles?.offerLetterApproval
        );
        localStorage.setItem("viewOnlyAccess", data?.roles?.viewOnlyAccess);
        localStorage.setItem("isAddApplicant", data?.roles?.isAddApplicant);
        localStorage.setItem("isAddProgram", data?.roles?.isAddProgram);
        localStorage.setItem("approvalRequest", data?.roles?.approvalRequest);

        localStorage.setItem(
          "userName",
          data.Data.firstName + " " + data.Data.lastName
        );

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "loginAdmin",
  initialState: {
    username: "",
    email: "",
    isFetching: false,
    isSuccess: false,
    isUser: false,
    isError: false,
    errorMessage: "",
    mentorStudentRelationship: null,
    isCoachAdmin: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isUser = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      console.log("payload payload", payload);
      state.email = payload?.Data?.email;
      state.username = payload?.Data?.firstName;
      state.isFetching = false;
      state.isSuccess = true;
      state.isUser = payload?.isUser;
      state.isCoachAdmin = payload?.isCoachAdmin;
      state.mentorStudentRelationship =
        payload?.Data?.MentorStudentRelationship &&
        payload?.Data?.MentorStudentRelationship;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [loginUser.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;
