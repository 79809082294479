import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let adminId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};

export const getDashboardDetailData = createAsyncThunk(
  "dashboardDetail/getDashboardDetailData",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/getDashboardData",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboardDetail",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    dashboardDetails: {},
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      return state;
    },
  },
  extraReducers: {
    [getDashboardDetailData.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.dashboardDetails = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getDashboardDetailData.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getDashboardDetailData.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
  },
});

export const { clearState } = dashboardSlice.actions;

export const dashboardData = (state) => state.dashboardDetail;
