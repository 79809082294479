import React from "react";
import "./AlertPopupStyle.css";
const AlertPopup = (props) => {
  console.log("print data", props);

  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container delete-poup">
          <div className="popup-header">
            <div className="popup-heading">
              Alert Message
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>

            <div className="popup-header-body">
              <div className="header-left">
                <p className="text-7">{props.message}</p>
                {/* <p className="text-7r">You can’t undo this action.</p> */}
              </div>
            </div>
          </div>

          <div className="popup-footer mt-40">
            <div className="btn-group">
              <button onClick={props.handleClose} className="dark-btn-outline">
                cancel
              </button>
              <button
                onClick={() => props.handleEditFeedback()}
                className="danger-btn"
              >
                Edit Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AlertPopup;
