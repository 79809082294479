import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import SideMenu from "../../components/Layout/menu/SideMenu";
import { summaryGetCohortStats } from "../../reducers/feeSlice";
import "./CohortStatsStyle.css";

const CohortStats = ({ programId, renderComponent, cohortStatsData }) => {
  const dispatch = useDispatch();

  const [genderStat, setGenderStats] = useState([]);
  const [stateStat, setStateStat] = useState([]);
  const [instituteStat, setInstituteStat] = useState([]);
  const [employerStat, setEmployerStat] = useState([]);
  const [streamStat, setStreamStat] = useState([]);
  const [ageStat, setAgeStat] = useState([]);
  const [workFieldStat, setWorkFieldStat] = useState([]);

  useEffect(() => {
    if (renderComponent === 7) dispatch(summaryGetCohortStats(programId));
  }, [renderComponent]);

  console.log("ifjefehfhe8fhef", cohortStatsData);

  // useEffect(() => {
  //   if (Object.keys(cohortStatsData).length > 0) {
  //     // setGenderStats(cohortStatsData?.Gender);
  //     // setStateStat(cohortStatsData?.State_Current);
  //     // setStreamStat(cohortStatsData?.Stream_Class12);
  //     // setAgeStat(cohortStatsData?.averageAge);
  //     // setInstituteStat(cohortStatsData?.Institute_Undergrad);
  //   }
  // }, [cohortStatsData]);

  // console.log("printing gender Stats", genderStat);

  useEffect(() => {
    if (cohortStatsData) {
      console.log("after if else condition", cohortStatsData);
      setInstituteStat(cohortStatsData?.Institute_Undergrad);
      setStateStat(cohortStatsData?.State_Current);
      setAgeStat(parseInt(cohortStatsData?.averageAge[0]?.averageAge));
      setStreamStat(cohortStatsData?.Stream_Class12);
      setEmployerStat(cohortStatsData?.Company_WorkEx1);
      setWorkFieldStat(cohortStatsData?.Job_Title_WorkEx1);
    }
  }, [cohortStatsData]);

  console.log("institute", instituteStat);

  const streamTitle = [];
  const streamValue = [];

  streamStat.map((val) => {
    streamTitle.push(val.Stream_Class12 ?? "Others");
    streamValue.push(val.Stream_Class12_count);
  });

  const data = {
    options: {
      chart: {
        id: "basic-bar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        categories: streamTitle,
      },
      fill: {
        colors: ["#FFB843"],
      },
      stroke: {
        colors: ["#FFB843"],
      },
      dataLabels: {
        style: {
          colors: ["#000"],
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
      },
    },
    series: [
      {
        name: "series-1",
        data: streamValue,
      },
    ],
  };

  var options = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#5243aa1a",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            show: true,
            // fontSize: "14px",
            fontFamily: undefined,
            fontWeight: 400,
            color: undefined,
            // offsetY: 16,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      colors: ["#5243AA"],
    },
    labels: ["Average Results"],
    //   radialBar: {
    //     startAngle: -90,
    //     endAngle: 90,
    //     track: {
    //       background: "#e7e7e7",
    //       strokeWidth: '97%',
    //       margin: 5, // margin is in pixels
    //       dropShadow: {
    //         enabled: true,
    //         top: 2,
    //         left: 0,
    //         color: '#999',
    //         opacity: 1,
    //         blur: 2
    //       }
    //     },
    // }
  };

  var options2 = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#ff540d1a",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      colors: ["#FF540D"],
    },
    labels: ["Average Results"],
    //   radialBar: {
    //     startAngle: -90,
    //     endAngle: 90,
    //     track: {
    //       background: "#e7e7e7",
    //       strokeWidth: '97%',
    //       margin: 5, // margin is in pixels
    //       dropShadow: {
    //         enabled: true,
    //         top: 2,
    //         left: 0,
    //         color: '#999',
    //         opacity: 1,
    //         blur: 2
    //       }
    //     },
    // }
  };

  var optionsPie = {
    series: [44, 66],
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: ["#FF540D", "#FFB843"],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  var optionsPie2 = {
    series: [40, 10, 35, 25],
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: ["#00875A", "#FF540D", "#FFB843", "#0052CC"],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  var optionsPie3 = {
    series: [38, 10],
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: ["#0052CC", "#0052cc1a"],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  console.log("printing age value", ageStat);

  return (
    <>
      <SideMenu />

      <div className="oneWrap">
        <div className="threexBoxWrap flexbox">
          <div className="programBoxWrap cohortBox LightblueFeeBox">
            <div className="programDetails">
              <h3 className="programCount">18-45 years</h3>
              <span className="programText">Age Range</span>
            </div>
            <div className="boxDetailed mt-20">
              <ReactApexChart
                options={options}
                series={[ageStat]}
                type="radialBar"
                width="100%"
                height="350px"
              />
              <div className="chart"></div>
              <div className="chartHeading">Average Age</div>
            </div>
          </div>

          {/* <div className="programBoxWrap cohortBox LightblueFeeBox">
            <div className="programDetails">
              <h3 className="programCount">0-9 years</h3>
              <span className="programText">Work Experience Range</span>
            </div>
            <div className="boxDetailed mt-20">
              <ReactApexChart
                options={options2}
                series={[30]}
                type="radialBar"
                width="100%"
                height="350px"
              />
              <div className="chart"></div>
              <div className="chartHeading">Average Experience Range</div>
            </div>
          </div> */}

          <div className="graphWrapper">
            <div className="graphHeading flexbox">
              <h3 className="headingGraph">Gender</h3>
            </div>
            <div className="graphBody">
              <ReactApexChart
                options={optionsPie}
                series={optionsPie.series}
                type="donut"
                width="100%"
                height="215px"
              />
              <div className="legendsGraph flexbox mt-30">
                <div className="chartDetail maleCat">
                  Male: <strong>62%</strong>
                </div>
                <div className="chartDetail femaleCat">
                  Female: <strong>38%</strong>
                </div>
              </div>
            </div>
          </div>

          <div className="graphWrapper">
            <div className="graphHeading flexbox">
              <h3 className="headingGraph">States</h3>
            </div>
            <div className="graphBody min284">
              {stateStat?.map((item, index) => {
                const { State_Current, State_Current_count } = item;
                return (
                  <div key={index} className="listCountBox flexbox">
                    <div className="stateName">{State_Current}</div>
                    <span className="divider"></span>
                    <div className="stateCount">{State_Current_count}</div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="graphWrapper">
            <div className="graphHeading flexbox">
              <h3 className="headingGraph">Gender</h3>
            </div>
            <div className="graphBody min450">
              <ReactApexChart
                options={optionsPie}
                series={optionsPie.series}
                type="donut"
                width="100%"
                height="350px"
              />
              <div className="legendsGraph flexbox mt-30">
                <div className="chartDetail maleCat">
                  Male: <strong>62%</strong>
                </div>
                <div className="chartDetail femaleCat">
                  Female: <strong>38%</strong>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="graphWrapper">
            <div className="graphHeading flexbox">
              <h3 className="headingGraph">Time Wise Work Experience</h3>
            </div>
            <div className="graphBody min450">
              <ReactApexChart
                options={optionsPie3}
                series={optionsPie3.series}
                type="donut"
                width="100%"
                height="350px"
              />
              <div className="legendsGraph flexbox mt-30">
                <div className="chartDetail blueDot">
                  Freshers: <strong>62%</strong>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="graphWrapper">
            <div className="graphHeading flexbox">
              <h3 className="headingGraph">Freshers</h3>
            </div>
            <div className="graphBody min450">
              <ReactApexChart
                options={optionsPie2}
                series={optionsPie2.series}
                type="donut"
                width="100%"
                height="350px"
              />
              <div className="legendsGraph flexbox mt-30">
                <div className="chartDetail greenDot">
                  4-6 Years: <strong>2.5%</strong>
                </div>
                <div className="chartDetail blueDot">
                  2 Years: <strong>22.5%</strong>
                </div>
              </div>
              <div className="legendsGraph flexbox mt-10">
                <div className="chartDetail maleCat">
                  6-8 Years: <strong>22.5%</strong>
                </div>
                <div className="chartDetail femaleCat">
                  2-4 Years: <strong>52.5%</strong>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="oneXWrapper mt-20">
          <div className="graphWrapper">
            <div className="graphHeading flexbox">
              <h3 className="headingGraph">Applicant Summary</h3>
            </div>
            <div className="graphBody">
              <ReactApexChart
                options={data.options}
                series={data.series}
                type="bar"
                width="100%"
                height="400px"
              />
            </div>
          </div>
        </div>

        <div className="graphWrapper mt-20">
          <div className="graphHeading flexbox">
            <h3 className="headingGraph">Work Experience</h3>
          </div>
          <div className="wrapGraphBody flexbox">
            <div className="graphBody">
              {workFieldStat.map((item, index) => {
                const { Job_Title_WorkEx1, Job_Title_WorkEx1_count } = item;

                let experienceCount = parseInt(Job_Title_WorkEx1_count) ?? 0;
                return (
                  <div key={index} className="listCountBox flexbox">
                    <div className="stateName">{Job_Title_WorkEx1}</div>
                    <span className="divider"></span>
                    <div className="stateCount">{experienceCount}</div>
                  </div>
                );
              })}
            </div>
            {/* <div className="graphBody">
                {stateStat?.map((item, index) => {
                  const { State_Current, State_Current_count } = item;
                  return (
                    <div key={index} className="listCountBox flexbox">
                      <div className="stateName">{State_Current}</div>
                      <span className="divider"></span>
                      <div className="stateCount">{State_Current_count}</div>
                    </div>
                  );
                })}
              </div>
              <div className="graphBody">
                {stateStat?.map((item, index) => {
                  const { State_Current, State_Current_count } = item;
                  return (
                    <div key={index} className="listCountBox flexbox">
                      <div className="stateName">{State_Current}</div>
                      <span className="divider"></span>
                      <div className="stateCount">{State_Current_count}</div>
                    </div>
                  );
                })}
              </div> */}
          </div>
        </div>

        {/* <div className="graphWrapper mt-20">
          <div className="graphHeading flexbox">
            <h3 className="headingGraph">Work Experience</h3>
          </div>

          <div className="multi4Box flexbox">
            {workFieldStat.map((item, index) => {
              const { Job_Title_WorkEx1, Job_Title_WorkEx1_count } = item;

              let experienceCount = parseInt(Job_Title_WorkEx1_count) ?? 0;

              return (
                <div key={index} className="coverGraph">
                  <ReactApexChart
                    options={options}
                    series={[experienceCount]}
                    type="radialBar"
                    width="100%"
                    height="350px"
                  />
                  <div className="chartHeadingSmall">{Job_Title_WorkEx1}</div>
                </div>
              );
            })}
          </div>
        </div> */}

        <div className="twoBoxWrap flexbox mt-20 listBoxWrapper">
          <div className="graphWrapper">
            <div className="graphHeading flexbox">
              <h3 className="headingGraph">Former Employers</h3>
            </div>
            <div className="graphBody flexbox">
              {employerStat.map((item, index) => {
                const { Company_WorkEx1 } = item;

                return (
                  <div key={index} className="listBoxIcon">
                    <img src="/assets/icons/CheckCircleBlack.svg" alt="" />
                    {Company_WorkEx1 ?? "Others"}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="graphWrapper">
            <div className="graphHeading flexbox">
              <h3 className="headingGraph">UG Colleges</h3>
            </div>
            <div className="graphBody flexbox">
              {instituteStat?.map((item, index) => {
                const { Institute_Undergrad } = item;

                return (
                  <div key={index} className="listBoxIcon">
                    <img src="/assets/icons/CheckCircleBlack.svg" alt="" />{" "}
                    {Institute_Undergrad}
                  </div>
                );
              })}
              {/* <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" /> Novartis
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" /> Deloitte
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" />{" "}
                Blackstone-COE
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" />{" "}
                Clearwater
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" /> Times
                Group
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" />{" "}
                Schneider
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" />{" "}
                Moody’s/AKP
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" /> Goldman
                Sachs
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" /> Urban
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" />{" "}
                Moody’s/AKP
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" />{" "}
                Clearwater
              </div>
              <div className="listBoxIcon">
                <img src="/assets/icons/CheckCircleBlack.svg" alt="" /> Sri
                Venkateshwara
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CohortStats;
