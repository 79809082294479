import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  meetingData,
  getMeetingTitleData,
  clearState,
  getMentorStudentsData,
  getMeetingDetailListData,
  updateMeetingDetailsData,
  clearFeedBack,
  cancelledMeeting,
  createMentorAgenda,
  getSpecificMeetingDetailsAgenda,
  clearAgenda,
  createMeetingMaster,
  clearNewMeeting,
} from "../../../reducers/meetingSlice";
import "./MeetingListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import MeetingPopup from "../../../components/Popups/MeetingPopup/MeetingPopup";
import PageLoader from "../../../components/UI/loader/PageLoader";
import MeetingDatePopup from "../../../components/Popups/MeetingDatePopup/MeetingDatePopup";
import MeetFeedbackPopup from "../../../components/Popups/MeetFeedbackPopup/MeetFeedbackPopup";
import moment from "moment";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { data } from "jquery";
import AgendaPopup from "../../../components/Popups/AgendaPopup/AgendaPopup";
import ErrorToast from "../../../components/Toast/ErrorToast";
import MeetingCancelPopup from "../../../components/Popups/MeetingCancelPopup/MeetingCancelPopup";
const MeetingList = () => {
  const {
    isFetching,
    isSuccess,
    isError,
    getMeetingTitleList,
    getMentorStudentsList,
    getMeetingDetailsList,
    meetingFeedBack,
    getAgendaData,
    createMentorAgendaSuccess,
    newMeeting,
  } = useSelector(meetingData);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userId = searchParams.get("userId") ?? "";
  const studentId = searchParams.get("studentId") ?? "";
  // const param = useParams();
  // const userId = param.id;
  console.log(userId, "searchParams");
  console.log(studentId, "searchParamsstudentId");
  const [meetingListData, setMeetingListData] = useState([]);
  const [expiryDate, setExpiryDate] = useState("");
  const [meetingDetails, setMeetingDetail] = useState([]);
  const [mentorListData, setMentorListData] = useState([]);
  const [mentor, setMentor] = useState("");
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [isMeetingPopupOpen, setIsMeetingPopupOpen] = useState(false);
  const [isMeetingDateOpen, setIsMeetingDateOpen] = useState(false);
  const [running, setRunning] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [singleMeetingDetail, setSingleMeetingDetail] = useState(null);
  const [singleFeedBackDetail, setSingleFeedBackDetail] = useState(null);
  const [isMeetFeedbackPopupOpen, setIsMeetFeedbackPopupOpen] = useState(false);
  const [isAgendaPopupOpen, setIsAgendaPopupOpen] = useState(false);
  const [emailDetail, setEmailDetail] = useState("");
  const [isMeetingFeedbackPopupOpen, setIsMeetingFeedbackPopupOpen] =
    useState(false);
  const [meetId, setMeetId] = useState(null);
  const [nextMeetingPopupName, setNextMeetingPopupName] = useState(false);
  const [meetingChat, setMeetingChat] = useState("");
  const [singleAgenda, setSingleAgenda] = useState("");
  const [getAllAgenda, setGetAllAgenda] = useState(null);
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [meetCancelPopupOpen, setMeetCancelPopupOpen] = useState(false);
  const [cancelMeetData, setCancelMeetData] = useState("");
  const [cancelReason, setCancelReason] = useState("");

  const [singleMeetTicket,setSingleMeetTicket]=useState("")
  const [singleMeetTicketOpen,setSingleMeetTicketOpen]=useState(false)
  const [beforeMeetStart, setBeforeMeetStart] = useState(false);

  const utcOffset = moment().utcOffset();
  console.log(utcOffset, "offset");

  const utcOffsetData=mentorListData?.filter((res) => res?.studentId === mentor);
console.log(utcOffsetData,"utcOffsetData")

  useEffect(() => {
    setGetAllAgenda(getAgendaData);
  }, [getAgendaData]);

  const toggleMeetingPopup = () => {
    setIsMeetingPopupOpen(false);
    setRunning(false);
    setMin(0);
    setSec(0);
  };

  const toggleAgendaPopup = () => {
    setIsAgendaPopupOpen(false);
  };

  let curDate = moment(new Date()).format("YYYY-MM-DDTHH:mm");
  console.log(curDate, "curDatee");

  const onMeetingClick = (data) => {
    let currentDateTime = new Date().getTime();
    let newMeet=moment(data?.Date)?.format("YYYY-MM-DDTHH:mm")
    let meetDateTime = new Date(newMeet).getTime();
    let curDates = moment(new Date()).format("YYYY-MM-DDTHH:mm");

    setIsMeetingPopupOpen(true);
    setSingleMeetingDetail(data);
    if (currentDateTime >= meetDateTime) {
      setBeforeMeetStart(false);
      if (data?.meetingLink && data.meetingStatusByMentor === "Approved") {
        setRunning(true);
        setBeforeMeetStart(false);
        let obj = {
          startedBy: "Mentor",
          // userId: getMentorStudentsList && getMentorStudentsList?.User?.id,
          userId: userId,
          meetingMasterId: data.meetingMasterId,
          studentId: mentor,
          startTimeByMentor: curDates,
          utcOffset: utcOffsetData && utcOffsetData[0]?.User?.timezone,
        };
        dispatch(updateMeetingDetailsData(obj));
        // setTimeout(() => {
        //   window.open(data?.meetingLink);
        //   setRunning(true);
        //   setBeforeMeetStart(false)
        //   let obj = {
        //     startedBy: "Mentor",
        //     // userId: getMentorStudentsList && getMentorStudentsList?.User?.id,
        //     userId: userId,
        //     meetingMasterId: data.meetingMasterId,
        //     studentId: mentor,
        //     startTimeByMentor: curDates,
        //     utcOffset:utcOffset
        //   };
        //   dispatch(updateMeetingDetailsData(obj));
        // }, 3000);
      } else if (data.meetingStatusByMentor === "onGoing") {
        setRunning(true);
        let curTime = moment(new Date()).format("HH:mm");
        let dd = data?.startTimeByMentor;
        let ddHours = dd?.slice(0, 2);
        let ddMins = dd?.slice(3, 5);
        let currHours = curTime?.slice(0, 2);
        let currMins = curTime?.slice(3, 5);
        let totalHours = parseInt(currHours) - parseInt(ddHours);
        let totalMinutes = parseInt(currMins) - parseInt(ddMins);
        let result = totalMinutes + totalHours * 60;
        setMin(result);
        setBeforeMeetStart(false);
      }
    }else {
      console.log("else");
      setBeforeMeetStart(true);
      setTimeout(() => {
        toggleMeetingPopup();
      }, 5000);
    }
    setMeetId(data.meetingMasterId);
  };

  const toggleMeetFeedbackPopup = () => {
    setIsMeetFeedbackPopupOpen(false);
  };

  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setSec((prevTime) => prevTime + 1);
        if (sec === 59) {
          setMin((prevTime) => prevTime + 1);
          setSec(0);
        }
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  });

  useEffect(() => {
    let obj = {
      userId: userId,
    };
    dispatch(getMeetingTitleData(obj));
    dispatch(getMentorStudentsData(obj));
  }, []);

  useEffect(() => {
    setMeetingListData(getMeetingTitleList);
    setMentorListData(getMentorStudentsList);
    setMeetingDetail(getMeetingDetailsList);
  }, [getMeetingTitleList, getMentorStudentsList, getMeetingDetailsList]);

  const onDateClick = (res) => {
    setIsMeetingDateOpen(true);
    setMeetingId(res?.id);
  };
  const datePopClose = () => {
    setIsMeetingDateOpen(false);
    setExpiryDate("");
    setNextMeetingPopupName(false);
  };

  const feedBackDetailOpen = (data) => {
    setIsMeetFeedbackPopupOpen(true);
    setSingleFeedBackDetail(data);
    console.log(data, "data");
  };

  useEffect(() => {
    if (getMentorStudentsList?.length === 0) {
      return;
    }
    setMentor(getMentorStudentsList[0]?.studentId);
    let obj = {
      studentId: mentor,
      userId: userId,
    };
    dispatch(getMeetingDetailListData(obj));
  }, [getMentorStudentsList]);

  const handleMentorChange = (e) => {
    let data = e.target.value;
    setMentor(data);
    let obj = {
      studentId: data,
      userId: userId,
    };
    dispatch(getMeetingDetailListData(obj));
    navigate(`/meetingList?userId=${userId}&studentId=${data}`);
  };

  const formDateValidation = () => {
    let status = true;
    if (expiryDate === "") {
      setIsShowError(true);
      setErrMessage("Select Date");
      status = false;
    }
    return status;
  };
  const handleDateSubmit = () => {
    if (!formDateValidation()) return;
    let obj = {
      time: "11:00",
      Date: new Date(expiryDate).toUTCString(),
      startedBy: "Mentor",
      approvedByMentor: true,
      studentId: mentor,
      meetingMasterId: meetingId,
      approvedByStudent: false,
      email: emailDetail,
      userId: userId,
      utcOffset: utcOffsetData && utcOffsetData[0]?.User?.timezone,
    };
    dispatch(updateMeetingDetailsData(obj));
  };

  /**onClikc */
  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };
  const approveClick = (data) => {
    let obj = {
      time: data.time,
      Date: data.Date,
      startedBy: "Mentor",
      approvedByStudent: true,
      // userId: getMentorStudentsList && getMentorStudentsList?.User?.id,
      userId: userId,
      meetingMasterId: data.meetingMasterId,
      approvedByMentor: true,
      studentId: mentor,
      email: emailDetail,
      utcOffset: utcOffsetData && utcOffsetData[0]?.User?.timezone,
      approveClickedByMentor: true,
    };
    dispatch(updateMeetingDetailsData(obj));
  };

  useEffect(() => {
    if (isSuccess) {
      datePopClose();
      dispatch(clearState());
      cancelMeetingHandleClose();
      let obj = {
        studentId: mentor,
        userId: userId,
      };
      dispatch(getMeetingDetailListData(obj));
    }
    let data = mentorListData?.filter((res) => res?.studentId === studentId);
    console.log(mentorListData,"dgfkbkbkhbk")
    if (data?.length > 0) {
      setEmailDetail(data[0]?.Student?.officialEmail);
    }
  }, [isSuccess, getMentorStudentsList, mentorListData, mentor]);

  const onEndMeetingClick = () => {
    setIsMeetingFeedbackPopupOpen(true);
    toggleMeetingPopup();
    let obj = {
      startedBy: "Mentor",
      // userId: getMentorStudentsList && getMentorStudentsList?.User?.id,
      userId: userId,
      meetingMasterId: meetId,
      studentId: mentor,
      endTimeByMentor: curDate,
      utcOffset: utcOffsetData && utcOffsetData[0]?.User?.timezone,
    };
    dispatch(updateMeetingDetailsData(obj));
  };

  useEffect(() => {
    let newmeet =
      meetingDetails[meetingDetails?.length - 1]?.meetingStatusByMentor;
    if (meetingFeedBack) {
      dispatch(clearFeedBack());
      if (newmeet === "Completed") {
        // onDateClick(meetingListData[meetingDetails?.length]);
        setNextMeetingPopupName(true);
      }
    }
  }, [meetingFeedBack]);

  useEffect(() => {
    let newmeet = meetingDetails[meetingDetails?.length - 1];
    let meetingListLength = meetingListData?.length;
    let meetingDetailsLength = meetingDetails?.length;
    if (
      meetingListLength > meetingDetailsLength &&
      newmeet?.meetingStatusByMentor === "Completed" &&
      newmeet?.isFeedbackByMentor
    ) {
      onDateClick(meetingListData[meetingDetails?.length]);
      setNextMeetingPopupName(true);
    }
  }, [meetingDetails, meetingListData]);

  useEffect(() => {
    let data = meetingDetails?.filter(
      (res) => res.isFeedbackByMentor === false
    );
    console.log(data, "false");
    if (
      data?.length > 0 &&
      data[0]?.isFeedbackByMentor === false &&
      data[0]?.meetingStatusByMentor === "Completed"
    ) {
      setIsMeetingFeedbackPopupOpen(true);
      setSingleMeetingDetail(data[0]);
    } else if (
      data?.length > 0 &&
      data[0]?.meetingStatusByMentor === "onGoing"
    ) {
      onMeetingClick(data[0]);
    }
  }, [meetingDetails]);

  let currentDate = new Date().getTime();

  const cancelMeeting = () => {
    if (cancelReason === "") {
      setIsShowError(true);
      setErrMessage("Enter Description");
      return;
    }
    let obj
    if(singleMeetTicketOpen){
      obj = {
        meetingDetailId: cancelMeetData?.id,
        utcOffset: utcOffsetData && utcOffsetData[0]?.User?.timezone,
        ticketDescription: cancelReason,
        ticketRaised:true,
        ticketDate:curDate,
        isCancelled: false,
      };
    }else{
      obj = {
      userId: cancelMeetData?.userId,
      isCancelled: true,
      isFeedbackByMentor: true,
      isFeedbackByStudent: true,
      meetingStatusByMentor: "Completed",
      meetingStatusByStudent: "Completed",
      approvedByMentor: true,
      approvedByStudent: true,
      meetingDetailId: cancelMeetData?.id,
      cancelledBy: cancelMeetData?.userId,
      isCancelledBy: "Mentor",
      utcOffset: utcOffsetData && utcOffsetData[0]?.User?.timezone,
      cancelReason: cancelReason,
    };
  }
    dispatch(cancelledMeeting(obj));
  };

  const openAgendaPopup = (data) => {
    setIsAgendaPopupOpen(true);
    setSingleAgenda(data);
    let obj = {
      userId: data.userId,
      meetingDetailId: data.id,
    };
    dispatch(getSpecificMeetingDetailsAgenda(obj));
  };

  const formAgendaValidation = () => {
    let status = true;
    if (meetingChat === "") {
      setIsShowError(true);
      setErrMessage("Enter Agenda");
      status = false;
    }
    return status;
  };

  const createAgenda = () => {
    if (!formAgendaValidation()) return;
    let obj = {
      // studentId:singleAgenda?.studentId,
      userResponseId: singleAgenda?.userId,
      meetingId: singleAgenda?.meetingMasterId,
      meetingDetailId: singleAgenda?.id,
      reply: meetingChat,
      date: curDate,
      utcOffset: utcOffsetData && utcOffsetData[0]?.User?.timezone,
    };
    dispatch(createMentorAgenda(obj));
  };

  useEffect(() => {
    if (createMentorAgendaSuccess) {
      let obj = {
        userId: singleAgenda.userId,
        meetingDetailId: singleAgenda.id,
      };
      dispatch(getSpecificMeetingDetailsAgenda(obj));
      dispatch(clearAgenda());
      setMeetingChat("");
    }
  }, [createMentorAgendaSuccess]);

  // useEffect(()=>{
  //   let newData=meetingDetails[meetingDetails?.length-1]
  //   if(meetingDetails?.length >0 && isSuccess && newData.approvedByMentor === true && newData.approvedByStudent === false){
  //     openAgendaPopup(newData)
  //   }
  // },[isSuccess])

  const addNewMeeting = () => {
    let obj = {
      title: `Coaching Session ${meetingListData?.length + 1}`,
      displayOrder: meetingListData?.length + 1,
      userId: userId,
      utcOffset: utcOffsetData && utcOffsetData[0]?.User?.timezone,
    };
    dispatch(createMeetingMaster(obj));
  };
  useEffect(() => {
    if (newMeeting) {
      dispatch(clearNewMeeting());
      let obj = {
        userId: userId,
      };
      dispatch(getMeetingTitleData(obj));
    }
  }, [newMeeting]);
  useEffect(() => {
    console.log(studentId, "stu");
    setMentor(studentId);
  }, [mentor]);

  useEffect(() => {
    let newData = meetingDetails && meetingDetails[meetingDetails?.length - 1];
    let curDate = new Date().getTime();
    let newMeet=moment(newData?.Date)?.format("YYYY-MM-DDTHH:mm")
    let oldTime = new Date(newMeet).getTime() + 3600000;
    console.log(newData, "newDatatata");
    if (
      oldTime <= curDate &&
      (newData?.meetingStatusByMentor === "Pending" ||
        newData?.meetingStatusByMentor === "Approved")
    ) {
      // cancelMeeting(newData)

      let obj = {
        userId: newData.userId,
        isFeedbackByMentor: true,
        isFeedbackByStudent: true,
        meetingStatusByMentor: "Completed",
        meetingStatusByStudent: "Completed",
        approvedByMentor: true,
        approvedByStudent: true,
        meetingDetailId: newData.id,
        isTimeLapse: true,
        utcOffset: utcOffsetData && utcOffsetData[0]?.User?.timezone,
      };
      dispatch(cancelledMeeting(obj));
    }
  }, [meetingDetails]);

  const cancelMeetingHandleClose = () => {
    setMeetCancelPopupOpen(false);
    setCancelMeetData("");
    setCancelReason("");
    setSingleMeetTicketOpen(false)
  };

  const toggleCancelMeet = (data) => {
    setMeetCancelPopupOpen(true);
    setCancelMeetData(data);
  };
  const toggleMeetTicket = () => {
    let newData=meetingDetails[meetingDetails?.length-1]
    setMeetCancelPopupOpen(true);
    setSingleMeetTicketOpen(true)
    setCancelMeetData(newData);
  };

  console.log(cancelReason,"cancelReason")
  console.log(singleMeetTicketOpen,"singleMeetTicketOpen")

  return (
    <>
      {isFetching && <PageLoader />}
      <div className="main-body">
        <SideMenu meeting={true} />
        <MeetingPopup
          handleClose={toggleMeetingPopup}
          show={isMeetingPopupOpen}
          // show={true}
          min={min}
          sec={sec}
          setMin={setMin}
          setSec={setSec}
          singleMeetingDetail={singleMeetingDetail}
          running={running}
          isMeetingFeedbackPopupOpen={isMeetingFeedbackPopupOpen}
          setIsMeetingFeedbackPopupOpen={setIsMeetingFeedbackPopupOpen}
          onEndMeetingClick={onEndMeetingClick}
          openAgendaPopup={openAgendaPopup}
          beforeMeetStart={beforeMeetStart}
          toggleMeetTicket={toggleMeetTicket}
        />
        <MeetingDatePopup
          show={isMeetingDateOpen}
          handleClose={datePopClose}
          expiryDate={expiryDate}
          setExpiryDate={setExpiryDate}
          handleDateSubmit={handleDateSubmit}
          nextMeetingPopupName={nextMeetingPopupName}
        />
        <AgendaPopup
          show={isAgendaPopupOpen}
          handleClose={toggleAgendaPopup}
          meetingChat={meetingChat}
          setMeetingChat={setMeetingChat}
          createAgenda={createAgenda}
          getAllAgenda={getAllAgenda}
        />
        <MeetFeedbackPopup
          show={isMeetFeedbackPopupOpen}
          handleClose={toggleMeetFeedbackPopup}
          singleFeedBackDetail={singleFeedBackDetail}
          userId={userId}
          utcOffset={utcOffsetData && utcOffsetData[0]?.User?.timezone}
        />
        <MeetingCancelPopup
          show={meetCancelPopupOpen}
          cancelMeetingHandleClose={cancelMeetingHandleClose}
          cancelMeetSubmit={cancelMeeting}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
          singleMeetTicketOpen={singleMeetTicketOpen}
        />

        <ErrorToast
          show={isShowError}
          setShow={() => setIsShowError(false)}
          message={errMessage}
        />
        <div className="page-header">
          <p className="component-heading">One On One Coaching</p>
          
          <div className="sos-flexbox">
            <button className="new-sos-btn" onClick={toggleMeetTicket}>SOS</button>
            <button className="approve-btn" onClick={() => window.open("https://cdn.mastersunion.org/assets/coach_coachee_pdf/Coach.pdf")}>
              <img src="../assets/icons/tickmark.svg" alt="" /> Download Lab Manual</button>
          </div>

        </div>

        <div className="filter-bar">
          <div className="mentor-details">
            {mentorListData &&
              mentorListData?.map((res, index) => (
                <label className="create-label" key={index}>
                  <input
                    className="radio-btn"
                    type="radio"
                    value={res?.Student && res?.Student?.id}
                    checked={mentor === res?.Student?.id}
                    onChange={handleMentorChange}
                    key={index}
                  />
                  <span className="radiomark text-capitalize"></span>
                  {res.Student && res.Student.firstName}{" "}
                  {res.Student && res.Student.lastName}
                </label>
              ))}
          </div>
        </div>
        <div className="feedback-container scrollbar meetingListContainer">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr className="p-20">
                <th>Session Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Availability</th>
                <th>Session Status</th>
                <th>Cancel Reason</th>
                <th>SOS Raised</th>
                <th>SOS Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {meetingListData &&
                meetingListData?.map((res, index) => (
                  <tr key={index}>
                    <td>{res.title}</td>
                    {meetingDetails &&
                      meetingDetails
                        .filter((row) => row.meetingMasterId === res.id)
                        .map((data, i) => (
                          <React.Fragment key={i}>
                            <td>
                              <div className="edit-date">
                                {moment(data.Date).format("DD-MMMM-YYYY")}
                                {data.approvedByMentor === true &&
                                data.approvedByStudent === true ? (
                                  ""
                                ) : (
                                  <img
                                    src="../../assets/icons/edit.svg"
                                    alt=""
                                    onClick={() => onDateClick(res)}
                                  />
                                )}
                              </div>
                            </td>
                            {/* <td>{data.time}</td> */}
                            <td>
                            {moment(data?.Date)?.format("hh:mm A")}
                            </td>
                            
                            <td>
                              {data.meetingStatusByMentor === "Pending" &&
                                data.approvedByMentor === false &&
                                data.approvedByStudent === true && (
                                  <button
                                    className="approve-btn"
                                    onClick={() => approveClick(data)}
                                  >
                                    <img
                                      src="../assets/icons/tickmark.svg"
                                      alt=""
                                    />{" "}
                                    Approve
                                  </button>
                                )}

                              {data.isTimeLapse === true &&
                              data?.startTimeByMentor === null ? (
                                <p className="text-danger stripe">
                                  Time Lapsed
                                </p>
                              ) : (
                                data.approvedByMentor === true &&
                                data.approvedByStudent === true && (
                                  <p
                                    className={
                                      data.isCancelled
                                        ? "text-danger stripe"
                                        : "text-success"
                                    }
                                  >
                                    Approved
                                  </p>
                                )
                              )}
                              {data.meetingStatusByMentor === "Pending" &&
                                data.approvedByStudent === false &&
                                "---"}
                            </td>
                            <td>
                              {data.meetingStatusByMentor === "Pending" &&
                                data.meetingStatusByMentor}
                              {
                                // currentDate >= new Date(data.Date).getTime() &&
                                data.meetingStatusByMentor === "Approved" && (
                                  <button
                                    className="primary-btn-a"
                                    onClick={() => onMeetingClick(data)}
                                  >
                                    Start Session
                                  </button>
                                )
                              }

                              {data.isTimeLapse === true &&
                              data?.startTimeByMentor === null ? (
                                <p className="text-danger stripe">
                                  Time Lapsed
                                </p>
                              ) : (
                                data.meetingStatusByMentor === "Completed" &&
                                data.isCancelled === false &&
                                data.meetingStatusByMentor
                              )}
                              {data.isCancelled === true && (
                                <p className="text-danger stripe">
                                  Cancelled By{" "}
                                  {data?.isCancelledBy === "Mentor"
                                    ? "Coach"
                                    : "Coachee"}
                                </p>
                              )}
                            </td>
                            <td>
                              {data.cancelReason ? data.cancelReason : "---"}
                            </td>
                            <td>
                              {data.ticketRaised ? "Yes" : "No"}
                            </td>
                            <td>
                              {data.ticketDescription ? data.ticketDescription : "---"}
                            </td>
                            <td>
                              <button
                                className="action-btn"
                                onClick={() => openActionMenu(index)}
                                onBlur={closeActionMenu}
                              >
                                <img src="../assets/icons/options.svg" />
                                <ul
                                  // className="action-dropdown"
                                  className={
                                    menuId === index
                                      ? "action-dropdown active"
                                      : "action-dropdown"
                                  }
                                >
                                  {data?.MeetFeedbacks?.length > 0 &&
                                  data?.MeetFeedbacks?.filter(
                                    (dd) => dd.feedbackFor === "Student"
                                  )?.length > 0 ? (
                                    <li
                                      className="dropdown-item"
                                      onClick={() => feedBackDetailOpen(data)}
                                    >
                                      <img
                                        src="../assets/icons/preview.svg"
                                        alt=""
                                      />
                                      <p className="dropdown-text">
                                        Feedback Detail
                                      </p>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  <li
                                    className="dropdown-item"
                                    onClick={() => openAgendaPopup(data)}
                                  >
                                    <img
                                      src="../assets/icons/plus-black.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">Agenda</p>
                                  </li>
                                  {data.isCancelled === true ||
                                  data.meetingStatusByMentor === "Completed" ? (
                                    ""
                                  ) : (
                                    <li
                                      className="dropdown-item"
                                      // onClick={() => cancelMeeting(data)}
                                      onClick={() => toggleCancelMeet(data)}
                                    >
                                      <img
                                        src="../assets/icons/delete.svg"
                                        alt=""
                                      />
                                      <p className="dropdown-text text-danger">
                                        Cancel Session
                                      </p>
                                    </li>
                                  )}
                                </ul>
                              </button>
                            </td>
                          </React.Fragment>
                        ))}
                    {((meetingDetails?.length === 0 && index === 0) ||
                      (meetingListData?.length !== meetingDetails &&
                        index === meetingDetails?.length &&
                        meetingDetails[meetingDetails?.length - 1]
                          ?.meetingStatusByMentor === "Completed")) && (
                      <React.Fragment>
                        <td>
                          <div className="edit-date">
                            {/* {moment(data.Date).format("DD-MMMM-YYYY")} */}
                            ---
                            <img
                              src="../../assets/icons/edit.svg"
                              alt=""
                              onClick={() => onDateClick(res)}
                            />
                          </div>
                        </td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td></td>
                      </React.Fragment>
                    )}
                  </tr>
                ))}
              <tr>
                <td>
                  <button
                    className="green-btn optional-green-btn"
                    onClick={addNewMeeting}
                  >
                    + Add Session
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MeetingList;
