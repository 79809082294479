import React from "react";
import SideMenu from "../../../components/Layout/menu/SideMenu";
function ProgramList() {
  return (
    <>
      <SideMenu />
      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">All Programs</p>
          <button className="primary-btn feed-btn">
            <img src="../assets/icons/plus.svg" alt="" /> Add Program
          </button>
        </div>
        <div className="filter-bar">
          <form action="" className="search-bar">
            <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Search"
              className="clean-input"
            />
          </form>
          <div className="filters">
            <select id="type">
              <option value="">All Feedback</option>
              <option value="Class">Class</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
            <select id="type">
              <option value="">All Status</option>
              <option value="expire">Expired</option>
              <option value="draft">Draft</option>
              <option value="active">Active</option>
            </select>
            <select id="type">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div className="feedback-container scrollbar">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Program name</th>
                <th>Batch</th>
                <th>Number of Clusters</th>
                <th>Durations</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  PGP TBM
                  {/* <p className="primary-text pointer">PGP TBM</p> */}
                </td>
                <td>Cohort1</td>
                <td>05</td>
                <td>16 Months</td>

                <td className="text-center">
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                  </button>
                  <ul className="action-dropdown active">
                    <li className="dropdown-item">
                      <img src="../assets/icons/table.svg" alt="" />
                      <p className="dropdown-text">Bidding Request</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/table.svg" alt="" />
                      <p className="dropdown-text">View Request</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/message.svg" alt="" />
                      <p className="dropdown-text">Term Course Count</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/message.svg" alt="" />
                      <p className="dropdown-text">Add Course</p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ProgramList;
