import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let adminId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};

export const getOrganizationProgramsWithBatch = createAsyncThunk(
  "fee/organizatonProgramsWithBatch",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/organizationProgramsWithBatch",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getOrganizationPrograms = createAsyncThunk(
  "fee/organizatonPrograms",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/organizationPrograms",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllBatchByProgramId = createAsyncThunk(
  "fee/getAllBatchByProgramId",
  async (programId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/getAllBatchByProgramId?programId=" +
          programId,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getSingleBatchByProgramId = createAsyncThunk(
  "fee/getSingleBatchByProgramId",
  async (programId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/getAllBatchByProgramId?programId=" +
          programId,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addProgramAndFeeDetails = createAsyncThunk(
  "fee/addProgramAndFeeDetails",
  async (feeObj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/addProgramAndFeeDetails",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(feeObj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFeeTypes = createAsyncThunk(
  "fee/getFeeTypes",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/getFeeTypes",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProgramFeeDetails = createAsyncThunk(
  "fee/getProgramFeeDetails",
  async (obj, thunkAPI) => {
    try {
      let programId = obj.programMasterId;
      let batchId = obj.programBatchId;
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programMasterId/" +
          programId +
          "/programId/" +
          batchId +
          "/getProgramAndFeeDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const editFeeDetails = createAsyncThunk(
  "fee/editFeeDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/editFeeDetails",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteFeeDetails = createAsyncThunk(
  "fee/deleteFeeDetails",
  async (obj, thunkAPI) => {
    try {
      let feeId = obj.feeId;
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/feeId/" +
          feeId +
          "/deleteFeeDetails",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getParticularProgramFeeTypes = createAsyncThunk(
  "fee/getParticularProgramFeeTypes",
  async (obj, thunkAPI) => {
    try {
      let batchId = obj.programBatchId;
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          "/getParticularProgramFeeTypes",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// /:orgId/programId/:programId/feeId/:feeId/getStudentByFeeType
export const getStudentFeeList = createAsyncThunk(
  "fee/getStudentFeeList",
  async (obj, thunkAPI) => {
    try {
      let batchId = obj.programBatchId;
      let feeId = obj.feeId;
      let feeMasterId = obj.feeMasterId;
      console.log(obj, "objobj");
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          "/feeId/" +
          feeId +
          "/feeMasterId/" +
          feeMasterId +
          "/getStudentByFeeType",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**GET COURSE FEE STUDENT LIST */

export const getCourseFeeStudentList = createAsyncThunk(
  "fee/getCourseFeeStudentList",
  async ({ obj, params }, thunkAPI) => {
    try {
      var search = params?.searchTitle ?? "";
      var balance = params?.balance ?? "";
      var dueStatus = params?.dueStatus ?? "";
      var requestType = params?.requestType ?? "";
      var pageSize = params?.pageSize ?? "10";
      var pageNo = params?.pageNo ?? "1";
      var welcomeStatus = params?.welcomeStatus ?? "";
      var studentStatus = params?.studentStatus ?? "";
      var balanceStatus = params?.balanceStatus ?? "";
      var feeDueStartDate = params?.feeDueStartDate ?? "";
      var feeDueEndDate = params?.feeDueEndDate ?? "";
      var bankLoan = params?.bankLoan ?? "";
      var descending = params?.descending ?? "";
      var date = params?.date ?? "";

      let batchId = obj.programBatchId;
      console.log(obj, "objobj");
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          `/getProgramFeeTransaction?searchTitle=${search}&balance=${balance}&dueStatus=${dueStatus}&requestType=${requestType}&pageSize=${pageSize}&pageNo=${pageNo}&welcomeStatus=${welcomeStatus}&studentStatus=${studentStatus}&balanceStatus=${balanceStatus}&feeDueStartDate=${feeDueStartDate}&feeDueEndDate=${feeDueEndDate}&bankLoan=${bankLoan}&descending=${descending}&date=${date}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /:orgId/feeId/:feeId/student/:studentId/getParticularStudentTransactionDetails
export const getSingleStudentFeeList = createAsyncThunk(
  "fee/getSingleStudentFeeList",
  async (obj, thunkAPI) => {
    try {
      let programBatchId = obj.programBatchId;
      let studentId = obj.studentId;
      console.log(obj, "objobj");
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programBatch/" +
          programBatchId +
          "/student/" +
          studentId +
          "/getParticularStudentTransactionDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//getStudentsPendingFeeDues
export const getStudentsPendingFeeDues = createAsyncThunk(
  "fee/getStudentsPendingFeeDues",
  async ({ searchTitle, fromDate, toDate }, thunkAPI) => {
    console.log(fromDate, "fromDate");
    console.log("toDate", toDate);
    try {
      setTokenValues();
      var search = searchTitle ?? "";
      var organizationId = orgId ?? "";

      const response = await fetch(
        `http://localhost:2001/api/org/${organizationId}/getStudentsPendingFeeDues?fromDate=${fromDate}&toDate=${toDate}&searchTitle=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//searchApplicant
export const searchApplicant = createAsyncThunk(
  "fee/searchApplicant",
  async ({ searchTitle, name }, thunkAPI) => {
    console.log(name, "name");
    try {
      setTokenValues();
      var search = name ?? "";
      const response = await fetch(
        `http://localhost:2001/api/org/searchApplicant?name=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//Extend Fee Due
// /:organizationId/studentId/:studentId/extendFeeDueDate feeDueID,date
export const extendFeeDueDate = createAsyncThunk(
  "fee/extendFeeDueDate",
  async (obj, thunkAPI) => {
    console.log(obj, "obj");
    const studentId = obj.singleStudentId;
    const objData = {
      feeDueID: obj.feeDueID,
      date: obj.dueDate,
    };
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/studentId/" +
          studentId +
          "/extendFeeDueDate",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(objData),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const verifyUTRReciept = createAsyncThunk(
  "fee/verifyUTRReciept",
  async (obj, thunkAPI) => {
    const objData = {
      status: obj.status,
      feeDueId: obj.feeDueId,
      studentId: obj.studentId,
    };
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/studentId/" +
          objData.studentId +
          "/verifyUTRReciept",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(objData),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// /:organizationId/admin/:adminId/addUTRTransaction
export const addUTRTransaction = createAsyncThunk(
  "fee/addUTRTransaction",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/addUTRTransaction",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const approveLoanRequest = createAsyncThunk(
  "fee/approveLoanRequest",
  async (obj, thunkAPI) => {
    const objData = {
      isLoanApproved: obj.isLoanApproved,
      studentId: obj.studentId,
    };
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/feeDueId/" +
          obj.feeDueId +
          "/approveLoanRequest",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(objData),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getStudentLoanDocuments = createAsyncThunk(
  "fee/getStudentLoanDocuments",
  async (obj, thunkAPI) => {
    const objData = {
      isDocumentResponse: obj.isDocumentResponse,
    };
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/feeDueId/" +
          obj.feeDueId +
          "/getStudentLoanDocuments",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(objData),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const applyForLoan = createAsyncThunk(
  "fee/applyForLoan",
  async (feeDueId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/feeDueId/" +
          feeDueId +
          "/applyForLoan",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateBankResponse = createAsyncThunk(
  "fee/updateBankResponse",
  async (obj, thunkAPI) => {
    const objData = {
      bankName: obj.bankName,
      loanAmt: obj.loanAmt,
      studentId: obj.studentId,
      feeId: obj.feeDueId,
      status: obj.status,
      transactionNumber: obj.transactionNumber,
      programName: obj.programName,
      programId: obj.programId,
      subventionAmt: obj.subventionAmt,
    };

    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/feeDueId/" +
          obj.feeDueId +
          "/updateBankResponse",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(objData),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteParticularFeeDue = createAsyncThunk(
  "fee/deleteParticularFeeDue",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/adminId/" +
          adminId +
          "/feeDueId/" +
          obj.feeDueId +
          "/deleteParticularFeeDue",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sendFeeDueEmail = createAsyncThunk(
  "fee/sendFeeDueEmail",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/sendFeeDuesToStudents",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getProgramFeeTransaction = createAsyncThunk(
  "fee/getProgramFeeTransaction",
  async (obj, thunkAPI) => {
    try {
      let batchId = obj.programBatchId;
      let feeId = obj?.feeId ?? null;
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          `/getProgramFeeTransaction?feeId=${feeId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateFeeManually = createAsyncThunk(
  "fee/updateFeeManually",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/feeDueId/" +
          obj?.feeDueId +
          `/updateFeeManually`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postUpdateTransactionType = createAsyncThunk(
  "fee/postUpdateTransactionType",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/feeDueId/" +
          obj?.feeDueId +
          `/updateTransactionType`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postSubventionDetails = createAsyncThunk(
  "fee/postSubventionDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          `/updateSubventionLoanDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getParticularStudentFeeDues = createAsyncThunk(
  "fee/getParticularStudentFeeDues",
  async (obj, thunkAPI) => {
    try {
      let studentId = obj.studentId;
      let programBatchId = obj.programBatchId;
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programBatch/" +
          programBatchId +
          "/student/" +
          studentId +
          "/getStudentAllFeeDues",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postEditFeeDueAmount = createAsyncThunk(
  "fee/postEditFeeDueAmount",
  async (obj, thunkAPI) => {
    const objData = {
      amount: obj?.amount,
      programId: obj?.programId,
      studentId: obj?.studentId,
      dueOn: obj?.dueOn,
    };
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/feeDueId/" +
          obj?.feeDueId +
          "/editFeeDueWithoutMail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(objData),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postCreateFeeDueAmount = createAsyncThunk(
  "fee/postCreateFeeDueAmount",
  async (obj, thunkAPI) => {
    const objData = {
      amount: obj?.amount,
      programId: obj?.programId,
      studentId: obj?.studentId,
      dueOn: obj?.dueOn,
      feeDueSentBy: obj?.feeDueSentBy,
      feeMasterId: obj?.feeMasterId,
    };
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/generateNewFeeDue",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(objData),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postUpdateStudentStatus = createAsyncThunk(
  "fee/postUpdateStudentStatus",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/updateStudentStatus",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postUpdatePaymentMethod = createAsyncThunk(
  "fee/postUpdatePaymentMethod",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/addStudentPaymentMethodTransactionWise",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postUpdateReceiptNumber = createAsyncThunk(
  "fee/postUpdateReceiptNumber",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/updateTransactionNumber",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// http://localhost:2001/api/org/:organizationId/getDailyTransactionUpdates

export const getDailyTransactionUpdates = createAsyncThunk(
  "fee/getDailyTransactionUpdates",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      var search = obj?.searchTitle ?? "";
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          `/getDailyTransactionUpdates?searchTitle=${search}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const summaryGetAllProgramList = createAsyncThunk(
  "fee/summaryGetAllProgramList",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + `/getProgramList`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProgramSummaryDetailsPart1 = createAsyncThunk(
  "fee/getProgramSummaryDetailsPart1",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programMasterId/" +
          obj?.programId +
          "/programId/" +
          obj?.batchId +
          `/getSummary`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProgramSummaryDetailsPart2 = createAsyncThunk(
  "fee/getProgramSummaryDetailsPart2",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programMasterId/" +
          obj?.programId +
          "/programId/" +
          obj?.batchId +
          `/getSummary2`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProgramSummaryDetailsPart3 = createAsyncThunk(
  "fee/getProgramSummaryDetailsPart3",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programMasterId/" +
          obj?.programId +
          "/programId/" +
          obj?.batchId +
          `/getSummary3`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getCounsellorReport = createAsyncThunk(
  "fee/getCounsellorReport",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/program/" +
          obj?.batchId +
          `/getCounsellorApplicantsInfo`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**GET COURSE FEE STUDENT LIST */

export const getStudentLoanRequests = createAsyncThunk(
  "fee/getStudentLoanRequests",
  async ({ obj, params }, thunkAPI) => {
    try {
      var search = params?.searchTitle ?? "";
      // var balance = params?.balance ?? "";
      // var dueStatus = params?.dueStatus ?? "";
      // var requestType = params?.requestType ?? "";
      // var pageSize = params?.pageSize ?? "10";
      // var pageNo = params?.pageNo ?? "1";
      // var welcomeStatus = params?.welcomeStatus ?? "";
      // var studentStatus = params?.studentStatus ?? "";
      // var balanceStatus = params?.balanceStatus ?? "";
      // var feeDueStartDate = params?.feeDueStartDate ?? "";
      // var feeDueEndDate = params?.feeDueEndDate ?? "";
      // var bankLoan = params?.bankLoan ?? "";
      // var descending = params?.descending ?? "";
      // var date = params?.date ?? "";

      // ?searchTitle=${search}&balance=${balance}&dueStatus=${dueStatus}&requestType=${requestType}&pageSize=${pageSize}&pageNo=${pageNo}&welcomeStatus=${welcomeStatus}&studentStatus=${studentStatus}&balanceStatus=${balanceStatus}&feeDueStartDate=${feeDueStartDate}&feeDueEndDate=${feeDueEndDate}&bankLoan=${bankLoan}&descending=${descending}&date=${date}`,

      let batchId = obj.programBatchId;
      console.log(obj, "objobj");
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          `/getApplicantsBankLoanRequest?searchTitle=${search}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getParticularStudentTimeLine = createAsyncThunk(
  "fee/getParticularStudentTimeLine",
  async (applicantId, thunkAPI) => {
    try {
      setTokenValues();

      console.log("checkign object data", applicantId);

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/applicantId/" +
          applicantId +
          `/getApplicantTimeline`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getStudentFlexDetails = createAsyncThunk(
  "fee/getStudentFlexDetails",
  async (
    { programBatchId, searchTitle, programMasterId, downloadCsv },
    thunkAPI
  ) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/program/" +
          programBatchId +
          "/programMasterId/" +
          programMasterId +
          `/getStudentFlexDetails?searchParam=${searchTitle}&downloadCsv=${downloadCsv}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getParticularStudentFlexDetails = createAsyncThunk(
  "fee/getParticularStudentFlexDetails",
  async (studentId, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/student/" +
          studentId +
          `/getAllFlexDetails`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getParticularStudentNotesFollowUp = createAsyncThunk(
  "fee/getParticularStudentNotesFollowUp",
  async (applicantId, thunkAPI) => {
    try {
      setTokenValues();

      console.log("checkign object data", applicantId);

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/applicantId/" +
          applicantId +
          `/getApplicantNotes`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postAddTimeLineNote = createAsyncThunk(
  "fee/postAddTimeLineNote",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const objData = {
        note: obj?.note,
        comment: obj?.comment,
        isFollowUp: obj?.isFollowUp,
        date: obj?.date,
        programId: obj?.programId,
        applicantId: obj?.applicantId,
        adminId: adminId,
        followerName: obj?.followerName,
      };

      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + `/addNote`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(objData),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProgramSummaryDetail = createAsyncThunk(
  "fee/getProgramSummaryDetail",
  async (programId, thunkAPI) => {
    try {
      setTokenValues();

      console.log("checkign object data", programId);

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          programId +
          `/getProgramApplicantsSummary`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getOrganizationInterviewerList = createAsyncThunk(
  "fee/getOrganizationInterviewerList",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/getInterviewersList",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postAddInterviewerToProgram = createAsyncThunk(
  "fee/postAddInterviewerToProgram",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/saveInterviewersProgram",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getInterviewersByProgram = createAsyncThunk(
  "fee/getInterviewersByProgram",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/getInterviewersByProgram",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProgramWiseInterviewerList = createAsyncThunk(
  "fee/getProgramWiseInterviewerList",
  async (programMasterId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/program/" +
          programMasterId +
          "/getProgramWiseAdmin",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getInterviewerSlots = createAsyncThunk(
  "fee/getInterviewerSlots",
  async ({ interviewerId, date }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          interviewerId +
          `/getAdminInterviewSlots?date=${date}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postAddInterviewerPriorities = createAsyncThunk(
  "fee/postAddInterviewerPriorities",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/addInterviewerPriorities",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postAddInterviewSlotReminder = createAsyncThunk(
  "fee/postAddInterviewSlotReminder",
  async ({ interviewerId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          interviewerId +
          "/addSlotReminder",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({}),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getInterviewerSlotsSummary = createAsyncThunk(
  "fee/getInterviewerSlotsSummary",
  async (programMasterId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/getInterviewersSlotsSummary",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data.Data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProgramInterviewersSummary = createAsyncThunk(
  "fee/getProgramInterviewersSummary",
  async (programId, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/program/" +
          programId +
          `/interviewersSummary`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** API for getting student/applicant profile */
/**START */
export const getStudentProfileDetails = createAsyncThunk(
  "fee/getStudentProfileDetails",
  async (studentId, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/applicant/" +
          studentId +
          `/getApplicantdetails`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**END */

/**MENTOR CALL API FOR POST/GET/PUT */
/**START */

export const postAddMentorForInterviewToProgram = createAsyncThunk(
  "fee/postAddMentorForInterviewToProgram",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/savementorshipCallInterviewersProgram",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getMentorCallInterviewersByProgram = createAsyncThunk(
  "fee/getMentorCallInterviewersByProgram",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/getmentorshipCallInterviewersByProgram",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getProgramWiseMentorCallList = createAsyncThunk(
  "fee/getProgramWiseMentorCallList",
  async (programMasterId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/program/" +
          programMasterId +
          "/getmentorshipCallProgramWiseAdmin",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getOrganizationMentorList = createAsyncThunk(
  "fee/getOrganizationMentorList",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/getMentorsList",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**END */

export const summaryGetCohortStats = createAsyncThunk(
  "fee/summaryGetCohortStats",
  async (programId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/program/" +
          programId +
          `/cohortStats`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getStudentRevenueCouncillorWise = createAsyncThunk(
  "fee/getStudentRevenueCouncillorWise",
  async ({ programBatchId, councillorName }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/program/" +
          programBatchId +
          `/getStudentDetailsCouncillorWise?councillorName=${councillorName}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const feeSlice = createSlice({
  name: "feeDetail",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    programNameList: [],
    programList: [],
    feeTypes: [],
    feeDetailsData: {},
    editFeeDetail: {},
    isSubmitted: false,
    programDetailId: "",
    isComplete: false,
    programFeeTypeList: null,
    studentFeeListData: [],
    singleStudentFeeListData: null,
    extendDueDateSuccess: false,
    addUTRTransactionSuccess: false,
    sendFeeDueSuccess: false,
    programFeeTransactionData: null,
    courseStudentListData: [],
    studentFeeDuesList: null,
    editFeeDueSuccess: false,
    deleteFeeDueSuccess: false,
    createFeeDueSuccess: false,
    updateStatusSuccess: false,
    receiptUpdatedSuccess: false,
    transactionData: [],
    updateSubventionSuccess: false,
    transactionTypeSuccess: false,
    studentCount: null,
    summaryProgramList: [],
    programSummaryDataPart1: {},
    programSummaryDataPart2: {},
    programSummaryDataPart3: {},
    singleProgramBatch: [],
    FeeDueList: [],
    ApplicantList: [],
    FeeDataCount: 0,
    studentLoanRequestList: [],
    counsellorReportData: [],
    studentTimelineData: null,
    studentFollowupNotes: null,
    isTimeLineNotePublished: false,
    applicantProfileDetail: null,
    programSummaryData: null,
    flexList: [],
    allInterviewerList: [],
    interviewProgramList: [],
    programWiseInterviewerList: [],
    adminInterviewSlots: [],
    programAvailableSlots: "",
    interviewerSlotsSummaryList: [],
    programInterviewersSummary: null,
    studentProfileData: null,
    studentFlexList: [],
    cohortStatsData: null,
    studentCounsellorWise: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.editFeeDueSuccess = false;
      state.deleteFeeDueSuccess = false;
      state.createFeeDueSuccess = false;
      state.updateStatusSuccess = false;
      state.updateSubventionSuccess = false;
      state.transactionTypeSuccess = false;
      state.programSummaryDataPart1 = {};
      state.programSummaryDataPart2 = {};
      state.programSummaryDataPart3 = {};
      state.isTimeLineNotePublished = false;
      state.receiptUpdatedSuccess = false;
      state.studentFlexList = [];
      return state;
    },
    clearComplete: (state) => {
      state.isComplete = false;
    },
    clearExtendDate: (state) => {
      state.extendDueDateSuccess = false;
    },
    clearTransaction: (state) => {
      state.addUTRTransactionSuccess = false;
    },
    clearFeeDue: (state) => {
      state.sendFeeDueSuccess = false;
    },
    clearCounsellorData: (state) => {
      state.studentCounsellorWise = [];
    },
  },
  extraReducers: {
    [getOrganizationProgramsWithBatch.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.programNameList = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getOrganizationProgramsWithBatch.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getOrganizationProgramsWithBatch.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getOrganizationPrograms.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.programList = payload;
      state.isFetching = false;
      // state.isSuccess = true;
      return state;
    },
    [getOrganizationPrograms.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getOrganizationPrograms.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getAllBatchByProgramId.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.batchDetails = payload;
      // state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getAllBatchByProgramId.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      // state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllBatchByProgramId.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getSingleBatchByProgramId.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.singleProgramBatch = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getSingleBatchByProgramId.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSingleBatchByProgramId.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    //feedueList
    [getStudentsPendingFeeDues.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.FeeDueList = payload;
      state.FeeDataCount = payload.Count;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getStudentsPendingFeeDues.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getStudentsPendingFeeDues.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    //searchApplicant
    [searchApplicant.fulfilled]: (state, { payload }) => {
      state.ApplicantList = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [searchApplicant.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [searchApplicant.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [addProgramAndFeeDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.isComplete = true;
      state.programDetailId = payload.programId;
      return state;
    },
    [addProgramAndFeeDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addProgramAndFeeDetails.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getFeeTypes.fulfilled]: (state, { payload }) => {
      console.log("printing payload feeTypes ", payload);
      state.feeTypes = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getFeeTypes.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getFeeTypes.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getProgramFeeDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload feeTypes ", payload);
      state.feeDetailsData = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getProgramFeeDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramFeeDetails.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [editFeeDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isComplete = true;
      // state.isEdited = true;
      return state;
    },
    [editFeeDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [editFeeDetails.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [deleteFeeDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isComplete = true;
      // state.isEdited = true;
      return state;
    },
    [deleteFeeDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [deleteFeeDetails.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getParticularProgramFeeTypes.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.programFeeTypeList = payload;
      // state.isFetching = false;
      // state.isSuccess = true;
      // state.isComplete = true;
      // state.isEdited = true;
      return state;
    },
    [getParticularProgramFeeTypes.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getParticularProgramFeeTypes.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getStudentFeeList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.studentFeeListData = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getStudentFeeList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getStudentFeeList.pending]: (state) => {
      state.isFetching = true;
    },

    [getCourseFeeStudentList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.courseStudentListData = payload;
      state.studentCount = payload?.Count;
      state.isFetching = false;
      return state;
    },
    [getCourseFeeStudentList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getCourseFeeStudentList.pending]: (state) => {
      state.isFetching = true;
    },

    [getSingleStudentFeeList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.singleStudentFeeListData = payload;
      state.isFetching = false;
      return state;
    },
    [getSingleStudentFeeList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSingleStudentFeeList.pending]: (state) => {
      state.isFetching = true;
    },
    [extendFeeDueDate.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.extendDueDateSuccess = true;
      // state.isEdited = true;
      return state;
    },
    [extendFeeDueDate.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [extendFeeDueDate.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [verifyUTRReciept.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      // state.isEdited = true;
      return state;
    },
    [verifyUTRReciept.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [verifyUTRReciept.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [addUTRTransaction.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.addUTRTransactionSuccess = true;
      return state;
    },
    [addUTRTransaction.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addUTRTransaction.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [approveLoanRequest.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [approveLoanRequest.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [approveLoanRequest.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getStudentLoanDocuments.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getStudentLoanDocuments.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getStudentLoanDocuments.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [applyForLoan.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [applyForLoan.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [applyForLoan.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [updateBankResponse.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [updateBankResponse.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateBankResponse.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [deleteParticularFeeDue.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [deleteParticularFeeDue.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [deleteParticularFeeDue.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [sendFeeDueEmail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.sendFeeDueSuccess = true;
      return state;
    },
    [sendFeeDueEmail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [sendFeeDueEmail.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [getProgramFeeTransaction.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.programFeeTransactionData = payload;
      return state;
    },
    [getProgramFeeTransaction.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramFeeTransaction.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [updateFeeManually.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [updateFeeManually.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateFeeManually.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [postUpdateTransactionType.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.transactionTypeSuccess = true;
      return state;
    },
    [postUpdateTransactionType.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postUpdateTransactionType.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [getParticularStudentFeeDues.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.studentFeeDuesList = payload;
      state.isFetching = false;
      return state;
    },
    [getParticularStudentFeeDues.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getParticularStudentFeeDues.pending]: (state) => {
      state.isFetching = true;
    },

    [postEditFeeDueAmount.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.editFeeDueSuccess = true;
      return state;
    },
    [postEditFeeDueAmount.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postEditFeeDueAmount.pending]: (state) => {
      state.isFetching = true;
    },

    [postCreateFeeDueAmount.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.createFeeDueSuccess = true;
      return state;
    },
    [postCreateFeeDueAmount.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postCreateFeeDueAmount.pending]: (state) => {
      state.isFetching = true;
    },

    [postUpdateStudentStatus.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.updateStatusSuccess = true;
      return state;
    },
    [postUpdateStudentStatus.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postUpdateStudentStatus.pending]: (state) => {
      state.isFetching = true;
    },

    [postUpdatePaymentMethod.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.updateStatusSuccess = true;
      return state;
    },
    [postUpdatePaymentMethod.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postUpdatePaymentMethod.pending]: (state) => {
      state.isFetching = true;
    },

    [getDailyTransactionUpdates.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.transactionData = payload;
      state.isFetching = false;
      return state;
    },
    [getDailyTransactionUpdates.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getDailyTransactionUpdates.pending]: (state) => {
      state.isFetching = true;
    },

    [postSubventionDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.updateSubventionSuccess = true;
      return state;
    },
    [postSubventionDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postSubventionDetails.pending]: (state) => {
      state.isFetching = true;
    },

    [summaryGetAllProgramList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.summaryProgramList = payload;
      // state.isFetching = false;
      return state;
    },
    [summaryGetAllProgramList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [summaryGetAllProgramList.pending]: (state) => {
      state.isFetching = true;
    },

    [getProgramSummaryDetailsPart1.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.programSummaryDataPart1 = payload;
      state.isFetching = false;
      return state;
    },
    [getProgramSummaryDetailsPart1.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramSummaryDetailsPart1.pending]: (state) => {
      state.isFetching = true;
    },

    [getProgramSummaryDetailsPart2.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.programSummaryDataPart2 = payload;
      state.isFetching = false;
      return state;
    },
    [getProgramSummaryDetailsPart2.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramSummaryDetailsPart2.pending]: (state) => {
      state.isFetching = true;
    },

    [getProgramSummaryDetailsPart3.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.programSummaryDataPart3 = payload;
      state.isFetching = false;
      return state;
    },
    [getProgramSummaryDetailsPart3.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramSummaryDetailsPart3.pending]: (state) => {
      state.isFetching = true;
    },

    [getStudentLoanRequests.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.studentLoanRequestList = payload;
      state.isFetching = false;
      return state;
    },
    [getStudentLoanRequests.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getStudentLoanRequests.pending]: (state) => {
      state.isFetching = true;
    },

    [getCounsellorReport.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.counsellorReportData = payload?.Data;
      state.isFetching = false;
      return state;
    },
    [getCounsellorReport.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getCounsellorReport.pending]: (state) => {
      state.isFetching = true;
    },

    [getParticularStudentTimeLine.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.studentTimelineData = payload;
      state.applicantProfileDetail = payload?.applicant;
      return state;
    },
    [getParticularStudentTimeLine.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getParticularStudentTimeLine.pending]: (state) => {
      state.isFetching = true;
    },
    [getStudentFlexDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.flexList = payload;
      return state;
    },
    [getStudentFlexDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getStudentFlexDetails.pending]: (state) => {
      state.isFetching = true;
    },

    [getParticularStudentNotesFollowUp.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.studentFollowupNotes = payload;
      return state;
    },
    [getParticularStudentNotesFollowUp.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getParticularStudentNotesFollowUp.pending]: (state) => {
      state.isFetching = true;
    },

    [postAddTimeLineNote.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isTimeLineNotePublished = true;
      return state;
    },
    [postAddTimeLineNote.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postAddTimeLineNote.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [postUpdateReceiptNumber.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.receiptUpdatedSuccess = true;
      // state.updateStatusSuccess = true;
      return state;
    },
    [postUpdateReceiptNumber.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postUpdateReceiptNumber.pending]: (state) => {
      // state.isFetching = true;
    },

    [getProgramSummaryDetail.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.programSummaryData = payload;
      return state;
    },
    [getProgramSummaryDetail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramSummaryDetail.pending]: (state) => {
      state.isFetching = true;
    },

    [getOrganizationInterviewerList.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.allInterviewerList = payload;
      state.isFetching = false;
      // state.isSuccess = true;
      return state;
    },
    [getOrganizationInterviewerList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getOrganizationInterviewerList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [postAddInterviewerToProgram.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postAddInterviewerToProgram.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postAddInterviewerToProgram.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getInterviewersByProgram.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.interviewProgramList = payload;
      state.isFetching = false;
      // state.isSuccess = true;
      return state;
    },
    [getInterviewersByProgram.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getInterviewersByProgram.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getProgramWiseInterviewerList.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.programWiseInterviewerList = payload.Data;
      state.programAvailableSlots = payload.totalSlots;
      // state.isFetching = false;
      // state.isSuccess = true;
      return state;
    },
    [getProgramWiseInterviewerList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramWiseInterviewerList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getInterviewerSlots.fulfilled]: (state, { payload }) => {
      state.adminInterviewSlots = payload;

      state.isFetching = false;
      return state;
    },
    [getInterviewerSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getInterviewerSlots.pending]: (state) => {
      state.isFetching = true;
    },

    [postAddInterviewerPriorities.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postAddInterviewerPriorities.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postAddInterviewerPriorities.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [postAddInterviewSlotReminder.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postAddInterviewSlotReminder.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postAddInterviewSlotReminder.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getInterviewerSlotsSummary.fulfilled]: (state, { payload }) => {
      state.interviewerSlotsSummaryList = payload;
      state.isFetching = false;
      return state;
    },
    [getInterviewerSlotsSummary.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getInterviewerSlotsSummary.pending]: (state) => {
      state.isFetching = true;
    },

    [getProgramInterviewersSummary.fulfilled]: (state, { payload }) => {
      state.programInterviewersSummary = payload;
      state.isFetching = false;
      return state;
    },
    [getProgramInterviewersSummary.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramInterviewersSummary.pending]: (state) => {
      state.isFetching = true;
    },

    [getStudentProfileDetails.fulfilled]: (state, { payload }) => {
      state.studentProfileData = payload;
      state.isFetching = false;
      return state;
    },
    [getStudentProfileDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getStudentProfileDetails.pending]: (state) => {
      state.isFetching = true;
    },

    /** */

    [postAddMentorForInterviewToProgram.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postAddMentorForInterviewToProgram.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postAddMentorForInterviewToProgram.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getMentorCallInterviewersByProgram.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.interviewProgramList = payload;
      state.isFetching = false;
      // state.isSuccess = true;
      return state;
    },
    [getMentorCallInterviewersByProgram.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMentorCallInterviewersByProgram.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getProgramWiseMentorCallList.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.programWiseInterviewerList = payload.Data;
      state.programAvailableSlots = payload.totalSlots;
      // state.isFetching = false;
      // state.isSuccess = true;
      return state;
    },
    [getProgramWiseMentorCallList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramWiseMentorCallList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getOrganizationMentorList.fulfilled]: (state, { payload }) => {
      // console.log("printing payload", payload);
      state.allInterviewerList = payload;
      state.isFetching = false;
      // state.isSuccess = true;
      return state;
    },
    [getOrganizationMentorList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getOrganizationMentorList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getParticularStudentFlexDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.studentFlexList = payload?.Data;
      state.isSuccess = true;
      return state;
    },
    [getParticularStudentFlexDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getParticularStudentFlexDetails.pending]: (state) => {
      state.isFetching = true;
    },

    [summaryGetCohortStats.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.cohortStatsData = payload;

      // state.summaryProgramList = payload;
      // state.isFetching = false;
      return state;
    },
    [summaryGetCohortStats.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [summaryGetCohortStats.pending]: (state) => {
      state.isFetching = true;
    },
    //

    [getStudentRevenueCouncillorWise.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.studentCounsellorWise = payload?.Data;

      // state.summaryProgramList = payload;
      // state.isFetching = false;
      return state;
    },
    [getStudentRevenueCouncillorWise.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getStudentRevenueCouncillorWise.pending]: (state) => {
      state.isFetching = true;
    },
    //
  },
});

export const {
  clearState,
  clearComplete,
  clearExtendDate,
  clearTransaction,
  clearFeeDue,
  clearCounsellorData,
} = feeSlice.actions;

export const feeData = (state) => state.feeDetail;
