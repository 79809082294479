import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  meetingData,
  getMeetingTitleData,
  getMentorStudentsData,
  getMeetingDetailListData,
  getAllUserData,
  fetchAllMeetingDetails
} from "../../../reducers/meetingSlice";
import "./MeetingListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import PageLoader from "../../../components/UI/loader/PageLoader";
import MeetFeedbackPopup from "../../../components/Popups/MeetFeedbackPopup/MeetFeedbackPopup";
import moment from "moment";
import MeetingDetailDropdown from "../../../components/Dropdowns/MeetingDetailDropdown/MeetingDetailDropdown";
import SearchSelect from "react-select";
import StudentMeetFeedback from "../../../components/Popups/MeetFeedbackPopup/StudentMeetFeedback";
import AdminAgenda from "../../../components/Popups/AgendaPopup/AdminAgenda";
import { CSVLink } from "react-csv";
import { environment } from "../../../environments/environment";
const NewAdminMeetingDetails = () => {
  const {
    isFetching,
    getMeetingTitleList,
    getMentorStudentsList,
    getMeetingDetailsList,
    getNewMeetingDetailsList,
    allUserData
  } = useSelector(meetingData);
  const dispatch = useDispatch();
  const [meetingListData, setMeetingListData] = useState([]);
  const [meetingDetails, setMeetingDetail] = useState([]);
  const [mentorListData, setMentorListData] = useState([]);
  const [mentor, setMentor] = useState("");
  const [menuId, setMenuId] = useState(null);
  const [singleFeedBackDetail, setSingleFeedBackDetail] = useState(null);
  const [studentMeetDetail,setStudentMeetDetail]=useState("")
  const [studentFeedBackDetail, setStudentFeedBackDetail] = useState(null);
  const [isMeetFeedbackPopupOpen, setIsMeetFeedbackPopupOpen] = useState(false);
  const [isAgendaPopupOpen, setIsAgendaPopupOpen] = useState(false);
  const [getAllAgenda, setGetAllAgenda] = useState(null);
  const [isMeetFeedbackStudentPopupOpen, setIsMeetFeedbackStudentPopupOpen] =
    useState(false);
  const [dropdownDataChange, setDropdownDataChange] = useState("");
  const [allUser, setAllUser] = useState([]);
  const [isDropDown, setIsDropDown] = useState(false);

  const neworgId=localStorage.getItem("orgId")
  const newAdminId=localStorage.getItem("adminId")

  const toggleMeetFeedbackPopup = () => {
    setIsMeetFeedbackPopupOpen(false);
  };
  const toggleStudentMeetFeedbackPopup = () => {
    setIsMeetFeedbackStudentPopupOpen(false);
  };

  
  const toggleAgendaPopup = () => {
    setIsAgendaPopupOpen(false);
  };
  // Get All User
  useEffect(() => {
    dispatch(getAllUserData());
  }, []);

  //   useEffect(() => {
  //     if (allUser?.length > 0) {
  //       let obj = {
  //         userId: allUser[0]?.id,
  //       };
  //       dispatch(getMeetingTitleData(obj));
  //       dispatch(getMentorStudentsData(obj));
  //     }
  //   }, [allUser]);

  // set Data
  useEffect(() => {
    setMeetingListData(getMeetingTitleList);
    setMentorListData(getMentorStudentsList);
    setMeetingDetail(getNewMeetingDetailsList);
    setAllUser(allUserData);
  }, [
    getMeetingTitleList,
    getMentorStudentsList,
    getNewMeetingDetailsList,
    allUserData,
  ]);

  //   useEffect(() => {
  //     if(mentorListData?.length === 0 || allUser?.length === 0){
  //       return;
  //     }
  //     if(!isDropDown){
  //       setMentor(mentorListData[0]?.studentId);
  //       console.log("in if api",{studentId:mentorListData[0]?.studentId , userId: allUser[0]?.id});
  //       dispatch(getMeetingDetailListData({studentId:mentorListData[0]?.studentId , userId: allUser[0]?.id}));
  //     }else{
  //       setMentor(mentorListData[0]?.studentId);
  //       console.log("in else api",{studentId:mentorListData[0]?.studentId , userId: dropdownDataChange});
  //       dispatch(getMeetingDetailListData({studentId:mentorListData[0]?.studentId , userId: dropdownDataChange}));
  //     }
  //   },[mentorListData,allUser,dropdownDataChange])

  const feedBackDetailOpen = (data) => {
    setIsMeetFeedbackPopupOpen(true);
    setSingleFeedBackDetail(data);
    setStudentFeedBackDetail("");
  };
  const studentFeedBackDetailOpen = (data) => {
    setIsMeetFeedbackStudentPopupOpen(true);
    setStudentMeetDetail(data);
    setSingleFeedBackDetail("");
  };

  const handleMentorChange = (e) => {
    let data = e.target.value;
    setMentor(data);
    let obj;
    if (dropdownDataChange) {
      obj = {
        studentId: data,
        userId: dropdownDataChange,
      };
    } else {
      obj = {
        studentId: data,
        userId: allUser[0]?.id,
      };
    }
    dispatch(getMeetingDetailListData(obj));
  };


  /**onClikc */
  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const dropDownOnChhange = (e) => {
    console.log(e.value, "e");
    let data = e;
    setIsDropDown(true);
    setDropdownDataChange(data);
    let obj = {
      userId: e.value,
    };
    dispatch(fetchAllMeetingDetails(obj));
    // let obj
    // console.log("mentor",mentor);
    // console.log("mentorListData",mentorListData);
    // if(mentor){
    //   console.log("inside if");
    //   obj = {
    //     studentId: mentor,
    //     userId: data,
    //   };
    // }else{
    //   obj = {
    //     studentId: mentorListData[0]?.studentId,
    //     userId: data,
    //   };
    // }
    // dispatch(getMeetingTitleData(obj));
    // dispatch(getMentorStudentsData(obj));
  };

  const options =
    allUser?.length > 0 &&
    allUser?.map((res) => ({
      value: res.id,
      label: `${res.firstName} ${res.lastName}`,
    }));
  const optionsss = options && [{ value: "all", label: "All" }, ...options];

  useEffect(() => {
    let obj = {
      userId: "all",
    };
    dispatch(fetchAllMeetingDetails(obj));
    setDropdownDataChange({ value: "all", label: "All" });
  }, []);

  console.log(dropdownDataChange, "options");

  
  const openAgendaPopup = (data) => {
    setIsAgendaPopupOpen(true);
    setGetAllAgenda(data);
  };

  const onClickStudentCSV = () => {
    var url =
    environment.baseURL +
    "/api/org/" +
    neworgId +
    "/admin/" +
    newAdminId +
    "/downloadMeetingDetailsData"

    window.open(url, "_blank", "noopener,noreferrer");
  };

  let downloadReportCSV=( separator = ',')=>{
    setTimeout(() => {
      let rows = document.querySelectorAll('table#' + "tableCsv"+ ' tr');
      let csv = [];
      for (let i = 0; i < rows.length; i++) {
        // let inputValue = (<HTMLInputElement>rows[i].querySelector('input[name="myMarks"]'))
        // // let value = inputValue.getAttribute('placeholder')
        // console.log(inputValue);
        let row = [], cols = rows[i].querySelectorAll('td, th');
        // let row = [], cols = rows[i].querySelectorAll('td, th');

        for (let j = 0; j < cols.length; j++) {
          let data = cols[j].textContent
          data = data.replace(/"/g, '""');
          row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
      }
      let csv_string = csv.join('\n');
      // let filename = this.surveyTitle +'_'+ new Date().toLocaleDateString() + '.csv';
      let filename = "empName" + '.csv'
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 2000);
  }

  return (
    <>
      {isFetching && <PageLoader />}
      <div className="main-body">
        <SideMenu
        meeting={true}
        title="Meeting Details"
        />
        <MeetFeedbackPopup
          show={isMeetFeedbackPopupOpen}
          handleClose={toggleMeetFeedbackPopup}
          singleFeedBackDetail={singleFeedBackDetail}
          studentFeedBackDetail={studentFeedBackDetail}
        />
        <AdminAgenda
          show={isAgendaPopupOpen}
          handleClose={toggleAgendaPopup}
          getAllAgenda={getAllAgenda}
        />
        <StudentMeetFeedback
          show={isMeetFeedbackStudentPopupOpen}
          handleClose={toggleStudentMeetFeedbackPopup}
          singleFeedBackDetail={studentMeetDetail}
        //   studentFeedBackDetail={studentFeedBackDetail}
        />
        <div className="page-header">
          <p className="component-heading">Meeting Details</p>
          <div className="selected-fee">
            {/* <SearchSelect
              value={dropdownDataChange}
              onChange={dropDownOnChhange}
              options={optionsss}
              // getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              // getOptionValue={(option) => option.id}
              styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
            /> */}
              {/* <button
                className="primary-btn feed-btn"
                onClick={onClickStudentCSV}
              >
                Meeting CSV
              </button> */}
          </div>
        </div>

        <div className="filter-bar">
          {/* <div className="mentor-details">
            {mentorListData &&
              mentorListData?.map((res, index) => (
                <label className="create-label" key={index}>
                  <input
                    className="radio-btn"
                    type="radio"
                    value={res?.Student && res?.Student?.id}
                    checked={mentor === res?.Student?.id}
                    onChange={handleMentorChange}
                    key={index}
                  />
                  <span className="radiomark"></span>
                  {res.Student && res.Student.firstName}{" "}
                  {res.Student && res.Student.lastName}
                </label>
              ))}
          </div> */}
        </div>
        <div className="feedback-container scrollbar">
          <table className="feedback-table" cellPadding={0} cellSpacing={0} id="tableCsv">
            <thead>
              <tr className="p-20">
                <th>Meeting Name</th>
                <th>Coach Name</th>
                <th>Coache Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Coach Meeting Status</th>
                <th>Coache Meeting Status</th>
                <th>Cancel Reason</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {meetingDetails &&
                meetingDetails.map((data, i) => (
                  <tr key={i}>
                    <td>{data?.MeetingMaster?.title}</td>
                    <td className="text-capitalize">
                      {data?.User?.firstName} {data?.User?.lastName}
                    </td>
                    <td className="text-capitalize">
                      {data?.Student?.firstName} {data?.Student?.lastName}
                    </td>
                    <td>
                      <div className="edit-date">
                        {moment(data.Date).format("DD-MMMM-YYYY")}
                      </div>
                    </td>
                    <td>{data.time}</td>
                    <td>
                      {/* {data.meetingStatusByMentor} */}
                      {data.isTimeLapse === true && data?.startTimeByMentor === null ? (
                        <p className="text-danger stripe">Time Lapsed</p>
                      ) : (
                        data.meetingStatusByMentor === "Completed" &&
                        data.isCancelled === false &&
                        data.meetingStatusByMentor
                      )}
                      {data.isCancelled === true && (
                        <p className="text-danger stripe">
                          Cancelled By{" "}
                          {data?.isCancelledBy === "Mentor"
                            ? "Coach"
                            : "Coachee"}
                        </p>
                      )}
                      {data.meetingStatusByMentor === "Pending" &&
                        data.meetingStatusByMentor}
                      {data.meetingStatusByMentor === "Approved" &&
                        data.meetingStatusByMentor}
                      {data.meetingStatusByMentor === "onGoing" &&
                        data.meetingStatusByMentor}
                    </td>
                    <td>
                      {data.isTimeLapse === true && data?.startTimeByStudent === null ? (
                        <p className="text-danger stripe">Time Lapsed</p>
                      ) : (
                        data.meetingStatusByStudent === "Completed" &&
                        data.isCancelled === false &&
                        data.meetingStatusByStudent
                      )}
                      {data.isCancelled === true && (
                        <p className="text-danger stripe">
                          Cancelled By{" "}
                          {data?.isCancelledBy === "Mentor"
                            ? "Coach"
                            : "Coachee"}
                        </p>
                      )}
                      {data.meetingStatusByStudent === "Pending" &&
                        data.meetingStatusByStudent}
                      {data.meetingStatusByStudent === "Approved" &&
                        data.meetingStatusByStudent}
                      {data.meetingStatusByStudent === "onGoing" &&
                        data.meetingStatusByStudent}
                    </td>
                    {/* <td>{data.meetingStatusByStudent}</td> */}
                    
                    <td>{data.cancelReason ? data.cancelReason :"---"}</td>
                    <td>
                      {(data?.MeetFeedbacks?.length > 0 || data?.MeetingAgendas?.length > 0) && (
                        <button
                          className="action-btn"
                          onClick={() => openActionMenu(i)}
                          onBlur={closeActionMenu}
                        >
                          <img src="../assets/icons/options.svg" />
                          <ul
                            className={
                              menuId === i
                                ? "action-dropdown active"
                                : "action-dropdown"
                            }
                          >
                            {data?.MeetFeedbacks?.filter(
                              (dd) => dd.feedbackFor === "Student"
                            )?.length > 0 ? (
                              <li
                                className="dropdown-item"
                                onClick={() => feedBackDetailOpen(data)}
                              >
                                <img src="../assets/icons/preview.svg" alt="" />
                                <p className="dropdown-text">Given By Coach</p>
                              </li>
                            ) : (
                              ""
                            )}

                            {data?.MeetFeedbacks?.filter(
                              (dd) => dd.feedbackFor === "Mentor"
                            )?.length > 0 ? (
                              <li
                                className="dropdown-item"
                                onClick={() => studentFeedBackDetailOpen(data)}
                              >
                                <img src="../assets/icons/preview.svg" alt="" />
                                <p className="dropdown-text">
                                  Given By Coache
                                </p>
                              </li>
                            ) : (
                              ""
                            )}
                            {
                              data?.MeetingAgendas?.length > 0 && <li
                              className="dropdown-item"
                              onClick={() => openAgendaPopup(data)}
                            >
                              <img
                                src="../assets/icons/plus-black.svg"
                                alt=""
                              />
                              <p className="dropdown-text">Agenda</p>
                            </li>
                            }
                          </ul>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default NewAdminMeetingDetails;
