import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import InterviewerPriorityPopup from "../../../components/Popups/InterviewerPriorityPopup/InterviewerPriorityPopup";
import {
  clearState,
  feeData,
  getMentorCallInterviewersByProgram,
  getInterviewerSlotsSummary,
  postAddInterviewSlotReminder,
} from "../../../reducers/feeSlice";
import "../interviewSettings/InterviewSettingStyle.css";
import PageLoader from "../../../components/UI/loader/PageLoader";

const MentorCallSettings = () => {
  const {
    isFetching,
    interviewProgramList,
    isSuccess,
    interviewerSlotsSummaryList,
  } = useSelector(feeData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [interviewerId, setInterviewerId] = useState("");
  const [showPriorityPopup, setShowPriorityPopup] = useState(false);
  const [viewType, setViewType] = useState("");
  const [programMasterId, setProgramMasterId] = useState("");
  const [menuId, setMenuId] = useState(null);
  const [programInterviewer, setProgramInterviewer] = useState([]);
  const [renderComponent, setRenderComponent] = useState(1);

  useEffect(() => {
    getProgramsList();
  }, []);

  const getProgramsList = () => {
    dispatch(getMentorCallInterviewersByProgram());
  };

  const handleClose = () => {
    setShowPriorityPopup(false);
    setViewType("");
    setProgramInterviewer([]);
    setProgramMasterId("");
  };

  const handlePriorityPopup = (type, show) => {
    setViewType(type);
    setShowPriorityPopup(show);
  };

  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };

  const closeActionMenu = () => {
    setMenuId(null);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      getProgramsList();
      dispatch(clearState());
    }
  }, [isSuccess]);

  const onClickAddSlotReminder = (id) => {
    let obj = {
      interviewerId: id,
    };

    dispatch(postAddInterviewSlotReminder(obj));

    console.log("printing data", obj);
  };

  const getAllInterviewersSummary = () => {
    dispatch(getInterviewerSlotsSummary());
  };

  return (
    <>
      {/* <SideMenu /> */}
      {isFetching && <PageLoader />}

      <InterviewerPriorityPopup
        interviewerList={programInterviewer}
        show={showPriorityPopup}
        handleClose={handleClose}
        viewType={viewType}
        programMasterId={programMasterId}
      />

      <>
        <div className="page-header">
          <div className="pageTogglerOuter">
            <p
              onClick={() => setRenderComponent(1)}
              className={`page-toggler ${
                renderComponent === 1 ? "active" : ""
              }`}
            >
              Mentor Call Setting
            </p>
            <p
              onClick={() => {
                setRenderComponent(2);
                getAllInterviewersSummary();
              }}
              className={`page-toggler ${
                renderComponent === 2 ? "active" : ""
              }`}
            >
              All Mentors
            </p>
          </div>
        </div>
        {renderComponent === 1 && (
          <div className="interviewSettingTableContainer">
            <table
              className="interviewSettingsTable"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th>Program</th>
                  <th>Interviewers</th>
                  <th>Total Available Slots</th>
                  <th>Slot Details</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {interviewProgramList?.length > 0 &&
                  interviewProgramList?.map((item, index) => {
                    const { program, interviewerPriorities, totalSlots } = item;

                    return (
                      <tr key={index}>
                        <td>{program.programName}</td>
                        <td>
                          <div className="interviewsNameContainer">
                            {interviewerPriorities.length > 0 ? (
                              interviewerPriorities.map((data, ind) => {
                                const { firstName, lastName } = data;

                                return (
                                  <div
                                    onClick={() => {
                                      handlePriorityPopup("setPriority", true);
                                      setProgramInterviewer(
                                        interviewerPriorities
                                      );
                                    }}
                                    key={ind}
                                    className="interviewerName"
                                  >
                                    {(firstName + " " + lastName)
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.substring(1)
                                      )
                                      .join(" ")}
                                  </div>
                                );
                              })
                            ) : (
                              <p
                                onClick={() => {
                                  handlePriorityPopup("addMentor", true);
                                  setProgramMasterId(program?.id);
                                }}
                              >
                                No mentor added
                              </p>
                            )}
                          </div>
                        </td>

                        <td>{totalSlots ?? "0"} Slots</td>
                        <td>
                          {" "}
                          {interviewerPriorities.length > 0 ? (
                            <button
                              onClick={() => {
                                navigate(
                                  `availableSlots?programMasterId=${program?.id}`
                                );
                              }}
                              className="primary-outline-btn"
                            >
                              View Slot
                            </button>
                          ) : (
                            "--"
                          )}
                        </td>

                        <td className="text-center">
                          <button
                            onClick={() => openActionMenu(index)}
                            onBlur={closeActionMenu}
                            className="action-btn"
                          >
                            <img src="../assets/icons/options.svg" />
                          </button>
                          <ul
                            className={
                              menuId === index
                                ? "action-dropdown active "
                                : "action-dropdown"
                            }
                          >
                            <li
                              onClick={() => {
                                handlePriorityPopup("addMentor", true);
                                setProgramMasterId(program?.id);
                              }}
                              className="dropdown-item"
                            >
                              <img src="../assets/icons/table.svg" alt="" />
                              <p className="dropdown-text">Add Mentor</p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
        {renderComponent === 2 && (
          <div className="interviewSettingTableContainer">
            <table
              className="interviewSettingsTable"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th>Interviewer Name</th>
                  <th>Total Slots</th>
                  <th>Booked Slots</th>
                  <th>Remaining Slots</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {interviewerSlotsSummaryList?.length > 0 &&
                  interviewerSlotsSummaryList?.map((item, index) => {
                    const {
                      firstName,
                      lastName,
                      total,
                      available,
                      booked,
                      interviewerId,
                    } = item;

                    return (
                      <tr key={index}>
                        <td>
                          {(firstName + " " + lastName)
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.substring(1)
                            )
                            .join(" ")}
                        </td>
                        <td>{total}</td>
                        <td>{booked} Slots</td>
                        <td>{available}</td>

                        <td className="text-center">
                          <button
                            onClick={() => openActionMenu(index)}
                            onBlur={closeActionMenu}
                            className="action-btn"
                          >
                            <img src="../assets/icons/options.svg" />
                          </button>
                          <ul
                            className={
                              menuId === index
                                ? "action-dropdown active "
                                : "action-dropdown"
                            }
                          >
                            <li
                              onClick={() => {
                                onClickAddSlotReminder(interviewerId);
                              }}
                              className="dropdown-item"
                            >
                              <img src="../assets/icons/table.svg" alt="" />
                              <p className="dropdown-text">
                                Send Slots Reminder
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </>
    </>
  );
};

export default MentorCallSettings;
