import React from "react";
import "./GoBackPopupStyle.css";
import { useNavigate } from "react-router-dom";

const GoBackPopup = (props) => {
  const navigate = useNavigate();

  const handleDiscardFeedback = () => {
    // props.handleCloseGoBack;
    navigate(`${props.navigateTo}`);
  };
  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container goback-poup">
          <div className="popup-header">
            <div className="popup-heading">
              Discard Changes
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleCloseGoBack}
              />
            </div>
            <div className="popup-header-body">
              <div className="header-left">
                <p className="text-7">
                  Are you sure you want to discard changes of this form?
                </p>
              </div>
            </div>
            <div className="popup-footer pt-10">
              <div className="btn-group">
                <button
                  onClick={handleDiscardFeedback}
                  className="dark-btn-outline capitalise-btn"
                >
                  Discard
                </button>
                <button
                  onClick={props.handleCloseGoBack}
                  className="danger-btn capitalise-btn"
                >
                  Keep Editing
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GoBackPopup;
