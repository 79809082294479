import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const setQuestionType = createAsyncThunk(
  "setQuestionType",
  async (data, thunkAPI) => {
    try {
      // console.log('print value', JSON.stringify(data));
      return data;
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const pageLayoutSlice = createSlice({
  name: "pageLayout",
  initialState: {
    questionType: {},
  },
  reducers: {
    clearState: (state) => {
      state.questionType = {};

      return state;
    },
  },
  extraReducers: {
    [setQuestionType.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.questionType = payload;
      return state;
    },
    [setQuestionType.rejected]: (state, { payload }) => {
      console.log("payload", payload);
    },
    [setQuestionType.pending]: (state) => {
      // console.log('inside pending state', state);
    },
  },
});

export const { clearState } = pageLayoutSlice.actions;

export const layoutSelector = (state) => state.pageLayout;
