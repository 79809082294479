import React, { useState } from "react";
import "./approvalListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getApplicantDetailsForLogisticsPartner,
  putSaveLogisticsPartnerResponse,
  shippingData,
} from "../../../reducers/shippingSlice";
import { useEffect } from "react";
import { environment } from "../../../environments/environment";
import PageLoader from "../../../components/UI/loader/PageLoader";
import SaveLogisticDataPopup from "../../../components/Popups/StudentAvailibilityPopup/SaveLogisticDataPopup";
import moment from "moment";
import {
  applicantData,
  getCounsellorWisePayrollDetails,
} from "../../../reducers/applicantSlice";

const CounsellorPayrollDetail = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const [show, setShow] = useState(false);
  const [partnerName, setPartnerName] = useState("");

  const [applicantDetails, setApplicantDetails] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const { isFetching, counsellorPayrollData } = useSelector(applicantData);

  useEffect(() => {
    dispatch(getCounsellorWisePayrollDetails());
  }, []);

  //   useEffect(() => {
  //     if (applicantDetailsLogistics) {
  //       setApplicantDetails(applicantDetailsLogistics);
  //     }
  //   }, [applicantDetailsLogistics]);

  const formatAmount = (amount) => {
    var number = parseInt(amount);

    var formattedAmount = new Intl.NumberFormat("en-IN", {
      // style: "currency",
      currency: "INR",
    }).format(number);

    // console.log(
    //   new Intl.NumberFormat("en-IN", {
    //     style: "currency",
    //     currency: "INR",
    //   }).format(number)
    // );

    return formattedAmount;

    // console.log("sddsdsdsdsd", number2);
  };

  return (
    <>
      <SideMenu />
      {isFetching && <PageLoader />}

      <div className="main-body">
        <div className="page-header">
          <div className="studentDetails">
            <div className="student-inner-left button-set-student">
              <div className="student-details-outer">
                <div className="student-details">
                  <p className="student-name text-18">
                    Counsellor Payroll Details
                  </p>
                </div>
                {/* <div className="student-details">
                  <p className="student-mail">
                    {logisticResponse?.program?.batch}
                  </p>
                </div> */}
              </div>

              <div>
                {/* <button
                  className="primary-btn"
                  style={{ "margin-right": "10px" }}
                  onClick={() => {
                    var url =
                      environment.baseURL +
                      `/api/org/orderId/${orderId}/downloadOfferLetterBoxLogisticsApplicantCSV`;

                    window.open(url, "_blank", "noopener,noreferrer");
                  }}
                >
                  Download Csv
                </button>
               
                <button
                  onClick={() => {
                    toggleLogisticDataPopup();
                  }}
                  className="primary-btn"
                >
                  Save
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
        </div>

        <div className="feedback-container scrollbar">
          <table
            className="feedback-table table"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Counsellor name</th>
                <th>Cohort</th>
                <th>Program Name</th>
                <th>Total Fee</th>
              </tr>
            </thead>
            <tbody>
              {counsellorPayrollData?.length > 0 &&
                counsellorPayrollData?.map((item, index) => {
                  const { councillorName, name, programName, totalFee } = item;
                  // const { applicantdetail } = Applicant;
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{councillorName}</td>
                      <td>{name}</td>
                      <td>{programName}</td>
                      <td>₹ {formatAmount(totalFee)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CounsellorPayrollDetail;
