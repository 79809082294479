import React, { useEffect, useState, useRef } from "react";
import "./approvalListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import CreateInterviewPopup from "../../../components/Popups/createInterviewPopup/createInterviewPopup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/UI/loader/PageLoader";
import {
  applicantData,
  clearState,
  getApplicantDetailList,
  shortlistStudents,
  clearCsvState,
  deleteInterView,
  getApplicantFeedbackAns,
  clearFeedbackData,
  postStudentAvailability,
  putResendInterviewSlotMail,
  rejectApplicants,
} from "../../../reducers/applicantSlice";
import moment from "moment";
import ToastMessage from "../../../components/Toast/ToastComponent";
// import DeletePopup from "../../../components/Popups/DeletePopup/DeletePopup";
import InterViewDeletePopup from "../../../components/Popups/InterViewDeletePopup/InterViewDeletePopup";

import ErrorToast from "../../../components/Toast/ErrorToast";
import EditScholarship from "../../../components/Popups/EditFeeDuePopup/EditScholarship";
import StudentAvailibilityPopup from "../../../components/Popups/StudentAvailibilityPopup/StudentAvailibilityPopup";
import AddStudentResume from "../../../components/Popups/AddStudentListPopup/AddStudentResume";
import * as filestack from "filestack-js";
const client = filestack.init("AEe3jxIGTtu285BMdsMgNz");

const ApplicantList = () => {
  const token = {};
  const [isFetchApi, setIsFetchApi] = useState(false);
  const [viewType, setOfferViewType] = useState("");
  const [applicantEmail, setApplicantEmail] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [interviewerId, setInterviewerId] = useState("");
  const [applicantId, setApplicantId] = useState("");
  const [interviewData, setInterviewData] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [scholarshipAmount, setScholarshipAmount] = useState(0);
  const [type, setType] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const [applicantMobile, setApplicantMobile] = useState("");
  const [showMoreLoader, setShowMoreLoader] = useState(false);
  const [isFilterMenuActive, setisFilterMenuActive] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [date, setDate] = useState("");
  const filter1 = useRef(null);
  const filter2 = useRef(null);
  const filter3 = useRef(null);
  const filter4 = useRef(null);
  const [show, setShow] = useState(false);
  const [showSummaryPopup, setSummaryPopup] = useState(false);
  const [newApplicantId, setNewApplicantId] = useState("");

  //toast states
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [succMessage, setSuccessMessage] = useState("");

  //check applicants states

  const [allChecked, setAllChecked] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isTotalSelected, setTotalSelected] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const [showResumePopup, setShowResumePopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resumeUrl, setResumeUrl] = useState("");

  // for cover letter

  const [coverFile, setCoverFile] = useState("");
  const [isCoverFilePicked, setIsCoverFilePicked] = useState(false);
  const coverFileInput = React.useRef(null);
  const [coverUrl, setCoverUrl] = useState("");

  const onClickUpload = (event) => {
    if (event.target.files.length > 0) {
      setIsLoading(true);
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
      const files = event.target.files[0];
      // client
      //   .upload(files, {}, token)
      //   .then((res) => {
      //     setResumeUrl(res.url);
      //     setIsLoading(false);
      //   })
      //   .catch((err) => {
      //     setIsLoading(false);
      //   });

      const data = new FormData();

      data.append("file", files);
      data.append("upload_preset", "qlm4yp8h");
      data.append("cloud_name", "df491ehfg");

      fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("printing", res);

          setIsLoading(false);
          setResumeUrl(res.secure_url);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const onClickDeleteUpload = () => {
    setSelectedFile("");
    setIsFilePicked(false);
  };

  // onClick Cover Letter

  const onClickCoverUpload = (event) => {
    if (event.target.files.length > 0) {
      setIsLoading(true);
      setCoverFile(event.target.files[0]);
      setIsCoverFilePicked(true);
      const files = event.target.files[0];
      // client
      //   .upload(files, {}, token)
      //   .then((res) => {
      //     setCoverUrl(res.url);
      //     setIsLoading(false);
      //   })
      //   .catch((err) => {
      //     setIsLoading(false);
      //   });

      const data = new FormData();

      data.append("file", files);
      data.append("upload_preset", "qlm4yp8h");
      data.append("cloud_name", "df491ehfg");

      fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("printing", res);

          setIsLoading(false);
          setCoverUrl(res.secure_url);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const onClickCoverDeleteUpload = () => {
    setCoverFile("");
    setIsCoverFilePicked(false);
  };

  const [loaded, setLoaded] = useState(false);
  const clickApply = () => {
    setisFilterMenuActive(false);
    setPageNo("1");
    if (
      admissionFee !== "" ||
      interviewStatus !== "" ||
      approvalStatus !== "" ||
      applicantInterviewStatus !== ""
    ) {
      setFilterApplied(true);
      setApplyFilter(true);
    } else {
      setClearFilter(true);
      setFilterApplied(false);
      setPageNo("1");
      setAdmissionFee("");
      setInterviewStatus("");
      setApprovalStatus("");
      setInterviewAvailability("");
      setDate("");
      setDescending("");
      setisFilterMenuActive(false);
      filter1.current.selectedIndex = 0;
      filter2.current.selectedIndex = 0;
      filter3.current.selectedIndex = 0;
      filter4.current.selectedIndex = 0;
    }
  };
  const clickClear = () => {
    setFilterApplied(false);
    setPageNo("1");
    setAdmissionFee("");
    setInterviewStatus("");
    setApprovalStatus("");
    setInterviewAvailability("");
    setDate("");
    setDescending("");
    setisFilterMenuActive(false);
    if (
      admissionFee !== "" ||
      interviewStatus !== "" ||
      descending !== "" ||
      approvalStatus !== "" ||
      applicantInterviewStatus !== ""
    )
      setApplyFilter(true);
    filter1.current.selectedIndex = 0;
    filter2.current.selectedIndex = 0;
    filter3.current.selectedIndex = 0;
    filter4.current.selectedIndex = 0;
  };

  const handleAllCheck = (e) => {
    let currStdIds = applicantListArray.map((data) => data.id);
    const currSet = new Set(currStdIds);
    if (allChecked) {
      setTotalSelected(false);
      setAllChecked(false);
      let newArr = isChecked.filter((val) => !currSet.has(val));
      return setIsChecked(newArr);
    }
    setAllChecked(true);
    let newIsChecked = [...isChecked];
    currStdIds.map((val) => {
      if (!newIsChecked.includes(val)) {
        newIsChecked.push(val);
      }
    });
    return setIsChecked(newIsChecked);
  };

  const handleSingleCheck = (e) => {
    const id = e.target.value;
    if (isChecked.includes(id)) {
      setIsChecked(isChecked.filter((checked_id) => checked_id !== id));
      setTotalSelected(false);
      return setAllChecked(false);
    }
    isChecked.push(id);
    setIsChecked([...isChecked]);
    checkAllChecked();
  };
  const checkAllChecked = () => {
    let stdIds = applicantListArray.map((item) => item.id);
    let isAll = stdIds.every((val) => isChecked.includes(val));
    if (!isAll) {
      setTotalSelected(false);
    }
    setAllChecked(isAll);
  };
  const clearSelected = () => {
    setTotalSelected((prev) => !prev);
    setIsChecked([]);
    setAllChecked(false);
  };

  //filters states
  const [result, setResult] = useState("");
  const [interviewStatus, setInterviewStatus] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [applicantInterviewStatus, setInterviewAvailability] = useState("");
  const [admissionFee, setAdmissionFee] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [pageNo, setPageNo] = useState("1");
  const [descending, setDescending] = useState("");
  const [pageSize, setPageSize] = useState("10");

  const [scholarshipPopup, setScholarshipPopup] = useState(false);
  const [scholarshipType, setScholarshipType] = useState("");
  const [dlResponse, setDLResponse] = useState("");
  const [dlResponseReason, setDLResReason] = useState("");

  const handleLoadClick = () => {
    if (pageNo == Math.ceil(applicantCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
    setShowMoreLoader(true);
    setLoaded(true);
  };

  const handleSearch = (event) => {
    var search = event.target.value;
    setSearchTitle(search);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchByTitle(e.target.value);
    }
  };

  const searchByTitle = (val) => {
    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: val,
      interviewStatus: interviewStatus,
      result: result,
      pageNo: "1",
      pageSize: pageSize,
      admissionFee: admissionFee,
      descending: descending,
      date: date,
      approvalStatus: approvalStatus,
      applicantInterviewStatus: applicantInterviewStatus,
    };
    dispatch(getApplicantDetailList({ obj, params }));
    dispatch(clearState());
    setIsChecked([]);
    setAllChecked(false);
    setPageNo("1");
  };

  const togglePopup = () => {
    setIsPopupActive((prev) => !prev);
    setType("");
    setApplicantEmail("");
    setApplicantName("");
    setApplicantMobile("");
    setInterviewerId("");
    setApplicantId("");
    setPopupTitle("");
    setInterviewData(null);
    setViewOnly(false);
  };

  const {
    isFetching,
    isSuccess,
    isError,
    applicantListDetail,
    applicantCount,
    successMessage,
    deleteInterViewList,
    feedbackAns,
    programDetails,
    resendMailSuccess,
  } = useSelector(applicantData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [applicantListArray, setApplicantListArray] = useState([]);
  const [shortListId, setShortListId] = useState([]);
  const [changeProgramShow, setChangeProgramShow] = useState(false);

  const param = useParams();
  console.log("csdcscscscscscscscscscscscscscscscscs", param);
  const programBatchId = param.proId;

  useEffect(() => {
    if (applyFilter || loaded || clearFilter) {
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        interviewStatus: interviewStatus,
        result: result,
        pageNo: pageNo,
        pageSize: pageSize,
        admissionFee: admissionFee,
        descending: descending,
        date: date,
        approvalStatus: approvalStatus,
        applicantInterviewStatus: applicantInterviewStatus,
      };
      dispatch(getApplicantDetailList({ obj, params }));
      setApplyFilter(false);
      setClearFilter(false);
      dispatch(clearState());
      setViewOnly(false);
    } else {
      console.log("done");
    }
  }, [result, pageNo, pageSize, programBatchId, applyFilter, clearFilter]);

  useEffect(() => {
    console.log("inside 1 use effect");
    setPageNo("1");
    setIsChecked([]);
    setAllChecked(false);
    setLoaded(false);
    setViewOnly(false);
  }, [pageSize, result, applyFilter]);

  useEffect(() => {
    // setApplicantListArray([]);

    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: searchTitle,
      interviewStatus: interviewStatus,
      result: result,
      pageNo: pageNo,
      pageSize: pageSize,
      admissionFee: admissionFee,
      descending: descending,
      date: date,
      approvalStatus: approvalStatus,
      applicantInterviewStatus: applicantInterviewStatus,
    };
    if (isSuccess) {
      if (isPopupActive) {
        togglePopup();
        setViewOnly(false);
      }
      if (scholarshipPopup) {
        setScholarshipPopup(false);
      }
      setScholarshipType("");
      dispatch(getApplicantDetailList({ obj, params }));
      dispatch(clearState());

      if (show) {
        closeStudentAvailabilityPopup();
      }

      if (showResumePopup) {
        uploadResumePopup();
      }

      if (resendMailSuccess) {
        setTimeout(() => setEnableSuccessMessage(true), 2000);
        setSuccessMessage("Slot schedule mail sent to applicant");
      }

      dispatch(clearFeedbackData());
    }
  }, [isSuccess]);

  // console.log("fbdfdfbydbfybdybfbdyfbydf", applicantListArray);

  useEffect(() => {
    if (applicantListDetail) {
      console.log("checking loaded status", loaded);
      if (loaded === true) {
        setApplicantListArray([...applicantListArray, ...applicantListDetail]);
        setLoaded("done");
      } else if (loaded === false) {
        setApplicantListArray(applicantListDetail);
      } else {
        console.log(null);
      }
      setLoaded(false);
    }

    // setApplicantListArray(applicantListDetail);
  }, [applicantListDetail]);

  /**onClikc */
  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const shortlistApplicants = () => {
    if (isChecked.length > 0) {
      var params = {
        searchTitle: searchTitle,
        interviewStatus: interviewStatus,
        result: result,
        admissionFee: admissionFee,
        approvalStatus: approvalStatus,
        applicantInterviewStatus: applicantInterviewStatus,
      };
      dispatch(
        shortlistStudents({
          applicants: isChecked,
          isTotalSelected,
          params,
          programId: programBatchId,
        })
      );
    }
  };
  // const convertDateTime = (date) => {
  //   var formatDate = moment(date).format("LLLL");
  //   return formatDate;
  // };

  // console.log("checng array", applicantListArray);

  const shortListMessage = () => {
    if (successMessage) {
      setTimeout(() => setEnableSuccessMessage(true), 2000);
      setSuccessMessage("Selected Candidates are shortlisted");
      dispatch(clearCsvState());
      setIsChecked([]);
      setAllChecked(false);
    }
  };
  const deleteInterViewMessage = () => {
    if (deleteInterViewList) {
      setTimeout(() => setEnableSuccessMessage(true), 2000);
      dispatch(clearCsvState());
      setIsChecked([]);
      setAllChecked(false);
    }
  };
  useEffect(() => {
    shortListMessage();
    deleteInterViewMessage();
  }, [successMessage, deleteInterViewList]);

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onDeleteShortListClick = (interViewId) => {
    dispatch(deleteInterView({ Id: interViewId }));
    setIsDeleteOpen(false);
  };

  const delMess = "Interview deleted successfully";
  const sucMess = "Selected Candidates are shortlisted";

  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);
  const [studentFeedbackAccess, setFeedbackAccess] = useState(false);
  const [addApplicantAccess, setApplicantAccess] = useState(false);
  const [addProgramAccess, setAddProgramAccess] = useState(false);
  const [loggedInUserId, setLoggedInId] = useState("");

  useEffect(() => {
    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    const interviewFeedback =
      localStorage.getItem("interviewFeedback") === "true";
    const isApplicantAccess = localStorage.getItem("isAddApplicant") === "true";
    const isAddProgramAccess = localStorage.getItem("isAddProgram") === "true";

    const adminId = localStorage.getItem("adminId");

    console.log("guru guru", adminId);

    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
    if (interviewFeedback) {
      setFeedbackAccess(interviewFeedback);
    }
    if (isApplicantAccess) {
      setApplicantAccess(isApplicantAccess);
    }

    if (isAddProgramAccess) {
      setAddProgramAccess(isAddProgramAccess);
    }
    if (adminId) {
      setLoggedInId(adminId);
    }

    setApplyFilter(true);
    setApplicantListArray([]);
    dispatch(clearFeedbackData());
  }, []);

  // console.log("interviewFeedback", studentFeedbackAccess);

  const editScholarshipClick = (id) => {
    setScholarshipPopup(true);
    setChangeProgramShow(false);
    setApplicantId(id);
    setScholarshipType("edit");
  };

  const changeProgramPopup = (id) => {
    setShow(true);
    setChangeProgramShow(true);
    setOfferViewType("switchCourse");
    console.log(id);
    setNewApplicantId(id);
  };

  const closeStudentAvailabilityPopup = () => {
    setShow(false);
    setOfferViewType("");
    setDLResReason("");
    setDLResponse("");
  };

  const uploadResumePopup = (id) => {
    setShowResumePopup(!showResumePopup);
    onClickDeleteUpload();
    setApplicantId("");
    onClickCoverDeleteUpload();
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleCoverClick = () => {
    coverFileInput.current.click();
  };

  const onClickUploadResume = () => {
    let obj = {
      resume: resumeUrl,
      applicantId: applicantId,
      coverLetter: coverUrl,
    };

    dispatch(postStudentAvailability(obj));
    setPageNo("1");
  };

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const formatDate = (mDate) => {
    var date = moment(mDate).format("DD MMMM YYYY");

    return date;
  };

  const viewStudentTimeline = (id, isStudent) => {
    window.open(
      `/particularStudentfee?studentId=${id}&programBatchId=${programBatchId}&isStudent=${isStudent}`
    );
  };

  const onClickProgramName = (programId, batchId) => {
    navigate(
      `/feeSummary?programMasterId=${programId}&programBatchId=${batchId}&activeTab=3`
    );
  };

  const onClickResendMailForInterviewSlot = (applicantId) => {
    console.log("itemn clicked");

    dispatch(putResendInterviewSlotMail({ applicantId }));
  };

  // useEffect(()=>{

  // },[resendMailSuccess])

  // setTimeout(() => setEnableSuccessMessage(true), 2000);
  //     setSuccessMessage("Selected Candidates are shortlisted");

  const onRejectClick = (ids) => {
    if (ids.length > 0) {
      dispatch(
        rejectApplicants({
          obj: ids,
          programId: programBatchId,
          programMasterId: programDetails?.Program?.id,
        })
      );
      setIsChecked([]);
      setAllChecked(false);
      setPageNo("1");
    }
  };

  return (
    <>
      <SideMenu />
      {isLoading && pageNo === "1" ? <PageLoader /> : ""}

      <AddStudentResume
        handleClose={uploadResumePopup}
        onClickUploadResume={onClickUploadResume}
        isFilePicked={isFilePicked}
        isCoverFilePicked={isCoverFilePicked}
        hiddenFileInput={hiddenFileInput}
        coverFileInput={coverFileInput}
        selectedFile={selectedFile}
        coverFile={coverFile}
        onClickUpload={onClickUpload}
        onClickCoverUpload={onClickCoverUpload}
        onClickDeleteUpload={onClickDeleteUpload}
        onClickCoverDeleteUpload={onClickCoverDeleteUpload}
        handleClick={handleClick}
        handleCoverClick={handleCoverClick}
        show={showResumePopup}
        setLoaded={setLoaded}
        setPageNo={setPageNo}
        // data={"data"}
      />
      <StudentAvailibilityPopup
        show={show}
        closePopup={closeStudentAvailabilityPopup}
        popupTitle={"Student Availability"}
        applicantId={applicantId}
        setLoaded={setLoaded}
        setPageNo={setPageNo}
        viewType={viewType}
        dlResponseReason={dlResponseReason}
        dlResponse={dlResponse}
        newApplicantId={newApplicantId}
        setNewApplicantId={setNewApplicantId}
        offerStatusObj={{}}
      />
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />

      <CreateInterviewPopup
        type={type}
        togglePopup={togglePopup}
        show={isPopupActive}
        applicantEmail={applicantEmail}
        applicantMobile={applicantMobile}
        applicantName={applicantName}
        interviewerId={interviewerId}
        applicantId={applicantId}
        interviewData={interviewData}
        programBatchId={programBatchId}
        popupTitle={popupTitle}
        feedbackAns={feedbackAns}
        scholarshipAmount={scholarshipAmount}
        setLoaded={setLoaded}
        setPageNo={setPageNo}
        viewOnly={viewOnly}
      />

      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={succMessage}
      />

      <InterViewDeletePopup
        handleDelete={onDeleteShortListClick}
        handleClose={toggleDeletePopup}
        shortListId={shortListId}
        show={isDeleteOpen}
        name={"applicant"}
      />

      <EditScholarship
        closePopup={() => {
          setScholarshipPopup(false);
          setScholarshipType("");
          setApplicantId("");
        }}
        show={scholarshipPopup}
        scholarshipType={scholarshipType}
        programBatchId={programBatchId}
        studentId={applicantId}
        setLoaded={setLoaded}
        setPageNo={setPageNo}
      />

      <div className="main-body">
        <div className="page-header">
          <button onClick={() => navigate(-1)} className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            Back to Page
          </button>
          <div className="studentDetails">
            <div className="student-inner-left button-set-student">
              <div className="student-details-outer">
                <div className="student-details">
                  <p className="student-name text-18">
                    {programDetails?.Program?.programName}{" "}
                    <img src="../assets/icons/sectionSymbol.svg" />{" "}
                    <span className="text-danger">Applicants list</span>
                  </p>
                </div>
                <div className="student-details">
                  <p className="student-mail">
                    {programDetails?.batch} &#xb7;{" "}
                    {programDetails?.durationInMonths} months
                  </p>
                </div>
              </div>
              {localStorage.getItem("viewOnlyAccess") !== "true" && (
                <button
                  className="primary-btn feed-btn"
                  onClick={() =>
                    onClickProgramName(
                      programDetails?.Program?.id,
                      programBatchId
                    )
                  }
                >
                  Program Summary
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="filter-bar">
          <form action="" className="search-bar">
            <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              onChange={handleSearch}
              onKeyDown={onSearchKeyPress}
              placeholder="Search"
              className="clean-input"
            />
          </form>
          <div className="total-data-desc">
            Showing {applicantListArray?.length} out of {applicantCount}
          </div>
          <div className="filters">
            <button
              className={filterApplied ? "filterBtn-active" : "filterBtn"}
              onClick={() => setisFilterMenuActive(!isFilterMenuActive)}
            >
              {" "}
              {filterApplied ? (
                <img src="../assets/icons/filterWhite.svg" alt="" />
              ) : (
                <img src="../assets/icons/filter.svg" alt="" />
              )}{" "}
              Filters
            </button>

            <select
              id="type"
              onChange={(e) => {
                setPageSize(e.target.value);
                setPageNo("1");
                setApplyFilter(true);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div
          className="feedback-container scrollbar applicantList"
          style={{ minHeight: "600px" }}
        >
          <table
            className="feedback-table table fixedTableDesign maxWidthTable"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th className="check-row">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      disabled={
                        viewOnlyAccess || studentFeedbackAccess === false
                      }
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                      checked={allChecked}
                      onChange={handleAllCheck}
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                </th>
                <th>
                  <div className="filterTableHeader">
                    Candidate name
                    <div className="filterImg">
                      <img
                        src="../assets/icons/filterArrowUp.svg"
                        alt=""
                        className={
                          descending === "true" ? "pointer active" : "pointer"
                        }
                        onClick={() => {
                          setDescending("false");
                          setApplyFilter(true);
                          setPageNo("1");
                        }}
                      />
                      <img
                        src="../assets/icons/filterArrowDown.svg"
                        alt=""
                        className={
                          descending === "false" ? "pointer active" : "pointer"
                        }
                        onClick={() => {
                          setDescending("true");
                          setApplyFilter(true);
                          setPageNo("1");
                        }}
                      />
                    </div>
                  </div>
                </th>
                <th>Email</th>
                <th>Counsellor Name</th>
                <th>Interview Mail Status</th>
                <th>Interview Status</th>
                <th>Interviewer Name</th>
                <th>Interview Feedback</th>
                <th>Scholarship</th>
                <th>Approval Status</th>
                <th>DL Response</th>
                <th>Decision Letter</th>
                {/* <th>Offer Acceptance Status</th> */}
                <th>DL Sent On </th>
                <th>DL Due On </th>
                <th>Admission Fee</th>
                <th>
                  <div className="filterTableHeader">
                    Registered On
                    <div className="filterImg">
                      <img
                        src="../assets/icons/filterArrowUp.svg"
                        alt=""
                        className={date === "" ? "pointer active" : "pointer"}
                        onClick={() => {
                          setDate("true");
                          setApplyFilter(true);
                          setPageNo("1");
                          setDescending("");
                        }}
                      />
                      <img
                        src="../assets/icons/filterArrowDown.svg"
                        alt=""
                        className={
                          date === "true" ? "pointer active" : "pointer"
                        }
                        onClick={() => {
                          setDate("");
                          setApplyFilter(true);
                          setPageNo("1");
                          setDescending("");
                        }}
                      />
                    </div>
                  </div>
                </th>
                {/* <th>Result</th> */}
                {!viewOnlyAccess && studentFeedbackAccess && (
                  <th className="text-center">Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {isChecked?.length > 0 && (
                <tr className="selection">
                  <td className=" pl-0" colSpan="12">
                    <table
                      className="selection-table"
                      cellPadding={0}
                      cellSpacing={0}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {!isTotalSelected && (
                              <>
                                <p>
                                  {allChecked && "All"}{" "}
                                  <b>{isChecked?.length} Applicants</b> on this
                                  page are selected.
                                </p>
                                {allChecked && (
                                  <button
                                    className="clear-btn"
                                    onClick={() =>
                                      setTotalSelected((prev) => !prev)
                                    }
                                  >
                                    Select All{" "}
                                    <b>{applicantCount} Applicants</b>
                                  </button>
                                )}
                              </>
                            )}
                            {isTotalSelected && (
                              <>
                                <p>
                                  All <b>{applicantCount} Applicants</b> are
                                  selected.
                                </p>
                                <button
                                  className="clear-btn"
                                  onClick={clearSelected}
                                >
                                  Clear Selected Applicants
                                </button>
                              </>
                            )}
                            {"   "}
                            <button
                              className="primary-btn-a"
                              onClick={shortlistApplicants}
                            >
                              Send for Approval
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}

              {applicantListArray &&
                applicantListArray?.map((item, index) => {
                  const {
                    id,
                    firstName,
                    lastName,
                    officialEmail,
                    mobileNumber,
                    applicationId,
                    registrationFee,
                    councillorName,
                    interviewStatus,
                    result,
                    isMailed,
                    isShortlisted,
                    scholarshipAmount,
                    isStudent,
                    isApplicantApproved,
                    createdAt,
                    applicantInterviewStatus,
                    FeeDues,
                    offerLetterResponse,
                    offerLetterResponseReason,
                    offerLetterStatus,
                    notInterestedReason,
                    ApplicantInterviews,
                    isInterviewMailSent,
                  } = item;

                  return (
                    <tr key={index}>
                      <td>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            disabled={
                              viewOnlyAccess || studentFeedbackAccess === false
                            }
                            className="checkmark-btn"
                            checked={isChecked.includes(id)}
                            value={id}
                            onChange={handleSingleCheck}
                          />
                          <span className="checkmarkspan"></span>
                        </label>
                      </td>
                      <td className="programDetail">
                        <div
                          onClick={() => viewStudentTimeline(id, isStudent)}
                          className="programDetailText"
                        >
                          <p className="primary-text pointer">
                            {(firstName + " " + lastName)
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.substring(1)
                              )
                              .join(" ")}
                          </p>
                        </div>
                      </td>
                      <td>{officialEmail.toLowerCase()}</td>
                      <td>
                        <div className="counsellorName">
                          <p>
                            {councillorName === "" || councillorName === null
                              ? "--"
                              : councillorName}{" "}
                          </p>{" "}
                          {(councillorName === "" || councillorName === null) &&
                            (loggedInUserId ===
                              "378616ed-044f-46e5-bed7-0892a1ad63a5" ||
                              loggedInUserId ===
                                "3b19fa50-b1ff-4216-93bf-c786f8d8cd95") && (
                              <img
                                onClick={() => {
                                  setShow(true);
                                  setApplicantId(id);
                                  setOfferViewType("counsellor");
                                }}
                                src="../assets/icons/edit.svg"
                                alt=""
                                class="pointer"
                              />
                            )}
                        </div>
                      </td>

                      <td>
                        {isInterviewMailSent ? (
                          <p className="tag-active">Yes</p>
                        ) : (
                          <p className="tag-draft">No</p>
                        )}
                      </td>
                      <td>
                        <>
                          {interviewStatus === "Pending" && (
                            <p className="tag-draft">Pending</p>
                          )}
                          {interviewStatus === "Scheduled" && (
                            <p className="tag-active">
                              {moment(ApplicantInterviews[0]?.date).format(
                                "lll"
                              )}
                            </p>
                          )}
                          {interviewStatus === "Completed" && (
                            <p className="tag-active">Completed</p>
                          )}
                          {interviewStatus === "NotAppeared" && (
                            <p className="text-danger">No Show</p>
                          )}
                          {interviewStatus === "NotInterested" && (
                            <p className="text-danger">Not Interested</p>
                          )}
                          {interviewStatus === "NotEligible" && (
                            <p className="text-danger">Not Eligible</p>
                          )}
                        </>
                      </td>

                      <td>{ApplicantInterviews[0]?.interviewerName ?? "--"}</td>

                      <td>
                        {result === "Pass" && (
                          <p className="text-success">Pass</p>
                        )}
                        {result === "Fail" && (
                          <p className="text-danger">Fail</p>
                        )}
                        {result === "Waitlisted" && (
                          <p className="light-grey-text">Waitlisted</p>
                        )}
                        {result === "Pending" && <p>--</p>}
                      </td>
                      <td>
                        {scholarshipAmount ? scholarshipAmount + "%" : "--"}{" "}
                      </td>

                      <td>
                        {interviewStatus === "Pending" ||
                        interviewStatus === "Scheduled" ? (
                          "--"
                        ) : (
                          <>
                            {" "}
                            {applicantInterviewStatus === "NotAppeared" ||
                            applicantInterviewStatus === "NotInterested" ||
                            applicantInterviewStatus === "NotEligible" ? (
                              "--"
                            ) : (
                              <>
                                {isApplicantApproved === "Request Pending" && (
                                  <p className="dark-grey-text">
                                    Request Pending
                                  </p>
                                )}
                                {isApplicantApproved === "Approval Pending" && (
                                  <p className="light-grey-text">
                                    Approval Pending
                                  </p>
                                )}
                                {isApplicantApproved === "Rejected" && (
                                  <p className="text-danger">Rejected</p>
                                )}
                                {isApplicantApproved === "Approved" && (
                                  <p className="text-success">Approved</p>
                                )}
                                {isApplicantApproved === "Waitlisted" && (
                                  <p className="yellow-text">Waitlisted</p>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>

                      <td>{offerLetterResponse}</td>

                      <td>
                        {interviewStatus === "Pending" ||
                        interviewStatus === "Scheduled" ? (
                          "--"
                        ) : (
                          <>
                            {" "}
                            {applicantInterviewStatus === "NotAppeared" ||
                            applicantInterviewStatus === "NotInterested" ||
                            applicantInterviewStatus === "NotEligible" ? (
                              "--"
                            ) : (
                              <>
                                {isApplicantApproved === "Waitlisted" ? (
                                  "--"
                                ) : (
                                  <>
                                    {isMailed ? (
                                      <p className="text-success">Sent</p>
                                    ) : (
                                      "--"
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>

                      {/* {isMailed ? (
                        <>
                          {offerLetterStatus === "willPay" ? (
                            <td>Will Pay</td>
                          ) : (
                            <td className="Tabletooltip">
                              <p>
                                {offerLetterStatus === "willPay"
                                  ? "Will Pay"
                                  : notInterestedReason ?? "--"}
                              </p>
                              <label class="Insidetooltip">
                                {offerLetterStatus === "willPay"
                                  ? "Will Pay"
                                  : notInterestedReason ?? "--"}
                              </label>
                            </td>
                          )}
                        </>
                      ) : (
                        <>
                          <td>--</td>
                        </>
                      )} */}

                      <td>
                        {FeeDues.length > 0
                          ? formatDate(FeeDues[0]?.createdAt)
                          : "--"}
                      </td>
                      <td>
                        {FeeDues.length > 0
                          ? formatDate(FeeDues[0]?.dueOn)
                          : "--"}{" "}
                      </td>

                      <td>
                        {interviewStatus === "Pending" ? (
                          "--"
                        ) : (
                          <>
                            {applicantInterviewStatus === "NotAppeared" ||
                            applicantInterviewStatus === "NotInterested" ||
                            applicantInterviewStatus === "NotEligible" ? (
                              "--"
                            ) : (
                              <>
                                {isMailed ? (
                                  <>
                                    {isApplicantApproved === "Waitlisted" ||
                                    isApplicantApproved === "Rejected" ? (
                                      "--"
                                    ) : (
                                      <>
                                        {isStudent ? (
                                          <p className="text-success">Paid</p>
                                        ) : (
                                          <p className="text-danger">Pending</p>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  "--"
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>
                      <td>{moment(createdAt).format("DD MMMM YYYY")}</td>

                      {!viewOnlyAccess ? (
                        <>
                          {!isStudent ? (
                            <td className="text-center">
                              <button
                                className="action-btn"
                                onClick={() => {
                                  if (
                                    councillorName !== "" &&
                                    councillorName !== null
                                  ) {
                                    openActionMenu(index);
                                  } else {
                                    setIsShowError(true);
                                    setErrMessage(
                                      "Please link counsellor name to applicant first"
                                    );
                                  }
                                }}
                                onBlur={closeActionMenu}
                              >
                                <img src="../assets/icons/options.svg" />
                                <ul
                                  className={
                                    menuId === index
                                      ? "action-dropdown active  applicantDropdown"
                                      : "action-dropdown applicantDropdown"
                                  }
                                  //  className="action-dropdown active approval-drpdown"
                                >
                                  {addApplicantAccess && (
                                    <>
                                      {interviewStatus === "Pending" && (
                                        <>
                                          <li
                                            className="dropdown-item "
                                            onClick={() => {
                                              if (
                                                programBatchId ===
                                                "18c98b70-c61e-47f9-98f8-e4c643b5d413"
                                              ) {
                                                togglePopup();
                                                setType("create");
                                                setApplicantEmail(
                                                  officialEmail
                                                );
                                                setApplicantName(
                                                  firstName + " " + lastName
                                                );
                                                setApplicantMobile(
                                                  mobileNumber
                                                );
                                                setApplicantId(id);
                                                setPopupTitle(
                                                  "Schedule Interview"
                                                );
                                              } else {
                                                window.open(
                                                  `https://learn.mastersunion.org/interviewscheduler?redirectUrl=${id}&programId=${programBatchId}`,
                                                  "_blank",
                                                  "noopener,noreferrer"
                                                );
                                              }
                                            }}

                                            // onClick={() => {
                                            //   togglePopup();
                                            //   setType("create");
                                            //   setApplicantEmail(officialEmail);
                                            //   setApplicantName(
                                            //     firstName + " " + lastName
                                            //   );
                                            //   setApplicantMobile(mobileNumber);
                                            //   setApplicantId(id);
                                            //   setPopupTitle(
                                            //     "Schedule Interview"
                                            //   );
                                            // }}
                                          >
                                            <img
                                              src="../assets/icons/edit.svg"
                                              alt=""
                                            />
                                            <p className="dropdown-text">
                                              Schedule Interview
                                            </p>
                                          </li>

                                          <li
                                            className="dropdown-item "
                                            onClick={() => {
                                              onClickResendMailForInterviewSlot(
                                                id
                                              );
                                            }}
                                          >
                                            <img
                                              src="../assets/icons/edit.svg"
                                              alt=""
                                            />
                                            <p className="dropdown-text">
                                              Resend Schedule Mail
                                            </p>
                                          </li>
                                        </>
                                      )}

                                      <li
                                        className="dropdown-item "
                                        onClick={() => {
                                          setShow(true);
                                          setApplicantId(id);
                                          setOfferViewType(
                                            "studentAvailability"
                                          );
                                        }}
                                      >
                                        <img
                                          src="../assets/icons/preview.svg"
                                          alt=""
                                        />
                                        <p className="dropdown-text">
                                          Student Availability
                                        </p>
                                      </li>

                                      <li
                                        className="dropdown-item "
                                        onClick={() => {
                                          setShow(true);
                                          setApplicantId(id);
                                          setOfferViewType("offerLetterBox");
                                        }}
                                      >
                                        <img
                                          src="../assets/icons/preview.svg"
                                          alt=""
                                        />
                                        <p className="dropdown-text">
                                          Offer Letter Box
                                        </p>
                                      </li>
                                    </>
                                  )}

                                  <li
                                    onClick={() => {
                                      setApplicantId(id);
                                      setShowResumePopup(!showResumePopup);
                                    }}
                                    className="dropdown-item"
                                  >
                                    <img
                                      src="../assets/icons/edit.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">
                                      Upload Resume
                                    </p>
                                  </li>

                                  {/* <li
                                    onClick={() => {
                                      onRejectClick([id]);
                                    }}
                                    className="dropdown-item"
                                  >
                                    <img
                                      src="../assets/icons/edit.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">
                                      Rejection Mail
                                    </p>
                                  </li> */}

                                  {/* <li
                                    className="dropdown-item "
                                    onClick={() => {
                                      setShow(true);
                                      setApplicantId(id);
                                      setOfferViewType("dlResponse");
                                      setDLResReason(offerLetterResponseReason);
                                      setDLResponse(offerLetterResponse);
                                    }}
                                  >
                                    <img
                                      src="../assets/icons/preview.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">DL Response</p>
                                  </li> */}

                                  {(loggedInUserId ===
                                    "378616ed-044f-46e5-bed7-0892a1ad63a5" ||
                                    loggedInUserId ===
                                      "3b19fa50-b1ff-4216-93bf-c786f8d8cd95") && (
                                    <>
                                      {isMailed && (
                                        <>
                                          <li
                                            className="dropdown-item "
                                            onClick={() => {
                                              setShow(true);
                                              setApplicantId(id);
                                              setOfferViewType("updateFee");
                                              // setDLResReason(
                                              //   offerLetterResponseReason
                                              // );
                                              // setDLResponse(offerLetterResponse);
                                            }}
                                          >
                                            <img
                                              src="../assets/icons/preview.svg"
                                              alt=""
                                            />
                                            <p className="dropdown-text">
                                              Update Fee Manually
                                            </p>
                                          </li>
                                        </>
                                      )}
                                    </>
                                  )}

                                  {applicantInterviewStatus !== "NotAppeared" &&
                                    applicantInterviewStatus !==
                                      "NotInterested" &&
                                    applicantInterviewStatus !==
                                      "NotEligible" && (
                                      <>
                                        {studentFeedbackAccess && (
                                          <>
                                            {interviewStatus !== "Completed" &&
                                              interviewStatus ===
                                                "Scheduled" && (
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    togglePopup();
                                                    setType("feedback");

                                                    setApplicantId(id);
                                                    setPopupTitle(
                                                      firstName +
                                                        " " +
                                                        lastName +
                                                        " Feedback"
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src="../assets/icons/edit.svg"
                                                    alt=""
                                                  />
                                                  <p className="dropdown-text">
                                                    Candidate Feedback
                                                  </p>
                                                </li>
                                              )}

                                            {interviewStatus === "Completed" &&
                                              !isStudent && (
                                                <>
                                                  <li
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                      togglePopup();
                                                      setType("feedback");

                                                      setApplicantId(id);
                                                      setPopupTitle(
                                                        firstName +
                                                          " " +
                                                          lastName +
                                                          " Feedback Details"
                                                      );

                                                      setScholarshipAmount(
                                                        item.scholarshipAmount
                                                      );
                                                      dispatch(
                                                        getApplicantFeedbackAns(
                                                          {
                                                            applicantId:
                                                              item.id,
                                                          }
                                                        )
                                                      );

                                                      setViewOnly(true);
                                                    }}
                                                  >
                                                    <img
                                                      src="../assets/icons/preview.svg"
                                                      alt=""
                                                    />
                                                    <p className="dropdown-text">
                                                      View Feedback Details
                                                    </p>
                                                  </li>

                                                  <li
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                      editScholarshipClick(id)
                                                    }
                                                  >
                                                    <img
                                                      src="../assets/icons/edit.svg"
                                                      alt=""
                                                    />
                                                    <p className="dropdown-text">
                                                      Edit Scholarship
                                                    </p>
                                                  </li>
                                                </>
                                              )}
                                          </>
                                        )}
                                      </>
                                    )}

                                  {/* {!isMailed && ( */}
                                  <li
                                      className="dropdown-item"
                                      onClick={() => changeProgramPopup(id)}
                                    >
                                      <img
                                        src="../assets/icons/edit.svg"
                                        alt=""
                                      />
                                      <p className="dropdown-text">
                                        Program Switch
                                      </p>
                                    </li>
                                  {/* )} */}
                                </ul>
                              </button>
                            </td>
                          ) : (
                            <td className="text-center">
                              <button
                                className="action-btn"
                                onClick={() => openActionMenu(index)}
                                onBlur={closeActionMenu}
                              >
                                <img src="../assets/icons/options.svg" />
                                <ul
                                  className={
                                    menuId === index
                                      ? "action-dropdown active approval-drpdown"
                                      : "action-dropdown approval-drpdown"
                                  }
                                >
                                  <li
                                    className="dropdown-item"
                                    onClick={() => editScholarshipClick(id)}
                                  >
                                    <img
                                      src="../assets/icons/edit.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">
                                      Edit Scholarship
                                    </p>
                                  </li>

                                  <li
                                    onClick={() => {
                                      setApplicantId(id);
                                      setShowResumePopup(!showResumePopup);
                                    }}
                                    className="dropdown-item"
                                  >
                                    <img
                                      src="../assets/icons/edit.svg"
                                      alt=""
                                    />
                                    Upload Resume
                                  </li>

                                  <li
                                    className="dropdown-item "
                                    onClick={() => {
                                      setShow(true);
                                      setApplicantId(id);
                                      setOfferViewType("offerLetterBox");
                                    }}
                                  >
                                    <img
                                      src="../assets/icons/preview.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">
                                      Offer Letter Box
                                    </p>
                                  </li>

                                  <li
                                      className="dropdown-item"
                                      onClick={() => changeProgramPopup(id)}
                                    >
                                      <img
                                        src="../assets/icons/edit.svg"
                                        alt=""
                                      />
                                      <p className="dropdown-text">
                                        Program Switch
                                      </p>
                                    </li>
                                </ul>
                              </button>
                            </td>
                          )}
                        </>
                      ) : (
                        <td className="text-center">
                          <button
                            className="action-btn"
                            onClick={() => openActionMenu(index)}
                            onBlur={closeActionMenu}
                          >
                            <img src="../assets/icons/options.svg" />
                            <ul
                              className={
                                menuId === index
                                  ? "action-dropdown active  applicantDropdown"
                                  : "action-dropdown applicantDropdown"
                              }
                            >
                              {interviewStatus === "Completed" && (
                                <li
                                  className="dropdown-item"
                                  onClick={() => {
                                    togglePopup();
                                    setType("feedback");

                                    setApplicantId(id);
                                    setPopupTitle(
                                      firstName +
                                        " " +
                                        lastName +
                                        " Feedback Details"
                                    );

                                    setScholarshipAmount(
                                      item.scholarshipAmount
                                    );
                                    dispatch(
                                      getApplicantFeedbackAns({
                                        applicantId: item.id,
                                      })
                                    );

                                    setViewOnly(true);
                                  }}
                                >
                                  <img
                                    src="../assets/icons/preview.svg"
                                    alt=""
                                  />
                                  <p className="dropdown-text">
                                    View Feedback Details
                                  </p>
                                </li>
                              )}
                            </ul>
                          </button>
                        </td>
                      )}
                    </tr>
                  );

                  // return (

                  // );
                })}
            </tbody>
          </table>
        </div>

        {applicantListArray?.length !== applicantCount && (
          <div className="loadMoreRow">
            <button
              className={`${
                isLoading && showMoreLoader
                  ? "primary-btn-a active"
                  : "primary-btn-a"
              }`}
              onClick={handleLoadClick}
            >
              <img src="../assets/icons/loader icon-01.png" /> Show More
            </button>
          </div>
        )}

        {/* <div className=" pg-arrows-outer ">
          <p className="pg-arraows-p">
            {pageNo} of{" "}
            {applicantCount <= pageSize
              ? "1"
              : Math.ceil(applicantCount / pageSize)}
          </p>
          <div className="pg-arrows">
            <a onClick={setPreviousPage}>
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                className="pg-left"
              />
            </a>
            <a onClick={setNextPage}>
              <img
                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                className="pg-right"
              />
            </a>
          </div>
        </div> */}
      </div>

      {/* FILTER - MENU - STARTS */}
      <div className={`filterMenuOuter ${isFilterMenuActive ? "active" : ""}`}>
        <div className="filterHeader">
          <div className="heading">Filters</div>
          <img
            src="../assets/icons/close.svg"
            alt=""
            className="pointer"
            onClick={() => setisFilterMenuActive(false)}
          />
        </div>
        <div className="filterBody">
          <div className="text-1-bold">Admission Fee</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setAdmissionFee(e.target.value)}
              ref={filter1}
            >
              <option value="">All</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
          <div className="mt-15 text-1-bold">Interview Status</div>

          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setInterviewStatus(e.target.value)}
              ref={filter2}
            >
              <option value="">All</option>
              <option value="Completed">Completed</option>
              <option value="Pending">Pending</option>
              <option value="Scheduled">Scheduled</option>
              <option value="NotAppeared">No Show</option>
              <option value="NotInterested">Not Interested</option>
              <option value="NotEligible">Not Eligible</option>
            </select>
          </div>

          <div className="mt-15 text-1-bold">Approval Status</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setApprovalStatus(e.target.value)}
              ref={filter3}
            >
              <option value="">All</option>
              <option value="Request Pending">Request Pending</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
              <option value="Waitlisted">Waitlisted</option>
              <option value="Approval Pending">Approval Pending</option>
            </select>
          </div>

          {/* <div className="mt-15 text-1-bold">Interview Availability</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setInterviewAvailability(e.target.value)}
              ref={filter4}
            >
              <option value="">All</option>
              <option value="NotAppeared">No Show</option>
              <option value="NotInterested">Not Interested</option>
              <option value="NotEligible">Not Eligible</option>
            </select>
          </div> */}

          {/* <div className="selectOuter"></div> */}
        </div>
        <div className="filterFooter">
          <div className="btnGrp">
            <button className="dark-btn-outline-a" onClick={clickClear}>
              Clear
            </button>
            <button className="primary-btn-a" onClick={clickApply}>
              Apply
            </button>
          </div>
        </div>
      </div>
      {/* FILTER- MENU- ENDS */}
    </>
  );
};

export default ApplicantList;
