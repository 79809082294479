import React, { useState } from "react";
import SunEditor from "suneditor-react";
import mergeTag from "../../Editor/EditorPlugin";
import "suneditor/dist/css/suneditor.min.css";

const CreateTemplateListPopup = (props) => {
  const [showEditTemplate, setEditTemplate] = useState(true);
  const handleEditorChange = (content) => {
    props.setTemplateBody(content);
  };

  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        {
          props.viewTemplateList ?
          <div
            className={`popup-container mail-popped ${showEditTemplate ? "active" : ""
              }`}
          >
            <div className="popup-header">
              <div className="popup-heading">
                Template Preview
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={props.handleClose}
                />
              </div>
              <div className="popup-header-body">
                <div className="mail-head-shell">
                  <div className="mail-head-shell-inside">
                    <span className="text-1">Template Name</span>
                    <p className="text-1-bold mt-10">
                      {props.singleTemplateData && props.singleTemplateData.templateName}
                    </p>
                  </div>
  
                  <div className="mail-head-shell-inside">
                    <span className="text-1">Template Subject</span>
                    <p className="text-1-bold mt-10">
                      {props.singleTemplateData && props.singleTemplateData.templateSubject}
  
                    </p>
                  </div>
  
                  <div className="mail-head-shell-inside width-100 mt-50">
                    <span className="text-1">Template Body</span>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="popup-body only-img scrollbar">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.singleTemplateData && props.singleTemplateData.templateBody
                }}
              />
            </div>
          </div>
          :
          <div
          className={`popup-container mail-popped withedit ${
            showEditTemplate ? "active" : ""
          } `}
        >
          <div className="popup-header">
            <div className="popup-heading">
              Edit Template
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>

            <div className="popup-header-body ">
              <div className="mail-head-shell">
                <div className="mail-head-shell-inside">
                  <div className="form-grp mb-20">
                    <label htmlFor="email">Template Name</label>
                    <input
                      type="text"
                      className="form-input"
                      value={props.templateName}
                      onChange={(e) => props.setTemplateName(e.target.value)}
                      placeholder="Enter name"
                    />
                  </div>
                </div>

                <div className="mail-head-shell-inside">
                  <div className="form-grp mb-20">
                    <label htmlFor="email">Template Subject</label>
                    <input
                      type="text"
                      className="form-input"
                      value={props.templateSubject}
                      onChange={(e) => props.setTemplateSubject(e.target.value)}
                      placeholder="Enter subject"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-body scrollbar">
            <div className="mail-head-shell-inside width-100 mt-10">
              <span className="text-5 mb-5 dis-block">Email Body</span>
              <SunEditor
              setContents={props.templateBody}
                showToolbar={true}
                onChange={handleEditorChange}
                setDefaultStyle="height: auto"
                setOptions={{
                  plugins: [mergeTag],
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "list",
                      "align",
                      "fontSize",
                      "formatBlock",
                      "table",
                      "fullScreen",
                      "preview",
                      "codeView",
                    ],
                    [
                      {
                        name: "merge_tag",
                        dataCommand: "merge_tag",
                        buttonClass: "",
                        title: "Add Token",
                        dataDisplay: "submenu",
                        innerHTML: "<span>Token</span>",
                      },
                    ],
                  ],
                }}
              />
            </div>
          </div>

          <div className="popup-footer mail-popup-footer">
            <button className="danger-btn width-auto"
            onClick={props.updateTemplateSubmit}>Update Template</button>
          </div>
        </div>
        }
      </div>
    </React.Fragment>
  );
};

export default CreateTemplateListPopup;
