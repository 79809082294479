import React from "react";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import "./CourseListStyle.css";

function CourseList() {
  return (
    <>
      <SideMenu />
      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">All Course</p>
          <button className="primary-btn feed-btn">
            <img src="../assets/icons/plus.svg" alt="" /> Add Course
          </button>
        </div>
        <div className="student-container">
          <div className="student-info-outer">
            <div className="student-inner-left">
              <div className="student-details-outer">
                <div className="student-details">
                  <p className="student-name text-18">
                    Applied Finance
                    <img src="../assets/icons/sectionSymbol.svg" />{" "}
                    <span className="text-danger">All Courses</span>
                  </p>
                </div>
                <div className="student-details">
                  <p className="student-mail">2022 &bull; 10 months</p>
                </div>
              </div>
            </div>
            <div className="student-inner-right bg-grey">
              <div className="fee-box">
                <p>All Courses</p>
                <p className="text-danger">4</p>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-bar">
          <form action="" className="search-bar">
            <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Search"
              className="clean-input"
            />
          </form>
          <div className="filters">
            <select id="type">
              <option value="">All Feedback</option>
              <option value="Class">Class</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
            <select id="type">
              <option value="">All Status</option>
              <option value="expire">Expired</option>
              <option value="draft">Draft</option>
              <option value="active">Active</option>
            </select>
            <select id="type">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div className="feedback-container scrollbar course-table-outer">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th className="custom-width">Course name</th>
                <th className="custom-width">Faculty</th>
                <th>Cluster</th>
                <th>Course Code</th>
                <th>Type</th>
                <th>Credits</th>
                <th>Hours</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="custom-width">
                  <p className="primary-text pointer">
                    How Does the Economy Work (Part 1)
                  </p>
                </td>
                <td className="custom-width">
                  <p className="faculty-details">
                    <b>Narendra Jadhav</b> MP, Rajya Sabha & Chief Economist,
                    RBI
                  </p>
                </td>
                <td>Marketing</td>
                <td>MABD1625</td>
                <td>Core</td>
                <td>2</td>
                <td>20</td>
                <td>
                  <p className="tag-expired">Unpublish</p>
                </td>
                <td className="text-center">
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                  </button>
                  <ul className="action-dropdown  course-dropdown">
                    <li className="dropdown-item">
                      <img src="../assets/icons/table.svg" alt="" />
                      <p className="dropdown-text">View all Request</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/message.svg" alt="" />
                      <p className="dropdown-text">Assignments</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/file.svg" alt="" />
                      <p className="dropdown-text">Grades</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/edit.svg" alt="" />
                      <p className="dropdown-text">Edit Course Details</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/fileText.svg" alt="" />
                      <p className="dropdown-text">Course Design</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/cross-black.svg" alt="" />
                      <p className="dropdown-text">Unpublish</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/delete.svg" alt="" />
                      <p className="dropdown-text text-danger">Delete</p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default CourseList;
