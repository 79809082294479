import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import PageLoader from "../../../components/UI/loader/PageLoader";
import CreateInterviewPopup from "../../../components/Popups/createInterviewPopup/createInterviewPopup";
import moment from "moment";

import {
  getRequestedApplicantList,
  applicantData,
  clearState,
  clearCsvState,
  deleteInterView,
  getApplicantFeedbackAns,
  approveApplicants,
  rejectApplicants,
  generateNewPartialFeeDue,
  getWelcomeLetterStudentList,
  postStudentAvailability,
} from "../../../reducers/applicantSlice";
import ToastMessage from "../../../components/Toast/ToastComponent";
import "./ShortListStyle.css";
import ActionPopup from "../../../components/Popups/ActionPopup/ActionPopup";
import EditScholarship from "../../../components/Popups/EditFeeDuePopup/EditScholarship";
import RejectWaitlistPopup from "../../../components/Popups/RejectWaitlistPopup/RejectWaitlistPopup";

const ApplicantRequest = () => {
  const navigate = useNavigate();
  const [mailType, setMailType] = useState("");
  const [dueType, setDueType] = useState("");
  const param = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const programBatchId = param.proId;
  const programMasterId = searchParams.get("programMasterId");
  const [isNewOffer, setIsNewOffer] = useState(true);
  const [showMoreLoader, setShowMoreLoader] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [shortListDataArr, setShortListDataArr] = useState([]);

  const [pageNo, setPageNo] = useState("1");
  const [pageSize, setPageSize] = useState("10");

  const [loggedInUser, setLoggedInUser] = useState("");

  const [showMailPopup, setMailPopup] = useState(false);

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    setLoggedInUser(userName);
  }, []);

  //action Popup states
  const [actionMessage, setActionMessage] = useState("");
  const [actionHeading, setActionHeading] = useState("");
  const [showAction, setShowAction] = useState(false);
  const [actionType, setActionType] = useState("");
  const [actionName, setActionName] = useState("");

  const [searchTitle, setSearchTitle] = useState("");
  const [emailStatus, setEmailStatus] = useState("false");
  const [approvedStatus, setApprovedStatus] = useState("Approval Pending");
  const [welcomeStatus, setWelcomeStatus] = useState("false");

  const [scholarshipPopup, setScholarshipPopup] = useState(false);
  const [scholarshipType, setScholarshipType] = useState("");

  const handleSearch = (event) => {
    var search = event.target.value;
    setSearchTitle(search);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchByTitle(e.target.value);
    }
  };

  const searchByTitle = (val) => {
    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: val,
      emailStatus: emailStatus,
      approvedStatus: approvedStatus,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    dispatch(getRequestedApplicantList({ obj, params }));
    dispatch(clearState());
    setIsChecked([]);
    setAllChecked(false);
    setPageNo("1");
  };

  useEffect(() => {
    setPageNo("1");
    setIsChecked([]);
    setAllChecked(false);
  }, [pageSize, approvedStatus, emailStatus]);

  const [menuId, setMenuId] = useState(null);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isInterviewPopup, setIsInterviewPopup] = useState(false);
  const [applicantId, setApplicantId] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [interviewerId, setInterviewerId] = useState("");
  const [scholarshipAmount, setScholarshipAmount] = useState(0);
  const [feeDueId, setFeeDueId] = useState("");
  const [type, setType] = useState("");

  const [viewOnly, setViewOnly] = useState(false);

  const closeInterviewPopup = () => {
    setIsInterviewPopup(false);
    setInterviewerId("");
    setApplicantId("");
    setPopupTitle("");
    setScholarshipAmount(0);
    setType("");
    setViewOnly(false);
  };

  //check logic
  const [allChecked, setAllChecked] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isTotalSelected, setTotalSelected] = useState(false);
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [shortListId, setShortListId] = useState([]);

  const handleAllCheck = (e) => {
    let currStdIds = shortListDataArr.map((data) => data.id);
    const currSet = new Set(currStdIds);
    if (allChecked) {
      setTotalSelected(false);
      setAllChecked(false);
      let newArr = isChecked.filter((val) => !currSet.has(val));
      return setIsChecked(newArr);
    }
    setAllChecked(true);
    let newIsChecked = [...isChecked];
    currStdIds.map((val) => {
      if (!newIsChecked.includes(val)) {
        newIsChecked.push(val);
      }
    });
    return setIsChecked(newIsChecked);
  };

  console.log("program master id", programMasterId);

  const handleSingleCheck = (e) => {
    const id = e.target.value;
    if (isChecked.includes(id)) {
      setIsChecked(isChecked.filter((checked_id) => checked_id !== id));
      setTotalSelected(false);
      return setAllChecked(false);
    }
    isChecked.push(id);
    setIsChecked([...isChecked]);
    checkAllChecked();
  };
  const checkAllChecked = () => {
    let stdIds = shortListDataArr.map((item) => item.id);
    let isAll = stdIds.every((val) => isChecked.includes(val));
    if (!isAll) {
      setTotalSelected(false);
    }
    setAllChecked(isAll);
  };
  const clearSelected = () => {
    setTotalSelected((prev) => !prev);
    setIsChecked([]);
    setAllChecked(false);
  };

  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const {
    shortListData,
    shortListCount,
    isFetching,
    feedbackAns,
    isSuccess,
    isError,
    deleteInterViewList,
    admissionAmount,
    offerLetterAmount,
    tutionFeeAmount,
  } = useSelector(applicantData);

  const deleteShortlistMessage = () => {
    if (deleteInterViewList) {
      setTimeout(() => setEnableSuccessMessage(true), 2000);
      dispatch(clearCsvState());
      setIsChecked([]);
      setAllChecked(false);
    }
  };

  useEffect(() => {
    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: searchTitle,
      emailStatus: emailStatus,
      approvedStatus: approvedStatus,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    dispatch(getRequestedApplicantList({ obj, params }));
    dispatch(clearState());
    // setIsChecked([]);
    // setAllChecked(false);
  }, [emailStatus, approvedStatus, pageNo, pageSize, welcomeStatus]);

  useEffect(() => {
    console.log("in shortList", isSuccess);
    if (isSuccess) {
      closeInterviewPopup();
      setShowAction(false);
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        approvedStatus: approvedStatus,
        pageNo: pageNo,
        pageSize: pageSize,
      };

      dispatch(getRequestedApplicantList({ obj, params }));
      dispatch(clearState());
      setIsChecked([]);
      setAllChecked(false);

      setScholarshipPopup(false);
      setScholarshipType("");
      setApplicantId("");
      setViewOnly(false);
      closeInterviewPopup();
      setMailPopup(false);
      setApplicantId("");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (shortListData) {
      console.log("checking loaded status", loaded);
      if (loaded === true) {
        setShortListDataArr([...shortListDataArr, ...shortListData]);
        setLoaded("done");
      } else if (loaded === false) {
        setShortListDataArr(shortListData);
      } else {
        console.log(null);
      }
      setLoaded(false);
    }

    // setApplicantListArray(applicantListDetail);
  }, [shortListData]);

  useEffect(() => {
    deleteShortlistMessage();
  }, [deleteInterViewList]);

  const onApproveClick = (ids) => {
    if (ids.length > 0) {
      var params = {
        searchTitle: searchTitle,
        emailStatus: emailStatus,
        approvedStatus: approvedStatus,
      };
      dispatch(
        approveApplicants({
          obj: ids,
          programId: programBatchId,
          isTotalSelected,
          approvedBy: loggedInUser,
          query: params,
        })
      );
      setIsChecked([]);
      setAllChecked(false);
      setPageNo("1");
    }
  };
  const onRejectClick = (ids) => {
    if (ids.length > 0) {
      dispatch(
        rejectApplicants({
          obj: ids,
          programId: programBatchId,
          programMasterId: programMasterId,
        })
      );
      setIsChecked([]);
      setAllChecked(false);
      setPageNo("1");
    }
  };

  const onClickWaitlistApplicant = (id) => {
    let obj = {
      isApplicantApproved: "Waitlisted",
      applicantId: id,
      programId: programBatchId,
      programMasterId: programMasterId,
    };
    console.log("printing obejc", obj);

    dispatch(postStudentAvailability(obj));
  };

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onSubmit = () => {
    if (actionName === "Approve") {
      if (actionHeading === "Approve Candidates") {
        onApproveClick(isChecked);
      } else {
        if (applicantId !== "") {
          onApproveClick([applicantId]);
        }
      }
    } else if (actionName === "Reject") {
      if (applicantId !== "") {
        onRejectClick([applicantId]);
      }
    } else if (actionName === "waitlist") {
      if (applicantId !== "") {
        onClickWaitlistApplicant(applicantId);
      }
    } else if (actionName === "Delete") {
      if (applicantId === "" || feeDueId === "") {
        return;
      }
      dispatch(
        generateNewPartialFeeDue({
          applicantId: applicantId,
          feeDueId: feeDueId,
        })
      );
    }
  };

  const mTotalPayableFee = (tutionFee, admissionFee, scholarship) => {
    let scholarshipAmount = parseFloat(scholarship * tutionFee) / 100;
    let gettingTotalTutionFee =
      parseFloat(tutionFee) - parseFloat(scholarshipAmount);
    let gettingTotalAmount =
      parseFloat(gettingTotalTutionFee) + parseFloat(admissionFee);
    let totalPayableFee =
      (parseFloat(gettingTotalAmount) * 18) / 100 +
      parseFloat(gettingTotalAmount);

    console.log("total payable amount", totalPayableFee);

    return parseFloat(totalPayableFee).toFixed(2);

    // return
  };

  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);
  const [isMailAccess, setMailAccess] = useState(false);
  const [isOfferAccess, setOfferAccess] = useState(false);
  const [studentFeedbackAccess, setFeedbackAccess] = useState(false);

  useEffect(() => {
    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    const isOfferAccess =
      localStorage.getItem("offerLetterApproval") === "true";
    const isMailAccess = localStorage.getItem("mailSending") === "true";
    const interviewFeedback =
      localStorage.getItem("interviewFeedback") === "true";
    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
    if (isOfferAccess) {
      setOfferAccess(isOfferAccess);
    }
    if (isMailAccess) {
      setMailAccess(isMailAccess);
    }
    if (interviewFeedback) {
      setFeedbackAccess(interviewFeedback);
    }

    setShortListDataArr([]);
  }, []);

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  const handleLoadClick = () => {
    if (pageNo == Math.ceil(shortListCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
    setLoaded(true);
    setShowMoreLoader(true);
  };

  const onClickApproveButton = (id, status) => {
    if (status) {
      setScholarshipPopup(true);
      setScholarshipType("view");
      setApplicantId(id);
      console.log("inside if consditon");
    } else {
      console.log("inside else condition consditon");
      setApplicantId(id);
      setShowAction(true);
      setActionHeading("Approve Candidate");
      setActionMessage("Approve this candidate");
      setActionName("Approve");
      setActionType("success");
    }
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onClickWaitlist = (id) => {
    // toggleMailPopup();
    // setMailType("waitList");

    setApplicantId(id);
    setShowAction(true);
    setActionHeading("Waitlist Candidate");
    setActionMessage("Waitlist this candidate");
    setActionName("waitlist");
    setActionType("warning");
  };

  const onClickReject = (id) => {
    // toggleMailPopup();
    // setMailType("reject");

    setApplicantId(id);
    setShowAction(true);
    setActionHeading("Reject Candidate");
    setActionMessage("Reject this candidate");
    setActionName("Reject");
    setActionType("warning");
  };

  const toggleMailPopup = () => {
    setMailPopup(!showMailPopup);
  };

  const formatName = (firstName, lastName) => {
    var name = (firstName + " " + lastName)
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");

    return name;
  };

  const viewStudentTimeline = (id, isStudent) => {
    window.open(
      `/particularStudentfee?studentId=${id}&programBatchId=${programBatchId}&isStudent=${isStudent}`
    );
  };

  return (
    <>
      <SideMenu />
      {isFetching && pageNo === "1" ? <PageLoader /> : ""}
      <ActionPopup
        show={showAction}
        heading={actionHeading}
        handleClose={() => {
          setShowAction(false);
          setApplicantId("");
          setFeeDueId("");
        }}
        message={actionMessage}
        action={actionName}
        actionType={actionType}
        onSubmit={onSubmit}
      />

      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Shorlisted deleted successfully"}
      />

      <EditScholarship
        closePopup={() => {
          setScholarshipPopup(false);
          setScholarshipType("");
          setApplicantId("");
        }}
        show={scholarshipPopup}
        scholarshipType={scholarshipType}
        programBatchId={programBatchId}
        studentId={applicantId}
        setLoaded={setLoaded}
        setPageNo={setPageNo}
      />

      <CreateInterviewPopup
        type={type}
        togglePopup={closeInterviewPopup}
        show={isInterviewPopup}
        feedbackAns={feedbackAns}
        interviewerId={interviewerId}
        applicantId={applicantId}
        programBatchId={programBatchId}
        popupTitle={popupTitle}
        scholarshipAmount={scholarshipAmount}
        setLoaded={setLoaded}
        setPageNo={setPageNo}
        viewOnly={viewOnly}
      />

      <RejectWaitlistPopup
        handleClose={() => {
          setMailPopup(false);
          setApplicantId("");
        }}
        show={showMailPopup}
        mailType={mailType}
        programMasterId={programMasterId}
        programBatchId={programBatchId}
        applicantId={applicantId}
        setPageNo={setPageNo}
      />

      <div className="main-body">
        <div className="page-header">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="component-back"
          >
            <img src="../assets/icons/back-btn.svg" /> Back to Page
          </button>

          <p className="text-1"></p>

          <div className="studentDetails">
            <div className="student-inner-left">
              <div className="student-details-outer">
                <div className="student-details">
                  <p className="student-name text-18">
                    {admissionAmount?.ProgramDetail?.Program?.programName}{" "}
                    <img src="../assets/icons/sectionSymbol.svg" />{" "}
                    <span className="text-danger">Approval Request</span>
                  </p>
                </div>
                <div className="student-details">
                  <p className="student-mail">
                    {admissionAmount?.ProgramDetail?.batch} &#xb7;{" "}
                    {admissionAmount?.ProgramDetail?.durationInMonths} months
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-bar">
          <form action="" className="search-bar">
            <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              onChange={handleSearch}
              onKeyDown={onSearchKeyPress}
              placeholder="Search"
              className="clean-input"
            />
          </form>
          <div className="total-data-desc">
            Showing {shortListDataArr?.length} out of {shortListCount}
          </div>
          <div className="filters">
            <select id="type" onChange={(e) => setPageSize(e.target.value)}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div className="feedback-container scrollbar shortlist tableOverflow">
          <table
            className="feedback-table table"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th>Candidate name</th>
                <th>Phone Number</th>
                <th>Email Id</th>
                <th>Scholarship </th>
                <th>Interviewer Name</th>
                <th>Total Amount Payable</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {shortListDataArr &&
                shortListDataArr.map((item, index) => (
                  <tr key={item.id}>
                    {/* <td>
                      <div className="programDetail">
                        {formatName(item.firstName, item.lastName)}

                        {item.isStudent && (
                          <div className="activeCourse">Student</div>
                        )}
                      </div>
                    </td> */}

                    <td className="programDetail">
                      <div
                        onClick={() =>
                          viewStudentTimeline(item.id, item.isStudent)
                        }
                        className="programDetailText"
                      >
                        <p className="primary-text pointer">
                          {(item.firstName + " " + item.lastName)
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.substring(1)
                            )
                            .join(" ")}
                        </p>
                      </div>
                      {item.isStudent && (
                        <div className="activeCourse">Student</div>
                      )}
                    </td>

                    <td>{item.mobileNumber}</td>
                    <td>{item.officialEmail}</td>
                    <td>
                      {item.isApplicantApproved === "Rejected" ? (
                        "--"
                      ) : (
                        <>
                          ₹{" "}
                          {parseFloat(
                            item?.scholarshipAmount * tutionFeeAmount?.amount
                          ) / 100}{" "}
                          ({item?.scholarshipAmount} %){" "}
                        </>
                      )}
                    </td>
                    {/* {item?.ApplicantInterviews?.length > 0 && ( */}
                    <td>
                      {item?.ApplicantInterviews[0]?.interviewerName ?? "--"}
                    </td>
                    {/* )} */}

                    <td>
                      ₹{" "}
                      {mTotalPayableFee(
                        tutionFeeAmount?.amount,
                        admissionAmount?.amount,
                        item?.scholarshipAmount
                      )}
                    </td>

                    {item.isApplicantApproved === "Pending" ||
                    item.isApplicantApproved === "Approval Pending" ? (
                      <td className="btn-grp">
                        <button
                          className="green-btn"
                          onClick={() =>
                            onClickApproveButton(
                              item?.id,
                              item?.isScholarshipEdited
                            )
                          }
                        >
                          <img src="../../assets/icons/tickmark.svg" />
                          Approve
                          {/* {item?.isScholarshipEdited ? "Scholarship Update" : "Approve"} */}
                        </button>
                        {!item?.isScholarshipEdited && (
                          <>
                            <button
                              className="danger-btn"
                              onClick={() => {
                                onClickReject(item?.id);
                                // setApplicantId(item?.id);
                              }}
                            >
                              <img src="../../assets/icons/redCross.svg" />{" "}
                              Reject
                            </button>

                            <button
                              className="yellow-btn"
                              onClick={() => {
                                onClickWaitlist(item?.id);
                                // setApplicantId(item?.id);
                              }}
                            >
                              <img src="../../assets/icons/tickmark.svg" />
                              Waitlist
                            </button>
                          </>
                        )}
                      </td>
                    ) : (
                      <td>
                        <p className="tag-expired">Rejected</p>
                      </td>
                    )}

                    <td className="text-center">
                      <button
                        className="action-btn"
                        onClick={() => openActionMenu(index)}
                        onBlur={closeActionMenu}
                      >
                        <img src="../assets/icons/options.svg" />
                        <ul
                          className={`action-dropdown ${
                            menuId === index ? "active" : ""
                          } approval-drpdown`}
                        >
                          <>
                            <li
                              className="dropdown-item"
                              onClick={() => {
                                setType("feedback");
                                setInterviewerId(
                                  item.ApplicantInterviews[0].id
                                );
                                setApplicantId(item.id);
                                setIsInterviewPopup(true);
                                setPopupTitle(
                                  formatName(item.firstName, item.lastName) +
                                    " Feedback"
                                );
                                setScholarshipAmount(item.scholarshipAmount);
                                dispatch(
                                  getApplicantFeedbackAns({
                                    applicantId: item.id,
                                  })
                                );
                                setViewOnly(true);
                              }}
                            >
                              <img src="../assets/icons/preview.svg" alt="" />
                              <p className="dropdown-text">View Feedback</p>
                            </li>

                            <li
                              className="dropdown-item"
                              onClick={() => openInNewTab(item?.resume)}
                            >
                              <img src="../assets/icons/preview.svg" alt="" />
                              <p className="dropdown-text">View Resume</p>
                            </li>

                            <li
                              className="dropdown-item"
                              onClick={() => openInNewTab(item?.coverLetter)}
                            >
                              <img src="../assets/icons/preview.svg" alt="" />
                              <p className="dropdown-text">View Profile</p>
                            </li>
                          </>
                        </ul>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {shortListDataArr?.length !== shortListCount && (
          <div className="loadMoreRow">
            <button
              className={`${
                isFetching && showMoreLoader
                  ? "primary-btn-a active"
                  : "primary-btn-a"
              }`}
              onClick={handleLoadClick}
            >
              <img src="../assets/icons/loader icon-01.png" /> Show More
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ApplicantRequest;
