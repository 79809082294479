import React from "react";
import "./AddClusterPopupStyle.css";
const AddClusterPopup = (props) => {
  console.log("print data", props);
  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container cluster-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Add New Cluster
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>
          </div>
          <div className="popup-body">
            <div className="form-group mt-20">
              <label htmlFor="name" className="create-label">
                Program Name <span className="imp-mark">*</span>
              </label>
              <input type="text" className="create-input mt-5" />
              <span className="error-msg">Enter the field</span>
            </div>
          </div>
          <div className="popup-footer mt-10">
            <div className="btn-group">
              <button onClick={props.handleClose} className="dark-btn-outline">
                cancel
              </button>
              <button className="primary-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddClusterPopup;
