import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let adminId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};

export const getApplicantProgramListData = createAsyncThunk(
  "applicants/getApplicantProgramListData",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/adminId/" +
          adminId +
          "/getApplicantProgramList",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const postStudentAvailability = createAsyncThunk(
  "applicants/postStudentAvailability",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/applicantId/" +
          obj?.applicantId +
          `/applicantInterviewAppearedStatus`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const putUpdateApplicantOfferStatus = createAsyncThunk(
  "applicants/putUpdateApplicantOfferStatus",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/applicant/" +
          obj?.applicantId +
          `/updateApplicantOfferLetterStatus`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postAddApplicantFeeManually = createAsyncThunk(
  "applicants/postAddApplicantFeeManually",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/applicantId/" +
          obj?.applicantId +
          "/admin/" +
          adminId +
          `/addStudentFromApplicantManually`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getApplicantDetailList = createAsyncThunk(
  "applicants/getApplicantDetailList",
  async ({ obj, params }, thunkAPI) => {
    try {
      setTokenValues();
      var search = params?.searchTitle ?? "";
      var result = params?.result ?? "";
      var interviewStatus = params?.interviewStatus ?? "";
      var pageSize = params?.pageSize ?? "10";
      var pageNo = params?.pageNo ?? "1";
      var admissionFee = params?.admissionFee ?? "";
      var descending = params?.descending ?? "";
      var date = params?.date ?? "";
      var approvalStatus = params?.approvalStatus ?? "";
      var applicantInterviewStatus = params?.applicantInterviewStatus ?? "";

      let batchId = obj.programBatchId;
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          `/getApplicantDetails?searchTitle=${search}&result=${result}&interviewStatus=${interviewStatus}&pageSize=${pageSize}&pageNo=${pageNo}&admissionFee=${admissionFee}&descending=${descending}&date=${date}&approvalStatus=${approvalStatus}&applicantInterviewStatus=${applicantInterviewStatus}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const scheduleApplicantInterview = createAsyncThunk(
  "applicants/scheduleApplicantInterview",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const payload = { obj };

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          `/scheduleApplicantInterview`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const submitInterviewFeedbackAnswers = createAsyncThunk(
  "applicants/submitInterviewFeedbackAnswers",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let applicantId = obj.applicantId;
      const payload = {
        interviewFeedbackAnswers: obj.answers,
        status: obj.status,
        scholershipAmt: obj.scholershipAmt,
        programId: obj.programId,
      };

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/applicant/" +
          applicantId +
          `/submitInterviewFeedbackAnswers`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const shortlistStudents = createAsyncThunk(
  "applicants/shortlistStudents",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const payload = {
        applicantArray: obj.applicants,
        isTotalSelected: obj.isTotalSelected,
        query: obj.params,
      };
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/program/" +
          obj.programId +
          `/shortlistStudents`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getShortlistedApplicantList = createAsyncThunk(
  "applicants/getShortlistedApplicantList",
  async ({ obj, params }, thunkAPI) => {
    try {
      setTokenValues();

      var searchTitle = params?.searchTitle ?? "";
      var emailStatus = params?.emailStatus ?? "";
      var approvedStatus = params?.approvedStatus ?? "";
      // var interviewStatus = params?.interviewStatus ?? "";
      var pageSize = params?.pageSize ?? "10";
      var pageNo = params?.pageNo ?? "1";

      let batchId = obj.programBatchId;
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          `/getAllShorlistedCandidate?searchTitle=${searchTitle}&emailStatus=${emailStatus}&approvedStatus=${approvedStatus}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getRequestedApplicantList = createAsyncThunk(
  "applicants/getRequestedApplicantList",
  async ({ obj, params }, thunkAPI) => {
    try {
      setTokenValues();

      var searchTitle = params?.searchTitle ?? "";
      var emailStatus = params?.emailStatus ?? "";
      var approvedStatus = params?.approvedStatus ?? "";
      // var interviewStatus = params?.interviewStatus ?? "";
      var pageSize = params?.pageSize ?? "10";
      var pageNo = params?.pageNo ?? "1";

      let batchId = obj.programBatchId;
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          `/getApprovalRequestCandidates?searchTitle=${searchTitle}&emailStatus=${emailStatus}&approvedStatus=${approvedStatus}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getWelcomeLetterStudentList = createAsyncThunk(
  "applicants/getWelcomeLetterStudentList",
  async ({ obj, params }, thunkAPI) => {
    try {
      setTokenValues();

      var searchTitle = params?.searchTitle ?? "";
      var emailStatus = params?.emailStatus ?? "";
      var pageSize = params?.pageSize ?? "10";
      var pageNo = params?.pageNo ?? "1";

      let batchId = obj.programBatchId;
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          `/getAllWelcomeLetterStudents?searchTitle=${searchTitle}&emailStatus=${emailStatus}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const importStudentCsvFile = createAsyncThunk(
  "applicants/importStudentCsvFile",
  async ({ selectedFile, progId }, thunkAPI) => {
    try {
      setTokenValues();
      // const payload = { selectedFile };
      const formData = new FormData();
      formData.append("file", selectedFile);
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/adminId/" +
          adminId +
          "/program/" +
          progId +
          `/applicantCsvUpload`,

        {
          method: "POST",
          headers: {
            // Accept: "application/json",
            // "Content-Type": "multipart/form-data",
            Authorization: token,
          },
          body: formData,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /:orgId/admin/:adminId/downloadSampleMailCSV
export const studentSampleFileDownload = createAsyncThunk(
  "applicants/studentSampleFileDownload",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          `/downloadSampleMailCSV`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.text();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getApplicantFeedbackAns = createAsyncThunk(
  "applicants/getApplicantFeedbackAns",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      let applicantId = obj.applicantId;
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/applicant/" +
          applicantId +
          `/getApplicantFeedbackAns`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** DELETE Interview idArray*/
export const deleteInterView = createAsyncThunk(
  "applicants/deleteInterView",
  async ({ Id, query, isTotalSelected, programId }, thunkAPI) => {
    try {
      setTokenValues();
      const payload = { idArray: Id, query, isTotalSelected };
      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/program/${programId}/deleteApplicants`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const approveApplicants = createAsyncThunk(
  "applicants/approveApplicants",
  async ({ programId, obj, query, approvedBy, isTotalSelected }, thunkAPI) => {
    try {
      setTokenValues();

      const payload = { applicants: obj, isTotalSelected, query, approvedBy };
      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/admin/${adminId}/program/${programId}/approveApplicants`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const rejectApplicants = createAsyncThunk(
  "applicants/rejectApplicants",
  async (
    { programId, obj, programMasterId, templateBody, templateSubject },
    thunkAPI
  ) => {
    try {
      setTokenValues();
      const payload = {
        applicants: obj,
        specificTemplateBody: templateBody,
        specificTemplateSubject: templateSubject,
        programMasterId: programMasterId,
      };

      console.log("priting payload data", payload);

      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/admin/${adminId}/program/${programId}/rejectApplicants`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createMeetLink = createAsyncThunk(
  "applicants/createMeetLink",
  async ({ programId, obj }, thunkAPI) => {
    try {
      setTokenValues();

      const payload = { time: obj.time, emails: obj.email };
      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/createMeetLink`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// /:organizationId/admin/:adminId/sendOfferLettersToApplicants
export const sendAcceptanceOfferLatterEmail = createAsyncThunk(
  "applicants/sendAcceptanceOfferLatterEmail",
  async ({ obj, query, isTotalSelected }, thunkAPI) => {
    try {
      setTokenValues();
      const payload = { ...obj, isTotalSelected, query };
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/sendOfferLettersToApplicants",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// export const sendOfferLetterApplicantEmailToVendor = createAsyncThunk(
//   "applicants/sendOfferLetterApplicantEmailToVendor",
//   async ({ obj, query, isTotalSelected }, thunkAPI) => {
//     try {
//       setTokenValues();
//       const payload = { ...obj, isTotalSelected, query };
//       const response = await fetch(
//         environment.baseURL +
//           "/api/org/" +
//           orgId +
//           "/admin/" +
//           adminId +
//           "/sendApplicantsToVendor",
//         {
//           method: "POST",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: token,
//           },
//           body: JSON.stringify(payload),
//         }
//       );
//       let data = await response.json();
//       console.log("response", data);
//       if (response.status === 200) {
//         return data.Data;
//       } else {
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (e) {
//       console.log("Error", e.response.data);
//       thunkAPI.rejectWithValue(e.response.data);
//     }
//   }
// );

export const editOfferLetterAmountEmail = createAsyncThunk(
  "applicants/editOfferLetterAmountEmail",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/feeDueId/" +
          obj?.feeDueId +
          "/editFeeDue",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getApplicantUsers = createAsyncThunk(
  "applicants/getApplicantUsers",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/user/" + orgId + "/getAllUsers",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const generateNewPartialFeeDue = createAsyncThunk(
  "applicants/generateNewPartialFeeDue",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/feeDueId/" +
          obj.feeDueId +
          "/applicantId/" +
          obj.applicantId +
          "/generateNewPartialFeeDue",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getStudentsByProgram = createAsyncThunk(
  "applicants/getStudentsByProgram",
  async ({ programId, params }, thunkAPI) => {
    try {
      setTokenValues();
      var search = params?.searchTitle ?? "";
      var pageSize = params?.pageSize ?? "10";
      var pageNo = params?.pageNo ?? "1";

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          programId +
          `/getStudentsByProgram?searchTitle=${search}&pageSize=${pageSize}&pageNo=${pageNo}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postEditScholarship = createAsyncThunk(
  "applicants/postEditScholarship",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/student/" +
          obj?.studentId +
          "/editStudentScholarshipRequest",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postUpdateApplicantScholarship = createAsyncThunk(
  "applicants/postUpdateApplicantScholarship",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/adminId/" +
          adminId +
          "/student/" +
          obj?.studentId +
          "/editStudentScholarship",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// cash free button data
export const postUpdateCashFreeStatus = createAsyncThunk(
  "applicants/postUpdateCashFreeStatus",
  async (obj, thunkAPI) => {
    try {
      console.log(obj, "new obj");
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/updateCashFreeStatus",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getApplicantScholarship = createAsyncThunk(
  "applicants/getApplicantScholarship",
  async (studentId, thunkAPI) => {
    try {
      setTokenValues();

      console.log("checkign object data", studentId);

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/student/" +
          studentId +
          `/getScholarshipDetails`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// post timeline comment
export const postTimelineComment = createAsyncThunk(
  "applicants/postTimelineComment",
  async (obj, thunkAPI) => {
    try {
      console.log(obj, "new obj");
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/addTimelineComment",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// download student CSV
export const postDownloadStudentCSV = createAsyncThunk(
  "applicants/postDownloadStudentCSV",
  async (programId, thunkAPI) => {
    try {
      console.log(programId, "new obj");
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/programId/" +
          programId +
          "/downloadStudentFeeListCSV",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          // body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sendWelcomeLetterMail = createAsyncThunk(
  "applicants/sendWelcomeLetterMail",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/sendWelcomeMailToStudents",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getProgramsDetails = createAsyncThunk(
  "applicants/getProgramsDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/getProgramsDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateApplicantProgram = createAsyncThunk(
  "applicants/updateApplicantProgram",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          `/updateApplicantProgram`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** Resend interview slot mail */
/** START */
export const putResendInterviewSlotMail = createAsyncThunk(
  "applicants/putResendInterviewSlotMail",
  async ({ applicantId }, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/applicant/" +
          applicantId +
          `/extendInterviewSelectionDate`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ applicantId }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** END */

export const getSendOrientationMailStudentList = createAsyncThunk(
  "applicants/getSendOrientationMailStudentList",
  async ({ obj, params }, thunkAPI) => {
    try {
      setTokenValues();

      var searchTitle = params?.searchTitle ?? "";
      var emailStatus = params?.emailStatus ?? "";
      var pageSize = params?.pageSize ?? "10";
      var pageNo = params?.pageNo ?? "1";

      let batchId = obj.programBatchId;
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/programId/" +
          batchId +
          `/getAllOrientationLetterStudents?searchTitle=${searchTitle}&emailStatus=${emailStatus}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postSendOrientationMailLetter = createAsyncThunk(
  "applicants/postSendOrientationMailLetter",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/sendOrientationMailToStudents",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const postSendMentorCallMail = createAsyncThunk(
  "applicants/postSendMentorCallMail",
  async ({ applicantId }, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/applicant/" +
          applicantId +
          `/sendMentorshipCallMail`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ applicantId }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getCounsellorWisePayrollDetails = createAsyncThunk(
  "applicants/getCounsellorWisePayrollDetails",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + `/api/org/getCounsellorWisePayrollDetails`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**Mentorship Call API  START*/

export const getExternalMentorApplicantList = createAsyncThunk(
  "applicants/getExternalMentorApplicantList",
  async (_, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/org/adminId/" +
          adminId +
          `/getExternalMentorApplicantList`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ adminId }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postUpdateMentorApplicantFeedback = createAsyncThunk(
  "applicants/postUpdateMentorApplicantFeedback",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      // console.log("print applicant id", applicantI);

      console.log("print object", obj);

      // return;
      const response = await fetch(
        environment.baseURL +
          "/api/org/meeting/" +
          obj?.meetingId +
          `/updateMentorshipcallFeedbackAnswers`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj?.data),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**END */

export const applicantSlice = createSlice({
  name: "applicantDetail",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    applicantProgramList: [],
    applicantListDetail: [],
    shortListData: [],
    interviewScheduled: false,
    applicantCount: null,
    shortListCount: null,
    admissionAmount: null,
    importCsv: "",
    sampleFileDownload: null,
    feedbackAns: null,
    isImport: false,
    successMessage: false,
    deleteInterViewList: false,
    meetLink: "",
    sendAcceptanceEmail: false,
    getApplicantUsersData: [],
    programDetails: {},
    studentsList: [],
    studentsCount: null,
    offerLetterAmount: null,
    tutionFeeAmount: 0,
    scholarshipDetails: null,
    welcomeLetterSuccess: false,
    getAllProgramsDetails: [],
    resendMailSuccess: false,
    counsellorPayrollData: null,
    mentorApplicantList: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.welcomeLetterSuccess = false;
      state.resendMailSuccess = false;
      return state;
    },
    clearInterviewState: (state) => {
      state.interviewScheduled = false;
      return state;
    },
    clearCsvState: (state) => {
      state.isImport = false;
      state.successMessage = false;
      state.deleteInterViewList = false;
      return state;
    },
    clearEmailState: (state) => {
      state.sendAcceptanceEmail = false;
      return state;
    },
    clearFeedbackData: (state) => {
      state.feedbackAns = null;
      return state;
    },
  },
  extraReducers: {
    [getApplicantProgramListData.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.applicantProgramList = payload;
      state.isFetching = false;
      return state;
    },
    [getApplicantProgramListData.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getApplicantProgramListData.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getApplicantDetailList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.applicantListDetail = payload?.Data;
      state.applicantCount = payload?.Count;
      state.programDetails = payload?.programDetails;
      state.isFetching = false;
      return state;
    },
    [getApplicantDetailList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getApplicantDetailList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [submitInterviewFeedbackAnswers.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [submitInterviewFeedbackAnswers.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [submitInterviewFeedbackAnswers.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [scheduleApplicantInterview.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.interviewScheduled = true;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [scheduleApplicantInterview.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [scheduleApplicantInterview.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [shortlistStudents.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      // state.interviewScheduled = true;
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = true;
      return state;
    },
    [shortlistStudents.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [shortlistStudents.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getShortlistedApplicantList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.shortListData = payload.Data;
      state.shortListCount = payload.Count;
      state.admissionAmount = payload?.feeAmount;
      state.offerLetterAmount = payload?.studentsRecord[0];
      state.tutionFeeAmount = payload?.tutionFee;
      state.isFetching = false;
      return state;
    },
    [getShortlistedApplicantList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getShortlistedApplicantList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getRequestedApplicantList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.shortListData = payload.Data;
      state.shortListCount = payload.Count;
      state.admissionAmount = payload?.feeAmount;
      state.offerLetterAmount = payload?.studentsRecord[0];
      state.tutionFeeAmount = payload?.tutionFee;
      state.isFetching = false;
      return state;
    },
    [getRequestedApplicantList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getRequestedApplicantList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getWelcomeLetterStudentList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.shortListData = payload.Data;
      state.shortListCount = payload.Count;
      state.admissionAmount = payload?.feeAmount;
      state.offerLetterAmount = payload?.studentsRecord[0];
      state.isFetching = false;
      return state;
    },
    [getWelcomeLetterStudentList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getWelcomeLetterStudentList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getSendOrientationMailStudentList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.shortListData = payload.Data;
      state.shortListCount = payload.Count;
      state.admissionAmount = payload?.feeAmount;
      state.offerLetterAmount = payload?.studentsRecord[0];
      state.isFetching = false;
      return state;
    },
    [getSendOrientationMailStudentList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSendOrientationMailStudentList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

    [importStudentCsvFile.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.importCsv = payload;
      state.isFetching = false;
      state.isSuccess = true;
      state.isImport = true;
      return state;
    },
    [importStudentCsvFile.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [importStudentCsvFile.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [studentSampleFileDownload.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.sampleFileDownload = payload;
      // state.isFetching = false;
      return state;
    },
    [studentSampleFileDownload.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      // state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [studentSampleFileDownload.pending]: (state) => {
      // console.log("inside pending state", state);
      // state.isFetching = true;
    },
    [getApplicantFeedbackAns.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.feedbackAns = payload;
      state.isFetching = false;
      return state;
    },
    [getApplicantFeedbackAns.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getApplicantFeedbackAns.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [deleteInterView.fulfilled]: (state, { payload }) => {
      console.log("printing payload deleting", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.deleteInterViewList = true;
      // state.studentList = payload;
      return state;
    },
    [deleteInterView.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.IntdeleteInterViewList = payload;
    },
    [deleteInterView.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [approveApplicants.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      // state.studentList = payload;
      return state;
    },
    [approveApplicants.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [approveApplicants.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [rejectApplicants.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      // state.studentList = payload;
      return state;
    },
    [rejectApplicants.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [rejectApplicants.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [createMeetLink.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.meetLink = payload;
      // state.studentList = payload;
      return state;
    },
    [createMeetLink.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [createMeetLink.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [sendAcceptanceOfferLatterEmail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      // state.isSuccess = true;
      state.sendAcceptanceEmail = true;
      return state;
    },
    [sendAcceptanceOfferLatterEmail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [sendAcceptanceOfferLatterEmail.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    // [sendOfferLetterApplicantEmailToVendor.fulfilled]: (state, { payload }) => {
    //   // state.isFetching = false;
    //   // state.isSuccess = true;
    //   // state.sendAcceptanceEmail = true;
    //   return state;
    // },
    // [sendOfferLetterApplicantEmailToVendor.rejected]: (state, { payload }) => {
    //   console.log("payload", payload);
    //   // state.isFetching = false;
    //   state.isError = true;
    //   state.errorMessage = payload?.Message || "Something Went Wrong";
    // },
    // [sendOfferLetterApplicantEmailToVendor.pending]: (state) => {
    //   console.log("inside pending state student", state);
    //   // state.isFetching = true;
    // },
    [editOfferLetterAmountEmail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      // state.isSuccess = true;
      state.sendAcceptanceEmail = true;
      return state;
    },
    [editOfferLetterAmountEmail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [editOfferLetterAmountEmail.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getApplicantUsers.fulfilled]: (state, { payload }) => {
      state.getApplicantUsersData = payload;
      return state;
    },
    [getApplicantUsers.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getApplicantUsers.pending]: (state) => {
      console.log("inside pending state student", state);
    },
    [generateNewPartialFeeDue.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [generateNewPartialFeeDue.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [generateNewPartialFeeDue.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getStudentsByProgram.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.studentsList = payload.Data;
      state.studentsCount = payload.Count;
      return state;
    },
    [getStudentsByProgram.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getStudentsByProgram.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [getApplicantScholarship.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.scholarshipDetails = payload.Data;

      console.log("hhhfhhff", payload);
      return state;
    },
    [getApplicantScholarship.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getApplicantScholarship.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [postEditScholarship.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postEditScholarship.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postEditScholarship.pending]: (state) => {
      state.isFetching = true;
    },

    [postUpdateApplicantScholarship.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postUpdateApplicantScholarship.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postUpdateApplicantScholarship.pending]: (state) => {
      state.isFetching = true;
    },

    [postStudentAvailability.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postStudentAvailability.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postStudentAvailability.pending]: (state) => {
      state.isFetching = true;
    },

    [putUpdateApplicantOfferStatus.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [putUpdateApplicantOfferStatus.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [putUpdateApplicantOfferStatus.pending]: (state) => {
      state.isFetching = true;
    },

    [postUpdateCashFreeStatus.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postUpdateCashFreeStatus.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postUpdateCashFreeStatus.pending]: (state) => {
      state.isFetching = true;
    },

    [postTimelineComment.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postTimelineComment.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postTimelineComment.pending]: (state) => {
      state.isFetching = true;
    },

    [postDownloadStudentCSV.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      return state;
    },
    [postDownloadStudentCSV.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postDownloadStudentCSV.pending]: (state) => {
      state.isFetching = true;
    },
    [sendWelcomeLetterMail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      // state.isSuccess = true;
      state.welcomeLetterSuccess = true;
      return state;
    },
    [sendWelcomeLetterMail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [sendWelcomeLetterMail.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getProgramsDetails.fulfilled]: (state, { payload }) => {
      // state.isFetching = false;
      state.getAllProgramsDetails = payload;
      return state;
    },
    [getProgramsDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getProgramsDetails.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [updateApplicantProgram.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [updateApplicantProgram.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateApplicantProgram.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [postAddApplicantFeeManually.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [postAddApplicantFeeManually.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postAddApplicantFeeManually.pending]: (state) => {
      state.isFetching = true;
    },

    [putResendInterviewSlotMail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.resendMailSuccess = true;
      return state;
    },
    [putResendInterviewSlotMail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [putResendInterviewSlotMail.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [postSendOrientationMailLetter.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      // state.isSuccess = true;
      state.welcomeLetterSuccess = true;
      return state;
    },
    [postSendOrientationMailLetter.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postSendOrientationMailLetter.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [postSendMentorCallMail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.resendMailSuccess = true;
      return state;
    },
    [postSendMentorCallMail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postSendMentorCallMail.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [getCounsellorWisePayrollDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.counsellorPayrollData = payload?.Data;
      return state;
    },
    [getCounsellorWisePayrollDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getCounsellorWisePayrollDetails.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    //**Mentor Call API */START

    [getExternalMentorApplicantList.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.mentorApplicantList = payload?.Data;

      console.log("printing payload data", payload);

      return state;
    },
    [getExternalMentorApplicantList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getExternalMentorApplicantList.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [postUpdateMentorApplicantFeedback.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;

      console.log("printing payload data", payload);

      return state;
    },
    [postUpdateMentorApplicantFeedback.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postUpdateMentorApplicantFeedback.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    /**END */
  },
});

export const {
  clearState,
  clearInterviewState,
  clearCsvState,
  clearEmailState,
  clearFeedbackData,
} = applicantSlice.actions;

export const applicantData = (state) => state.applicantDetail;
