import React, { useState } from "react";
import "./UploadBox.css";

const UploadBox = () => {
  const [selectedFile, setSelectedFile] = useState("Upload Media");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const onClickUpload = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0].name);
      setIsFilePicked(true);
    }
  };

  const onClickDeleteUpload = () => {
    setSelectedFile("Upload Media");
    setIsFilePicked(false);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="uploadbox_wrapper mt-30">
      <div className={isFilePicked ? "upload_shell active" : "upload_shell"}>
        {!isFilePicked && (
          <input
            className="uploadbox"
            onChange={onClickUpload}
            ref={hiddenFileInput}
            id="uploadbox"
            type="file"
          />
        )}
        <div className="upload_inner">
          <label htmlFor="uploadbox" className="active">
            {selectedFile}
          </label>

          <div className="uploadicons">
            {!isFilePicked ? (
              <img
                onClick={handleClick}
                src="../../../assets/icons/upload.svg"
                alt=""
              />
            ) : (
              <img
                onClick={onClickDeleteUpload}
                src="../../../assets/icons/close.svg"
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadBox;
