import React from "react";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import "./AddSessionStyle.css";
const AddSesion = () => {
  return (
    <>
      <SideMenu />

      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">
            <span className="text-grey">Add Course |</span> Add Sessions
          </p>
        </div>

        <div className="addCourse-container">
          <div className="all-session-box">
            <div className="session-box">
              <div className="session-box-header">All Session</div>
              <table className="session-table" cellPadding={0} cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Session</th>
                    <th>Date | Time</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Session 1</td>
                    <td>03 June | 09:00 to 11:00 AM</td>
                    <td>
                      <div className="btn-grp">
                        <img
                          src="../assets/icons/edit.svg"
                          alt=""
                          className="pointer"
                        />
                        <img
                          src="../assets/icons/delete.svg"
                          alt=""
                          className="pointer"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Session 1</td>
                    <td>03 June | 09:00 to 11:00 AM</td>
                    <td>
                      <div className="btn-grp">
                        <img
                          src="../assets/icons/edit.svg"
                          alt=""
                          className="pointer"
                        />
                        <img
                          src="../assets/icons/delete.svg"
                          alt=""
                          className="pointer"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="session-form">
            <p className="text-2">Add Sessions</p>
            <form action="" className="form mt-10">
              <div className="form-group mt-20">
                <label htmlFor="topic" className="create-label">
                  Session 2 Topic
                </label>
                <input
                  type="text"
                  className="create-input mt-5"
                  placeholder="Topic"
                />
              </div>
              <div className="form-2-col">
                <div className="form-group mt-20">
                  <label htmlFor="topic" className="create-label">
                    Date & Time
                  </label>
                  <input
                    type="dateTime-local"
                    className="create-input mt-5"
                    placeholder="Topic"
                  />
                </div>
                <div className="form-group mt-20">
                  <label htmlFor="topic" className="create-label"></label>
                  <input
                    type="dateTime-local"
                    className="create-input mt-5"
                    placeholder="Topic"
                  />
                </div>
              </div>
              <div className="editor-block"></div>
              <div className="form-footer-new mt-20">
                <button className="component-back">
                  <img src="../assets/icons/back-btn.svg" />
                  Back to page
                </button>
                <button className="primary-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSesion;
