import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../FeeDuePopup/FeeDuePopupStyle.css";
import ToastMessage from "../../Toast/ToastComponent";
import ErrorToast from "../../Toast/ErrorToast";
import SearchSelect from "react-select";

import {
  postStudentAvailability,
  applicantData,
  postStudentAvailibility,
  getProgramsDetails,
  updateApplicantProgram,
  postAddApplicantFeeManually,
  putUpdateApplicantOfferStatus,
} from "../../../reducers/applicantSlice";

const userArray = [
  { id: 1, value: "Aditya Gupta", label: "Aditya Gupta" },
  { id: 2, value: "Jatin Khanna", label: "Jatin Khanna" },
  { id: 3, value: "Bijoy Nair", label: "Bijoy Nair" },
  { id: 4, value: "Bikrant Singh", label: "Bikrant Singh" },
  { id: 5, value: "Deepanshu Sharma", label: "Deepanshu Sharma" },
  { id: 6, value: "Deepika Sharma", label: "Deepika Sharma" },
  { id: 7, value: "Kashish Bajaj", label: "Kashish Bajaj" },
  { id: 8, value: "Test User", label: "Test User" },
  { id: 9, value: "Mohit Dhawan", label: "Mohit Dhawan" },
  { id: 10, value: "Mohit Kumar", label: "Mohit Kumar" },
  { id: 11, value: "Nikhil", label: "Nikhil" },
  { id: 12, value: "Nikhir Nagpal", label: "Nikhir Nagpal" },
  { id: 13, value: "Rakshit Gairola", label: "Rakshit Gairola" },
  { id: 14, value: "Rakshit Malhotra", label: "Rakshit Malhotra" },
  { id: 15, value: "Ridhiema Pathak", label: "Ridhiema Pathak" },
  { id: 16, value: "Riya Garg", label: "Riya Garg" },
  { id: 17, value: "Saket Ranjan", label: "Saket Ranjan" },
  { id: 18, value: "Sakshi Tripathi", label: "Sakshi Tripathi" },
  { id: 19, value: "Shahrose Bhat", label: "Shahrose Bhat" },
  { id: 20, value: "Shikhar Dhall", label: "Shikhar Dhall" },
  { id: 21, value: "Shubham Pandey", label: "Shubham Pandey" },
  { id: 22, value: "Tripti Mishra", label: "Tripti Mishra" },
  { id: 23, value: "Vaibhav K Achantani", label: "Vaibhav K Achantani" },
  { id: 24, value: "Aakash Chander", label: "Aakash Chander" },
  { id: 25, value: "Sakcham Shukla", label: "Sakcham Shukla" },
  { id: 26, value: "Evan Magotra", label: "Evan Magotra" },
  { id: 27, value: "Vasuda Rathi", label: "Vasuda Rathi" },
  { id: 28, value: "Sajal Sharma", label: "Sajal Sharma" },
  { id: 29, value: "Nirbhay Singh", label: "Nirbhay Singh" },
  { id: 30, value: "Himanshu Chhabra", label: "Himanshu Chhabra" },
  { id: 31, value: "Nandini Hajela", label: "Nandini Hajela" },
  { id: 32, value: "Pooja Singh", label: "Pooja Singh" },
  { id: 33, value: "Vaidika Mukherjee", label: "Vaidika Mukherjee" },
  { id: 34, value: "Vansh Saini", label: "Vansh Saini" },
  { id: 35, value: "Lakshay Batra", label: "Lakshay Batra" },
  { id: 36, value: "Zaiban Khan", label: "Zaiban Khan" },
  { id: 37, value: "Shubhendu Dubey", label: "Shubhendu Dubey" },
  { id: 38, value: "Rishab Tripathi", label: "Rishab Tripathi" },
  { id: 39, value: "Mohd. Owais", label: "Mohd. Owais" },
  { id: 40, value: "Aditya Upadhyay", label: "Aditya Upadhyay" },
  { id: 41, value: "Anmol Pandita", label: "Anmol Pandita" },
];

const StudentAvailibilityPopup = (props) => {
  const [counsellorName, setCounsellorName] = useState("");
  const { getAllProgramsDetails } = useSelector(applicantData);

  const dispatch = useDispatch();
  const [reason, setReason] = useState("");

  const [dlResponseReason, setDLResponseReason] = useState("");
  const [newProgramId, setNewProgramId] = useState("");

  const handleClose = () => {
    setAvailibility("NotInterested");
    props.closePopup();
    clearPopupData();
    setNewProgramId("");
    props.setNewApplicantId("");
  };

  const programArray =
    getAllProgramsDetails?.Data?.length > 0 &&
    getAllProgramsDetails?.Data?.map((res) => ({
      id: res.id,
      value: res.id,
      label: res.Program.programName,
    }));

  const [avalibility, setAvailibility] = useState("NotInterested");
  const [letterBoxStatus, setLetterBoxStatus] = useState("Pending");
  const [dlResponse, setDLResponse] = useState("NotSent");

  const [offerAcceptanceStatus, setOfferAcceptanceStatus] = useState("willPay");
  const [notInterestedReason, setNotInterestedReason] = useState("");

  /** Update Fee Manually*/
  /**start */
  const [transactionNumber, setTransactionNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [paidOn, setPaidOn] = useState("");

  const handleUpdateFee = () => {
    let obj = {
      transactionNumber: transactionNumber,
      applicantId: props.applicantId,
      amount: amount,
      paidOn: paidOn,
    };

    dispatch(postAddApplicantFeeManually(obj));
    props.setPageNo("1");
    clearPopupData();
  };

  /**end */

  const handleStatusChange = (e) => {
    var status = e.target.value;
    setAvailibility(status);
  };
  const handleSave = () => {
    let obj = {
      applicantInterviewStatus:
        avalibility === "Pending" ? "Appeared" : avalibility,
      applicantId: props.applicantId,
      interviewStatus: avalibility,
    };

    dispatch(postStudentAvailability(obj));
    props.setPageNo("1");
    clearPopupData();
  };

  const handleLetterStatusChange = (e) => {
    var status = e.target.value;
    setLetterBoxStatus(status);
  };

  const handleOfferAcceptanceStatusChange = (e) => {
    var status = e.target.value;
    setOfferAcceptanceStatus(status);
  };

  const handleDLResponseChange = (e) => {
    var status = e.target.value;
    setDLResponse(status);
  };

  const handleLetterBoxSave = () => {
    let obj = {
      offerLetterBox: letterBoxStatus,
      applicantId: props.applicantId,
      offerLetterBoxReason: reason,
    };

    dispatch(postStudentAvailability(obj));
    props.setPageNo("1");
    clearPopupData();
  };

  const handleAcceptanceStatusSave = () => {
    let obj = {
      offerLetterStatus: offerAcceptanceStatus,
      applicantId: props.applicantId,
      notInterestedReason: notInterestedReason,
    };

    dispatch(putUpdateApplicantOfferStatus(obj));
    props.setPageNo("1");
    clearPopupData();
  };

  const handleDLResponseSave = () => {
    let obj = {
      offerLetterResponse: dlResponse,
      applicantId: props.applicantId,
      offerLetterResponseReason: dlResponseReason,
    };

    dispatch(postStudentAvailability(obj));
    props.setPageNo("1");
    clearPopupData();
  };

  const handleCounsellorNameSave = () => {
    let obj = {
      councillorName: counsellorName,
      applicantId: props.applicantId,
    };

    dispatch(postStudentAvailability(obj));
    props.setPageNo("1");
    clearPopupData();
  };

  const clearPopupData = () => {
    setAvailibility("NotInterested");
    setLetterBoxStatus("Pending");
    setReason("");
    setCounsellorName("");
    setDLResponse("NotSent");
    setDLResponseReason("");
    setNotInterestedReason("");
    setOfferAcceptanceStatus("willPay");
    setTransactionNumber("");
    setAmount("");
    setPaidOn("");
  };

  useEffect(() => {
    dispatch(getProgramsDetails());
  }, []);

  useEffect(() => {
    if (props.dlResponseReason) setDLResponseReason(props.dlResponseReason);
  }, [props.dlResponseReason]);

  useEffect(() => {
    if (props.dlResponse) setDLResponse(props.dlResponse);
  }, [props.dlResponse]);

  const updateProgramSubmit = () => {
    let obj = {
      programId: newProgramId,
      applicantId: props.newApplicantId,
    };
    dispatch(updateApplicantProgram(obj));
    props.setPageNo("1");
    clearPopupData();
  };

  useEffect(() => {
    if (Object.keys(props?.offerStatusObj).length != 0) {
      setOfferAcceptanceStatus(props?.offerStatusObj["offerLetterStatus"]);
      setNotInterestedReason(props?.offerStatusObj["notInterestedReason"]);
    }
  }, [props.offerStatusObj]);

  return (
    <>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container due-popup">
          {props?.viewType === "studentAvailability" && (
            <>
              <div className="popup-heading">
                Student Availability
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              </div>

              <div className="popup-body">
                <div
                  onChange={handleStatusChange}
                  className="radio-group mt-10"
                >
                  <label className="create-label" htmlFor="Active">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={avalibility === "NotInterested"}
                      value="NotInterested"
                      id="Active"
                      name="Active"
                    />
                    <span className="radiomark"></span>Not Interested
                  </label>

                  <label className="create-label" htmlFor="DropOut">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="DropOut"
                      id="DropOut"
                      value="NotAppeared"
                      checked={avalibility === "NotAppeared"}
                    />
                    <span className="radiomark"></span>No Show
                  </label>

                  <label className="create-label" htmlFor="NotEligible">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="NotEligible"
                      id="NotEligible"
                      value="NotEligible"
                      checked={avalibility === "NotEligible"}
                    />
                    <span className="radiomark"></span>Not Eligible
                  </label>
                  <br />

                  <label className="create-label" htmlFor="Pending">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="Pending"
                      id="Pending"
                      value="Pending"
                      checked={avalibility === "Pending"}
                    />
                    <span className="radiomark"></span>Pending
                  </label>
                </div>
              </div>
              <div className="popup-footer">
                <button className="primary-btn" onClick={handleSave}>
                  Save
                </button>
              </div>
            </>
          )}
          {props?.viewType === "switchCourse" && (
            <>
              <div className="popup-heading">
                Switch Course
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              </div>

              <div className="popup-body">
                <div className="form-grp mb-20 ns-Font">
                  <label htmlFor="name">
                    Select Program<span className="imp-mark">*</span>
                  </label>

                  <SearchSelect
                    // defaultValue={counsellorName}
                    onChange={(e) => setNewProgramId(e.value)}
                    options={programArray}
                  />
                </div>
                <br />
              </div>
              <div className="popup-footer">
                <button className="primary-btn" onClick={updateProgramSubmit}>
                  Save
                </button>
              </div>
            </>
          )}

          {props?.viewType === "offerLetterBox" && (
            <>
              <div className="popup-heading">
                Offer Letter Box
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              </div>

              <div className="popup-body">
                <div
                  onChange={handleLetterStatusChange}
                  className="radio-group mt-10"
                >
                  <label className="create-label" htmlFor="Pending">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={letterBoxStatus === "Pending"}
                      value="Pending"
                      id="Pending"
                      name="Pending"
                    />
                    <span className="radiomark"></span>Pending
                  </label>

                  <label className="create-label" htmlFor="Intransit">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={letterBoxStatus === "Intransit"}
                      value="Intransit"
                      id="Intransit"
                      name="Intransit"
                    />
                    <span className="radiomark"></span>Intransit
                  </label>

                  <label className="create-label" htmlFor="Delivered">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="Delivered"
                      id="Delivered"
                      value="Delivered"
                      checked={letterBoxStatus === "Delivered"}
                    />
                    <span className="radiomark"></span>Delivered
                  </label>

                  <label className="create-label" htmlFor="Other">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="Other"
                      id="Other"
                      value="Other"
                      checked={letterBoxStatus === "Other"}
                    />
                    <span className="radiomark"></span>Other
                  </label>
                </div>
                <br />

                {letterBoxStatus === "Other" && (
                  <div className="form-grp">
                    <label htmlFor="">Reason</label>

                    <textarea
                      className="create-input"
                      cols="5"
                      rows="5"
                      placeholder="type your reason"
                      // value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    ></textarea>
                  </div>
                )}
              </div>
              <div className="popup-footer">
                <button className="primary-btn" onClick={handleLetterBoxSave}>
                  Save
                </button>
              </div>
            </>
          )}

          {props?.viewType === "offerStatus" && (
            <>
              <div className="popup-heading">
                Offer Acceptance Status
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              </div>

              <div className="popup-body">
                <div
                  onChange={handleOfferAcceptanceStatusChange}
                  className="radio-group mt-10"
                >
                  <label className="create-label" htmlFor="willPay">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={offerAcceptanceStatus === "willPay"}
                      value="willPay"
                      id="willPay"
                      name="willPay"
                    />
                    <span className="radiomark"></span>Will Pay
                  </label>

                  <label className="create-label" htmlFor="notInterested">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={offerAcceptanceStatus === "notInterested"}
                      value="notInterested"
                      id="notInterested"
                      name="notInterested"
                    />
                    <span className="radiomark"></span>Not Interested
                  </label>
                </div>
                <br />

                {offerAcceptanceStatus === "notInterested" && (
                  <>
                    <label>
                      Candidate Not Interested Reason{" "}
                      <span className="imp-mark">*</span>
                    </label>
                    <select
                      id="type"
                      className="popup-dropdown mt-5"
                      onChange={(e) => setNotInterestedReason(e.target.value)}
                      value={notInterestedReason}
                    >
                      <option value="">Select Not Interested Reason</option>
                      <option value="Cannot Afford">Cannot Afford</option>
                      <option value="Program timings does not permit">
                        Program timings does not permit
                      </option>
                      <option value="Health does not permit">
                        Health does not permit
                      </option>
                      <option value="Not convinced with outcome">
                        Not convinced with outcome
                      </option>
                      <option value="Open for negotiations">
                        Open for negotiations
                      </option>
                      {/* <option value="other">Other</option> */}
                    </select>
                  </>
                )}

                {/* {offerAcceptanceStatus === "Other" && (
                  <div className="form-grp">
                    <label htmlFor="">Reason</label>

                    <textarea
                      className="create-input"
                      cols="5"
                      rows="5"
                      placeholder="type your reason"
                      // value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    ></textarea>
                  </div>
                )} */}
              </div>
              <div className="popup-footer">
                <button
                  className="primary-btn"
                  onClick={handleAcceptanceStatusSave}
                >
                  Save
                </button>
              </div>
            </>
          )}

          {props?.viewType === "counsellor" && (
            <>
              <div className="popup-heading">
                Select Counsellor
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              </div>

              <div className="popup-body">
                <div className="form-grp mb-20 ns-Font">
                  <label htmlFor="name">
                    Counsellor Name<span className="imp-mark">*</span>
                  </label>

                  <SearchSelect
                    defaultValue={counsellorName}
                    onChange={(e) => setCounsellorName(e.value)}
                    options={userArray}
                  />
                </div>
                <br />
              </div>
              <div className="popup-footer">
                <button
                  className="primary-btn"
                  onClick={handleCounsellorNameSave}
                >
                  Save
                </button>
              </div>
            </>
          )}

          {props?.viewType === "dlResponse" && (
            <>
              <div className="popup-heading">
                Offer Letter Response
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              </div>

              <div className="popup-body">
                <div className="form-grp">
                  <label htmlFor="amount">
                    Offer Letter Response <span className="imp-mark">*</span>
                  </label>
                  <select
                    id="type"
                    className="popup-dropdown mt-5"
                    onChange={(e) => setDLResponse(e.target.value)}
                    value={dlResponse}
                  >
                    <option value="NotSent">NotSent</option>
                    <option value="Pending">Pending</option>
                    <option value="InProgress">InProgress</option>
                    <option value="Accepted">Accepted</option>
                    <option value="Rejected">Rejected</option>
                  </select>

                  {/* <span
                    className={validateDate ? "error-msg active" : "error-msg"}
                  >
                    Please select scholarship
                  </span> */}
                </div>

                <br />

                {/* {dlResponse === "Other" && ( */}
                <div className="form-grp">
                  <label htmlFor="">Reason</label>

                  <textarea
                    className="create-input"
                    cols="5"
                    rows="5"
                    placeholder="type your reason"
                    value={dlResponseReason}
                    onChange={(e) => setDLResponseReason(e.target.value)}
                  ></textarea>
                </div>
                {/* )} */}
              </div>
              <div className="popup-footer">
                <button className="primary-btn" onClick={handleDLResponseSave}>
                  Save
                </button>
              </div>
            </>
          )}

          {props?.viewType === "updateFee" && (
            <>
              <div className="popup-heading">
                Update Fee
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              </div>

              <div className="popup-body">
                <div className="form-grp">
                  <label htmlFor="receiptNo">
                    Transaction/Receipt No. <span className="imp-mark">*</span>
                  </label>
                  <input
                    type="text"
                    name="receiptNo"
                    id="receiptNo"
                    className="create-input"
                    placeholder="Enter transaction number"
                    // value={address}
                    onChange={(e) => setTransactionNumber(e.target.value)}
                  />
                </div>

                <div className="form-grp mt-20">
                  <label htmlFor="amount">
                    Amount <span className="imp-mark">*</span>
                  </label>
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    className="create-input"
                    placeholder="Enter amount"
                    // value={gstNo}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>

                <div className="form-grp mt-20">
                  <label htmlFor="paidOn">
                    Paid On <span className="imp-mark">*</span>
                  </label>
                  <input
                    type="date"
                    name="paidOn"
                    id="paidOn"
                    className="create-input"
                    placeholder="Enter GST No"
                    // value={gstNo}
                    onChange={(e) => setPaidOn(e.target.value)}
                  />
                </div>
              </div>
              <div className="popup-footer">
                <button className="primary-btn" onClick={handleUpdateFee}>
                  Update Fee
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StudentAvailibilityPopup;
//

{
  /* <div
                  onChange={handleDLResponseChange}
                  className="radio-group mt-10"
                >
                  <label className="create-label" htmlFor="NotSent">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={dlResponse === "NotSent"}
                      value="NotSent"
                      id="NotSent"
                      name="NotSent"
                    />
                    <span className="radiomark"></span>Not Sent
                  </label>

                  <label className="create-label" htmlFor="Pending">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={dlResponse === "Pending"}
                      value="Pending"
                      id="Pending"
                      name="Pending"
                    />
                    <span className="radiomark"></span>Pending
                  </label>

                  <label className="create-label" htmlFor="Accepted">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={dlResponse === "Accepted"}
                      value="Accepted"
                      id="Accepted"
                      name="Accepted"
                    />
                    <span className="radiomark"></span>Accepted
                  </label>

                  <label className="create-label" htmlFor="Rejected">
                    <input
                      className="radio-btn"
                      type="radio"
                      checked={dlResponse === "Rejected"}
                      value="Rejected"
                      id="Rejected"
                      name="Rejected"
                    />
                    <span className="radiomark"></span>Rejected
                  </label>

                  <label className="create-label" htmlFor="InProgress">
                    <input
                      className="radio-btn"
                      type="radio"
                      name="InProgress"
                      id="InProgress"
                      value="InProgress"
                      checked={dlResponse === "InProgress"}
                    />
                    <span className="radiomark"></span>In Progress
                  </label>
                </div> */
}
