import React, { useEffect, useState } from 'react'
import SideMenu from '../../../components/Layout/menu/SideMenu';
import './ExpenceDashboardStyle.css'
import { getAllRequests, expenseSliceFunction, getDashboardData, clearState, rejectExpense, approveExpense } from '../../../reducers/expenseSlice';
import Chart from 'react-apexcharts';
import FinanceInvoicePopoup from '../../../components/Popups/FinanceInvoicePopoup/FinanceInvoicePopoup';
import {
    feeData, getOrganizationProgramsWithBatch,
} from "../../../reducers/feeSlice";
import PageLoader from "../../../components/UI/loader/PageLoader";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const ExpenceDashboard = () => {

    const dispatch = useDispatch()
    const [programId, setProgramId] = useState("")
    const [cohort, setCohort] = useState("")
    // const { isFetching, isSuccess, isError,allRequests,allVendors,allProcessed } = useSelector(expenseSliceFunction);


    useEffect(() => {
        dispatch(getAllRequests())
        const params = {
            programId: programId,
            cohort: cohort
        }
        dispatch(getDashboardData(params));
        getAuthority()
        dispatch(getOrganizationProgramsWithBatch());
    }, [])

    useEffect(() => {
        const params = {
            programId: programId,
            cohort: cohort
        }
        if (apply) {
            dispatch(getDashboardData(params));
            dispatch(clearState());
            setApply(false)
        }
    }, [programId, cohort])
    const navigate = useNavigate()

    const { isFetching, dashboardData, isSuccess } = useSelector(expenseSliceFunction);
    const { programNameList } = useSelector(feeData);
    const [arr, setArr] = useState([])
    const [showAskDetailsPopup, setShowAskDetailsPopup] = useState(false)
    const [authority, setAuthority] = useState("")
    const [apply, setApply] = useState(false)


    const [invoiceData, setInvoiceData] = useState([])
    const [showFinanceInvoicePopup, setShowFinanceInvoicePopup] = useState(false)
    // var arr=[];


    const handleFinanceInvoiceClose = () => {
        setShowFinanceInvoicePopup(!showFinanceInvoicePopup)
    }
    const handleAskDetailsClose = () => {
        setShowAskDetailsPopup(!showAskDetailsPopup)
    }
    useEffect(() => {
        const params = {
            programId: programId,
            cohort: cohort
        }
        if (isSuccess) {
            dispatch(getDashboardData(params));
            dispatch(clearState())
            setShowFinanceInvoicePopup(false)
        }
    }, [isSuccess])
    const getAuthority = () => {
        let finance = localStorage.getItem("isFinanceMember")
        let admin = localStorage.getItem("canApproveExpense")

        if (admin === "true") {
            setAuthority("admin")
        }
        else if (finance === "true") {
            setAuthority("finance")
        }
        else {
            setAuthority("member")
        }
    }

    const getMonth = () => {
        setArr([])
        if (dashboardData?.result1) {
            var val = false
            for (let i = 1; i <= 12; i++) {

                dashboardData?.result1?.map(item => {
                    if (item.month == i) {

                        setArr(val => ([...val, { totalAmount: item.totalAmount, month: item.month }]))
                        val = true

                    }
                })
                if (val == false) setArr(val => ([...val, { totalAmount: 0, month: i }]))
                val = false
            }

        }
        else {
            console.log("end");
        }
    }
    const monthData = () => {
        console.log(arr);

        let arr1 = [];
        arr?.map(item => {
            arr1.push(item?.totalAmount)
        })
        return arr1
    }

    const data = () => {
        let arr1 = [];
        dashboardData?.result2?.map(item => {
            arr1.push(item?.totalAmount)
        })
        return arr1
    }
    const data2 = () => {
        let arr1 = [];
        dashboardData?.result2?.map(item => {
            arr1.push(item?.purpose == "Acad Ops/Student Experience" ? "Student Experience": item?.purpose)
        })
        return arr1
    }
    const getPercentage = (data) => {

        const sumWithInitial = dashboardData?.result2?.reduce(
            (acc, currentValue) => acc + currentValue.totalAmount,
            0
        );
        return Math.round((data / sumWithInitial) * 100)
    }

    const getCohort = (id) => {
        if (id !== "") {
            let result = programNameList?.find(item => item.id === id);
            return result
        }
        else {
            return null
        }
    }

    useEffect(() => {
        getMonth();

    }, [dashboardData])

    const handleClick = (id, name = "") => {
        console.log("name", name);
        if (name == "reject") { dispatch(rejectExpense(id)) }
        else if (name == "accept") {
            dispatch(approveExpense(id))
        }
    }

    const option = {

        series: [{
            name: "Monthly Expenses",
            data: monthData()
        }],
        options: {
            chart: {
                type: 'area',
                height: 280,
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },

            title: {
                text: 'Monthy Expenses',
                align: 'left'
            },
            subtitle: {
                align: 'left'
            },
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            xaxis: {
                type: 'month',
            },
            yaxis: {
                opposite: true
            },
            legend: {
                horizontalAlign: 'left'
            },
            fill: {
                type: 'gradient',
                colors: ['#eff4f2', '#eff4f2', '#eff4f2']
            }
        }


    };
    const pieChart = {

        series: [...data()],

        chartOptions: {
            labels: [...data2()],
            chart: {
                width: 380,
                type: 'donut',
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: {
                show: false,
                position: 'right',
                offsetY: 0,
                height: 230,
                colors: ['#237951', '#7baf97', '#a7c9b9', '#bdd7cb', '#d3e4dc', 'e9f2ee', '#f4f8f6']

            },
            fill: {
                colors: ['#237951', '#7baf97', '#a7c9b9', '#bdd7cb', '#d3e4dc', 'e9f2ee', '#f4f8f6']
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (val) {
                        return "₹ " + val
                    },
                    title: {
                        formatter: function (aj) {
                            return aj
                        }
                    }
                }
            }
        },


    };
    const capitalize = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };
    console.log(cohort);

    return (
        <>
            <SideMenu />
            {isFetching && <PageLoader />}
            <FinanceInvoicePopoup show={showFinanceInvoicePopup} handleClose={handleFinanceInvoiceClose} handleAskDetailsClose={handleAskDetailsClose} data={invoiceData} authority={authority} />

            <div className="main-body">
                <div className="page-header">
                    <p className="component-heading primary-text">Expense Management</p>
                </div>
                <div className="expenceDashboardOuter scrollbar">
                    <div className="expenceGraphsOuter">
                        <div className="expenceGraphOuter">
                            {/* <div className="expenceDropdownDropdown">
                                <p className="selectedItem">All Courses</p>
                                <div className="dropdownBox scrollbar">
                                    <div className="dropdownItem">PGP in Technology & Business Management </div>
                                    <div className="dropdownItem">PGP in Technology & Business Management </div>
                                    <div className="dropdownItem">PGP in Technology & Business Management </div>
                                    <div className="dropdownItem">PGP in Technology & Business Management </div>
                                    <div className="dropdownItem">PGP in Technology & Business Management </div>
                                    <div className="dropdownItem">PGP in Technology & Business Management </div>
                                    <div className="dropdownItem">PGP in Technology & Business Management </div>
                                </div>
                            </div> */}
                            <div className="filter-bar">
                                <div className="filters">
                                    <select id="type" defaultValue={"Expense For"} onChange={(e) => { setProgramId(e.target.value); setApply(true); setCohort("") }}>
                                        <option value=''>All Program</option>
                                        {
                                            programNameList?.length > 0 && programNameList?.map((res, i) => (<option value={res.id} key={i}>{res.programName}</option>)
                                            )
                                        }
                                    </select>

                                    {getCohort(programId) && <select id="type" onChange={(e) => { setCohort(e.target.value); setApply(true) }}>
                                        <option value=''>All Cohort</option>

                                        {getCohort(programId) ? getCohort(programId)?.ProgramDetails?.map((item, index) => (
                                            <option value={item.id} key={index}>{item.name}</option>
                                        )) : <option value="">Please select a program first</option>}

                                    </select>

                                    }
                                    {/* <select id="type" >
                                        <option value="">Year</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                    </select> */}
                                </div>
                            </div>
                            <div id="chart">
                                <Chart options={option.options} series={option.series} type="area" height={280} />

                            </div>
                        </div>
                        <div className="expenceGraphOuter">
                            <div className="expencePieChart">
                                <div className="chart-wrap">
                                    <div id="chart">
                                        <p className='purposeText'>Raised For</p>
                                        <Chart options={pieChart.chartOptions} series={pieChart.series} type="donut" width={300} />
                                    </div>
                                </div>
                                <div className="chartDetailsOuter">
                                    {
                                        dashboardData?.result2?.map((item, index) => (
                                            <div className="chartDetails" key={index}>
                                                <p className="name">{item.purpose == "Acad Ops/Student Experience" ? "Student Experience": item.purpose}</p>
                                                <div className="progressbarOuter">
                                                    <div className="progressBar">
                                                        <div className="progress" style={{ width: `${getPercentage(item.purpose.totalAmount)}%` }} ></div>
                                                    </div>
                                                    <p className="percent">{getPercentage(item.totalAmount)}%</p>
                                                </div>
                                            </div>))

                                    }
                                    {/* <div className="chartDetails">
                                        <p className="name">Admissions and Marketing</p>
                                        <div className="progressbarOuter">
                                            <div className="progressBar">
                                                <div className="progress" style={{ width: '20%' }} ></div>
                                            </div>
                                            <p className="percent">40%</p>
                                        </div>
                                    </div>
                                    <div className="chartDetails">
                                        <p className="name">Admissions and Marketing</p>
                                        <div className="progressbarOuter">
                                            <div className="progressBar">
                                                <div className="progress" style={{ width: '15%' }} ></div>
                                            </div>
                                            <p className="percent">40%</p>
                                        </div>
                                    </div>
                                    <div className="chartDetails">
                                        <p className="name">Admissions and Marketing</p>
                                        <div className="progressbarOuter">
                                            <div className="progressBar">
                                                <div className="progress" style={{ width: '30%' }} ></div>
                                            </div>
                                            <p className="percent">40%</p>
                                        </div>
                                    </div>
                                    <div className="chartDetails">
                                        <p className="name">Admissions and Marketing</p>
                                        <div className="progressbarOuter">
                                            <div className="progressBar">
                                                <div className="progress" style={{ width: '60%' }} ></div>
                                            </div>
                                            <p className="percent">40%</p>
                                        </div>
                                    </div>
                                    <div className="chartDetails">
                                        <p className="name">Admissions and Marketing</p>
                                        <div className="progressbarOuter">
                                            <div className="progressBar">
                                                <div className="progress" style={{ width: '5%' }} ></div>
                                            </div>
                                            <p className="percent">40%</p>
                                        </div>
                                    </div>
                                    <div className="chartDetails">
                                        <p className="name">Admissions and Marketing</p>
                                        <div className="progressbarOuter">
                                            <div className="progressBar">
                                                <div className="progress" style={{ width: '90%' }} ></div>
                                            </div>
                                            <p className="percent">40%</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="expenceTableOuter">
                        <table className="expenceTable" cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>Ticket ID</th>
                                    <th>Raised By</th>
                                    <th>Vendor Name</th>
                                    {/* <th>Member Name</th> */}
                                    <th>Expense For</th>
                                    <th>Cohort</th>
                                    <th>Invoice Type</th>
                                    <th>Raised For </th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Request</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dashboardData?.result3 && dashboardData?.result3?.length > 0 && dashboardData?.result3?.map((val, index) => (
                                    <tr key={index} >
                                        <td>{'INVOICE-' + val.ticketId}</td>
                                        <td>{capitalize(val.Admin?.firstName ?? '-') + ' ' + capitalize(val.Admin?.lastName ?? '-')}</td>
                                        <td>{val.VendorDetail?.vendorName ?? '-'}</td>
                                        {/* <td>{val.MemberDetail?.memberName ?? '-'}</td> */}
                                        <td>{val?.Program?.programName ?? '-'}</td>
                                        <td>{val?.ProgramDetail?.name ?? '-'}</td>
                                        <td>
                                            {val.invoiceType === "Final" && (<p className="success-tag">{val.invoiceType} Payment</p>)}
                                            {val.invoiceType === "Partial" && (<p className="draft-tag">{val.invoiceType} Payment</p>)}
                                            {val.invoiceType === "Advance" && (<p className="primary-tag">{val.invoiceType} Payment</p>)}
                                        </td>
                                        <td>{val.purpose == "Acad Ops/Student Experience" ? "Student Experience": val.purpose}</td>
                                        <td>₹ {val.amount}</td>
                                        <td>{val.status === "Completed" && (<p className="tag-active">Approved</p>)}
                                            {val.status === "Pending" && (authority === "admin" ?
                                                (<><button className="success-btn-new" onClick={() => handleClick(val?.id, "accept")}>
                                                    <img src="../assets/icons/tickmark.svg" alt="" /> Approve</button>
                                                    <button className="reject-btn reject-btn-new mt-5" onClick={() => handleClick(val?.id, "reject")}>
                                                        <img src="../../assets/icons/redCross.svg" alt="" />{" "}
                                                        <img src="../../assets/icons/whiteCross.svg" alt="" />{" "}
                                                        Reject</button>
                                                </>) : (<p className="tag-draft">{val.status}</p>))}
                                            {val.status === "Rejected" && (<p className="tag-expired">{val.status}</p>)}
                                        </td>
                                        <td><button className="primary-btn" onClick={() => { setInvoiceData(val); setShowFinanceInvoicePopup(true) }}>View</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="tableFooter">
                            <p className="primary-text" onClick={() => navigate('/financeRequest')}>See More</p> <img src="../assets/icons/blueArrowRight.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenceDashboard