import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

let token;

let setTokenValues = () => {
  token = localStorage.getItem("token");
};
export const getVerifyEmailsData = createAsyncThunk(
  "applicants/getVerifyEmailsData",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        "https://epicapi.mastersunion.org/api/org/getValidatedEmails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const postEmailData = createAsyncThunk(
  "applicants/postEmailData",
  async (selectedFile, thunkAPI) => {
    try {
      console.log(selectedFile);
      setTokenValues();
      const formData = new FormData();
      formData.append("file", selectedFile);

      const response = await fetch(
        "https://epicapi.mastersunion.org/api/org/emailValidator",
        {
          method: "POST",
          headers: {
            //   Accept: "application/json",
            //  "Content-Type": "multipart/form-data",
            Authorization: token,
          },
          body: formData,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const emailSampleFileDownload = createAsyncThunk(
  "applicants/studentSampleFileDownload",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        //   environment.baseURL +
        //     "/api/org/" +
        //     orgId +
        //     "/admin/" +
        //     adminId +
        //     `/downloadSampleMailCSV`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.text();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const verifyEmailSlice = createSlice({
  name: "applicantDetail",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    sampleFileDownload: "",
    verifyEmailData: [],
    importedFile: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      return state;
    },
    clearCsvState: (state) => {
      state.isImport = false;
      state.successMessage = false;
      state.deleteInterViewList = false;
      return state;
    },
  },
  extraReducers: {
    [getVerifyEmailsData.fulfilled]: (state, { payload }) => {
      console.log("printing email payload", payload);
      state.verifyEmailData = payload;
      state.isFetching = false;
      return state;
    },
    [getVerifyEmailsData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getVerifyEmailsData.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [postEmailData.fulfilled]: (state, { payload }) => {
      console.log("printing post payload", payload);
      state.importedFile = payload;
      state.isFetching = false;
      return state;
    },
    [postEmailData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postEmailData.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [emailSampleFileDownload.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.sampleFileDownload = payload;
      // state.isFetching = false;
      return state;
    },
    [emailSampleFileDownload.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      // state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
  },
});

export const { clearState, clearCsvState } = verifyEmailSlice.actions;
export const verifyEmailsData = (state) => state.verifyEmailDetail;
