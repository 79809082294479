import React, { useEffect, useState, createRef } from "react";
import insertTextAtCursor from "insert-text-at-cursor";
import SideMenu from "../../components/Layout/menu/SideMenu";
import { COMMON_TEXT } from "../../constants/textConstant";
import "./BulkMailerStyle.css";
import ErrorToast from "../../components/Toast/ErrorToast";
import PageLoader from "../../components/UI/loader/PageLoader";
import ToastMessage from "../../components/Toast/ToastComponent";
import SearchSelect from "react-select";
import CreateTemplatePopup from "../../components/Popups/CreateTemplatePopup/CreateTemplatePopup";

import $ from "jquery";
import SunEditor from "suneditor-react";
import {
  genericMailData,
  getAllTemplateData,
  createTemplate,
  clearState,
  emailSampleFileDownload,
  getTokenList,
  sendGenericMail,
} from "../../reducers/genericMailSendSlice";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";

const BulkMailer = () => {
  const changeContainer = (show, hide1, hide2) => {
    $(`#btn-${show}`).addClass("active");
    $(`#btn-${hide1}`).removeClass("active");
    $(`#btn-${hide2}`).removeClass("active");
    $(`#container-${show}`).addClass("show");
    $(`#container-${hide1}`).removeClass("show");
    $(`#container-${hide2}`).removeClass("show");
  };

  // Error Toast
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  // Checklist steps
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [step6, setStep6] = useState(false);

  const nextStepChecklist = () => {
    if (
      step1 === false ||
      step2 === false ||
      step3 === false ||
      step4 === false ||
      step5 === false ||
      step6 === false
    ) {
      setIsShowError(true);
      setErrMessage("Select All CheckList");
      return;
    }
    changeContainer(2, 1, 3);
  };

  // Old Temp Data
  const {
    isFetching,
    templateDataList,
    isSuccess,
    sampleFileDownload,
    getAllTokenList,
    successMessgae,
    mailSendSuccess,
  } = useSelector(genericMailData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTemplateData());
  }, []);
  useEffect(() => {
    dispatch(emailSampleFileDownload());
  }, []);
  const ref = createRef();

  //toast states
  const [showAddCsvPopup, setAddCsvPopup] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [dropdownDataChange, setDropdownDataChange] = useState("");
  const [getAllTemplates, setGetAllTemplates] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailToken, setEmailToken] = useState(null);
  const [isCreateTemplateOpen, setIsCreateTemplateOpen] = useState(false);
  const [createTemplateList, setCreateTemplateList] = useState(false);
  const [getToken, setGetToken] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [fromEmail, setFromEmail] = useState("");

  //Create Template
  const [templateName, setTemplateName] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateBody, setTemplateBody] = useState("");
  const [addedOn, setAddedOn] = useState("");

  //
  console.log(getAllTokenList, "getAllTokenList");

  const onClickUpload = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    }
    console.log(event.target);
  };

  const onClickDeleteUpload = () => {
    setSelectedFile("");
    setIsFilePicked(false);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const toggleClosePopup = () => {
    setAddCsvPopup(false);
    // onClickDeleteUpload();
  };

  const onClickAddApplicant = () => {
    setAddCsvPopup(true);
  };

  const handleChangeSubject = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEmailToken = (event) => {
    setEmailToken(event.target.value);
    insertTextAtCursor(ref.current, event.target.value);
  };

  const toggleCreateTemplatePopup = () => {
    setIsCreateTemplateOpen(false);
    setAddedOn("");
    setTemplateBody("");
    setTemplateName("");
    setTemplateSubject("");
    setCreateTemplateList(false);
  };
  const openCreateTemplatePopup = () => {
    setIsCreateTemplateOpen(true);
    setCreateTemplateList(true);
  };

  const createTemplateSubmit = () => {
    let obj = {
      templateName: templateName,
      templateSubject: templateSubject,
      templateBody: templateBody,
      addedOn: addedOn,
    };
    if(templateName === "" || templateSubject === "" || templateBody === ""){
      setIsShowError(true);
      setErrMessage("Fill All Fields");
      return;
    }
    dispatch(createTemplate(obj));
  };
  console.log(templateBody,"templateBody")

  let templateEditorData =
    getAllTemplates?.length > 0 &&
    getAllTemplates?.filter((res) => res?.id === dropdownDataChange);
  //Set Data
  useEffect(() => {
    setGetAllTemplates(templateDataList);
  }, [templateDataList]);

  const onClickCsvSubmit = () => {
    if (selectedFile === "" || selectedFile.type !== "text/csv") {
      setIsShowError(true);
      setErrMessage("Choose a file in csv format!");
      return;
    }
    dispatch(getTokenList(selectedFile));
    setGetToken("");
    changeContainer(3, 2, 1);
  };

  console.log(fromEmail, "selectedFileselectedFile");

  let tData=["fName","lName"]

  useEffect(() => {
    setGetToken(getAllTokenList);
  }, [getAllTokenList]);
  const mergeTag = {
    // @Required
    // plugin name
    name: "merge_tag",

    // @Required
    // data display
    display: "submenu",

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      // Generate submenu HTML
      // Always bind "core" when calling a plugin function
      let listDiv = this.setSubmenu.call(core);

      // You must bind "core" object when registering an event.
      /** add event listeners */
      var self = this;
      listDiv.querySelectorAll(".se-btn-list").forEach(function (btn) {
        btn.addEventListener("click", self.onClick.bind(core));
      });

      // @Required
      // You must add the "submenu" element using the "core.initMenuTarget" method.
      /** append target button menu */
      core.initMenuTarget(this.name, targetElement, listDiv);
    },

    setSubmenu: function () {
      const listDiv = this.util.createElement("DIV");
      // @Required
      // A "se-submenu" class is required for the top level element.
      listDiv.className = "se-submenu se-list-layer";
      // listDiv.innerHTML = `<div className="se-list-inner se-list-font-size"><ul className="se-list-basic">${
      //   getToken?.length > 0
      //     ? getToken?.map(
      //         (res, i) =>(
      //           `<li key=${i}><button type="button" className="se-btn-list" value={${res}}>${res}</button></li>`
      //         ))
      //     : ""
      // }</ul></div>`;
      listDiv.innerHTML = `<div class="se-list-inner se-list-font-size"><ul class="se-list-basic">${getToken?.length > 0 && getToken?.map((res,i)=>`<li><button type="button" class="se-btn-list" value="{${res}}">${res}</button></li>`)}</ul></div>`
      return listDiv;
    },
    onClick: function (e) {
      const value = e.target.value;
      const node = this.util.createElement("span");
      this.util.addClass(node, "se-custom-tag");
      node.textContent = value;

      this.insertNode(node);
      const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
      node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);

      this.submenuOff();
    },
  };

  const formValidation = () => {
    let status = true;
    if (selectedFile === "") {
      setIsShowError(true);
      setErrMessage("Add Csv");
      status = false;
    } else if (editorState === "") {
      setIsShowError(true);
      setErrMessage("Select Template");
      status = false;
    } else if (emailSubject === "") {
      setIsShowError(true);
      setErrMessage("Select Email Subject");
      status = false;
    } else if (fromEmail === "") {
      setIsShowError(true);
      setErrMessage("Select From Email");
      status = false;
    }
    return status;
  };

  const submitGenericMail = () => {
    if (!formValidation()) return;
    let obj = {
      file: selectedFile,
      specificTemplateBody: editorState,
      specificTemplateSubject: emailSubject,
      fromEmail: fromEmail,
    };
    dispatch(sendGenericMail(obj));
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsShowSuccess(true);
      }, 2000);
      dispatch(clearState());
      dispatch(getAllTemplateData());
      toggleCreateTemplatePopup();
      toggleClosePopup();
    }
  }, [isSuccess, dropdownDataChange]);

  useEffect(() => {
    if (mailSendSuccess) {
      setTimeout(() => {
        setIsShowSuccess(true);
      }, 2000);
      dispatch(clearState());
      dispatch(getAllTemplateData());
      onClickDeleteUpload();
      setEditorState("");
      setEmailSubject("");
      setFromEmail("");
      setGetToken([]);
      setDropdownDataChange("");
      setStep1("")
      setStep2("")
      setStep3("")
      setStep4("")
      setStep5("")
      setStep6("")
      changeContainer(1, 2, 3)
    }
  }, [mailSendSuccess]);

  const handleEditorChange = (content) => {
    setEditorState(content);
  };
  const dropDownOnChhange = (e) => {
    let data = e.id;
    setDropdownDataChange(data);

    let subData =
      getAllTemplates?.length > 0 &&
      getAllTemplates?.filter((res) => res?.id === data);
    setEmailSubject(subData?.length > 0 ? subData[0]?.templateSubject : "");
    console.log(data,"getTokennnnnnnn")
  };

  // console.log(
  //   templateEditorData?.length > 0 &&
  //   templateEditorData[0]?.templateBody
  // ,"datatata")

  return (
    <>
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      {isFetching && <PageLoader />}
      <ToastMessage
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessgae}
      />
      <SideMenu />
      
      <CreateTemplatePopup
        handleClose={toggleCreateTemplatePopup}
        show={isCreateTemplateOpen}
        templateName={templateName}
        templateBody={templateBody}
        templateSubject={templateSubject}
        setTemplateName={setTemplateName}
        setTemplateBody={setTemplateBody}
        setTemplateSubject={setTemplateSubject}
        addedOn={addedOn}
        setAddedOn={setAddedOn}
        createTemplateSubmit={createTemplateSubmit}
      />
      <div className="main-body">
        <div className="page-header bulk-header">
          <button className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            {COMMON_TEXT.goBack}
          </button>
          <div>
            <button
              className="primary-clean active"
              id="btn-1"
              // onClick={() => changeContainer(1, 2, 3)}
            >
              Checklist
            </button>
            <button
              className="primary-clean"
              id="btn-2"
              // onClick={() => changeContainer(2, 1, 3)}
              // onClick={handleOnPressNext}
            >
              Upload File
            </button>
            <button
              className="primary-clean"
              id="btn-3"
              // onClick={() => changeContainer(3, 2, 1)}
            >
              Edit Mail
            </button>
          </div>
          <div></div>
        </div>
        <div className="bulk-container">
          <div
            className=" scrollbar show"
            id="container-1"
            style={{ display: "none" }}
          >
            <div className="create-container">
              <div className="checklist-outer">
                <h2 className="heading">Checklist</h2>
                <div className="checklist">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                      value={step1}
                      checked={step1}
                      onChange={() => setStep1(!step1)}
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                  <div className="checklist-content">
                    <h4>Step 1 </h4>
                    <p>
                      (Download Sample Csv and add all receipients) Name and
                      Email fields are mandatory, 'Email' value will be picked
                      as reciepient email and all other dynamic values that need
                      to be integerated in the email, All tokens will get binded
                      in the token list in the template
                    </p>
                  </div>
                </div>
                <div className="checklist">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                      value={step2}
                      checked={step2}
                      onChange={() => setStep2(!step2)}
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                  <div className="checklist-content">
                    <h4>Step 2 </h4>
                    <p>Upload the modified Csv</p>
                  </div>
                </div>
                <div className="checklist">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                      value={step3}
                      checked={step3}
                      onChange={() => setStep3(!step3)}
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                  <div className="checklist-content">
                    <h4>Step 3</h4>
                    <p>
                      Select either already existing template or create new
                      template
                    </p>
                  </div>
                </div>
                <div className="checklist">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                      value={step4}
                      checked={step4}
                      onChange={() => setStep4(!step4)}
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                  <div className="checklist-content">
                    <h4>Step 4</h4>
                    <p>
                      Select 'from address' (this will be treated as sender
                      address)
                    </p>
                  </div>
                </div>
                <div className="checklist">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                      value={step5}
                      checked={step5}
                      onChange={() => setStep5(!step5)}
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                  <div className="checklist-content">
                    <h4>Step 5</h4>
                    <p>Add tokens from Token list to the email template</p>
                  </div>
                </div>

                <div className="checklist">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                      value={step6}
                      checked={step6}
                      onChange={() => setStep6(!step6)}
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                  <div className="checklist-content">
                    <h4>Step 6</h4>
                    <p>
                      Click on Send Email on the botton to trigger the email to
                      all the receipients added on the sheet
                    </p>
                  </div>
                </div>
              </div>
              <div className="create-container-footer">
                <button
                  className="primary-btn-a disabled"
                  onClick={nextStepChecklist}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div id="container-2" style={{ display: "none" }}>
            <div className="create-container">
              <div className="upload-file-outer">
                <div className="upload-file-box">
                  <img
                    src="../assets/icons/upload-cloud-01.svg"
                    alt="upload-icon"
                  />
                  <p>Select CSV file here to upload</p>
                  {/* <p>or</p> */}
                  {!isFilePicked && (
                    <input
                      onChange={onClickUpload}
                      ref={hiddenFileInput}
                      id="uploadbox"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      className="uploadbox"
                    />
                  )}
                  <button className="clear-btn" onClick={handleClick}>
                    Select a file
                  </button>
                </div>

                {isFilePicked && (
                  <div className="uploaded-file">
                    <p className="file-name">{selectedFile?.name}</p>
                    <img
                      src="../../assets/icons/delete.svg"
                      className="pointer"
                      onClick={onClickDeleteUpload}
                    />
                  </div>
                )}
              </div>
              <div className="create-container-footer upload-footer">
                <button className="clear-btn">
                  <CSVLink
                    data={sampleFileDownload ? sampleFileDownload : ""}
                    target="_blank"
                    className="clear-btn"
                  >
                    Download Sample CSV File
                  </CSVLink>
                </button>
                <button className="primary-btn-a" onClick={onClickCsvSubmit}>
                  Next
                </button>
              </div>
            </div>
          </div>
          <div id="container-3" style={{ display: "none" }}>
            <div className="mail-box-outer scrollbar">
              <div className="mail-details">
                <div className="form-2-grp">
                  <div className="form-group mt-20">
                    <label htmlFor="" className="create-label">
                      Select Template
                    </label>
                    <div className="selected-fee">
                      {/* <TemplateDataDropdown
                    options={getAllTemplates}
                    dropDownOnChhange={dropDownOnChhange}
                  /> */}
                      <SearchSelect
                        onChange={dropDownOnChhange}
                        options={getAllTemplates}
                        getOptionLabel={(option) => option.templateName}
                        getOptionValue={(option) => option.id}
                        styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="btn-grp">
                    <label htmlFor="" className="create-label">
                    Create Template
                    </label>
                    <button className="primary-btn-a feed-btn mt-5" onClick={openCreateTemplatePopup}>
                      {/* <img src="../assets/icons/plus.svg" alt="" /> Add Csv */}
                      Create Template
                    </button>
                  </div>
                </div>
                <div className="form-2-grp">
                  <div className="form-group mt-20">
                    <label for="name" className="create-label">
                      Email Subject
                    </label>
                    <input
                      type="text"
                      className="create-input mt-5"
                      placeholder="Enter Email Subject"
                      value={emailSubject}
                      onChange={handleChangeSubject}
                    />
                  </div>
                  <div className="form-group mt-20">
                    <label for="name" className="create-label">
                      From
                    </label>

                    <select
                      id="type"
                      className="popup-dropdown"
                      value={fromEmail}
                      onChange={(e) => setFromEmail(e.target.value)}
                    >
                      <option value="">Select From Email</option>
                      <option value="tech@mastersunion.org">
                        tech@mastersunion.org
                      </option>
                      <option value="mastercampadmissions@mastersunion.org">
                        mastercampadmissions@mastersunion.org
                      </option>
                      <option value="admissions@mastersunion.org">
                        admissions@mastersunion.org
                      </option>
                    </select>
                    {/* <input
                  type="text"
                  className="create-input mt-5"
                  placeholder="Enter Couse Name"
                /> */}
                  </div>
                </div>
              </div>
              <div className="edit-mail-container scrollbar mt-20">
                {/* <CKEditor
              // onInstanceReady={({ editor }) => {
              //   console.log("CKEDITOR ready", CKEDITOR);
              //   console.log("editor", editor);
              //   console.log("ui", editor.ui);
              //   console.log("richcombo", editor.ui.addRichCombo);
              // }}
              // onBeforeLoad={(CKEDITOR) => {
              //   console.log("CKEDITOR", CKEDITOR);
              // }}
              // editor={
              //   templateEditorData?.length > 0 &&
              //   templateEditorData[0]?.templateBody
              // }
              // initData={
              //   templateEditorData?.length > 0 &&
              //   templateEditorData[0]?.templateBody
              // }
              // initData={<p>Hello from CKEditor 4!</p>}
              data={
                templateEditorData?.length > 0 &&
                templateEditorData[0]?.templateBody
              }
              onPluginsLoaded={(args) => {
                const editor = args.editor;
                // console.log("onPluginsLoaded", args, CKEDITOR);
                editor.ui.addRichCombo("my-combo", {
                  label: "Merge Codes",
                  title: "Merge Codes",
                  toolbar: "basicstyles,0",
                  panel: {
                    // css: [ CKEDITOR.skin.getPath( 'editor' ) ].concat( config.contentsCss ),
                    css: [
                      "https://cdn.ckeditor.com/4.16.2/standard-all/skins/moono-lisa/editor.css?t=L7C8",
                      "https://cdn.ckeditor.com/4.4.0/standard/contents.css?t=E3OD",
                      "./styles.css"
                    ],
                    multiSelect: false,
                    attributes: { "aria-label": "Merge Codes" }
                  },
                  init: function () {
                    this.startGroup("Merge Codes Group #1");
                    this.add("{{foo}}", "Foo");
                    this.add("{{bar}}", "Bar");
                    this.add("{{foo test}}", "Foo test!");
                    this.startGroup("Merge Codes Group #2");
                    this.add("{{ping}}", "Ping");
                    this.add("{{pong}}", "Pong");
                  },
                  onClick: function (value) {
                    editor.focus();
                    editor.fire("saveSnapshot");
                    editor.insertHtml(value);
                    editor.fire("saveSnapshot");
                  }
                });
              }}
            /> */}
                {getToken?.length > 0 && (
                  <SunEditor
                    setContents={
                      templateEditorData?.length > 0 &&
                      templateEditorData[0]?.templateBody
                    }
                    showToolbar={true}
                    onChange={handleEditorChange}
                    setDefaultStyle="height: auto"
                    setOptions={{
                      plugins: [mergeTag],
                      buttonList: [
                        [
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "list",
                          "align",
                          "fontSize",
                          "formatBlock",
                          "table",
                          "fullScreen",
                          "preview",
                          "codeView",
                        ],
                        [
                          {
                            name: "merge_tag",
                            dataCommand: "merge_tag",
                            buttonClass: "",
                            title: "Add Token",
                            dataDisplay: "submenu",
                            innerHTML: "<span>Token</span>",
                          },
                        ],
                      ],
                    }}
                  />
                 )} 
              </div>
              <div className="edit-email-footer mt-20">
                <div className="test-mail">
                  {/* <input type="email" name="" id="" className="create-input" />
              <button className="primary-btn-a">Send Test Mail</button> */}
                </div>
                <div className="btn-grp mt-10">
                  <button className="success-btn" onClick={submitGenericMail}>
                    Send Mail
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkMailer;
