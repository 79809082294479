import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "../environments/environment";

export const fetchPlayerList = createAsyncThunk(
  "admin/feedbackList",
  (teamId) =>
    axios
      .get(`https://api.opendota.com/api/teams/${teamId}/players`)
      .then((response) => response.data)
      .catch((error) => error)
);

let orgId;
let token;
let adminId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};

export const getAllFeedbacks = createAsyncThunk(
  "feedback/getAllFeedbacks",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      var search = obj?.searchTitle ?? "";
      var feedbackType = obj?.searchParams ?? "";
      var statusType = obj?.status ?? "";
      var pageSize = obj?.pageSize ?? "";
      var pageNo = obj?.pageNo ?? "";

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          `/getAllFeedbacks?searchTitle=${search}&searchParams=${feedbackType}&status=${statusType}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createFeedback = createAsyncThunk(
  "feedback/createFeedback",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      obj.organizationId = orgId;
      obj.adminId = adminId;
      console.log("kassi ", obj);
      const payload = { obj };

      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/admin/${adminId}/createFeedback`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getFeedbackDetailById = createAsyncThunk(
  "feedback/getFeedbackDetailById",
  async (feedbackId, thunkAPI) => {
    try {
      console.log("printing data", feedbackId);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/" +
          feedbackId +
          "/getParticularFeedbackData",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllStudent = createAsyncThunk(
  "feedback/getAllStudent",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      var search = obj?.searchTitle ?? "";
      var program = obj?.programName ?? "";
      var batch = obj?.batchName ?? "";
      var pageSize = obj?.pageSize ?? "";
      var pageNo = obj?.pageNo ?? "";

      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          `/getAllStudent?searchTitle=${search}&program=${program}&batch=${batch}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllProgramsDetails = createAsyncThunk(
  "feedback/getAllProgramsDetails",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/getAllPrograms",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllBatchByProgramId = createAsyncThunk(
  "feedback/getAllBatchByProgramId",
  async (programId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/getAllBatchByProgramId?programId=" +
          programId,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** EDIT PARTICULAR FEEDBACK */
export const editFeedback = createAsyncThunk(
  "feedback/editFeedback",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      obj.organizationId = orgId;
      obj.adminId = adminId;
      console.log("dfhhhdfhdf ", obj);
      var id = obj.feedId;
      const payload = { obj };
      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/admin/${adminId}/${id}/editFeedback`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/** GET EMAIL TEMPLATE */
export const getEmailTemplate = createAsyncThunk(
  "feedback/getEmailTemplate",
  async (obj, thunkAPI) => {
    console.log("printing template data", obj);
    try {
      setTokenValues();

      var templateName = obj.templateName;
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          templateName +
          "/getSpecificTemplateDetails",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** DELETE FEEDBACK */
export const deleteFeedback = createAsyncThunk(
  "feedback/deleteFeedback",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      obj.organizationId = orgId;
      obj.adminId = adminId;
      console.log("delete obj ", obj);
      var id = obj.feedId;
      const payload = { obj };
      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/admin/${adminId}/${id}/deleteFeedback`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/** PUBLISH FEEDBACK */
export const publishFeedback = createAsyncThunk(
  "feedback/publishFeedback",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      obj.organizationId = orgId;
      obj.adminId = adminId;
      var feedbackId = obj.feedId;
      const payload = { obj };
      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/admin/${adminId}/${feedbackId}/publishFeedback`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/** GET ATTEMPTED STUDENTS FOR FEEDBACK */
export const getFeedbackAttemptedStudents = createAsyncThunk(
  "feedback/getFeedbackAttemptedStudents",
  async (feedbackId, thunkAPI) => {
    try {
      console.log("printing data", feedbackId);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/" +
          feedbackId +
          "/getFeedbackStudents",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** GET ATTEMPTED STUDENTS FOR FEEDBACK */
export const getFeedbackStudentAnswer = createAsyncThunk(
  "feedback/getFeedbackStudentAnswer",
  async (obj, thunkAPI) => {
    var feedbackId = obj.feedbackId;
    var studentId = obj.studentId;
    try {
      console.log("printing data", feedbackId);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/student/" +
          studentId +
          "/" +
          feedbackId +
          "/getParticularFeedbackAnswer",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        console.log("inside if condition");
        return data.Data;
      } else {
        console.log("inside else condition");
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Extend Date
export const extendExpiryDate = createAsyncThunk(
  "feedback/extendExpiryDate",
  async (object, thunkAPI) => {
    try {
      console.log("printing object expiry: ", object);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/" +
          object.feedId +
          "/extendExpiryDate",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(object),
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// SEND REMINDER
export const sendFeedbackFillReminder = createAsyncThunk(
  "feedback/sendFeedbackFillReminder",
  async (object, thunkAPI) => {
    try {
      console.log("printing object sendReminder: ", object);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/feedback/" +
          object.feedId +
          "/unAttemptedSurveyMail",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(object),
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// TEST EMAIL TEMPLATE
export const sendFeedbackTestEmail = createAsyncThunk(
  "feedback/sendFeedbackTestEmail",
  async (object, thunkAPI) => {
    try {
      console.log("printing object sendReminder: ", object);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/feedback/" +
          object.feedId +
          "/testSurveyMail",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(object),
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** DOWNLOAD ATTEMPTED STUDENTS CSV FOR FEEDBACK */
export const getAttemptedStudentCSV = createAsyncThunk(
  "feedback/getAttemptedStudentCSV",
  async (feedbackId, thunkAPI) => {
    try {
      console.log("printing data", feedbackId);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/" +
          feedbackId +
          "/dataToPrintfeedbackCSV",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /:organizationId/admin/:adminId/sendFeeDuesToStudents

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    successMessage: "",
    feedbackList: [],
    feedbackCount: null,
    feedbackResponse: {},
    programDetails: [],
    studentList: [],
    totalStudentsCount: null,
    batchDetail: [],
    feedbackDetail: null,
    emailTemplateData: "",
    attemptedStudentList: [],
    studentAnswer: [],
    csvAttemptedStudent: [],
    createdSuccess: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      state.feedbackList = [];
      state.feedbackResponse = {};
      state.feedbackDetail = null;
      state.createdSuccess = false;

      return state;
    },
    clearDataResponse: (state) => {
      state.feedbackResponse = {};
      state.feedbackDetail = null;
      state.createdSuccess = false;

      return state;
    },
    setInitialState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      state.createdSuccess = false;
      state.feedbackResponse = {};
      state.feedbackDetail = null;
    },
  },
  extraReducers: {
    [createFeedback.fulfilled]: (state, { payload }) => {
      state.createdSuccess = true;
      console.log("printing payload", payload);
      state.feedbackResponse = payload;
      state.isFetching = false;
      return state;
    },
    [createFeedback.rejected]: (state, { payload }) => {
      console.log("payload rejected", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [createFeedback.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getAllFeedbacks.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.feedbackList = payload.Data;
      state.feedbackCount = payload.Count;
      return state;
    },
    [getAllFeedbacks.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getAllFeedbacks.pending]: (state) => {
      console.log("inside pending state getAllFeedbacks", state);
      state.isFetching = true;
    },
    [getFeedbackDetailById.fulfilled]: (state, { payload }) => {
      console.log("printing payload id", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.feedbackDetail = payload;
      return state;
    },
    [getFeedbackDetailById.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getFeedbackDetailById.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getAllStudent.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.studentList = payload?.Students;
      state.totalStudentsCount = payload?.totalCount;
      return state;
    },
    [getAllStudent.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getAllStudent.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getAllProgramsDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.programDetails = payload;
      return state;
    },
    [getAllProgramsDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getAllProgramsDetails.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getAllBatchByProgramId.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.batchDetail = payload;
      return state;
    },
    [getAllBatchByProgramId.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getAllBatchByProgramId.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [editFeedback.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // state.studentList = payload;
      return state;
    },
    [editFeedback.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [editFeedback.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getEmailTemplate.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.emailTemplateData = payload;
      // state.studentList = payload;
      return state;
    },
    [getEmailTemplate.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getEmailTemplate.pending]: (state) => {
      console.log("inside pending state emailTEmplate", state);
      state.isFetching = true;
    },
    [deleteFeedback.fulfilled]: (state, { payload }) => {
      console.log("printing payload deleting", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.feedbackList = payload;
      // state.studentList = payload;
      return state;
    },
    [deleteFeedback.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [deleteFeedback.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getFeedbackAttemptedStudents.fulfilled]: (state, { payload }) => {
      console.log("printing payload deleting", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.attemptedStudentList = payload;
      // state.studentList = payload;
      return state;
    },
    [getFeedbackAttemptedStudents.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
      // state.feedbackList = payload;
    },
    [getFeedbackAttemptedStudents.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getFeedbackStudentAnswer.fulfilled]: (state, { payload }) => {
      console.log("printing payload deleting", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.studentAnswer = payload;
      // state.studentList = payload;
      return state;
    },
    [getFeedbackStudentAnswer.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
      // state.feedbackList = payload;
    },
    [getFeedbackStudentAnswer.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [extendExpiryDate.fulfilled]: (state, { payload }) => {
      console.log("printing payload extend", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = "Expiry Date Extended";
      // state.studentList = payload;
      return state;
    },
    [extendExpiryDate.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message;
      // state.feedbackList = payload;
    },
    [extendExpiryDate.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [publishFeedback.fulfilled]: (state, { payload }) => {
      console.log("printing payload extend", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = "Feedback Published";
      // state.studentList = payload;
      return state;
    },
    [publishFeedback.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message;
      // state.feedbackList = payload;
    },
    [publishFeedback.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getAttemptedStudentCSV.fulfilled]: (state, { payload }) => {
      console.log("printing payload extend", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.csvAttemptedStudent = payload;
      state.successMessage = "Feedback Published";
      // state.studentList = payload;
      return state;
    },
    [getAttemptedStudentCSV.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message;
      // state.feedbackList = payload;
    },
    [getAttemptedStudentCSV.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [sendFeedbackFillReminder.fulfilled]: (state, { payload }) => {
      console.log("printing payload extend", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = "Reminder sent to unattempted Students";
      // state.studentList = payload;
      return state;
    },
    [sendFeedbackFillReminder.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message;
      // state.feedbackList = payload;
    },
    [sendFeedbackFillReminder.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [sendFeedbackTestEmail.fulfilled]: (state, { payload }) => {
      console.log("printing payload extend", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = "Test email sent to registered email address";
      // state.studentList = payload;
      return state;
    },
    [sendFeedbackTestEmail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message;
      // state.feedbackList = payload;
    },
    [sendFeedbackTestEmail.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
  },
});

export const { clearState, clearDataResponse, setInitialState } =
  feedbackSlice.actions;

export const feedbackData = (state) => state.feedbackList;
