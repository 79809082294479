import React, { useEffect } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const PdfDownloader = ({
  rootElementId,
  downloadFileName,
  isDownload,
  setDownload,
}) => {
  useEffect(() => {
    if (isDownload) {
      downloadPdfDocument();
      setDownload(false);
    }
  }, [isDownload]);

  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);
    html2canvas(input, { scale: 2, quality: 4 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "cm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();

      console.log("checking heaight", height);
      pdf.addImage(imgData, "JPEG", 0, 1, width, height - 2);
      pdf.save(`${downloadFileName}.pdf`);
    });
  };

  return <></>;
};

export default PdfDownloader;
