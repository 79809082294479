import React, { useEffect, useRef, useState } from "react";
import "./InterviewerPriorityPopupStyle.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  feeData,
  getOrganizationInterviewerList,
  getOrganizationMentorList,
  getOrganizationPrograms,
  postAddInterviewerPriorities,
  postAddInterviewerToProgram,
  postAddMentorForInterviewToProgram,
} from "../../../reducers/feeSlice";

import ReactSelect from "react-select";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const InterviewerPriorityPopup = ({
  show,
  handleClose,
  interviewerList,
  viewType,
  programMasterId,
}) => {
  const dispatch = useDispatch();
  const { programList, allInterviewerList } = useSelector(feeData);
  const defaultList = ["Gurji kochar", "Vaibhav", "Kashish"];
  // React state to track order of items
  const [itemList, setItemList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [programId, setProgramId] = useState("");

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
  };

  useEffect(() => {
    if (interviewerList.length > 0) {
      setItemList(interviewerList);
    }
  }, [interviewerList]);

  console.log(interviewerList, "print item", itemList);

  useEffect(() => {
    if (viewType === "addInterview") {
      dispatch(getOrganizationInterviewerList());
    }

    if (viewType === "addMentor") {
      dispatch(getOrganizationMentorList());
    }
  }, [viewType]);

  const interviewerArray =
    allInterviewerList?.length > 0 &&
    allInterviewerList?.map((res) => ({
      id: res.id,
      value: res.id,
      label: res.firstName + " " + res.lastName,
    }));

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleProgramChange = (e) => {
    setProgramId(e.target.value);
  };

  console.log("fddfdfdfdfdf", selectedOptions);

  const onClickSaveInterview = () => {
    let newArray = [];
    selectedOptions.forEach((element) => {
      newArray.push(element.id);
    });

    let obj = {
      programMasterId: programMasterId,
      interviewersArray: newArray,
    };

    console.log("sdsdsdsdsdsdsdd", obj);
    dispatch(postAddInterviewerToProgram(obj));
  };

  const onClickSavePriority = () => {
    let obj = {
      priorities: itemList,
    };

    dispatch(postAddInterviewerPriorities(obj));
  };

  const onClickSaveMentor = () => {
    let newArray = [];
    selectedOptions.forEach((element) => {
      newArray.push(element.id);
    });

    let obj = {
      programMasterId: programMasterId,
      interviewersArray: newArray,
    };

    console.log("sdsdsdsdsdsdsdd", obj);
    dispatch(postAddMentorForInterviewToProgram(obj));
  };

  const onClickSaveMentorPriority = () => {
    let obj = {
      priorities: itemList,
    };

    dispatch(postAddInterviewerPriorities(obj));
  };

  return (
    <div className={`popup-outer ${show ? "show-popup" : ""}`}>
      <div className="setPriorityContainer">
        <div className="popup-header">
          <div className="popup-heading">
            {viewType === "setPriority" && "Slots Priority"}
            {viewType === "addInterview" && "Add Interviewer"}
            {viewType === "addMentor" && "Add Mentor"}
            <img
              src="../assets/icons/close.svg"
              alt=""
              onClick={() => {
                handleClose();
                setItemList([]);
              }}
              className="close-btn"
            />
          </div>
        </div>
        <div className="popup-body">
          {viewType === "setPriority" && (
            <>
              <div className="dragableOuterContainer">
                <div className="priorityRow">
                  {itemList.map((item, index) => (
                    <div className="priority">P{index + 1}</div>
                  ))}
                </div>

                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable droppableId="list-container">
                    {(provided) => (
                      <div
                        className="list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {itemList.map((item, index) => (
                          <Draggable
                            key={item.adminId}
                            draggableId={item.adminId}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="item-container"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                {(item.firstName + " " + item.lastName)
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.substring(1)
                                  )
                                  .join(" ")}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <button
                onClick={() => {
                  onClickSavePriority();
                }}
                className="primary-btn w-100 mt-20"
              >
                Save
              </button>
            </>
          )}

          {viewType === "addInterview" && (
            <>
              <div className="reactSelectContainer">
                <ReactSelect
                  options={interviewerArray}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  onChange={handleChange}
                  allowSelectAll={true}
                  value={selectedOptions}
                />
              </div>

              <button
                onClick={onClickSaveInterview}
                className="primary-btn w-100 mt-20"
              >
                Save
              </button>
            </>
          )}

          {viewType === "addMentor" && (
            <>
              <div className="reactSelectContainer">
                <ReactSelect
                  options={interviewerArray}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  onChange={handleChange}
                  allowSelectAll={true}
                  value={selectedOptions}
                />
              </div>

              <button
                onClick={onClickSaveMentor}
                className="primary-btn w-100 mt-20"
              >
                Save
              </button>
            </>
          )}

          {/* <select type="text" className="create-input" name="p1" id="p1">
                  {interviewerList.map((data) => {
                    const { id, value, label } = data;
                    return (
                      <option key={id} value={value}>
                        {label}
                      </option>
                    );
                  })}
                </select> */}
          {/* </div>
            );
          })} */}
        </div>
      </div>
    </div>
  );
};

export default InterviewerPriorityPopup;
