import React, { useEffect, useState } from "react";
import "../AddStudentListPopup/AddStudentListPopupStyle.css";

import { CSVLink, CSVDownload } from "react-csv";
const AddEmailPopup = (props) => {
  const arr = [];
  // props.sampleFileDownload
  useEffect(() => {}, [props.csvFileDownload]);
  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container csv-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Add {props.title ? props.title : "Applicants"}
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>

            <div className="popup-header-body">
              <div className="header-left">
                <p className="text-7">
                  Upload {props.title ? props.title : "student"} list in .csv
                  format
                </p>
                {/* <p className="text-7r">You can’t undo this action.</p> */}
              </div>
            </div>
          </div>
          <div className="popup-body">
            <div className="form-group">
              <label htmlFor="app-list" className="create-label">
                {props.title ? props.title : "Application"} List{" "}
                <span className="imp-mark">*</span>
              </label>
              <div className="upload-btn-wrapper">
                <div className="upload-inner">
                  {/* <input   
                  name='file'
                  type='file'
                  onChange={props.handleFile("file")}
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  id="" 
                  className="upload-btn"
                  hidden
                  /> */}
                  {!props.isFilePicked && (
                    <input
                      className="uploadbox"
                      onChange={props.onClickUpload}
                      ref={props.hiddenFileInput}
                      id="uploadbox"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  )}
                  <button className="upload-btn" onClick={props.handleClick}>
                    Choose a File
                  </button>
                  <p className="file-name">{props.selectedFile?.name}</p>
                </div>
                <img
                  src="../../assets/icons/delete.svg"
                  alt=""
                  onClick={props.onClickDeleteUpload}
                />
              </div>
              <span className="error-msg ">select any option</span>

              {/* <p className="text-5" style={{cursor:'pointer'}}
            onClick={props.sampleFileDownloadCsv}>Sample File Download</p> */}
              {/* <CSVLink
                data={props.csvFileDownload ? props.csvFileDownload : ""}
                target="_blank"
                className="clear-btn"
              >
                Download Sample File
              </CSVLink> */}
            </div>
          </div>
          <div className="popup-footer">
            <div className="btn-group">
              <button onClick={props.handleClose} className="dark-btn-outline">
                cancel
              </button>
              <button className="green-btn" onClick={props.onClickCsv}>
                Add CSV File
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEmailPopup;
