import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

// localhost:3000/api/org/58e74be4-57a8-40b0-be4b-8b77b73c4efc/fetchProgramTransactionDetailsFromCronEmail
export const getTransactionHistory = createAsyncThunk(
  "tranactionHistory/getTransactionHistory",
  async (obj, thunkAPI) => {
    try {
        let proId=obj.proId
        let payload={
          date:obj.date
        }
      const response = await fetch(
        environment.baseURL + "/api/org/"+proId+"/fetchProgramTransactionDetailsFromCronEmail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const transactionHistorySlice = createSlice({
  name: "tranactionHistory",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    getAllTransaction: {},
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      return state;
    },
  },
  extraReducers: {
    [getTransactionHistory.fulfilled]: (state, { payload }) => {
      state.getAllTransaction = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getTransactionHistory.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getTransactionHistory.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
  },
});

export const { clearState } = transactionHistorySlice.actions;

export const tranactionHistoryData = (state) => state.tranactionHistory;
