import React, { useEffect, useState } from "react";
import "./FeeRecieptStyle.css";
import "./TaxInvoiceStyle.css";
import convertor from "number-to-words";
import PdfDownloader from "../PdfDownloader/PdfDownloader";
import moment from "moment";

const TaxInvoice = ({ showTaxInvoice, setShowTaxInvoice, taxInvoiceData }) => {
  const [isDownload, setDownload] = useState(false);

  console.log("prinintg data", taxInvoiceData);

  return (
    <>
      <PdfDownloader
        downloadFileName={taxInvoiceData.name + "_" + taxInvoiceData.feeName}
        rootElementId="receiptZero"
        isDownload={isDownload}
        setDownload={(val) => setDownload(val)}
      />
      <div className={`popup-outer ${showTaxInvoice ? "show-popup" : ""}`}>
        <div className="popup-container receipt-popup-container">
          <div className="popup-header">
            <div className="popup-heading receipt-popup-header">
              <div className="popup-right">
                Download Receipt
                <button
                  className="primary-btn-a"
                  onClick={() => setDownload(true)}
                >
                  Download Now
                </button>
              </div>
              <img
                onClick={() => {
                  setShowTaxInvoice(false);
                }}
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
              />
            </div>
          </div>
          <div className="popup-body  fee-recipt-body scrollbar">
            <div className={`receipt-outer`}>
              <div className="taxInvoiceContainerOuter">
                <div class="taxInvoiceContainer" id="receiptZero">
                  <div class="taxInvoiceHeader">
                    <img src="../assets/icons/old-mu-on-white.svg" alt="" />
                    <p> Office of Finance</p>
                  </div>
                  <p className="taxHeading">Tax Invoice</p>
                  <div className="taxInvoiceHeader mt-10">
                    <p className="taxDetailText">
                      Invoice No: <span>{taxInvoiceData.receiptNumber}</span>
                    </p>
                    <p className="taxDetailText">
                      Invoice Date:{" "}
                      <span>
                        {moment(taxInvoiceData?.invoiceGeneratedOn).format("Do MMM YYYY")}
                      </span>
                    </p>
                  </div>
                  <div className="taxStudentInfo">
                    <p className="name">{taxInvoiceData.name},</p>
                    <p className="taxInfo mt-10">
                      <span>Address:</span> {taxInvoiceData.address}
                    </p>
                    <p className="taxInfo">
                      <span>Place of Supply:</span> {taxInvoiceData.supplyPlace}
                    </p>
                    <p className="taxInfo">
                      Whether the TAX is payable on reverse charge basis -{" "}
                      <span>No</span>
                    </p>
                    <p className="taxInfo">
                      <span>GST No:</span>{" "}
                      {taxInvoiceData.studentGSTNo === "" ||
                      taxInvoiceData.studentGSTNo === null
                        ? "Unregistered"
                        : taxInvoiceData.studentGSTNo}
                    </p>
                  </div>
                  <table
                    className="receipt-table mt-10"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th colSpan="4">Description</th>
                        <th>HSN/SAC Code</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td colSpan="4">
                          <b>
                            Fee For Mastercamp In {taxInvoiceData.programName}
                          </b>
                        </td>
                        <td>{taxInvoiceData.hsnCode}</td>
                        <td>
                          ₹{" "}
                          {(
                            parseFloat(taxInvoiceData.feeAmount) / 1.18
                          ).toFixed(2)}
                        </td>
                      </tr>

                      {taxInvoiceData.supplyPlace === "Haryana" ||
                      taxInvoiceData.supplyPlace === "" ||
                      taxInvoiceData.supplyPlace === null ? (
                        <>
                          <tr>
                            <td></td>
                            <td colSpan="2"></td>
                            <td style={{ textAlign: "left" }} colSpan="3">
                              CGST @ 9%
                            </td>
                            <td>
                              ₹{" "}
                              {(
                                ((parseFloat(taxInvoiceData.feeAmount) * 100) /
                                  118) *
                                0.09
                              ).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td colSpan="2"></td>
                            <td style={{ textAlign: "left" }} colSpan="3">
                              SGST @ 9%
                            </td>
                            <td>
                              ₹{" "}
                              {(
                                ((parseFloat(taxInvoiceData.feeAmount) * 100) /
                                  118) *
                                0.09
                              ).toFixed(2)}
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {taxInvoiceData.feeTaxes &&
                            taxInvoiceData.feeTaxes.length > 0 &&
                            taxInvoiceData.feeTaxes.map((tax) => (
                              <tr key={tax.id}>
                                <td></td>
                                <td colSpan="2"></td>
                                <td style={{ textAlign: "left" }} colSpan="3">
                                  I{tax.taxType} @ {tax.percent}%
                                </td>
                                <td>
                                  ₹{" "}
                                  {(
                                    parseFloat(taxInvoiceData.feeAmount) -
                                    parseFloat(taxInvoiceData.feeAmount) / 1.18
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            ))}
                        </>
                      )}

                      <tr>
                        <td></td>
                        <td colSpan="2"></td>
                        <td colSpan="2"></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }} colSpan="6">
                          <b>Total Amount After Tax</b>
                        </td>
                        <td>
                          <b>₹ {taxInvoiceData.feeAmount}</b>
                        </td>
                      </tr>
                      <tr>
                        {taxInvoiceData.feeAmount && (
                          <td colSpan="7" style={{ textAlign: "left" }}>
                            Rupees in Words:{" "}
                            <b className="initCap">
                              {convertor.toWords(taxInvoiceData.feeAmount)} /-
                            </b>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  <div className="stamp taxInvoice">
                    <p className="receipt-text">For Masters’ Union</p>
                    <img
                      src="assets/icons/muSignatureImg.png"
                      alt=""
                      className="stampImg"
                    />
                    <p className="receipt-text mt-40">Authorised Signatory</p>
                  </div>
                  <hr />
                  <div className="receipt-footer">
                    <div className="location">
                      <img
                        style={{ width: "200px" }}
                        src="assets/icons/MapPin.svg"
                        alt="map-icon"
                      />
                      <div className="content">
                        <p>
                          <b>Masters’ Union</b>, A Unit of Shanti Informatics
                          Ground Floor, Tower C, Dlf Cyber Park Parcel of Land
                          at Plot No-405-B Sector 20, Udyog Vihar, Phase III
                          Gurgaon, Haryana, PIN-122008
                        </p>
                      </div>
                    </div>
                    <div className="location">
                      <img
                        style={{ width: "63px" }}
                        src="assets/icons/Phone.svg"
                        alt="phone-icon"
                      />
                      <div className="content">
                        <p>
                          <b>1800 120 5288</b>
                        </p>
                        <p>GSTIN:06ADSFS8704J1ZG</p>
                        <p>PAN:ADSFS8704J</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxInvoice;
