import React from "react";
import { useState, useEffect, useRef } from "react";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import AddVendorPopup from "../../../components/Popups/AddVendorPopup/AddVendorPopup";
import "./RaiseRequestStyle.css";
import ErrorToast from "../../../components/Toast/ErrorToast";
import { useDispatch, useSelector } from "react-redux";
import {
  addVendorDetails,
  addMemberDetails,
  clearState,
  expenseSliceFunction,
  getAllVendors,
  postRequestData,
  getAllMembers,
} from "../../../reducers/expenseSlice";
import {
  feeData,
  getOrganizationProgramsWithBatch,
} from "../../../reducers/feeSlice";
import * as filestack from "filestack-js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../components/UI/loader/PageLoader";
import TagInput from "../../../components/TagInput/TagInput";
import "../../../components/TagInput/TagInput.css";

const client = filestack.init("AcpQ2RjKSnWT5GjS9f6wOz"); // filestack key updated
const token = {};

const RaiseRequest = () => {
  let vendorData = {
    vendorName: "",
    gstNumber: "",
    contactPersonName: "",
    phoneNumber: "",
    email: "",
    bankName: "",
    accountNumber: "",
    IfscCode: "",
    isMember: false,
  };

  let memberData = {
    memberName: "",
    gstNumber: "",
    contactPersonName: "",
    phoneNumber: "",
    email: "",
    bankName: "",
    accountNumber: "",
    IfscCode: "",
  };
  const keyDeliverableRef = React.useRef(null);
  const agreementRef = React.useRef(null);
  const invoiceRef = React.useRef(null);
  const vendorRef = useRef(null);

  const { programNameList } = useSelector(feeData);
  const [fetching, setFetch] = useState(false);
  const navigate = useNavigate();
  const [cohort, setCohort] = useState(null);

  const Purpose = [
    "Select purpose of expense",
    "Admissions and Marketing",
    "Acad Ops/Student Experience",
    "Career Services",
    "Academics",
    "Admin",
    "Master Relations",
    "Travel",
  ];
  const Invoice = ["Enter Invoice Type", "Final", "Partial", "Advance"];
  const [errMessage, setErrMessage] = useState(
    "Please fill all the required field"
  );

  const [postData, setPostData] = useState({
    vendorId: "",
    memberId: "",
    expenseFor: "",
    purpose: "",
    amount: 0,
    invoiceType: "",
    approvingAuthority: "",
    expenseDate: "",
    dueDate: "",
    agreementCopy: "",
    invoices: "",
    deliverables: "",
    cohort: "",
  });

  const {
    isFetching,
    isSuccess,
    isError,
    allVendors,
    requestRaised,
    allMembers,
  } = useSelector(expenseSliceFunction);
  const dispatch = useDispatch();
  const [isShowError, setIsShowError] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({ ...vendorData });
  // const [memberDetails, setMemberDetails] = useState({ ...memberData });

  const [showAddVendorPopup, setShowAddVendorPopup] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [memberPopup, setMemberPopup] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [keyDeliverables, setkeyDeliverables] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [isGenral, setIsGenral] = useState(false);

  //  const [aggrementCopy,setAggrementCopy] =useState({"name":"","url":""})

  //  console.log(postData);

  useEffect(() => {
    console.log("tags and deliverables: ");
    console.log(tags, keyDeliverables);
    if (tags.length > 0) {
      let arr = [];
      for (let i = 0; i < tags.length; i++) {
        console.log("in for", i);
        arr.push({
          keyDeliverables: tags[i].name,
          proofOfDeliverables: keyDeliverables?.[i]?.url ?? "",
        });
      }
      console.log(arr);
      setPostData((data) => {
        console.log({ ...data, deliverables: arr });
        return { ...data, deliverables: arr };
      });
    }
    console.log(postData);
    if (invoice && invoice.length > 0) {
      let arr = [];
      invoice?.forEach((item) => {
        arr.push({ attachment: item.url });
      });
      setPostData({ ...postData, invoices: arr });
    }
  }, [keyDeliverables, invoice, tags]);

  const handleSave = () => {
    // Object.keys(postData).forEach(function(key, index) {
    //   console.log(postData[key].length);
    //   if((postData[key].length==0  && key!= "vendorId" && key!= "memberId" &&key!= "memberId")|| postData[key]==="" ){
    //     console.log("aj",key)
    //     setErrMessage(`${key} field cannot be empty`)
    //     setIsShowError(true);
    //   }
    //   else if(postData["memberId"]=="" && postData["vendorId"]==""){
    //     setErrMessage(`Please add a member or vendor`)
    //     setIsShowError(true);
    //   }
    //   else{
    //     setErrMessage(false)
    //   }
    // }
    // );
    // if(errMessage==false){dispatch(postRequestData(postData))}
    console.log("in save");
    if (
      postData.amount != 0 &&
      (isGenral || (postData.cohort != "" && postData.expenseFor != "")) &&
      tags.length > 0 &&
      (postData.memberId != "" || postData.vendorId != "") &&
      postData.expenseDate != "" &&
      postData.approvingAuthority != "" &&
      invoice.length > 0
    ) {
      console.log(tags, keyDeliverables);
      // console.log("saved", postData);
      dispatch(postRequestData({ ...postData, isGenralReq: isGenral }));
    }
    // else if (tags.length !== keyDeliverables.length) {
    //   setErrMessage("Proof of key deliverables should be in number as key deliverables")
    //   setIsShowError(true)
    // }
    else {
      setIsShowError(true);
      setErrMessage("Please fill all the required field");
    }
  };
  // console.log(invoice,keyDeliverables);

  const handleDelete = () => {
    setIsFilePicked("");
    setSelectedKey("");

    setPostData({ ...postData, agreementCopy: "" });
  };
  const handleClick = (ref) => {
    if (ref.current) ref.current.click();
  };

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "n/a";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + " " + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  };

  const onClickUpload = (event, name = "") => {
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      if (name == "key") {
        setFetch(true);
        // client
        //   .upload(files, {}, token)
        //   .then((res) => {
        //     console.log(res);
        //     if (res) {
        //       let obj = { name: event.target.files[0].name, url: res.url, size: res.size }
        //       setkeyDeliverables([...keyDeliverables, { ...obj }])
        //       setFetch(false)
        //     }

        //     else {
        //       errMessage = "Connection Error"
        //       setFetch(false)
        //       setIsShowError(true);
        //     }
        //   })
        const data = new FormData();

        data.append("file", files);
        data.append("upload_preset", "qlm4yp8h");
        data.append("cloud_name", "df491ehfg");

        fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            console.log("printing", res);
            if (res) {
              let obj = {
                name: event.target.files[0].name,
                url: res.secure_url,
                size: bytesToSize(res.bytes),
              };
              console.log("print object", obj);
              setkeyDeliverables([...keyDeliverables, { ...obj }]);
              setFetch(false);
            } else {
              errMessage = "Connection Error";
              setFetch(false);
              setIsShowError(true);
            }
          })
          .catch((err) => {
            setFetch(false);
          });
      } else if (name == "invoice") {
        setFetch(true);
        // client.upload(files, {}, token).then((res) => {
        //   if (res) {
        //     let obj = {
        //       name: event.target.files[0].name,
        //       url: res.url,
        //       size: res.size,
        //     };
        //     setInvoice([...invoice, { ...obj }]);
        //     setFetch(false);
        //   } else {
        //     errMessage = "Connection Error";
        //     setFetch(false);
        //     setIsShowError(true);
        //   }
        // });

        const data = new FormData();

        data.append("file", files);
        data.append("upload_preset", "qlm4yp8h");
        data.append("cloud_name", "df491ehfg");

        fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            console.log("printing", res);
            if (res) {
              let obj = {
                name: event.target.files[0].name,
                url: res.secure_url,
                size: bytesToSize(res.bytes),
              };
              console.log("print object", obj);
              setInvoice([...invoice, { ...obj }]);
              setFetch(false);
            } else {
              errMessage = "Connection Error";
              setFetch(false);
              setIsShowError(true);
            }
          })
          .catch((err) => {
            setFetch(false);
          });
      }
    }
  };

  const onClickRemove = (name = "", val = "") => {
    // console.log(name,val)
    if (name == "key") {
      let filtered = keyDeliverables.filter((item) => item.name !== val);
      setkeyDeliverables([...filtered]);
    } else if (name == "invoice") {
      let filtered = invoice.filter((item) => item.name !== val);
      setInvoice([...filtered]);
    }
  };

  const getCohort = (id) => {
    if (id !== "") {
      let result = programNameList?.find((item) => item.id === id);
      return result;
    } else {
      return null;
    }
  };

  const uploadAggrementCopy = (event) => {
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      // setAggrementCopy(val=>({...val,"name":event.target.files[0].name}))
      setFetch(true);
      // client.upload(files, {}, token).then((res) => {
      //   if (res) {
      //     setPostData((postData) => ({ ...postData, agreementCopy: res.url }));
      //     setSelectedKey(event.target.files[0].name);
      //     setFetch(false);
      //     setIsFilePicked(true);
      //   } else {
      //     setFetch(false);
      //     errMessage = "Connection Error";
      //     setIsShowError(true);
      //   }
      // });

      const data = new FormData();

      data.append("file", files);
      data.append("upload_preset", "qlm4yp8h");
      data.append("cloud_name", "df491ehfg");

      fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("printing", res);
          // if (res) {
          //   let obj = {
          //     name: event.target.files[0].name,
          //     url: res.secure_url,
          //     size: bytesToSize(res.bytes),
          //   };
          //   console.log("print object", obj);
          //   setInvoice([...invoice, { ...obj }]);
          //   setFetch(false);
          // } else {
          //   errMessage = "Connection Error";
          //   setFetch(false);
          //   setIsShowError(true);
          // }

          if (res) {
            setPostData((postData) => ({
              ...postData,
              agreementCopy: res.secure_url,
            }));
            setSelectedKey(event.target.files[0].name);
            setFetch(false);
            setIsFilePicked(true);
          } else {
            setFetch(false);
            errMessage = "Connection Error";
            setIsShowError(true);
          }
        })
        .catch((err) => {
          setFetch(false);
        });
    }
  };

  const memberCheckedToggle = () => {
    setIsMember(!isMember);
    if (vendorRef.current) vendorRef.current.selectedIndex = 0;

    setPostData((val) => ({ ...val, vendorId: "" }));
  };

  const handleAddVendorPopup = (val = "") => {
    if (val === "member") {
      setMemberPopup(true);
      setVendorDetails({ ...memberData });
    } else {
      setMemberPopup(false);
      setVendorDetails({ ...vendorData });
    }
    setShowAddVendorPopup(!showAddVendorPopup);
  };

  const vendorDetailsHandleChange = (e) => {
    const { name, value } = e.target;
    setVendorDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const vendorDetailsSubmit = () => {
    if (
      (vendorDetails.vendorName !== "" || vendorDetails.memberName !== "") &&
      vendorDetails.contactPersonName !== "" &&
      vendorDetails.phoneNumber !== "" &&
      vendorDetails.email !== "" &&
      vendorDetails.bankName !== "" &&
      vendorDetails.accountNumber !== "" &&
      vendorDetails.IfscCode !== ""
    ) {
      if (memberPopup) {
        dispatch(addMemberDetails(vendorDetails));
      } else {
        dispatch(addVendorDetails(vendorDetails));
      }
    } else {
      setIsShowError(true);
      setErrMessage("Please fill all the required fields");
    }
  };

  useEffect(() => {
    dispatch(getAllVendors());
    dispatch(getAllMembers());
    dispatch(getOrganizationProgramsWithBatch());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      handleAddVendorPopup();
      dispatch(getAllVendors());
    }
    if (isSuccess && requestRaised) {
      dispatch(clearState());
      navigate("/financeRequest", { exact: true });
    }
  }, [isSuccess]);
  console.log(postData, "data");

  return (
    <>
      <SideMenu />
      {(isFetching || fetching) && <PageLoader />}
      return (
      <>
        <SideMenu />
        {(isFetching || fetching) && <PageLoader />}

        <ErrorToast
          show={isShowError}
          setShow={() => setIsShowError(false)}
          message={errMessage}
        />
        <AddVendorPopup
          show={showAddVendorPopup}
          handleAddVendorPopup={handleAddVendorPopup}
          vendorDetails={vendorDetails}
          vendorDetailsHandleChange={vendorDetailsHandleChange}
          vendorDetailsSubmit={vendorDetailsSubmit}
          memberPopup={memberPopup}
        />
        <div className="main-body">
          <div className="page-header center-heading">
            <button className="component-back">
              <img src="../assets/icons/back-btn.svg" />
              <p
                className="text-5"
                onClick={() => navigate("/financeRequest", { exact: true })}
              >
                Back to Page
              </p>
            </button>
            <p className="component-heading primary-text">Raise Request</p>
          </div>
          <div className=" scrollbar show" id="container-1">
            <div className="create-container raiseRequest">
              <form action="">
                {!isMember && (
                  <div className="form-2-col">
                    <div className="form-grp">
                      <label htmlFor="">
                        Vendor Name <span className="imp-mark">*</span>
                      </label>
                      <select
                        name=""
                        id=""
                        className="customSelect"
                        defaultValue={"Select Vendor"}
                        onChange={(e) =>
                          setPostData({ ...postData, vendorId: e.target.value })
                        }
                        ref={vendorRef}
                      >
                        <option disabled>Select Vendor</option>
                        {allVendors?.length > 0 &&
                          allVendors?.map((res, i) => (
                            <option value={res.id} key={i}>
                              {res.vendorName}
                            </option>
                          ))}
                      </select>
                      <span className="error-msg">Enter the field</span>
                    </div>
                    <img
                      src="../assets/icons/plus-red.svg"
                      alt=""
                      className="pointer"
                      onClick={() => handleAddVendorPopup()}
                    />
                  </div>
                )}
                <div className="form-2-col">
                  <div className="form-grp">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        name="isGenral"
                        id="isGenral"
                        className="checkmark-btn"
                        checked={isGenral}
                        onChange={() => {
                          setIsGenral((g) => !g);
                          setPostData({
                            ...postData,
                            expenseFor: "",
                            cohort: "",
                          });
                        }}
                      />
                      <span className="checkmarkspan"></span>
                      General Entry
                    </label>
                  </div>
                </div>

                {!isGenral && (
                  <div className="form-2-col">
                    <div className="form-grp">
                      <label htmlFor="" className="create-label">
                        Expense incurred for <span className="imp-mark">*</span>
                      </label>
                      <select
                        name=""
                        id=""
                        className="customSelect"
                        defaultValue={"Select Program"}
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            expenseFor: e.target.value,
                          })
                        }
                      >
                        <option disabled>Select Program</option>
                        {programNameList?.length > 0 &&
                          programNameList?.map((res, i) => (
                            <option value={res.id} key={i}>
                              {res.programName}
                            </option>
                          ))}
                      </select>
                      <span className="error-msg">Enter the field</span>
                    </div>
                    {getCohort(postData.expenseFor) && (
                      <div className="form-grp mb-0">
                        <label htmlFor="" className="create-label">
                          Cohort <span className="imp-mark">*</span>
                        </label>

                        <select
                          name=""
                          id=""
                          className="customSelect"
                          defaultValue={Purpose[0]}
                          onChange={(e) =>
                            setPostData((val) => ({
                              ...val,
                              cohort: e.target.value,
                            }))
                          }
                        >
                          <option value="">Choose Cohort</option>

                          {getCohort(postData.expenseFor) ? (
                            getCohort(postData.expenseFor)?.ProgramDetails?.map(
                              (item, index) => (
                                <option value={item.id} key={index}>
                                  {item.name}
                                </option>
                              )
                            )
                          ) : (
                            <option value="">
                              Please select a program first
                            </option>
                          )}
                        </select>
                        <span className="error-msg">Enter the field</span>
                      </div>
                    )}
                  </div>
                )}

                <div className="form-grp mb-0">
                  <label htmlFor="" className="create-label">
                    Raised For <span className="imp-mark">*</span>
                  </label>
                  <select
                    name=""
                    id=""
                    className="customSelect"
                    defaultValue={Purpose[0]}
                    onChange={(e) =>
                      setPostData({ ...postData, purpose: e.target.value })
                    }
                  >
                    {/* <option value="" disabled>Select purpose of expense</option> */}
                    {Purpose &&
                      Purpose?.map((item, index) =>
                        index !== 0 ? (
                          <option value={item} key={index}>
                            {item == "Acad Ops/Student Experience"
                              ? "Student Experience"
                              : item}
                          </option>
                        ) : (
                          <option value={item} key={index} disabled>
                            {item}
                          </option>
                        )
                      )}
                  </select>
                  <span className="error-msg">Enter the field</span>
                </div>
                {/* <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="allCheck"
                    id="allCheck"
                    className="checkmark-btn"
                    checked={isMember} onChange={memberCheckedToggle} />
                  <span className="checkmarkspan"></span>
                  Others
                </label> */}

                {isMember && (
                  <div className="form-2-col mt-30">
                    <div className="form-grp ">
                      <label htmlFor="">
                        Member Name <span className="imp-mark">*</span>
                      </label>
                      <select
                        name=""
                        id=""
                        className="customSelect"
                        defaultValue={"Select Member"}
                        onChange={(e) =>
                          setPostData({ ...postData, memberId: e.target.value })
                        }
                      >
                        <option disabled>Select Member</option>
                        {allMembers?.length > 0 &&
                          allMembers?.map((res, i) => (
                            <option value={res.id} key={i}>
                              {res.memberName}
                            </option>
                          ))}
                      </select>
                      <span className="error-msg">Enter the field</span>
                    </div>
                    <img
                      src="../assets/icons/plus-red.svg"
                      alt=""
                      className="pointer"
                      onClick={() => {
                        handleAddVendorPopup("member");
                      }}
                    />
                  </div>
                )}

                <div className="line mt-15"></div>

                <div className="form-grp mt-20">
                  <label htmlFor="" className="create-label">
                    {postData.invoiceType == "Partial" ? "Partial" : "Total"}{" "}
                    Payment <span className="imp-mark">*</span>
                  </label>
                  <input
                    type="number"
                    placeholder={
                      postData.invoiceType == "Partial"
                        ? "Enter Partial Payment"
                        : "Enter Total Payment"
                    }
                    className="create-input"
                    onInput={(e) =>
                      setPostData({ ...postData, amount: e.target.value })
                    }
                  />
                  <span className="error-msg">Enter the field</span>
                </div>
                <div className="form-grp mt-10">
                  <label htmlFor="">
                    Invoice Type<span className="imp-mark">*</span>
                  </label>
                  <select
                    name=""
                    id=""
                    className="customSelect"
                    defaultValue={Invoice[0]}
                    onInput={(e) =>
                      setPostData((postData) => ({
                        ...postData,
                        invoiceType: e.target.value,
                      }))
                    }
                  >
                    {/* <option value="" disabled>Select purpose of expense</option> */}
                    {Invoice &&
                      Invoice?.map((item, index) =>
                        index !== 0 ? (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ) : (
                          <option value={item} key={index} disabled>
                            {item}
                          </option>
                        )
                      )}
                  </select>
                  <span className="error-msg">Enter the field</span>
                </div>
                <div className="form-2-col mt-10">
                  <div className="form-grp">
                    <label htmlFor="" className="create-label">
                      Expense Date <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="date"
                      className="create-input"
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          expenseDate: e.target.value,
                        })
                      }
                    />
                    <span className="error-msg">Enter the field</span>
                  </div>
                  <div className="form-grp mb-0">
                    <label htmlFor="" className="create-label">
                      Due Date <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="date"
                      className="create-input"
                      onChange={(e) =>
                        setPostData({ ...postData, dueDate: e.target.value })
                      }
                    />
                    <span className="error-msg">Enter the field</span>
                  </div>
                </div>
                <div className="form-grp mt-15">
                  <label htmlFor="" className="create-label">
                    Key deliverables <span className="imp-mark">*</span>
                  </label>
                  {/* <div className="keyContainerOuter">
                  <div className="keyItem">
                    Flight tickets
                    <img src="../assets/icons/whiteCross-1.svg" alt="" />
                  </div>
                  <div className="keyItem">
                    Food Bills
                    <img src="../assets/icons/whiteCross-1.svg" alt="" />
                  </div>
                </div> */}
                  {/* <TagsInput value={tags} onChange={(tags)=>setTags(tags)} /> */}
                  <TagInput
                    selected={tags}
                    setSelected={setTags}
                    placeholder="Enter Key Deliverables"
                  />

                  <span className="error-msg">Enter the field</span>
                </div>

                <div className="form-grp mt-15">
                  <label htmlFor="" className="create-lebel">
                    Proof of Key deliverables<span className="imp-mark">*</span>
                  </label>
                  <div className="upload-btn-wrapper">
                    <div className="upload-inner">
                      <input
                        className="uploadbox"
                        id="uploadbox"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.jpeg,.pdf,.png"
                        ref={keyDeliverableRef}
                        onChange={(e) => onClickUpload(e, "key")}
                      />
                      <p className="file-name">{selectedKey?.name}</p>
                      <button
                        className="upload-btn"
                        onClick={() => handleClick(keyDeliverableRef)}
                        type="button"
                      >
                        Choose a File
                      </button>
                      <p className="file-name"></p>
                    </div>
                  </div>
                  {keyDeliverables && keyDeliverables?.length > 0 && (
                    <div className="attachFilesTableOuter mt-15">
                      <table
                        className="attachFilesTable"
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <thead>
                          <tr>
                            <th>File Name</th>
                            <th>Size</th>
                            <th>Date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {keyDeliverables?.map((val, index) => (
                            <tr key={index}>
                              <td>{val.name}</td>
                              <td>{val.size}</td>
                              <td>
                                {" "}
                                {moment(new Date()).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                <button
                                  className="danger-btn-outline"
                                  type="button"
                                  onClick={() => onClickRemove("key", val.name)}
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className="form-grp mt-20">
                  <label htmlFor="" className="create-lebel">
                    Attach Agreement copy
                  </label>
                  <div className="upload-btn-wrapper">
                    <div className="upload-inner">
                      {!isFilePicked && (
                        <input
                          className="uploadbox"
                          id="uploadbox"
                          type="file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.jpeg,.pdf,.png"
                          ref={agreementRef}
                          onChange={uploadAggrementCopy}
                        />
                      )}

                      <button
                        className={"upload-btn"}
                        onClick={() => handleClick(agreementRef)}
                        type="button"
                      >
                        Choose a File
                      </button>
                      <p className="file-name">{selectedKey}</p>
                    </div>
                    {isFilePicked && (
                      <img
                        src="../../assets/icons/delete.svg"
                        alt=""
                        onClick={handleDelete}
                      />
                    )}
                  </div>
                </div>
                <div className="form-grp mt-20">
                  <label htmlFor="" className="create-lebel">
                    Attach Invoice<span className="imp-mark">*</span>
                  </label>
                  <div className="attachFilesOuter">
                    <div className="upload-btn-wrapper">
                      <div className="upload-inner">
                        <input
                          className="uploadbox"
                          id="uploadbox"
                          type="file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.jpeg,.pdf,.png"
                          ref={invoiceRef}
                          onChange={(e) => onClickUpload(e, "invoice")}
                        />

                        <button
                          className="upload-btn"
                          onClick={() => handleClick(invoiceRef)}
                          type="button"
                        >
                          Choose a File
                        </button>
                      </div>
                    </div>
                    {invoice && invoice?.length > 0 && (
                      <div className="attachFilesTableOuter mt-10">
                        <table
                          className="attachFilesTable"
                          cellPadding={0}
                          cellSpacing={0}
                        >
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Size</th>
                              <th>Date</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoice?.map((val, index) => (
                              <tr key={index}>
                                <td>{val.name}</td>
                                <td>{val.size}</td>
                                <td>
                                  {" "}
                                  {moment(new Date()).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  <button
                                    className="danger-btn-outline"
                                    type="button"
                                    onClick={() =>
                                      onClickRemove("invoice", val.name)
                                    }
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
                <div className="line mt-20"></div>

                <div className="form-grp mt-20">
                  <label htmlFor="" className="create-label">
                    Approving Authority <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10">
                    <label className="create-label">
                      <input
                        className="radio-btn"
                        type="radio"
                        name="authority"
                        value={"3b19fa50-b1ff-4216-93bf-c786f8d8cd95"}
                        onClick={(e) =>
                          setPostData({
                            ...postData,
                            approvingAuthority: e.target.value,
                          })
                        }
                      />
                      <span className="radiomark"></span>Gurji Kochar
                    </label>
                    <label className="create-label">
                      <input
                        className="radio-btn"
                        type="radio"
                        name="authority"
                        value={"7d0de7d3-8604-4b34-8693-e08434c26c4c"}
                        onClick={(e) =>
                          setPostData({
                            ...postData,
                            approvingAuthority: e.target.value,
                          })
                        }
                      />
                      <span className="radiomark"></span>Chetan Verma
                    </label>
                    <label className="create-label">
                      <input
                        className="radio-btn"
                        type="radio"
                        name="authority"
                        value={"6bbb372a-8af9-41e6-963a-65c48d20275a"}
                        onClick={(e) =>
                          setPostData({
                            ...postData,
                            approvingAuthority: e.target.value,
                          })
                        }
                      />
                      <span className="radiomark"></span>Mahak Garg
                    </label>
                    <label className="create-label">
                      <input
                        className="radio-btn"
                        type="radio"
                        name="authority"
                        value={"480f6558-7f9b-4c2e-8cb9-89e1326f3171"}
                        onClick={(e) =>
                          setPostData({
                            ...postData,
                            approvingAuthority: e.target.value,
                          })
                        }
                      />
                      <span className="radiomark"></span>Rajendra Kumar
                    </label>
                  </div>
                </div>
              </form>
              <div className="containerFooter">
                <div className="btnGrp">
                  <button className="grey-btn" onClick={() => navigate(-1)}>
                    Back
                  </button>
                  <button className="primary-btn" onClick={handleSave}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default RaiseRequest;
