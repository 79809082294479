import React, { useEffect, useState } from "react";
import SideMenu from "../../components/Layout/menu/SideMenu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDailyTransactionUpdates,
  feeData,
  getOrganizationPrograms,
  getSingleBatchByProgramId,
} from "../../reducers/feeSlice";
import PageLoader from "../../components/UI/loader/PageLoader";
import moment from "moment";

const AllTransaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, transactionData, programList, singleProgramBatch } =
    useSelector(feeData);
  const [isFilterMenuActive, setisFilterMenuActive] = useState(false);
  const [getAllTransaction, setGetAllTransaction] = useState([]);
  const [program, setProgram] = useState("");
  const [batch, setBatch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [getBatch, setGetBatch] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (singleProgramBatch) {
      setGetBatch(singleProgramBatch);
    } else {
      setGetBatch([]);
    }
  }, [singleProgramBatch]);

  useEffect(() => {
    let obj = {
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
      programMasterId: "allPrograms",
      programId: "allCohorts",
    };
    dispatch(getDailyTransactionUpdates(obj));
    dispatch(getOrganizationPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGetAllTransaction(transactionData);
  }, [transactionData]);

  const applyFilter = () => {
    let obj = {
      searchTitle: search,
      fromDate: fromDate ? fromDate : moment(new Date()).format("YYYY-MM-DD"),
      toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
      programMasterId: program ? program : "allPrograms",
      programId: batch ? batch : "allCohorts",
    };
    dispatch(getDailyTransactionUpdates(obj));
    setisFilterMenuActive(false);
  };

  const clearFilter = () => {
    setFromDate("");
    setToDate("");
    setBatch("");
    setProgram("");
    setGetBatch([]);
    setSearch("");
    let obj = {
      searchTitle: search,
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
      programMasterId: "allPrograms",
      programId: "allCohorts",
    };
    dispatch(getDailyTransactionUpdates(obj));
    setisFilterMenuActive(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (search !== "") {
        let obj = {
          searchTitle: search,
          fromDate: fromDate
            ? fromDate
            : moment(new Date()).format("YYYY-MM-DD"),
          toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
          programMasterId: program ? program : "allPrograms",
          programId: batch ? batch : "allCohorts",
        };
        dispatch(getDailyTransactionUpdates(obj));
      }
    }
  };

  const ClearInput = () => {
    setSearch("");
    // setClearSearch(!clearSearch)
    let obj = {
      searchTitle: search,
      fromDate: fromDate ? fromDate : moment(new Date()).format("YYYY-MM-DD"),
      toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
      programMasterId: program ? program : "allPrograms",
      programId: batch ? batch : "allCohorts",
    };
    dispatch(getDailyTransactionUpdates(obj));
  };

  const programHandleChange = (e) => {
    let data = e.target.value;
    setProgram(data);
    dispatch(getSingleBatchByProgramId(data));
  };
  const batchHandleChange = (e) => {
    let data = e.target.value;
    setBatch(data);
  };

  return (
    <>
      <SideMenu />
      {isFetching && <PageLoader />}
      <div className="main-body">
        {/* FILTER - MENU - STARTS */}
        <div
          className={`filterMenuOuter ${isFilterMenuActive ? "active" : ""}`}
        >
          <div className="filterHeader">
            <div className="heading">Filters</div>
            <img
              src="../assets/icons/close.svg"
              alt=""
              className="pointer"
              onClick={() => setisFilterMenuActive(false)}
            />
          </div>
          <div className="filterBody">
            <div className="selectOuter">
              <select value={program} onChange={programHandleChange}>
                {/* <option selected disabled>Select Program</option> */}
                <option>Select Program</option>
                <option value={"allPrograms"}>All</option>
                {programList?.length > 0 &&
                  programList?.map((res, i) => (
                    <option value={res.id} key={i}>
                      {res.programName}
                    </option>
                  ))}
              </select>
            </div>
            {program === "allPrograms" ? (
              ""
            ) : (
              <div className="selectOuter mt-10">
                <select value={batch} onChange={batchHandleChange}>
                  <option>Select Cohorts</option>
                  <option value={"allCohorts"}>All</option>
                  {getBatch?.length > 0 &&
                    getBatch?.map((res, i) => (
                      <option value={res.id} key={i}>
                        {res.name}
                      </option>
                    ))}
                </select>
              </div>
            )}
            <p className="text-3 mt-10">From Date</p>
            <input
              type="date"
              className="new-filter-input mt-5"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <p className="text-3 mt-10">To Date</p>
            <input
              type="date"
              className="new-filter-input mt-5"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="filterFooter">
            <div className="btnGrp">
              <button className="dark-btn-outline-a" onClick={clearFilter}>
                Clear
              </button>
              <button className="primary-btn-a" onClick={applyFilter}>
                Apply
              </button>
            </div>
          </div>
        </div>
        {/* FILTER- MENU- ENDS */}
        <div className="page-header">
          <button onClick={() => navigate(-1)} className="component-back">
            <img src="../assets/icons/back-btn.svg" alt="" />
            Back
          </button>
          <p className="component-heading">All Transaction</p> <p></p>
        </div>
        <div className="filter-bar">
          {/* <div className="searchBox">
            {/* <img src="../assets/icons/search-sm.svg" alt="" /> */}

            {/* <input
              type="text"
              className="searchInput"
              placeholder="Search ..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={handleKeyPress}
            />
             {search.length === 0 ? (
              <img src="../assets/icons/search-sm.svg" alt="" />
            ) : (
              <img
                src="../assets/icons/close.svg"
                onClick={ClearInput}
                alt=""
                className="pointer"
              />
            )} 
          </div> */}
          <div></div>

          <div className="filters">
            <button
              className="filterBtn"
              onClick={() => setisFilterMenuActive(true)}
            >
              {" "}
              <img src="../assets/icons/filter.svg" alt="" /> Filters
            </button>
          </div>
        </div>
        <div className="feedback-container">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Program Name</th>
                <th>Student Name</th>
                <th>Email</th>
                {/* <th>Transaction Id</th> */}
                {/* <th>Transaction Type</th> */}
                <th>Transaction Number</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Subvention Amount</th>
                <th>Loan Partner</th>
                <th>Payment Mode</th>

                {/* <th>Payment Status</th> */}
              </tr>
            </thead>
            <tbody>
              {getAllTransaction?.length > 0 &&
                getAllTransaction.map((res) => (
                  <tr>
                    <td>
                      <div className="programDetail">
                        <div className="programDetailText">
                          <p className="primary-text">
                            {
                              res.FeeDue?.Student?.ProgramDetail?.Program
                                ?.programName
                            }{" "}
                          </p>
                          <p>{res.FeeDue?.Student?.ProgramDetail?.batch}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      {res.FeeDue?.Student?.firstName}{" "}
                      {res.FeeDue?.Student?.lastName}
                    </td>
                    <td>{res.FeeDue?.Student?.officialEmail}</td>
                    {/* <td>{res.id}</td> */}
                    {/* <td>
                      {res.FeeDue?.isOneTimePayment && "OTP"}
                      {res.FeeDue?.isPartialLoan && "Partial Loan"}
                      {res.FeeDue?.isPartialOtp && "Partial OTP"}
                      {res.FeeDue?.isFullLoan && "Full Loan"}
                      {res.FeeDue?.isPartialLoan && "Partial Loan"}
                      {res.FeeDue?.isFlex && "Bank Loan"}
                      {res.FeeDue?.isAdmissionsFee && "Admissions Fee"}
                    </td> */}
                    <td>{res.transactionNumber}</td>
                    {/* <td>{res.amount}</td> */}
                    <td>{moment(res.paidOn).format("DD/MM/YYYY")}</td>
                    <td>
                      <p className="text-success">
                        ₹{" "}
                        {new Intl.NumberFormat("en-IN", {
                          currency: "INR",
                        }).format(res.amount)}
                      </p>
                    </td>
                    <td>
                      {" "}
                      <p className="danger-text">
                        ₹ {res.subventionAmount}
                      </p>{" "}
                    </td>
                    <td>
                      {res.FeeDue?.bankName ? res.FeeDue?.bankName : "---"}
                    </td>
                    <td>
                      <p className="activeCourse">
                        {res.FeeDue?.cashFreeStatus === "paid" &&
                          "Cash Free/UPI"}
                        {res.FeeDue?.isBankLoan && "Bank Loan"}
                        {res.FeeDue?.isBankTransfer && "NEFT/RTGS"}
                      </p>
                    </td>

                    {/* <td>{res.FeeDue?.isPaid ? "Paid" : "Pending"}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AllTransaction;
