import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
// import { useSelector } from "react-redux";
import Header from "./components/Layout/header/header";
import Login from "./pages/login/LoginPage";
import Forget from "./pages/forgetPassword/ForgetPage";
import Reset from "./pages/resetPassword/ResetPage";
import FeedbackList from "./pages/feedback/feedbackList/FeedbackList";
import CreateFeedback from "./pages/feedback/create/CreateFeedback";
import FeedbackPreview from "./pages/feedback/feedbackPreview/FeedbackPreview";
import { PrivateRoute } from "./helpers/PrivateRoute";
import ClubList from "./pages/clubs/clubList/ClubList";
import FeeList from "./pages/feeDetails/feeList/feeList";
import ClubPreview from "./pages/clubs/ClubPreview/ClubPreview";
import CreateFee from "./pages/feeDetails/createFee/createFee";
import CreateClub from "./pages/clubs/CreateClub/CreateClub";
import ViewClub from "./pages/clubs/ViewClub/ViewClub";
import BlankScreen from "./components/BlankComponent/BlankScreen";
import FeedbackAnswers from "./pages/feedback/feedbackAnswers/FeedbackAnswers";
import FeePreview from "./pages/feeDetails/feePreview/feePreview";
import StudentFeeList from "./pages/feeDetails/studentFeeList/studentFeeList";
import SingleStudentFee from "./pages/feeDetails/singleStudentFee/singleStudentFee";
import CreateInterview from "./pages/interview/createInterview/createInterview";
import ApplicantProgramList from "./pages/interview/interviewList/ApplicantProgramList";
import ApplicantList from "./pages/interview/approvalList/applicantList";
import ShortList from "./pages/interview/shortList/ShortList";
import ApprovalList from "./pages/interview/approvalList/approvalList";
import MeetingList from "./pages/Meeting/MeetingList/MeetingList";
// import StudentList from "./pages/studentList/StudentList";
import CourseList from "./pages/course-roaster/CourseList/CourseList";
import ProgramList from "./pages/course-roaster/ProgramList/ProgramList";
import AddProgram from "./pages/course-roaster/AddProgram/AddProgram";
import AddCourse from "./pages/course-roaster/AddCourse/AddCourse";
import GradeList from "./pages/course-roaster/Gades/GradeList/GradeList";
import BiddingList from "./pages/course-roaster/BiddingList/BiddingList";
import ExpenceDashboard from "./pages/expenceManagement/expenceDashboard/ExpenceDashboard";
import FinanceRequest from "./pages/expenceManagement/financeRequest/FinanceRequest";
import RaiseRequest from "./pages/expenceManagement/raiseRequest/RaiseRequest";

import AdminMeetingDetails from "./pages/Meeting/MeetingList/AdminMeetingDetails";
import CourseFeeStudentList from "./pages/feeDetails/studentCourseFeeList/studentCourseFeeList";
import GenericMailSend from "./pages/genericMailSend/GenericMailSend";
import FeeReciept from "./components/Recipt/FeeReciept";
import GenericMailTemplateList from "./pages/genericMailSend/GerericMailTemplateList";
import Calculator from "./pages/calculator/Calculator";
import AddSesion from "./pages/course-roaster/AddSession/AddSesion";
import CourseDetails from "./pages/course-roaster/CourseDetails/CourseDetails";
import AddCourse2 from "./pages/course-roaster/AddCourse2/AddCourse2";
import AddSesion2 from "./pages/course-roaster/AddSession2/AddSession2";
import Dashboard from "./pages/dashboard/Dashboard";
import StudentFeeDueList from "./pages/feeDetails/studentFeeDueList/studentFeeDueList";
import NewMeeting from "./pages/newMeeting/NewMeeting";
import BulkMailer from "./pages/bulkMailer/BulkMailer";
import ApplicantRequest from "./pages/interview/shortList/ApplicantRequest";
import OfferLetterApproval from "./pages/interview/shortList/OfferLetterApproval";
import OfferLetterList from "./pages/interview/shortList/OfferLetterList";
import WelcomeLetterList from "./pages/interview/shortList/WelcomeLetterList";
import SiteUnderMaintenance from "./components/UnderMaintenance/SiteUnderMaintenance";
import SomethingWentWrong from "./pages/somethingWentWrong/SomethingWentWrong";
import AllTransaction from "./pages/transaction/AllTransaction";
import TransactionList from "./pages/transaction/TransactionList";
import TransactionHistory from "./pages/transactionHistory/TransactionHistory";
import NewAdminMeetingDetails from "./pages/Meeting/MeetingList/NewAdminMeetingDetails";
import FeeSummary from "./pages/dashboard/FeeSummary";
import VerifyEmails from "./pages/verifyEmails/VerifyEmails";
import StudentLoanRequest from "./pages/feeDetails/loanRequest/StudentLoanRequest";
import InterviewFeedback from "./pages/InterviewFeedback/InterviewFeedback";
import DueFee from "./pages/dueFee/DueFee";
import FlexList from "./pages/feeDetails/FlexList/FlexList";
// import InterviewScheduler from "./pages/settings/interviewScheduler/InterviewScheduler";
// import CreateInterviewSlots from "./pages/settings/interviewScheduler/CreateInterviewSlots";
// import InterviewSettingPage from "./pages/settings/interviewSettings/InterviewSettingPage";
import FindApplicant from "./pages/findApplicant/FindApplicant";
import Settings from "./pages/settings/Settings";
import MentorFeedback from "./pages/mentorFeedback/MentorFeedback";
import OrientationMailLetter from "./pages/interview/shortList/OrientationMailLetter";
import VendorList from "./pages/interview/approvalList/vendorList";
import LogisticList from "./pages/interview/approvalList/logisticList";
// import CohortStats from "./pages/CohortStats/CohortStats";
import { environment } from "./environments/environment";
import CounsellorPayrollDetail from "./pages/interview/approvalList/CounsellorPayrollDetail";
import MentorApplicantList from "./pages/interview/mentor/MentorApplicantList";

// require("dotenv").config();

function App() {
  const pathname = window.location.pathname;
  const location = useLocation();
  let position = "";
  useEffect(() => {
    position = pathname.search("calculator");
  }, [pathname]);

  // if (environment.baseURL !== "http://localhost:2001") {
  // console.log = function () {};
  // }

  return (
    <React.Fragment>
      {/* Main Header Component   */}

      {pathname !== "/calculator" && <Header />}
      <div className="mainSection scrollbar">
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forget" element={<Forget />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact element={<Calculator />} path="/calculator" />
          <Route exact element={<MeetingList />} path="/meetingList" />
          <Route exact element={<NewMeeting />} path="/newMeetingList" />
          <Route exact element={<FindApplicant />} path="/findApplicant" />
          <Route
            exact
            element={<TransactionHistory />}
            path="/transactionHistory"
          />
          <Route
            exact
            element={<SiteUnderMaintenance />}
            path="/siteMaintenance"
          />
          <Route
            exact
            element={<SomethingWentWrong />}
            path="/somethingWentWrong"
          />

          <Route exact path="/" element={<PrivateRoute />}>
            {/* <Route exact path="/cohortStats" element={<CohortStats />} /> */}

            {/* {localStorage.getItem("isMentor") === "true" ? (
              <Route exact path="/" element={<MentorApplicantList />} />
            ) : ( */}
            {/* <React.Fragment> */}
            <Route exact element={<ApplicantProgramList />} path="/" />
            <Route exact element={<FeedbackList />} path="/feedbackList" />
            <Route
              exact
              element={<ApplicantRequest />}
              path="/applicantRequest/:proId"
            />

            <Route
              exact
              element={<OfferLetterApproval />}
              path="/offerLetterApproval/:proId"
            />

            <Route
              exact
              element={<OfferLetterList />}
              path="/offerLetterList/:proId"
            />

            <Route
              exact
              element={<WelcomeLetterList />}
              path="/welcomeLetterList/:proId"
            />

            <Route
              exact
              element={<OrientationMailLetter />}
              path="/orientationLetter/:proId"
            />

            <Route
              exact
              element={<CreateFeedback />}
              path="/createFeedback/:id"
            />
            <Route
              exact
              element={<FeedbackPreview />}
              path="/feedbackPreview"
            />
            <Route exact element={<ClubList />} path="/clubList" />
            <Route exact element={<ClubPreview />} path="/clubPreview" />

            <Route
              exact
              element={<CounsellorPayrollDetail />}
              path="/counsellorDetail"
            />

            <Route
              exact
              element={<SingleStudentFee />}
              path="/particularStudentfee"
            />
            <Route exact path="/feedbackAnswer" element={<FeedbackAnswers />} />
            <Route exact element={<FeeList />} path="/feeList" />
            <Route exact element={<CreateFee />} path="/CreateFee" />
            <Route exact element={<CreateClub />} path="/CreateClub" />
            <Route exact element={<ViewClub />} path="/ViewClub" />
            <Route exact element={<BlankScreen />} path="/BlankScreen" />
            <Route
              exact
              element={<SingleStudentFee />}
              path="/particularStudentfee"
            />

            <Route
              exact
              element={<SingleStudentFee />}
              path="/particularStudentfee"
            />
            <Route exact path="/feedbackAnswer" element={<FeedbackAnswers />} />

            <Route
              exact
              element={<ApplicantProgramList />}
              path="/applicantProgList"
            />
            <Route
              exact
              element={<AdminMeetingDetails />}
              path="/meetingDetails"
            />
            <Route
              exact
              element={<ApplicantList />}
              path="/applicantList/:proId"
            />
            <Route
              exact
              element={<CreateInterview />}
              path="/createInterview"
            />
            <Route exact element={<ShortList />} path="/shortList/:proId" />
            {/* <Route
              exact
              element={<StudentList />}
              path="/studentList/:programId"
            /> */}
            <Route exact element={<FeePreview />} path="/feePreview" />
            {/* <Route exact element={<StudentFeeList />} path="/studentFeeList" /> */}
            <Route
              exact
              element={<CourseFeeStudentList />}
              path="/couseFeeStudentList"
            />
            <Route exact path="/dashboard" element={<Dashboard />} />

            <Route
              exact
              path="/studentFeeDues"
              element={<StudentFeeDueList />}
            />
            <Route exact path="/allTransaction" element={<AllTransaction />} />
            <Route
              exact
              path="/transactionlist"
              element={<TransactionList />}
            />
            <Route exact path="/dueFeelist" element={<DueFee />} />

            <Route
              exact
              path="/adminMeetingDetails"
              element={<NewAdminMeetingDetails />}
            />

            {/* EXPENCE-MANAGEMENT */}
            <Route
              exact
              element={<ExpenceDashboard />}
              path="/expenceDashboard"
            />
            <Route exact element={<FinanceRequest />} path="/financeRequest" />
            <Route exact element={<RaiseRequest />} path="/raiseRequest" />
            <Route exact element={<StudentLoanRequest />} path="/loanRequest" />
            <Route exact element={<FlexList />} path="/flexList" />
            {/* <Route
              exact
              path="/interviewScheduler"
              element={<InterviewScheduler />}
            /> */}
            {/* <Route
              exact
              path="/createInterviewSlot"
              element={<CreateInterviewSlots />}
            /> */}
            {/* </React.Fragment> */}
            {/* )} */}
          </Route>
          <Route
            exact
            element={<InterviewFeedback />}
            path="/interviewFeedback/:applicantId"
          />

          <Route exact element={<ApprovalList />} path="/applicantApproval" />
          <Route exact element={<VerifyEmails />} path="/verifyEmails" />

          {/* COURSE-ROASTER */}
          <Route exact element={<CourseList />} path="/courseList" />
          <Route exact element={<ProgramList />} path="/programList" />
          <Route exact element={<AddProgram />} path="/addProgram" />
          <Route exact element={<AddCourse />} path="/addCourse" />
          <Route exact element={<AddCourse2 />} path="/addCourse2" />
          <Route exact element={<GradeList />} path="/gradeLsit" />
          <Route exact element={<BiddingList />} path="/biddingList" />
          <Route exact element={<CourseDetails />} path="/courseDetails" />
          <Route exact element={<AddSesion />} path="/addSession" />
          <Route exact element={<AddSesion2 />} path="/addSession2" />
          {/* COURSE-ROASTER */}

          <Route exact element={<GenericMailSend />} path="/mailer" />
          <Route exact element={<FeeReciept />} path="/reciept" />
          <Route
            element={<GenericMailTemplateList />}
            path="/genericMailTemplateList"
          />
          <Route element={<BulkMailer />} path="/bulkMail" />
          <Route element={<FeeSummary />} path="/feeSummary" />
          <Route element={<Settings />} path="/settings/*" />
          {/* <Route element={<InterviewSettingPage />} path="/interviewSettings" /> */}
          <Route element={<MentorFeedback />} path="/mentorFeedback" />

          {/* <Route element={<TimeLine />} path="/studentTimeline" /> */}
          <Route exact element={<VendorList />} path="/vendorApproval" />
          <Route
            exact
            element={<LogisticList />}
            path="/logisticsPartnerApproval"
          />
        </Routes>
      </div>
      {/* <Footer /> */}
    </React.Fragment>

    // <Router>
    //   <Header />
    //   <div className="mainSection">
    //     <Routes>
    //       <Route exact path="/" element={Login} />
    //       <Route path="/login" element={Login} />

    //       <Route path="/dashboard" element={HomePage} />
    //     </Routes>
    //   </div>
    // </Router>
  );
}
export default App;
