import React, { useEffect, useState } from "react";
import SideMenu from "../../components/Layout/menu/SideMenu";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactionHistory,
  tranactionHistoryData,
} from "../../reducers/transactionHistorySlice";
import PageLoader from "../../components/UI/loader/PageLoader";

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? "";
  const currDate = searchParams.get("date") ?? "";
  // const params = useParams();
  const { isFetching, getAllTransaction } = useSelector(tranactionHistoryData);
  // const [isFilterMenuActive, setisFilterMenuActive] = useState(false);
  const [allTransaction, setAllTransaction] = useState([]);
  // const [date,setDate]=useState(moment(new Date()).format("YYYY-MM-DD"))
  const [date, setDate] = useState(currDate);
  console.log(date, "date");

  useEffect(() => {
    let obj = {
      proId: id,
      date: date,
    };
    dispatch(getTransactionHistory(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (getAllTransaction?.Data?.length > 0) {
      setAllTransaction(getAllTransaction?.Data);
    } else {
      setAllTransaction([]);
    }
  }, [getAllTransaction]);

  // const formatAmount = () => {
  //   var number = 1045;

  //   var formattedAmount = new Intl.NumberFormat("en-IN", {
  //     // style: "currency",
  //     currency: "INR",
  //   }).format(number);

  //   // console.log(
  //   //   new Intl.NumberFormat("en-IN", {
  //   //     style: "currency",
  //   //     currency: "INR",
  //   //   }).format(number)
  //   // );
  //   console.log(formattedAmount, "formattedAmount");

  //   return formattedAmount;

  //   // console.log("sddsdsdsdsd", number2);
  // };

  return (
    <>
      <SideMenu meeting={true} title="Transaction History" />
      {isFetching && <PageLoader />}
      <div className="main-body">
        {/* FILTER - MENU - STARTS */}
        {/* <div className={`filterMenuOuter ${isFilterMenuActive?'active':''}`} >
        <div className="filterHeader">
        <div className="heading">Filters</div>
        <img src="../assets/icons/close.svg" alt="" className="pointer" onClick={()=>setisFilterMenuActive(false)} />
        </div>
        <div className="filterBody">
          <div className="selectOuter">
            <select name="" id="">
              <option value="null" selected disabled>Select Name</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
        </div>
        <div className="filterFooter">
          <div className="btnGrp">
            <button className="dark-btn-outline-a" onClick={()=>setisFilterMenuActive(false)}>Clear</button>
            <button className="primary-btn-a" onClick={()=>setisFilterMenuActive(false)}>Apply</button>
          </div>
        </div>
      </div> */}
        {/* FILTER- MENU- ENDS */}
        <div className="page-header">
          <p className="component-heading">Transaction History</p>{" "}
        </div>
        <div className="filter-bar">
          <div id="type" className="faculty-div text-success">
            Total Amount: ₹{" "}
            {new Intl.NumberFormat("en-IN", {
              currency: "INR",
            }).format(getAllTransaction?.totalAmount)}
          </div>
          <div className="filters">
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="create-input"
            />
          </div>
        </div>
        <div className="feedback-container">
          <table className="feedback-table" cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Serial Number</th>
                <th>Program Name</th>
                <th>Student Name</th>
                <th>Fee Name</th>
                <th>Amount</th>
                <th>Transaction Number</th>
                <th>Receipt Number</th>
              </tr>
            </thead>
            <tbody>
              {allTransaction?.length > 0 &&
                allTransaction.map((res, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{res?.ProgramDetail?.Program?.programName}</td>
                    <td>
                      {(res.Student?.firstName + " " + res.Student?.lastName)
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() + word.substring(1)
                        )
                        .join(" ")}
                    </td>
                    <td>{res.Fee?.FeeMaster?.feeName}</td>
                    {/* <td>{res.amount}</td> */}
                    <td>
                      ₹{" "}
                      {new Intl.NumberFormat("en-IN", {
                        currency: "INR",
                      }).format(res.amount)}
                    </td>
                    <td>{res.transactionNumber}</td>
                    <td>{res.receiptNumber}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
