import React, { useEffect } from 'react'

const ErrorToast = (props) => {
    useEffect(() => {
        setTimeout(() => {
            props.setShow();
        }, 2000);
    }, [props.show])
    return (
        <div className={`danger-toast ${props.show ? "active" : ""}`}>
            <img src="../assets/icons/errorIcon.svg" alt="" />
            <p>{props.message}</p>
        </div>
    )
}

export default ErrorToast