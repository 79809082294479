import React, { useState } from "react";
import "./MeetingPopupStyle.css";
import MeetFeedbackPopup from "../../../components/Popups/MeetFeedbackPopup/MeetFeedbackPopup";
import moment from "moment";
const MeetingPopup = (props) => {
  // const [isMeetFeedbackPopupOpen, setIsMeetFeedbackPopupOpen] = useState(false);

  const toggleMeetFeedbackPopup = () => {
    props.setIsMeetingFeedbackPopupOpen(!props.isMeetingFeedbackPopupOpen);
    props.setMin(0);
    props.setSec(0);
  };

  const handleClosePopup = () => {
    props.handleClose();
    props.setMin(0);
    props.setSec(0);
  };
  // const onMeetingClick = () => {
  //   setIsMeetingFeedbackPopupOpen(true);
  //   props.handleClose()
  // };
  console.log(
    props?.popupMeetingLink,
    "props?.popupMeetingLink"
  );
  return (
    <>
      <MeetFeedbackPopup
        show={props.isMeetingFeedbackPopupOpen}
        handleClose={toggleMeetFeedbackPopup}
        singleMeetingDetail={props.singleMeetingDetail}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container meet-popup">
          <div className="popup-header">
            <div className="popup-heading">
              <p className="text-primary">
                Session time : {props.min < 10 ? "0" + props.min : props.min} :{" "}
                {props.sec < 10 ? "0" + props.sec : props.sec}
                seconds
              </p>
              {/* <img src="../assets/icons/close.svg" alt="" className="close-btn" 
            onClick={handleClosePopup}
            /> */}

              {/* <img src="../assets/icons/plus-black.svg" alt="" /> */}
              <div className="sos-wrapper">
              <button className="dropdown-text pointer danger-btn-outline" onClick={props.toggleMeetTicket}>SOS</button>
              {
                props.beforeMeetStart? "":
                <button className="dropdown-text pointer dark-btn-outline-a" onClick={()=>props.openAgendaPopup(props.singleMeetingDetail)}>+ Agenda</button>
              }
              </div> 
            </div>
          </div>
          <div className="popup-body">
            {
              props.beforeMeetStart ? <p className="text-4 waiting-popup-text">You Cannot Start The Meeting Before <span>{moment(props.singleMeetingDetail.Date).format("MMMM Do YYYY, h:mm:ss a")}</span> </p>
              :

            props?.running ? (
              <div className="icon-bar-wrapper">
                <img src="/assets/img/gmeetlogo.png" />
                  <div className="iconbar-text">
                    <p className="text-4">To join the video meeting, click this link: <span className="pointer blue-link" onClick={()=>window.open(props.singleMeetingDetail?.meetingLink)}>{(props.singleMeetingDetail?.meetingLink)}</span></p>
                  </div>
              </div>
            ) : (
              <p className="text-4">Forwarding to your session</p>
            )}
            {
              (props.singleMeetingDetail?.startTimeByStudent != null && props.singleMeetingDetail?.meetingStatusByStudent === "onGoing") && 
              <>
                <span className="border-up"></span>
                <p className="text-4 mt-20">Please Join the session, Coache will let you in.</p>
              </>
            }
            {/* <p className="text-4">Click here to end your session</p> */}
          </div>
          <div className="popup-footer ">
            {
              props.beforeMeetStart? "":
              props?.running ? (
                <button className="danger-btn" onClick={props.onEndMeetingClick}>
                  End session
                </button>
              ) : (
                <button className="secondary-btn-a">End session</button>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingPopup;
