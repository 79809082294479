import React, { useRef, useState, useEffect, Component } from "react";
import Paragraph from "../../../components/AnswersComponents/Paragraph/Paragraph";
import RatingBox from "../../../components/AnswersComponents/Rating/RatingBox";
import SingleSelect from "../../../components/AnswersComponents/SingleSelect/SingleSelect";
import UploadBox from "../../../components/AnswersComponents/UploadBox/UploadBox";
import MultiSelect from "../../../components/AnswersComponents/MultiSelect/MultiSelect";
import "./FeedbackPreviewStyle.css";
import {
  COMMON_TEXT,
  EMAIL_TEMPLATE_NAME,
  EMAIL_TYPE,
} from "../../../constants/textConstant";
import RenderSlider from "./SliderComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  feedbackData,
  getEmailTemplate,
  getFeedbackDetailById,
  setInitialState,
} from "../../../reducers/feedbackSlice";
import PageLoader from "../../../components/UI/loader/PageLoader";
import MailPopup from "../../../components/Popups/MailPopup/MailPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import DeletePopup from "../../../components/Popups/DeletePopup/DeletePopup";
import AlertPopup from "../../../components/Popups/AlertPopup/AlertPopup";

const FeedbackPreview = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const feedbackId = searchParams.get("id");
  const [previewType, setPreviewType] = useState("");
  const [sliderArray, setQuestionArray] = useState([]);
  const [layoutType, setLayoutType] = useState("");
  const [emailTemplateContent, setEmailTemplateData] = useState("");
  const [showAlertPopup, setAlertPopup] = useState(false);

  console.log("grgrgrgrgrgrg", showAlertPopup);

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    feedbackList,
    feedbackResponse,
    studentList,
    feedbackDetail,
    emailTemplateData,
  } = useSelector(feedbackData);

  useEffect(() => {
    try {
      if (
        feedbackId !== null ||
        feedbackId !== undefined ||
        feedbackId !== "null"
      ) {
        dispatch(getFeedbackDetailById(feedbackId));
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [feedbackId]);

  useEffect(() => {
    if (
      feedbackDetail !== null ||
      feedbackDetail !== undefined ||
      feedbackDetail !== "null"
    ) {
      setPreviewType(feedbackDetail && feedbackDetail.previewType);
      setLayoutType(feedbackDetail && feedbackDetail.layoutType);

      var array = [];

      feedbackDetail &&
        feedbackDetail.FeedbackQuestions.forEach((element, index) => {
          var item = {
            id: index,
            questionType: element.questionType,
            questionName: element.questionName,
            isMandatory: element.isMandatory,
            option: element.FeedbackQuestionOptions,
          };

          array.push(item);
        });

      setQuestionArray(array);
    }
  }, [feedbackDetail]);

  console.log("printing value", sliderArray);

  const previousState = () => {
    if (currentIndex === 0) {
      return 0;
    }
    // this.setState({ currentIndex: currentIndex - 1 });
    setCurrentIndex(currentIndex - 1);
  };

  const nextState = () => {
    if (currentIndex !== sliderArray.length) {
      if (currentIndex === sliderArray.length - 1) {
        return 0;
      }
      // this.setState({ currentIndex: currentIndex + 1 });
      setCurrentIndex(currentIndex + 1);
      console.log("inside if ", currentIndex);
    }
    // if(currentIndex === sliderArray.data.length)
  };

  // const indexSlide = (info) => {
  //   const { id } = info;
  //   // this.setState({ currentIndex: id - 1 });
  //   setCurrentIndex(id - 1);
  // };

  const toggleMailPopup = () => {
    setIsMailOpen(!isMailOpen);
  };

  useEffect(() => {
    dispatch(
      getEmailTemplate({
        templateName: EMAIL_TEMPLATE_NAME.emailTemplateForPublish,
      })
    );
  }, []);

  const onClickEditFeedback = () => {
    setAlertPopup(false);
    navigate(`/createFeedback/${feedbackId}`);
  };

  const toggleAlertPopup = () => {
    setAlertPopup(!showAlertPopup);
  };

  const onClickLiveButton = () => {
    if (
      (feedbackDetail && feedbackDetail.FeedbackStudents.length === 0) ||
      sliderArray.length === 0
    ) {
      toggleAlertPopup();
    } else {
      toggleMailPopup();
    }
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     dispatch(setInitialState());
  //   }
  // }, [isSuccess]);

  return (
    <React.Fragment>
      {isFetching && <PageLoader />}

      <SideMenu />

      <AlertPopup
        handleEditFeedback={onClickEditFeedback}
        handleClose={toggleAlertPopup}
        feedbackId={feedbackId}
        show={showAlertPopup}
        message={
          feedbackDetail && feedbackDetail.FeedbackStudents.length === 0
            ? COMMON_TEXT.addStudentsAlert
            : COMMON_TEXT.addQuestionsAlert
        }
      />

      <MailPopup
        emailType={EMAIL_TYPE.publishEmail}
        emailTemplateData={emailTemplateData}
        handleClose={toggleMailPopup}
        show={isMailOpen}
        studentCount={feedbackDetail && feedbackDetail.FeedbackStudents.length}
        feedbackId={feedbackId}
      />

      <div className="main-body">
        <div className="page-header create-header">
          <button onClick={() => navigate(-1)} className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            {COMMON_TEXT.goBack}
          </button>

          {feedbackDetail && feedbackDetail.isPublished === false && (
            <div className="preview-btns">
              <button
                onClick={onClickEditFeedback}
                className="dark-btn-outline feedback-btn"
              >
                <img
                  src="../assets/icons/edit-black.svg"
                  alt=""
                  className="iconblack"
                />{" "}
                Edit
              </button>
              {feedbackDetail && feedbackDetail.status !== "expire" && (
                <button onClick={onClickLiveButton} className="danger-btn">
                  <img src="../assets/icons/live.svg" alt="" /> Live
                </button>
              )}
            </div>
          )}
        </div>

        <div className="preview-screen scrollbar">
          {previewType === COMMON_TEXT.multipage && sliderArray.length > 0 && (
            <div className="preview-style multipage">
              <RenderSlider
                key={sliderArray[currentIndex].id}
                data={sliderArray[currentIndex]}
                layout={layoutType}
                nextState={nextState}
              />

              {/* {renderSlider} */}
              {/* {sliderArray.data.map((item) => {
              const { id, questionType, questionName, answers, title, desc } =
                item;
              return (
                <div className="inside-main" key={id}>
                  <div className="inside-shell">
                    <div
                      className={`inside-mid ${sliderArray.layout.layoutType}`}
                    >
                      <div className="inside-content">
                        <h2 className="heading-2">{questionName || title}</h2>
                        {desc && <p className="text-4">{desc}</p>}
                        {questionType === "Paragraph" && <Paragraph />}
                        {questionType === "Rating" && <RatingBox />}
                        {questionType === "SingleSelect" && <SingleSelect />}
                        {questionType === "MultiSelect" && <MultiSelect />}
                        {questionType === "Upload" && <UploadBox />}
                        {id === 0 && (
                          <button className="danger-btn mt-55 max-w170">
                            Get Started
                          </button>
                        )}
                        {id !== 0 && (
                          <button className="danger-btn mt-55 max-w170">
                            Continue
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} */}
              <div className="inside-footer">
                <div className="nu-buttons">
                  <p>
                    <button
                      className="clear-btn"
                      // onClick={() => customSlider.current.slickPrev()}
                      onClick={previousState}
                    >
                      {/* {() => setCurrent(current + 1)} */}
                      <img
                        src="../assets/icons/prev.svg"
                        alt=""
                        className="mr-15"
                      />
                    </button>

                    <button
                      className="clear-btn"
                      onClick={nextState}
                      //   onClick={() => customSlider.current.slickNext()}
                    >
                      {currentIndex + 1}/{sliderArray.length}
                      <img
                        src="../assets/icons/forw.svg"
                        alt=""
                        className="ml-15"
                      />
                    </button>
                  </p>
                </div>
              </div>
            </div>
          )}

          {previewType === COMMON_TEXT.singlePage && sliderArray.length > 0 && (
            <div className="preview-style singlepage">
              {sliderArray.map((item) => {
                console.log("printing new value", item);
                const ref = React.forwardRef();
                const handleClick = (i) =>
                  ref.current.scrollIntoView({
                    top: -100,
                    behavior: "smooth",
                  });
                const {
                  id,
                  questionType,
                  questionName,
                  answers,
                  title,
                  desc,
                  required,
                } = item;

                console.log("printing item value", item);
                return (
                  <div className="inside-main" key={id} id={id} ref={ref}>
                    <div className="inside-shell">
                      <div className={`inside-mid ${layoutType}`}>
                        <div className="inside-content">
                          <h2 className="heading-2">
                            {questionName || title}{" "}
                            <span className="imp-question">*</span>
                          </h2>
                          {desc && <p className="text-4">{desc}</p>}
                          {questionType === "paragraph" && <Paragraph />}
                          {questionType === "rating" && <RatingBox />}
                          {questionType === "singleSelect" && (
                            <SingleSelect item={item} />
                          )}
                          {questionType === "multiSelect" && (
                            <MultiSelect item={item} />
                          )}
                          {questionType === "uploadMedia" && <UploadBox />}

                          {/* {id === 0 && (
                            <button
                              className="danger-btn mt-55 max-w170"
                              onClick={() => handleClick(ref)}
                            >
                              Continue
                            </button>
                          )}
                          {id !== 0 && (
                            <>
                              <button
                                className="danger-btn mt-55 max-w170"
                                onClick={() => handleClick(ref)}
                              >
                                Continue
                              </button>
                              {required && (
                                <button className="skip-btn">skip</button>
                              )}
                            </>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* {sliderArray.data.map((dataItem) => {
              const { id, questionName, questionType, answers } = dataItem;
              return (
                <div className="inside-main" key={id}>
                  <div className="inside-shell">
                    <div
                      className={`inside-mid ${sliderArray.layout.layoutType}`}
                    >
                      <div className="inside-content">
                        <h2 className="heading-2">{questionName}</h2>
                        <p className="text-4">
                          The PGP TBM is an extremely flexible programme that
                          can be highly customized to <br />
                          meet the needs of each student’s career aspirations.
                        </p>

                        <button className="danger-btn mt-55 max-w170">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} */}

              <div className="feedback-footer">
                <button
                  className="danger-btn max-w170"
                  // onClick={() => handleClick(ref)}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeedbackPreview;
