/* eslint-disable */
import React, { useState } from "react";
import "./SideMenuStyle.css";
import $ from "jquery";
import { headerHandler, headerSelector } from "../../../reducers/headerSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";

const SideMenu = ({ meeting, title }) => {
  const { isHamburgerEnable } = useSelector(headerSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleToggler = (num1, num2, num3) => {
    $(`#toggler-${num1}`).toggleClass("active");
    $(`#toggler-${num2}`).removeClass("active");
    $(`#toggler-${num3}`).removeClass("active");
    console.log("active");
  };

  const handleToggler1 = (num1, num2, num3) => {
    $(`#toggler-${num1}`).toggleClass("active");
    $(`#toggler-${num2}`).removeClass("active");
    $(`#toggler-${num3}`).removeClass("active");
    console.log("active");
  };

  const handleMouseEnter = () => {
    dispatch(headerHandler(true));
    $("#side-menu").addClass("active");
  };
  const handleMouseLeave = () => {
    dispatch(headerHandler(false));
    $(`#toggler-1`).removeClass("active");
    $(`#toggler-2`).removeClass("active");
    $(`#toggler-3`).removeClass("active");
    $(`#sub-menu-1`).removeClass("active");
    $(`#sub-menu-2`).removeClass("active");
    $("#side-menu").removeClass("active");
  };

  return (
    // <div className="side-menu scrollbar" id="side-menu" >
    <div
      className={
        isHamburgerEnable ? "side-menu scrollbar active" : "side-menu scrollbar"
      }
      // onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id="side-menu"
    >
      <ul className="sidenav-list">
        {/* <NavLink
        // className={(navData) =>
        //   navData.isActive ? "sidenav-list-item active" : "sidenav-list-item"
        // }
        // to={"/applicantProgList"}
        >
          <div className="main-menu">
            <div className="img-hover">
              <img src="../assets/icons/home.svg" alt="" />
              <img src="../assets/icons/home-blue.svg" alt="" />
            </div>
            <p>Dashboard</p>
          </div>
        </NavLink> */}

        {/* <li className="sidenav-list-item">
          <div className="main-menu">
            <div className="img-hover">
              <img src="../assets/icons/home.svg" alt="" />
              <img src="../assets/icons/home-blue.svg" alt="" />
            </div>
            <p>Dashboard</p>
          </div>
        </li> */}

        {/* <NavLink
          className={(navData) =>
            navData.isActive ? "sidenav-list-item active" : "sidenav-list-item"
          }
          to={"/dashboard"}
        >
          <div className="main-menu">
            <div className="img-hover">
              <img src="../assets/icons/home.svg" alt="" />
              <img src="../assets/icons/home-blue.svg" alt="" />
            </div>
            <p>Dashboard</p>
          </div>
        </NavLink> */}

        {/* <NavLink
          className={(navData) =>
            navData.isActive ? "sidenav-list-item active" : "sidenav-list-item"
          }
          to={"/applicantProgList"}
        >
          <div className="main-menu">
            <div className="img-hover">
              <img src="../assets/icons/calender-star.svg" alt="" />
              <img src="../assets/icons/calender-star-blue.svg" alt="" />
            </div>
            <p>Revenue</p>
          </div>
        </NavLink> */}

        {meeting ? (
          <div
            className="sidenav-list-item active"
            // to={"/applicantProgList"}
          >
            <div className="main-menu">
              <div className="img-hover">
                <img
                  src="https://cdn.mastersunion.org/assets/imgV2/calender-star.svg"
                  alt=""
                />
                <img
                  src="https://cdn.mastersunion.org/assets/imgV2/calender-star-blue.svg"
                  alt=""
                />
              </div>
              <p>{title ? title : "Session"}</p>
            </div>
          </div>
        ) : (
          <>
            <li
              className="sidenav-list-item menuToggler"
              id="toggler-1"
              onClick={() => handleToggler1(1, 2, 3)}
            >
              <div className="main-menu">
                <div className="img-hover">
                  <img
                    src="https://cdn.mastersunion.org/assets/imgV2/calender-star.svg"
                    alt=""
                  />
                  <img
                    src="https://cdn.mastersunion.org/assets/imgV2/calender-star-blue.svg"
                    alt=""
                  />
                </div>
                <p>
                  Revenue
                  <img
                    src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                    className="sidemenu-togger"
                  />
                </p>
              </div>
              <ul className="sub-menu" id="sub-menu-1">
                <NavLink className="sub-menu-item" to={"/applicantProgList"}>
                  <div className="img-hover">
                    <img
                      src="https://cdn.mastersunion.org/assets/imgV2/buildings.svg"
                      alt=""
                    />
                    <img
                      src="https://cdn.mastersunion.org/assets/imgV2/building-blue.svg"
                      alt=""
                    />
                  </div>
                  <p>Dashboard</p>
                </NavLink>

                <NavLink className="sub-menu-item" to={"/allTransaction"}>
                  <div className="img-hover">
                    <img
                      src="https://cdn.mastersunion.org/assets/imgV2/master.svg"
                      alt=""
                    />
                    <img
                      src="https://cdn.mastersunion.org/assets/imgV2/master-blue.svg"
                      alt=""
                    />
                  </div>
                  <p>All Transactions</p>
                </NavLink>

                <NavLink className="sub-menu-item" to={"/counsellorDetail"}>
                  <div className="img-hover">
                    <img
                      src="https://cdn.mastersunion.org/assets/imgV2/master.svg"
                      alt=""
                    />
                    <img
                      src="https://cdn.mastersunion.org/assets/imgV2/master-blue.svg"
                      alt=""
                    />
                  </div>
                  <p>Counsellor Payroll</p>
                </NavLink>
              </ul>
            </li>

            {/* <NavLink
              className={(navData) =>
                navData.isActive
                  ? "sidenav-list-item active"
                  : "sidenav-list-item"
              }
              to={"/applicantProgList"}
            >
              <div className="main-menu">
                <div className="img-hover">
                  <img
                    src="https://cdn.mastersunion.org/assets/imgV2/calender-star.svg"
                    alt=""
                  />
                  <img
                    src="https://cdn.mastersunion.org/assets/imgV2/calender-star-blue.svg"
                    alt=""
                  />
                </div>
                <p>Revenue</p>
              </div>
            </NavLink> */}

            {/* <NavLink
              className={(navData) =>
                navData.isActive
                  ? "sidenav-list-item active"
                  : "sidenav-list-item"
              }
              to={"/interviewScheduler"}
            >
              <div className="main-menu">
                <div className="img-hover">
                  <img src="../assets/icons/calender-star.svg" alt="" />
                  <img src="../assets/icons/calender-star-blue.svg" alt="" />
                </div>
                <p>Interviews</p>
              </div>
            </NavLink> */}

            {/* <NavLink
          className={(navData) =>
            navData.isActive
              ? "sidenav-list-item active"
              : "sidenav-list-item"
          }
          to={"/expenceDashboard"}
        >
          <div className="main-menu">
            <div className="img-hover">
              <img src="../assets/icons/expenseIcon.svg" alt="" />
              <img src="../assets/icons/expenseIcon-blue.svg" alt="" />
            </div>
            <p>Expense</p>
          </div>
        </NavLink> */}
            {localStorage.getItem("viewOnlyAccess") !== "true" && (
              <li
                className="sidenav-list-item menuToggler"
                id="toggler-2"
                onClick={() => handleToggler(2, 1, 3)}
              >
                <div className="main-menu">
                  <div className="img-hover">
                    <img
                      src="https://cdn.mastersunion.org/assets/imgV2/expenseIcon.svg
"
                      alt=""
                    />
                    <img
                      src="https://cdn.mastersunion.org/assets/imgV2/expenseIcon-blue.svg"
                      alt=""
                    />
                  </div>
                  <p>
                    Expense Management
                    <img
                      src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                      className="sidemenu-togger"
                    />
                  </p>
                </div>
                <ul className="sub-menu" id="sub-menu-1">
                  {(localStorage.getItem("isFinanceMember") == "true" ||
                    localStorage.getItem("canApproveExpense") == "true") && (
                    <NavLink className="sub-menu-item" to={"/expenceDashboard"}>
                      <div className="img-hover">
                        <img
                          src="https://cdn.mastersunion.org/assets/imgV2/buildings.svg"
                          alt=""
                        />
                        <img
                          src="https://cdn.mastersunion.org/assets/imgV2/building-blue.svg"
                          alt=""
                        />
                      </div>
                      <p>Dashboard</p>
                    </NavLink>
                  )}
                  <NavLink className="sub-menu-item" to={"/financeRequest"}>
                    <div className="img-hover">
                      <img
                        src="https://cdn.mastersunion.org/assets/imgV2/master.svg"
                        alt=""
                      />
                      <img
                        src="https://cdn.mastersunion.org/assets/imgV2/master-blue.svg"
                        alt=""
                      />
                    </div>
                    <p>Raise Invoice</p>
                  </NavLink>
                </ul>
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
};
export default SideMenu;
