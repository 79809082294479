import React, { useState, useEffect } from "react";
import FeeDropdown from "../../Dropdowns/FeeDropdown/FeeDropdown";
import {
  feedbackData,
  getEmailTemplate,
} from "../../../reducers/feedbackSlice";
import { useSelector, useDispatch } from "react-redux";
import MailPopup from "../../../components/Popups/MailPopup/MailPopup";
import "../FeeDuePopup/FeeDuePopupStyle.css";
import {
  COMMON_TEXT,
  EMAIL_TEMPLATE_NAME,
  EMAIL_TYPE,
  Acceptance_Letter,
} from "../../../constants/textConstant";
import {
  clearFeeDue,
  feeData,
  getCourseFeeStudentList,
  getStudentFeeList,
  postEditFeeDueAmount,
  postCreateFeeDueAmount,
  postUpdateStudentStatus,
  postUpdatePaymentMethod,
  postUpdateReceiptNumber,
} from "../../../reducers/feeSlice";
import ToastMessage from "../../Toast/ToastComponent";
import {
  applicantData,
  getWelcomeLetterStudentList,
} from "../../../reducers/applicantSlice";
import ErrorToast from "../../Toast/ErrorToast";
const StudentStatusPopup = (props) => {
  const { editFeeDueSuccess, createFeeDueSuccess } = useSelector(feeData);

  const [studentStatus, setStudentStatus] = useState("Active");
  const [supplyStatus, setSupplyStatus] = useState("Haryana");
  const [paymentMode, setPaymentMode] = useState("Pending");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isHaryana, setIsHaryana] = useState(true);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");

  const [loggedInUser, setLoggedInUser] = useState("");

  const [feeAmount, setFeeAmount] = useState("");
  const [feeDate, setFeeDate] = useState("");
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState(false);
  const [validateDate, setDateValidation] = useState(false);

  const [address, setAddress] = useState("");
  const [gstNo, setGSTNo] = useState("");

  const dispatch = useDispatch();

  const handleClose = () => {
    props.closePopup();
    setPaymentMethod("");
  };

  console.log("printing props ", props);

  const updateStudentStatus = () => {
    const obj = {
      studentStatus: studentStatus,
      studentId: props?.studentId,
    };
    console.log(obj, "send email");
    dispatch(postUpdateStudentStatus(obj));
  };

  const updatePaymentMode = () => {
    const obj = {
      paymentMethod: paymentMode,
      studentId: props?.studentId,
    };
    console.log(obj, "send email");
    dispatch(postUpdateStudentStatus(obj));
  };

  const updateStudentDetails = () => {
    const obj = {
      studentId: props?.studentId,
      studentGSTNo: gstNo,
      supplyPlace: supplyStatus,
      address: address,
    };
    console.log(obj, "send email");
    dispatch(postUpdateStudentStatus(obj));
  };

  const updateReceiptNumber = () => {
    const obj = {
      transactionId: props?.feeDueId,
      receiptNumber: receiptNumber,
      invoiceGeneratedOn: invoiceDate,
    };
    console.log(obj, "send email");
    dispatch(postUpdateReceiptNumber(obj));
    setReceiptNumber("");
    setInvoiceDate("");
  };

  const updatePaymentMethod = () => {
    const obj = {
      isOneTimePayment: paymentMethod === "OTP",
      feeDueId: props?.feeDueId,
      isFullLoan: paymentMethod === "Full Loan",
      // isFlex: paymentMethod === "Flex",
      isPartialLoan: paymentMethod === "Partial Loan",
      isPartialOtp: paymentMethod === "Partial OTP",
      isAdmissionsFee: paymentMethod === "Admissions Fee",
      isFlexRecieved: paymentMethod === "Flex Received",
      isFlexToBeRecieved: paymentMethod === "Flex to be Received",
    };

    console.log(obj, "send email");

    dispatch(postUpdatePaymentMethod(obj));
    // handleClose();
  };

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    setLoggedInUser(userName);
  }, []);

  const handleStatusChange = (e) => {
    var status = e.target.value;
    setStudentStatus(status);
  };

  const handlePlaceOfSupply = (e) => {
    var status = e.target.value;
    // setSupplyStatus(status);
    if (status === "Haryana") {
      setSupplyStatus("Haryana");
      setIsHaryana(true);
    } else {
      setSupplyStatus("");
      setIsHaryana(false);
    }
  };

  const handlePaymentModeChange = (e) => {
    var status = e.target.value;
    setPaymentMode(status);
  };

  const handleMethodChange = (event) => {
    setPaymentMethod(event?.target.value);
  };

  useEffect(() => {
    if (Object.keys(props?.studentDetails).length != 0) {
      setAddress(props?.studentDetails?.address ?? "");
      setGSTNo(props?.studentDetails?.studentGSTNo ?? "");

      if (props?.studentDetails?.supplyPlace === "Haryana") {
        setIsHaryana(true);
        setSupplyStatus(props?.studentDetails?.supplyPlace);
      } else {
        setIsHaryana(false);
        setSupplyStatus(props?.studentDetails?.supplyPlace);
      }
    } else {
      console.log("inside our else condition");
    }
  }, [props?.studentDetails]);

  return (
    <>
      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={"Created Successfully"}
      />

      <ErrorToast
        show={showErrorMessage}
        setShow={() => setErrorMessage(false)}
        message={"Please fill all fields"}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container due-popup studentStatusPopup">
          <div className="popup-heading">
            {props?.statusType === "studentStatus" && "Student Status"}
            {props?.statusType === "paymentMethod" && "Payment Method"}
            {props?.statusType === "paymentMode" && "Payment Method"}
            {props?.statusType === "updateAddress" && "Update Details"}
            {props?.statusType === "updateReceiptNo" && "Update Receipt Number"}

            <img
              src="../assets/icons/close.svg"
              alt=""
              className="close-btn"
              onClick={handleClose}
            />
          </div>

          <div className="popup-body">
            <form action="">
              {props?.statusType === "studentStatus" && (
                <div className="form-grp">
                  {/* <label htmlFor="amount">
                    Student Status <span className="imp-mark">*</span>
                  </label> */}
                  {/* <input
                    type="number"
                    name="feeAmount"
                    id="amount"
                    className="create-input pr-85"
                    placeholder="Enter Amount"
                    value={feeAmount}
                    onChange={(e) => setFeeAmount(e.target.value)}
                  /> */}
                  <div
                    onChange={handleStatusChange}
                    className="radio-group mt-10"
                  >
                    <label className="create-label" htmlFor="Active">
                      <input
                        className="radio-btn"
                        type="radio"
                        checked={studentStatus === "Active"}
                        value="Active"
                        id="Active"
                        name="Active"
                      />
                      <span className="radiomark"></span>Active
                    </label>

                    <label className="create-label" htmlFor="DropOut">
                      <input
                        className="radio-btn"
                        type="radio"
                        name="DropOut"
                        id="DropOut"
                        value="DropOut"
                        checked={studentStatus === "DropOut"}
                      />
                      <span className="radiomark"></span>DropOut
                    </label>

                    <label className="create-label" htmlFor="Defer">
                      <input
                        className="radio-btn"
                        type="radio"
                        name="Defer"
                        id="Defer"
                        value="Defer"
                        checked={studentStatus === "Defer"}
                      />
                      <span className="radiomark"></span>Defer
                    </label>
                  </div>
                  <span
                    className={validateDate ? "error-msg active" : "error-msg"}
                  >
                    Please enter fee amount
                  </span>
                </div>
              )}

              {props?.statusType === "paymentMode" && (
                <div className="form-grp">
                  {/* <label htmlFor="amount">
                    Student Status <span className="imp-mark">*</span>
                  </label> */}
                  {/* <input
                    type="number"
                    name="feeAmount"
                    id="amount"
                    className="create-input pr-85"
                    placeholder="Enter Amount"
                    value={feeAmount}
                    onChange={(e) => setFeeAmount(e.target.value)}
                  /> */}
                  <div
                    onChange={handlePaymentModeChange}
                    className="radio-group mt-10"
                  >
                    <label className="create-label" htmlFor="Pending">
                      <input
                        className="radio-btn"
                        type="radio"
                        checked={paymentMode === "Pending"}
                        value="Pending"
                        id="Pending"
                        name="Pending"
                      />
                      <span className="radiomark"></span>Pending
                    </label>

                    <label className="create-label" htmlFor="oneTimePayment">
                      <input
                        className="radio-btn"
                        type="radio"
                        name="oneTimePayment"
                        id="oneTimePayment"
                        value="oneTimePayment"
                        checked={paymentMode === "oneTimePayment"}
                      />
                      <span className="radiomark"></span>One Time Payment
                    </label>

                    <label className="create-label" htmlFor="LoanAvail">
                      <input
                        className="radio-btn"
                        type="radio"
                        name="LoanAvail"
                        id="LoanAvail"
                        value="LoanAvail"
                        checked={paymentMode === "LoanAvail"}
                      />
                      <span className="radiomark"></span>Loan Avail
                    </label>
                  </div>
                  <span
                    className={validateDate ? "error-msg active" : "error-msg"}
                  >
                    Please enter fee amount
                  </span>
                </div>
              )}

              {props?.statusType === "paymentMethod" && (
                <div className="form-grp">
                  {/* <label htmlFor="">
                    Payment Method<span className="imp-mark">*</span>
                  </label> */}

                  <select
                    className="form-input"
                    value={paymentMethod}
                    onChange={handleMethodChange}
                  >
                    <option disabled value="">
                      Select Payment Method
                    </option>

                    <option value="OTP">OTP - One time payment</option>
                    <option value="Full Loan">Full Loan</option>
                    {/* <option value="Flex">Flex</option> */}
                    <option value="Partial Loan">Partial Loan</option>
                    <option value="Partial OTP">Partial OTP</option>
                    <option value="Admissions Fee">Admissions Fee</option>
                    <option value="Flex to be Received">
                      Flex to be Received
                    </option>
                    <option value="Flex Received">Flex Received</option>
                  </select>
                </div>
              )}

              {props?.statusType === "updateAddress" && (
                <>
                  <div className="form-grp">
                    <label htmlFor="address">
                      Student Address <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      className="create-input"
                      placeholder="Enter address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>

                  {/* <div className="form-grp mt-20">
                    <label htmlFor="gstNo">
                      Student GST No. <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="text"
                      name="gstNo"
                      id="gstNo"
                      className="create-input"
                      placeholder="Enter GST No"
                      value={gstNo}
                      onChange={(e) => setGSTNo(e.target.value)}
                    />
                  </div> */}

                  <div className="form-grp mt-20">
                    <label htmlFor="placeSupply">
                      Place of Supply <span className="imp-mark">*</span>
                    </label>

                    <div
                      onChange={handlePlaceOfSupply}
                      className="radio-group mt-10"
                    >
                      <label className="create-label" htmlFor="Haryana">
                        <input
                          className="radio-btn"
                          type="radio"
                          checked={isHaryana}
                          value="Haryana"
                          id="Haryana"
                          name="Haryana"
                        />
                        <span className="radiomark"></span>Haryana
                      </label>

                      <label className="create-label" htmlFor="Other">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="Other"
                          id="Other"
                          value="Other"
                          checked={!isHaryana}
                        />
                        <span className="radiomark"></span>Other
                      </label>
                    </div>
                  </div>

                  {!isHaryana && (
                    <input
                      type="text"
                      name="place"
                      id="place"
                      className="create-input mt-15"
                      placeholder="Enter supply place"
                      value={supplyStatus}
                      onChange={(e) => setSupplyStatus(e.target.value)}
                    />
                  )}
                </>
              )}

              {props?.statusType === "updateReceiptNo" && (
                <>
                  <div className="form-grp ">
                    <label htmlFor="receiptNo">
                      Receipt No. <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="text"
                      name="receiptNo"
                      id="receiptNo"
                      className="create-input"
                      placeholder="Enter number"
                      value={receiptNumber}
                      onChange={(e) => setReceiptNumber(e.target.value)}
                    />
                  </div>
                  <div className="form-grp mt-10">
                    <label htmlFor="date1">
                      Invoice Generated On. <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="date"
                      name="date1"
                      id="date1"
                      className="create-input"
                      placeholder="Enter number"
                      value={invoiceDate}
                      onChange={(e) => setInvoiceDate(e.target.value)}
                    />

                    {/* <input
                        type="date"
                        className="create-input"
                        placeholder="Enter Receipt Number"
                        value={moment(res?.invoiceGeneratedOn).format(
                          "YYYY-MM-DD"
                        )}
                        id={res.id}
                        onBlur={(e) => updateInvoiceGeneratedOn(e)}
                        onChange={(e) => handleInvoiceGeneratedOn(e, res.id)}
                      /> */}
                  </div>
                </>
              )}
            </form>
          </div>
          <div className="popup-footer">
            {/* {props?.dueType === "createFeeDue" && ( */}

            {props?.statusType === "studentStatus" && (
              <button className="primary-btn" onClick={updateStudentStatus}>
                Save
              </button>
            )}

            {props?.statusType === "paymentMethod" && (
              <button className="primary-btn" onClick={updatePaymentMethod}>
                Save
              </button>
            )}
            {props?.statusType === "paymentMode" && (
              <button className="primary-btn" onClick={updatePaymentMode}>
                Save
              </button>
            )}

            {props?.statusType === "updateAddress" && (
              <button className="primary-btn" onClick={updateStudentDetails}>
                Save
              </button>
            )}

            {props?.statusType === "updateReceiptNo" && (
              <button className="primary-btn" onClick={updateReceiptNumber}>
                Update
              </button>
            )}

            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentStatusPopup;
