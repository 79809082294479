import React from "react";
import Paragraph from "../../../components/AnswersComponents/Paragraph/Paragraph";
import RatingBox from "../../../components/AnswersComponents/Rating/RatingBox";
import SingleSelect from "../../../components/AnswersComponents/SingleSelect/SingleSelect";
import UploadBox from "../../../components/AnswersComponents/UploadBox/UploadBox";
import MultiSelect from "../../../components/AnswersComponents/MultiSelect/MultiSelect";

const RenderSlider = ({ layout, data, nextState }) => {
  const { id, questionType, questionName, answers, title, desc } = data;

  // console.log(data);
  // const moveTop = () => {
  //   // document.getElementsByClassName(".inside-main")[0].offsetTop(0);
  // };
  return (
    <div className="inside-main" key={id}>
      <div className="inside-shell">
        <div className={`inside-mid ${layout}`}>
          <div className="inside-content">
            <h2 className="heading-2">{questionName || title}</h2>
            {desc && <p className="text-4">{desc}</p>}
            {questionType === "paragraph" && <Paragraph />}
            {questionType === "rating" && <RatingBox />}
            {questionType === "singleSelect" && <SingleSelect item={data} />}
            {questionType === "multiSelect" && <MultiSelect item={data} />}
            {questionType === "uploadMedia" && <UploadBox />}
            {id === 0 && (
              <button className="danger-btn mt-55 max-w170" onClick={nextState}>
                Continue
              </button>
            )}
            {id !== 0 && (
              <button onClick={nextState} className="danger-btn mt-55 max-w170">
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderSlider;
