import React from 'react'
import "./BlankScreenStyle.css";

function BlankScreen() {
  return (
    <div className='BlankScreen-Wrapper'>

        <div className='BlankScreen'>
            <img src="../../assets/img/blankuser.png" alt="" />
            <h2 className='blank-heading'>Create a Club</h2>
            <p className='text-1'><strong>You have created a new club.</strong> Now you can conduct club related activities.</p>
        </div>

    </div>
  )
}

export default BlankScreen