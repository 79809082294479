import React from "react";
const ActionPopup = (props) => {
    return (
        <>
            <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
                <div className="popup-container delete-poup">
                    <div className="popup-header">
                        <div className="popup-heading">
                            {props.heading}
                            <img
                                src="../assets/icons/close.svg"
                                alt=""
                                className="close-btn"
                                onClick={props.handleClose}
                            />
                        </div>

                        <div className="popup-header-body">
                            <div className="header-left">
                                <p className="text-7">{props.message}</p>
                                {/* <p className="text-7r">You can’t undo this action.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="popup-footer mt-40">
                        <div className="btn-group">
                            <button onClick={props.handleClose} className="dark-btn-outline">
                                cancel
                            </button>
                            {props.actionType === 'warning' ?
                                (<button className="danger-btn" onClick={props.onSubmit}>{props.action}</button>) :
                                (<button className="green-btn" onClick={props.onSubmit}>{props.action}</button>)
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default ActionPopup;