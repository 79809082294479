import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const headerHandler = createAsyncThunk(
  "headerHandler",
  async (data, thunkAPI) => {
    try {
      // console.log('print value', JSON.stringify(data));
      return data;
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const checkLoginStatus = createAsyncThunk(
  "checkLoginStatus",
  async (data, thunkAPI) => {
    try {
      // console.log('print value', JSON.stringify(data));
      return data;
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const headerSlice = createSlice({
  name: "header",
  initialState: {
    isHamburgerEnable: false,
    isUserLoggedIn: false,
  },
  reducers: {
    clearState: (state) => {
      state.isHamburgerEnable = false;
      state.isUserLoggedIn = false;

      return state;
    },
  },
  extraReducers: {
    [headerHandler.fulfilled]: (state, { payload }) => {
      // console.log('printing payload', payload);
      state.isHamburgerEnable = payload;
      return state;
    },
    [headerHandler.rejected]: (state, { payload }) => {
      console.log("payload", payload);
    },
    [headerHandler.pending]: (state) => {
      // console.log('inside pending state', state);
    },
    [checkLoginStatus.fulfilled]: (state, { payload }) => {
      // console.log('printing payload', payload);
      state.isUserLoggedIn = payload;
      return state;
    },
    [checkLoginStatus.rejected]: (state, { payload }) => {
      console.log("payload", payload);
    },
    [checkLoginStatus.pending]: (state) => {
      // console.log('inside pending state', state);
    },
  },
});

export const { clearState } = headerSlice.actions;

export const headerSelector = (state) => state.header;
