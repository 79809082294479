import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let adminId;
let userId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
  userId = "24ddf443-adb7-439e-87c5-907dd828gba7";
  // userId="5cd297ada-6c6a-478c-8473-8b9087e26d1"
};

// /:organizationId/user/:userId/getMentorStudents
export const getMentorStudentsData = createAsyncThunk(
  "meetings/getMentorStudentsData",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let usrId = obj?.userId ?? userId;
      const response = await fetch(
        environment.baseURL +
          "/api/org/user" +
          // orgId +
          "/user/" +
          usrId +
          "/getMentorStudents",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//   /:organizationId/user/:userId/getMeetingMasterDetails
export const getMeetingTitleData = createAsyncThunk(
  "meetings/getMeetingTitleData",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let usrId = obj?.userId ?? userId;

      const response = await fetch(
        environment.baseURL +
          "/api/org/user" +
          // orgId +
          "/user/" +
          usrId +
          "/getMeetingMasterDetails",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /:organizationId/user/:userId/student/:studentId/getMeetingDetails
export const getMeetingDetailListData = createAsyncThunk(
  "meetings/getMeetingDetailListData",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let studentId = obj?.studentId;
      let usrId = obj?.userId ?? userId;
      const response = await fetch(
        environment.baseURL +
          "/api/org/user" +
          // orgId +
          "/user/" +
          usrId +
          "/student/" +
          studentId +
          // "1afc38e5-935d-4f66-8f91-4be944f80b1c" +
          "/getMeetingDetails",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /:organizationId/user/:userId/student/:studentId/getMeetingDetails
export const fetchAllMeetingDetails = createAsyncThunk(
  "meetings/fetchAllMeetingDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let usrId = obj?.userId ?? userId;
      const response = await fetch(
        environment.baseURL +
          "/api/org" +
          // orgId +
          "/user/" +
          usrId +
          // "1afc38e5-935d-4f66-8f91-4be944f80b1c" +
          "/fetchAllMeetingDetails",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /:organizationId/user/:userId/updateMeetingDetails
export const updateMeetingDetailsData = createAsyncThunk(
  "meetings/updateMeetingDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let payload;
      if (obj.startTimeByMentor) {
        payload = {
          startedBy: obj.startedBy,
          studentId: obj.studentId,
          meetingMasterId: obj.meetingMasterId,
          // organizationId: orgId,
          userId: obj.userId,
          startTimeByMentor: obj.startTimeByMentor,
          meetingStatusByMentor: "onGoing",
          utcOffset: obj.utcOffset,
        };
      } else if (obj.endTimeByMentor) {
        payload = {
          startedBy: obj.startedBy,
          studentId: obj.studentId,
          meetingMasterId: obj.meetingMasterId,
          // organizationId: orgId,
          userId: obj.userId,
          endTimeByMentor: obj.endTimeByMentor,
          meetingStatusByMentor: "Completed",
          utcOffset: obj.utcOffset,
        };
      } else {
        payload = {
          // time: obj.time,
          Date: obj.Date,
          startedBy: obj.startedBy,
          approvedByMentor: obj.approvedByMentor,
          studentId: obj.studentId,
          meetingMasterId: obj.meetingMasterId,
          // organizationId: orgId,
          userId: obj.userId,
          approvedByStudent: obj.approvedByStudent,
          email: obj.email,
          utcOffset: obj.utcOffset,
          approveClickedByMentor: obj.approveClickedByMentor,
        };
      }

      const response = await fetch(
        environment.baseURL +
          "/api/org/user" +
          // orgId +
          "/user/" +
          obj.userId +
          "/updateMeetingDetails",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /:organizationId/user/:userId/getMentorStudents
export const getAllUserData = createAsyncThunk(
  "meetings/getAllUserData",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/getAllUsers",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /:organizationId/user/:userId/createMentorFeedback
export const createMentorFeedback = createAsyncThunk(
  "meetings/createMentorFeedback",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const payload = {
        meetingMasterId: obj.meetingMasterId,
        meetingDetailId: obj.meetingDetailId,
        feedbackFor: obj.feedbackFor,
        studentId: obj.studentId,
        response1: obj.response1,
        response2: obj.response2,
        response3: obj.response3,
        response4: obj.response4,
        response5: obj.response5,
        response6: obj.response6,
        response7: obj.response7,
        response8: obj.response8,
        // organizationId:orgId,
        userId: obj.userId,
        isFeedbackByMentor: true,
        utcOffset: obj.utcOffset,
      };

      const response = await fetch(
        environment.baseURL +
          "/api/org/user" +
          // orgId +
          "/user/" +
          obj.userId +
          "/createMentorFeedback",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const cancelledMeeting = createAsyncThunk(
  "meetings/cancelledMeeting",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/user" +
          // orgId +
          "/user/" +
          obj.userId +
          "/cancelledMeeting",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createMentorAgenda = createAsyncThunk(
  "meetings/createMentorAgenda",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/user" +
          // orgId +
          "/user/" +
          obj.userResponseId +
          "/createMentorAgenda",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /user/:userId/getSpecificMeetingDetailsAgenda/:meetingDetailId
export const getSpecificMeetingDetailsAgenda = createAsyncThunk(
  "meetings/getSpecificMeetingDetailsAgenda",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/user" +
          "/user/" +
          obj.userId +
          "/getSpecificMeetingDetailsAgenda/" +
          obj.meetingDetailId,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getInterviewSlotDetails = createAsyncThunk(
  "meetings/getInterviewSlotDetails",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/user/getInterviewSlotDetails/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAdminInterviewSlots = createAsyncThunk(
  "meetings/getAdminInterviewSlots",
  async (date, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          `/getAdminInterviewSlots?date=${date}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createMeetingMaster = createAsyncThunk(
  "meetings/createMeetingMaster",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/user" +
          // orgId +
          "/user/" +
          obj.userId +
          "/createMeetingMaster",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addAdminAvailableSlots = createAsyncThunk(
  "meetings/addAdminAvailableSlots",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/addAdminAvailableSlots",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteAdminInterviewSlot = createAsyncThunk(
  "meetings/deleteAdminInterviewSlot",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/deleteAdminInterviewSlot",
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addMentorAvailableSlots = createAsyncThunk(
  "meetings/addMentorAvailableSlots",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/addmentorshipCallAdminAvailableSlots",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const getMentorCallInterviewSlots = createAsyncThunk(
  "meetings/getMentorCallInterviewSlots",
  async (date, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          `/getmentorshipCallAdminInterviewSlots?date=${date}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);





export const meetingSlice = createSlice({
  name: "meetingDetails",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    getMentorStudentsList: [],
    getMeetingTitleList: [],
    getMeetingDetailsList: [],
    getNewMeetingDetailsList: [],
    meetingFeedBack: false,
    allUserData: [],
    createMentorAgendaSuccess: false,
    getAgendaData: [],
    interviewSlotsData: [],
    adminInterviewSlots: [],

    newMeeting: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      return state;
    },
    clearFeedBack: (state) => {
      state.meetingFeedBack = false;
      return state;
    },
    clearAgenda: (state) => {
      state.createMentorAgendaSuccess = false;
      return state;
    },
    clearNewMeeting: (state) => {
      state.newMeeting = false;
      return state;
    },
  },
  extraReducers: {
    [getMentorStudentsData.fulfilled]: (state, { payload }) => {
      state.getMentorStudentsList = payload;
      state.isFetching = false;
      return state;
    },
    [getMentorStudentsData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMentorStudentsData.pending]: (state) => {
      state.isFetching = true;
    },
    [getMeetingTitleData.fulfilled]: (state, { payload }) => {
      state.getMeetingTitleList = payload;
      state.isFetching = false;
      return state;
    },
    [getMeetingTitleData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMeetingTitleData.pending]: (state) => {
      state.isFetching = true;
    },
    [getMeetingDetailListData.fulfilled]: (state, { payload }) => {
      state.getMeetingDetailsList = payload;
      state.isFetching = false;
      return state;
    },
    [getMeetingDetailListData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMeetingDetailListData.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchAllMeetingDetails.fulfilled]: (state, { payload }) => {
      state.getNewMeetingDetailsList = payload;
      state.isFetching = false;
      return state;
    },
    [fetchAllMeetingDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [fetchAllMeetingDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [updateMeetingDetailsData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [updateMeetingDetailsData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateMeetingDetailsData.pending]: (state) => {
      state.isFetching = true;
    },
    [createMentorFeedback.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.meetingFeedBack = true;
      return state;
    },
    [createMentorFeedback.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [createMentorFeedback.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllUserData.fulfilled]: (state, { payload }) => {
      state.allUserData = payload;
      state.isFetching = false;
      return state;
    },
    [getAllUserData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllUserData.pending]: (state) => {
      state.isFetching = true;
    },
    [cancelledMeeting.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      return state;
    },
    [cancelledMeeting.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [cancelledMeeting.pending]: (state) => {
      state.isFetching = true;
    },
    [createMentorAgenda.fulfilled]: (state, { payload }) => {
      // state.isSuccess = true;
      state.isFetching = false;
      state.createMentorAgendaSuccess = true;
      return state;
    },
    [createMentorAgenda.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [createMentorAgenda.pending]: (state) => {
      state.isFetching = true;
    },
    [getSpecificMeetingDetailsAgenda.fulfilled]: (state, { payload }) => {
      state.getAgendaData = payload;
      state.isFetching = false;
      return state;
    },
    [getSpecificMeetingDetailsAgenda.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificMeetingDetailsAgenda.pending]: (state) => {
      // state.isFetching = true;
    },
    [getInterviewSlotDetails.fulfilled]: (state, { payload }) => {
      state.interviewSlotsData = payload;
      state.isFetching = false;
      return state;
    },
    [getInterviewSlotDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getInterviewSlotDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [getAdminInterviewSlots.fulfilled]: (state, { payload }) => {
      state.adminInterviewSlots = payload;
      state.isFetching = false;
      return state;
    },
    [getAdminInterviewSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAdminInterviewSlots.pending]: (state) => {
      state.isFetching = true;
    },
    [createMeetingMaster.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.newMeeting = true;
      state.isSuccess = true;
      return state;
    },
    [createMeetingMaster.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [createMeetingMaster.pending]: (state) => {
      state.isFetching = true;
    },
    [addAdminAvailableSlots.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [addAdminAvailableSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addAdminAvailableSlots.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteAdminInterviewSlot.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [deleteAdminInterviewSlot.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [deleteAdminInterviewSlot.pending]: (state) => {
      state.isFetching = true;
    },

    [addMentorAvailableSlots.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [addMentorAvailableSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addMentorAvailableSlots.pending]: (state) => {
      state.isFetching = true;
    },

    [getMentorCallInterviewSlots.fulfilled]: (state, { payload }) => {
      state.adminInterviewSlots = payload;
      state.isFetching = false;
      return state;
    },
    [getMentorCallInterviewSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMentorCallInterviewSlots.pending]: (state) => {
      state.isFetching = true;
    },



  },
});

export const { clearState, clearFeedBack, clearAgenda, clearNewMeeting } =
  meetingSlice.actions;

export const meetingData = (state) => state.meetingDetails;
