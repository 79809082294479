import React from "react";
import "../BlankComponent/BlankScreenStyle.css";

const SiteUnderMaintenance = () => {
  return (
    <div className="BlankScreen-Wrapper">
      <div className="BlankScreen">
        <img src="../../assets/img/maintenance.svg" alt="" />
        <h2 className="blank-heading">Emergency server maintenance</h2>
        <p className="text-1">
        Try refreshing the page, ot try again leter.
        </p>
        <button className="primary-btn mt-20">Refresh Page</button>
      </div>
    </div>
  );
};

export default SiteUnderMaintenance;
