import React, { useEffect, useState, useRef } from "react";
import "../studentFeeList/studentFeeListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import PageLoader from "../../../components/UI/loader/PageLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "../studentCourseFeeList/studentCourseFeeListStyle.css";
import { environment } from "../../../environments/environment";
import {
  clearState,
  feeData,
  getStudentLoanRequests,
} from "../../../reducers/feeSlice";
import moment from "moment";
import VerifyPopup from "../../../components/Popups/VerifyPopup/VerifyPopup";
import LoanPopup from "../../../components/Popups/LoanPopup/LoanPopup";
import MuLoanPopUp from "../../../components/Popups/MuLoan/MuLoan";
import LoanApprovePopup from "../../../components/Popups/LoanApprovePopup/LoanApprovePopup";
import UpdateFeePopup from "../../../components/Popups/UpdateFeePopup/UpdateFeePopup";
import StudentStatusPopup from "../../../components/Popups/StudentStatusPopup/StudentStatusPopup";
const StudentLoanRequest = () => {
  /**Student Status Popup State */
  const [studentStatusPopup, setStudentStatusPopup] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [isFilterMenuActive, setisFilterMenuActive] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [descending, setDescending] = useState("");
  const [date, setDate] = useState("");

  /**END */
  const {
    isFetching,
    isComplete,
    isSuccess,
    isError,
    programFeeTypeList,
    updateStatusSuccess,
    studentCount,
    studentLoanRequestList,
  } = useSelector(feeData);
  const [menuId, setMenuId] = useState(null);

  const [showMoreLoader, setShowMoreLoader] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [allChecked, setAllChecked] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isTotalSelected, setTotalSelected] = useState(false);
  const [feeAmount, setFeeAmount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [applyFilter, setApplyFilter] = useState(false);

  const [isLoanPopUpOpen, setLoanPopup] = useState(false);
  const [isLoanApprovePopUpOpen, setLoanApprovePopUp] = useState(false);
  const [isUpdateFeePopUpOpen, setUpdateFeePopUp] = useState(false);
  const [isMuLoanPopUpOpen, setMuLoanPopup] = useState(false);
  const [isVerifyPopupOpen, setVerifyPopup] = useState(false);

  const [feeDueData, setFeeDueData] = useState(null);
  const [feeDueId, setFeeDueId] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [utrNumber, setUtrNumber] = useState(null);
  const filter1 = useRef(null);
  const filter2 = useRef(null);
  const filter3 = useRef(null);
  const filter4 = useRef(null);

  const clickApply = () => {
    setisFilterMenuActive(false);

    setPageNo("1");
    if (
      loanStatus !== "" ||
      balancePayableStatus !== "" ||
      studentStatus !== "" ||
      welcomeStatus !== ""
    ) {
      setFilterApplied(true);
      setApplyFilter(true);
    }
  };
  const clickClear = () => {
    setFilterApplied(false);
    setPageNo("1");

    setDescending("");
    setLoanStatus("");
    setBalancePayableStatus("");
    setStudentStatus("");
    setDate("");
    setWelcomeStatus("");
    setisFilterMenuActive(false);

    if (
      loanStatus !== "" ||
      balancePayableStatus !== "" ||
      studentStatus !== "" ||
      welcomeStatus !== "" ||
      descending !== ""
    )
      setApplyFilter(true);
    filter1.current.selectedIndex = 0;
    filter2.current.selectedIndex = 0;
    filter3.current.selectedIndex = 0;
    filter4.current.selectedIndex = 0;
  };

  //filters states
  const [dueStatus, setDueStatus] = useState("false");
  const [welcomeStatus, setWelcomeStatus] = useState("");

  const [searchTitle, setSearchTitle] = useState("");
  const [requestType, setRequestType] = useState("");
  const [balancePayableStatus, setBalancePayableStatus] = useState("");
  const [loanStatus, setLoanStatus] = useState("");
  const [studentStatus, setStudentStatus] = useState("");

  const [pageNo, setPageNo] = useState("1");
  const [pageSize, setPageSize] = useState("10");

  const handleSearch = (event) => {
    var search = event.target.value;
    setSearchTitle(search);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchByTitle(e.target.value);
    }
  };

  const searchByTitle = (val) => {
    let obj = {
      programBatchId: programBatchId,
    };
    var params = {
      searchTitle: val,
      dueStatus: dueStatus,
      requestType: requestType,
      pageNo: pageNo,
      pageSize: pageSize,
      balanceStatus: balancePayableStatus,
      studentStatus: studentStatus,
      welcomeStatus: welcomeStatus,
      bankLoan: loanStatus,
      descending: descending,
      date: date,
      feeDueStartDate:
        startDate && moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
      feeDueEndDate: endDate && moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
    };
    if (startDate != null && endDate == null) {
      return;
    }
    if (startDate == null && endDate == null) {
      params = {
        searchTitle: val,
        dueStatus: dueStatus,
        requestType: requestType,
        pageNo: pageNo,
        pageSize: pageSize,
        balanceStatus: balancePayableStatus,
        studentStatus: studentStatus,
        welcomeStatus: welcomeStatus,
        descending: descending,
        date: date,
        feeDueStartDate: "",
        feeDueEndDate: "",
        bankLoan: loanStatus,
      };
    }
    dispatch(getStudentLoanRequests({ obj, params }));
    dispatch(clearState());
    setIsChecked([]);
    setAllChecked(false);
    setPageNo("1");
  };

  const [studentData, setStudentData] = useState([]);
  const [searchParams] = useSearchParams();
  const programBatchId = searchParams.get("programBatchId");
  const programMasterId = searchParams.get("programMasterId");
  const feeMasterId = searchParams.get("feeMasterId");
  const feeId = searchParams.get("feeId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFeePopupActive, setIsFeePopupActive] = useState(false);
  const handleFeePopup = () => {
    setIsFeePopupActive(!isFeePopupActive);
  };

  const [updateType, setUpdateType] = useState("");
  const [loanDetails, setLoanDetails] = useState(null);

  useEffect(() => {
    if (applyFilter || loaded) {
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        dueStatus: dueStatus,
        requestType: requestType,
        pageNo: pageNo,
        pageSize: pageSize,
        balanceStatus: balancePayableStatus,
        studentStatus: studentStatus,
        date: date,
        welcomeStatus: welcomeStatus,
        bankLoan: loanStatus,
        descending: descending,
        feeDueStartDate: moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
        feeDueEndDate: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
      };
      if (startDate != null && endDate == null) {
        return;
      }
      if (startDate == null && endDate == null) {
        params = {
          searchTitle: searchTitle,
          dueStatus: dueStatus,
          requestType: requestType,
          pageNo: pageNo,
          pageSize: pageSize,
          balanceStatus: balancePayableStatus,
          studentStatus: studentStatus,
          welcomeStatus: welcomeStatus,
          descending: descending,
          date: date,
          feeDueStartDate: "",
          feeDueEndDate: "",
          bankLoan: loanStatus,
        };
      }
      dispatch(getStudentLoanRequests({ obj, params }));
      setApplyFilter(false);
      dispatch(clearState());
    } else {
      console.log("done");
    }
  }, [
    dueStatus,
    requestType,
    pageNo,
    pageSize,
    programBatchId,
    applyFilter,
    endDate,
  ]);

  useEffect(() => {
    if (isSuccess || updateStatusSuccess) {
      let obj = {
        programBatchId: programBatchId,
      };
      var params = {
        searchTitle: searchTitle,
        dueStatus: dueStatus,
        requestType: requestType,
        pageNo: pageNo,
        pageSize: pageSize,
        balanceStatus: balancePayableStatus,
        studentStatus: studentStatus,
        descending: descending,
        date: date,
        welcomeStatus: welcomeStatus,
        feeDueStartDate: moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
        feeDueEndDate: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
        bankLoan: loanStatus,
      };
      if (startDate != null && endDate == null) {
        return;
      }
      if (startDate == null && endDate == null) {
        params = {
          searchTitle: searchTitle,
          dueStatus: dueStatus,
          requestType: requestType,
          pageNo: pageNo,
          pageSize: pageSize,
          balanceStatus: balancePayableStatus,
          studentStatus: studentStatus,
          descending: descending,
          welcomeStatus: welcomeStatus,
          date: date,
          feeDueStartDate: "",
          feeDueEndDate: "",
          bankLoan: loanStatus,
        };
      }
      dispatch(getStudentLoanRequests({ obj, params }));
      dispatch(clearState());
      setStudentStatusPopup(false);
      setStatusType("");
    }
  }, [isSuccess, updateStatusSuccess]);

  useEffect(() => {
    setPageNo("1");
    setIsChecked([]);
    setAllChecked(false);
    setLoaded(false);
  }, [pageSize, requestType, dueStatus, applyFilter]);

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    if (studentLoanRequestList) {
      setFeeAmount(studentLoanRequestList?.fee?.amount);
      if (loaded === true) {
        setStudentData([...studentData, ...studentLoanRequestList?.Data]);
        setLoaded("done");
      } else if (loaded === false) {
        setStudentData(studentLoanRequestList?.Data);
      } else {
        console.log(null);
      }
      setLoaded(false);
    }
  }, [studentLoanRequestList]);

  const toggleVerifyPopup = () => {
    setVerifyPopup((p) => !p);
  };

  const toggleLoanPopup = () => {
    setLoanPopup((p) => !p);
  };

  const toggleLoanApprovePopup = () => {
    setLoanApprovePopUp((p) => !p);
  };

  const toggleMuLoanPopup = () => {
    setMuLoanPopup((p) => !p);
  };

  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);
  const [isMailAccess, setMailAccess] = useState(false);

  useEffect(() => {
    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    const isMailAccess = localStorage.getItem("mailSending") === "true";
    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
    if (isMailAccess) {
      setMailAccess(isMailAccess);
    }

    setStudentData([]);
    setApplyFilter(true);
  }, []);

  const handleLoadClick = () => {
    if (pageNo == Math.ceil(studentCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
    setShowMoreLoader(true);
    setLoaded(true);
  };

  console.log("hgfghfhfhfhfhfhfhf", feeDueData);

  const toggleUpdateFeePopup = () => {
    setUpdateFeePopUp((p) => !p);
    setUpdateType("");
    setLoanDetails(null);
  };

  const onClickStudentCSV = () => {
    var url =
      environment.baseURL +
      "/api/org/programId/" +
      programBatchId +
      "/downloadStudentFeeListCSV";

    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <SideMenu />
      <LoanPopup
        show={isLoanPopUpOpen}
        feeDueData={feeDueData}
        studentId={studentId}
        handleClose={toggleLoanPopup}
      />
      <LoanApprovePopup
        show={isLoanApprovePopUpOpen}
        feeDueData={feeDueData}
        studentId={studentId}
        handleClose={toggleLoanApprovePopup}
        programName={
          studentLoanRequestList?.programDetails?.Program?.programName
        }
        programId={programBatchId}
      />

      {isFetching && pageNo === "1" ? <PageLoader /> : ""}

      <div className="main-body popupFixed">
        <div className="popupFixedContainer">
          <div className="page-header">
            <button
              className="component-back"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src="../assets/icons/back-btn.svg" />
              Back to Page
            </button>
            <div className="studentDetails">
              <div className="student-inner-left">
                <div className="student-details-outer">
                  <div className="student-details">
                    <p className="student-name text-18">
                      {
                        studentLoanRequestList?.programDetails?.Program
                          ?.programName
                      }{" "}
                      <img src="../assets/icons/sectionSymbol.svg" />{" "}
                      <span className="text-danger">Course Fee</span>
                    </p>
                  </div>
                  <div className="student-details">
                    <p className="student-mail">
                      {studentLoanRequestList?.programDetails?.name} &#xb7;
                      {
                        studentLoanRequestList?.programDetails?.durationInMonths
                      }{" "}
                      months
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="student-inner-right bg-grey">
                <div className="fee-box total">
                  <p>Total Dues</p>
                  <p className="text-dark">₹ {studentLoanRequestList?.total}</p>
                </div>
                <div className="fee-box">
                  <p>Received</p>
                  <p className="text-success">
                    ₹ {studentLoanRequestList?.submitted}
                  </p>
                </div>
                <div className="fee-box">
                  <p>Pending</p>
                  <p className="text-danger">
                    ₹ {studentLoanRequestList?.pending}
                  </p>
                </div>
              </div> */}
            </div>
          </div>

          <div className="filter-bar">
            <form action="" className="search-bar">
              <img src="../assets/icons/search.svg" alt="" />
              <input
                type="text"
                name=""
                id=""
                onChange={handleSearch}
                onKeyDown={onSearchKeyPress}
                placeholder="Search"
                className="clean-input"
              />
            </form>

            <div className="total-data-desc">
              Showing {studentData?.length} out of {studentCount}
            </div>

            <div className="filters">
              {/* <button
                className="primary-btn feed-btn"
                onClick={onClickStudentCSV}
              >
                Student CSV
              </button>
              <button
                className={filterApplied ? "filterBtn-active" : "filterBtn"}
                onClick={() => setisFilterMenuActive(!isFilterMenuActive)}
              >
                {" "}
                {filterApplied ? (
                  <img src="../assets/icons/filterWhite.svg" alt="" />
                ) : (
                  <img src="../assets/icons/filter.svg" alt="" />
                )}{" "}
                Filters
              </button> */}

              <select
                id="type"
                onChange={(e) => {
                  setPageSize(e.target.value);
                  setApplyFilter(true);
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="student-table-outer scrollbar ">
            <table
              className="feedback-table fixedTableDesign tablew100"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Total payable </th>
                  <th>Submited Amount </th>
                  <th>Balance Payable</th>
                  {!viewOnlyAccess && isMailAccess && (
                    <th className="text-center">Request</th>
                  )}

                  {!viewOnlyAccess && isMailAccess && (
                    <th className="text-center">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {studentData?.map((res, index) => {
                  const {
                    id,
                    firstName,
                    lastName,
                    officialEmail,
                    mobileNumber,
                    FeeDues,
                    StudentFeeRecord,
                    studentStatus,
                  } = res;
                  return (
                    <React.Fragment key={id}>
                      <tr>
                        <td>
                          <div className="programDetail">
                            <div className="programDetailText">
                              <p className="primary-text pointer">
                                {(firstName + " " + lastName)
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.substring(1)
                                  )
                                  .join(" ")}
                              </p>
                            </div>
                            {studentStatus === "Active" ? (
                              <div className="activeCourse">Active</div>
                            ) : (
                              ""
                            )}
                            {studentStatus === "DropOut" ? (
                              <div className="expiredCourse">Dropout</div>
                            ) : (
                              ""
                            )}
                            {studentStatus === "Defer" ? (
                              <div className="DeferCourse">Defer</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        <td>{officialEmail.toLowerCase()}</td>
                        <td>{mobileNumber}</td>
                        <td>₹ {StudentFeeRecord?.totalAmount ?? "0"}</td>
                        <td>
                          <p className="text-success">
                            ₹ {StudentFeeRecord?.submittedAmount ?? "0"}
                          </p>
                        </td>
                        <td>
                          <p className="text-danger">
                            ₹ {StudentFeeRecord?.pendingAmount ?? "0"}
                          </p>
                        </td>

                        {!viewOnlyAccess && isMailAccess && (
                          <td className="text-center">
                            {FeeDues[0]?.isPaid ? (
                              <p>N/A</p>
                            ) : (
                              <>
                                {FeeDues[0]?.isBankTransfer ||
                                FeeDues[0]?.isMuLoan ||
                                FeeDues[0]?.isBankLoan ? (
                                  <div>
                                    {FeeDues[0]?.isBankLoan && (
                                      <span
                                        onClick={() => {
                                          toggleLoanPopup();
                                          setStudentId(id);
                                          setFeeDueData(FeeDues[0]);
                                        }}
                                        className="dark-btn"
                                      >
                                        Bank Loan
                                      </span>
                                    )}
                                  </div>
                                ) : (
                                  <p>N/A</p>
                                )}
                              </>
                            )}
                          </td>
                        )}
                        <td className="text-center">
                          <button
                            className="action-btn"
                            onClick={() => openActionMenu(index)}
                            onBlur={closeActionMenu}
                          >
                            <img src="../assets/icons/options.svg" />
                            <ul
                              className={`action-dropdown ${
                                menuId === index ? "active" : ""
                              } approval-drpdown`}
                            >
                              {FeeDues[0]?.isBankLoan &&
                                FeeDues[0]?.FeeTransactions?.length === 0 && (
                                  <li
                                    className="dropdown-item "
                                    onClick={() => {
                                      toggleLoanApprovePopup();
                                      setStudentId(id);
                                      setFeeDueData(FeeDues[0]);
                                    }}
                                  >
                                    <img
                                      src="../assets/icons/copy.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">
                                      Loan Approval
                                    </p>
                                  </li>
                                )}

                              {FeeDues[0]?.isBankLoan &&
                                FeeDues[0]?.FeeTransactions?.length > 0 && (
                                  <li
                                    className="dropdown-item "
                                    onClick={() => {
                                      toggleUpdateFeePopup();
                                      setUpdateType("viewLoanDetail");
                                      setFeeDueId(FeeDues[0]?.id);
                                      setLoanDetails(FeeDues[0]);
                                    }}
                                  >
                                    <img
                                      src="../assets/icons/copy.svg"
                                      alt=""
                                    />
                                    <p className="dropdown-text">
                                      Loan Details
                                    </p>
                                  </li>
                                )}
                            </ul>
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>

          {studentData?.length !== studentCount && (
            <div className="loadMoreRow">
              <button
                className={`${
                  isFetching && showMoreLoader
                    ? "primary-btn-a active"
                    : "primary-btn-a"
                }`}
                onClick={handleLoadClick}
              >
                <img src="../assets/icons/loader icon-01.png" /> Show More
              </button>
            </div>
          )}
        </div>
      </div>
      {/* FILTER - MENU - STARTS */}
      <div className={`filterMenuOuter ${isFilterMenuActive ? "active" : ""}`}>
        <div className="filterHeader">
          <div className="heading">Filters</div>
          <img
            src="../assets/icons/close.svg"
            alt=""
            className="pointer"
            onClick={() => setisFilterMenuActive(false)}
          />
        </div>
        <div className="filterBody">
          {/* <div className="text-1-bold">Admission Fee</div>
          <div className="selectOuter mt-5">
         
          <select id="type" onChange={(e) => setAdmissionFee(e.target.value)} ref={filter1}>
              <option value="">All</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
          <div className="mt-15 text-1-bold">Interview Status</div>
         
          <div className="selectOuter mt-5">
         
          <select
              id="type"
              onChange={(e) => setInterviewStatus(e.target.value)} ref={filter2}
            >
              <option value="">All</option>
              <option value="Completed">Completed</option>
              <option value="Pending">Pending</option>
              <option value="Scheduled">Scheduled</option>
            </select>
          </div> */}
          {/* <div className="selectOuter"></div> */}
          <div className="text-1-bold">Loan Status</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setLoanStatus(e.target.value)}
              ref={filter4}
            >
              <option value="">All</option>
              <option value="paid">Approved</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div className="text-1-bold mt-15">Payment Status</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setBalancePayableStatus(e.target.value)}
              ref={filter1}
            >
              <option value="">All</option>
              <option value="paid">Paid</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div className="text-1-bold mt-15">Student Status</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setStudentStatus(e.target.value)}
              ref={filter2}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="DropOut">DropOut</option>
              <option value="Defer">Defer</option>
            </select>
          </div>

          <div className="text-1-bold mt-15">Welcome Status</div>
          <div className="selectOuter mt-5">
            <select
              id="type"
              onChange={(e) => setWelcomeStatus(e.target.value)}
              ref={filter3}
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="sent">Sent</option>
            </select>
          </div>
        </div>

        <div className="filterFooter">
          <div className="btnGrp">
            <button className="dark-btn-outline-a" onClick={clickClear}>
              Clear
            </button>
            <button className="primary-btn-a" onClick={clickApply}>
              Apply
            </button>
          </div>
        </div>
      </div>
      {/* FILTER- MENU- ENDS */}
    </>
  );
};

export default StudentLoanRequest;
