import React, { useState } from "react";
import "./createInterviewStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import FeeDropdown from "../../../components/Dropdowns/FeeDropdown/FeeDropdown";
import 'react-calendar/dist/Calendar.css';

const CreateInterview = () => {
  const [selectedOption, setSelectedOption] = useState("Select Program");
  return (
    <>
      <SideMenu />
      <div className="main-body">
        <div className="page-header">
          <button className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            back to page
          </button>
        </div>
        <div className="scrollbar show" id="container-1">
          <div className="create-container interview-container">
            <form action="">
              <div className="form-group mt-20">
                <label htmlFor="app-list" className="create-label">
                  Application List <span className="imp-mark">*</span>
                </label>
                <div className="upload-btn-wrapper">
                  <div className="upload-inner">
                    <input type="file" name="" hidden="hidden" id="" />
                    <button className="upload-btn">Choose a File</button>
                    <p className="file-name">Contacts.CSV</p>
                  </div>
                  <img src="../../assets/icons/delete.svg" alt="" />
                </div>
                <span className="error-msg ">select any option</span>
              </div>
              <div className="form-group interview">
                <label htmlFor="program" className="create-label">
                  Proogram Name <span className="imp-mark">*</span>
                </label>
                <FeeDropdown
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
                <span className="error-msg">select any option</span>
              </div>
              <div className="form-group interview">
                <label htmlFor="program" className="create-label">
                  Proogram Name <span className="imp-mark">*</span>
                </label>
                <FeeDropdown
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
                <span className="error-msg">select any option</span>
              </div>
            </form>
            <div className="form-footer mt-30">
              <button className="primary-btn">Next</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInterview;
