import React from "react";
import "./AddVendorPopupStyle.css";
const AddVendorPopup = (props) => {

  const handleSave =()=>{
    
    props.vendorDetailsSubmit()
  }
  // console.log(props.memberPopup);
  // console.log(props.vendorDetails);

  return (
    <>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container vendorPopup">
          <div className="popup-header">
            <div className="popup-heading">
              {props.memberPopup?"Member Details":"Vendor Details"}
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleAddVendorPopup}
              />
            </div>
          </div>
          <div className="popup-body">
            <div className="form-grp">
              <label htmlFor="" className="create-label">
              {props.memberPopup?"Member Name":"Vendor Name"}
              </label>
              <input
                type="text"
                className="create-input"
                placeholder="Enter First Name"
                name={props.memberPopup?"memberName":"vendorName"}
                value={props.vendorDetails.vendorName}
                onChange={props.vendorDetailsHandleChange}
              />
            </div>
            <div className="form-2-col mt-20">
              <div className="form-grp">
                <label htmlFor="" className="create-label">
                  GST Number
                </label>
                <input
                  type="text"
                  className="create-input"
                  placeholder={props.memberPopup?"Enter Member GST number":"Enter Vendor GST number"}
                  name="gstNumber"
                  value={props.vendorDetails.gstNumber}
                  onChange={props.vendorDetailsHandleChange}
                />
              </div>
              <div className="form-grp mb-0">
                <label htmlFor="" className="create-label">
                  Contact person name
                </label>
                <input
                  type="text"
                  className="create-input"
                  placeholder="Enter name of contact person"
                  name="contactPersonName"
                  value={props.vendorDetails.contactPersonName}
                  onChange={props.vendorDetailsHandleChange}
                />
              </div>
            </div>
            <div className="form-2-col mt-20">
              <div className="form-grp">
                <label htmlFor="" className="create-label">
                  Phone Number
                </label>
                <input
                  type="number"
                  className="create-input"
                  placeholder={props.memberPopup?"Enter Member phone number":"Enter Vendor phone number"}
                  name="phoneNumber"
                  value={props.vendorDetails.phoneNumber}
                  onChange={props.vendorDetailsHandleChange}
                />
              </div>
              <div className="form-grp mb-0">
                <label htmlFor="" className="create-label">
                  Email id
                </label>
                <input
                  type="email"
                  className="create-input"
                  placeholder="Enter name of contact person"
                  name="email"
                  value={props.vendorDetails.email}
                  onChange={props.vendorDetailsHandleChange}
                />
              </div>
            </div>
            <div className="line mt-20 mb-20"></div>
            <div className="form-grp">
              <label htmlFor="" className="create-label">
                Bank Name
              </label>
              <input
                type="text"
                className="create-input"
                placeholder="Enter name of the bank"
                name="bankName"
                value={props.vendorDetails.bankName}
                onChange={props.vendorDetailsHandleChange}
              />
            </div>
            <div className="form-2-col mt-20">
              <div className="form-grp">
                <label htmlFor="" className="create-label">
                  Account Number
                </label>
                <input
                  type="number"
                  className="create-input"
                  placeholder="Enter account number"
                  name="accountNumber"
                  value={props.vendorDetails.accountNumber}
                  onChange={props.vendorDetailsHandleChange}
                />
              </div>
              <div className="form-grp mb-0">
                <label htmlFor="" className="create-label">
                  IFSC Code
                </label>
                <input
                  type="text"
                  className="create-input"
                  placeholder="Enter bank IFSC code"
                  name="IfscCode"
                  value={props.vendorDetails.IfscCode}
                  onChange={props.vendorDetailsHandleChange}
                />
              </div>
            </div>
          </div>
          <div className="containerFooter">
            <div className="btnGrp flex-end">
              <button
                className="primary-btn"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVendorPopup;
