import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./UpdateFeePopupStyle.css";
import {
  feeData,
  postSubventionDetails,
  postUpdateTransactionType,
  updateFeeManually,
} from "../../../reducers/feeSlice";

import "../LoanPopup/LoanPopupStyle.css";
import ErrorToast from "../../Toast/ErrorToast";
import * as moment from "moment";

const UpdateFeePopup = ({
  show,
  handleClose,
  feeDueId,
  studentId,
  programBatchId,
  updateType,
  loanDetails,
}) => {
  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [bankName, setBankName] = useState("Eduvanz");

  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState("cashfree");
  const [amount, setAmount] = useState("");
  const [paidOn, setPaidOn] = useState("");
  const [subvention, setSubvention] = useState("");
  const [transactionNo, setTransactionNo] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const {
    transactionTypeSuccess,
    extendDueDateSuccess,
    editFeeDueSuccess,
    deleteFeeDueSuccess,
    createFeeDueSuccess,
    updateStatusSuccess,
    updateSubventionSuccess,
  } = useSelector(feeData);

  console.log("hhhdhdhdhdhdhdhd", loanDetails);

  const toggleEditMode = () => {
    setIsEditable(!isEditable);
  };

  console.log("jfjfjfjjfjfjfjfjjfjj", isEditable);

  const handleClosePopup = () => {
    handleClose();
    setAmount("");
    setSubvention("");
    setPaidOn("");
    setTransactionNo("");
    setBankName("");
    setPaymentMethod("cashfree");
    setSubvention("");
  };

  useEffect(() => {
    if (transactionTypeSuccess) {
      handleClose();
      setAmount("");
      setSubvention("");
      setPaidOn("");
      setTransactionNo("");
      setBankName("");
      setPaymentMethod("cashfree");
      setSubvention("");
    }
  }, [transactionTypeSuccess]);

  const submitAmount = (status) => {
    if (
      paymentMethod === "" ||
      amount === "" ||
      transactionNo === "" ||
      paidOn === "" ||
      (subvention === "" && paymentMethod == "bankloan")
    ) {
      setIsShowError(true);
      setErrMessage("Fill all the required fields!");
      return;
    }

    var obj = {
      isMuLoan: paymentMethod == "muLoan",
      isBankLoan: paymentMethod == "bankloan",
      isOnlinePayment: paymentMethod == "cashfree",
      isBankTransfer: paymentMethod == "banktransfer",
      isFlex: paymentMethod == "jodoFlex",
      isJodoFlex: paymentMethod == "jodoFlex",
      paidOn,
      amount,
      subvention,
      transactionNumber: transactionNo,
      studentId,
      feeDueId: feeDueId,
      programId: programBatchId,
      bankName,
      isFlexRecieved: paymentMethod == "jodoFlex" ? true : false,
      isFlexToBeRecieved: false,
    };

    console.log("dfdffdfdffd", obj);

    dispatch(updateFeeManually(obj));
  };

  const updateTransactionType = (status) => {
    if (
      paymentMethod === "" ||
      amount === "" ||
      transactionNo === "" ||
      paidOn === "" ||
      (subvention === "" && paymentMethod == "bankloan")
    ) {
      setIsShowError(true);
      setErrMessage("Fill all the required fields!");
      return;
    }
    dispatch(
      postUpdateTransactionType({
        isMuLoan: paymentMethod == "muLoan",
        isBankLoan: paymentMethod == "bankloan",
        isOnlinePayment: paymentMethod == "cashfree",
        isBankTransfer: paymentMethod == "banktransfer",
        paidOn,
        amount,
        subventionAmount: paymentMethod === "bankloan" ? subvention : 0,
        transactionNumber: transactionNo,
        studentId,
        feeDueId: feeDueId,
        programId: programBatchId,
        bankName,
        cashFreeStatus:
          paymentMethod === "bankloan" ||
          paymentMethod === "banktransfer" ||
          paymentMethod === "muLoan" ||
          paymentMethod === "jodoFlex"
            ? "pending"
            : "paid",
        isFlexRecieved: paymentMethod == "jodoFlex" ? true : false,
        isFlexToBeRecieved: false,
        isFlex: paymentMethod == "jodoFlex",
        isJodoFlex: paymentMethod == "jodoFlex",
      })
    );
  };

  const editSubventionDetails = (status) => {
    if (
      amount === "" ||
      transactionNo === "" ||
      paidOn === "" ||
      subvention === ""
    ) {
      setIsShowError(true);
      setErrMessage("Fill all the required fields!");
      return;
    }
    dispatch(
      postSubventionDetails({
        isBankLoan: true,
        paidOn,
        amount,
        subventionAmount: subvention,
        transactionNumber: transactionNo,
        studentId,
        feeDueId: feeDueId,
        programId: programBatchId,
        bankName,
        transactionId: loanDetails?.FeeTransactions[0]?.id,
        isSubvention: true,
      })
    );
  };

  useEffect(() => {
    if (updateType === "editFee") {
      if (loanDetails !== null && loanDetails !== undefined) {
        setAmount(loanDetails?.FeeTransactions[0]?.amount);
        setPaidOn(
          moment(loanDetails?.FeeTransactions[0]?.paidOn).format("YYYY-MM-DD")
        );
        setTransactionNo(loanDetails?.FeeTransactions[0]?.transactionNumber);

        if (loanDetails?.cashFreeStatus === "paid") {
          setPaymentMethod("cashfree");
        } else if (loanDetails?.isBankTransfer) {
          setPaymentMethod("banktransfer");
        } else if (loanDetails?.isMuLoan) {
          setPaymentMethod("muLoan");
        } else if (loanDetails?.isFlex) {
          setPaymentMethod("jodoFlex");
        }

        if (loanDetails?.isBankLoan) {
          setPaymentMethod("bankloan");
          setBankName(loanDetails?.bankName);
          setSubvention(loanDetails?.FeeTransactions[0]?.subventionAmount);
        }
      }
    }

    if (updateType === "loanDetail" || updateType === "viewLoanDetail") {
      if (loanDetails !== null && loanDetails !== undefined) {
        setAmount(loanDetails?.FeeTransactions[0]?.amount);
        setSubvention(loanDetails?.FeeTransactions[0]?.subventionAmount);
        setPaidOn(
          moment(loanDetails?.FeeTransactions[0]?.paidOn).format("YYYY-MM-DD")
        );
        setTransactionNo(loanDetails?.FeeTransactions[0]?.transactionNumber);
        setBankName(loanDetails?.bankName);
      }
    }
  }, [updateType]);

  return (
    <>
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div
        className={`update-fees-popuop popup-outer ${show ? "show-popup" : ""}`}
      >
        <div className="popup-container loan-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Update Fee
              <img
                onClick={() => {
                  handleClosePopup();
                }}
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
              />
            </div>
          </div>
          <div className="popup-body loan-popup-body ">
            <div className="loan-step-content scrollbar">
              <div className="border-box">
                {(updateType === "updateFee" || updateType === "editFee") && (
                  <>
                    <div className="loan-options">
                      <div className="optionbar_fee">
                        <div className="form-grp">
                          <label htmlFor="partner">Select Payment Method</label>

                          <label
                            className={"create-label active"}
                            htmlFor="cashfree"
                          >
                            <input
                              className="radio-btn"
                              type="radio"
                              value="cashfree"
                              onChange={(e) => setPaymentMethod(e.target.value)}
                              checked={paymentMethod === "cashfree"}
                              name="semester"
                              id="cashfree"
                            />
                            <span className="radiomark" />
                            Cash Free / UPI
                          </label>

                          <label
                            className={"create-label active"}
                            htmlFor="banktransfer"
                          >
                            <input
                              className="radio-btn"
                              type="radio"
                              value="banktransfer"
                              name="semester"
                              id="banktransfer"
                              onChange={(e) => setPaymentMethod(e.target.value)}
                              checked={paymentMethod === "banktransfer"}
                            />
                            <span className="radiomark" />
                            Bank Transfer <h5>(NEFT / IMPS / RTGS)</h5>
                          </label>

                          <label
                            className={"create-label active"}
                            htmlFor="bankloan"
                          >
                            <input
                              className="radio-btn"
                              type="radio"
                              value="bankloan"
                              name="semester"
                              id="bankloan"
                              onChange={(e) => setPaymentMethod(e.target.value)}
                              checked={paymentMethod === "bankloan"}
                            />
                            <span className="radiomark" />
                            Bank Loan
                          </label>

                          <label
                            className={"create-label active"}
                            htmlFor="jodoFlex"
                          >
                            <input
                              className="radio-btn"
                              type="radio"
                              value="jodoFlex"
                              name="semester"
                              id="jodoFlex"
                              onChange={(e) => setPaymentMethod(e.target.value)}
                              checked={paymentMethod === "jodoFlex"}
                            />
                            <span className="radiomark" />
                            Jodo Flex
                          </label>

                          {/* <select
                            name="partner"
                            id="partner"
                            value={paymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        >   
                            <option value="cashFree">Cash Free</option>
                            <option value="bankTransfer">Bank Transfer</option>
                            <option value="bankLoan">Bank Loan</option>
                        </select> */}
                        </div>
                      </div>

                      <div className="option_fields">
                        <div className="form-grp">
                          <label htmlFor="amount">Enter Amount</label>
                          <input
                            type="number"
                            name="amount"
                            id="amount"
                            placeholder="Enter amount"
                            className="create-input"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                        <div className="form-grp mt-20">
                          <label htmlFor="amount">Enter Date</label>
                          <input
                            type="date"
                            name="paidOn"
                            id="paidOn"
                            placeholder="Enter paid on date"
                            className="create-input"
                            value={paidOn}
                            onChange={(e) => setPaidOn(e.target.value)}
                          />
                        </div>
                        {paymentMethod == "bankloan" && (
                          <>
                            <div className="form-grp mt-10">
                              <label htmlFor="partner">Bank partners</label>
                              <select
                                name="partner"
                                id="partner"
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}
                              >
                                <option value="Eduvanz">Eduvanz</option>
                                <option value="Jodo">Jodo</option>
                                <option value="PropellD">PropellD</option>
                                <option value="Liquiloans">Liquiloans</option>
                                <option value="GrayQuest">GrayQuest</option>
                                <option value="IDFC First">IDFC First</option>
                                <option value="HDFC Credila">HDFC Credila</option>
                              </select>
                            </div>

                            <div className="form-grp mt-20">
                              <label htmlFor="amount">
                                Enter Subvention amount
                              </label>
                              <input
                                type="number"
                                name="Subvention"
                                id="Subvention"
                                placeholder="Enter Subvention Amount"
                                className="create-input"
                                value={subvention}
                                onChange={(e) => setSubvention(e.target.value)}
                              />
                            </div>
                          </>
                        )}
                        <div className="form-grp mt-20">
                          <label htmlFor="amount">
                            Enter Transaction Number
                          </label>
                          <input
                            type="text"
                            name="transaction"
                            id="transaction"
                            placeholder="Enter Trasaction Number"
                            className="create-input"
                            value={transactionNo}
                            onChange={(e) => setTransactionNo(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn-grp">
                      {updateType === "editFee" && (
                        <button
                          className="accept-btn"
                          onClick={() => updateTransactionType("Approved")}
                        >
                          <img src="../../assets/icons/whiteTick.svg" alt="" />
                          Update Details
                        </button>
                      )}

                      {updateType === "updateFee" && (
                        <button
                          className="accept-btn"
                          onClick={() => submitAmount("Approved")}
                        >
                          <img src="../../assets/icons/whiteTick.svg" alt="" />
                          Submit
                        </button>
                      )}
                    </div>
                  </>
                )}

                {(updateType === "loanDetail" ||
                  updateType === "viewLoanDetail") && (
                  <>
                    <div className="loan-options">
                      <div className="option_fields">
                        {updateType === "loanDetail" && (
                          <div className="toggleOuter">
                            <p>Edit Details</p>
                            <div
                              onClick={toggleEditMode}
                              className={`toggler ${
                                isEditable ? "active" : ""
                              }`}
                            >
                              <div className="toggleCircle"></div>
                            </div>
                          </div>
                        )}

                        {/* <button onClick={toggleEditMode}>Edit detail</button> */}

                        <div className="form-grp mt-10">
                          <label htmlFor="partner">Bank partners</label>
                          <select
                            name="partner"
                            id="partner"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                          >
                            <option value="Eduvanz">Eduvanz</option>
                            <option value="Jodo">Jodo</option>
                            <option value="PropellD">PropellD</option>
                            <option value="Liquiloans">Liquiloans</option>
                            <option value="GrayQuest">GrayQuest</option>
                            <option value="IDFC First">IDFC First</option>
                            <option value="HDFC Credila">HDFC Credila</option>
                          </select>
                        </div>

                        <div className="form-grp mt-20">
                          <label htmlFor="amount">Enter Amount</label>
                          <input
                            type="number"
                            name="amount"
                            id="amount"
                            placeholder="Enter amount"
                            className="create-input"
                            disabled={!isEditable}
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                        <div className="form-grp mt-20">
                          <label htmlFor="amount">Enter Date</label>
                          <input
                            type="date"
                            name="paidOn"
                            id="paidOn"
                            placeholder="Enter paid on date"
                            className="create-input"
                            disabled={!isEditable}
                            value={paidOn}
                            onChange={(e) => setPaidOn(e.target.value)}
                          />
                        </div>
                        <div className="form-grp mt-20">
                          <label htmlFor="amount">
                            Enter Subvention amount
                          </label>
                          <input
                            type="number"
                            name="Subvention"
                            id="Subvention"
                            placeholder="Enter Subvention Amount"
                            className="create-input"
                            disabled={!isEditable}
                            value={subvention}
                            onChange={(e) => setSubvention(e.target.value)}
                          />
                        </div>
                        <div className="form-grp mt-20">
                          <label htmlFor="amount">
                            Enter Transaction Number
                          </label>
                          <input
                            type="text"
                            name="transaction"
                            id="transaction"
                            placeholder="Enter Trasaction Number"
                            disabled={!isEditable}
                            value={transactionNo}
                            className="create-input"
                            onChange={(e) => setTransactionNo(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    {isEditable && (
                      <div className="btn-grp">
                        <button
                          className="accept-btn"
                          onClick={() => editSubventionDetails("Approved")}
                        >
                          <img src="../../assets/icons/whiteTick.svg" alt="" />
                          Update Details
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <div className="popup-footer">
            <button className="green-btn">Save</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default UpdateFeePopup;
