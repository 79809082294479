import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  applyForLoan,
  approveLoanRequest,
  getStudentLoanDocuments,
  updateBankResponse,
} from "../../../reducers/feeSlice";
import toast from "react-hot-toast";

import "./LoanPopupStyle.css";
import ErrorToast from "../../Toast/ErrorToast";

const LoanPopup = ({ show, handleClose, feeDueData, studentId }) => {
  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [urls, setUrls] = useState([]);

  // const dispatch = useDispatch();
  // const [bankName, setBankName] = useState("State Bank of India");
  // const [loanAmt, setLoanAmt] = useState('');

  // const updateResStatus = (status) => {
  //   dispatch(approveLoanRequest({ feeDueId: feeDueData.id, isLoanApproved: status, studentId }));
  // }
  // console.log("in loanPopup feedue0", feeDueData);

  // const onSendLoan = (status) => {
  //   console.log(bankName);
  //   console.log(loanAmt);
  //   if (bankName === '' || loanAmt === '') {
  //     // toast.error("Fill all the required fields!");
  //     setIsShowError(true);
  //     setErrMessage("Fill all the required fields!");
  //     return;
  //   }
  //   dispatch(updateBankResponse({ bankName, loanAmt, feeDueId: feeDueData.id, studentId ,status}));
  // }

  useEffect(() => {
    var filteredArray = [];

    if (feeDueData?.LoanApproves[0]) {
      Object.keys(feeDueData?.LoanApproves[0]).map(function (key) {
        if (
          key === "applicantAadharCard" ||
          key === "applicantPassportPhoto" ||
          key === "applicantPanCard" ||
          key === "applicant3MonthsSalarySlips" ||
          key === "applicant3MonthsBankStatement" ||
          key === "markSheet10" ||
          key === "markSheet12" ||
          key === "graduationMarksheet" ||
          key === "feeStructure" ||
          key === "feeReciepts" ||
          key === "admissionLetter" ||
          key === "applicant6MonthsBankStatement" ||
          key === "postGraduationMarksheet" ||
          key === "entranceExamScoreCard" ||
          key === "coApplicantAadharCard" ||
          key === "coApplicantPanCard" ||
          key === "coApplicantPresentAddressProof" ||
          key === "coApplicantPermanantAddressProof" ||
          key === "coApplicantElectricityBill" ||
          key === "coApplicantLatest2MonthForm16" ||
          key === "coApplicant2YearITR" ||
          key === "coApplicantGSTCertificate" ||
          key === "coApplicantsFirmRegistrationProof"
        ) {
          if (
            feeDueData?.LoanApproves[0][key] !== null &&
            feeDueData?.LoanApproves[0][key] !== ""
          ) {
            filteredArray.push(feeDueData?.LoanApproves[0][key]);
          }
        }
      });

      setUrls(filteredArray);
    }
  }, [feeDueData?.LoanApproves[0]]);

  const handleDownload = () => {
    for (var i = 0; i < urls.length; i++) {
      forceDownload(
        urls[i],
        urls[i].substring(urls[i].lastIndexOf("/") + 1, urls[i].length)
      );
    }
  };

  const forceDownload = (url, fileName) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  };

  return (
    <>
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className={`popup-outer ${show ? "show-popup" : ""}`}>
        <div className="popup-container loan-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Loan Details
              <img
                onClick={() => {
                  handleClose();
                }}
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
              />
            </div>
          </div>
          <div className="popup-body loan-popup-body ">
            <div className="loan-steps-outer">
              <div className="loan-step active">
                <p className="number">1</p>
                <p className="text">Request response</p>
              </div>
              <div className={`loan-step active`}>
                <p className="number">2</p>
                <p className="text">Documents</p>
              </div>
              {/* <div className={`loan-step ${(feeDueData?.LoanApproves[0]?.isDocumentStatus === 'Approved' && feeDueData?.LoanApproves[0]?.isRequestResponseStatus === 'Approved') ? 'active' : ''}`}>
                <p className="number">3</p>
                <p className="text">Request for Loan</p>
              </div> */}
              {/* <div className={`loan-step ${feeDueData?.LoanApproves[0]?.isLoanApprovalResponse ? 'active' : ''}`}> */}
              {/* <div className={`loan-step active`}>
                <p className="number">3</p>
                <p className="text">Loan Response</p>
              </div> */}
            </div>
            <div className="loan-step-content scrollbar">
              <div className="border-box">
                <div className="box-header">
                  <div className="box-left">
                    <p className="heading">
                      Loan Amount{" "}
                      <span className="text-success">
                        ₹ {feeDueData?.feeDue}
                      </span>
                    </p>
                    {/* <p className="text">Student send Request for Loan</p> */}
                  </div>
                  {/* <div className="box-right">
                    {feeDueData?.LoanApproves[0]?.isRequestResponseStatus === 'Pending' && (<>
                      <button className="reject-btn" onClick={() => updateResStatus(false)}>
                        <img src="../../assets/icons/redCross.svg" alt="" />{" "}
                        Reject
                      </button>
                      <button className="accept-btn" onClick={() => updateResStatus(true)}>
                        <img src="../../assets/icons/whiteTick.svg" alt="" />
                        Approve
                      </button>
                    </>)}
                    {feeDueData?.LoanApproves[0]?.isRequestResponseStatus === 'Approved' && (<div className="approved-tag">Approved</div>)}
                    {feeDueData?.LoanApproves[0]?.isRequestResponseStatus === 'Rejected' && (<div className="pending-tag">Rejected</div>)}
                  </div> */}
                </div>
                {/* <div className="box-body">{feeDueData?.loanMessage}</div> */}
              </div>

              <div className="border-box">
                <div className="box-header">
                  <div className="box-left">
                    <p className="heading">Loan Application Form</p>
                    {/* <p className="text">Student send Request for Loan</p> */}
                  </div>
                  {/* <div className="box-right">
                    <div className="approved-tag">Approved</div>
                  </div> */}
                </div>
                <div className="box-application-body">
                  <div className="application-detail">
                    <p className="label">Name</p>
                    <p className="info">
                      {feeDueData?.LoanApproves[0]?.applicantName}
                    </p>
                  </div>
                  <div className="application-detail">
                    <p className="label">Date of Birth</p>
                    <p className="info">{feeDueData?.LoanApproves[0]?.Dob}</p>
                  </div>
                  <div className="application-detail">
                    <p className="label">Email Id</p>
                    <p className="info">
                      {feeDueData?.LoanApproves[0]?.applicantEmail}
                    </p>
                  </div>
                  <div className="application-detail">
                    <p className="label">Mobile Number</p>
                    <p className="info">
                      {feeDueData?.LoanApproves[0]?.phoneNumber}
                    </p>
                  </div>
                </div>
                <div className="box-application-body">
                  <div className="application-detail w-100">
                    <p className="label">Permanent Address</p>
                    <p className="info">
                      {feeDueData?.LoanApproves[0]?.applicantAddress}
                    </p>
                  </div>
                </div>
                <div className="box-application-body">
                  <div className="application-detail">
                    <p className="label">Employment Status</p>
                    <p className="info">
                      {feeDueData?.LoanApproves[0]?.applicantEmployment}
                    </p>
                  </div>
                  <div className="application-detail">
                    <p className="label">Company Name</p>
                    <p className="info">
                      {feeDueData?.LoanApproves[0]?.applicantCompanyName}
                    </p>
                  </div>
                  <div className="application-detail">
                    <p className="label">Loan Tenure</p>
                    <p className="info">
                      {feeDueData?.LoanApproves[0]?.loanTenure} Months
                    </p>
                  </div>

                  <div className="application-detail">
                    <p className="label">Experience</p>
                    <p className="info">
                      {feeDueData?.LoanApproves[0]?.experience}
                    </p>
                  </div>
                </div>
              </div>

              {
                <div className="border-box">
                  <div className="box-header">
                    <div className="box-left">
                      <p className="heading">Documents</p>
                      <p className="text">
                        Need documents to apply For Bank Loan
                      </p>
                    </div>
                    <div className="box-right">
                      {/* {(!feeDueData?.LoanApproves[0]?.isDocumentResponse && !feeDueData?.LoanApproves[0]?.isDocumentSkipped) && (<>
                      <button className="green-btn"
                        onClick={() => dispatch(getStudentLoanDocuments({ feeDueId: feeDueData.id, isDocumentResponse: true }))}>
                        Ask Student for Doument
                      </button>
                      <button className="light-skip-btn"
                        onClick={() => dispatch(getStudentLoanDocuments({ feeDueId: feeDueData.id, isDocumentResponse: false }))}>
                        skip</button>
                    </>)} */}
                      {feeDueData?.LoanApproves[0]?.isDocumentStatus ===
                        "Approved" && (
                        <div className="approved-tag">Received</div>
                      )}
                      {feeDueData?.LoanApproves[0]?.isDocumentStatus ===
                        "Pending" && <div className="pending-tag">Pending</div>}

                      <img
                        src="../../assets/icons/downloadBtn.svg"
                        alt=""
                        className="download-btn"
                        onClick={handleDownload}
                      />

                      {/* {feeDueData?.LoanApproves[0]?.isDocumentSkipped && (<div className="pending-tag">Skipped</div>)} */}
                    </div>
                  </div>
                  {feeDueData?.LoanApproves[0]?.isDocumentStatus ===
                    "Approved" && (
                    <div className="box-upload-outer">
                      {feeDueData?.LoanApproves[0]?.applicantPassportPhoto !==
                        null &&
                        feeDueData?.LoanApproves[0]?.applicantPassportPhoto !==
                          "" && (
                          <div className="box-upload-box">
                            <div className="box-upload-content">
                              <img src="../../assets/img/csv-png.png" alt="" />
                              <div className="box-upload-text">
                                <p className="file-name">Passport.png</p>
                                {/* <p className="file-size">3 MB Doc</p> */}
                              </div>
                            </div>
                            <img
                              src="../../assets/icons/downloadBtn.svg"
                              alt=""
                              className="download-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  feeDueData?.LoanApproves[0]
                                    ?.applicantPassportPhoto
                                );
                              }}
                            />
                          </div>
                        )}
                      {feeDueData?.LoanApproves[0]?.applicantPanCard !== null &&
                        feeDueData?.LoanApproves[0]?.applicantPanCard !==
                          "" && (
                          <div className="box-upload-box">
                            <div className="box-upload-content">
                              <img src="../../assets/img/csv-png.png" alt="" />
                              <div className="box-upload-text">
                                <p className="file-name">PanCard.csv</p>
                                {/* <p className="file-size">3 MB Doc</p> */}
                              </div>
                            </div>
                            <img
                              src="../../assets/icons/downloadBtn.svg"
                              alt=""
                              className="download-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  feeDueData?.LoanApproves[0]?.applicantPanCard
                                );
                              }}
                            />
                          </div>
                        )}
                      {feeDueData?.LoanApproves[0]?.applicantAadharCard !==
                        null &&
                        feeDueData?.LoanApproves[0]?.applicantAadharCard !==
                          "" && (
                          <div className="box-upload-box">
                            <div className="box-upload-content">
                              <img src="../../assets/img/csv-png.png" alt="" />
                              <div className="box-upload-text">
                                <p className="file-name">AadharCard.csv</p>
                                {/* <p className="file-size">3 MB Doc</p> */}
                              </div>
                            </div>
                            <img
                              src="../../assets/icons/downloadBtn.svg"
                              alt=""
                              className="download-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  feeDueData?.LoanApproves[0]
                                    ?.applicantAadharCard
                                );
                              }}
                            />
                          </div>
                        )}

                      {feeDueData?.LoanApproves[0]
                        ?.applicant3MonthsBankStatement !== null &&
                        feeDueData?.LoanApproves[0]
                          ?.applicant3MonthsBankStatement !== "" && (
                          <div className="box-upload-box">
                            <div className="box-upload-content">
                              <img src="../../assets/img/csv-png.png" alt="" />
                              <div className="box-upload-text">
                                <p className="file-name">
                                  3MonthsBankStatement.csv
                                </p>
                                {/* <p className="file-size">3 MB Doc</p> */}
                              </div>
                            </div>
                            <img
                              src="../../assets/icons/downloadBtn.svg"
                              alt=""
                              className="download-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  feeDueData?.LoanApproves[0]
                                    ?.applicant3MonthsBankStatement
                                );
                              }}
                            />
                          </div>
                        )}
                      {feeDueData?.LoanApproves[0]
                        ?.applicant3MonthsSalarySlips !== null &&
                        feeDueData?.LoanApproves[0]
                          ?.applicant3MonthsSalarySlips !== "" && (
                          <div className="box-upload-box">
                            <div className="box-upload-content">
                              <img src="../../assets/img/csv-png.png" alt="" />
                              <div className="box-upload-text">
                                <p className="file-name">
                                  3MonthsSalarySlips.csv
                                </p>
                                {/* <p className="file-size">3 MB Doc</p> */}
                              </div>
                            </div>
                            <img
                              src="../../assets/icons/downloadBtn.svg"
                              alt=""
                              className="download-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  feeDueData?.LoanApproves[0]
                                    ?.applicant3MonthsSalarySlips
                                );
                              }}
                            />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              }
              {/* {(feeDueData?.LoanApproves[0]?.isDocumentStatus === 'Approved' && feeDueData?.LoanApproves[0]?.isRequestResponseStatus === 'Approved') && (
                <div className="border-box">
                  <div className="box-header">
                    <div className="box-left">
                      <p className="heading">Request for Loan</p>
                      <p className="text">
                        Send request for Loan to Loan partners
                      </p>
                    </div>
                    <div className="box-right">
                      {!feeDueData?.LoanApproves[0]?.isLoanApprovalResponse ? (<button className="green-btn"
                        onClick={() => dispatch(applyForLoan(feeDueData.id))}>
                        Apply for Loan
                      </button>)
                        : (<div className="approved-tag">Request Sent</div>)}
                    </div>
                  </div>
                </div>)} */}
            </div>
          </div>
          {/* <div className="popup-footer">
            <button className="green-btn">Save</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LoanPopup;
