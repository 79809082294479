import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import {
  clearComplete,
  clearState,
  deleteFeeDetails,
  feeData,
  getProgramFeeDetails,
} from "../../../reducers/feeSlice";
import EditFeePopup from "../../../components/Popups/EditFeePopup/EditFeePopup";
import "./feePreviewStyle.css";
import PageLoader from "../../../components/UI/loader/PageLoader";

const FeePreview = (props) => {
  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);

  const navigate = useNavigate();
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [feeItem, setFeeItem] = useState(null);
  const [isActionPoppOpen, setIsActionPopupOpen] = useState({
    isActive: false,
    id: null,
  });
  const { isFetching, isComplete, isError, feeDetailsData } =
    useSelector(feeData);

  const [feeDetail, setFeeDetail] = useState({});
  const [feeList, setFeeList] = useState([]);
  const dispatch = useDispatch();
  console.log("printing props", props);
  const [searchParams] = useSearchParams();
  console.log("adsdsdsad", searchParams);
  const programMasterId = searchParams.get("programMasterId");
  const programBatchId = searchParams.get("programBatchId");

  const onAddFee = () => {
    setFeeItem(null);
    toggleEditPopup();
  };
  const onEditFee = (data) => {
    setFeeItem(data);
    toggleEditPopup();
  };

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  const onDeleteFee = (id) => {
    dispatch(deleteFeeDetails({ feeId: id }));
  };

  const toggleEditPopup = () => {
    setIsEditPopupOpen((prev) => !prev);
    setIsActionPopupOpen({});
  };

  useEffect(() => {
    try {
      let object = {
        programMasterId: programMasterId,
        programBatchId: programBatchId,
      };

      dispatch(getProgramFeeDetails(object));
    } catch (error) {}
  }, [programMasterId]);

  useEffect(() => {
    if (isComplete) {
      setIsEditPopupOpen(false);
      setIsActionPopupOpen({});
      let object = {
        programMasterId: programMasterId,
        programBatchId: programBatchId,
      };
      dispatch(getProgramFeeDetails(object));
      dispatch(clearComplete());
    }
  }, [isComplete]);

  useEffect(() => {
    if (
      feeDetailsData == null ||
      feeDetailsData == undefined ||
      feeDetailsData == "null"
    ) {
      console.log("inside if ");
    } else {
      console.log("inside elese");
      setFeeDetail(feeDetailsData);
      setFeeList(feeDetailsData && feeDetailsData.Fees);
    }
  }, [feeDetailsData]);
  const [isFeeToggle, setIsFeeToggle] = useState(-1);

  // const toggleViewMore = (id) => {
  //   setIsFeeToggle(!)

  // };

  console.log("fsfsfsfsf", isFeeToggle);
  console.log("Fdfdfdfdfd", feeDetail);

  const convertDueDate = (date) => {
    var dueDate = moment(date).format("ll");
    return dueDate;
  };

  useEffect(() => {
    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
  }, []);

  return (
    <>
      <SideMenu />
      {isFetching && <PageLoader />}
      <EditFeePopup
        handleToggle={toggleEditPopup}
        show={isEditPopupOpen}
        feeItem={feeItem}
      />
      <div className="main-body">
        <div className="page-header">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="component-back"
          >
            <img src="../assets/icons/back-btn.svg" />
            Back to page
          </button>
          {!viewOnlyAccess && (
            <button className="primary-btn feed-btn" onClick={onAddFee}>
              <img src="../assets/icons/plus.svg" alt="" /> Add Fee
            </button>
          )}
        </div>
        <div className="preview-container scrollbar fee">
          <div className="main-container fee-container">
            <div className="club-header">
              <div className="header-top">
                <img
                  src="https://img.freepik.com/free-vector/smooth-mesh-blurred-background-multi-color-gradient-pattern-smooth- modern-watercolor-style-backdrop_124894-147.jpg?size=626&amp;ext=jpg&amp;ga=GA1.2.1292351815.1642896000"
                  alt=""
                />
              </div>
              <div className="header-bottom">
                <div className="header-content">
                  <h3 className="heading-4 mb-5">
                    {feeDetail?.Program?.programName}
                  </h3>
                  <p className="club-desc">
                    {feeDetail && feeDetail.name} &#9679;{" "}
                    {feeDetail && feeDetail.durationInMonths} months
                  </p>
                </div>
                {/* <div className="student-inner-right bg-grey">
                  <div className="fee-box total">
                    <p>Total Dues</p>
                    <p className="text-dark">₹ 60,00,000</p>
                  </div>
                  <div className="fee-box">
                    <p>Received</p>
                    <p className="text-success">₹ 9,59,000</p>
                  </div>
                  <div className="fee-box">
                    <p>Pending</p>
                    <p className="text-danger">₹ 50,41,000</p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="fee-body">
              {feeList &&
                feeList.map((item, index) => (
                  <div className="fee-box-outer">
                    <div className="fee-box fee-info">
                      <div className="fee-box-left">
                        <div className="fee-type">
                          <p className="fee-type-text">
                            {item.FeeMaster.feeName}
                          </p>

                          <p className="text-dark">₹ {item.amount} + GST/-</p>
                        </div>
                      </div>
                      <div className="fee-box-right">
                        {!viewOnlyAccess && (
                          <button
                            onClick={() => onEditFee(item)}
                            className="dark-btn-a"
                          >
                            <img
                              src="../assets/icons/white_pencil_icon.svg"
                              alt=""
                            />
                            Edit Fee
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="installment-section">
                      <div className="installment-box">
                        <div className="installement-table-outer">
                          <table className="installment-table">
                            <thead>
                              <tr>
                                <td>TAX</td>
                                <td>Percentage %</td>
                              </tr>
                            </thead>
                            <tbody>
                              {item.FeeTaxes.map((taxesObject, taxesIndex) => (
                                <tr key={taxesIndex}>
                                  <td>{taxesObject.taxType}</td>
                                  <td>{taxesObject.percent}%</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* <div className="installment-btn-grp">
                      <button 
                        onClick={() =>
                          feeDueClick(
                            item.programMasterId,
                            item.programId,
                            item.feeMasterId,
                            item.id
                          )
                        }
                        className="dark-btn-a"
                      >
                        View Details
                      </button>
                    </div> */}
                  </div>

                  // <div
                  //   key={index}
                  //   className={`fee-box-outer ${
                  //     isFeeToggle === index ? "active" : ""
                  //   }`}
                  // >
                  //   <div className="fee-box">
                  //     <div className="fee-box-left">
                  //       <div className="fee-type">
                  //         <p className="fee-type-text">
                  //           {item.FeeMaster.feeName}
                  //         </p>
                  //       </div>
                  //       <div className="fee-amount">
                  //         <img src="../../assets/icons/rupee.svg" alt="" />
                  //         <div className="fee-amount-text">{item.amount}</div>
                  //       </div>
                  //       {/* <div className="due-date">
                  //         <img src="../../assets/icons/due-date.svg" alt="" />
                  //         <p className="due-date-text">Tue, 9 Feb, 2021</p>
                  //       </div> */}
                  //     </div>
                  //     <div className="fee-box-right">
                  //       <button
                  //         onClick={() =>
                  //           feeDueClick(
                  //             item.programMasterId,
                  //             item.programId,
                  //             item.feeMasterId,
                  //             item.id
                  //           )
                  //         }
                  //         className="dark-btn-a"
                  //       >
                  //         Student List
                  //       </button>
                  //       {isFeeToggle === index ? (
                  //         <button
                  //           className="light-btn-a"
                  //           onClick={() => setIsFeeToggle(-1)}
                  //         >
                  //           View Less
                  //           <img
                  //             src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                  //             alt=""
                  //             className="rotate-img"
                  //           />
                  //         </button>
                  //       ) : (
                  //         <button
                  //           className="light-btn-a"
                  //           onClick={() => setIsFeeToggle(index)}
                  //         >
                  //           View More
                  //           <img
                  //             src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
                  //             alt=""
                  //           />
                  //         </button>
                  //       )}

                  //       <button
                  //         className="action-btn"
                  //         onClick={() =>
                  //           setIsActionPopupOpen({
                  //             isActive: !isActionPoppOpen.isActive,
                  //             id: index,
                  //           })
                  //         }
                  //         onBlur={() =>
                  //           setIsActionPopupOpen({
                  //             isActive: false,
                  //             id: index,
                  //           })
                  //         }
                  //       >
                  //         <img src="../assets/icons/options.svg" />
                  //       </button>
                  //     </div>
                  //     {index === isActionPoppOpen.id && (
                  //       <ul
                  //         className={`action-dropdown ${
                  //           isActionPoppOpen.isActive ? "active" : ""
                  //         }`}
                  //       >
                  //         <li
                  //           className="dropdown-item"
                  //           onClick={() => onEditFee(item)}
                  //         >
                  //           <img src="../assets/icons/edit.svg" alt="" />
                  //           <p className="dropdown-text">Edit</p>
                  //         </li>
                  //         <li
                  //           className="dropdown-item"
                  //           onClick={() => onDeleteFee(item.id)}
                  //         >
                  //           <img src="../assets/icons/delete.svg" alt="" />
                  //           <p className="dropdown-text text-danger">Delete</p>
                  //         </li>
                  //       </ul>
                  //     )}
                  //   </div>
                  //   <div className="installment-section">
                  //     <div className="installment-box">
                  //       <div className="installment-heading">Tax</div>
                  //       <div className="installement-table-outer">
                  //         <table className="installment-table">
                  //           <thead>
                  //             <tr>
                  //               <td>Name</td>
                  //               <td>Percentage %</td>
                  //             </tr>
                  //           </thead>
                  //           <tbody>
                  //             {item.FeeTaxes.map((taxesObject, taxesIndex) => (
                  //               <tr key={taxesIndex}>
                  //                 <td>{taxesObject.taxType}</td>
                  //                 <td>{taxesObject.percent}%</td>
                  //               </tr>
                  //             ))}
                  //           </tbody>
                  //         </table>
                  //       </div>
                  //     </div>
                  //     {/* ))} */}

                  //   </div>
                  // </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeePreview;
