import React, { useEffect, useState } from 'react'
import './FinanceInvoicePopoupStyle.css'
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { rejectExpense, approveExpense, processExpense, clearState } from '../../../reducers/expenseSlice';
import ErrorToast from "../../../components/Toast/ErrorToast";



const FinanceInvoicePopoup = (props) => {
    const dispatch = useDispatch()
    const [releaseDate, setReleaseDate] = useState('')
    const [isShowError, setIsShowError] = useState(false);

    const [utrNumber, setUTRNumber] = useState('');
    const [paidAmount, setPaidAmount] = useState('');
    const [difference, setDifference] = useState('');
    const [reasonDiff, setReasonDiff] = useState('');

    console.log(props.data);
    let errMessage = "Please fill all required fields"

    const handleClick = (id, name = "") => {
        console.log("name", name);
        if (name == "reject") { dispatch(rejectExpense(id)) }
        else if (name == "accept") {
            dispatch(approveExpense(id))
        }
        else {
            if (releaseDate != "" && releaseDate != null && utrNumber != '' && utrNumber != null && paidAmount != '' && paidAmount != null) {
                let obj = { expenseId: id, releaseDate: releaseDate, utrNumber, amountPaid: paidAmount, differenceInAmt: difference, reasonForDifference: reasonDiff }
                dispatch(processExpense(obj))
            }
            else {

                setIsShowError(true)
            }
        }

    }

    useEffect(() => {
        setUTRNumber(props.data?.utrNumber ?? '');
        setPaidAmount(props.data?.amountPaid ?? '');
        setDifference(props.data?.differenceInAmt ?? '');
        setReasonDiff(props.data?.reasonForDifference ?? '');
    }, [props.data]);

    return (
        <>
            <ErrorToast
                show={isShowError}
                setShow={() => setIsShowError(false)}
                message={errMessage}
            />
            <div className={`popup-outer ${props.show ? 'show-popup' : ''}`}>
                <div className="popup-container financeInvoice">
                    <div className="popup-header">
                        <div className="popup-heading">
                            View Details
                            <img
                                src="../assets/icons/close.svg"
                                alt=""
                                className="close-btn"
                                onClick={props.handleClose}
                            />
                        </div>
                    </div>
                    <div className="popup-body financePopupBody scrollbar">
                        <div className="popupLogoHeader">
                            <img src="../assets/logo/main-logo.png" alt="" />
                            <div className="btnGrp">
                                {props.authority === "admin" && props.data?.status === "Pending" && <button className="reject-btn" onClick={() => handleClick(props.data?.id, "reject")}>
                                    <img src="../../assets/icons/redCross.svg" alt="" />{" "}
                                    <img src="../../assets/icons/whiteCross.svg" alt="" />{" "}
                                    Reject
                                </button>}
                                {/* <button className="primary-outline-btn-a" onClick={() => { props.handleAskDetailsClose(); props.handleClose() }}>Ask for more details</button> */}
                                {props.authority === "finance" && (!props.data?.isProcessed ? (<button className="success-btn" onClick={() => handleClick(props.data?.id)}> <img src="../assets/icons/tickmark.svg" alt="" /> Process payment</button>) : (<p className="success-tag">Processed</p>))}
                                {props.authority === "admin" && (props.data?.status === "Pending" ? (<button className="success-btn" onClick={() => handleClick(props.data?.id, "accept")}> <img src="../assets/icons/tickmark.svg" alt="" /> Approve</button>) : (props.data.status === "Completed" ? (<p className="success-tag">Approved</p>) : (<p className="tag-expired">Rejected</p>)))}
                            </div>
                        </div>
                        <div className="ticktInfoOuter">
                            <div className="ticktInfoBox">
                                <p className="title">Ticket Id:</p>
                                <p className="value">{'INVOICE-' + props.data?.ticketId}</p>
                            </div>
                            <div className="ticktInfoBox">
                                <p className="title">Raised For:</p>
                                <p className="value">{props.data?.purpose == "Acad Ops/Student Experience" ? "Student Experience": props.data?.purpose}</p>
                            </div>
                            <div className="ticktInfoBox">
                                <p className="title">Bill to:</p>
                                <p className="value">{props.data?.VendorDetail?.vendorName ?? props.data?.MemberDetail?.memberName}</p>
                                <p className="value-2">+91-{props.data?.VendorDetail?.phoneNumber ?? props.data?.MemberDetail?.phoneNumber}</p>

                            </div>
                            <div className="ticktInfoBox">
                                <p className="title">Expense For:</p>
                                <p className="value">{props.data?.Program?.programName ?? '-'}</p>
                            </div>
                            <div className="ticktInfoBox">
                                <p className="title">Invoice Type:</p>
                                <p className="value">{props.data?.invoiceType} Payment</p>
                            </div>
                            <div className="ticktInfoBox">
                                <p className="title">Contact Person:</p>
                                <p className="value">{props.data?.VendorDetail?.contactPersonName ?? props.data?.MemberDetail?.contactPersonName}</p>
                            </div>

                        </div>
                        <div className="form-2-col">
                            <div className="form-group">
                                <label htmlFor="name" className="create-label">
                                    Expense date <span className="imp-mark">*</span>
                                </label>
                                <input type="date" value={moment(props.data?.expenseDate).format("YYYY-MM-DD")} onChange={() => { console.log("") }} className="create-input mt-5" disabled />
                                <span className="error-msg">Enter the field</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name" className="create-label">
                                    Due date<span className="imp-mark">*</span>
                                </label>
                                <input type="date" className="create-input mt-5" value={moment(props.data?.dueDate).format("YYYY-MM-DD")} onChange={() => { console.log("") }} disabled />
                                <span className="error-msg">Enter the field</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name" className="create-label">
                                    Release date<span className="imp-mark">*</span>
                                </label>
                                {(props.authority === "finance" && !props.data.isProcessed) ? <input type="date" value={moment(releaseDate).format("YYYY-MM-DD")} className="create-input mt-5" onChange={(e) => setReleaseDate(e.target.value)} /> : <input type="date" value={moment(props.data?.releaseDate).format("YYYY-MM-DD") ?? ''} className="create-input mt-5" disabled onChange={() => { console.log("22") }} />}
                                <span className="error-msg">Enter the field</span>
                            </div>
                        </div>

                        <div className="form-2-col flex-start">
                            <div className="form-group">
                                <label htmlFor="name" className="create-label">
                                    UTR Number <span className="imp-mark">*</span>
                                </label>
                                <input type="text" placeholder='Enter UTR Number' value={utrNumber} disabled={props.authority !== "finance" || props.data.isProcessed} onChange={(e) => setUTRNumber(e.target.value)} className="create-input mt-5" />
                                <span className="error-msg">Enter the field</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name" className="create-label">
                                    Amount Paid <span className="imp-mark">*</span>
                                </label>
                                <input type="number" placeholder='Enter Paid Amount' className="create-input mt-5" value={paidAmount} disabled={props.authority !== "finance" || props.data.isProcessed} onChange={(e) => setPaidAmount(e.target.value)} />
                                <span className="error-msg">Enter the field</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name" className="create-label">
                                    Difference In Amount
                                </label>
                                <input type="number" placeholder='Enter Difference Amount' value={difference} className="create-input mt-5" disabled={props.authority !== "finance" || props.data.isProcessed} onChange={(e) => setDifference(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="name" className="create-label">
                                    Reason For Difference
                                </label>
                                <input type="text" placeholder='Enter Reason for Difference' value={reasonDiff} className="create-input mt-5" disabled={props.authority !== "finance" || props.data.isProcessed} onChange={(e) => setReasonDiff(e.target.value)} />
                            </div>
                        </div>

                        <div className="approvingContainer">
                            <div className="approvingBox">
                                <p className="label">Approving Authority: </p>
                                <h2 className=" name mt-10">{props.data?.approvingAuthority === "3b19fa50-b1ff-4216-93bf-c786f8d8cd95" && "Gurji Kochar"}
                                {props.data?.approvingAuthority === "7d0de7d3-8604-4b34-8693-e08434c26c4c" && "Chetan Verma"}
                                {props.data?.approvingAuthority === "6bbb372a-8af9-41e6-963a-65c48d20275a" && "Mahak Garg"}
                                {props.data?.approvingAuthority === "480f6558-7f9b-4c2e-8cb9-89e1326f3171" && "Rajendra Kumar"}</h2>
                                {props.data?.ExpenseDeliverables &&
                                    (<><p className="label mt-20">Key Deliverables: </p>
                                        <div className="keys mt-10">
                                            {props.data?.ExpenseDeliverables?.map((item, index) => {
                                                return <p className="primary-tag" key={index}>{item?.keyDeliverables}</p>
                                            })}
                                        </div></>
                                    )}
                            </div>

                            {props.data?.ExpenseDeliverables && props.data?.ExpenseDeliverables.length > 0 && (<div className="approvingBox">
                                <p className="label">Proof of Key Deliverables:</p>
                                {/* <p className="desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p> */}
                                <div className="uploadedDocContainer mt-10">
                                    {props.data?.ExpenseDeliverables?.map((item, index) => {
                                        if (item?.proofOfDeliverables && item?.proofOfDeliverables !== '')
                                            return <div className="uploadedFileDetailBox" key={index}>
                                                <div className="fileName">
                                                    <img src="../assets/icons/paperclipGreen.svg" alt="" />
                                                    <p> {item?.keyDeliverables}</p>
                                                </div>
                                                {/* <button className="light-btn-a"><img src="../assets/icons/preview.svg" alt="" /> View File</button> */}
                                                <a className="light-btn-a" href={item?.proofOfDeliverables} target="_blank" ><img src="../assets/icons/preview.svg" alt="" /> View File</a>
                                            </div>
                                    })}


                                </div>
                            </div>)}
                        </div>
                        <div className="approvingContainer">
                            {props.data?.InvoiceAttachments && props.data?.InvoiceAttachments.length > 0 && (<div className="approvingBox">
                                <div className="attachedFileContainer">
                                    <p className="title">Attached invoice: </p>
                                    <div className="uploadedDocContainer mt-10">
                                        {props.data?.InvoiceAttachments?.map((item, index) => (
                                            <div className="uploadedFileDetailBox" key={index}>

                                                <div className="fileName">
                                                    <img src="../assets/icons/paperclipGreen.svg" alt="" />
                                                    <p> Invoice</p>
                                                </div>
                                                {/* <button className="light-btn-a"><img src="../assets/icons/preview.svg" alt="" /> View File</button> */}
                                                <a className="light-btn-a" href={item.attachment} target="_blank"><img src="../assets/icons/preview.svg" alt="" /> View File</a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>)}
                            {props.data?.agreementCopy && props.data?.agreementCopy != '' && (<div className="approvingBox">
                                <div className="attachedFileContainer">
                                    <p className="title">Attached Agreement Copy: </p>
                                    <div className="uploadedDocContainer mt-10">

                                        <div className="uploadedFileDetailBox">
                                            <div className="fileName">
                                                <img src="../assets/icons/paperclipGreen.svg" alt="" />
                                                <p> Agreement Copy</p>
                                            </div>
                                            {/* <button className="light-btn-a"><img src="../assets/icons/preview.svg" alt="" /> View File</button> */}
                                            <a className="light-btn-a" href={props.data?.agreementCopy} target="_blank"><img src="../assets/icons/preview.svg" alt="" /> View File</a>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                        <div className="amountOuter">
                            <p>Total Amount:</p>
                            <p>₹ {props.data?.amount}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FinanceInvoicePopoup