import React, { useEffect, useState } from "react";
import "./createInterviewPopupStyle.css";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import SearchSelect from "react-select";

import {
  applicantData,
  clearInterviewState,
  getApplicantDetailList,
  scheduleApplicantInterview,
  submitInterviewFeedbackAnswers,
  getApplicantUsers,
} from "../../../reducers/applicantSlice";
import ErrorToast from "../../Toast/ErrorToast";
const CreateInterviewPopup = (props) => {
  const {
    isFetching,
    isSuccess,
    isError,
    applicantListDetail,
    interviewScheduled,
    getApplicantUsersData,
  } = useSelector(applicantData);
  const [interviewerName, setInterviewerName] = useState("");

  // console.log("********print props*******", props);

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [meetingLink, setMeetingLink] = useState("");
  const [interviewDateTime, setInterviewDateTime] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setInterviewerName(event?.value);

    // console.log("dfdfdfdfdfedf", event);
  };

  const handleDateTimeChange = (event) => {
    setInterviewDateTime(event.target.value);
  };

  const onClickScheduleInterview = () => {
    let obj = {
      interviewerName: interviewerName,
      interviewStatus: "Pending",
      result: "Pending",
      applicantId: props.applicantId,
      date: interviewDateTime,
      meetingLink: meetingLink,
      programId: props.programBatchId,
      email: props.applicantEmail,
    };

    if (interviewDateTime === "" || interviewerName === "") {
      setIsShowError(true);
      setErrMessage("Fill all the required fields!");
      // toast.error("Fill all the required fields !");
    } else {
      dispatch(scheduleApplicantInterview(obj));
      clearPopupData();
      props.setPageNo("1");
    }
  };

  useEffect(() => {
    if (props?.interviewData && props?.interviewData !== null) {
      let data = props.interviewData;
      setInterviewerName(interviewerName);
      setMeetingLink(data.meetingLink);
      setInterviewDateTime(data.date);
    }
  }, [props?.interviewData]);

  const [pageActive, setActivePage] = useState(1);

  //feedback logic
  const [commSkill, setCommSkill] = useState({
    interviewFeedQuestionId: "questionId1",
    answer: "1",
  });
  const [fitness, setFitness] = useState({
    interviewFeedQuestionId: "questionId2",
    answer: "1",
  });
  const [revelance, setRelevance] = useState({
    interviewFeedQuestionId: "questionId3",
    answer: "1",
  });
  const [score, setScore] = useState({
    interviewFeedQuestionId: "questionId4",
    answer: "",
  });
  const [shouldAccept, setShouldAccept] = useState({
    interviewFeedQuestionId: "questionId5",
    answer: "yes",
  });

  const [result, setResult] = useState("Pass");

  const [scholership, setScholership] = useState("");

  const [willJoin, setWillJoin] = useState({
    interviewFeedQuestionId: "questionId6",
    answer: "",
  });
  const [canAfford, setCanAfford] = useState({
    interviewFeedQuestionId: "questionId7",
    answer: "",
  });

  const [currCTC, setCurrCTC] = useState({
    interviewFeedQuestionId: "questionId8",
    answer: "",
  });

  const [comments, setComments] = useState({
    interviewFeedQuestionId: "questionId9",
    answer: "",
  });
  // const [willJoinDesc, setWillJoinDesc] = useState({
  //   interviewFeedQuestionId: "questionId5",
  //   answer: "",
  // });
  // const [wontJoin, setWontJoin] = useState({
  //   interviewFeedQuestionId: "questionId9",
  //   answer: "",
  // });

  useEffect(() => {
    if (props?.feedbackAns && props?.feedbackAns?.length > 0) {
      props?.feedbackAns.map((ans) => {
        let ansObj = {
          id: ans.id,
          answer: ans.answer,
          interviewFeedQuestionId: ans.interviewFeedQuestionId,
        };

        if (ans.interviewFeedQuestionId == "questionId1") {
          setCommSkill(ansObj);
        } else if (ans.interviewFeedQuestionId == "questionId2") {
          setFitness(ansObj);
        } else if (ans.interviewFeedQuestionId == "questionId3") {
          setRelevance(ansObj);
        } else if (ans.interviewFeedQuestionId == "questionId4") {
          setScore(ansObj);
        } else if (ans.interviewFeedQuestionId == "questionId5") {
          setShouldAccept(ansObj);
          if (ansObj.answer === "no") {
            setResult("Fail");
          } else if (ansObj.answer === "maybe") {
            setResult("Waitlisted");
          } else {
            setResult("Pass");
          }
        } else if (ans.interviewFeedQuestionId == "questionId6") {
          setWillJoin(ansObj);
        } else if (ans.interviewFeedQuestionId == "questionId7") {
          setCanAfford(ansObj);
        } else if (ans.interviewFeedQuestionId == "questionId8") {
          setCurrCTC(ansObj);
        } else if (ans.interviewFeedQuestionId == "questionId9") {
          setComments(ansObj);
        }
        setScholership(props.scholarshipAmount);
        setIsReadOnly(true);
      });
    }
  }, [props?.feedbackAns]);

  console.log(props?.feedbackAns);

  const clearOnFail = () => {
    setScholership("");
    setWillJoin({ interviewFeedQuestionId: "questionId6", answer: "" });
    setCanAfford({ interviewFeedQuestionId: "questionId7", answer: "" });
    setCurrCTC({ interviewFeedQuestionId: "questionId8", answer: "" });
  };
  const clearPopupData = () => {
    setCommSkill({ interviewFeedQuestionId: "questionId1", answer: "1" });
    setFitness({ interviewFeedQuestionId: "questionId2", answer: "1" });
    setRelevance({ interviewFeedQuestionId: "questionId3", answer: "1" });
    setScore({ interviewFeedQuestionId: "questionId4", answer: "" });
    setShouldAccept({ interviewFeedQuestionId: "questionId5", answer: "yes" });
    setWillJoin({ interviewFeedQuestionId: "questionId6", answer: "yes" });
    setCanAfford({ interviewFeedQuestionId: "questionId7", answer: "yes" });
    setCurrCTC({ interviewFeedQuestionId: "questionId8", answer: "" });
    setComments({ interviewFeedQuestionId: "questionId9", answer: "" });
    setScholership("");
    setResult("Pass");

    setInterviewerName("");
    setMeetingLink("");
    setInterviewDateTime("");
  };

  //radio changes
  const onSkillsChange = (e) => {
    setCommSkill((prev) => {
      return {
        id: prev.id,
        interviewFeedQuestionId: prev.interviewFeedQuestionId,
        answer: e.target.value,
      };
    });
  };
  const onFitnessChange = (e) => {
    setFitness((prev) => {
      return {
        id: prev.id,
        interviewFeedQuestionId: prev.interviewFeedQuestionId,
        answer: e.target.value,
      };
    });
  };
  const onRelevanceChange = (e) => {
    setRelevance((prev) => {
      return {
        id: prev.id,
        interviewFeedQuestionId: prev.interviewFeedQuestionId,
        answer: e.target.value,
      };
    });
  };

  const saveFeedback = () => {
    let answers = [
      commSkill,
      fitness,
      revelance,
      score,
      shouldAccept,
      willJoin,
      canAfford,
      currCTC,
      comments,
    ];

    let status = result;
    let scholershipAmt = scholership;
    let obj = {
      answers,
      status,
      scholershipAmt,
      interviewerId: props.interviewerId,
      applicantId: props.applicantId,
      programId: props.programBatchId,
    };
    if (
      (result === "Pass" &&
        (scholership === "" ||
          willJoin.answer === "" ||
          canAfford.answer === "")) ||
      score.answer === ""
    ) {
      // toast.error("Fill all mandatory fields");
      setIsShowError(true);
      setErrMessage("Fill all mandatory fields");
      return;
    }
    if (score.answer < 0 || score.answer > 100) {
      // toast.error("Enter a valid score");
      setIsShowError(true);
      setErrMessage("Enter a valid score");
      return;
    }
    console.log(obj);

    dispatch(submitInterviewFeedbackAnswers(obj));
    clearPopupData();
    setActivePage(1);
    props.setPageNo("1");
  };

  const checkScore = () => {
    if (score.answer < 0 || score.answer > 100) {
      // toast.error("Enter a valid score");
      setIsShowError(true);
      setErrMessage("Enter a valid score");
      return;
    }
    setActivePage(pageActive + 1);
  };

  const userArray = [
    { id: 1, value: "Vikas Singha", label: "Vikas Singha" },
    { id: 2, value: "Shahrose Bhat", label: "Shahrose Bhat" },
    { id: 3, value: "Vaibhav Achantani", label: "Vaibhav Achantani" },
    { id: 4, value: "Gurji Kochar", label: "Gurji Kochar" },
    { id: 5, value: "Tarun Gangwar", label: "Tarun Gangwar" },
  ];

  useEffect(() => {
    if (isSuccess) clearPopupData();
  }, [isSuccess]);

  useEffect(() => {
    if (!props?.viewOnly) clearPopupData();
  }, [props?.viewOnly]);

  return (
    <>
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container interview-popup">
          <div className="popup-header">
            <div className="popup-heading">
              {props.popupTitle}
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={() => {
                  props.togglePopup();
                  setActivePage(1);
                  clearPopupData();
                }}
              />
            </div>
          </div>

          {props.type === "create" && (
            <div className="popup-body">
              <div className="popup-form-outer scrollbar active">
                <form className="popup-form scrollbar active">
                  {/* <span>Schedule Interview of {props.applicantName}</span> */}
                  {/* <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">Candidate Name</label>
                    <input
                      type="text"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Enter Candidate Name"
                    />
                  </div>
                  <div className="form-grp mb-20">
                    <label htmlFor="name">Phone Number</label>
                    <input
                      type="tel"
                      className="form-input"
                      placeholder="Enter Phone Number"
                    />
                  </div>
                  <div className="form-grp mb-20">
                    <label htmlFor="name">Candidate Email ID</label>
                    <input
                      type="email"
                      className="form-input"
                      placeholder="Enter Email ID"
                    />
                  </div> */}
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-input"
                      disabled
                      value={props.applicantName}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-grp mb-20">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      type="tel"
                      className="form-input"
                      disabled
                      value={props.applicantMobile}
                      placeholder="Enter Phone Number"
                    />
                  </div>
                  <div className="form-grp mb-20">
                    <label htmlFor="email">Candidate Email ID</label>
                    <input
                      type="email"
                      className="form-input"
                      disabled
                      value={props.applicantEmail}
                      placeholder="Enter Email ID"
                    />
                  </div>
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">
                      Date & Time<span className="imp-mark">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      name=""
                      id=""
                      value={interviewDateTime}
                      onChange={handleDateTimeChange}
                      className="form-input"
                      placeholder="Select Date and Time"
                    />
                  </div>
                  <div className="form-grp mb-20 ns-Font">
                    <label htmlFor="name">
                      Interviewer Name<span className="imp-mark">*</span>
                    </label>

                    <SearchSelect
                      defaultValue={interviewerName}
                      onChange={handleChange}
                      options={userArray}
                      // getOptionLabel={(option) =>
                      //   option.firstName + " " + option.lastName
                      // }
                      // getOptionValue={(option) =>
                      //   option.firstName + " " + option.lastName
                      // }
                    />
                    {/* <select
                      className="form-input"
                      value={interviewerName}
                      onChange={handleChange}
                    >
                      <option disabled value="">
                        Select Interviewer
                      </option>

                      <option value="Vikas Singha">Vikas Singha</option>
                      <option value="Shahrose Bhat">Shahrose Bhat</option>
                      <option value="Vaibhav Achantani">
                        Vaibhav Achantani
                      </option>
                      <option value="Srajan Rastogi">Srajan Rastogi</option>
                      <option value="Gurji Kochar">Gurji Kochar</option>

                      {/* {getApplicantUsersData &&
                        getApplicantUsersData.map((res, index) => (
                          <option value={res.id} key={index}>
                            {res.firstName} {res.lastName}
                          </option>
                        ))} */}
                    {/* </select> */}

                    {/* <input
                      type="text"
                      className="form-input"
                      placeholder="Enter Interviewer Name"
                    /> */}
                  </div>
                  {/* <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">Application Fee</label>
                    <input
                      type="text"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Enter Application Fee"
                    />
                  </div>
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">Application Number</label>
                    <input
                      type="text"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Enter Application Number"
                    />
                  </div>
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">Consular</label>
                    <input
                      type="text"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Enter Consular"
                    />
                  </div> */}

                  {/* <div className="form-grp mb-20">
                    <label htmlFor="">Interview</label>
                    <div className="radio-group mt-10">
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="interview"
                        />
                        <span className="radiomark"></span>Yes
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="interview"
                        />
                        <span className="radiomark"></span>No
                      </label>
                    </div>
                  </div> */}

                  {/* <div className="form-grp mb-20">
                    <label htmlFor="name">Meeting link</label>
                    <input
                      onChange={onChangeMeetingLink}
                      type="url"
                      value={meetingLink}
                      className="form-input"
                      placeholder="Enter Meeting link"
                    />
                  </div> */}
                  {/* <div className="form-grp mb-20">
                    <label htmlFor="name">Meeting Link</label>
                    <button className="primary-btn-a">
                      Generate Meeting Link
                    </button>
                  </div> */}
                </form>
              </div>
              <div
                className={`popup-form-outer scrollbar ${
                  pageActive === 2 ? "active" : ""
                }`}
              >
                <form action="" className="popup-form scrollbar" id="form-2">
                  <div className="form-grp mt-20">
                    <label htmlFor="">
                      How would you rate the communication skills of the
                      candidate? <span className="imp-mark">*</span>
                    </label>
                    <div className="radio-group mt-10">
                      <p className="text-1">Poor</p>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="skills"
                        />
                        <span className="radiomark"></span>1
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="skills"
                        />
                        <span className="radiomark"></span>2
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="skills"
                        />
                        <span className="radiomark"></span>3
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="skills"
                        />
                        <span className="radiomark"></span>4
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="skills"
                        />
                        <span className="radiomark"></span>5
                      </label>
                      <p className="text-1">Outstanding</p>
                    </div>
                    <span className="error-msg">Enter the date</span>
                  </div>
                  <div className="form-grp">
                    <label htmlFor="">
                      Is the candidate a good fit for the Master’s Union
                      community? <span className="imp-mark">*</span>
                    </label>
                    <div className="radio-group mt-10">
                      <p className="text-1">Poor</p>
                      <label className="create-label">
                        <input className="radio-btn" type="radio" name="fit" />
                        <span className="radiomark"></span>1
                      </label>
                      <label className="create-label">
                        <input className="radio-btn" type="radio" name="fit" />
                        <span className="radiomark"></span>2
                      </label>
                      <label className="create-label">
                        <input className="radio-btn" type="radio" name="fit" />
                        <span className="radiomark"></span>3
                      </label>
                      <label className="create-label">
                        <input className="radio-btn" type="radio" name="fit" />
                        <span className="radiomark"></span>4
                      </label>
                      <label className="create-label">
                        <input className="radio-btn" type="radio" name="fit" />
                        <span className="radiomark"></span>5
                      </label>
                      <p className="text-1">Outstanding</p>
                    </div>
                    <span className="error-msg">Enter the date</span>
                  </div>
                  <div className="form-grp">
                    <label htmlFor="">
                      How relevent is candidate’s profile for the program?{" "}
                      <span className="imp-mark">*</span>
                    </label>
                    <div className="radio-group mt-10">
                      <p className="text-1">Poor</p>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="profile"
                        />
                        <span className="radiomark"></span>1
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="profile"
                        />
                        <span className="radiomark"></span>2
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="profile"
                        />
                        <span className="radiomark"></span>3
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="profile"
                        />
                        <span className="radiomark"></span>4
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="profile"
                        />
                        <span className="radiomark"></span>5
                      </label>
                      <p className="text-1">Outstanding</p>
                    </div>
                    <span className="error-msg">Enter the date</span>
                  </div>
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">Candidate will join</label>
                    <textarea
                      type="textarea"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Write a Description"
                      rows="5"
                      cols="5"
                      maxLength="500"
                    />
                  </div>
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">
                      willingness to join (Im your opinion, do you think the
                      candidate will join the program?){" "}
                      <span className="imp-mark">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Enter Candidate Name"
                    />
                    <span className="error-msg">select any option</span>
                  </div>
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">
                      What should the amount of scholarship we should offer this
                      candidate? <span className="imp-mark">*</span>
                    </label>
                    <div className="radio-group mt-10">
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="scholarship"
                        />
                        <span className="radiomark"></span>Yes
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="scholarship"
                        />
                        <span className="radiomark"></span>No
                      </label>
                    </div>
                    <span className="error-msg">select any option</span>
                  </div>
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">
                      Can the Candidate afford the program in your opinion?{" "}
                      <span className="imp-mark">*</span>
                    </label>
                    <div className="radio-group mt-10">
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="afford"
                        />
                        <span className="radiomark"></span>Yes
                      </label>
                      <label className="create-label">
                        <input
                          className="radio-btn"
                          type="radio"
                          name="afford"
                        />
                        <span className="radiomark"></span>No
                      </label>
                    </div>
                    <span className="error-msg">select any option</span>
                  </div>

                  <div className="form-grp mb-20">
                    <label htmlFor="name">Scholarship</label>
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Enter Scholarship"
                    />
                  </div>
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">
                      Additional Comments, anything about the candidate that
                      stands out (Any Special scholarship requirements)
                    </label>
                    <textarea
                      type="textarea"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Write a Description"
                      rows="5"
                      cols="5"
                      maxLength="500"
                    />
                  </div>
                </form>
              </div>
              <div
                className={`popup-form-outer scrollbar ${
                  pageActive === 3 ? "active" : ""
                }`}
              >
                <form action="" className="popup-form scrollbar" id="form-3">
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">
                      Won’t join or need more counseelling
                    </label>
                    <textarea
                      type="textarea"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Write a Description"
                      rows="5"
                      cols="5"
                      maxLength="500"
                    />
                  </div>
                  <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">
                      Additional Comments, anything about the candidate that
                      stands out (Any Special scholarship requirements)
                    </label>
                    <textarea
                      type="textarea"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Write a Description"
                      rows="5"
                      cols="5"
                      maxLength="500"
                    />
                  </div>
                </form>
              </div>

              <div className="form-footer mt-30">
                <div></div>
                <button
                  onClick={onClickScheduleInterview}
                  className="primary-btn"
                >
                  Schedule
                </button>
              </div>
            </div>
          )}

          {props.type === "feedback" && (
            <div className="popup-body">
              <div
                className={`popup-form-outer scrollbar ${
                  pageActive === 1 ? "active" : ""
                }`}
              >
                <fieldset
                  style={{ border: "none" }}
                  disabled={props?.viewOnly ? "disabled" : ""}
                >
                  <form action="" className="popup-form scrollbar" id="form-1">
                    <div className="form-grp mt-20">
                      <label htmlFor="">
                        How would you rate the communication skills of the
                        candicate? <span className="imp-mark">*</span>
                      </label>
                      <div className="radio-group mt-10">
                        <p className="text-1">Poor</p>
                        {["1", "2", "3", "4", "5"].map((val) => (
                          <label className="create-label" key={val}>
                            <input
                              className="radio-btn"
                              type="radio"
                              name="skills"
                              value={val}
                              checked={commSkill.answer === val}
                              onChange={onSkillsChange}
                            />
                            <span className="radiomark"></span>
                            {val}
                          </label>
                        ))}
                        <p className="text-1">Outstanding</p>
                      </div>
                      <span className="error-msg">Enter the date</span>
                    </div>
                    <div className="form-grp">
                      <label htmlFor="">
                        Is the candidate a good fit for the Master’s Union
                        community? <span className="imp-mark">*</span>
                      </label>
                      <div className="radio-group mt-10">
                        <p className="text-1">Poor</p>
                        {["1", "2", "3", "4", "5"].map((val) => (
                          <label className="create-label" key={val}>
                            <input
                              className="radio-btn"
                              type="radio"
                              name="fit"
                              value={val}
                              checked={fitness.answer === val}
                              onChange={onFitnessChange}
                            />
                            <span className="radiomark"></span>
                            {val}
                          </label>
                        ))}
                        <p className="text-1">Outstanding</p>
                      </div>
                      <span className="error-msg">Enter the date</span>
                    </div>
                    <div className="form-grp">
                      <label htmlFor="">
                        How relevent is candidate’s profile for the program?{" "}
                        <span className="imp-mark">*</span>
                      </label>
                      <div className="radio-group mt-10">
                        <p className="text-1">Poor</p>
                        {["1", "2", "3", "4", "5"].map((val) => (
                          <label className="create-label" key={val}>
                            <input
                              className="radio-btn"
                              type="radio"
                              name="profile"
                              value={val}
                              checked={revelance.answer === val}
                              onChange={onRelevanceChange}
                            />
                            <span className="radiomark"></span>
                            {val}
                          </label>
                        ))}
                        <p className="text-1">Outstanding</p>
                      </div>
                      <span className="error-msg">Enter the date</span>
                    </div>
                    <div className="form-grp mt-20 mb-20">
                      <label htmlFor="">
                        Overall Score (Out of 100){" "}
                        <span className="imp-mark">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-input"
                        name=""
                        id=""
                        max="100"
                        value={score.answer}
                        onChange={(e) =>
                          setScore((prev) => {
                            return {
                              id: prev.id,
                              interviewFeedQuestionId:
                                prev.interviewFeedQuestionId,
                              answer: e.target.value,
                            };
                          })
                        }
                        placeholder="Enter Score"
                      />
                    </div>

                    <div className="form-grp mt-20 mb-20">
                      <label htmlFor="">
                        Should Masters' Union accept the candidate?{" "}
                        <span className="imp-mark">*</span>
                      </label>
                      <div className="radio-group mt-10">
                        <label className="create-label">
                          <input
                            className="radio-btn"
                            type="radio"
                            name="shouldAccept"
                            value="yes"
                            checked={shouldAccept.answer === "yes"}
                            onChange={(e) => {
                              setResult("Pass");
                              setShouldAccept((prev) => {
                                return {
                                  id: prev.id,
                                  interviewFeedQuestionId:
                                    prev.interviewFeedQuestionId,
                                  answer: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="radiomark"></span>Yes
                        </label>
                        <label className="create-label">
                          <input
                            className="radio-btn"
                            type="radio"
                            name="shouldAccept"
                            value="no"
                            checked={shouldAccept.answer === "no"}
                            onChange={(e) => {
                              setResult("Fail");
                              clearOnFail();
                              setScholership("0");
                              setShouldAccept((prev) => {
                                return {
                                  id: prev.id,
                                  interviewFeedQuestionId:
                                    prev.interviewFeedQuestionId,
                                  answer: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="radiomark"></span>No
                        </label>
                        <label className="create-label">
                          <input
                            className="radio-btn"
                            type="radio"
                            name="shouldAccept"
                            value="maybe"
                            checked={shouldAccept.answer === "maybe"}
                            onChange={(e) => {
                              setResult("Waitlisted");
                              clearOnFail();
                              setScholership("0");
                              setShouldAccept((prev) => {
                                return {
                                  id: prev.id,
                                  interviewFeedQuestionId:
                                    prev.interviewFeedQuestionId,
                                  answer: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="radiomark"></span>WaitListed
                        </label>
                        {/* <label className="create-label">
                          <input
                            className="radio-btn"
                            type="radio"
                            name="shouldAccept"
                            value="counselling"
                            checked={shouldAccept.answer === "counselling"}
                            onChange={(e) => {
                              setResult("Pass");
                              setShouldAccept((prev) => {
                                return {
                                  id: prev.id,
                                  interviewFeedQuestionId:
                                    prev.interviewFeedQuestionId,
                                  answer: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="radiomark"></span>Candidate needs
                          more counselling
                        </label> */}
                      </div>
                      <span className="error-msg">select any option</span>
                    </div>
                  </form>
                </fieldset>
              </div>
              <div
                className={`popup-form-outer scrollbar ${
                  pageActive === 2 ? "active" : ""
                }`}
              >
                <fieldset
                  style={{ border: "none" }}
                  disabled={props?.viewOnly ? "disabled" : ""}
                >
                  <form action="" className="popup-form scrollbar" id="form-3">
                    {/* <div className="form-grp mt-20 mb-20">
                    <label htmlFor="">Candidate will join</label>
                    <textarea
                      type="textarea"
                      className="form-input"
                      name=""
                      id=""
                      placeholder="Write a Description"
                      onChange={(e) =>
                        setWillJoinDesc((prev) => {
                          return {
                            id: prev.id,
                            interviewFeedQuestionId:
                              prev.interviewFeedQuestionId,
                            answer: e.target.value,
                          };
                        })
                      }
                      value={willJoinDesc.answer}
                      rows="5"
                      cols="5"
                      maxLength="500"
                    />
                  </div> */}
                    {result === "Pass" && (
                      <>
                        <div className="form-grp mt-20 mb-20">
                          <label htmlFor="">
                            What should the amount of scholarship we should
                            offer this candidate?{" "}
                            <span className="imp-mark">*</span>
                          </label>
                          {/* <input
                        type="number"
                        className="form-input"
                        name=""
                        id=""
                        value={scholership}
                        onChange={(e) => setScholership(e.target.value)}
                        placeholder="Enter Scholarship Amount"
                      /> */}
                          <select
                            id="type"
                            className="popup-dropdown mt-5"
                            onChange={(e) => setScholership(e.target.value)}
                            value={scholership}
                          >
                            <option value="">Choose Scholarship</option>
                            <option value="0">No Scholarship</option>
                            {[...Array(10)].map((p, i) => (
                              <option key={i} value={(i + 1) * 5}>
                                {(i + 1) * 5}%
                              </option>
                            ))}
                            <option value="75">75% (Special Case)</option>
                            <option value="100">100% Tuition Fee</option>
                          </select>
                        </div>

                        <div className="form-grp mt-20 mb-20">
                          <label htmlFor="">
                            Willingness to join (In your opinion, do you think
                            the candidate will join the program?){" "}
                            <span className="imp-mark">*</span>
                          </label>
                          <div className="radio-group mt-10">
                            <label className="create-label">
                              <input
                                className="radio-btn"
                                type="radio"
                                name="willJoin"
                                value="yes"
                                checked={willJoin.answer === "yes"}
                                onChange={(e) =>
                                  setWillJoin((prev) => {
                                    return {
                                      id: prev.id,
                                      interviewFeedQuestionId:
                                        prev.interviewFeedQuestionId,
                                      answer: e.target.value,
                                    };
                                  })
                                }
                              />
                              <span className="radiomark"></span>Yes
                            </label>
                            <label className="create-label">
                              <input
                                className="radio-btn"
                                type="radio"
                                name="willJoin"
                                value="no"
                                checked={willJoin.answer === "no"}
                                onChange={(e) =>
                                  setWillJoin((prev) => {
                                    return {
                                      id: prev.id,
                                      interviewFeedQuestionId:
                                        prev.interviewFeedQuestionId,
                                      answer: e.target.value,
                                    };
                                  })
                                }
                              />
                              <span className="radiomark"></span>No
                            </label>
                            <label className="create-label">
                              <input
                                className="radio-btn"
                                type="radio"
                                name="willJoin"
                                value="maybe"
                                checked={willJoin.answer === "maybe"}
                                onChange={(e) =>
                                  setWillJoin((prev) => {
                                    return {
                                      id: prev.id,
                                      interviewFeedQuestionId:
                                        prev.interviewFeedQuestionId,
                                      answer: e.target.value,
                                    };
                                  })
                                }
                              />
                              <span className="radiomark"></span>Maybe
                            </label>
                          </div>
                          <span className="error-msg">select any option</span>
                        </div>
                        <div className="form-grp mt-20 mb-20">
                          <label htmlFor="">
                            Can the Candidate afford the program in your
                            opinion? <span className="imp-mark">*</span>
                          </label>
                          <div className="radio-group mt-10">
                            <label className="create-label">
                              <input
                                className="radio-btn"
                                type="radio"
                                name="afford"
                                onChange={(e) =>
                                  setCanAfford((prev) => {
                                    return {
                                      id: prev.id,
                                      interviewFeedQuestionId:
                                        prev.interviewFeedQuestionId,
                                      answer: e.target.value,
                                    };
                                  })
                                }
                                value="yes"
                                checked={canAfford.answer === "yes"}
                              />
                              <span className="radiomark"></span>Yes
                            </label>
                            <label className="create-label">
                              <input
                                className="radio-btn"
                                type="radio"
                                name="afford"
                                onChange={(e) =>
                                  setCanAfford((prev) => {
                                    return {
                                      id: prev.id,
                                      interviewFeedQuestionId:
                                        prev.interviewFeedQuestionId,
                                      answer: e.target.value,
                                    };
                                  })
                                }
                                value="no"
                                checked={canAfford.answer === "no"}
                              />
                              <span className="radiomark"></span>No
                            </label>
                            <label className="create-label">
                              <input
                                className="radio-btn"
                                type="radio"
                                name="afford"
                                onChange={(e) =>
                                  setCanAfford((prev) => {
                                    return {
                                      id: prev.id,
                                      interviewFeedQuestionId:
                                        prev.interviewFeedQuestionId,
                                      answer: e.target.value,
                                    };
                                  })
                                }
                                value="maybe"
                                checked={canAfford.answer === "maybe"}
                              />
                              <span className="radiomark"></span>Maybe (No clear
                              indication )
                            </label>
                          </div>
                          <span className="error-msg">select any option</span>
                        </div>
                        <div className="form-grp mt-20 mb-20">
                          <label htmlFor="">Current CTC estimate</label>
                          <input
                            type="number"
                            className="form-input"
                            name=""
                            id=""
                            value={currCTC.answer}
                            onChange={(e) =>
                              setCurrCTC((prev) => {
                                return {
                                  id: prev.id,
                                  interviewFeedQuestionId:
                                    prev.interviewFeedQuestionId,
                                  answer: e.target.value,
                                };
                              })
                            }
                            placeholder="Enter Estimate CTC"
                          />
                        </div>
                      </>
                    )}
                    <div className="form-grp mt-20 mb-20">
                      <label htmlFor="">
                        Additional comments, anything about the candidate that
                        stands out? (Any special scholarship requirements)
                      </label>
                      <textarea
                        type="textarea"
                        className="form-input"
                        name=""
                        id=""
                        placeholder="Write a Description"
                        value={comments.answer}
                        onChange={(e) =>
                          setComments((prev) => {
                            return {
                              id: prev.id,
                              interviewFeedQuestionId:
                                prev.interviewFeedQuestionId,
                              answer: e.target.value,
                            };
                          })
                        }
                        rows="5"
                        cols="5"
                        maxLength="500"
                      />
                    </div>
                  </form>
                </fieldset>
              </div>

              <div className="form-footer mt-30">
                {pageActive != 1 && (
                  <button
                    className="secondary-btn"
                    onClick={() => {
                      setActivePage(pageActive - 1);
                    }}
                  >
                    Back
                  </button>
                )}
                <div className="pagination">
                  <div
                    className={`page-dot ${pageActive === 1 ? "active" : ""}`}
                    onClick={() => setActivePage(1)}
                  ></div>
                  <div
                    className={`page-dot ${pageActive === 2 ? "active" : ""}`}
                    onClick={() => setActivePage(2)}
                  ></div>
                  {/* <div
                    className={`page-dot ${pageActive === 3 ? "active" : ""}`}
                    onClick={() => setActivePage(3)}
                  ></div> */}
                </div>
                {pageActive < 2 ? (
                  <button
                    className="primary-btn"
                    onClick={() => {
                      checkScore();
                    }}
                  >
                    Next
                  </button>
                ) : (
                  <>
                    {!props?.viewOnly && (
                      <button className="primary-btn" onClick={saveFeedback}>
                        Save
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateInterviewPopup;
