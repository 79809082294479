import React, { useRef, useState, useEffect, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import "../feedbackPreview/FeedbackPreviewStyle.css";
import "./FeedbackAnswersStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import PageLoader from "../../../components/UI/loader/PageLoader";
import {
  feedbackData,
  getFeedbackDetailById,
  getFeedbackStudentAnswer,
} from "../../../reducers/feedbackSlice";

const FeedbackAnswers = (props) => {
  const [searchParams] = useSearchParams();
  const feedId = searchParams.get("feedId");
  const stuId = searchParams.get("studId");
  const [questionArray, setQuestionArray] = useState([]);
  const [filteredAnswers, setFilteredAnswers] = useState([]);

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    feedbackList,
    feedbackResponse,
    studentList,
    feedbackDetail,
    emailTemplateData,
    studentAnswer,
  } = useSelector(feedbackData);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (feedId !== null || feedId !== undefined || feedId !== "null") {
        dispatch(getFeedbackDetailById(feedId));

        var obj = {
          feedbackId: feedId,
          studentId: stuId,
        };
        dispatch(getFeedbackStudentAnswer(obj));
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [feedId]);

  useEffect(() => {
    if (
      feedbackDetail !== null ||
      feedbackDetail !== undefined ||
      feedbackDetail !== "null"
    ) {
      // setPreviewType(feedbackDetail && feedbackDetail.previewType);
      // setLayoutType(feedbackDetail && feedbackDetail.layoutType);

      var array = [];

      feedbackDetail &&
        feedbackDetail.FeedbackQuestions.forEach((element, index) => {
          var item = {
            id: element.id,
            questionType: element.questionType,
            questionName: element.questionName,
            isMandatory: element.isMandatory,
            option: element.FeedbackQuestionOptions,
          };

          array.push(item);
        });

      console.log("fdfddffd", array);
      setQuestionArray(array);
    }
  }, [feedbackDetail]);

  useEffect(() => {
    const res = Object.values(
      studentAnswer.reduce(
        (a, { feedbackQuestionId, feedbackOptionId, answer }) => {
          a[feedbackQuestionId] = a[feedbackQuestionId] || {
            feedbackQuestionId,
            feedbackOptionId: new Set(),
            answer,
          };
          a[feedbackQuestionId].feedbackOptionId.add(feedbackOptionId);
          return a;
        },
        {}
      )
    ).map(({ feedbackQuestionId, feedbackOptionId, answer }) => ({
      feedbackQuestionId,
      feedbackOptionId: [...feedbackOptionId],
      answer: answer,
    }));

    console.log(res);
    setFilteredAnswers(res);
  }, [studentAnswer]);

  return (
    <React.Fragment>
      <SideMenu />
      {isFetching && <PageLoader />}

      <div className="preview-screen scrollbar mt-50 feedback-answers">
        <div className="preview-style singlepage">
          {questionArray.map((item) => {
            const {
              id,
              questionType,
              questionName,
              answers,
              title,
              desc,
              required,
            } = item;

            // return answersArray.map((questionAnswer, index) => (
            //   <React.Fragment key={index}>
            //     {questionType === "paragraph" &&
            //       questionAnswer.feedbackQuestionId === id && (
            //         <div className="inside-main">
            //           <div className="inside-shell">
            //             <div className="inside-mid card-style">
            //               <div className="inside-content">
            //                 <h2 className="heading-2">{questionName}</h2>
            //                 <p className="feedbacka-answer">
            //                   {questionAnswer.answer}
            //                 </p>
            //               </div>
            //             </div>
            //           </div>
            //         </div>
            //       )}

            //     {questionType === "singleSelect" &&
            //       questionAnswer.feedbackQuestionId === id && (
            //         <div className="inside-main">
            //           <div className="inside-shell">
            //             <div className="inside-mid card-style">
            //               <div className="inside-content">
            //                 <h2 className="heading-2">{questionName}</h2>
            //                 <div className="singleselect mt-30 col-3">
            //                   {item.option.map((listItem) => (
            //                     <label className="singleselectlabel">
            //                       <input
            //                         className="singleselect-btn"
            //                         disabled
            //                         defaultChecked={
            //                           listItem.id ===
            //                           questionAnswer.feedbackOptionId
            //                         }
            //                         type="radio"
            //                       />
            //                       <span className="singleselectradio" />
            //                       {listItem.value}
            //                     </label>
            //                   ))}
            //                 </div>
            //               </div>
            //             </div>
            //           </div>
            //         </div>
            //       )}

            //     {questionType === "multiSelect" &&
            //       questionAnswer.feedbackQuestionId === id && (
            //         <div className="inside-main">
            //           <div className="inside-shell">
            //             <div className="inside-mid card-style">
            //               <div className="inside-content">
            //                 <h2 className="heading-2">{questionName}</h2>
            //                 <div className="mt-30 checkmark-wrapper col-3">
            //                   {item.option.map((listItem) => (
            //                     <label className="checkmark-label">
            //                       <input
            //                         type="checkbox"
            //                         disabled
            //                         className="checkmark-btn"
            //                         defaultChecked={
            //                           listItem.id ===
            //                           questionAnswer.feedbackOptionId
            //                         }
            //                       />
            //                       <span className="checkmarkspan"></span>
            //                       {listItem.value}
            //                     </label>
            //                   ))}
            //                 </div>
            //               </div>
            //             </div>
            //           </div>
            //         </div>
            //       )}

            //     {questionType === "rating" &&
            //       questionAnswer.feedbackQuestionId === id && (
            //         <div className="inside-main">
            //           <div className="inside-shell">
            //             <div className="inside-mid card-style">
            //               <div className="inside-content">
            //                 <h2 className="heading-2">{questionName}</h2>

            //                 <div className="rating-wrapper mt-30">
            //                   <div className="rating">
            //                     <input
            //                       checked={questionAnswer.answer === "5"}
            //                       type="radio"
            //                       name="rating"
            //                       id="rating-5"
            //                     />
            //                     <label htmlFor="rating-5"></label>
            //                     <input
            //                       checked={questionAnswer.answer === "4"}
            //                       type="radio"
            //                       name="rating"
            //                       id="rating-4"
            //                     />
            //                     <label htmlFor="rating-4"></label>
            //                     <input
            //                       checked={questionAnswer.answer === "3"}
            //                       type="radio"
            //                       name="rating"
            //                       id="rating-3"
            //                     />
            //                     <label htmlFor="rating-3"></label>
            //                     <input
            //                       checked={questionAnswer.answer === "2"}
            //                       type="radio"
            //                       name="rating"
            //                       id="rating-2"
            //                     />
            //                     <label htmlFor="rating-2"></label>
            //                     <input
            //                       checked={questionAnswer.answer === "1"}
            //                       type="radio"
            //                       name="rating"
            //                       id="rating-1"
            //                     />
            //                     <label htmlFor="rating-1"></label>
            //                   </div>
            //                   <div className="rating-count">
            //                     {questionAnswer.answer} out of 5
            //                   </div>
            //                 </div>
            //               </div>
            //             </div>
            //           </div>
            //         </div>
            //       )}
            //   </React.Fragment>
            // ));

            // return (
            //   <>

            //   ans
            //     <span>hello hello</span>
            //   </>
            // );

            //  answersArray.forEach((element) => {
            //   return <span>{element}</span>;
            // });

            // const toFindDuplicates = (arry) =>
            //   arry.filter((item, index) => arr.indexOf(item) !== index);
            // const duplicateElementa = tofindDuplicates(arry);
            // console.log(duplicateElements);

            let questionAnswer = filteredAnswers.filter((answer) => {
              return answer.feedbackQuestionId === id;
            });

            questionAnswer = questionAnswer[0];

            return (
              <>
                {questionType === "paragraph" && (
                  <div className="inside-main">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>
                          <p className="feedbacka-answer">
                            {questionAnswer?.answer}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {questionType === "singleSelect" && (
                  <div className="inside-main">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>
                          <div className="singleselect mt-30 col-3">
                            {item.option.map((listItem) => (
                              <label className="singleselectlabel">
                                <input
                                  className="singleselect-btn"
                                  disabled
                                  onChange={() => {}}
                                  checked={questionAnswer?.feedbackOptionId.includes(
                                    listItem.id
                                  )}
                                  type="radio"
                                />
                                <span className="singleselectradio" />
                                {listItem.value}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {questionType === "multiSelect" && (
                  <div className="inside-main">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>
                          <div className="mt-30 checkmark-wrapper col-3">
                            {item.option.map((listItem) => (
                              <label className="checkmark-label">
                                <input
                                  type="checkbox"
                                  disabled
                                  onChange={() => {}}
                                  className="checkmark-btn"
                                  checked={questionAnswer?.feedbackOptionId.includes(
                                    listItem.id
                                  )}
                                />
                                <span className="checkmarkspan"></span>
                                {listItem.value}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {questionType === "rating" && (
                  <div className="inside-main">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>

                          <div className="rating-wrapper mt-30">
                            <div className="rating">
                              <input
                                checked={questionAnswer?.answer === "5"}
                                onChange={() => {}}
                                type="radio"
                                name="rating"
                                id="rating-5"
                              />
                              <label htmlFor="rating-5"></label>
                              <input
                                checked={questionAnswer?.answer === "4"}
                                onChange={() => {}}
                                type="radio"
                                name="rating"
                                id="rating-4"
                              />
                              <label htmlFor="rating-4"></label>
                              <input
                                checked={questionAnswer?.answer === "3"}
                                onChange={() => {}}
                                type="radio"
                                name="rating"
                                id="rating-3"
                              />
                              <label htmlFor="rating-3"></label>
                              <input
                                checked={questionAnswer?.answer === "2"}
                                onChange={() => {}}
                                type="radio"
                                name="rating"
                                id="rating-2"
                              />
                              <label htmlFor="rating-2"></label>
                              <input
                                checked={questionAnswer?.answer === "1"}
                                onChange={() => {}}
                                type="radio"
                                name="rating"
                                id="rating-1"
                              />
                              <label htmlFor="rating-1"></label>
                            </div>
                            <div className="rating-count">
                              {questionAnswer?.answer} out of 5
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            );

            //             return answersArray.map((element) => {
            //               return (
            //                 <div className="inside-main">
            //                   <div className="inside-shell">
            //                     <div className="inside-mid card-style">
            //                       <div className="inside-content">
            //                         {questionType === "paragraph" &&
            //                           element.feedbackQuestionId === id && (
            //                             <div>
            //                               <h2 className="heading-2">{questionName}</h2>
            //                               <span>{element.answer}</span>
            //                             </div>
            //                           )}

            //                         {(questionType === "singleSelect" &&
            //                           element.feedbackQuestionId === id) && (
            //                             <div>
            //                               <h2 className="heading-2">{questionName}</h2>

            //                               {item.option.map((listItem) => (
            //                                 <div className="singleselect mt-30 col-3">
            //                                   <label className="singleselectlabel">
            //                                     <input
            //                                       className="singleselect-btn"
            //                                       disabled
            //                                       defaultChecked={
            //                                         listItem.id === element.feedbackOptionId
            //                                       }
            //                                       type="radio"
            //                                     />
            //                                     <span className="singleselectradio" />
            //                                     {listItem.value}
            //                                   </label>
            //                                 </div>
            //                               ))}
            //                             </div>
            //                           )}

            //                         {questionType === "multiSelect" &&
            //                           element.feedbackQuestionId === id && (
            //                             <div>
            //                               <h2 className="heading-2">{questionName}</h2>

            //                               {item.option.map((listItem) => (
            //                                 <div className="mt-30 checkmark-wrapper col-3">
            //                                   <label className="checkmark-label">
            //                                     <input
            //                                       type="checkbox"
            //                                       disabled
            //                                       className="checkmark-btn"
            //                                       defaultChecked={
            //                                         listItem.id === element.feedbackOptionId
            //                                       }
            //                                     />
            //                                     <span className="checkmarkspan"></span>
            //                                     {listItem.value}
            //                                   </label>
            //                                 </div>
            //                               ))}
            //                             </div>
            //                           )}

            //                         {questionType === "rating" &&
            //                           element.feedbackQuestionId === id && (
            //                             <div>
            //                               <h2 className="heading-2">{questionName}</h2>

            //                             </div>
            //                           )}

            //                         {questionType === "uploadMedia" &&
            //                           element.feedbackQuestionId === id && (
            //                             <div>
            //                               <h2 className="heading-2">{questionName}</h2>
            //                               <div className="uploadbox_wrapper mt-30">
            //                                 <div className="upload_shell active">
            //                                   <div className="upload_inner">
            //                                     <label
            //                                       htmlFor="uploadbox"
            //                                       className="active"
            //                                     >
            //                                       {element.answer}
            //                                     </label>
            //                                   </div>
            //                                 </div>
            //                               </div>
            //                             </div>
            //                           )}

            //                         {/* {element.f

            //                             eedbackQuestionId === id && (
            //                               <textarea
            //                                 className="create-input"
            //                                 name=""
            //                                 id=""
            //                                 cols="5"
            //                                 rows="5"
            //                                 // defaultValue={element.answers}
            //                                 placeholder={"element.answers"}
            //                               />
            //                             )} */}

            //                         {/* paragraph */}
            //                         {/* rating bar */}
            //                         {/* <div className="rating-wrapper mt-30">
            // <div className="rating">
            // <input type="radio" name="rating" id="rating-5" />
            // <label htmlFor="rating-5"></label>
            // <input type="radio" name="rating" id="rating-4" />
            // <label htmlFor="rating-4"></label>
            // <input type="radio" name="rating" id="rating-3" />
            // <label htmlFor="rating-3"></label>
            // <input type="radio" name="rating" id="rating-2" />
            // <label htmlFor="rating-2"></label>
            // <input type="radio" name="rating" id="rating-1" />
            // <label htmlFor="rating-1"></label>
            // </div>
            // <div className="rating-count">4 out of 5</div>
            // </div> */}
            //                         {/* rating bar */}
            //                         {/* multi-select */}
            //                         {/* <div className="mt-30 checkmark-wrapper col-3">
            // <label className="checkmark-label">
            // <input type="checkbox" className="checkmark-btn" />
            // <span className="checkmarkspan"></span>
            // Item Value
            // </label>
            // </div> */}
            //                         {/* multi-select */}
            //                         {/* single-select */}
            //                         {/* <div className="singleselect mt-30 col-3">
            // <label className="singleselectlabel">
            // <input className="singleselect-btn" type="radio" />
            // <span className="singleselectradio" />
            // item value
            // </label>
            // </div> */}
            //                         {/* single-select */}
            //                         {/* upload-media */}
            //                         {/* <div className="uploadbox_wrapper mt-30">
            // <div className="upload_shell active">
            // <div className="upload_inner">
            // <label htmlFor="uploadbox" className="active">
            // file-name
            // </label>
            // </div>
            // </div>
            // </div> */}
            //                         {/* upload-media */}
            //                       </div>
            //                     </div>
            //                   </div>
            //                 </div>
            //               );
            //             });
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeedbackAnswers;
