import React from "react";
import "./CourseDetailsStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import { COMMON_TEXT } from "../../../constants/textConstant";

const CourseDetails = () => {
  return (
    <>
      <SideMenu />
      <div className="main-body">
        <div className="page-header club-preview-header">
          <p className="component-heading">Course Details</p>
          <div className="btn-group">
            <button className="dark-btn-outline">
              <img src="../assets/icons/edit-black.svg" alt="" /> Edit
            </button>
          </div>
        </div>
        <div className="preview-container scrollbar">
          <div className="main-container course-container">
            <div className="club-header">
              <div className="header-top">
                <img
                  src="https://img.freepik.com/free-vector/smooth-mesh-blurred-background-multi-color-gradient-pattern-smooth-
                  modern-watercolor-style-backdrop_124894-147.jpg?size=626&ext=jpg&ga=GA1.2.1292351815.1642896000"
                  alt=""
                />
              </div>
              <div className="header-bottom">
                <div className="course-detail">
                  <div className="club-detail">
                    <h3 className="heading-3 mb-5">Branding and Identity</h3>
                    <p className="club-desc">03 June, 2022 5 Months</p>
                  </div>
                  <div className="btn-group">
                    <button className="primary-outline-btn-a">Publish</button>
                  </div>
                </div>
                <div className="course-detail mt-15 content">
                  <p>
                    In highly fluid environments, such as those that we are in
                    now, it is of-course critical for businesses of all types to
                    maintain a high level of agility adaptability. But it is
                    also important to have a point of view on the future, even
                    if that view is likely to change in response to new
                    contexts. In spite of what some futurists would have you
                    believe the future is rarely that predictable, but that
                    shouldn’t stop us from having a vision or continually
                    exploring potential future scenarios.
                  </p>
                  <p className="">
                    The world has been disrupted by many technological
                    advancements in the last 30 years. These disruptions have
                    just started and we expect our lives to be disrupted
                    further. Take for instance, the COVID - enforced WFH policy
                    ( a great combination of technological and organisational
                    disruption).
                  </p>
                </div>
                <div className="border mt-10"></div>
                <div className="course-table mt-10">
                  <div className="course-content">
                    <p className="heading">Cluster</p>
                    <p className="content">Design</p>
                  </div>
                  <div className="course-content">
                    <p className="heading">Type</p>
                    <p className="content">Core</p>
                  </div>
                  <div className="course-content">
                    <p className="heading">Term</p>
                    <p className="content">2</p>
                  </div>
                  <div className="course-content">
                    <p className="heading">Mode</p>
                    <p className="content">Offline</p>
                  </div>
                  <div className="course-content">
                    <p className="heading">Credits</p>
                    <p className="content">4 Points</p>
                  </div>
                  <div className="course-content">
                    <p className="heading">Faculty</p>
                    <p className="content">Naredra Yadav</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="club-body course-body mt-40">
              <div className="course-body-header">
                <p className="text">
                  <span className="text-grey">Course Code: </span>MARTSEC 02
                </p>
                <p className="primary-text pointer">View Student List</p>
              </div>
              <div className="feedback-container scrollbar border">
                <table
                  className="feedback-table"
                  cellPadding={0}
                  cellSpacing={0}
                >
                  <thead>
                    <tr>
                      <th>Sessions</th>
                      <th style={{ paddingLeft: "0 !important" }}>
                        Date | Time
                      </th>
                      <th>Topics</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="p-20">
                      <td>Session 01</td>
                      <td>03 June | 09:00 to 11:00 AM</td>

                      <td>
                        Research Brand Post Mortem , if long text would be there
                      </td>
                    </tr>
                    <tr className="p-20">
                      <td>Session 01</td>
                      <td>03 June | 09:00 to 11:00 AM</td>

                      <td>
                        Research Brand Post Mortem , if long text would be there
                      </td>
                    </tr>
                    <tr className="p-20">
                      <td>Session 01</td>
                      <td>03 June | 09:00 to 11:00 AM</td>

                      <td>
                        Research Brand Post Mortem , if long text would be there
                      </td>
                    </tr>
                    <tr className="p-20">
                      <td>Session 01</td>
                      <td>03 June | 09:00 to 11:00 AM</td>

                      <td>
                        Research Brand Post Mortem , if long text would be there
                      </td>
                    </tr>
                    <tr className="p-20">
                      <td>Session 01</td>
                      <td>03 June | 09:00 to 11:00 AM</td>

                      <td>
                        Research Brand Post Mortem , if long text would be there
                      </td>
                    </tr>
                    <tr className="p-20">
                      <td>Session 01</td>
                      <td>03 June | 09:00 to 11:00 AM</td>

                      <td>
                        Research Brand Post Mortem , if long text would be there
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
