import React, { useState } from "react";
import "./FeeDropdownStyle.css";

const FeeDropdown = ({
  selectedOption,
  setSelectedOption,
  options,
  setDropdownDataChange,
}) => {
  // const options = ["Tution Fee", "Hostel Fee", "Electricity Fee"];
  const [isActive, setIsActive] = useState(false);
  console.log(options, "options");
  return (
    <>
      <div className="dropdown fee-dropdown mt-5">
        <div
          onClick={() => setIsActive(!isActive)}
          className="dropdown-btn text-6"
        >
          {selectedOption?.FeeMaster?.feeName}
        </div>
        {isActive && (
          <div className="dropdown-content text-6">
            {options.map((option, index) => {
              return (
                <div
                  className="dropdown-item"
                  key={index}
                  onClick={() => {
                    setSelectedOption(option);
                    setIsActive(false);
                    setDropdownDataChange(option);

                    console.log("fdfdfdf", option);
                  }}
                >
                  {option?.FeeMaster?.feeName}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default FeeDropdown;
