import React from 'react'
import './BankTransferPopupStyle.css'

const BankTransferPopup = (props) => {
    return (
        <>
            <div className={`popup-outer  ${props.show ? 'show-popup' : ''}`}>
                <div className="popup-container bankTransfer">
                    <div className="popup-header">
                        <div className="popup-heading">
                            Payment mode
                            <img
                                src="../assets/icons/close.svg"
                                alt=""
                                className="close-btn"
                                onClick={props.handleBankTransferPopup}

                            />
                        </div>
                    </div>
                    <div className="popup-body">
                        <div className="online-fee">
                            <div className="bank-details">
                                <p className="detail">Account Name:</p>
                                <p className="text-bold">
                                    Masters Uniom School of Business Unit of shanti informatics
                                </p>
                            </div>
                            <div className="bank-details mt-20">
                                <p className="detail">Account Number:</p>
                                <p className="text-bold">928020006649</p>
                            </div>
                            <div className="bank-details mt-20">
                                <p className="detail">IFSC Code:</p>
                                <p className="text-bold">UTI000000656</p>
                            </div>
                            <div className="bank-details mt-20">
                                <p className="detail">Bank Name:</p>
                                <p className="text-bold">AXIS Bank</p>
                            </div>
                            <div className="form-grp mt-20">
                                <label htmlFor="utr" className="create-label">
                                    Enter the UTR Number Post Transfer
                                </label>
                                <input
                                    type="text"
                                    className="create-input mt-5"
                                    placeholder="Enter the UTR Number"
                                   
                                />
                            </div>
                            <div className="popup-footer mt-40">
                                <button className="green-btn" onClick={props.handleBankTransferPopup}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BankTransferPopup