export const COMMON_TEXT = {
  loginText: "Login",
  forgetText: "Submit",
  resetText: "Confirm",
  forgotPassword: "Forget Password?",
  keepLogin: "Remember my login details",
  passwordAlert: "Please enter password",
  enterPassword: "Enter Password",
  emailAlert: "Please enter valid email",
  enterEmail: "Enter Email",
  welcomeAdmin: "Welcome",
  loginToAccount: "Please login to your Account",
  forgetAdmin: "User forget password",
  forgetAdminText: "Please your Email",
  backToLogin: "Back to Login",
  resetAdmin: "Reset your Password",
  resetAdminText: "Please Reset your Password",
  enterCnfPassword: "Enter Comfirm Password",
  questionDetails: "Question Details",
  goBack: "Back to page",
  studentReportList: "Report of students who have attempted the Feedback",
  exportCsv: "Export CSV",
  feedbackReportText: "Feedback Report",
  viewResponseText: "View Response",
  multipage: "multiPage",
  singlePage: "singlePage",
  addStudentsAlert:
    "Please add students for this feedback before going to publish",
  addQuestionsAlert:
    "Please add questions for this feedback before going to publish",
};

export const EMAIL_TYPE = {
  publishEmail: "publish",
  extendDataEmail: "extendDate",
  reminderFeedbackEmail: "sendReminder",
  acceptanceLetter: "acceptanceLetter",
  feeDueMail: "feeDueMail",
  welcomeLetter: "welcomeLetter",
  orientationMail: "orientationMail",
};

export const EMAIL_TEMPLATE_NAME = {
  emailTemplateForPublish: "Masters'Union_Create_Session_Template",
  emailTemplateForSendReminder: "Masters'Union_Session_Reminder_Template",
  emailTemplateForFeeDue: "Masters'Union_Update_Fees_0n_Student_Dashboard",
  emailTemplateWelcomeLetter: "Masters'Union_Welcome_Email_To_Students",
  emailTemplateWelcomeLetterProduct:
    "Masters'Union_Welcome_Email_To_PM_Students",
  emailTemplateWelcomeLetterBlockChain:
    "Masters'Union_Welcome_Email_To_Pro_BlockChain_Students",
  emailTemplateWelcomeLetterKPMG:
    "Masters'Union_Welcome_Email_To_KPMG_Students",
  emailTemplateWelcomeLetterFPA: "Masters'Union_Welcome_Email_To_FPA_Students",
  emailTemplateWelcomeLetterUX: "Masters'Union_Welcome_Email_To_UED_Students",
  emailTemplateWelcomeLetterHRM: "Masters'Union_Welcome_Email_To_HR_Students",
  emailTemplateWelcomeLetterCreator:
    "Masters'Union_Welcome_Email_To_Creatorpreneur_Students",
};

export const EMAIL_ORIENTATION_MAIL = {
  emailTemplateOrientationLetterHRM:
    "Masters'Union_Orientation_Email_To_HR_Students",
  emailTemplateOrientationLetterUX:
    "Masters'Union_Orientation_Email_To_UX_Students",
  emailTemplateOrientationLetterAF:
    "Masters'Union_Orientation_Email_To_AF_Students",
  emailTemplateOrientationLetterPM:
    "Masters'Union_Orientation_Email_To_PM_Students",
  emailTemplateOrientationLetterPGPRise:
    "Masters'Union_Orientation_Email_To_PGP_Rise_Students",
  emailTemplateOrientationLetterCreatorpreneur:
    "Masters'Union_Orientation_Email_To_Creatorpreneur_Students",
};

export const Acceptance_Letter = {
  acceptenceTemplateForPublish: "Masters'Union_Acceptance Letter",
  acceptanceLetterPGBTM: "Masters'Union_Acceptance Letter_Kpmg",
  acceptanceLetterKPMG: "Masters'Union_Acceptance Letter_Kpmg",
  acceptanceLetterProductMgmt:
    "Masters'Union_Acceptance Letter_Product_Management",
  acceptanceLetterArtComm:
    "Masters'Union_Acceptance Letter_Arts_Of_Communication",
  acceptanceLetterLeaderShip:
    "Masters'Union_Acceptance Letter_Effective_Leadership",
  acceptanceLetterBlockChain: "Masters'Union_Acceptance Letter_Block_Chain",
  acceptanceLetterUserExperience:
    "Masters'Union_Acceptance Letter_User_Experience",
  acceptanceLetterFinancialPlanning:
    "Masters'Union_Acceptance Letter_Financial_Planning_Analysis",
  acceptanceLetterHumanResource:
    "Masters'Union_Acceptance Letter_Human_Resource",
  acceptanceLetterCreatorpreneur:
    "Masters'Union_Acceptance Letter_Creatorpreneur",
  acceptanceLetterPGPRise: "Masters'Union_Acceptance_Letter_PGP_Rise",
  acceptanceLetterAudit: "Masters'Union_Acceptance Letter_Applied_Audit",
  // emailTemplateForSendReminder: "Masters'Union_Session_Reminder_Template",
};

export const Rejection_Letter = {
  rejectionFPA: "Masters'Union_Fpa_Applicants_Rejection_Email",
  rejectionKPMG: "Masters'Union_Kpmg_Applicants_Rejection_Email",
  rejectionUsrExp: "Masters'Union_UserExp_Applicants_Rejection_Email",
  rejectionProductMgmt:
    "Masters'Union_ProductManagement_Applicants_Rejection_Email",
  rejectionHRMgmt: "MastersUnion_HumanResource_Applicant_Rejection_Email",
};

export const Waitlist_Letter = {
  waitlistTemplate: "Masters'Union_Fpa_Applicants_Rejection_Email",
};
