import React from 'react'
import './FinancePaymentStyle.css'
const FinancePayment = (props) => {
    return (
        <>
            <div className={`popup-outer ${props.show ? 'show-popup' : ''}`}>
                <div className="popup-container sendFeePopup">
                    <div className="popup-header">
                        <div className="popup-heading">
                            Payment mode
                            <img
                                src="../assets/icons/close.svg"
                                alt=""
                                className="close-btn"
                                onClick={props.handleFinancePaymentPopup}

                            />
                        </div>
                    </div>
                    <div className="popup-body financePayment">
                        <div className="fee-option" onClick={()=>{props.handleBankTransferPopup();props.handleFinancePaymentPopup()}}>
                            Pay Online
                            <img src="../assets/icons/ArrowLeftBlack.svg" alt="" />
                            <img src="../assets/icons/ArrowLeft.svg" alt="" />
                        </div>
                        <div className="fee-option" onClick={()=>{props.handleCashDetailsPopup();props.handleFinancePaymentPopup()}}>
                            Pay Cash
                            <img src="../assets/icons/ArrowLeftBlack.svg" alt="" />
                            <img src="../assets/icons/ArrowLeft.svg" alt="" />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FinancePayment