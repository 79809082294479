import React from 'react'

const CashDetailsPopup = (props) => {
  return (
    <>
    <div className={`popup-outer  ${props.show ? 'show-popup' : ''}`}>
                <div className="popup-container bankTransfer">
                    <div className="popup-header">
                        <div className="popup-heading">
                        Cash Details
                            <img
                                src="../assets/icons/close.svg"
                                alt=""
                                className="close-btn"
                                onClick={props.handleCashDetailsPopup}

                            />
                        </div>
                    </div>
                    <div className="popup-body">
                        <div className="online-fee">
                        <div className="form-grp">
                                <label htmlFor="amount" className="create-label">
                                Amount Paid
                                </label>
                                <input
                                    id='amount'
                                    type="text"
                                    className="create-input"
                                    placeholder="₹ 47,000"
                                   
                                />
                            </div>
                            <div className="form-grp mt-20">
                                <label htmlFor="number" className="create-label">
                                Receipt Number
                                </label>
                                <input
                                    id='number'
                                    type="text"
                                    className="create-input"
                                    placeholder="12886001460078"
                                   
                                />
                            </div>
                            
                            <div className="form-grp mt-20">
                                <label htmlFor="dateTime" className="create-label">
                                Time & Date 
                                </label>
                                <input
                                    id='dateTime'
                                    type="datetime-local"
                                    className="create-input"
                                    
                                   
                                />
                            </div>
                            <div className="popup-footer mt-20">
                                <button className="green-btn" onClick={props.handleCashDetailsPopup}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default CashDetailsPopup