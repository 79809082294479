import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVerifyEmailsData,
  verifyEmailsData,
  postEmailData,
  emailSampleFileDownload,
} from "../../reducers/verifyEmailSlice";
import AddEmailPopup from "../../components/Popups/AddEmailPopup/AddEmailPopup";
import ErrorToast from "../../components/Toast/ErrorToast";

import "../interview/approvalList/approvalListStyle.css";

const VerifyEmails = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const hiddenFileInput = React.useRef(null);

  const dispatch = useDispatch();
  const { isFetching, isSuccess, isError, errorMessage, verifyEmailData } =
    useSelector(verifyEmailsData);
  useEffect(() => {
    dispatch(getVerifyEmailsData());
    dispatch(emailSampleFileDownload());
  }, []);
  const clickAddCSV = () => {
    setShowPopup(true);
  };

  const onClickDeleteUpload = () => {
    setSelectedFile("");
    setIsFilePicked(false);
  };

  const onClickUpload = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    }
  };
  const onClickCsv = () => {
    if (selectedFile === "" || selectedFile.type !== "text/csv") {
      // toast.error("Choose a file in csv format!");
      setIsShowError(true);
      setErrMessage("Choose a file in csv format!");
      return;
    }
    dispatch(postEmailData(selectedFile));
  };

  const toggleAddStudentList = () => {
    setShowPopup(!showPopup);
    onClickDeleteUpload();
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <React.Fragment>
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <AddEmailPopup
        handleClose={toggleAddStudentList}
        show={showPopup}
        //  handleFile={handleFile}
        onClickCsv={onClickCsv}
        // csvFileDownload={csvFileDownload}
        isFilePicked={isFilePicked}
        hiddenFileInput={hiddenFileInput}
        selectedFile={selectedFile}
        onClickUpload={onClickUpload}
        onClickDeleteUpload={onClickDeleteUpload}
        handleClick={handleClick}
        title={"Email"}
      />
      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">MasterCamps - Verify Emails</p>
          {/* <button className="primary-btn feed-btn" onClick={allTransactionClick}>
               All Transaction
            </button> */}

          {/* <button
            className="primary-btn feed-btn"
            onClick={() => {
              navigate(`/feeSummary`);
            }}
          >
            Summary
          </button> */}

          {true && (
            <button className="primary-btn feed-btn" onClick={clickAddCSV}>
              <img src="../assets/icons/plus.svg" alt="" /> Add CV
            </button>
          )}
        </div>
        <div className="feedback-container">
          <table
            className="feedback-table table "
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                {/* <th className="check-row">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      disabled={
                        viewOnlyAccess || studentFeedbackAccess === false
                      }
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                      checked={allChecked}
                      onChange={handleAllCheck}
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                </th> */}
                <th>
                  <div className="">Candidate name</div>
                </th>
                <th>Domain</th>
                <th>Email</th>
                <th>Status</th>
                {/* <th>
                  <div className="filterTableHeader">
                    Registered On
                    <div className="filterImg">
                      <img
                        src="../assets/icons/filterArrowUp.svg"
                        alt=""
                        className={
                          date===""
                            ? "pointer active"
                            : "pointer"
                        }
                        onClick={() => {
                          setDate("true");
                          setApplyFilter(true);
                          setPageNo("1");
                          setDescending("")
                        }}
                      />
                      <img
                        src="../assets/icons/filterArrowDown.svg"
                        alt=""
                        className={
                          date==="true"
                            ? "pointer active"
                            : "pointer"
                        }
                        onClick={() => {
                          setDate("");
                          setApplyFilter(true);
                          setPageNo("1");
                          setDescending("")
                        }}
                      />
                    </div>
                  </div>
                </th> */}
                {/* <th>Result</th> */}
                {/* {!viewOnlyAccess && studentFeedbackAccess && (
                  <th className="text-center">Action</th>
                )} */}
              </tr>
            </thead>
            <tbody>
              {verifyEmailData &&
                verifyEmailData?.map((item, index) => {
                  const {
                    id,
                    firstName,
                    lastName,
                    domain,
                    email,
                    updatedAt,
                    createdAt,
                    isValid,
                  } = item;

                  return (
                    <tr key={index}>
                      <td className="text-capitalize">
                        {firstName + lastName}
                      </td>
                      <td>{domain}</td>
                      <td>{email}</td>
                      {/* <td>{isValid}</td> */}
                      <td>
                        {isValid === true && <p className="tag-draft">Found</p>}
                        {isValid === false && (
                          <p className="tag-active">Not Found</p>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerifyEmails;
