import React from "react";
import "./UserListPopupStyle.css";

const UserListPopup = (props) => {
  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container userlist-popup">
          <div className="popup-header">
            <div className="popup-heading">
              User List
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>
          </div>
          <div className="popup-body scrollbar">
              <ul className="user-list">
                <li className="user-list-item">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p className="text-1">Anand Krishnan</p>
                </li>
                <li className="user-list-item">
                  <label className="checkbox-label">
                    <input type="checkbox" name="" className="checkbox-btn" />
                    <span className="checkmark"></span>
                  </label>
                  <p className="text-1">Anand Krishnan</p>
                </li>
                <li className="user-list-item">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p className="text-1">Anand Krishnan</p>
                </li>
                <li className="user-list-item">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="checkbox-btn"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p className="text-1">Anand Krishnan</p>
                </li>
              </ul>
            </div>
            <div className="popup-footer pt-10">
              <button className="primary-btn btn-lg">Submit</button>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserListPopup;
