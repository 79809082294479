import React, { useState } from "react";
import SunEditor from "suneditor-react";
import mergeTag from "../../Editor/EditorPlugin";
import "suneditor/dist/css/suneditor.min.css";

const CreateTemplatePopup = (props) => {
  const [showEditTemplate, setEditTemplate] = useState(true);
  const handleEditorChange = (content) => {
    props.setTemplateBody(content);
  };

  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div
          className={`popup-container mail-popped withedit ${
            showEditTemplate ? "active" : ""
          } `}
        >
          <div className="popup-header">
            <div className="popup-heading">
              Create Template
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>

            <div className="popup-header-body ">
              <div className="mail-head-shell">
                <div className="mail-head-shell-inside">
                  <div className="form-grp mb-20">
                    <label htmlFor="email">Template Name</label>
                    <input
                      type="text"
                      className="form-input"
                      value={props.templateName}
                      onChange={(e) => props.setTemplateName(e.target.value)}
                      placeholder="Enter name"
                    />
                  </div>
                </div>

                <div className="mail-head-shell-inside">
                  <div className="form-grp mb-20">
                    <label htmlFor="email">Template Subject</label>
                    <input
                      type="text"
                      className="form-input"
                      value={props.templateSubject}
                      onChange={(e) => props.setTemplateSubject(e.target.value)}
                      placeholder="Enter subject"
                    />
                  </div>
                </div>
                {/* <div className="mail-head-shell-inside">
                  <div className="form-grp mb-20">
                    <label htmlFor="email">Date</label>

                    <input
                      type="datetime-local"
                      name="expiryDate"
                      id="expiryDate"
                      className="create-input mt-5"
                      value={props.addedOn}
                      onChange={(e) => props.setAddedOn(e.target.value)}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="popup-body scrollbar">
            <div className="mail-head-shell-inside width-100 mt-10">
              <span className="text-5 mb-5 dis-block">Email Body</span>
              <SunEditor
                setContents={props.templateBody}
                showToolbar={true}
                onChange={handleEditorChange}
                setDefaultStyle="height: auto"
                setOptions={{
                  // plugins: [mergeTag],
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "list",
                      "align",
                      "fontSize",
                      "formatBlock",
                      "table",
                      "fullScreen",
                      "preview",
                      "codeView",
                    ],
                    // [
                    //   {
                    //     name: "merge_tag",
                    //     dataCommand: "merge_tag",
                    //     buttonClass: "",
                    //     title: "Add Token",
                    //     dataDisplay: "submenu",
                    //     innerHTML: "<span>Token</span>",
                    //   },
                    // ],
                  ],
                }}
              />
            </div>
          </div>

          <div className="popup-footer mail-popup-footer">
            <button
              className="danger-btn width-auto"
              onClick={props.createTemplateSubmit}
            >
              Create Template
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateTemplatePopup;
