import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createMentorFeedback,
  clearFeedBack,
  meetingData,
} from "../../../reducers/meetingSlice";
import "./MeetFeedbackPopupStyle.css";
import ErrorToast from "../../../components/Toast/ErrorToast";
import moment from "moment";
const MeetFeedbackPopup = (props) => {
  const { isFetching, meetingFeedBack } = useSelector(meetingData);
  const dispatch = useDispatch();
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [que1, setQue1] = useState("");
  const [que2, setQue2] = useState("");
  const [que3, setQue3] = useState("");
  const [que4, setQue4] = useState("");
  const [que5, setQue5] = useState("");
  const [que6, setQue6] = useState("");
  const [que7, setQue7] = useState("");
  // const [que8, setQue8] = useState("");
  const utcOffset=moment().utcOffset()

  const feedBackSubmit = () => {
    console.log(props.singleMeetingDetail, "submit");
    // singleMeetingDetail={singleMeetingDetail}
    let obj = {
      meetingMasterId: props?.singleMeetingDetail?.meetingMasterId,
      meetingDetailId: props?.singleMeetingDetail?.id,
      feedbackFor: "Student",
      studentId: props?.singleMeetingDetail?.studentId,
      response1: que1,
      response2: que2,
      response3: que3,
      response4: que4,
      response5: que5,
      response6: que6,
      response7: que7,
      utcOffset:props?.utcOffset,
      // response8: que8,
      userId: props?.singleMeetingDetail?.userId,
    };
    if(que1 === "" || que2 === "" || que3 === "" || que4 === "" || que5 === "" || que6 === "" || que7 === ""){
      setIsShowError(true);
      setErrMessage("Fill all questions");
      return
    }
    dispatch(createMentorFeedback(obj));
  };

  let feedData =
    props?.singleFeedBackDetail &&
    props?.singleFeedBackDetail?.MeetFeedbacks?.filter(
      (res) => res.feedbackFor === "Student"
    );
  let feedDataStudent =
    props?.studentFeedBackDetail &&
    props?.studentFeedBackDetail?.MeetFeedbacks?.filter(
      (res) => res.feedbackFor === "Mentor"
    );
  const handleClose = () => {
    setQue1("");
    setQue2("");
    setQue3("");
    setQue4("");
    setQue5("");
    setQue6("");
    setQue7("");
    // setQue8("");
    props.handleClose();
  };
  useEffect(() => {
    if (meetingFeedBack) {
      dispatch(clearFeedBack());
      handleClose();
    }
    if (feedData?.length > 0) {
      setQue1(feedData && feedData[0]?.response1);
      setQue2(feedData && feedData[0]?.response2);
      setQue3(feedData && feedData[0]?.response3);
      setQue4(feedData && feedData[0]?.response4);
      setQue5(feedData && feedData[0]?.response5);
      setQue6(feedData && feedData[0]?.response6);
      setQue7(feedData && feedData[0]?.response7);
      // setQue8(feedData && feedData[0]?.response8);
    } else if (feedDataStudent?.length > 0) {
      setQue1(feedDataStudent && feedDataStudent[0]?.response1);
      setQue2(feedDataStudent && feedDataStudent[0]?.response2);
      setQue3(feedDataStudent && feedDataStudent[0]?.response3);
      setQue4(feedDataStudent && feedDataStudent[0]?.response4);
      setQue5(feedDataStudent && feedDataStudent[0]?.response5);
      setQue6(feedDataStudent && feedDataStudent[0]?.response6);
      setQue7(feedDataStudent && feedDataStudent[0]?.response7);
      // setQue8(feedDataStudent && feedDataStudent[0]?.response8);
    }
  }, [meetingFeedBack, feedData, feedDataStudent]);

  return (
    <>
    <ErrorToast
      show={isShowError}
      setShow={() => setIsShowError(false)}
      message={errMessage}
    />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container feedback-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Feedback Form
              {feedData?.length > 0 || feedDataStudent?.length > 0 ? (
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              ) : (
                ""
              )}
              {/* {
                feedDataStudent?.length >0 ?
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
                :""
              } */}
              {/* <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={handleClose}
              /> */}
            </div>
          </div>
          <div className="popup-body scrollbar">
            <div className="popup-form-outer scrollbar active">
              <form className="popup-form scrollbar active">
                {/* 1 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                  How was your todays’ interaction with the Coachee. Rate your satisfaction on the scale of
1-5, 5 being the highest ?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10">
                    <p className="text-1">Poor</p>
                    {["1", "2", "3", "4", "5"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que1"
                          value={val}
                          checked={que1 === val}
                          onChange={(e) => setQue1(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    <p className="text-1">Outstanding</p>
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 2 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                  Was your student coachable during the session ? <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10 flex-start">
                    {/* <p className="text-1">Poor</p> */}
                    {["Yes", "No"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que2"
                          value={val}
                          checked={que2 === val}
                          onChange={(e) => setQue2(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    {/* <p className="text-1">Outstanding</p> */}
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 3 */}
                <div className="form-grp mt-20 flex-start">
                  <label htmlFor="">
                  Were you and your student able to achieve the objectives of this session ?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10 flex-start">
                    {/* <p className="text-1">Poor</p> */}
                    {["Yes", "No"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que3"
                          value={val}
                          checked={que3 === val}
                          onChange={(e) => setQue3(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    {/* <p className="text-1">Outstanding</p> */}
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 4 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                  How proactive and invested was your Coachee in this session. Rate on the scale of 1-5, 5
being the highest ? <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10">
                    <p className="text-1">Poor</p>
                    {["1", "2", "3", "4", "5"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que4"
                          value={val}
                          checked={que4 === val}
                          onChange={(e) => setQue4(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    <p className="text-1">Outstanding</p>
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 5 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                  Did your Coachee complete all the previous task/s assigned ? <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10 flex-start">
                    {/* <p className="text-1">Poor</p> */}
                    {["Yes", "No"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que5"
                          value={val}
                          checked={que5 === val}
                          onChange={(e) => setQue5(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    {/* <p className="text-1">Outstanding</p> */}
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 6 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                  Did you face any challenges during this session?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10 flex-start">
                    {/* <p className="text-1">Poor</p> */}
                    {["Yes", "No"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que6"
                          value={val}
                          checked={que6 === val}
                          onChange={(e) => setQue6(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    {/* <p className="text-1">Outstanding</p> */}
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 7 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                  Do you wish to share any suggestions?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  
                  <div className="radio-group mt-10">
                    <textarea
                      id="question-input"
                      name="que8"
                      rows="2"
                      className="question-c-text-area text-6 scrollbar"
                      placeholder="Enter your suggestions."
                      value={que7}
                      onChange={(e) => setQue7(e.target.value)}
                    ></textarea>
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* <div className="form-grp mt-20">
                  <label htmlFor="">
                    Any suggestions to improve the program?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10">
                    <textarea
                      id="question-input"
                      name="que8"
                      rows="2"
                      className="question-c-text-area text-6"
                      placeholder="Enter your suggestions."
                      value={que8}
                      onChange={(e) => setQue8(e.target.value)}
                    ></textarea>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
          <div className="popup-footer ">
            {feedData?.length > 0 || feedDataStudent?.length > 0 ? (
              ""
            ) : (
              <button className="primary-btn" onClick={feedBackSubmit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetFeedbackPopup;
