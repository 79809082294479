import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SettingsStyle.css";
import { useLocation } from "react-router-dom";

const SettingsSideMenu = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname);
  return (
    <>
      <div className="interviewSettingsMenu">
        <div
          onClick={() => {
            navigate("interviewSetting");
          }}
          className={`interviewSettingMenuOption ${
            location.pathname === "/settings/interviewSetting" ||
            location.pathname === "/settings/interviewSetting/availableSlots"
              ? "active"
              : ""
          }`}
        >
          <p>Interview Settings</p>
          <img src="../assets/icons/arrow-right.svg" alt="" />
        </div>
        <div
          onClick={() => {
            navigate("interviewSlots");
          }}
          className={`interviewSettingMenuOption ${
            location.pathname ===
              "/settings/interviewSlots/createInterviewSlot" ||
            location.pathname === "/settings/interviewSlots"
              ? "active"
              : ""
          }`}
        >
          <p>Interview Slots</p>
          <img src="../assets/icons/arrow-right.svg" alt="" />
        </div>

        {/* <div
          onClick={() => {
            navigate("mentorCallSettings");
          }}
          className={`interviewSettingMenuOption ${
            location.pathname === "/settings/mentorCallSettings" ||
            location.pathname === "/settings/mentorCallSettings"
              ? "active"
              : ""
          }`}
        >
          <p>Mentor Call Settings</p>
          <img src="../assets/icons/arrow-right.svg" alt="" />
        </div>

        <div
          onClick={() => {
            navigate("mentorCallSlots");
          }}
          className={`interviewSettingMenuOption ${
            location.pathname ===
              "/settings/mentorCallSlots/createMentorCallSlot" ||
            location.pathname === "/settings/mentorCallSlots"
              ? "active"
              : ""
          }`}
        >
          <p>Mentor Call Slots</p>
          <img src="../assets/icons/arrow-right.svg" alt="" />
        </div> */}
      </div>
    </>
  );
};

export default SettingsSideMenu;
