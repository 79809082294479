import React, { useState } from "react";
import "./approvalListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import CreateInterviewPopup from "../../../components/Popups/createInterviewPopup/createInterviewPopup";

const ApprovalList = () => {
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [type, setType] = useState("");
  const togglePopup = () => {
    setIsPopupActive(!isPopupActive);
    setType("");
  };
  return (
    <>
      <SideMenu />
      <CreateInterviewPopup
        type={type}
        togglePopup={togglePopup}
        show={isPopupActive}
      />
      <div className="main-body">
        <div className="page-header">
          <button className="component-back">
            <img src="../assets/icons/back-btn.svg" />
            back to page
          </button>
          {/* <p className="text-1">PGP in Technology & Business Management</p> */}
        </div>
        <div className="filter-bar">
          <form action="" className="search-bar">
            <img src="../assets/icons/search.svg" alt="" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Search"
              className="clean-input"
            />
          </form>
          <div className="filters">
            <select id="type">
              <option value="">Program Type</option>
              <option value="className">className</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
            <select id="type">
              <option value="">Program Type</option>
              <option value="className">className</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
            <select id="type">
              <option value="">Program Type</option>
              <option value="className">className</option>
              <option value="General">General</option>
              <option value="Event">Event</option>
              <option value="Session">Session</option>
              <option value="Others">Others</option>
            </select>
            <select id="type">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div className="feedback-container scrollbar">
          <table
            className="feedback-table table"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th className="check-row">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="allCheck"
                      id="allCheck"
                      className="checkmark-btn"
                    />
                    <span className="checkmarkspan"></span>
                  </label>
                </th>
                <th>candidate name</th>
                <th>Phone Number</th>
                <th>Gmail</th>
                <th>Time & Date</th>
                <th>interviewer name</th>
                <th>interview</th>
                <th>Result</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className="selection">
                <td className=" pl-0" colSpan="12">
                  <table
                    className="selection-table"
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            All <b>50</b> Applicant on this page are selected.
                          </p>
                          {"  "}
                          <button className="primary-btn-a">
                            Send for Approval
                          </button>
                          <button className="danger-btn-a">Delete</button>
                          {/* <img src="../../assets/icons/delete.svg" alt="" /> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="checkbox-label">
                    <input type="checkbox" className="checkmark-btn" />
                    <span className="checkmarkspan"></span>
                  </label>
                </td>
                <td>Utkarsh Rathore</td>
                <td>9234567890</td>
                <td>utkarsh@gmail.com</td>
                <td>10/04/2021 & 10:00 AM</td>
                <td>Vikas Sharma</td>
                <td>
                  <p className="tag-active">Completed</p>
                </td>
                <td>
                  <p className="text-success">Pass</p>
                </td>
                <td className="text-center">
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                    <ul className="action-dropdown"></ul>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="checkbox-label">
                    <input type="checkbox" className="checkmark-btn" />
                    <span className="checkmarkspan"></span>
                  </label>
                </td>
                <td>Utkarsh Rathore</td>
                <td>9234567890</td>
                <td>utkarsh@gmail.com</td>
                <td>10/04/2021 & 10:00 AM</td>
                <td>Vikas Sharma</td>
                <td>
                  <p className="tag-draft">Pending</p>
                </td>
                <td>
                  <p className="">---</p>
                </td>
                <td className="text-center">
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                    <ul className="action-dropdown"></ul>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="checkbox-label">
                    <input type="checkbox" className="checkmark-btn" />
                    <span className="checkmarkspan"></span>
                  </label>
                </td>
                <td>Utkarsh Rathore</td>
                <td>9234567890</td>
                <td>utkarsh@gmail.com</td>
                <td>10/04/2021 & 10:00 AM</td>
                <td>Vikas Sharma</td>
                <td>
                  <p className="tag-active">Completed</p>
                </td>
                <td>
                  <p className="text-danger">Fail</p>
                </td>
                <td className="text-center">
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                    <ul className="action-dropdown active approval-drpdown">
                      <li
                        className="dropdown-item "
                        onClick={() => {
                          togglePopup();
                          setType("create");
                        }}
                      >
                        <img src="../assets/icons/edit.svg" alt="" />
                        <p className="dropdown-text">Interview Details</p>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          togglePopup();
                          setType("feedback");
                        }}
                      >
                        <img src="../assets/icons/edit.svg" alt="" />
                        <p className="dropdown-text">Candidate Feedback</p>
                      </li>
                      <li className="dropdown-item ">
                        <img src="../assets/icons/delete.svg" alt="" />
                        <p className="dropdown-text">Delete</p>
                      </li>
                    </ul>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ApprovalList;
