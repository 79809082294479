import React, { useState , useRef} from "react";
import "./ClubListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import DeletePopup from "../../../components/Popups/DeletePopup/DeletePopup";
import Reportpopup from "../../../components/Popups/ReportPopup/Reportpopup";
import UserListPopup from "../../../components/Popups/UserListPopup/UserListPopup";
import ClubCommentPopup from "../../../components/Popups/ClubCommentPopup/ClubCommentPopup";
import $ from "jquery";
const ClubList = () => {

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };
  const [isClubCommentOpen, setIsClubCommentOpen] = useState(false);
  const toggleClubCommentPopup = () => {
    setIsClubCommentOpen(!isClubCommentOpen);
  };
  const [isactiveDropDown, setIsactiveDropDown] = useState(false);
  const toggleReview = (id) => {    
    setIsactiveDropDown(!isactiveDropDown);
  }
  // const {current: fieldId} = useRef("prefix-" + (Math.random().toString(36)+'00000000000000000').slice(2, 7))
  let current = 0

var  generateId = (prefix)=> {
  var id= `${prefix || 'id'}-${current++}`;
  return id;
}
  const changeContainer = (show, hide1, hide2) => {
    $(`#btn-${show}`).addClass("active");
    $(`#btn-${hide1}`).removeClass("active");
    $(`#btn-${hide2}`).removeClass("active");

    $(`#contain-${show}`).addClass("show");
    $(`#contain-${hide1}`).removeClass("show");
    $(`#contain-${hide2}`).removeClass("show");
  };
  return (
    <React.Fragment>
      <SideMenu />
      <DeletePopup handleClose={toggleDeletePopup} show={isDeleteOpen} />
      <ClubCommentPopup handleClose={toggleClubCommentPopup} show={isClubCommentOpen} />
      <div className="main-body">
        <div className="page-header club-header">
          <button className="primary-clean active" id="btn-1" onClick={() => changeContainer(1, 2, 3)}>
            Club List
          </button>
          <button className="primary-clean" id="btn-2" onClick={() => changeContainer(2, 1, 3)}>
            New Requests
          </button>
          <button className="primary-clean" id="btn-3" onClick={() => changeContainer(3, 2, 1)}>
            Rejected list
          </button>
          <button className="primary-btn feed-btn club-header-btn">
            <img src="../assets/icons/plus.svg" alt="" /> Add Club
          </button>
        </div>
        {/* Club-list container */}
        <div className="club-list show " id="contain-1" style={{ display: "none" }}>
          <div className="filter-bar">
            <form action="" className="search-bar">
              <img src="../assets/icons/search.svg" alt="" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Search"
                className="clean-input"
              />
            </form>
            <div className="filters">
              <select id="type">
                <option value="hide">Feedback Type</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
              <select id="type">
                <option value="hide">Status</option>
                <option value="1">Expired</option>
                <option value="2">Draft</option>
                <option value="3">Active</option>
              </select>
              <select id="type">
                <option value="hide">1-50</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <div className="feedback-container scrollbar">
            <table className="feedback-table" cellPadding={0} cellSpacing0>
              <thead>
                <tr className="p-20">
                  <th>Club Name</th>
                  <th>President Name</th>
                  <th>Club Type</th>
                  <th>Events</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="p-20">
                  <td>Music Club</td>
                  <td>Anand Krishnan</td>
                  <td>Sport</td>
                  <td> 
                    <p className="text-primary">4 upcoming</p>{" "}
                    <p className="text-danger">5 past</p>
                  </td>
                  <td>
                    <p className="tag-unpublish">unpublished</p>
                  </td>
                  <td className="text-center">
                    <button className="action-btn">
                      <img src="../assets/icons/options.svg" />
                    </button>
                  </td>
                </tr>
                <tr className="p-20">
                  <td>Music Club</td>
                  <td>Anand Krishnan</td>
                  <td>Sport</td>
                  <td>
                    <p className="text-primary">10 upcoming</p>{" "}
                    <p className="text-danger">15 past</p>
                  </td>
                  <td>
                    <p className="tag-publish">published</p>
                  </td>
                  <td className="text-center">
                    <button className="action-btn">
                      <img src="../assets/icons/options.svg" />
                    </button>
                  </td>
                </tr>
                <tr className="p-20">
                  <td>Music Club</td>
                  <td>Anand Krishnan</td>
                  <td>Sport</td>
                  <td>
                    <p className="text-primary">4 upcoming</p>{" "}
                    <p className="text-danger">5 past</p>
                  </td>
                  <td>
                    <p className="tag-unpublish">unpublished</p>
                  </td>
                  <td className="text-center">
                    <button className="action-btn">
                      <img src="../assets/icons/options.svg" />
                    </button>
                  </td>
                </tr>
                <tr className="p-20">
                  <td>Music Club</td>
                  <td>Anand Krishnan</td>
                  <td>Sport</td>
                  <td>
                    <p className="text-primary">10 upcoming</p>{" "}
                    <p className="text-danger">15 past</p>
                  </td>
                  <td>
                    <p className="tag-publish">published</p>
                  </td>
                  <td className="text-center">
                    <button className="action-btn">
                      <img src="../assets/icons/options.svg" />
                    </button>
                  </td>
                </tr>
                <tr className="p-20">
                  <td>Music Club</td>
                  <td>Anand Krishnan</td>
                  <td>Sport</td>
                  <td>
                    <p className="text-primary">4 upcoming</p>{" "}
                    <p className="text-danger">5 past</p>
                  </td>
                  <td>
                    <p className="tag-unpublish">unpublished</p>
                  </td>
                  <td className="text-center">
                    <button className="action-btn">
                      <img src="../assets/icons/options.svg" />
                    </button>
                    <ul className="action-dropdown">
                      <li className="dropdown-item">
                        <img src="../assets/icons/edit.svg" alt="" />
                        <p className="dropdown-text">Edit</p>
                      </li>
                      <li className="dropdown-item">
                        <img src="../assets/icons/preview.svg" alt="" />
                        <p className="dropdown-text">Preview</p>
                      </li>
                      <li className="dropdown-item" onClick={toggleDeletePopup}>
                        <img src="../assets/icons/delete.svg" alt="" />
                        <p className="dropdown-text text-danger">Delete</p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr className="p-20">
                  <td>Music Club</td>
                  <td>Anand Krishnan</td>
                  <td>Sport</td>
                  <td>
                    <p className="text-primary">10 upcoming</p>{" "}
                    <p className="text-danger">15 past</p>
                  </td>
                  <td>
                    <p className="tag-publish">published</p>
                  </td>
                  <td className="text-center">
                    <button className="action-btn">
                      <img src="../assets/icons/options.svg" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* New request container */}
        <div className="new-request " id="contain-2" style={{ display: "none" }}>
          <div className="filter-bar club-filter">
            <form action="" className="search-bar">
              <img src="../assets/icons/search.svg" alt="" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Search"
                className="clean-input"
              />
            </form>
            <div className="filters">
              <select id="type">
                <option value="hide">Feedback Type</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
              <select id="type">
                <option value="hide">Status</option>
                <option value="1">Expired</option>
                <option value="2">Draft</option>
                <option value="3">Active</option>
              </select>
              <select id="type">
                <option value="hide">1-50</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <div className="newrequest-container scrollbar">
            <table className="newrequest-table" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr className="">
                  <th className="">Club Name</th>
                  <th className="">President Name </th>
                  <th>Club Type</th>
                  <th className="td-size">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="">Music club
                  <div className="tooltip">
                      <img src="../assets/icons/info-red.svg" className="tooltip" /><span className="tooltiptext">Comment</span>
                    </div>
                  </td>
                  <td className="">Anand Krishnan</td>
                  <td>Sport</td>

                  <td className="display-flex club-dropdown">
                    <button className="danger-btn-outline-blue" >Review</button>
                    <button className="action-btn"onClick={toggleReview}id={generateId()} >
                      <img src="../assets/icons/options.svg" />
                    </button>
                    <ul className={`action-dropdown club-action-dropdown  ${isactiveDropDown ? "active" : ""}`}>
                      <li className="dropdown-item" onClick={toggleClubCommentPopup}>
                        <img src="../assets/icons/Chats.svg" alt="" />
                        <p className="dropdown-text">Leave comment</p>
                      </li>
                      <li className="dropdown-item">
                        <img src="../assets/icons/delete.svg" alt="" />
                        <p className="dropdown-text text-danger">Delete</p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr className="">
                  <td className="">Music club</td>
                  <td className="">Anand Krishnan</td>
                  <td>Sport</td>

                  <td className="display-flex club-dropdown ">
                    <button className="danger-btn-outline-blue" >Review</button>
                    <button className="action-btn"onClick={toggleReview} id={generateId()}>
                      <img src="../assets/icons/options.svg" />
                    </button>
                    <ul className={`action-dropdown club-action-dropdown  ${isactiveDropDown ? "active" : ""}`}>
                      <li className="dropdown-item" onClick={toggleClubCommentPopup}>
                        <img src="../assets/icons/Chats.svg" alt="" />
                        <p className="dropdown-text">Leave comment</p>
                      </li>
                      <li className="dropdown-item">
                        <img src="../assets/icons/delete.svg" alt="" />
                        <p className="dropdown-text text-danger">Delete</p>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        {/* Rejected List */}
        <div className="new-request reject-request " id="contain-3" style={{ display: "none" }}>
          <div className="filter-bar club-filter">
            <form action="" className="search-bar">
              <img src="../assets/icons/search.svg" alt="" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Search"
                className="clean-input"
              />
            </form>
            <div className="filters">
              <select id="type">
                <option value="hide">Feedback Type</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
              <select id="type">
                <option value="hide">Status</option>
                <option value="1">Expired</option>
                <option value="2">Draft</option>
                <option value="3">Active</option>
              </select>
              <select id="type">
                <option value="hide">1-50</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <div className="newrequest-container scrollbar">
            <table className="newrequest-table" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr className="">
                  <th className="">Club Name</th>
                  <th className="">President Name </th>
                  <th>Club Type</th>
                  <th className="td-size text-center ">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr className="">
                <td className="">Music club</td>
                <td className="">Anand Krishnan</td>
                <td>Sport</td>
                
                <td className="club-dropdown  text-center">
                <button className="danger-btn-outline-blue">Review</button>
                  <button className="action-btn">
                    <img src="../assets/icons/options.svg" />
                  </button>
                  <ul className="action-dropdown club-action-dropdown">
                    <li className="dropdown-item">
                      <img src="../assets/icons/Chats.svg" alt="" />
                      <p className="dropdown-text">Leave comment</p>
                    </li>
                    <li className="dropdown-item">
                      <img src="../assets/icons/delete.svg" alt="" />
                      <p className="dropdown-text text-danger">Delete</p>
                    </li>
                  </ul>
                </td>
              </tr> */}
                <tr className="">
                  <td className="">Music club</td>
                  <td className="">Anand Krishnan</td>
                  <td>Sport</td>

                  <td className="club-dropdown  text-center">
                    {/* <button className="danger-btn-outline-blue">Review</button> */}
                    <button className="action-btn">
                      <img src="../assets/icons/options.svg" />
                    </button>
                    <ul className="action-dropdown club-action-dropdown active">
                      <li className="dropdown-item">
                        <img src="../assets/icons/Eye.svg" alt="" />
                        <p className="dropdown-text">Club Details</p>
                      </li>
                      <li className="dropdown-item">
                        <img src="../assets/icons/delete.svg" alt="" />
                        <p className="dropdown-text text-danger">Delete</p>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default ClubList;
