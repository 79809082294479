import React from 'react'
import './AskDetailsPopupStyle.css'

const AskDetailsPopup = (props) => {
    return (
        <>
            <div className={`popup-outer ${props.show ? 'show-popup' : ''}`}>
                <div className="popup-container sendFeePopup">
                    <div className="popup-header">
                        <div className="popup-heading">
                            Ask for more details
                            <img
                                src="../assets/icons/close.svg"
                                alt=""
                                className="close-btn"
                                onClick={props.handleAskDetailsClose}

                            />
                        </div>
                    </div>
                    <div className="popup-body">
                        <div className="fee-option" onClick={()=>{props.handleAdditionalInfoClose();props.handleAskDetailsClose()}} >
                            Send to Team Member
                            <img src="../assets/icons/ArrowLeftBlack.svg" alt="" />
                            <img src="../assets/icons/ArrowLeft.svg" alt="" />
                        </div>
                        <div className="fee-option" onClick = {()=>{props.handleFinancePaymentPopup();props.handleAskDetailsClose()}}>
                            Send to Approving Authority
                            <img src="../assets/icons/ArrowLeftBlack.svg" alt="" />
                            <img src="../assets/icons/ArrowLeft.svg" alt="" />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AskDetailsPopup