import React, { useEffect, useState } from "react";
import "./FlexlistStyle.css";
import "../studentFeeList/studentFeeListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import PageLoader from "../../../components/UI/loader/PageLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "../studentCourseFeeList/studentCourseFeeListStyle.css";
import {
  clearState,
  downloadStudentFlexCSVDetails,
  feeData,
  getParticularStudentFlexDetails,
  getStudentFlexDetails,
} from "../../../reducers/feeSlice";
import { environment } from "../../../environments/environment";
import moment from "moment";
import $ from "jquery";

const StudentLoanRequest = () => {
  const { isFetching, isError, flexList, studentFlexList, isSuccess } =
    useSelector(feeData);
  const [searchTitle, setSearchTitle] = useState("");
  const [studentFlexData, setStudentFlexData] = useState([]);
  const [menuIndex, setMenuIndex] = useState(null);

  const [searchParams] = useSearchParams();
  const programBatchId = searchParams.get("programBatchId");
  const programMasterId = searchParams.get("programMasterId");
  const [show, setShow] = useState(false);
  const [menuId, setMenuId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    let obj = {
      programBatchId: programBatchId,
      searchTitle: "",
      programMasterId: programMasterId,
      downloadCsv: false,
    };

    dispatch(getStudentFlexDetails(obj));
  }, []);

  const handleSearch = (event) => {
    var search = event.target.value;
    setSearchTitle(search);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchByTitle(e.target.value);
    }
  };

  const searchByTitle = (val) => {
    let obj = {
      programBatchId: programBatchId,
      searchTitle: val,
      programMasterId: programMasterId,
      downloadCsv: false,
    };

    dispatch(getStudentFlexDetails(obj));
  };

  const onClickCsvDownload = () => {
    var url =
      environment.baseURL +
      "/api/org/" +
      localStorage.getItem("orgId") +
      "/program/" +
      programBatchId +
      "/programMasterId/" +
      programMasterId +
      `/downloadStudentFlexCsv?searchParam=${searchTitle}`;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const viewStudent = (id) => {
    var isStudent = true;
    window.open(
      `/particularStudentfee?studentId=${id}&programBatchId=${programBatchId}&isStudent=${isStudent}`
    );
  };

  const onClickShowHide = () => {};

  const openActionMenu = (id, studentId) => {
    setStudentFlexData([]);
    console.log("whats is menu", menuId);
    console.log("whats is id", id);
    $("#flextable-table tr").remove();

    if (menuId === id) {
      console.log("inside if condition");

      setMenuId(null);
      particularStudentFlexDetails(studentId);
    } else {
      console.log("inside else condition");

      setMenuId(id);
      particularStudentFlexDetails(studentId);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const particularStudentFlexDetails = (studentId) => {
    dispatch(getParticularStudentFlexDetails(studentId));
  };

  console.log("fmeifnenfineifef", studentFlexList);

  useEffect(() => {
    if (studentFlexList.length > 0) {
      setStudentFlexData(studentFlexList);
    }
  }, [studentFlexList]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isSuccess]);

  const formatDateMoment = (date) => {
    return moment(date).format("LL");
  };

  const checkDueOnDate = (oldDate) => {
    var isDueOn = false;
    var todayDate = new Date();
    var dateOne = new Date(oldDate);
    if (todayDate > dateOne) {
      return (isDueOn = false);
    } else {
      return (isDueOn = true);
    }
  };

  return (
    <>
      <SideMenu />

      {isFetching && <PageLoader />}

      <div className="main-body popupFixed">
        <div className="popupFixedContainer">
          <div className="page-header">
            <button
              className="component-back"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src="../assets/icons/back-btn.svg" />
              Back to Page
            </button>
            <div className="studentDetails">
              <div className="student-inner-left">
                <div className="student-details-outer">
                  {/* <div className="student-details">
                    <p className="student-name text-18">
                      {flexList?.programDetails?.length > 0 &&
                        flexList?.programDetails[0]?.Program?.programName}{" "}
                      <img src="../assets/icons/sectionSymbol.svg" />{" "} */}
                  <span className="text-danger">Flex Summary</span>
                  {/* </p>
                  </div>

                  <div className="student-details">
                    <p className="student-mail">
                      {flexList?.programDetails?.length > 0 &&
                        flexList?.programDetails[0]?.name}
                    </p>
                  </div> */}
                </div>

                <div className="student-details-outer">
                  {/* <div className="student-details">
                    <p className="student-name text-18">
                      {flexList?.programDetails?.length > 0 &&
                        flexList?.programDetails[0]?.Program?.programName}{" "}
                      <img src="../assets/icons/sectionSymbol.svg" />{" "} */}
                  <span
                    onClick={() => {
                      onClickCsvDownload();
                    }}
                    className="text-danger"
                  >
                    CSV
                  </span>
                  {/* </p>
                  </div>

                  <div className="student-details">
                    <p className="student-mail">
                      {flexList?.programDetails?.length > 0 &&
                        flexList?.programDetails[0]?.name}
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="filter-bar">
            <form action="" className="search-bar">
              <img src="../assets/icons/search.svg" alt="" />
              <input
                type="text"
                name=""
                id=""
                onChange={handleSearch}
                onKeyDown={onSearchKeyPress}
                placeholder="Search"
                className="clean-input"
              />
            </form>

            {/* <div className="total-data-desc">
              Showing {shortListDataArr?.length} out of {shortListCount}
            </div> */}

            {/* <div className="filters">
              <select id="type" onChange={(e) => setPageSize(e.target.value)}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div> */}
          </div>

          <div className="student-table-outer flexlistWrapper scrollbar flexTable">
            <table className="feedback-table" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Email</th>
                  <th>Program</th>
                  <th>Total Flex</th>
                  <th>Given Flex Amount</th>
                  <th>Flex Received Count</th>
                  <th>Flex Amount Received</th>
                  <th>Flex To Be Received Count</th>
                  <th>Flex Amount To Be Received</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {flexList?.Data?.map((item, index) => (
                  <React.Fragment key={item.studentId}>
                    <tr>
                      <td>
                        {/* {(item.firstName + " " + item.lastName)
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() + word.substring(1)
                        )
                        .join(" ")} */}

                        <div className="programDetail">
                          <div
                            onClick={() => viewStudent(item.studentId)}
                            className="programDetailText"
                          >
                            <p className="primary-text pointer">
                              {(item.firstName + " " + item.lastName)
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.substring(1)
                                )
                                .join(" ")}
                            </p>
                          </div>
                          {/* {studentStatus === "Active" ? ( */}
                          <div className="activeCourse">Active</div>
                          {/* // ) : (
                        //   ""
                        // )} */}
                          {/* {studentStatus === "DropOut" ? (
                          <div className="expiredCourse">Dropout</div>
                        ) : (
                          ""
                        )}
                        {studentStatus === "Defer" ? (
                          <div className="DeferCourse">Defer</div>
                        ) : (
                          ""
                        )} */}
                        </div>
                      </td>
                      <td>{item.officialEmail.toLowerCase()}</td>
                      <td>
                        {" "}
                        <div className="flextd">
                          <p>{item.programName}</p>{" "}
                          <p className="primary-tag">{item.batch}</p>
                        </div>{" "}
                      </td>
                      <td className="primaryTd">{item.totalFeeDues}</td>
                      <td className="primaryTd">{item.totalFeeDuesSum}</td>
                      <td className="successTd">{item.isFlexRecievedCount}</td>
                      <td className="successTd">{item.isFlexRecievedSum}</td>
                      <td className="dangerTd">
                        {item.isFlexToBeRecievedCount}
                      </td>
                      <td className="dangerTd">
                        {item.isFlextToBeRecievedSum}
                      </td>
                      <td
                        onClick={() => openActionMenu(index, item.studentId)}
                        // onBlur={closeActionMenu}
                        className="textBlue"
                      >
                        {menuId === index ? "Less" : "More"}
                      </td>
                    </tr>
                    <tr className="dropsliderTable">
                      <td colSpan={10} className="sliderDownTD">
                        <div
                          className={
                            menuId === index
                              ? "sliderDownTable active"
                              : "sliderDownTable"
                          }
                          // className="sliderDownTable active"
                        >
                          {/* add active here on click */}
                          <table
                            className="flextable-table"
                            cellPadding={0}
                            cellSpacing={0}
                          >
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Due Amount</th>
                                <th>Due On</th>
                                <th>Amount Received</th>
                                <th>Paid On</th>
                                <th>Payment Status</th>
                                <th>Bank Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentFlexData.length > 0 &&
                                studentFlexData?.map((data, ind) => {
                                  const {
                                    feeDue,
                                    amount,
                                    bankName,
                                    id,
                                    paymentStatus,
                                    paidOn,
                                    dueOn,
                                    isPaid,
                                  } = data;

                                  return (
                                    <tr key={id}>
                                      <td>{ind + 1}</td>
                                      <td>₹ {feeDue}</td>
                                      <td>
                                        {isPaid ? (
                                          <p className="text-success">
                                            {moment(dueOn).format(
                                              "DD MMMM YYYY"
                                            )}
                                          </p>
                                        ) : (
                                          <>
                                            {checkDueOnDate(dueOn) === false ? (
                                              <p className="text-danger">
                                                {moment(dueOn).format(
                                                  "DD MMMM YYYY"
                                                )}
                                              </p>
                                            ) : (
                                              <p className="text-success">
                                                {moment(dueOn).format(
                                                  "DD MMMM YYYY"
                                                )}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      </td>
                                      <td>{amount ? "₹ " + amount : "--"}</td>
                                      <td>
                                        {paidOn ? (
                                          <p className="text-success">
                                            {moment(paidOn).format(
                                              "DD MMMM YYYY"
                                            )}
                                          </p>
                                        ) : (
                                          "--"
                                        )}
                                      </td>
                                      <td>
                                        {paymentStatus === "paid" ? (
                                          <p className="text-success">Paid</p>
                                        ) : (
                                          "Pending"
                                        )}
                                      </td>
                                      <td>{bankName ? bankName : "--"}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentLoanRequest;
