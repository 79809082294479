import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { COMMON_TEXT } from "../../../constants/textConstant";
import { layoutSelector } from "../../../reducers/pageLayoutSlice";

import Dropdown from "../../Dropdowns/Dropdown/Dropdown";
import DropdownLayout from "../../Dropdowns/DropdownLayout/DropdownLayout";

import "./EditQuestionStyle.css";

function EditQuestion({
  isEditQuestion,
  setNewQuestion,
  questionArray,
  showEditQuestion,
  questionDetail,
  setQuestionDetail,
}) {
  const defaultQuestionType = {
    type: "Choose Question type",
    image: null,
  };

  const inputArr = [
    {
      value: "",
    },
  ];

  var defaultQuestion = "";

  var type = false;

  const [selected, setSelected] = useState(defaultQuestionType);

  const [questionId, setQuestionId] = useState("");
  const [isQuestionMandatory, setChecked] = useState(false);
  const [questionTitle, setQuestionTitle] = useState(defaultQuestion);
  const [optionArray, setOptionArray] = useState(inputArr);
  const [validateQuestionTitle, setQuestionTitleValidation] = useState(false);

  useEffect(() => {
    if (questionTitle === "") {
      if (selected.type === "singleSelect") {
        setQuestionTitle("Please select only one option from below options");
      } else if (selected.type === "paragraph") {
        setQuestionTitle("Please give your reviews in the below section");
      } else if (selected.type === "multiSelect") {
        setQuestionTitle("Please select many option from below options");
      } else if (selected.type === "rating") {
        setQuestionTitle("Please give us rating accordingly");
      } else if (selected.type === "uploadMedia") {
        setQuestionTitle("Please upload video in below button");
      }
    }
  }, [selected.type]);

  useEffect(() => {
    if (questionDetail.length > 0) {
      setQuestionTitle(questionDetail[0].questionName);
      setOptionArray(questionDetail[0].option);
      setQuestionId(questionDetail[0].id);
      setChecked(questionDetail[0].isMandatory);
    }
  }, [questionDetail]);

  if (selected.type === "singleSelect" || selected.type === "multiSelect") {
    type = true;
  }
  const addOption = () => {
    setOptionArray([
      ...optionArray,
      {
        value: "",
      },
    ]);
  };

  const onChangeQuestionTitle = (event) => {
    setQuestionTitle(event.target.value);
  };

  const handleInputChange = (e) => {
    const index = e.target.id;
    setOptionArray((s) => {
      const newArr = s.slice();
      // newArr[index].value = e.target.value;
      newArr[index] = { ...newArr[index], value: e.target.value };

      return newArr;
    });
  };

  const removeOptionItem = (id) => {
    var filteredOption = optionArray.filter(function (item, index) {
      return index !== id;
    });

    setOptionArray(filteredOption);
  };

  const addQuestionDetail = () => {
    if (questionTitle === "") {
      setQuestionTitleValidation(true);
    } else {
      setQuestionTitleValidation(false);
      if (questionId) {
        const i = questionArray.findIndex((_item) => _item.id === questionId);
        if (i != -1) {
          var item = {
            id: questionId,
            questionType: selected.type,
            questionName: questionTitle,
            isMandatory: isQuestionMandatory,
            option: optionArray,
          };
          questionArray[i] = item;
        } else {
          var item = {
            id: questionArray.length + 1,
            questionType: selected.type,
            questionName: questionTitle,
            isMandatory: isQuestionMandatory,
            option: optionArray,
          };
          setNewQuestion([...questionArray, item]);
        }
      } else {
        var item = {
          id: questionArray.length + 1,
          questionType: selected.type,
          questionName: questionTitle,
          isMandatory: isQuestionMandatory,
          option: optionArray,
        };
        setNewQuestion([...questionArray, item]);
      }

      showEditQuestion(defaultQuestionType, false);
      setQuestionTitle(defaultQuestion);
      setOptionArray(inputArr);
      setQuestionDetail([]);
      setQuestionId("");
      setChecked(false);
    }
  };

  const handleChange = (e) => {
    const { checked } = e.target;
    setChecked(checked);

    console.log("gffgfggffg", checked);
  };

  return (
    <div className={`component-outer ${isEditQuestion ? "slide-in" : ""}`}>
      <div className="sec-1">
        <button
          className="component-back"
          // onClick={props.showEditQuestion}
          onClick={addQuestionDetail}
        >
          <img src="../assets/icons/back-btn.svg" />
          <p className="text-5">{COMMON_TEXT.questionDetails}</p>
        </button>
      </div>

      <div className="sec-2">
        <div className="sec-2-inner">
          <label htmlFor="questions" className="text-6">
            Question Type
          </label>
          <Dropdown
            selected={selected}
            setSelected={setSelected}
            name="questions"
          />
        </div>
      </div>
      <div className="sec-2">
        <div className="sec-2-inner">
          <label htmlFor="question-input">
            <p className="text-6">Question</p>
          </label>
          <textarea
            id="question-input"
            name="question-input"
            rows="4"
            className="question-c-text-area text-6"
            placeholder="Enter your Question."
            value={questionTitle}
            // defaultValue={questionTitle}
            onChange={onChangeQuestionTitle}
          ></textarea>

          {validateQuestionTitle && <span>Enter question title</span>}
          <div className="mt-15 checking">
            <input
              type="checkbox"
              checked={isQuestionMandatory}
              onChange={(e) => handleChange(e)}
              className="checkmark-btn"
              aria-checked
            ></input>

            <span className="checkmarkspan"></span>
            <label htmlFor="mandatory" className="check-label">
              This feedback question is not mandatory
            </label>
          </div>
        </div>
      </div>
      {type && (
        <>
          <div className="sec-2">
            <div className="sec-2-inner">
              <div className="options-comp" id="option-sec">
                {optionArray.map((item, index) => {
                  return (
                    <div className="sub-option" key={index}>
                      <label
                        htmlFor={index + 1}
                        className="sub-option-left text-6"
                      >
                        Option {index + 1}
                      </label>
                      <input
                        className=" text-6"
                        placeholder="Type here...."
                        type="text"
                        id={index}
                        value={item.value}
                        onChange={handleInputChange}
                      />
                      {optionArray.length > 1 && (
                        <img
                          src="../assets/icons/cross.svg"
                          alt=""
                          className="option-remove"
                          onClick={() => removeOptionItem(index)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="sec-2">
            <div className="sec-2-inner">
              <button
                className="add-option text-6"
                value="+"
                onClick={addOption}
              >
                <img src="../assets/icons/plus-red.svg" alt="red-plus" /> Add
                Option
              </button>
            </div>
          </div>
        </>
      )}
      
      <div className="sec-2">
            <div className="sec-2-inner">
              <button
                className="add-option text-6"
                onClick={addQuestionDetail}
              >
                 Save
              </button>
            </div>
          </div>
    </div>
  );
}

export default EditQuestion;
