import React, { useState } from 'react'
import './VendorPopupStyle.css'
const VendorPopup = (props) => {
    const [selectForm, setSelectForm] = useState(0)
    return (
        <div className={`popup-outer boxVendorPopup ${props.show ? "show-popup" : ""}`}>
            <div className="popup-container">
                <div className="popup-header">
                    <div className="popup-heading">
                        {selectForm === 0 && <p className='heading'>Add Member</p>}
                        {selectForm === 1 && <p className='heading'>Add Vendor</p>}
                        {selectForm === 2 && <p className='heading'>Add Logistics Partner</p>}
                        <img src="../assets/icons/close.svg" alt="" className="close-btn" />
                    </div>
                </div>
                {selectForm === 0 && <div className="popup-body">
                    <div className="fee-option" onClick={()=>setSelectForm(1)}>
                        Add Vendor
                        <img src="../assets/icons/arrowWhite.svg" alt="" />
                        <img src="../assets/icons/arrowWhite.svg" alt="" />
                    </div>
                    <div className="fee-option" onClick={()=>setSelectForm(2)}>
                        Add Logistics Partner
                        <img src="../assets/icons/arrowWhite.svg" alt="" />
                        <img src="../assets/icons/arrowWhite.svg" alt="" />
                    </div>
                </div>}
                {selectForm === 1 && <div className="popup-body">
                    <div className="form-group">
                        <label htmlFor="name" className="create-label">Name</label>
                        <input type="text" name="name" id="name" placeholder='Enter vendor name' className="create-input" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="create-label">Email ID</label>
                        <input type="email" name="email" id="email" placeholder='Enter vendor Email ID' className="create-input" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone" className="create-label">Mobile Number</label>
                        <input type="tel" name="phone" id="phone" placeholder='Enter vendor mobile number' className="create-input" />
                    </div>
                </div>}
                {selectForm === 2 && <div className="popup-body">
                    <div className="form-group">
                        <label htmlFor="name" className="create-label">Name</label>
                        <input type="text" name="name" id="name" placeholder='Enter vendor name' className="create-input" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="create-label">Email ID</label>
                        <input type="email" name="email" id="email" placeholder='Enter vendor Email ID' className="create-input" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone" className="create-label">Mobile Number</label>
                        <input type="tel" name="phone" id="phone" placeholder='Enter vendor mobile number' className="create-input" />
                    </div>
                </div>}
                {selectForm > 0 && <div className="popup-footer">
                    <button className="primary-btn-a w-100">Save</button>
                </div>
                }
            </div>
        </div>
    )
}

export default VendorPopup