import React from 'react'
import './UpdateBoxAutomationStyle.css'
const UpdateBoxAutomation = (props) => {
    return (
        <div className={`popup-outer updateBoxAutomation ${props.show ? "show-popup" : ""}`}>
            <div className="popup-container">
                <div className="popup-header">
                    <div className="popup-heading">
                        <p className='heading'>Update Boxes Automation</p>
                        <img src="../assets/icons/close.svg" alt="" className="close-btn" />
                    </div>
                </div>
                <div className="popup-body">
                    <div className="form2Col">
                        <div className="form-group">
                            <label htmlFor="select1"  className="create-label">Select your Vendor</label>
                            <select name="" className="customSelect" id="">
                                <option value="null" selected disabled>Please Select any option</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="select1"  className="create-label">Select your Logistics Partner</label>
                            <select name="" className="customSelect" id="">
                                <option value="null" selected disabled>Please Select any option</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                    </div>
                    <div className="updateBoxTableOuter">
                        <table className="updateBoxTable" cellSpacing={0} cellPadding={0}>
                            <thead>
                                <tr>
                                    <th className="check-row">
                                        <label className="checkbox-label">
                                            <input
                                                type="checkbox"
                                                name="allCheck"
                                                id="allCheck"
                                                className="checkmark-btn"
                                            />
                                            <span className="checkmarkspan"></span>
                                        </label>
                                    </th>
                                    <th>Applicant Name</th>
                                    <th>E-mail ID</th>
                                    <th>Phone Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="check-row">
                                        <label className="checkbox-label">
                                            <input
                                                type="checkbox"
                                                name="allCheck"
                                                id="allCheck"
                                                className="checkmark-btn"
                                            />
                                            <span className="checkmarkspan"></span>
                                        </label>
                                    </td>
                                    <td>Aman Seth</td>
                                    <td>RamnikaStudent@gmail.com</td>
                                    <td>2018-2020</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="popup-footer">
                    <div className="btn-grp">
                        <button className="primary-btn-a">Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateBoxAutomation