import React from "react";
import { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getStudentsPendingFeeDues, feeData } from "../../reducers/feeSlice";
import moment from "moment/moment";
import { useSearchParams } from "react-router-dom";
import PageLoader from "../../components/UI/loader/PageLoader";

const DueFee = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, FeeDueList } = useSelector(feeData);

  // const [DueList, setDueList] = useState([{}]);
  // const [selectedDate, setSelectedDate] = useState();
  // console.log(selectedDate, "selectedDate");
  const [searchParams] = useSearchParams();
  const [menuId, setMenuId] = useState(null);
  const [search, setSearch] = useState("");
  console.log(search, "search");
  const [isFilterMenuActive, setisFilterMenuActive] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // const [pageNo, setPageNo] = useState("1");

  // const [pageSize, setPageSize] = useState("10");
  // const orgId = searchParams.get("orgId") ?? "";

  const orgId = localStorage.getItem("orgId");

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  // useEffect(() => {
  //   console.log(FeeDueList);
  //   dispatch(getStudentsPendingFeeDues({ search }));
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    // console.log(FeeDueList);
    let obj = {
      searchTitle:search,
      fromDate: fromDate ? fromDate : moment(new Date()).format("YYYY-MM-DD"),
      toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
    };
    dispatch(getStudentsPendingFeeDues(obj));
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (selectedDate) {
  //     let obj = {
  //       // organizationId: orgId,
  //       searchTitle: "",
  //       fromDate: fromDate ? fromDate : moment(new Date()).format("YYYY-MM-DD"),
  //       toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
  //     };
  //     dispatch(getStudentsPendingFeeDues(obj));
  //   }
  //   // eslint-disable-next-line
  // }, [selectedDate]);

  //SearchBar
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (search !== "") {
        let obj = {
          searchTitle:search,
          fromDate: fromDate
            ? fromDate
            : moment(new Date()).format("YYYY-MM-DD"),
          toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
        };
        dispatch(getStudentsPendingFeeDues(obj));
        console.log("search", e.key);
        //  setSearch(e.target.value);
      }
    }
  };

  const ClearInput = () => {
    setSearch("");
    // setClearSearch(!clearSearch)
    let obj = {
      searchTitle:search,
      fromDate: fromDate ? fromDate : moment(new Date()).format("YYYY-MM-DD"),
      toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
    };
    dispatch(getStudentsPendingFeeDues(obj));
  };

  const applyFilter = () => {
    let obj = {
      searchTitle: search,
      fromDate: fromDate ? fromDate : moment(new Date()).format("YYYY-MM-DD"),
      toDate: toDate ? toDate : moment(new Date()).format("YYYY-MM-DD"),
    };
    dispatch(getStudentsPendingFeeDues(obj));
    setisFilterMenuActive(false);
  };

  const clearFilter = () => {
    setFromDate("");
    setToDate("");
    let obj = {
      searchTitle:search,
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
    };
    dispatch(getStudentsPendingFeeDues(obj));
    setisFilterMenuActive(false);
  };

  return (
    <>
      <div className={`filterMenuOuter ${isFilterMenuActive ? "active" : ""}`}>
        <div className="filterHeader">
          <div className="heading">Filters</div>
          <img
            src="../assets/icons/close.svg"
            alt=""
            className="pointer"
            onClick={() => setisFilterMenuActive(false)}
          />
        </div>
        <div className="filterBody">
          <p className="text-3 mt-10">From Date</p>
          <input
            type="date"
            className="new-filter-input mt-5"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <p className="text-3 mt-10">To Date</p>
          <input
            type="date"
            className="new-filter-input mt-5"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <div className="filterFooter">
          <div className="btnGrp">
            <button className="dark-btn-outline-a" onClick={clearFilter}>
              Clear
            </button>
            <button className="primary-btn-a" onClick={applyFilter}>
              Apply
            </button>
          </div>
        </div>
      </div>
      {isFetching && <PageLoader />}
      <div className="dueFeeListOuter">
        <div className="filter-bar">
          <div className="searchBox">
            {/* <img src="../assets/icons/search-sm.svg" alt="" /> */}
            <input
              type="text"
              className="searchInput"
              placeholder="Search ..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={handleKeyPress}
            />

            {search.length === 0 ? (
              <img src="../assets/icons/search-sm.svg" alt="" />
            ) : (
              <img
                src="../assets/icons/close.svg"
                onClick={ClearInput}
                alt=""
                className="pointer"
              />
            )}
          </div>
          <button
            className="filterBtn"
            onClick={() => setisFilterMenuActive(true)}
          >
            {" "}
            <img src="../assets/icons/filter.svg" alt="" /> Filters
          </button>
        </div>

        <div className="feeDueTableContainer">
          <div className="feeTableOuter">
            <table
              className="feedback-table  feeTable"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Due Date</th>
                  <th>Amount </th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                {FeeDueList.Data?.length > 0 &&
                  FeeDueList.Data?.map((res, index) => (
                    <tr key={res.id}>
                      <td>
                        {res.Applicant?.firstName} {res.Applicant?.lastName}
                      </td>
                      <td>{res.Applicant?.officialEmail}</td>
                      <td> {res.Applicant?.mobileNumber}</td>
                      <td>{moment(res.dueOn).format("DD/MM/YYYY")}</td>
                      <td>{res.feeDue}</td>
                      <td>{res.cashFreeStatus}</td>
                      <td className="text-center">
                        <button
                          className="action-btn"
                          onClick={() => openActionMenu(index)}
                          onBlur={closeActionMenu}
                        >
                          <img src="../assets/icons/options.svg" />
                          <ul
                            className={
                              menuId === index
                                ? "action-dropdown active approval-drpdown"
                                : "action-dropdown approval-drpdown"
                            }
                          >
                            <li
                              className="dropdown-item"
                              onClick={() => openInNewTab(res?.pdfLink)}
                            >
                              <img src="../assets/icons/Eye.svg" alt="" />
                              <p className="dropdown-text">Pdf receipt</p>
                            </li>
                            <li
                              onClick={() =>
                                navigator.clipboard.writeText(res?.paymentLink)
                              }
                              className="dropdown-item "
                            >
                              <img src="../assets/icons/copy.svg" alt="" />
                              <p className="dropdown-text">Copy Payment Link</p>
                            </li>
                          </ul>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DueFee;
