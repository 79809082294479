import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createMentorFeedback,
  clearFeedBack,
  meetingData,
} from "../../../reducers/meetingSlice";
import ErrorToast from "../../../components/Toast/ErrorToast";
const StudentMeetFeedback = (props) => {
  const { isFetching, meetingFeedBack } = useSelector(meetingData);
  const dispatch = useDispatch();
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [que1, setQue1] = useState("");
  const [que2, setQue2] = useState("");
  const [que3, setQue3] = useState("");
  const [que4, setQue4] = useState("");
  const [que5, setQue5] = useState("");
  const [que6, setQue6] = useState("");
  const [que7, setQue7] = useState("");

  let feedData =
    props?.singleFeedBackDetail &&
    props?.singleFeedBackDetail?.MeetFeedbacks?.filter(
      (res) => res.feedbackFor === "Mentor"
    );
  const handleClose = () => {
    setQue1("");
    setQue2("");
    setQue3("");
    setQue4("");
    setQue5("");
    setQue6("");
    setQue7("");
    props.handleClose();
  };

  useEffect(() => {
    if (meetingFeedBack) {
      dispatch(clearFeedBack());
      handleClose();
    }
    if (feedData?.length > 0) {
      setQue1(feedData && feedData[0]?.response1);
      setQue2(feedData && feedData[0]?.response2);
      setQue3(feedData && feedData[0]?.response3);
      setQue4(feedData && feedData[0]?.response4);
      setQue5(feedData && feedData[0]?.response5);
      setQue6(feedData && feedData[0]?.response6);
      setQue7(feedData && feedData[0]?.response7);
      // setQue8(feedData && feedData[0]?.response8);
    }
  }, [meetingFeedBack, feedData]);
  console.log(feedData, "dfuyugtjgukhyutcdtxsdtjg");

  return (
    <>
    <ErrorToast
      show={isShowError}
      setShow={() => setIsShowError(false)}
      message={errMessage}
    />
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container feedback-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Feedback Form
              {feedData?.length > 0 ? (
                <img
                  src="../assets/icons/close.svg"
                  alt=""
                  className="close-btn"
                  onClick={handleClose}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="popup-body scrollbar">
            <div className="popup-form-outer scrollbar active">
              <form className="popup-form scrollbar active">
                {/* 1 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                    How was your todays’ interaction with the Coach. Rate your
                    satisfaction on the scale of 1-5, 5 being the highest ?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10">
                    <p className="text-1">Poor</p>
                    {["1", "2", "3", "4", "5"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que1"
                          value={val}
                          checked={que1 === val}
                          onChange={(e) => setQue1(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    <p className="text-1">Outstanding</p>
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 2 */}
                <div className="form-grp mt-20 ">
                  <label htmlFor="">
                    Were you able to achieve the agenda of this session ?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10 flex-start">
                    {/* <p className="text-1">Poor</p> */}
                    {["Yes", "No"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que2"
                          value={val}
                          checked={que2 === val}
                          onChange={(e) => setQue2(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    {/* <p className="text-1">Outstanding</p> */}
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 3 */}
                <div className="form-grp mt-20 ">
                  <label htmlFor="">
                    Was this session able to contribute to your learning journey
                    ? <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10 flex-start">
                    {/* <p className="text-1">Poor</p> */}
                    {["Yes", "No"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que3"
                          value={val}
                          checked={que3 === val}
                          onChange={(e) => setQue3(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    {/* <p className="text-1">Outstanding</p> */}
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 4 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                    Did you face any challenges during this session?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10 flex-start">
                    {/* <p className="text-1">Poor</p> */}
                    {["Yes", "No"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que4"
                          value={val}
                          checked={que4 === val}
                          onChange={(e) => setQue4(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    {/* <p className="text-1">Outstanding</p> */}
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 5 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                    How relevant do you think the engagement was? Rate on the
                    scale of 1-5, 5 being the highest?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10">
                    <p className="text-1">Poor</p>
                    {["1", "2", "3", "4", "5"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que5"
                          value={val}
                          checked={que5 === val}
                          onChange={(e) => setQue5(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    <p className="text-1">Outstanding</p>
                  </div>
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 6 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                    Highlight a skill/quality of your Coach that you wish to
                    imbibe? <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10">
                    <textarea
                      id="question-input"
                      name="que8"
                      rows="5"
                      className="question-c-text-area text-6 scrollbar"
                      // placeholder="Enter your suggestions."
                      value={que6}
                      onChange={(e) => setQue6(e.target.value)}
                    ></textarea>
                  </div>
                  {/* <div className="radio-group mt-10">
                    <p className="text-1">Poor</p>
                    {["1", "2", "3", "4", "5"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que6"
                          value={val}
                          checked={que6 === val}
                          onChange={(e) => setQue6(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    <p className="text-1">Outstanding</p>
                  </div> */}
                  <span className="error-msg">Enter the date</span>
                </div>
                {/* 7 */}
                <div className="form-grp mt-20">
                  <label htmlFor="">
                    Do you wish to share any suggestions?{" "}
                    <span className="imp-mark">*</span>
                  </label>
                  <div className="radio-group mt-10">
                    <textarea
                      id="question-input"
                      name="que8"
                      rows="5"
                      className="question-c-text-area text-6 scrollbar"
                      // placeholder="Enter your suggestions."
                      value={que7}
                      onChange={(e) => setQue7(e.target.value)}
                    ></textarea>
                  </div>
                  {/* <div className="radio-group mt-10">
                    <p className="text-1">Poor</p>
                    {["1", "2", "3", "4", "5"].map((val) => (
                      <label className="create-label" key={val}>
                        <input
                          className="radio-btn"
                          type="radio"
                          name="que7"
                          value={val}
                          checked={que7 === val}
                          onChange={(e) => setQue7(e.target.value)}
                        />
                        <span className="radiomark"></span>
                        {val}
                      </label>
                    ))}
                    <p className="text-1">Outstanding</p>
                  </div> */}
                  <span className="error-msg">Enter the date</span>
                </div>
              </form>
            </div>
          </div>
          <div className="popup-footer ">
            {feedData?.length > 0 ? (
              ""
            ) : (
            //   <button className="primary-btn" onClick={feedBackSubmit}>
            //     Submit
            //   </button>
            ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentMeetFeedback;
