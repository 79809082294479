import React, { useEffect, useState } from "react";
import "./interviewListStyle.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import AddStudentListPopup from "../../../components/Popups/AddStudentListPopup/AddStudentListPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  applicantData,
  getApplicantProgramListData,
  importStudentCsvFile,
  studentSampleFileDownload,
  clearCsvState,
  clearState,
  postUpdateCashFreeStatus,
} from "../../../reducers/applicantSlice";
import PageLoader from "../../../components/UI/loader/PageLoader";
import { useNavigate } from "react-router-dom";
import ToastMessage from "../../../components/Toast/ToastComponent";
import ErrorToast from "../../../components/Toast/ErrorToast";
const ApplicantProgramList = () => {
  const navigate = useNavigate();
  const {
    isFetching,
    isSuccess,
    isError,
    applicantProgramList,
    sampleFileDownload,
    importCsv,
    isImport,
  } = useSelector(applicantData);

  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);
  const [addApplicantAccess, setApplicantAccess] = useState(false);
  const [addProgramAccess, setAddProgramAccess] = useState(false);
  const [isOfferLetterAccess, setOfferLetterAccess] = useState(false);
  const [isMailAccess, setIsMailAccess] = useState(false);
  const [isApprovalAccess, setIsApprovalRequest] = useState(false);

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [showAddStudentPopup, setAddStudentPopup] = useState(false);
  const [showSuccessMessage, setEnableSuccessMessage] = useState(false);
  const [progId, setProgId] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const dispatch = useDispatch();
  const [programArrayList, setApplicantProgram] = useState([]);
  const [csvFileDownload, setCsvFileDownload] = useState([]);

  const [selectedFile, setSelectedFile] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    dispatch(getApplicantProgramListData());
    dispatch(studentSampleFileDownload());
  }, [isImport]);

  // const viewOnlyAccess = true;

  useEffect(() => {
    setApplicantProgram(applicantProgramList);
    setCsvFileDownload(sampleFileDownload);
    isImportSuccess();
  }, [applicantProgramList]);

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    // isAddApplicant
    // isAddProgram;

    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    const isApplicantAccess = localStorage.getItem("isAddApplicant") === "true";
    const isAddProgramAccess = localStorage.getItem("isAddProgram") === "true";
    const isOfferAccess =
      localStorage.getItem("offerLetterApproval") === "true";
    const isMailAccess = localStorage.getItem("mailSending") === "true";
    const isApprovalAccess = localStorage.getItem("approvalRequest") === "true";

    console.log("ddddddd", isApprovalAccess);

    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
    if (isApplicantAccess) {
      setApplicantAccess(isApplicantAccess);
    }

    if (isAddProgramAccess) {
      setAddProgramAccess(isAddProgramAccess);
    }

    if (isOfferAccess) {
      setOfferLetterAccess(isOfferAccess);
    }

    if (isMailAccess) {
      setIsMailAccess(isMailAccess);
    }

    if (isApprovalAccess) {
      setIsApprovalRequest(isApprovalAccess);
    }
  }, []);

  //import csv
  const [files, setFile] = useState("");

  const handleFile = (name) => (evt) => {
    setFile(evt.target.files[0]);
  };

  const onClickCsv = () => {
    if (selectedFile === "" || selectedFile.type !== "text/csv") {
      // toast.error("Choose a file in csv format!");
      setIsShowError(true);
      setErrMessage("Choose a file in csv format!");
      return;
    }
    dispatch(importStudentCsvFile({ selectedFile, progId }));
  };

  /**onClikc */
  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const onClickAddApplicant = (id) => {
    toggleAddStudentList();
    setProgId(id);
  };

  const toggleAddStudentList = () => {
    setAddStudentPopup(!showAddStudentPopup);
    onClickDeleteUpload();
  };

  const onClickApplicantList = (batchId) => {
    navigate(`/applicantList/${batchId}`);
  };

  // const onClickShortList = (batchId, programMasterId) => {
  //   navigate(`/shortList/${batchId}?programMasterId=${programMasterId}`);
  // };

  const onClickApplicantRequest = (batchId, programMasterId) => {
    navigate(`/applicantRequest/${batchId}?programMasterId=${programMasterId}`);
  };

  const onClickOfferRequest = (batchId, programMasterId) => {
    navigate(
      `/offerLetterApproval/${batchId}?programMasterId=${programMasterId}`
    );
  };

  const onClickOfferLetterList = (batchId, programMasterId) => {
    navigate(`/offerLetterList/${batchId}?programMasterId=${programMasterId}`);
  };
  const onClickWelcomeLetterList = (batchId, programMasterId) => {
    navigate(
      `/welcomeLetterList/${batchId}?programMasterId=${programMasterId}`
    );
  };

  const onClickSendOrientationMailList = (batchId, programMasterId) => {
    navigate(
      `/orientationLetter/${batchId}?programMasterId=${programMasterId}`
    );
  };

  const onClickFeeDetail = (programId, batchId) => {
    navigate(
      `/feePreview?programMasterId=${programId}&programBatchId=${batchId}`
    );
  };

  const isImportSuccess = () => {
    if (isImport) {
      toggleAddStudentList();
      setEnableSuccessMessage(true);
      dispatch(clearCsvState());
    }
  };
  const onClickUpload = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    }
  };

  const onClickDeleteUpload = () => {
    setSelectedFile("");
    setIsFilePicked(false);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const onClickReceivedFee = (programId, batchId) => {
    if (localStorage.getItem("viewOnlyAccess") !== "true") {
      navigate(
        `/couseFeeStudentList?programMasterId=${programId}&programBatchId=${batchId}`
      );
    }
  };

  const onAddFeeClick = () => {
    navigate(`/createFee`);
  };

  const allTransactionClick = () => {
    navigate(`/allTransaction`);
  };
  const onClickcashFreeStatus = () => {
    navigate(`/cashFreeStatus`);
  };

  const formatAmount = (amount) => {
    var number = parseInt(amount);

    var formattedAmount = new Intl.NumberFormat("en-IN", {
      // style: "currency",
      currency: "INR",
    }).format(number);

    // console.log(
    //   new Intl.NumberFormat("en-IN", {
    //     style: "currency",
    //     currency: "INR",
    //   }).format(number)
    // );

    return formattedAmount;

    // console.log("sddsdsdsdsd", number2);
  };

  const onClickProgramName = (programId, batchId) => {
    navigate(
      `/feeSummary?programMasterId=${programId}&programBatchId=${batchId}&activeTab=1`
    );
  };

  const cashFreeSubmit = () => {
    let obj = {
      id: "jdbjd",
    };
    dispatch(postUpdateCashFreeStatus(obj));
  };

  const formatPrice = (num) => {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "k" },
      { v: 1e5, s: "L" },
      { v: 1e7, s: " Cr" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  };

  return (
    <>
      <ToastMessage
        show={showSuccessMessage}
        setShow={() => setEnableSuccessMessage(false)}
        message={importCsv && importCsv.Message}
      />
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SideMenu />
      {isFetching && <PageLoader />}
      <AddStudentListPopup
        handleClose={toggleAddStudentList}
        show={showAddStudentPopup}
        handleFile={handleFile}
        onClickCsv={onClickCsv}
        csvFileDownload={csvFileDownload}
        isFilePicked={isFilePicked}
        hiddenFileInput={hiddenFileInput}
        selectedFile={selectedFile}
        onClickUpload={onClickUpload}
        onClickDeleteUpload={onClickDeleteUpload}
        handleClick={handleClick}
        title={"Applicant"}
      />
      <div className="main-body">
        <div className="page-header">
          <p className="component-heading">
            {localStorage.getItem("adminId") ===
            "afbf696e-8bbc-4ac8-9c76-d94326164a58"
              ? "Masters' Union - All Cohorts"
              : "MasterCamps - All Cohorts"}
          </p>
          {/* <button className="primary-btn feed-btn" onClick={allTransactionClick}>
               All Transaction
            </button> */}

          {/* <button
            className="primary-btn feed-btn"
            onClick={() => {
              navigate(`/feeSummary`);
            }}
          >
            Summary
          </button> */}
          <div className="buttons-in-line">
            {localStorage.getItem("viewOnlyAccess") !== "true" && (
              <button className="primary-btn feed-btn" onClick={cashFreeSubmit}>
                Refresh Cashfree
              </button>
            )}
            {addProgramAccess && (
              <button className="primary-btn feed-btn" onClick={onAddFeeClick}>
                <img src="../assets/icons/plus.svg" alt="" /> Add program
              </button>
            )}
          </div>
        </div>

        <div className="feedback-container scrollbar  feelist-container">
          <table
            className="feedback-table table interview-table maxWidthTable"
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th>Program name</th>
                <th>Applicant Count</th>
                <th>Offer Letters</th>
                <th>Student Count</th>
                <th>Expected Fee Amount</th>
                <th>Total Fee Received</th>
                <th>Balance Fee Amount</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {programArrayList?.map((element, index) => {
                const {
                  noOfStudents,
                  noOfApplicants,
                  offerLetterCount,
                  isDeleted,
                  programName,
                  name,
                  programMasterId,
                  programId,
                  totalAmount,
                  submittedAmount,
                  pendingAmount,
                } = element;
                return (
                  <tr key={index}>
                    <td>
                      <div className="programDetail">
                        <div
                          onClick={() => {
                            if (
                              localStorage.getItem("viewOnlyAccess") !== "true"
                            ) {
                              onClickProgramName(programMasterId, programId);
                            }
                          }}
                          className="programDetailText"
                        >
                          <p className="primary-text pointer">{programName}</p>{" "}
                          <p>{name}</p>
                        </div>
                        {isDeleted === 0 ? (
                          <div className="activeCourse">Active</div>
                        ) : (
                          <div className="expiredCourse">Closed</div>
                        )}
                      </div>
                    </td>
                    <td>
                      <button
                        className="primary-light-btn"
                        onClick={() => onClickApplicantList(programId)}
                      >
                        {noOfApplicants ?? "0"} Applicants
                        <img src="../assets/icons/blueArrowRight.svg" alt="" />
                      </button>
                    </td>

                    <td>
                      <button
                        disabled={
                          localStorage.getItem("viewOnlyAccess") === "true"
                        }
                        className="primary-light-btn"
                        onClick={() =>
                          onClickOfferLetterList(programId, programMasterId)
                        }
                      >
                        {offerLetterCount ?? "0"} Offers Sent
                        <img src="../assets/icons/blueArrowRight.svg" alt="" />
                      </button>
                    </td>
                    <td>
                      <button
                        disabled={
                          localStorage.getItem("viewOnlyAccess") === "true"
                        }
                        className="primary-light-btn"
                        onClick={() =>
                          onClickReceivedFee(programMasterId, programId)
                        }
                      >
                        {noOfStudents} Students{" "}
                        <img src="../assets/icons/blueArrowRight.svg" alt="" />
                      </button>
                    </td>

                    {/* <td>₹ {formatPrice(totalAmount)}</td> */}

                    <td className="toastRow">
                      <div className="hoverElement">
                        ₹ {formatPrice(totalAmount)}
                      </div>

                      <div className="tooltipOuter">
                        <p className="tooltipText">
                          ₹ {formatAmount(totalAmount)}
                        </p>
                      </div>
                    </td>

                    {/* <td>
                      <button
                        onClick={() =>
                          onClickReceivedFee(programMasterId, programId)
                        }
                        className="success-light-btn-b"
                      >
                        ₹ {formatPrice(submittedAmount)}
                        <img src="../assets/icons/greenArrowRight.svg" alt="" />
                        <div className="tooltipOuter">
                            <p className="tooltipText">
                              {formatAmount(submittedAmount)}
                            </p>
                          </div>
                      </button>

                      
                    </td> */}

                    <td className="toastRow">
                      <div
                        onClick={() =>
                          onClickReceivedFee(programMasterId, programId)
                        }
                        className="hoverElement success-light-btn-b"
                      >
                        ₹ {formatPrice(submittedAmount)}
                        <img src="../assets/icons/greenArrowRight.svg" alt="" />
                      </div>

                      <div className="tooltipOuter">
                        <p className="tooltipText">
                          ₹ {formatAmount(submittedAmount)}
                        </p>
                      </div>
                    </td>

                    {/* <td>
                      <button
                        onClick={() =>
                          onClickReceivedFee(programMasterId, programId)
                        }
                        className="danger-light-btn"
                      >
                        ₹ {formatPrice(pendingAmount)}
                        <img
                          src="../assets/icons/ArrowLeft_danger.svg"
                          alt=""
                        />
                      </button>
                    </td> */}

                    <td className="toastRow">
                      <div
                        onClick={() =>
                          onClickReceivedFee(programMasterId, programId)
                        }
                        className="hoverElement danger-light-btn"
                      >
                        ₹ {formatPrice(pendingAmount)}
                        <img
                          src="../assets/icons/ArrowLeft_danger.svg"
                          alt=""
                        />
                      </div>

                      <div className="tooltipOuter">
                        <p className="tooltipText">
                          ₹ {formatAmount(pendingAmount)}
                        </p>
                      </div>
                    </td>

                    <td className="text-center">
                      <button
                        className="action-btn"
                        onClick={() => openActionMenu(index)}
                        onBlur={closeActionMenu}
                      >
                        <img src="../assets/icons/options.svg" />
                      </button>
                      <ul
                        className={
                          menuId === index
                            ? "action-dropdown active"
                            : "action-dropdown"
                        }
                      >
                        {!viewOnlyAccess && addApplicantAccess && (
                          <li
                            onClick={() => onClickAddApplicant(programId)}
                            className="dropdown-item"
                          >
                            <img src="../assets/icons/edit.svg" alt="" />
                            Add Applicants
                          </li>
                        )}
                        <li
                          onClick={() => onClickApplicantList(programId)}
                          className="dropdown-item"
                        >
                          <img src="../assets/icons/list.svg" alt="" />
                          Applicant list
                        </li>

                        {isApprovalAccess && (
                          <li
                            onClick={() =>
                              onClickApplicantRequest(
                                programId,
                                programMasterId
                              )
                            }
                            className="dropdown-item"
                          >
                            <img
                              src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/approvalListIcon.svg"
                              alt=""
                            />
                            Approval Request
                          </li>
                        )}

                        {isOfferLetterAccess && (
                          <>
                            <li
                              onClick={() =>
                                onClickOfferRequest(programId, programMasterId)
                              }
                              className="dropdown-item"
                            >
                              <img
                                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/sendOfferLetter.svg"
                                alt=""
                              />
                              Send Offer Letter
                            </li>

                            <li
                              onClick={() =>
                                onClickOfferLetterList(
                                  programId,
                                  programMasterId
                                )
                              }
                              className="dropdown-item"
                            >
                              <img
                                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/offerLetter.svg"
                                alt=""
                              />
                              Offer Letters
                            </li>
                          </>
                        )}

                        {isMailAccess && (
                          <>
                            <li
                              onClick={() =>
                                onClickWelcomeLetterList(
                                  programId,
                                  programMasterId
                                )
                              }
                              className="dropdown-item"
                            >
                              <img
                                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/sendWelcomeLetter.svg"
                                alt=""
                              />
                              Send Welcome Letter
                            </li>
                            <li
                              onClick={() =>
                                onClickSendOrientationMailList(
                                  programId,
                                  programMasterId
                                )
                              }
                              className="dropdown-item"
                            >
                              <img
                                src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/sendWelcomeLetter.svg"
                                alt=""
                              />
                              Send Orientation Mail
                            </li>
                          </>
                        )}

                        {addProgramAccess && (
                          <li
                            onClick={() =>
                              onClickFeeDetail(programMasterId, programId)
                            }
                            className="dropdown-item"
                          >
                            <img src="../assets/icons/preview.svg" alt="" />
                            <p className="dropdown-text">Fee Details</p>
                          </li>
                        )}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ApplicantProgramList;
