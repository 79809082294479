import React, { useEffect, useState } from "react";
import "./AddStudentListPopupStyle.css";

const AddStudentResume = (props) => {
  const arr = [];
  // props.sampleFileDownload

  return (
    <React.Fragment>
      <div className={`popup-outer ${props.show ? "show-popup" : ""}`}>
        <div className="popup-container csv-popup">
          <div className="popup-header">
            <div className="popup-heading">
              Upload Documents
              <img
                src="../assets/icons/close.svg"
                alt=""
                className="close-btn"
                onClick={props.handleClose}
              />
            </div>

            <div className="popup-header-body">
              <div className="header-left">
                <p className="text-7"></p>
              </div>
            </div>
          </div>
          <div className="popup-body">
            <div className="form-group">
              <label htmlFor="app-list" className="create-label">
                Resume
                <span className="imp-mark">*</span>
              </label>
              <div className="upload-btn-wrapper">
                <div className="upload-inner">
                  {!props.isFilePicked && (
                    <input
                      className="uploadbox"
                      onChange={props.onClickUpload}
                      ref={props.hiddenFileInput}
                      id="uploadbox"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  )}
                  <button className="upload-btn" onClick={props.handleClick}>
                    Choose a File
                  </button>
                  <p className="file-name">{props.selectedFile?.name}</p>
                </div>
                <img
                  src="../../assets/icons/delete.svg"
                  alt=""
                  onClick={props.onClickDeleteUpload}
                />
              </div>
            </div>
            <br></br>

            <div className="form-group">
              <label htmlFor="app-list" className="create-label">
                Profile
                <span className="imp-mark">*</span>
              </label>
              <div className="upload-btn-wrapper">
                <div className="upload-inner">
                  {!props.isCoverFilePicked && (
                    <input
                      className="uploadbox"
                      onChange={props.onClickCoverUpload}
                      ref={props.coverFileInput}
                      id="uploadbox"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  )}
                  <button
                    className="upload-btn"
                    onClick={props.handleCoverClick}
                  >
                    Choose a File
                  </button>
                  <p className="file-name">{props.coverFile?.name}</p>
                </div>
                <img
                  src="../../assets/icons/delete.svg"
                  alt=""
                  onClick={props.onClickCoverDeleteUpload}
                />
              </div>
            </div>
          </div>
          <div className="popup-footer">
            <div className="btn-group">
              <button onClick={props.handleClose} className="dark-btn-outline">
                cancel
              </button>
              <button className="green-btn" onClick={props.onClickUploadResume}>
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddStudentResume;
