import React, { useEffect, useState } from "react";
import "./createFee.css";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import $ from "jquery";
import ProgramDropdown from "../../../components/Dropdowns/ProgramDropdown/ProgramDropdown";
import GoBackPopup from "../../../components/Popups/GoBackPopup/GoBackPopup";
import PageLoader from "../../../components/UI/loader/PageLoader";
import {
  addProgramAndFeeDetails,
  clearComplete,
  clearState,
  feeData,
  getFeeTypes,
} from "../../../reducers/feeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorToast from "../../../components/Toast/ErrorToast";
import ToastMessage from "../../../components/Toast/ToastComponent";

export default function CreateFee() {
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get("edit");
  const programMasterId = searchParams.get("programMasterId") ?? "";
  const programBatchId = searchParams.get("programBatchId") ?? "";

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [masterProId, setMasterProId] = useState(programMasterId);
  const [programId, setProgramId] = useState(programBatchId);
  // const [batchId, setBatchId] = useState('');
  const [feeId, setFeeId] = useState("");
  const [feeAmt, setFeeAmt] = useState("");
  // const [feeDue, setFeeDue] = useState('');
  const [duration, setDuration] = useState("");

  // const [taxes, setTaxes] = useState([{ id: 0, taxType: '', percent: '' }]);
  // const [installments, setInstallments] = useState([{ id: 0, installmentNumber: '', amount: '', dueDate: '' }]);
  const [taxes, setTaxes] = useState([{ id: 0, taxType: "", percent: "" }]);
  const [installments, setInstallments] = useState([
    { id: 0, installmentNumber: "", amount: "", dueDate: "" },
  ]);

  const [isInstallment, setIsInstallment] = useState(false);

  const {
    isComplete,
    isError,
    errorMessage,
    programDetailId,
    feeTypes,
    isFetching,
  } = useSelector(feeData);

  useEffect(() => {
    dispatch(getFeeTypes());
    console.log("isEdit: ", isEdit);
    // if(isEdit){
    //   console.log("editing");
    //   let object = {
    //     programMasterId: programMasterId,
    //     programBatchId: programBatchId,
    //   };
    //   dispatch(getEditFeeDetails(object));
    // }
  }, []);

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    if (programDetailId !== "") {
      console.log("navigated : " + programMasterId + " " + programDetailId);
      // navigate(`/feePreview/?programMasterId=${programMasterId}&programBatchId=${programDetailId}`);
    }
  }, [programDetailId]);

  useEffect(() => {
    if (isComplete) {
      setIsShowSuccess(true);
      setMasterProId("");
      setProgramId("");
      setDuration("");
      setFeeAmt("");
      setFeeId("");
      setInstallments([
        { id: 0, installmentNumber: "", amount: "", dueDate: "" },
      ]);
      setTaxes([{ id: 0, taxType: "", percent: "" }]);
      changeContainerfee(1, 2);
      dispatch(clearComplete());
      navigate("/applicantProgList");
    }
  }, [isComplete]);

  // const [addInstallment, setAddInstallment] = useState(false);
  const [isGoBackOpen, setIsGoBackOpen] = useState(false);
  const changeContainerfee = (show, hide1, hide2) => {
    $(`#feebtn-${show}`).addClass("active");
    $(`#feebtn-${hide1}`).removeClass("active");

    $(`#container-${show}`).addClass("show");
    $(`#container-${hide1}`).removeClass("show");
  };
  const toggleGoBackPopup = () => {
    setIsGoBackOpen(!isGoBackOpen);
  };

  const onProgramChange = (event) => {
    setMasterProId(event.target.value);
    // dispatch(getAllBatchByProgramId(event.target.value));
  };

  // const onAddInstallment = () => {
  //   setIsInstallment((prev) => !prev);
  //   setInstallments([
  //     { id: 0, installmentNumber: "", amount: "", dueDate: "" },
  //   ]);
  // };

  const onNextClick = (e) => {
    e.preventDefault();
    if (programId === "" || masterProId === "" || duration === "") {
      setErrMessage("Fill all the required fields!");
      setIsShowError(true);
      return;
    }
    changeContainerfee(2, 1);
  };

  // tax functions
  const onAddTax = (e) => {
    let isEmpty = taxes.some((tax) => tax.id === "" || tax.percent === "");
    if (isEmpty) {
      return;
    }
    setTaxes((prev) => [
      ...prev,
      { id: prev[prev.length - 1].id + 1, taxType: "", percent: "" },
    ]);
  };

  const onDeleteTax = (id) => {
    setTaxes((prev) => prev.filter((el) => el.id !== id));
  };

  const setTaxName = (e, id) => {
    let taxx = [...taxes];
    let idx = taxx.findIndex((obj) => obj.id === id);
    let obj = {
      id: taxx[idx].id,
      taxType: e.target.value,
      percent: taxx[idx].percent,
    };
    taxx.splice(idx, 1, obj);
    setTaxes(taxx);
  };
  const setTaxPer = (e, id) => {
    let taxx = [...taxes];
    let idx = taxx.findIndex((obj) => obj.id === id);
    let obj = {
      id: taxx[idx].id,
      taxType: taxx[idx].taxType,
      percent: e.target.value,
    };
    taxx.splice(idx, 1, obj);
    setTaxes(taxx);
  };

  // installement functions
  const onAddIns = (e) => {
    let isEmpty = installments.some(
      (ins) =>
        ins.amount === "" || ins.installmentNumber === "" || ins.dueDate === ""
    );
    if (isEmpty) {
      return;
    }
    setInstallments((prev) => [
      ...prev,
      {
        id: prev[prev.length - 1].id + 1,
        installmentNumber: "",
        amount: "",
        dueDate: "",
      },
    ]);
  };

  const onDeleteIns = (id) => {
    setInstallments((prev) => prev.filter((el) => el.id !== id));
  };

  const setInstallmentNo = (e, id) => {
    let ins = [...installments];
    let idx = ins.findIndex((obj) => obj.id === id);
    let obj = {
      id: ins[idx].id,
      installmentNumber: e.target.value,
      amount: ins[idx].amount,
      dueDate: ins[idx].dueDate,
    };
    ins.splice(idx, 1, obj);
    setInstallments(ins);
  };
  const setInstallmentAmt = (e, id) => {
    let ins = [...installments];
    let idx = ins.findIndex((obj) => obj.id === id);
    let obj = {
      id: ins[idx].id,
      installmentNumber: ins[idx].installmentNumber,
      amount: e.target.value,
      dueDate: ins[idx].dueDate,
    };
    ins.splice(idx, 1, obj);
    setInstallments(ins);
  };
  const setDueDate = (e, id) => {
    let ins = [...installments];
    let idx = ins.findIndex((obj) => obj.id === id);
    let obj = {
      id: ins[idx].id,
      installmentNumber: ins[idx].installmentNumber,
      amount: ins[idx].amount,
      dueDate: e.target.value,
    };
    ins.splice(idx, 1, obj);
    setInstallments(ins);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    if (
      programId === "" ||
      feeId === "" ||
      feeAmt === "" ||
      duration === "" ||
      masterProId === ""
    ) {
      isValid = false;
      setIsShowError(true);
      setErrMessage("Fill all the required fields!");
      // toast.error("Fill all the required fields !");
    }
    const program = {
      isCreate: true,
      programId: programId,
      duration: duration,
      programMasterId: masterProId,
    };
    const fee = {
      feeMasterId: feeId,
      amount: feeAmt,
      isPayableInInstallments: isInstallment,
      numberOfInstallments: installments.length,
      feeTax: taxes,
      installment: installments,
    };
    console.log("submit, ", fee);
    if (isValid) {
      dispatch(addProgramAndFeeDetails({ fee: fee, program: program }));
    }
  };
  return (
    <React.Fragment>
      <SideMenu />
      {isFetching && <PageLoader />}
      <GoBackPopup
        handleCloseGoBack={toggleGoBackPopup}
        show={isGoBackOpen}
        navigateTo="/applicantProgList"
      />
      <ToastMessage
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={"Program Created Successfully"}
      />
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />

      <div className="main-body">
        <div className="page-header create-header">
          <button className="component-back" onClick={toggleGoBackPopup}>
            <img src="../assets/icons/back-btn.svg" alt="" />
            Back to page
          </button>
          <div>
            <button
              className="primary-clean active"
              id="feebtn-1"
              onClick={() => changeContainerfee(1, 2)}
            >
              Program Section
            </button>
            <button
              className="primary-clean"
              id="feebtn-2"
              onClick={() => changeContainerfee(2, 1)}
            >
              Fee Section
            </button>
          </div>
          <div></div>
        </div>

        <div
          id="container-1"
          className="show createfee scrollbar"
          style={{ display: "none" }}
        >
          <div className="create-container">
            <form>
              <div className="form-group mt-20">
                <label htmlFor="title" className="create-label">
                  Program <span className="imp-mark">*</span>
                </label>
                <ProgramDropdown
                  value={masterProId}
                  onChange={onProgramChange}
                />
                <span className="error-msg">Enter the field</span>
              </div>

              {
                <div className="form-group mt-5">
                  <label htmlFor="title" className="create-label">
                    Batch <span className="imp-mark">*</span>
                  </label>

                  <select
                    id="type"
                    className="popup-dropdown mt-5"
                    onChange={(e) => setProgramId(e.target.value)}
                    value={programId}
                  >
                    <option value="">Select Batch</option>
                    {/* {batchDetails?.map((batch) => (
                    <option key={batch.id} id={batch.id} value={batch.id}>{batch.name}</option>
                  ))} */}
                    <option value="cohort-1">cohort-1</option>
                    <option value="cohort-2">cohort-2</option>
                    <option value="cohort-3">cohort-3</option>
                    <option value="cohort-4-r-2">cohort-4 R-2</option>
                    <option value="cohort-5">cohort-5</option>
                    <option value="cohort-6">cohort-6</option>
                    <option value="cohort-7">cohort-7</option>
                    <option value="cohort-8">cohort-8</option>
                    <option value="cohort-9">cohort-9</option>
                    <option value="cohort-10">cohort-10</option>
                    <option value="cohort-11">cohort-11</option>
                  </select>
                  <span className="error-msg">Enter the field</span>
                </div>
              }

              <div className="form-group mt-5">
                <label htmlFor="title" className="create-label">
                  Duration in months <span className="imp-mark">*</span>
                </label>
                <input
                  type="number"
                  name="duration"
                  id="duration"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="create-input mt-5"
                  placeholder="Enter months in program"
                />
                <span className="error-msg">Enter the field</span>
              </div>

              <div className="form-footer mt-30 extra-margin">
                <button className="primary-btn" onClick={onNextClick}>
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>

        <div id="container-2" className="createfee" style={{ display: "none" }}>
          <div className="create-container">
            <form action="" className="dynamic-form scrollbar">
              {/* <div className="form-group">
                <label htmlFor="title" className="create-label">Fee Type<span className="imp-mark">*</span></label>
                <div className="radio-group mt-10">

                        <label className="create-label" htmlFor="Semester">
                            <input className="radio-btn" type="radio" name="semester" id="Semester" value="Semester"/>
                            <span className="radiomark"></span>One time
                        </label>

                        <label className="create-label" htmlFor="Annual">
                            <input className="radio-btn" type="radio" name="semester" id="Annual" value="Annual"/>
                            <span className="radiomark"></span>Recurring
                        </label>
                </div>
            </div> */}

              <div className="form-group mt-20">
                <label htmlFor="title" className="create-label">
                  Fee name <span className="imp-mark">*</span>
                </label>

                <select
                  id="type"
                  className="popup-dropdown mt-5"
                  onChange={(e) => setFeeId(e.target.value)}
                  value={feeId}
                >
                  <option value="">Select Fee Name</option>
                  {feeTypes.length > 0 &&
                    feeTypes.map((fee) => (
                      <option key={fee.id} value={fee.id}>
                        {fee.feeName}
                      </option>
                    ))}
                  {/* <option value="hide">Select Fee Name</option>
                  <option value="Admission">Admission</option>
                  <option value="Tuition">Tuition</option>
                  <option value="Catering">Catering</option>
                  <option value="Hostel">Hostel</option> */}
                </select>
                <span className="error-msg">Enter the field</span>
              </div>

              <div className="form-group mt-5">
                <label htmlFor="title" className="create-label">
                  Fee Amount <span className="imp-mark">*</span>
                </label>
                <input
                  type="number"
                  name="feeAmt"
                  id="feeAmt"
                  value={feeAmt}
                  className="create-input mt-5"
                  placeholder="Enter Fee Amount"
                  onChange={(e) => setFeeAmt(e.target.value)}
                />
                <span className="error-msg">Enter the field</span>
              </div>
              {/* <div className="form-group mt-5">
                <label htmlFor="title" className="create-label">
                  Due Date <span className="imp-mark">*</span>
                </label>
                <input
                  type="date"
                  name=""
                  id=""
                  className="create-input mt-5"
                  placeholder="Enter Due Date"
                  onChange={onDueDateChange}
                />
                <span className="error-msg">Enter the field</span>
              </div> */}
              {taxes.map(({ id, taxType, percent }, index) => (
                <div className="multidivs" key={id}>
                  <div className="multidivs-content">
                    <label htmlFor="title" className="create-label">
                      Tax name<span className="imp-mark">*</span>
                    </label>
                    <input
                      type="text"
                      name="taxName"
                      id={"taxName-" + id}
                      value={taxType}
                      onChange={(e) => setTaxName(e, id)}
                      className="create-input mt-5"
                      placeholder="Enter Tax name"
                    />
                  </div>

                  <div className="multidivs-content">
                    <label htmlFor="title" className="create-label">
                      Tax %<span className="imp-mark">*</span>
                    </label>
                    <input
                      type="number"
                      name="taxPer"
                      id={"taxPer-" + id}
                      value={percent}
                      onChange={(e) => setTaxPer(e, id)}
                      className="create-input mt-5"
                      placeholder="Enter Tax"
                    />
                  </div>

                  {index === 0 ? (
                    <div
                      className="deleteicon addButtonAlignment"
                      onClick={onAddTax}
                    >
                      <img src="../../assets/icons/add_circle.svg" alt="" />
                    </div>
                  ) : (
                    <div
                      className="deleteicon addButtonAlignment"
                      onClick={(e) => onDeleteTax(id)}
                    >
                      <img src="../../assets/icons/delete_circle.svg" alt="" />
                    </div>
                  )}
                </div>
              ))}
              {/* <div className="border-bottom"></div>
              <div className="form-group">
                <label className="checkmark-label pt-0" htmlFor="DailyRadio">
                  <input
                    type="checkbox"
                    name="DailyRadio"
                    id="DailyRadio"
                    className="checkmark-btn"
                    value={isInstallment}
                    onChange={onAddInstallment}
                  />
                  <span className="checkmarkspan"></span>Add installments
                </label>
              </div> */}

              {isInstallment && (
                <>
                  <div className="border-bottom"></div>

                  {installments.length > 0 &&
                    installments.map(
                      ({ id, installmentNumber, amount, dueDate }, index) => (
                        <div className="multidivs with_3_sections" key={id}>
                          <div className="multidivs-content">
                            <label htmlFor="title" className="create-label">
                              Installment Number
                              <span className="imp-mark">*</span>
                            </label>
                            <input
                              type="number"
                              name=""
                              onChange={(e) => setInstallmentNo(e, id)}
                              value={installmentNumber}
                              id=""
                              className="create-input mt-5"
                              placeholder="Enter number of Installment"
                            />
                          </div>

                          <div className="multidivs-content">
                            <label htmlFor="title" className="create-label">
                              Installment amount
                              <span className="imp-mark">*</span>
                            </label>
                            <input
                              type="number"
                              name=""
                              id=""
                              onChange={(e) => setInstallmentAmt(e, id)}
                              value={amount}
                              className="create-input mt-5"
                              placeholder="Enter amount"
                            />
                          </div>

                          <div className="multidivs-content">
                            <label htmlFor="title" className="create-label">
                              Installment due date
                              <span className="imp-mark">*</span>
                            </label>
                            <input
                              type="date"
                              name=""
                              id=""
                              value={dueDate}
                              onChange={(e) => setDueDate(e, id)}
                              className="create-input mt-5"
                              placeholder="Enter due date"
                            />
                          </div>

                          {index === 0 ? (
                            <div className="deleteicon" onClick={onAddIns}>
                              <img
                                src="../../assets/icons/add_circle.svg"
                                alt=""
                              />
                            </div>
                          ) : (
                            <div
                              className="deleteicon"
                              onClick={(e) => onDeleteIns(id)}
                            >
                              <img
                                src="../../assets/icons/delete_circle.svg"
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      )
                    )}
                </>
              )}
            </form>
            <div className="form-footer  multibuttons">
              <button
                className="secondary-btn"
                onClick={(e) => {
                  e.preventDefault();
                  changeContainerfee(1, 2);
                }}
              >
                Back
              </button>
              <button className="primary-btn" onClick={onFormSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
