import React, { useState, useEffect } from "react";
import "../singleStudentFee/singleStudentFeeStyle.css";
import "./studentFeeDueListStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  feeData,
  getParticularProgramFeeTypes,
  getSingleStudentFeeList,
  extendFeeDueDate,
  clearExtendDate,
  clearState,
  deleteParticularFeeDue,
  getParticularStudentFeeDues,
} from "../../../reducers/feeSlice";
import SideMenu from "../../../components/Layout/menu/SideMenu";
import FeeExtendDatePopup from "../../../components/Popups/FeeExtendDatePopup/FeeExtendDatePopup";
import FeeDropdown from "../../../components/Dropdowns/FeeDropdown/FeeDropdown";
import moment from "moment";
import PageLoader from "../../../components/UI/loader/PageLoader";
import LoanPopup from "../../../components/Popups/LoanPopup/LoanPopup";
import VerifyPopup from "../../../components/Popups/VerifyPopup/VerifyPopup";
import AcceptPaymentPopup from "../../../components/Popups/AcceptPaymentPopup/AcceptPaymentPopup";
import MuLoan from "../../../components/Popups/MuLoan/MuLoan";
import ActionPopup from "../../../components/Popups/ActionPopup/ActionPopup";
import FeeReciept from "../../../components/Recipt/FeeReciept";
import UpdateFeePopup from "../../../components/Popups/UpdateFeePopup/UpdateFeePopup";

const StudentFeeDueList = () => {
  const {
    isFetching,
    isComplete,
    isError,
    isSuccess,
    programFeeTypeList,
    studentFeeDuesList,
    extendDueDateSuccess,
  } = useSelector(feeData);
  const [isUpdateFeePopUpOpen, setUpdateFeePopUp] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [selectedFee, setSelectedFee] = useState("Select Fee Type");
  // const [dropdownDataChange, setDropdownDataChange] = useState("");
  const [totalAmounts, setTotalAmounts] = useState({
    total: 0,
    paid: 0,
    pending: 0,
  });
  const [studentFeeDueData, setStudentFeeDuesData] = useState(null);
  const [feeDueId, setFeeDueId] = useState(null);
  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);

  const studentId = searchParams.get("studentId");
  const feeId = searchParams.get("feeId");
  const feeMasterId = searchParams.get("feeMasterId");
  const programMasterId = searchParams.get("programMasterId");
  const programBatchId = searchParams.get("programBatchId");

  console.log("fdfdfdfdfdfdfdf", studentFeeDuesList);

  useEffect(() => {
    if (studentFeeDuesList !== null) {
      setStudentFeeDuesData(studentFeeDuesList?.studentDetails);
      setTotalAmounts({
        total: studentFeeDuesList?.totalFeeDuesSum ?? 0,
        paid: studentFeeDuesList?.paidAmount ?? 0,
        pending: studentFeeDuesList?.totalPendingAmount ?? 0,
      });
    }
  }, [studentFeeDuesList]);

  useEffect(() => {
    if (isError) {
      localStorage.clear();
      navigate("/login", { replace: true });
      dispatch(clearState());
    }
  }, [isError]);

  useEffect(() => {
    let object = {
      studentId: studentId,
      programBatchId: programBatchId,
    };
    dispatch(getParticularStudentFeeDues(object));
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      let object = {
        studentId: studentId,
        programBatchId: programBatchId,
      };
      dispatch(getParticularStudentFeeDues(object));
      dispatch(clearState());
      setUpdateFeePopUp(false);
    }
  }, [isSuccess]);

  const toggleUpdateFeePopup = () => {
    setUpdateFeePopUp((p) => !p);
  };

  useEffect(() => {
    const isViewOnly = localStorage.getItem("viewOnlyAccess") === "true";
    if (isViewOnly) {
      setViewOnlyAccess(isViewOnly);
    }
  }, []);

  return (
    <>
      <SideMenu />

      <UpdateFeePopup
        show={isUpdateFeePopUpOpen}
        handleClose={toggleUpdateFeePopup}
        studentId={studentId}
        feeDueId={feeDueId}
      />

      {/* <LoanPopup
        show={isLoanPopUpOpen}
        feeDueData={feeDueData}
        studentId={studentId}
        feeId={feeId}
        handleClose={toggleLoanPopup}
      />
      <AcceptPaymentPopup
        show={isPaymentPopupOpen}
        handleClose={togglePaymentPopup}
      />
      <MuLoan show={isMuLoanPopUpOpen} handleClose={toggleMuLoanPopup} /> */}
      {isFetching && <PageLoader />}

      {/* <ActionPopup
        show={showAction}
        heading={actionHeading}
        handleClose={() => {
          setShowAction(false);
          setFeeDueId(null);
        }}
        message={actionMessage}
        action={actionName}
        actionType={actionType}
        onSubmit={onSubmit}
      /> */}

      <div className="main-body">
        <div className="page-header">
          <button
            className="component-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src="../assets/icons/back-btn.svg" />
            Back to Page
          </button>
        </div>
        {studentFeeDueData && (
          <div className="student-container">
            <div className="student-info-outer">
              <div className="student-inner-left">
                <div className="img-outer">
                  <img
                    src="/assets/img/blankuser.png"
                    alt=""
                    className="student-img"
                  />
                </div>
                <div className="student-details-outer">
                  <div className="student-details">
                    <p className="student-name">
                      {studentFeeDueData?.firstName}{" "}
                      {studentFeeDueData?.lastName}
                    </p>
                    <p className="success-tag">
                      {studentFeeDueData?.Program?.programName} &#xb7;
                      {
                        studentFeeDueData?.Program?.ProgramDetails[0]
                          .durationInMonths
                      }{" "}
                      months
                    </p>
                    <p className="primary-tag">
                      Registration number &#xb7;{" "}
                      {studentFeeDueData?.registrationNumber}
                    </p>
                  </div>
                  <div className="student-details">
                    <p className="student-mail">
                      {studentFeeDueData?.officialEmail} |{" "}
                      {studentFeeDueData?.mobileNumber}
                    </p>
                  </div>
                </div>
              </div>
              <div className="student-inner-right bg-grey">
                <div className="fee-box total">
                  <p>Total Dues</p>
                  <p className="text-dark">₹ {totalAmounts?.total}</p>
                </div>
                <div className="fee-box">
                  <p>Received</p>
                  <p className="text-success">₹ {totalAmounts?.paid}</p>
                </div>
                <div className="fee-box">
                  <p>Pending</p>
                  <p className="text-danger">₹ {totalAmounts?.pending}</p>
                </div>
              </div>
            </div>
            <div className="student-fee-table">
              {/* <div className="student-info-outer">
              <div className="student-inner-left">
                <p className="student-name">Tution Fee</p>
              </div>
              <div className="student-inner-right ">
                <div className="fee-box total">
                  <p>Total Dues</p>
                  <p className="text-dark">₹ 60,00,000</p>
                </div>
                <div className="fee-box">
                  <p>Received</p>
                  <p className="text-success">₹ 9,59,000</p>
                </div>
                <div className="fee-box">
                  <p>Pending</p>
                  <p className="text-danger">₹ 50,41,000</p>
                </div>
              </div>
            </div> */}
              <table
                className="fee-details-table"
                cellPadding={0}
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th>Fee Type</th>
                    <th>Fee Due Date</th>
                    <th>Due Amount</th>
                    <th>Paid Amount</th>
                    <th>Submited Date</th>
                    <th>Fee method</th>
                    <th>Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {studentFeeDueData &&
                    studentFeeDueData.FeeDues.map((due) => (
                      <tr>
                        <td>{due.Fee.FeeMaster.feeName}</td>
                        <td>
                          <p className="text-primary">
                            {moment(due?.dueOn).format("DD-MM-YYYY")}
                          </p>
                        </td>
                        <td>
                          <p>₹ {due.feeDue}</p>
                        </td>
                        <td>
                          {due?.isPaid ? (
                            <p className="text-success">
                              ₹ {due?.FeeTransactions[0]?.amount}
                            </p>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {due?.isPaid ? (
                            <>{moment(due?.paidOn).format("DD-MM-YYYY")}</>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {due.isPaid ? (
                            <>
                              {due.isBankTransfer && (
                                <p className="tag-active">Bank Transfer</p>
                              )}
                              {due.isMuLoan && (
                                <p className="tag-active">MU Loan</p>
                              )}
                              {due.isBankLoan && (
                                <p className="tag-active">Bank Loan</p>
                              )}
                              {due.cashFreeStatus === "paid" && (
                                <p className="tag-active">Cash Free</p>
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {
                            due.isPaid ? (
                              <p className="success-light-btn-a no_click">
                                Paid
                              </p>
                            ) : !viewOnlyAccess ? (
                              <p
                                className="success-light-btn-a succcess_color_blue"
                                onClick={() => {
                                  toggleUpdateFeePopup();
                                  // setStudentId(id);
                                  setFeeDueId(due?.id);
                                }}
                              >
                                Pay
                              </p>
                            ) : (
                              <p>--</p>
                            )

                            // (

                            // )
                          }
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StudentFeeDueList;
